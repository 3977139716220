import * as firebase from "firebase";
import Timestamp = firebase.firestore.Timestamp;
import * as moment from "moment";

export class DateTimeUtils {
  static isoToDate(dateIso: string): Date {
    return new Date(dateIso);
  }

  // Formata a data em tempo decorrido
  static timestampToMoment(date: Timestamp): string {
    if (!date) {
      return "";
    }
    moment.locale("pt-BR");
    return moment(date.toDate()).fromNow();
  }

}
