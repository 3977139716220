import {ITdDataTableColumn} from "@covalent/core/data-table";
import {isNullOrUndefined} from "../../utils/commons";
import {Complemento, ComplementoItem} from "../produto.model";
import {AngularFirestore} from "@angular/fire/firestore";
import {isNumber} from "util";

export class ComplementoProdutoItemDatasource {

  columns: ITdDataTableColumn[] = [
    {name: "drag", label: "", width: 50},
    {name: "nome", label: "Nome", tooltip: "Nome do complemento"},
    {name: "quantidadeMaxima", label: "Qtde Máxima", tooltip: "Quantidde máxima do item", numeric: true, width: 100},
    {name: "preco", label: "Preço", tooltip: "Preço em reais", format: transform, numeric: true, width: 150},
    {name: "options", label: "", tooltip: "", width: 50},
  ];

  private complemento: Complemento;

  constructor(private db: AngularFirestore, complemento: Complemento) {
    this.complemento = complemento;
  }

  get data(): ComplementoItem[] {
    return this.complemento.itens;
  }

  get dataRemoved(): ComplementoItem[] {
    return this.complemento.itensRemoved;
  }

  set data(data: ComplementoItem[]) {
    this.complemento.itens = data;
  }

  push(item: ComplementoItem) {
    // Criar um id para o item do complemento
    if (isNullOrUndefined(item.id)) {
      item.id = this.db.createId();
    }
    // Só adiciona caso ainda não esteja na lista
    if (!this.data.some(value => value.id === item.id)) {
      this.data.push(item);
    }
  }

  remove(item: ComplementoItem) {
    // Adiciona na lista de removidos
    this.complemento.itensRemoved.push(item);
    // Remove o item da lista
    this.data = this.data.filter(i => i.id !== item.id);
  }
}

export function transform(numero: any): string {
  if (isNullOrUndefined(numero) || !isNumber(numero)) {
    numero = 0.0;
  }
  const res = numero.toFixed(2).split(".");
  res[0] = res[0].split(/(?=(?:...)*$)/).join(".");
  return res.join(",");
}
