// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  version: require("../../package.json").version,
  google_maps_api_key: "AIzaSyBg9_9OKaQ4AtukJbAKJeipghWgvWmicPk",
  onesignal_app_id: "12d7c54d-dc47-4205-90b3-2b536c278e64",
  onesignal_rest_api_key: "ZTc3ZWQ5NmYtZjAyMi00N2E5LTg3M2QtMTNlNTMyOWMzN2I5",
  hub_do_dev_token: "61792475cBXGMAqHJU111564440",
  iugu_token: "d49cb8ab191f7cdd6151ce1479138cca", // token de testes
  firebase: {
    apiKey: "AIzaSyDPH0mws1z7IfEahwiSz-_AB7KXhr9Ntm8",
    authDomain: "fir-d2e1c.firebaseapp.com",
    databaseURL: "https://fir-d2e1c.firebaseio.com",
    projectId: "fir-d2e1c",
    storageBucket: "fir-d2e1c.appspot.com",
    messagingSenderId: "927950448395",
  },
  encriptKey: "eSgVkYp3s6v9y$B&E)H@McQfTjWmZq4t7w!z%C*F-JaNdRgUkXp2r5u8x/A?D(G+KbPeShVmYq3t6v9y$B&E)H@McQfTjWnZr4u7x!z%C*F-JaNdRgUkXp2s5v8y/B?D(G+KbPe" +
    "ShVmYq3t6w9z$C&F)H@McQfTjWnZr4u7x!A%D*G-KaNdRgUkXp2s5v8y/B?E(H+MbQeThVmYq3t6w9z$C&F)J@NcRfUjXnZr4u7x!A%D*G-KaPdSgVkYp3s5v8y/B?E(H+MbQeThWmZq4t7w9" +
    "z$C&F)J@NcRfUjXn2r5u8x/A%D*G-KaPdSgVkYp3s6v9y$B&E(H+MbQeThWmZq4t7w!z%C*F-J@NcRfUjXn2r5u8x/A?D(G+KbPdSgVkYp3s6v9y$B&E)H@McQfThWmZq4t7w!z%C*F-JaNdR" +
    "gUkXn2r5u8x/A?D(G+KbPeShVmYq3s6v9y$B&E)H@McQfTjWnZr4u7w!z%C*F-JaNdRgUkXp2s5v8y/A?D(G+Kb",
};
