import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireObject } from "@angular/fire/database";
import { AuthService } from "../../modules/login/auth.service";
import { from, of } from "rxjs";
import { Observable } from "rxjs/Rx";
import { map, mergeMap, take } from "rxjs/operators";
import { isEmpty, isNullOrUndefined } from "../../utils/commons";
import { Empresa } from "../../models/empresa";

export class Settings {
  // pedido: Pedido;
  impressoraPadrao: string;
  printFormat: string;
  pageDimension: string;
  numeroVias: number;
  apiIntegracao: boolean;
  apiKey: string;

  constructor(data?: any) {
    if (isNullOrUndefined(data)) {
      data = {};
    }
    this.impressoraPadrao = data.impressoraPadrao;
    this.printFormat = data.printFormat;
    this.pageDimension = data.pageDimension;
    this.numeroVias = data.numeroVias;
    this.apiIntegracao = data.apiIntegracao;
    this.apiKey = data.apiKey;
  }
}

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  constructor(
    private database: AngularFireDatabase,
    private auth: AuthService
  ) {}

  private getObject(empresa?: Empresa): AngularFireObject<Settings> {
    if (isNullOrUndefined(this.auth.currentEmpresa)) {
      throw new Error("Precisa estar logado em uma empresa!");
    }
    return this.database.object<Settings>(
      `empresas/${empresa ? empresa.id : this.auth.currentEmpresa.id}/settings`
    );
  }

  public getIdEmpresa(): string {
    return this.auth.currentEmpresa.id;
  }

  public getSettings(empresa?: Empresa): Observable<Settings> {
    return this.getObject(empresa)
      .valueChanges()
      .pipe(
        take(1),
        map((value) => new Settings(value))
      );
  }

  public hasImpressoraPadrao(empresa?: Empresa): Observable<boolean> {
    if (this.auth.currentEmpresa) {
      return this.getSettings(empresa).pipe(
        mergeMap((settings: Settings) => {
          return of(
            settings.impressoraPadrao && !isEmpty(settings.impressoraPadrao)
          );
        })
      );
    } else {
      return of(false);
    }
  }

  public save(settings: Settings): Observable<any> {
    return from(this.getObject().set(settings));
  }
}
