import {Injectable} from "@angular/core";
import {Repository} from "../../repository/Repository";
import {Abatimento} from "../../models/abatimento.model";
import {AngularFirestore} from "@angular/fire/firestore";
import {OrderBy} from "../../services/firebase/criteria/order-by";
import {AuthService} from "../../modules/login/auth.service";
import {$query, PoMQuery} from "../../services/firebase/criteria/query";
import {Observable} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {Criterion} from "../../services/firebase/criteria/criterion";
import {Empresa} from "../../models/empresa";
import {OrderByDesc} from "../../services/firebase/criteria/order-by-desc";
import {isEmpty, isNotNullOrUndefined} from "../../utils/commons";

@Injectable({
  providedIn: "root"
})
export class AbatimentosService extends Repository<Abatimento> {

  // TODO: Criar uma maneira abstrata de manter os filtros no repository
  // Variáveis dos filtros para manter selecionado ao trocar de telas do sistema
  private _empresaId: string;
  private _month: any;

  get empresaId(): string {
    return this._empresaId;
  }

  set empresaId(value: string) {
    this._empresaId = value;
  }

  get month(): any {
    return this._month;
  }

  set month(value: any) {
    this._month = value;
  }

  constructor(public db: AngularFirestore, public auth: AuthService) {
    super(db, "abatimentos", true);
  }

  getFilteredAbatimentos(criterion?: Criterion): Observable<Abatimento[]> {
    const dataInicial = this._month.startOf("month").toDate();
    const dataFinal = this._month.endOf("month").toDate();

    const filters = $query(
      new Criterion("data", ">=", dataInicial),
      new Criterion("data", "<=", dataFinal)
    );

    if (!isEmpty(criterion)) {
      filters.add(criterion);
    }

    if (isNotNullOrUndefined(this._empresaId)) {
      filters.add(new Criterion("empresa.id", "==", this._empresaId));
    }

    return this.col$(filters);
  }

  getAbatimentosByCurrentEmpresa(): Observable<Abatimento[]> {
    return this.auth.currentEmpresaObservable().pipe(
      mergeMap((empresa: Empresa) => {
        return this.col$($query(new Criterion("empresa.id", "==", empresa.id)));
      })
    );
  }

  col$(queryFn?: PoMQuery): Observable<Abatimento[]> {
    return this.auth.currentUser.pipe(
      mergeMap(user => {
        if (user.isConsultorOrAssistente()) {
          return super.col$(queryFn.add(new Criterion("empresa.consultor.id", "==", user.isConsultor() ? user.id : user.consultorId)));
        } else {
          return super.col$(queryFn);
        }
      })
    );
  }

  saveOrUpdate(item: Abatimento, keepId?: boolean): Observable<void> {
    return this.auth.currentUser.pipe(
      mergeMap(user => {
        item.userId = user.id;
        return super.saveOrUpdate(item, keepId);
      })
    );
  }

  deserialize(value: any): Abatimento {
    return new Abatimento(value);
  }

  protected orderBy(): OrderBy {
    return new OrderByDesc("data");
  }

}
