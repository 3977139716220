import {Cliente} from "./cliente";
import {FormaPagamento} from "./forma-pagamento.model";
import {Bairro} from "./bairro.model";
import {FireModel} from "./fire.model";
import {StatusPedido} from "./status-pedido.enum";
import {Empresa} from "./empresa";
import {PedidoItem} from "./pedido-item.model";
import * as firebase from "firebase";
import {isNullOrUndefined} from "util";
import Timestamp = firebase.firestore.Timestamp;
import {NumberUtils} from "../utils/number-utils";
import {Entregador} from "./entregador.model";

export class Pedido extends FireModel {

  public numero: number;
  public empresa: Empresa;
  public data: Timestamp;
  public status: string; // Enum StatusPedido
  public cliente: Cliente;
  public formaPagamento: FormaPagamento;
  public enderecoEntrega: Endereco;
  public entregador: Entregador;
  public buscarNoBalcao: boolean;
  public valorEntrega: number;
  public qtdeItens: number;
  public total: number;
  public subtotal: number;
  public pontos: number;
  public troco: number;
  public lastUpdate: Timestamp;
  public origem: string;
  public appVersion: string;
  public pedeQueChega: boolean;
  public valorEntregaLojista: number;
  public valorEntregaCancelada: number;
  public motivoEntregaCancelada: string;
  public itens: PedidoItem[];
  public taxaAdicional: number;
  public realtimeAnalysis: RealtimeAnalysis;

  // Mudança de status do pedido ficam registradas nesse array
  public logs: LogStatus[];

  constructor(pedido?: any) {
    super();
    this.itens = [];
    this.logs = [];
    if (pedido) {
      this.id = pedido.id;
      this.numero = pedido.numero;
      this.empresa = new Empresa(pedido.empresa);
      this.data = pedido.data;
      this.status = pedido.status;
      this.cliente = new Cliente(pedido.cliente);
      this.formaPagamento = new FormaPagamento(pedido.formaPagamento);
      this.enderecoEntrega = new Endereco(pedido.enderecoEntrega);
      this.entregador = pedido.entregador ? new Entregador(pedido.entregador) : null;
      this.buscarNoBalcao = pedido.buscarNoBalcao;
      this.valorEntrega = pedido.valorEntrega;
      this.qtdeItens = pedido.qtdeItens;
      this.total = pedido.total;
      this.subtotal = pedido.subtotal;
      this.pontos = pedido.pontos;
      this.troco = pedido.troco;
      this.lastUpdate = pedido.lastUpdate;
      this.origem = pedido.origem;
      this.appVersion = pedido.appVersion;
      this.pedeQueChega = pedido.pedeQueChega;
      this.valorEntregaLojista = pedido.valorEntregaLojista;
      this.valorEntregaCancelada = pedido.valorEntregaCancelada;
      this.motivoEntregaCancelada = pedido.motivoEntregaCancelada;
      this.taxaAdicional = pedido.taxaAdicional ? pedido.taxaAdicional : 0;
      if (pedido.itens) {
        pedido.itens.forEach(item => this.itens.push(new PedidoItem(item)));
      }
      if (pedido.logs) {
        pedido.logs.forEach(log => this.logs.push(new LogStatus(log)));
      }
      this.realtimeAnalysis = isNullOrUndefined(pedido.realtimeAnalysis) ? null : new RealtimeAnalysis(pedido.realtimeAnalysis);
    }
  }

  // Indica que o pedido está pendente
  isPendente(): boolean {
    return this.status === StatusPedido[StatusPedido.PENDENTE];
  }

  // Indica que o pedido é para delivery
  isDelivery(): boolean {
    return this.status === StatusPedido[StatusPedido.APROVADO] && !this.buscarNoBalcao;
  }

  // Indica que o cliente vai buscar o pedido no balcão
  isBuscarPedidoNoBalcao(): boolean {
    return this.status === StatusPedido[StatusPedido.APROVADO] && this.buscarNoBalcao;
  }

  isAprovado(): boolean {
    return this.isBuscarPedidoNoBalcao() || this.isDelivery();
  }

  isPronto(): boolean {
    return this.status === StatusPedido[StatusPedido.DELIVERY] || this.status === StatusPedido[StatusPedido.PRONTO];
  }

  // Indica que o pedido foi concluído
  isConcluido(): boolean {
    return this.status === StatusPedido[StatusPedido.CONCLUIDO];
  }

  isRejeitado(): boolean {
    return this.status === StatusPedido[StatusPedido.REJEITADO];
  }

  isCancelado(): boolean {
    return this.status === StatusPedido[StatusPedido.CANCELADO];
  }

  isCanceladoOrRejeitado(): boolean {
    return this.isRejeitado() || this.isCancelado();
  }

  // Calcula APENAS o valor da comissão a pagar pela empresa, não considerando os PoMs
  getValorComissaoEmpresa(): number {
    if (this.empresa.comissao === 0) return 0;

    // Verificar se o pedido está CANCELADO ou REJEITADO
    if (this.status === StatusPedido[StatusPedido.CANCELADO] || this.status === StatusPedido[StatusPedido.REJEITADO]) {
      return 0;
    }

    // Calcular a comissão sobre o subtotal do pedido
    return NumberUtils.round(this.subtotal * (this.empresa.comissao / 100), 2);
  }

  // Calcula o valor da comissão a pagar pela empresa, já considerando o valor de entrega da Central PQC
  getValorAPagar(): number {
    let valorAPagar = this.getValorComissaoEmpresa();

    // Verificar se o pedido está CANCELADO ou REJEITADO
    if (this.status === StatusPedido[StatusPedido.CANCELADO] || this.status === StatusPedido[StatusPedido.REJEITADO]) {
      return 0;
    }

    // Verificar se tem PoMs
    if (this.pontos) {
      valorAPagar -= this.pontos;
    }

    // Verificar se usa o serviço da Central Pede Que Chega
    if (this.pedeQueChega) {
      // Adiciona o valor da entrega
      valorAPagar += this.valorEntrega;
      // Se tiver valor de entrega do lojista, adiciona também
      if (!isNullOrUndefined(this.valorEntregaLojista)) {
        valorAPagar += this.valorEntregaLojista;
      }
      // Verificar se a entrega foi cancelada
      if (!isNullOrUndefined(this.valorEntregaCancelada)) {
        valorAPagar -= this.valorEntregaCancelada;
      }
    }

    return NumberUtils.round(valorAPagar, 2);
  }

  // Calcula a comissão do consultor
  getValorComissaoConsultor(): number {
    if (isNullOrUndefined(this.empresa.consultor) ||
      isNullOrUndefined(this.empresa.consultor.comissao) ||
      this.empresa.consultor.comissao === 0) {
      return 0;
    }

    // Valor da comissão que a empresa irá pagar
    const valorComissaoEmpresa = this.getValorComissaoEmpresa();

    // Calcula o valor do PoMs (2%)
    const poms = NumberUtils.round(valorComissaoEmpresa * 0.02, 2);

    // Calcula o valor da comissão do consultor
    const valorComissao = (valorComissaoEmpresa - poms) * (this.empresa.consultor.comissao / 100);

    // Arredondar
    return NumberUtils.round(valorComissao, 2);
  }

  getNewStatus(): string {
    switch (this.status) {
      case StatusPedido[StatusPedido.PENDENTE]: {
        return StatusPedido[StatusPedido.APROVADO];
      }

      case (StatusPedido[StatusPedido.APROVADO]): {
        if (this.buscarNoBalcao) {
          return StatusPedido[StatusPedido.PRONTO];
        } else {
          return StatusPedido[StatusPedido.DELIVERY];
        }
      }

      case StatusPedido[StatusPedido.PRONTO]: {
        return StatusPedido[StatusPedido.CONCLUIDO];
      }

      case StatusPedido[StatusPedido.DELIVERY]: {
        return StatusPedido[StatusPedido.CONCLUIDO];
      }
    }
  }

  getMotivoRejeicao(): string {
    if (this.isRejeitado() || this.isCancelado()) {
      const logs = this.logs.filter(log => StatusPedido[log.status] === StatusPedido.REJEITADO || StatusPedido[log.status] === StatusPedido.CANCELADO);
      if (logs && logs.length > 0) {
        return logs[0].motivo;
      }
    }
    return null;
  }
}

export enum TipoPedido {
  BALCAO = "BALCAO",
  DELIVERY = "DELIVERY"
}

export function TipoPedidoToString(tipo: string): string {
  switch (tipo) {
    case "BALCAO":
      return "Balcão";
    case "DELIVERY":
      return "Delivery";
  }
}

export class LogStatus {
  public data: Timestamp;
  public userId: string;
  public userName: string;
  public status: string; // Enum StatusPedido
  public motivo: string;

  constructor(log?: any) {
    if (log) {
      this.data = log.data;
      this.userId = log.userId;
      this.userName = log.userName;
      this.status = log.status;
      this.motivo = log.motivo;
    }
  }

}

class Endereco {
  public id: string;
  public logradouro: string;
  public numero: string;
  public pontoReferencia: string;
  public complemento: string;
  public bairro: Bairro;

  constructor(endereco?: any) {
    if (endereco) {
      this.id = endereco.id;
      this.logradouro = endereco.logradouro;
      this.numero = endereco.numero;
      this.pontoReferencia = endereco.pontoReferencia;
      this.complemento = endereco.complemento;
      this.bairro = new Bairro(endereco.bairro);
    }
  }

}

export enum MotivoRejeicaoTipo {
  outro = "outro",
  pedidoTeste = "pedidoTeste",
  enderecoIncompleto = "enderecoIncompleto",
  suspeitaTrote = "suspeitaTrote",
  suspeitaFraude = "suspeitaFraude",
  localIndisponivel = "localIndisponivel",
  semEntregador = "semEntregador",
  canceladoPeloCliente = "canceladoPeloCliente",
  produtoEmFalta = "produtoEmFalta",
  fimExpediente = "fimExpediente",
  pedidoExpirado = "pedidoExpirado"
}

export interface MotivoRejeicao {
  motivo: string;
  descricao: string;
  mensagem: string;
}

export class RealtimeAnalysis {
  public sessionId: string;
  public status: RealtimeStatus;
  public score: number;
  public requestId: string;

  constructor(data?: any) {
    if (data) {
      this.sessionId = data.sessionId;
      this.status = data.status;
      this.score = data.score;
      this.requestId = data.requestId;
    }
  }
}

export enum RealtimeStatus {
  // Aprovação automática
  APA = "APA",
  // Reprovação automática
  RPA = "RPA",
  // Reprovação política
  RPP = "RPP",
  // Aprovação política
  APP = "APP",
}
