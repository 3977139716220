import {Injectable} from "@angular/core";
import {Repository} from "../../repository/Repository";
import {Complemento} from "../../models/produto.model";
import {AngularFirestore} from "@angular/fire/firestore";

@Injectable()
export class ComplementoService extends Repository<Complemento> {

  public static readonly collectionName = "produtos-complementos";

  constructor(db: AngularFirestore) {
    super(db, ComplementoService.collectionName, true);
  }

  deserialize(value: any): Complemento {
    return new Complemento(value);
  }
}
