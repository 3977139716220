import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {forkJoin, Observable, of} from "rxjs";
import {map, mergeMap, take} from "rxjs/operators";
import {AppUser, UserType} from "../../models/appUser";
import {Repository} from "../../repository/Repository";
import {Criterion} from "../../services/firebase/criteria/criterion";
import {OrderBy} from "../../services/firebase/criteria/order-by";
import {OrderByAsc} from "../../services/firebase/criteria/order-by-asc";
import {$query, PoMQuery} from "../../services/firebase/criteria/query";
import {AuthService} from "../../modules/login/auth.service";
import {Empresa} from "../../models/empresa";
import {AngularFireFunctions} from "@angular/fire/functions";
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../../utils/commons";

@Injectable()
export class UsersService extends Repository<AppUser> {

  constructor(private dataBase: AngularFirestore,
              public authService: AuthService,
              public fns: AngularFireFunctions) {
    super(dataBase, "users", true);
  }

  col$(queryFn?: PoMQuery): Observable<AppUser[]> {
    return super.col$(queryFn);
  }

  public findByEmail(email: string): Observable<AppUser> {
    return this.col$($query(new Criterion("email", "==", email))).pipe(
      take(1),
      map(users => (users && users.length > 0 ? users[0] : undefined))
    );
  }

  remove(id: string): Observable<void> {
    return this.fns.httpsCallable("call-users-remove")({id: id});
  }

  // Função utlizada para buscar os Administradores e consultores no cadastro de empresa e relatório de comissões
  public getAdministradoresAndConsultores(): Observable<AppUser[]> {
    return this.col$().pipe(
      mergeMap((consultores: AppUser[]) => {
        return this.authService.currentUser.pipe(mergeMap((currentUser: AppUser) => {
          consultores = consultores.filter((consultor: AppUser) => {
            if (currentUser.isConsultor()) {
              return consultor.id === currentUser.id;
            } else if (currentUser.isAssistente()) {
              return consultor.id === currentUser.consultorId;
            } else {
              return consultor.userType === UserType.Administrador || consultor.userType === UserType.Consultor;
            }
          });
          return of(consultores);
        }));
      })
    );
  }

  // Busca os usuários conforme as permissões de visualização de usuários que o tipo de usuário possui
  getUsersByUserLoggedType(queryFn: PoMQuery = new PoMQuery()): Observable<AppUser[]> {
    if (isNotNullOrUndefined(this.authService.currentEmpresa)) {
      return this.col$(queryFn.add(new Criterion("empresas", "array-contains", this.authService.currentEmpresa.id)));
    } else {
      return this.authService.currentUser.pipe(mergeMap((currentUser: AppUser) => {
        if (currentUser.isAdministrador()) {
          return this.col$(queryFn).pipe(mergeMap((users: AppUser[]) => {
            users = users.filter((user: AppUser) => {
              return !user.isLojista();
            });
            users.sort((a,b) => a.name.localeCompare(b.name));
            return of(users);
          }));
        } else if (currentUser.isConsultorOrAssistente()) {
          return this.getAssistentesByUser(currentUser);
        }
      }));
    }
  }

  // Busca todos os Assistentes de um usuário Administrador ou Assistente
  public getAssistentesByUser(currentUser: AppUser): Observable<AppUser[]> {
    if (currentUser.isConsultorOrAssistente()) {
      return this.col$($query(new Criterion("consultorId", "==", currentUser.isConsultor() ? currentUser.id : currentUser.consultorId)))
        .pipe(mergeMap((users: AppUser[]) => {
          if (currentUser.isConsultor()) {
            users.push(currentUser);
            return of(users);
          } else if (currentUser.isAssistente()) {
            return this.getById(currentUser.consultorId).pipe(mergeMap((consultor: AppUser) => {
              users.push(consultor);
              return of(users);
            }));
          }
        }));
    }
  }

  public getAllConsultores(): Observable<AppUser[]> {
    return this.col$($query(new Criterion("userType", "==", UserType.Consultor)));
  }

  public getUsersByIds(ids: string[]): Observable<AppUser[]> {
    if (!isEmpty(ids)) {
      return forkJoin(
        ids.map((id: string) => {
          return super.getById(id);
        })
      );
    }
    return of();
  }

  deserialize(value: any): AppUser {
    return new AppUser(value);
  }

  protected orderBy(): OrderBy {
    return new OrderByAsc("name");
  }

  public setEmpresaSelecionada(empresa?: Empresa): Observable<any> {
    return this.authService.currentUser.pipe(
      mergeMap((user: AppUser) => {
        return this.getDoc(user.id).update(
          {empresaSelectedId: empresa ? empresa.id : null}
        );
      })
    );
  }

  usersIsEmpty(empresa?: Empresa): Observable<boolean> {
    return (empresa ? of(empresa) : this.authService.currentEmpresaObservable())
      .pipe(mergeMap(_empresa => {
        if (isNullOrUndefined(_empresa) || isNullOrUndefined(_empresa.id)) {
          return of(false);
        }
        return this.col$($query(new Criterion("empresas", "array-contains", _empresa.id)))
          .pipe(map((data) => !(data && data.length > 0)));
      }));
  }
}
