import {TdLoadingService} from "@covalent/core/loading";
import {Injectable} from "@angular/core";
// import {LoadingTopbarService} from "../../components/layout/loading-topbar/service/loading-topbar.service";
import {LoadingTopbarService} from "../../modules/shared/services/loading-topbar.service";
import {Observable} from "rxjs/Rx";
import {LoaderState} from "../../modules/shared/components/layout/loading-topbar/loading-topbar.component";

@Injectable()
export class LoadingService {

  constructor(private tdLoading: TdLoadingService,
              private loadingTopBar: LoadingTopbarService) {
  }

  register(name?: string, registers?: number, hideTopBar?: boolean): boolean {
    if (!hideTopBar) {
      this.showTopBar();
    }
    return this.tdLoading.register(name, registers);
  }

  timeOut(resolve: string, time?: number): void {
    setTimeout(() => {
      this.hideTopBar();
      this.tdLoading.resolve(resolve);
    }, time ? time : 1000);
  }

  showTopBar(): void {
    this.loadingTopBar.show();
  }

  hideTopBar(): void {
    this.loadingTopBar.hide();
  }

  isLoadingTopBar(): Observable<boolean> {
    return this.loadingTopBar.loaderState
      .map((loader: LoaderState) => loader.show);
  }
}
