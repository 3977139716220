import {Component, OnInit} from "@angular/core";
import {Observable, of} from "rxjs";
import {EmpresaComissaoService} from "../../../services/empresa-comissao.service";
import {AcoesComissao, EmpresaComissao, LogActivity, StatusComissao} from "../../../models/empresa-comissao.model";
import {FormControl} from "@angular/forms";
import {groupBy, map, mergeMap, take, toArray} from "rxjs/operators";
import {AbatimentosService} from "../../abatimentos/abatimentos.service";
import {Abatimento} from "../../../models/abatimento.model";
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";
import {Criterion} from "../../../services/firebase/criteria/criterion";
import {InvoiceIuguService} from "../../../services/iugu/invoice-iugu.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {AuthService} from "../../../modules/login/auth.service";
import {AppUser} from "../../../models/appUser";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {GerarFaturaDialogComponent} from "../gerar-fatura-dialog/gerar-fatura-dialog.component";
import {EmpresaService} from "../../empresa/empresa.service";
import {PerfilService} from "../../perfil-acesso/perfil.service";
import {Role} from "../../../models/perfil-acesso.model";
import {AngularFireFunctions} from "@angular/fire/functions";
import {EnviarNfseDialogComponent} from "../enviar-nfse-dialog/enviar-nfse-dialog.component";
import {NfseService} from "../../../services/nfse.service";
import {NfseResponseStatus} from "../../../models/nfse.model";
import * as firebase from "firebase";
import * as moment from "moment";
import Timestamp = firebase.firestore.Timestamp;

export class EmpresaComissoesByMonth {
  dataComissao: moment.Moment;
  empresaComissoes: EmpresaComissao[];
  empresaAbatimentos: Abatimento[];

  constructor(data: any = {}) {
    this.dataComissao = data.dataComissao;
    this.empresaComissoes = isNotNullOrUndefined(data.empresaComissoes) ? data.empresaComissoes.map(empresaComissao => new EmpresaComissao(empresaComissao)) : null;
    this.empresaAbatimentos = isNotNullOrUndefined(data.empresaAbatimentos) ? data.empresaAbatimentos.map(empresaAbatimento => new Abatimento(empresaAbatimento)) : null;
  }

  addAbatimento(abatimento: Abatimento): void {
    if (isNullOrUndefined(this.empresaAbatimentos)) {
      this.empresaAbatimentos = [abatimento];
    } else {
      this.empresaAbatimentos.push(abatimento);
    }
  }
}

@Component({
  selector: "app-faturas-list",
  templateUrl: "./faturas-list.component.html",
  styleUrls: ["./faturas-list.component.scss"]
})
export class FaturasListComponent implements OnInit {

  nfseStatus = NfseResponseStatus;
  StatusComissao = StatusComissao;
  statusSelected: FormControl = new FormControl();
  anoSelected: FormControl = new FormControl(new Date().getFullYear());
  empresaComissoesByMonth$: Observable<EmpresaComissoesByMonth[]>;
  disablePagarButton: boolean = false;
  buttonGerarNfseDisabled = false;
  currentUser$: Observable<AppUser>;
  companyHavePendingInvoice: boolean = false;
  Role = Role;
  years = [];

  constructor(public empresaComissaoService: EmpresaComissaoService,
              public abatimentosService: AbatimentosService,
              public loadingService: LoadingService,
              public dialogService: DialogService,
              public authService: AuthService,
              public invoiceIuguService: InvoiceIuguService,
              public dialog: MatDialog,
              public empresaService: EmpresaService,
              public perfil: PerfilService,
              public fns: AngularFireFunctions,
              public nfseService: NfseService) {
    this.currentUser$ = authService.currentUser;

    // Buscar todos os anos a partir de 2019 até o ano atual
    for (let ano = 2019; ano <= new Date().getFullYear(); ano++) {
      this.years.push(ano);
    }

    this.buscarComissoes();
  }

  ngOnInit() {
  }

  buscarComissoes() {
    const criterions: Criterion[] = [];

    if (isNotNullOrUndefined(this.statusSelected.value)) {
      criterions.push(new Criterion("status", "==", this.statusSelected.value));
    }

    if (isNotNullOrUndefined(this.anoSelected.value)) {
      const dataBefore = moment(new Date().setFullYear(this.anoSelected.value)).startOf("year");
      const yearBefore = Timestamp.fromDate(dataBefore.toDate());
      criterions.push(new Criterion("dataInicial", ">=", yearBefore));
      const dataAfter = moment(new Date().setFullYear(this.anoSelected.value)).endOf("year");
      const yearAfter = Timestamp.fromDate(dataAfter.toDate());
      criterions.push(new Criterion("dataInicial", "<=", yearAfter));
    }

    this.empresaComissoesByMonth$ = this.empresaComissaoService.getEmpresaComissoesCurrentEmpresa(criterions).pipe(
      take(1),
      mergeMap(res => res),
      groupBy(empresaComissao => moment(empresaComissao.dataInicial.toDate())), // Agrupa as comissões da empresa por mês
      mergeMap(group => {
        return group.pipe(toArray()).pipe(
          mergeMap(res => {
            // Retorna os agrupamentos como um objeto EmpresaComissoesByMonth faltando os abatimentos
            return of(new EmpresaComissoesByMonth({dataComissao: group.key, empresaComissoes: res}));
          })
        );
      }),
      toArray(),
      mergeMap(empresaComissoes => {
        this.companyHavePendingInvoice = empresaComissoes.some(comissoesbyMonth => {
          return comissoesbyMonth.empresaComissoes.some(comissao => {
            return (comissao.status === StatusComissao[StatusComissao.ABERTO]) && isNullOrUndefined(comissao.iuguInvoiceId) && isNullOrUndefined(comissao.iuguInvoiceUrl);
          });
        });
        return this.abatimentosService.getAbatimentosByCurrentEmpresa().pipe( // Busca os abatimentos da empresa
          take(1),
          mergeMap(abatimentos => {
            for (const empresaComissao of empresaComissoes) {
              for (const abatimento of abatimentos) {
                if (abatimento.valor !== 0) {
                  const isSameMonth = empresaComissao.dataComissao.month() === moment(abatimento.data.toDate()).month();
                  const hasSameComissaoId = !!empresaComissao.empresaComissoes.find(comissao => comissao.id === abatimento.comissaoId);
                  if (isSameMonth) {
                    empresaComissao.addAbatimento(abatimento);
                  } else if (hasSameComissaoId) {
                    empresaComissao.addAbatimento(abatimento);
                  } else {
                    if (abatimento.comissaoId === null
                      && moment().isAfter(abatimento.data.toDate())
                      && empresaComissoes.indexOf(empresaComissao) === 0) {
                      empresaComissao.addAbatimento(abatimento);
                    }
                  }
                }
              }
            }
            return of(empresaComissoes);
          })
        );
      })
    );
  }

  statusComissao() {
    return Object.keys(StatusComissao);
  }

  compareWithStatus(s1: StatusComissao, s2: StatusComissao): boolean {
    return s1 === s2;
  }

  getStatusComissao(statusComissao: string): string {
    switch (statusComissao) {
      case StatusComissao[StatusComissao.ABERTO]: {
        return "Aberto";
      }

      case StatusComissao[StatusComissao.FECHADO]: {
        return "Pago";
      }

      default: {
        return null;
      }
    }
  }

  getAbatimentosByComissao(abatimentos: Abatimento[], empresaComissao: EmpresaComissao): Abatimento[] {
    if (abatimentos) {
      return abatimentos.filter(abatimento => {

        // Se o abatimento tiver vinculado a uma comissão
        if (!isEmpty(abatimento.comissaoId)) {
          // Então a comparação deve ser feita apenas pelo ID
          return abatimento.comissaoId === empresaComissao.id;
        }

        const initialDate = moment(empresaComissao.dataInicial.toDate());
        const finalDate = moment(empresaComissao.dataFinal.toDate());

        // Verificar se o abatimento está dentro do período da comissão
        return moment(abatimento.data.toDate()).isBetween(initialDate, finalDate, "day", "[]");
      });
    } else {
      return abatimentos;
    }
  }

  showInvoice(url: string) {
    const win = window.open(url);
    // Caso o navegador bloqueie o pop-up
    if (!win || win.closed || typeof win.closed === "undefined") {
      this.dialogService
        .messageDialog()
        .message("Seu navegador está bloqueando a abertura da fatura. Por favor, habilite os pop-ups no site e tente novamente!")
        .show();
    }
  }

  getValorTotalComissao(empresaComissao, empresaAbatimentos): number {
    const valorComissao = this.getValorDeVendasComissaoByDate(empresaComissao) + empresaComissao.delivery - empresaComissao.pontos;
    const abatimentosByComissao = this.getAbatimentosByComissao(empresaAbatimentos, empresaComissao);
    if (isEmpty(abatimentosByComissao)) {
      return valorComissao;
    } else {
      return valorComissao + abatimentosByComissao.map(abatimento => Number(abatimento.valor.toFixed(2)))
        .reduce((acc, curr) => acc + curr);
    }
  }

  pagarFatura(comissao: EmpresaComissao, segundaVia?: boolean) {
    // Verificar se a comissão tem um ID de fatura
    if (isEmpty(comissao.iuguInvoiceId)) {

      this.dialogService
        .messageDialog()
        .message("ID da fatura não encontrado!")
        .show();

    } else {

      this.loadingService.showTopBar();
      this.disablePagarButton = true;
      this.invoiceIuguService.checkInvoiceAndGenerate(comissao.iuguInvoiceId, segundaVia)
        .subscribe(response => {
          this.loadingService.hideTopBar();
          this.disablePagarButton = false;
          if (response && response.data) {
            if (response.data.expirada) {
              this.faturaExpiradaDialog(comissao, response.data.iuguInvoiceUrl, response.message);
            } else {
              this.showInvoice(response.data.iuguInvoiceUrl);
            }
            if (segundaVia) {
              this.buscarComissoes();
            }
          }
        }, err => {
          this.loadingService.hideTopBar();
          this.disablePagarButton = false;
          this.dialogService
            .messageDialog()
            .message(err)
            .show();
        });

    }
  }

  faturaExpiradaDialog(comissao: EmpresaComissao, url: string, message: string) {
    this.dialogService.confirmDialog()
      .title("Atenção")
      .message(message)
      .acceptButton("Visualizar")
      .cancelButton("Segunda Via")
      .show().subscribe((accept: boolean) => {
      if (accept) {
        this.showInvoice(url);
      }
      if (accept === false) {
        this.pagarFatura(comissao, true);
      }
    });
  }

  getNovaViaData(data: any) {
    return moment(data.toDate()).format("llll");
  }

  getLogMessage(log: LogActivity) {
    if (log.acao === AcoesComissao[AcoesComissao.DAR_BAIXA]) {
      return `Baixa executada por ${log.userName} em ${this.getNovaViaData(log.data)}. Motivo: ${log.motivo}`;
    } else if (log.acao === AcoesComissao[AcoesComissao.GERAR_SEGUNDA_VIA_EXPIRADA]) {
      return `Segunda via de fatura gerada por ${log.userName} em ${this.getNovaViaData(log.data)}`;
    } else {
      return `Fatura parcial gerada por ${log.userName} em ${this.getNovaViaData(log.data)}`;
    }
  }

  gerarFatura() {
    this.dialog.open(GerarFaturaDialogComponent, <MatDialogConfig>{
      width: "600px",
      data: this.invoiceIuguService.generateParcialInvoice(this.authService.currentEmpresa.cnpj, false),
      disableClose: true
    }).afterClosed().pipe(map(value => {
      if (value) {
        this.companyHavePendingInvoice = false;
        return this.buscarComissoes();
      }
      return of;
    })).subscribe(() => {
      },
      error => {
        this.dialogService
          .messageDialog()
          .message(error.message)
          .show();
      }
    );
  }

  enviarNfse(nfse: any): void {
    this.dialog.open(EnviarNfseDialogComponent, <MatDialogConfig>{
      width: "600px",
      data: nfse,
      disableClose: true
    });
  }

  getValorDeVendasComissaoByDate(comissao: EmpresaComissao): number {
    if (moment(comissao.dataInicial.toDate()).isSameOrAfter(moment("2020-08-01", "YYYY-MM-DD"))) {
      return comissao.comissao;
    } else {
      return comissao.valor;
    }
  }

  public gerarNfse() {
    const cnpj = this.authService.currentEmpresa.cnpj;

    if (isEmpty(cnpj)) {
      return;
    }

    this.dialogService.confirmDialog()
      .acceptButton("Gerar NFS-e")
      .cancelButton("Cancelar")
      .message("Deseja realmente gerar uma NFS-e?")
      .show()
      .subscribe(accept => {
        if (accept) {
          this.loadingService.showTopBar();
          this.buttonGerarNfseDisabled = true;

          return this.nfseService.create(cnpj).subscribe(() => {
          }, (error) => {
            this.buttonGerarNfseDisabled = false;
            this.loadingService.hideTopBar();
            this.dialogService.messageDialog()
              .message(error.message)
              .show();
          }, () => {
            this.buttonGerarNfseDisabled = false;
            this.loadingService.hideTopBar();
            this.buscarComissoes();
          });
        } else {
          this.loadingService.hideTopBar();
          this.buttonGerarNfseDisabled = false;
        }
      });
  }
}
