import {Component, EventEmitter, forwardRef, Host, Input, OnInit, Optional, Output, SkipSelf} from "@angular/core";
import {AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {CropperService} from "../../../services/cropper.service";
import {ConfigCropper} from "../../../../../models/config-cropper.model";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: "app-image",
  templateUrl: "./image.component.html",
  styleUrls: ["./image.component.scss"],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ImageComponent),
    multi: true
  }]
})
export class ImageComponent implements OnInit, ControlValueAccessor {

  // Config de tamanho da imagem
  @Input()
  config: {
    width: string,
    height: string
  } = {
    width: "300px",
    height: "225px"
  };

  // Image Url
  imageUrl: string;

  @Input()
  formControlName: string;

  @Input()
  configCropper: ConfigCropper;

  @Output()
  deleteImage = new EventEmitter();

  // Evento de mudança de valor do componente
  onChange: any;

  // FormControl do componente dentro de algum formulário
  control?: AbstractControl;

  constructor(private cropperService: CropperService,
              private sanitizer: DomSanitizer,
              @Optional() @Host() @SkipSelf()
              private controlContainer: ControlContainer) {
  }

  ngOnInit() {
    if (this.controlContainer) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get(this.formControlName);
      }
    }
  }

  chooseImage() {
    this.cropperService.open(null, this.createCropperSettings(), (imageUrl) => {
      if (imageUrl) {
        this.imageUrl = imageUrl;
        this.onChange(this.imageUrl);
      }
    });
  }

  get imageStyle() {
    return {
      "width": `${this.config.width}`,
      "height": `${this.config.height}`,
      "background-image": "url(" + this.imageUrl + ")"
    };
  }

  createCropperSettings(): ConfigCropper {
    if (this.configCropper) {
      return this.configCropper;
    } else {
      return new ConfigCropper({
        width: 500,
        height: 375,
        canvasWidth: 500,
        canvasHeight: 375,
        aspectRatio: 4 / 3
      });
    }
  }

  deletarImagem() {
    this.deleteImage.emit();
  }

  /**
   * Writes a new value to the element.
   *
   * This method will be called by the forms API to write to the view when programmatic
   * (model -> view) changes are requested.
   *
   * Example implementation of `writeValue`:
   *
   * ```ts
   * writeValue(value: any): void {
   *   this._renderer.setProperty(this._elementRef.nativeElement, 'value', value);
   * }
   * ```
   */
  writeValue(obj: any): void {
    this.imageUrl = obj;
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   */
  registerOnTouched(fn: any): void {

  }
}

