import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {CategoriaProduto} from "../../models/categoria.model";
import {OrderByAsc} from "../../services/firebase/criteria/order-by-asc";
import {AuthService} from "../../modules/login/auth.service";
import {RepositoryByEmpresa} from "../../repository/repository-by-empresa";
import {OrderBy} from "../../services/firebase/criteria/order-by";
import {from, Observable, of} from "rxjs";
import {mergeMap, take} from "rxjs/operators";
import * as firebase from "firebase";
import {isNullOrUndefined} from "util";
import {AngularFireFunctions} from "@angular/fire/functions";
import WriteBatch = firebase.firestore.WriteBatch;

@Injectable()
export class CategoriaProdutoService extends RepositoryByEmpresa<CategoriaProduto> {

  constructor(private dataBase: AngularFirestore, auth: AuthService, public fns: AngularFireFunctions) {
    super(dataBase, auth, "categorias-produto", true);
  }

  deserialize(value: any): CategoriaProduto {
    return new CategoriaProduto(value);
  }

  saveOrUpdate(item: CategoriaProduto, keepId?: boolean): Observable<void> {
    return super.saveOrUpdate(item, keepId).pipe(
      mergeMap(() => {
        // Se o item for novo, ou seja, não tiver uma ordem na lista
        if (isNullOrUndefined(item.ordem)) {
          // Busca os registros e reordena todos
          return super.col$().pipe(
            take(1),
            mergeMap((categorias: CategoriaProduto[]) => {
              const batch: WriteBatch = this.dataBase.firestore.batch();
              categorias.forEach((categoria: CategoriaProduto, index) => {
                batch.update(categoria.ref, {"ordem": index});
              });
              return from(batch.commit());
            })
          );
        } else {
          return of(null);
        }
      }));
  }

  reordenar(categorias: CategoriaProduto[]) {
    const batch: WriteBatch = this.dataBase.firestore.batch();

    categorias.forEach(categoria => {
      batch.update(this.getDoc(categoria.id).ref, {ordem: categoria.ordem});
    });

    return batch.commit();
  }

  remove(id: string): Observable<void> {
    return this.fns.httpsCallable("call-categorias-remove")({id: id});
  }

  protected orderBy(): OrderBy {
    return new OrderByAsc("ordem");
  }

}
