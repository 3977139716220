import {Bairro} from "./bairro.model";

export class DeliveryBairro {

  static makeNew(delivery: any): DeliveryBairro {
    if (delivery instanceof DeliveryBairro) {
      return delivery;
    } else {
      return new DeliveryBairro(
        (delivery instanceof Bairro) ? delivery : new Bairro(delivery.bairro),
        delivery.valorEntrega,
        delivery.bloqueado ? delivery.bloqueado : false,
        delivery.id ? delivery.id : null);
    }
  }

  static makeNewList(bairros: any[] = []): DeliveryBairro[] {
    return bairros.map(bairro => DeliveryBairro.makeNew(bairro));
  }

  constructor(public bairro: Bairro,
              public valorEntrega: number,
              public bloqueado: boolean,
              public idPedeQueChega: string) {
  }

  toAny(): any {
    const a: any = {};
    if (this.bairro) a.bairro = this.bairro.toAny();
    a.valorEntrega = this.valorEntrega;
    a.bloqueado = this.bloqueado ? this.bloqueado : false;
    a.idPedeQueChega = a.idPedeQueChega ? this.idPedeQueChega : null;
    return a;
  }

}
