import {Injectable} from "@angular/core";
import {AngularFireAuth} from "@angular/fire/auth";
import {Router} from "@angular/router";
import {AppUser} from "../../models/appUser";
import {isNotNullOrUndefined, isNullOrUndefined} from "../../utils/commons";
import {DialogService} from "../../services/dialog/dialog.service";
import {Empresa} from "../../models/empresa";
import {from, Observable, of, Subject} from "rxjs";
import {finalize, map, mergeMap, tap} from "rxjs/operators";
import * as firebase from "firebase";
import {AngularFireDatabase} from "@angular/fire/database";
import {EmpresaAuthService} from "../../components/empresa/empresa-auth.service";
import {UsersAuthService} from "../../components/users/users-auth.service";
import {BehaviorSubject} from "rxjs/Rx";
import {DeviceDetectorService} from "ngx-device-detector";
import Persistence = firebase.auth.Auth.Persistence;
import {AngularFirestoreSecondary} from "../../services/firebase/angular-firestore-secondary";

@Injectable({
  providedIn: "root"
})
export class AuthService {

  // Dados do usuário
  private _userLogado: AppUser;
  private userSubject = new Subject<AppUser>();

  // Dados da empresa
  private _empresa: Empresa;
  private empresaSubject = new BehaviorSubject<Empresa>(null);

  constructor(private firebaseAuth: AngularFireAuth,
              private firebaseAuthSecondary: AngularFirestoreSecondary,
              private database: AngularFireDatabase,
              private router: Router,
              private dialog: DialogService,
              private userService: UsersAuthService,
              private empresaService: EmpresaAuthService,
              public deviceDetector: DeviceDetectorService) {


    // Monitorar o estado do usuário
    this.firebaseAuth.authState
      .pipe(
        mergeMap((user) => {
          if (user && !user.emailVerified) {
            return this.dialog
              .confirmDialog()
              .message("Você ainda não validou sua conta. Acesse sua caixa de e-mail e clique no link para ativação!")
              .acceptButton("Re-enviar e-mail")
              .cancelButton("Fechar")
              .show()
              .pipe(mergeMap(response => {
                  if (response) {
                    // Enviar um e-mail de verificação da conta
                    return from(user.sendEmailVerification());
                  }
                  return of(null);
                }),
                mergeMap(() => {
                  // Deslogar
                  return this.logout();
                }),
                map(() => null));
          }
          return of(user);
        }),
        mergeMap(user => {
          if (!isNullOrUndefined(user)) {
            return this.userService.findByEmail(user.email)
              .pipe(
                mergeMap((_user: AppUser) => {
                  if (isNullOrUndefined(_user)) {
                    this.showMessage("Seu usuário não está autorizado a acessar o sistema.");
                    return of();
                  }
                  if (user.emailVerified && !_user.emailVerificado) {
                    // Atualizar o campo de email verificado
                    return from(this.userService.getDoc(_user.id).update({
                      emailVerificado: true
                    })).pipe(
                      // Retornar o usuário
                      map(() => _user)
                    );
                  }
                  return of(_user);
                })
              );
          } else {
            return of(null);
          }
        }),
        // Atualizar o usuário com a última data de login
        mergeMap((_user: AppUser) => {
          if (!isNullOrUndefined(_user)) {
            return this.userService.setLastLogin(_user).pipe(map(() => _user));
          }
          return of(null);
        }),
        mergeMap((_user: AppUser) => {
          if (!isNullOrUndefined(_user)) {
            let empresaObservable: Observable<Empresa>;

            if (!isNullOrUndefined(this.currentEmpresa)) {
              empresaObservable = of(this.currentEmpresa);
            } else if (!isNullOrUndefined(_user.empresaSelectedId)) {
              empresaObservable = this.empresaService.getById(_user.empresaSelectedId);
            } else if (isNotNullOrUndefined(_user.empresas) && _user.empresas.length > 0) {
              empresaObservable = this.empresaService.getById(_user.empresas[0]);
            } else {
              empresaObservable = of(null);
            }

            return empresaObservable.pipe(
              tap((empresa: Empresa) => {
                if (!isNullOrUndefined(empresa)) {
                  this.empresaSubject.next(empresa);
                }
              }),
              map(() => _user)
            );
          }
          return of(null);
        }),
        mergeMap((_user: AppUser) => {
          if (!isNullOrUndefined(_user)) {
            if (_user.isLojista()) {
              return from(
                Promise.all([this.firebaseAuthSecondary.firestore.app.auth().setPersistence(Persistence.LOCAL),
                  this.firebaseAuth.auth.setPersistence(Persistence.LOCAL)
                ])
              ).pipe(
                map(() => _user)
              );
            } else {
              return of(_user);
            }
          } else {
            return of(null);
          }
        })
      )
      .subscribe(_user => {
        if (isNullOrUndefined(_user)) {
          // Limpar o usuário
          this.userSubject.next();
          // Limpar a empresa
          this.empresaSubject.next(null);
          // Se deslogar o usuário, redireciona para o login
          this.navigateToLogin();
        } else {
          // Verificar se o usuário está ativo no sistema
          if (_user.inactive) {
            this.showMessage("Você não tem permissão para acessar o sistema!");
          } else {
            // Manter o usuário
            this.userSubject.next(_user);
            // Tudo certo, #partiu
            this.navigateToHome();
          }
        }

      }, error => this.showMessage(error));

    // Atribuir o usuário
    this.userSubject.asObservable().subscribe(user => {
      this._userLogado = user;
    });

    // Atribuir a empresa
    this.empresaSubject.asObservable().subscribe(empresa => this._empresa = empresa);
  }

  private showMessage(message: string) {
    this.dialog
      .messageDialog()
      .message(message)
      .show()
      .subscribe(() => {
        // Desconectar
        this.logout().subscribe();
      });
  }

  navigateToHome() {
    // Navega para Home apenas se estiver na tela de Login
    if (this.router.url === "/login") {
      this.router.navigate(["/home"]);
    }
  }

  private navigateToLogin() {
    this.router.navigate(["/login"]);
  }

  login(credential: any): Observable<any> {
    return from(this.firebaseAuth.auth.setPersistence(Persistence.SESSION))
      .pipe(
        // Fazer login
        mergeMap(() => from(this.firebaseAuth.auth.signInWithEmailAndPassword(credential.email, credential.password))),
        mergeMap(() => from(this.firebaseAuthSecondary.firestore.app.auth().signInWithEmailAndPassword(credential.email, credential.password)
        )));
  }

  logout(): Observable<any> {
    return (!isNullOrUndefined(this.user) ?
      from(this.userService.getDoc(this.user.id).update({
        empresaSelectedId: null
      })) :
      of(null))
      .pipe(
        // Sair do Firebase Auth
        mergeMap(() => from(this.firebaseAuthSecondary.firestore.app.auth().signOut())),
        mergeMap(() => from(this.firebaseAuth.auth.signOut())),
        // Redirecionar para o login da aplicação
        finalize(() => this.navigateToLogin())
      );
  }

  recover(credential: any): Promise<any> {
    return this.firebaseAuth.auth.sendPasswordResetEmail(credential.email);
  }

  get user(): AppUser {
    if (this._userLogado) {
      return this._userLogado;
    }
  }

  get currentUser(): Observable<AppUser> {
    if (this._userLogado) {
      return of(this._userLogado);
    } else {
      return this.userSubject.asObservable();
    }
  }

  currentEmpresaObservable(): Observable<Empresa> {
    if (this._empresa) {
      return of(this._empresa);
    } else {
      return this.empresaSubject.asObservable();
    }
  }

  get currentEmpresa(): Empresa {
    return this._empresa;
  }

  set currentEmpresa(empresa: Empresa) {
    this.empresaSubject.next(empresa);
  }

}
