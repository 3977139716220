import {ChangeDetectorRef, Component, OnInit, ChangeDetectionStrategy} from "@angular/core";
import {PageService} from "../../../services/page.service";
import {Abatimento, TipoAbatimento, TipoAbatimentoToString} from "../../../models/abatimento.model";
import {AbatimentosService} from "../abatimentos.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";
import {DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {Moment} from "moment";
import {MatDatepicker} from "@angular/material/datepicker";
import {FormControl} from "@angular/forms";
import * as moment from "moment";
import {Empresa} from "../../../models/empresa";
import {EmpresaService} from "../../empresa/empresa.service";
import {tap} from "rxjs/operators";
import {isNotNullOrUndefined} from "../../../utils/commons";
import {isNullOrUndefined} from "util";
import {Criterion} from "../../../services/firebase/criteria/criterion";

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-abatimentos-list",
  templateUrl: "./abatimentos-list.component.html",
  styleUrls: ["./abatimentos-list.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class AbatimentosListComponent extends PageService<Abatimento> implements OnInit {

  abatimentos$: Observable<Abatimento[]>;

  tipoAbatimentoToString = TipoAbatimentoToString;
  readonly todosTipos: string = "TODOS";
  tipoAbatimento: string = this.todosTipos;

  dataFormControl = new FormControl(moment().startOf("month"));
  empresaSelected: Empresa;

  totalAcrescimos: number;
  totalDescontos: number;
  totalAbatimentos: number;

  constructor(public service: AbatimentosService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              public empresaService: EmpresaService) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/abatimentos");

    // Seta o mês selecionado anteriormente no datepicker
    if (isNotNullOrUndefined(this.service.month)) {
      this.dataFormControl.patchValue(this.service.month);
    }

    this.buscar(this.tipoAbatimento);
  }

  ngOnInit() {
  }

  protected newItem(): Abatimento {
    return undefined;
  }

  getValorConfigs(valor): { color: string, tooltipMessage: string } {
    if (valor && !isNaN(valor)) {
      if (valor < 0) {
        return {
          color: "red",
          tooltipMessage: "Desconto"

        };
      } else {
        return {
          color: "blue",
          tooltipMessage: "Acréscimo"
        };
      }
    }
  }

  buscar(tipoAbatimento: string) {

    if (isNullOrUndefined(this.empresaSelected)) {
      this.service.empresaId = null;
    } else {
      this.service.empresaId = this.empresaSelected.id;
    }

    let criterions: Criterion;

    if (tipoAbatimento !== "TODOS") {
      criterions = new Criterion("tipo", "==", tipoAbatimento);
    }

    this.service.month = this.dataFormControl.value;

    this.abatimentos$ = this.service.getFilteredAbatimentos(criterions).pipe(
      tap(abatimentos => {
        this.totalAbatimentos = 0;
        this.totalAcrescimos = 0;
        this.totalDescontos = 0;
        abatimentos.forEach(abatimento => {

          if (abatimento.valor > 0) {
            this.totalAcrescimos += abatimento.valor;
          } else {
            this.totalDescontos += abatimento.valor;
          }
        });
        this.totalAbatimentos = this.totalAcrescimos + this.totalDescontos;
      })
    );
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.dataFormControl.value;
    ctrlValue.year(normalizedYear.year()).startOf("day");
    this.dataFormControl.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<any>) {
    const ctrlValue = this.dataFormControl.value;
    ctrlValue.month(normalizedMonth.month()).startOf("day");
    this.dataFormControl.setValue(ctrlValue);
    datepicker.close();
    this.buscar(this.tipoAbatimento);
  }

  tiposAbatimento(): Array<string> {
    return Object.keys(TipoAbatimento);
  }

  onTipoAbatimentoChange(value: any): void {
    this.tipoAbatimento = value;
    this.buscar(value);
  }

  public receiveEmpresa(empresa: Empresa): void {
    this.empresaSelected = empresa;
    this.buscar(this.tipoAbatimento);
  }
}
