import { Injectable } from "@angular/core";
import {ElectronService} from "ngx-electron";

@Injectable({
  providedIn: "root"
})
export class IpcElectronService {
  constructor(private _electronService: ElectronService) {
  }

  public onForeground() {
    if(this._electronService.isElectronApp) {
      this._electronService.ipcRenderer.send("ipc-foreground");
    }
  }
}
