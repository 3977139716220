import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {Localizacao} from "../../../models/localizacao";
import {FormBuilder} from "@angular/forms";
import { Empresa } from "../../../models/empresa";

@Component({
  selector: "app-empresa-endereco-dialog",
  templateUrl: "./empresa-endereco-dialog.component.html",
  styleUrls: ["./empresa-endereco-dialog.component.scss"]
})
export class EmpresaEnderecoDialogComponent implements OnInit {

  static openDialog(dialog: MatDialog, empresa: Empresa): Observable<any> {
    const config = {
      width: "800px",
      minHeight: "500px",
      data: {
        empresa: empresa
      }
    };

    return dialog.open(EmpresaEnderecoDialogComponent, config).afterClosed();
  }

  nomeFantasia: string;
  localizacao: Localizacao;

  constructor(public dialogRef: MatDialogRef<EmpresaEnderecoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public formBuilder: FormBuilder) {
    this.localizacao = data["empresa"].localizacao;
    this.nomeFantasia = data["empresa"].nomeFantasia;
  }

  ngOnInit() {
  }

}
