import { FireModel } from "./fire.model";
import * as firebase from "firebase";
import Timestamp = firebase.firestore.Timestamp;

export class Entregador extends FireModel {

  public nome: string;
  public dataNascimento: Timestamp;
  public cpf: string;
  public cnh: string;
  public telefone: string;
  public placaMoto: string;
  public modeloMoto: string;
  public turno: string;
  public dataEntrada: Timestamp;
  public dataSaida: Timestamp;
  public motivoSaida: string;
  public endereco: string;
  public observacao: string;
  public inactive: boolean;
  public criacao: string;
  public atualizacao: string;

  constructor(entregador: any = {}) {
    super(entregador.id);
    this.nome = entregador.nome;
    this.dataNascimento = entregador.dataNascimento ? new Timestamp(entregador.dataNascimento.seconds, entregador.dataNascimento.nanoseconds) : null;
    this.cpf = entregador.cpf;
    this.cnh = entregador.cnh;
    this.telefone = entregador.telefone;
    this.placaMoto = entregador.placaMoto;
    this.modeloMoto = entregador.modeloMoto;
    this.turno = entregador.turno;
    this.dataEntrada = entregador.dataEntrada ? new Timestamp(entregador.dataEntrada.seconds, entregador.dataEntrada.nanoseconds) : null;
    this.dataSaida = entregador.dataSaida ? new Timestamp(entregador.dataSaida.seconds, entregador.dataSaida.nanoseconds) : null;
    this.motivoSaida = entregador.motivoSaida;
    this.endereco = entregador.endereco;
    this.observacao = entregador.observacao;
    this.inactive = entregador.inactive;
    this.criacao = entregador.criacao;
    this.atualizacao = entregador.atualizacao;
  }

  public toAny(): any {
    const a: any = {};
    if (this.id) a.id = this.id;
    if (this.nome) a.nome = this.nome.trim();
    if (this.dataNascimento) a.dataNascimento = this.dataNascimento.toDate().toISOString();
    if (this.cpf) a.cpf = this.cpf;
    if (this.cnh) a.cnh = this.cnh;
    if (this.telefone) a.telefone = this.telefone;
    if (this.placaMoto) a.placaMoto = this.placaMoto;
    if (this.modeloMoto) a.modeloMoto = this.modeloMoto;
    if (this.turno) a.turno = this.turno;
    if (this.dataEntrada) a.dataEntrada = this.dataEntrada.toDate().toISOString();
    if (this.dataSaida) a.dataSaida = this.dataSaida.toDate().toISOString();
    if (this.motivoSaida) a.motivoSaida = this.motivoSaida;
    if (this.endereco) a.endereco = this.endereco;
    if (this.observacao) a.observacao = this.observacao;
    a.inactive = this.inactive ? this.inactive : false;
    if (this.criacao) a.criacao = this.criacao;
    if (this.atualizacao) a.atualizacao = this.atualizacao;
    return a;
  }
}


export enum TurnoEntragador {
  DIURNO = "DIURNO",
  NOTURNO = "NOTURNO",
  AMBOS = "AMBOS"
}
