import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {PageService} from "../../../services/page.service";
import {PerfilAcesso, Role} from "../../../models/perfil-acesso.model";
import {ActivatedRoute, Router} from "@angular/router";
import {SnackService} from "../../../services/snack/snack.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {MatDialog} from "@angular/material/dialog";
import {PerfilService} from "../perfil.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SelectionModel} from "@angular/cdk/collections";
import {
  getAbatimentosAuthorities,
  getAvaliacoesAuthorities,
  getBairrosAuthorities,
  getCategoriasDeProdutoAuthorities,
  getCentralEntregaAuthorities, getClientesAuthorities,
  getComissoesConsultoresAuthorities,
  getComplementosDeProdutoAuthorities,
  getCozinhasAuthorities,
  getDeliveryAuthorities,
  getEmpresaAuthorities,
  getEntregadoresAuthorities,
  getEntregasAuthorities,
  getExtratoOnlineAuthorities,
  getFaturasAuthorities,
  getFinanceiroAuthorities,
  getFormasPagamentoAuthorities,
  getGerenciadorPedidosAuthorities,
  getMonitorAuthorities,
  getNotificacoesAuthorities,
  getPedidosAuthorities,
  getPerfilAcessoAuthorities,
  getProdutosAuthorities,
  getPromocaoAuthorities,
  getRelatorioComissoesAuthorities,
  getRelatorioPedidosAuthorities,
  getUsuarioAuthorities,
} from "../authorities.datasource";
import {Observable, Subscription} from "rxjs";
import {AuthService} from "../../../modules/login/auth.service";
import {isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";
import {AppUser} from "../../../models/appUser";
import {UsersService} from "../../users/users.service";
import {itensMenu, MenuItem} from "../../home/menu-itens";
import {take} from "rxjs/operators";
import {MatTreeFlatDataSource, MatTreeFlattener,} from "@angular/material/tree";
import {FlatTreeControl} from "@angular/cdk/tree";
import {CreateDataTreePerfilAcesso, PerfilByGroupFlatNode, PerfilByGroupNode, TreeData} from "../create-data-tree-perfil-acesso";

@Component({
  selector: "app-perfil-form",
  templateUrl: "./perfil-form.component.html",
  styleUrls: ["./perfil-form.component.scss"],
})
export class PerfilFormComponent extends PageService<PerfilAcesso> implements OnInit {
  form: FormGroup;

  userSubscription: Subscription;
  loggedUser: AppUser;

  // Observable de todos consultores
  consultores: Observable<AppUser[]>;

  // Items de todas opções
  itensMenu: MenuItem[] = itensMenu;
  itensBlocked: MenuItem[];

  canUpdateOwnPerfil: boolean = true;

  trees: TreeData[] = [];

  datasource: MatTreeFlatDataSource<PerfilByGroupNode, PerfilByGroupFlatNode>;
  treeFlattener: MatTreeFlattener<PerfilByGroupNode, PerfilByGroupFlatNode>;
  treeControl: FlatTreeControl<PerfilByGroupFlatNode>;
  checklistSelection = new SelectionModel<PerfilByGroupFlatNode>(true); //Seleção da checklist
  nestedNodeMap = new Map<PerfilByGroupNode, PerfilByGroupFlatNode>(); //Faz um map de nested node para flat node (ajuda a manter o mesmo objeto da seleção)
  flatNodeMap = new Map<PerfilByGroupFlatNode, PerfilByGroupNode>(); //Faz um map de flat node para nested node (ajuda a encontrar a nested node q será modificada)
  selectedItemsLength: number = 0;

  // Variáveis para vericficar mudaças na seleção de checkboxes
  initialSelection: any;
  changedOnSave: boolean = false;

  checkListDatabase: CreateDataTreePerfilAcesso = new CreateDataTreePerfilAcesso(this.createTrees());

  constructor(public service: PerfilService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              formBuilder: FormBuilder,
              public auth: AuthService,
              private userService: UsersService) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/perfis/");

    /** Variáveis do componente tree do angular material */
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<PerfilByGroupFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.datasource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
    this.checkListDatabase.initialize();
    this.datasource.data = this.checkListDatabase.dataChange.value;

    this.form = formBuilder.group({
      descricao: ["", [Validators.required, Validators.minLength(3)]],
      observacao: [""],
      consultorId: [null],
    });

    // Ajuste técnico para nao exibir as opções com o campo onlyForAdministrador
    // Ao refatorar a tela de permissões esse ajuste será substituído
    this.itensBlocked = this.itensMenu.filter((itemMenu: MenuItem) => {
      return itemMenu.onlyForAdministrador;
    });

    this.onChanges();
  }

  getLevel = (node: PerfilByGroupFlatNode) => node.level;

  isExpandable = (node: PerfilByGroupFlatNode) => node.expandable;

  getChildren = (node: PerfilByGroupNode): PerfilByGroupNode[] => node.children;

  hasChild = (_: number, _nodeData: PerfilByGroupFlatNode) => _nodeData.expandable;

  /** Converte o objeto que possui os nós recursivos em um objeto plano com os niveis de camadas */
  transformer = (node: PerfilByGroupNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item ? existingNode : new PerfilByGroupFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    flatNode.perfil = node.perfil;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  updateSelectedItemsLength(): void {
    this.selectedItemsLength = this.checklistSelection.selected.filter(
      (perfilNode) => {
        return isNotNullOrUndefined(perfilNode.perfil);
      }
    ).length;
  }

  /** Verifica se todos os complementos de um grupo estão selecionados */
  descendantsAllSelected(node: PerfilByGroupFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
  }

  /** Verifica se o grupo possui alguns complementos selecionados */
  descendantsPartiallySelected(node: PerfilByGroupFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Seleciona/Desmarca todos os complementos de um grupo */
  todoItemSelectionToggle(node: PerfilByGroupFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);

    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    descendants.every((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Seleciona/Desmarca um complemento e checa se essa mudança afeta o checkbox do grupo */
  todoLeafItemSelectionToggle(node: PerfilByGroupFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /** Checa todos os grupos quando um complemento é selecionado/desmarcado */
  checkAllParentsSelection(node: PerfilByGroupFlatNode): void {
    let parent: PerfilByGroupFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
    this.updateSelectedItemsLength();
  }

  /** Checa o estado do checkbox do grupo de acordo com os estados dos checkbox dos componentes desse grupo */
  checkRootNodeSelection(node: PerfilByGroupFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /** Retorna o grupo de um complemento */
  getParentNode(node: PerfilByGroupFlatNode): PerfilByGroupFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Adiciona valores nas trees */
  addTree(tree: TreeData, allowForLojistas?: boolean | false) {
    if (
      isNullOrUndefined(this.auth.currentEmpresa) ||
      (allowForLojistas && isNotNullOrUndefined(this.auth.currentEmpresa))
    ) {
      this.trees.push(tree);
    }
  }

  createTrees() {
    this.addTree(new TreeData("Empresas", getEmpresaAuthorities()));
    this.addTree(new TreeData("Usuários", getUsuarioAuthorities()), true);
    this.addTree(new TreeData("Perfil de Acesso", getPerfilAcessoAuthorities()), true);
    this.addTree(new TreeData("Bairros", getBairrosAuthorities()));
    this.addTree(new TreeData("Delivery", getDeliveryAuthorities()), true);
    this.addTree(new TreeData("Formas de Pagamento", getFormasPagamentoAuthorities(this.isLojista(), this.haveCompany())), true);
    this.addTree(new TreeData("Produtos", getProdutosAuthorities()), true);
    this.addTree(new TreeData("Categorias de Produto", getCategoriasDeProdutoAuthorities()), true);
    this.addTree(new TreeData("Complementos de Produto", getComplementosDeProdutoAuthorities()), true);
    this.addTree(new TreeData("Cozinhas", getCozinhasAuthorities()), true);
    this.addTree(new TreeData("Relatório", getPedidosAuthorities(this.isLojista(), this.isEmpresaSelecionada())), true);
    this.addTree(new TreeData("Gerenciador de Pedidos", getGerenciadorPedidosAuthorities()), true);
    this.addTree(new TreeData("Avaliações", getAvaliacoesAuthorities()), true);
    this.addTree(new TreeData("Notificações", getNotificacoesAuthorities()));
    this.addTree(new TreeData("Entregadores", getEntregadoresAuthorities()));
    this.addTree(new TreeData("Central de Entregas", getCentralEntregaAuthorities()));
    this.addTree(new TreeData("Entregas", getEntregasAuthorities()));
    this.addTree(new TreeData("Monitor de Pedidos", getMonitorAuthorities()));
    this.addTree(new TreeData("Relatório de Pedidos", getRelatorioPedidosAuthorities()));
    this.addTree(new TreeData("Promoções", getPromocaoAuthorities()));
    this.addTree(new TreeData("Relatório de Comissões", getRelatorioComissoesAuthorities()));
    this.addTree(new TreeData("Comissões de Consultores", getComissoesConsultoresAuthorities()));
    this.addTree(new TreeData("Financeiro", getFinanceiroAuthorities()));
    this.addTree(new TreeData("Faturas", getFaturasAuthorities(this.isLojista(), this.isEmpresaSelecionada())), true);
    this.addTree(new TreeData("Extrato de Pagamentos Online", getExtratoOnlineAuthorities()), true);
    this.addTree(new TreeData("Abatimentos", getAbatimentosAuthorities()));
    this.addTree(new TreeData("Clientes", getClientesAuthorities()));
    return this.trees;
  }

  isLojista(): boolean {
    return this.auth.user.isLojista();
  }

  haveCompany(): boolean {
    return isNotNullOrUndefined(this.auth.currentEmpresa);
  }

  isEmpresaSelecionada(): boolean {
    return isNotNullOrUndefined(this.auth.currentEmpresa);
  }

  hasSomeOptionSeleted(): boolean {
    return this.checklistSelection.hasValue();
  }

  appUserCompareWith(c1: string, c2: string): boolean {
    return c1 === c2;
  }

  getConsultorId(): string {
    if (this.loggedUser.isConsultor()) {
      return this.loggedUser.id;
    } else if (this.loggedUser.isAssistente()) {
      return this.loggedUser.consultorId;
    } else {
      return this.item.consultorId;
    }
  }

  ngOnInit() {
    super.ngOnInit();

    this.userSubscription = this.auth.currentUser.subscribe((user: AppUser) => {
      this.loggedUser = user;
      if (this.loggedUser.isAdministrador()) {
        this.consultores = this.userService.getAllConsultores();
      }
    });

    this.itemSubject.asObservable().subscribe((value) => {
      this.form.patchValue(value);
    });

    this.form.valueChanges.subscribe((value) => {
      Object.keys(value).forEach((key) => (this.item[key] = value[key]));
    });
  }

  protected newItem(): PerfilAcesso {
    return new PerfilAcesso();
  }

  afterLoadItem() {
    if (this.item.roles && this.item.roles.length > 0) {
      for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
        const roleIndex = +this.treeControl.dataNodes[i].perfil;
        const findRole = this.item.roles.filter(
          (roleStr) => Role[roleStr] === roleIndex
        );
        if (findRole && findRole.length > 0) {
          this.todoItemSelectionToggle(this.treeControl.dataNodes[i]);
        }
      }
    }

    this.initialSelection = this.checklistSelection.selected; // Utilizdo para verificar mudanças ao salvar

    if (this.item) {
      if (this.loggedUser.isLojista()) {
        this.service
          .getPerfilAcessoLoggedUser()
          .pipe(take(1))
          .subscribe((perfilAcesso) => {
            if (this.item.id === perfilAcesso.id) {
              this.canUpdateOwnPerfil = perfilAcesso.hasRole(
                Role.UsuarioUpdateOwnPerfilAcesso
              );
            } else {
              this.canUpdateOwnPerfil = true;
            }
          });
      } else {
        if (this.item.id === this.loggedUser.perfilAcesso.id) {
          this.service
            .getPerfilAcessoLoggedUser()
            .pipe(take(1))
            .subscribe((perfilAcesso) => {
              this.canUpdateOwnPerfil = perfilAcesso.hasRole(
                Role.UsuarioUpdateOwnPerfilAcesso
              );
            });
        } else {
          this.canUpdateOwnPerfil = true;
        }
      }
    }

    // Desabilita os formulários quando o perfil de acesso que está sendo editado é o mesmo do loggedUser e o usuário não tem permissão de alterar o próprio perfil
    if (!this.canUpdateOwnPerfil) {
      this.form.disable();
    }
  }

  saveOrUpdate(item: PerfilAcesso = this.item): Observable<void> {
    // Adiciona o consultorId ao item
    if (!this.auth.currentEmpresa) {
      this.item.consultorId = this.getConsultorId();
    } else {
      this.item.consultorId = null;
    }

    // Salvar os dados da tabela de permissões
    this.item.roles = [];

    this.checklistSelection.selected.forEach((perf) => {
      if (isNotNullOrUndefined(perf.perfil)) {
        const roleIndex = +perf.perfil; // Transformando o valor de perf.perfil em index para Role
        this.item.roles.push(Role[roleIndex]);
      }
    });
    this.changedOnSave = true;

    return super.saveOrUpdate(item);
  }

  onChanges() {
    let mudanca = 0;
    this.form.valueChanges.subscribe(() => {
      if (mudanca === 0) {
        this.formMudou = false;
        mudanca++;
      } else {
        this.formMudou = true;
      }
    });
  }

  // Caso o formulário foi modificado
  podeMudarRota(): boolean {
    if (this.formMudou === true || (this.initialSelection !== this.checklistSelection.selected && this.changedOnSave === false)) {
      return confirm("Os dados ainda não foram salvos. Deseja sair mesmo assim?");
    }
    return true;
  }
}
