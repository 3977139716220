import {Component, Inject, OnInit} from "@angular/core";
import {Observable, of} from "rxjs";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormControl, Validators} from "@angular/forms";
import * as moment from "moment";
import { MatDialogConfig } from "@angular/material/dialog";
import {LoadingService} from "../../../services/loading/loading.service";
import {catchError, mergeMap} from "rxjs/operators";
import {DialogService} from "../../../services/dialog/dialog.service";
import {InvoiceIuguService} from "../../../services/iugu/invoice-iugu.service";

@Component({
  selector: "app-gerar-novo-invoice-dialog",
  templateUrl: "./gerar-novo-invoice-dialog.component.html",
  styleUrls: ["./gerar-novo-invoice-dialog.component.scss"]
})
export class GerarNovoInvoiceDialogComponent implements OnInit {

  static openDialog(dialog: MatDialog, invoice: any): Observable<any> {
    const config = <MatDialogConfig>{
      width: "400px",
      data: {
        invoice: invoice
      }
    };

    return dialog.open(GerarNovoInvoiceDialogComponent, config).afterClosed();
  }

  dataVencimentoControl: FormControl = new FormControl({validators: Validators.required});
  invoice;
  minDate = new Date();

  disableButton: boolean = false;

  constructor(public dialogRef: MatDialogRef<GerarNovoInvoiceDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public invoiceIuguService: InvoiceIuguService,
              public loadingService: LoadingService,
              public dialogService: DialogService) {

    // Inicializar o calendário no dia seguinte, isso é importante para que não gere,
    // acidentalmente, uma fatura para o mesmo dia e o cliente não consiga pagar, depedendo do horário bancário.
    const dataVencimento = new Date();
    dataVencimento.setDate(this.minDate.getDate() + 1);
    this.dataVencimentoControl.patchValue(dataVencimento);
    this.invoice = data["invoice"];
  }

  ngOnInit() {
  }

  closeDialogAndHideTopBar() {
    this.loadingService.hideTopBar();
    this.dialogRef.close();
  }

  gerarFatura() {
    this.disableButton = true;
    this.loadingService.showTopBar();

    const newDueDate = moment(this.dataVencimentoControl.value).format("YYYY-MM-DD");

    return this.invoiceIuguService.generateExpiredInvoice(this.invoice.id, newDueDate).pipe(
      catchError(err => {
        this.closeDialogAndHideTopBar();
        return this.dialogService.messageDialog()
          .title("Atenção")
          .message(err)
          .show();
      }),
      mergeMap(res => {
        this.closeDialogAndHideTopBar();
        if (res) {
          if (res.data && res.data.iuguInvoiceUrl) {
            return this.dialogService.confirmDialog()
              .message(res.message)
              .acceptButton("Ver fatura")
              .cancelButton("Cancelar")
              .show()
              .pipe(
                mergeMap(accept => {
                  if (accept) {
                    window.open(res.data.iuguInvoiceUrl);
                  }
                  return of(res);
                })
              );
          }
          return this.dialogService.messageDialog()
            .message(res.message)
            .show()
            .pipe(
              mergeMap(() => of(res))
            );
        }
        return of(res);
      })
    ).subscribe(() => this.disableButton = false);
  }
}
