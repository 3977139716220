import {Component, Inject, OnInit} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {Observable} from "rxjs";
import {StatusPedeQueChega} from "../../../models/pedequechega.model";
import {Entregador} from "../../../models/entregador.model";

@Component({
  selector: "app-resumo-de-entregas-dialog",
  templateUrl: "./resumo-de-entregas-dialog.component.html",
  styleUrls: ["./resumo-de-entregas-dialog.component.scss"]
})

export class ResumoDeEntregasDialogComponent implements OnInit {

  static openDialog(dialog: MatDialog, entregas: any): Observable<any> {
    const config = {
      width: "550px",
      data: {
        entregas: entregas
      }
    };
    return dialog.open(ResumoDeEntregasDialogComponent, config).afterClosed();
  }

  public entregas;
  public entregadores: Entregador[] = [];

  readonly semEntregador = new Entregador({id: "1", nome: "Sem Entregador"});

  constructor(public dialogRef: MatDialogRef<ResumoDeEntregasDialogComponent>,
             @Inject(MAT_DIALOG_DATA) public data: any) {
    this.entregas = data["entregas"];
    if (this.entregas) {
      this.entregas = this.entregas.filter((item) => item.status !== StatusPedeQueChega[StatusPedeQueChega.CANCELADO]);
    }
    this.getEntregadores();
  }

  ngOnInit() {
  }

  getEntregadores() {
    for (const item of this.entregas) {
      const entregador = item.entregador ? item.entregador : this.semEntregador;
      if (!this.entregadores.some((_entregador) => _entregador.id === entregador.id)) {
        this.entregadores.push(entregador);
      }
    }
    this.entregadores.sort((a, b) => {
      if (b.id === "1") {return 1;}
      if (a.nome < b.nome) { return -1; }
      if (a.nome > b.nome) { return 1; }
      return 0;
    });
  }

  getEntregasRealizadas(entregador: Entregador): number {
    let _entregas: number = 0;
    for (const item of this.entregas) {
      if ((item.entregador ? item.entregador : this.semEntregador).id === entregador.id) {
        _entregas++;
      }
    }
    return _entregas;
  }

  getValorEntregador(entregador: Entregador): number {
    let valor: number = 0;
    for (const item of this.entregas) {
      if ((item.entregador ? item.entregador : this.semEntregador).id === entregador.id) {
        valor = valor + item.valor;
      }
    }
    return valor;
  }

}
