import {Component, ElementRef, OnInit, Renderer2, ViewChild} from "@angular/core";
import {
  DatetimeAdapter,
  MAT_DATETIME_FORMATS,
  MAT_NATIVE_DATETIME_FORMATS,
  NativeDatetimeAdapter
} from "@mat-datetimepicker/core";
import {DateAdapter, NativeDateAdapter} from "@angular/material/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable, of} from "rxjs";
import {Segmento} from "../../../models/segmento.model";
import {Empresa} from "../../../models/empresa";
import {SegmentoService} from "../../../services/segmento.service";
import {ActivatedRoute, Router} from "@angular/router";
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";
import {OneSignalService} from "../../../services/onesignal.service";
import {mergeMap, tap} from "rxjs/operators";
import {EmpresaService} from "../../empresa/empresa.service";
import * as moment from "moment";
import {SegmentoDialogComponent} from "../segmento-dialog/segmento-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {Location} from "@angular/common";

@Component({
  selector: "app-notificacoes-form",
  templateUrl: "./notificacoes-form.component.html",
  styleUrls: ["./notificacoes-form.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter
    },
    {
      provide: DatetimeAdapter,
      useClass: NativeDatetimeAdapter
    },
    {
      provide: MAT_DATETIME_FORMATS,
      useValue: MAT_NATIVE_DATETIME_FORMATS
    }
  ]
})
export class NotificacoesFormComponent implements OnInit {
  notificationId: string;

  form: FormGroup;
  segmentos: Observable<Segmento[]>;

  // Indica que está carregando os complementos
  minDate;
  notificacaoEnviada: boolean = false;

  // Título do botão salvar
  titleSaveButton: string = "Salvar";

  programacoes = [Programacao.enviar_agora, Programacao.agendar];

  dataEnvioPlaceholder = "Agendar para";

  notificacao: any = {};

  openEmojiPickerTitle: boolean = false;
  openEmojiPickerMessage: boolean = false;

  @ViewChild("titleEmojiButton", {static: false})
  titleEmojiButton: ElementRef;
  @ViewChild("titleEmoji", {static: false})
  titleEmoji: ElementRef;

  @ViewChild("messageEmojiButton", {static: false})
  messageEmojiButton: ElementRef;
  @ViewChild("messageEmoji", {static: false})
  messageEmoji: ElementRef;

  empresaSelected: Empresa = new Empresa();

  formMudou: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private renderer: Renderer2,
              private location: Location,
              public segmentoService: SegmentoService,
              public route: ActivatedRoute,
              public router: Router,
              public oneSignalService: OneSignalService,
              public empresaService: EmpresaService,
              public dialog: MatDialog,
              public dialogService: DialogService,
              public loadingService: LoadingService) {

    this.form = this.formBuilder.group({
      titulo: [""],
      mensagem: ["", [Validators.required, Validators.minLength(5)]],
      ttl: [24, [Validators.required, Validators.min(1), Validators.max(672)]],
      segmento: ["", [Validators.required]],
      dateTime: [""],
      programacao: ["", Validators.required]
    });
    this.notificationId = this.route.snapshot.queryParamMap.get("notificationId");

    this.segmentos = this.segmentoService.col$().pipe();

    // Define a menor data para marcar envio de notificações
    this.minDate = moment(moment.now()).toDate();

    // Ao mudar de agendar envio para enviar agora validar os botões
    this.form.get("programacao").valueChanges.subscribe(data => {
      if (data === Programacao.agendar) {
        this.form.get("dateTime").setValidators(Validators.required);
        this.form.get("dateTime").enable();
        this.titleSaveButton = "Agendar";
      } else if (data === Programacao.enviar_agora) {
        this.form.get("dateTime").clearValidators();
        this.form.get("dateTime").disable();
        this.titleSaveButton = "Enviar";
      } else {
        this.titleSaveButton = "Salvar";
      }
    });

    this.form.valueChanges.subscribe(() => {
      this.formMudou = true;
    });

    // Fechar tela de emojis ao clicar fora do elemento
    this.renderer.listen("window", "click", (e: Event) => {
      if (this.openEmojiPickerTitle) {
        if (!this.titleEmojiButton.nativeElement.contains(e.target) && !this.titleEmoji.nativeElement.contains(e.target)) {
          this.openEmojiPickerTitle = false;
        }
      }
      if (this.openEmojiPickerMessage) {
        if (!this.messageEmojiButton.nativeElement.contains(e.target) && !this.messageEmoji.nativeElement.contains(e.target)) {
          this.openEmojiPickerMessage = false;
        }
      }
    });
  }

  ngOnInit() {

    if (isNotNullOrUndefined(this.notificationId)) {
      this.oneSignalService.getById(this.notificationId).pipe(
        tap(notificacao => {
          this.form.patchValue({
            titulo: notificacao.headings.en,
            mensagem: notificacao.contents.en,
            // empresaId: (notificacao.data && notificacao.data.empresa_id) ? notificacao.data.empresa_id : ""
            ttl: notificacao.ttl
          }, {emitEvent: false});

          this.form.get("segmento").patchValue(notificacao.included_segments[0], {emitEvent: false});

          // Notificação já enviada
          if (notificacao.completed_at && notificacao.completed_at > 0) {

            this.programacoes = [Programacao.enviada];
            this.form.get("programacao").patchValue(Programacao.enviada, {emitEvent: false});

            this.dataEnvioPlaceholder = "Enviada em";
            this.form.get("dateTime").patchValue(new Date(notificacao.send_after * 1000), {emitEvent: false});

          } else {

            // Notificação agendada
            if (notificacao.send_after > notificacao.queued_at) {

              this.form.get("programacao").patchValue(Programacao.agendar, {emitEvent: false});
              if (notificacao.send_after) {
                this.form.get("dateTime").patchValue(new Date(notificacao.send_after * 1000), {emitEvent: false});
              }

            } else {
              this.form.get("programacao").patchValue(Programacao.enviar_agora, {emitEvent: false});
            }

          }
        }),
        mergeMap(notificacao => {
          if (isNotNullOrUndefined(notificacao.data) && isNotNullOrUndefined(notificacao.data.empresa_id)) {
            return this.empresaService.getEmpresa(notificacao.data.empresa_id).pipe(
              mergeMap(empresa => {
                this.empresaSelected = empresa;
                return of(notificacao);
              }));
          }
          return of(notificacao);
        }))
        .subscribe(value => {
          this.notificacao = value;
          if (value.completed_at && value.completed_at > 0) {
            this.form.disable();
            this.form.get("dateTime").disable();
            this.notificacaoEnviada = true;
          }
        });
    }
  }

  toggleEmojiPicker(campo: string) {
    if (campo === "titulo") {
      this.openEmojiPickerTitle = !this.openEmojiPickerTitle;
      this.openEmojiPickerMessage = false;
    }
    if (campo === "mensagem") {
      this.openEmojiPickerMessage = !this.openEmojiPickerMessage;
      this.openEmojiPickerTitle = false;
    }
  }

  addEmoji(event, campo: string) {
    let typePosition = 0;
    document.getElementById(`campo${campo}`).addEventListener("focusin", (e: any) => {
      typePosition = e.target.selectionStart;
    });
    document.getElementById(`campo${campo}`).focus();

    const text: string = this.form.get(campo).value;
    this.form.get(campo).patchValue(`${text.slice(0, typePosition)}${event.emoji.native}${text.slice(typePosition + 1)}`);
  }

  receiveEmpresa(empresa: Empresa) {
    this.formMudou = true;
    this.empresaSelected = empresa;
  }

  addSegmento() {
    // Abrir o Dialog para Cadastrar uma nova cidade
    SegmentoDialogComponent.showDialog(this.dialog).subscribe(segmento => {
      if (segmento) {
        if (segmento instanceof Segmento) {
          this.form.get("segmento").patchValue(segmento.nome);
        } else {
          if (isNotNullOrUndefined(this.form.get("segmento").value)
            && this.form.get("segmento").value !== ""
            && !(segmento as Segmento[]).find(seg => seg.nome === this.form.get("segmento").value)) {
            this.form.get("segmento").patchValue("");
          }
        }
      }
    });
  }

  goBack() {
    this.location.back();
  }

  saveOrUpdate() {
    const item = this.form.value;
    let mensagem: any;
    let additionalData = null;

    if (!isEmpty(this.empresaSelected) && !isEmpty(this.empresaSelected.id)) {
      additionalData = {
        notification_type: "promo",
        empresa_id: this.empresaSelected.id
      };
    }

    // Tratamento para o caso de "dateTime" não estiver no formato "Date"
    if (isNotNullOrUndefined(item.dateTime) && !isEmpty(item.dateTime) && !(item.dateTime instanceof Date)) {
      item.dateTime = item.dateTime.toDate();
    }

    mensagem = {
      app_id: environment.onesignal_app_id,
      headings: {
        en: item.titulo
      },
      contents: {
        en: item.mensagem
      },
      data: additionalData,
      included_segments: [item.segmento],
      ttl: this.form.get("ttl").value
    };

    if (this.form.get("programacao").value === Programacao.agendar) {
      mensagem.send_after = item.dateTime;
    }

    this.loadingService.showTopBar();

    if (isNotNullOrUndefined(this.notificationId)) {
      this.oneSignalService.deleteNotification(this.notificationId)
        .pipe(
          mergeMap(value => {
            if (value) {
              return this.oneSignalService.sendNotification(mensagem);
            }
          })).subscribe((result: any) => {
        this.verifySegmentoErrorAndFinalize(result);
        this.loadingService.hideTopBar();
      }, err => {
        this.dialogService.messageDialog()
          .title("Erro ao salvar!")
          .message(err)
          .show();
      });
    } else {
      this.oneSignalService.sendNotification(mensagem).subscribe((result: any) => {
        this.verifySegmentoErrorAndFinalize(result);
        this.loadingService.hideTopBar();
      }, err => {
        this.dialogService.messageDialog()
          .title("Erro ao salvar!")
          .message(err)
          .show();
      });
    }
  }

  verifySegmentoErrorAndFinalize(result) {
    if ((isNullOrUndefined(result.id) || result.id === "") && result.recipients <= 0) {
      return this.dialogService
        .messageDialog()
        .title("Falha ao salvar notificação!")
        .message("Nenhum usuário inscrito no segmento informado!")
        .show();
    }
    this.goBack();
  }

  programacaoToString(programacao: Programacao): string {
    if (programacao) {
      switch (programacao) {
        case Programacao.enviar_agora:
          return "Enviar Agora";
        case Programacao.agendar:
          return "Agendar Envio";
        case Programacao.enviada:
          return "Enviado";
        default:
          return "";
      }
    }
    return "";
  }

}

enum Programacao {
  enviar_agora = "enviarAgora",
  agendar = "agendar",
  enviada = "enviada"
}
