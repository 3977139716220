import {Injectable} from "@angular/core";
import {AuthService} from "./auth.service";
import {PerfilService} from "../../components/perfil-acesso/perfil.service";
import {PerfilAcesso} from "../../models/perfil-acesso.model";
import {AppUser} from "../../models/appUser";
import {mergeMap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {isNullOrUndefined} from "../../utils/commons";

@Injectable({
  providedIn: "root"
})
export class HasPermissionService {

  constructor(private authService: AuthService,
              private perfilService: PerfilService) {

  }

  hasPermission(permissions: string[]): Observable<boolean> {
    // Busca o perfil de acesso do usuário logado
    return this.perfilService.getPerfilAcessoLoggedUser().pipe(
      mergeMap((perfilAcesso: PerfilAcesso) => {
        // Caso o usuário não tenha perfil de acesso a permissão é negada
        if (isNullOrUndefined(perfilAcesso)) {
          return of(false);
        } else {
          // Checa se o usuário possui permissão
          return this.checkPermission(perfilAcesso.roles, permissions);
        }
      })
    );
  }

  private checkPermission(currentUserPermissions: string[], permissions: string[]): Observable<boolean> {
    return this.authService.currentUser.pipe(
      mergeMap((currentUser: AppUser) => {
        let hasPermission: boolean = false;
        if (currentUser && currentUserPermissions) {
          // Percorre as permissões a serem verificadas
          permissions.forEach((permission: string) => {
            // Verifica se o usuário possui alguma das permissões recebidas por parâmetro nas permissões do perfil de acesso dele
            hasPermission = currentUserPermissions.some(x => x.toUpperCase() === permission.toUpperCase());
          });
        }

        return of(hasPermission);
      })
    );
  }
}
