export class ConfigCropper {
  width: number;
  height: number;
  canvasWidth: number;
  canvasHeight: number;
  aspectRatio: number;

  constructor(canvasConfig: any = {}) {
    this.width = canvasConfig.width;
    this.height = canvasConfig.height;
    this.canvasWidth = canvasConfig.canvasWidth;
    this.canvasHeight = canvasConfig.canvasHeight;
    this.aspectRatio = canvasConfig.aspectRatio ? canvasConfig.aspectRatio : 1;
  }
}
