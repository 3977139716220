import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {Observable, of} from "rxjs";
import {FormaPagamento} from "../../models/forma-pagamento.model";
import {AuthService} from "../../modules/login/auth.service";
import {EmpresaCriterion, RepositoryByEmpresa} from "../../repository/repository-by-empresa";
import {OrderByAsc} from "../../services/firebase/criteria/order-by-asc";
import {OrderBy} from "../../services/firebase/criteria/order-by";
import {catchError, map, mergeMap} from "rxjs/operators";
import {isNullOrUndefined} from "util";
import {Empresa} from "../../models/empresa";
import {$query} from "../../services/firebase/criteria/query";
import {AngularFireFunctions} from "@angular/fire/functions";
import {Criterion} from "../../services/firebase/criteria/criterion";
import {isNotNullOrUndefined} from "../../utils/commons";

@Injectable()
export class FormaPagamentoService extends RepositoryByEmpresa<FormaPagamento> {

  constructor(database: AngularFirestore, auth: AuthService, public fns: AngularFireFunctions) {
    super(database, auth, "formas-pagamento");
  }

  isAllowedRecordWithoutCompanie(): boolean {
    return true;
  }

  protected orderBy(): OrderBy {
    return new OrderByAsc("descricao");
  }

  formaPagamentoIsEmpty(empresa?: Empresa): Observable<boolean> {
    return (empresa ? of(empresa) : this.auth.currentEmpresaObservable()).pipe(
      mergeMap((_empresa) => {
        if (isNullOrUndefined(_empresa) || isNullOrUndefined(_empresa.ref)) {
          return of(false);
        }
        return this.col$($query(new EmpresaCriterion(_empresa))).pipe(
          map((data) => !((data && data.length > 0) ||
            (isNotNullOrUndefined(_empresa.pagamentoOnline) && _empresa.pagamentoOnline.enabled === true)))
        );
      })
    );
  }

  remove(id: string): Observable<void> {
    return this.fns.httpsCallable("call-formasPagamento-remove")({id: id});
  }

  getFormasGeraisPagamento(): Observable<FormaPagamento[]> {
    return this.getCollection(
      $query(new Criterion("empresa", "==", null))
    ).valueChanges();
  }

  changeInactive(item: FormaPagamento): Observable<void> {
    return this.fns
      .httpsCallable("call-formasPagamento-changeInactive")({
        id: item.id,
        empresaId: this.auth.currentEmpresa.id,
      })
      .pipe(catchError((error) => this.handleCallableFunctionsError(error)));
  }

  changeInactivePagamentoOnline(empresaId: string): Observable<void> {
    return this.fns.httpsCallable("call-formasPagamento-changeInactivePagamentoOnline")({empresaId: empresaId})
      .pipe(catchError((error) => this.handleCallableFunctionsError(error)));
  }
}
