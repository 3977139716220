export class InvoiceIugu {
  // Coloquei any para os campos que eu não sabia qual era o tipo de dado
  public advance_fee: any;
  public advance_fee_cents: any;
  public authorized_at: any;
  public authorized_at_iso: any;
  public bank_slip: any;
  public canceled_at: any;
  public canceled_at_iso: any;
  public cc_emails: any;
  public chargeback_at: any;
  public chargeback_at_iso: any;
  public commission: string;
  public commission_cents: number;
  public created_at: string;
  public created_at_iso: string;
  public currency: string;
  public custom_variables: any[];
  public customer_id: any;
  public customer_name: any;
  public customer_ref: any;
  public discount: any;
  public discount_cents: any;
  public double_payment_id: any;
  public due_date: string;
  public early_payment_discount: boolean;
  public early_payment_discounts: any[];
  public email: string;
  public expired_at: any;
  public expired_at_iso: any;
  public financial_return_date: any;
  public financial_return_dates: any;
  public fines_on_occurrence_day: string;
  public fines_on_occurrence_day_cents: number;
  public id: string;
  public ignore_canceled_email: any;
  public ignore_due_email: any;
  public installments: any;
  public interest: any;
  public items: any[];
  public logs: any[];
  public notification_url: any;
  public occurrence_date: any;
  public order_id: any;
  public original_payment_id: any;
  public overpaid_cents: any;
  public paid: string;
  public paid_at: string;
  public paid_cents: number;
  public payable_with: string;
  public payment_method: string;
  public protested_at: any;
  public protested_at_iso: any;
  public refundable: any;
  public refunded_at: any;
  public refunded_at_iso: any;
  public return_url: any;
  public secure_id: string;
  public secure_url: string;
  public status: string;
  public tax_cents: any;
  public taxes_paid: string;
  public taxes_paid_cents: number;
  public total: string;
  public total_cents: number;
  public total_on_occurrence_day: string;
  public total_on_occurrence_day_cents: number;
  public total_overpaid: string;
  public total_paid: string;
  public total_paid_cents: number;
  public transaction_number: number;
  public updated_at: string;
  public user_id: any;
  public variables: any[];

  public isOverdue: boolean;
  public statusDescription: string;

  constructor(invoice: any = {}) {
    this.advance_fee = invoice.advance_fee;
    this.advance_fee_cents = invoice.advance_fee_cents;
    this.authorized_at = invoice.authorized_at;
    this.authorized_at_iso = invoice.authorized_at_iso;
    this.bank_slip = invoice.bank_slip;
    this.canceled_at = invoice.canceled_at;
    this.canceled_at_iso = invoice.canceled_at_iso;
    this.cc_emails = invoice.cc_emails;
    this.chargeback_at = invoice.chargeback_at;
    this.chargeback_at_iso = invoice.chargeback_at_iso;
    this.commission = invoice.commission;
    this.commission_cents = invoice.commission_cents;
    this.created_at = invoice.created_at;
    this.created_at_iso = invoice.created_at_iso;
    this.currency = invoice.currency;
    this.custom_variables = invoice.custom_variables;
    this.customer_id = invoice.customer_id;
    this.customer_name = invoice.customer_name;
    this.customer_ref = invoice.customer_ref;
    this.discount = invoice.discount;
    this.discount_cents = invoice.discount_cents;
    this.double_payment_id = invoice.double_payment_id;
    this.due_date = invoice.due_date;
    this.early_payment_discount = invoice.early_payment_discount;
    this.early_payment_discounts = invoice.early_payment_discounts;
    this.email = invoice.email;
    this.expired_at = invoice.expired_at;
    this.expired_at_iso = invoice.expired_at_iso;
    this.financial_return_date = invoice.financial_return_date;
    this.financial_return_dates = invoice.financial_return_dates;
    this.fines_on_occurrence_day = invoice.fines_on_occurrence_day;
    this.fines_on_occurrence_day_cents = invoice.fines_on_occurrence_day_cents;
    this.id = invoice.id;
    this.ignore_canceled_email = invoice.ignore_canceled_email;
    this.ignore_due_email = invoice.ignore_due_email;
    this.installments = invoice.installments;
    this.interest = invoice.interest;
    this.items = invoice.items;
    this.logs = invoice.logs;
    this.notification_url = invoice.notification_url;
    this.occurrence_date = invoice.occurrence_date;
    this.order_id = invoice.order_id;
    this.original_payment_id = invoice.original_payment_id;
    this.overpaid_cents = invoice.overpaid_cents;
    this.paid = invoice.paid;
    this.paid_at = invoice.paid_at;
    this.paid_cents = invoice.paid_cents;
    this.payable_with = invoice.payable_with;
    this.payment_method = invoice.payment_method;
    this.protested_at = invoice.protested_at;
    this.protested_at_iso = invoice.protested_at_iso;
    this.refundable = invoice.refundable;
    this.refunded_at = invoice.refunded_at;
    this.refunded_at_iso = invoice.refunded_at_iso;
    this.return_url = invoice.return_url;
    this.secure_id = invoice.secure_id;
    this.secure_url = invoice.secure_url;
    this.status = invoice.status;
    this.tax_cents = invoice.tax_cents;
    this.taxes_paid = invoice.taxes_paid;
    this.taxes_paid_cents = invoice.taxes_paid_cents;
    this.total = invoice.total;
    this.total_cents = invoice.total_cents;
    this.total_on_occurrence_day = invoice.total_on_occurrence_day;
    this.total_on_occurrence_day_cents = invoice.total_on_occurrence_day_cents;
    this.total_overpaid = invoice.total_overpaid;
    this.total_paid = invoice.total_paid;
    this.total_paid_cents = invoice.total_paid_cents;
    this.transaction_number = invoice.transaction_number;
    this.updated_at = invoice.updated_at;
    this.user_id = invoice.user_id;
    this.variables = invoice.variables;
    this.isOverdue = invoice.isOverdue;
    this.statusDescription = invoice.statusDescription;
  }

  isPendente(): boolean {
    return this.status === StatusInvoice[StatusInvoice.pending];
  }

  isExpired(): boolean {
    return this.status === StatusInvoice[StatusInvoice.expired];
  }
}

export enum StatusInvoice {
  //authorized = "authorized",
  pending = "pending",
  paid = "paid",
  partially_paid = "partially_paid",
  expired = "expired",
  canceled = "canceled",
  refunded = "refunded",
  released = "released",
  in_protest = "in_protest",
  chargeback = "chargeback"
}

export function getStatusInvoice(status: string, invoiceOverdue?: boolean): string {
  switch (status) {
    case StatusInvoice[StatusInvoice.canceled]: {
      return "Cancelada";
    }

    case StatusInvoice[StatusInvoice.expired]: {
      return "Expirada";
    }

    case StatusInvoice[StatusInvoice.paid]: {
      return "Paga";
    }

    case StatusInvoice[StatusInvoice.pending]: {
      if (invoiceOverdue) {
        return "Vencida";
      } else {
        return "Pendente";
      }
    }

    case StatusInvoice[StatusInvoice.partially_paid]: {
      return "Pago Parcialmente";
    }

    case StatusInvoice[StatusInvoice.in_protest]: {
      return "Contestada";
    }

    case StatusInvoice[StatusInvoice.chargeback]: {
      return "Acatada";
    }

    default: {
      return status;
    }
  }
}
