import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Pedido} from "../../../models/pedido.model";
import {StatusPedido} from "../../../models/status-pedido.enum";
import {GerenciadorPedidosService} from "../service/gerenciador-pedidos.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {PrinterService} from "../../../services/printer.service";
import {Observable, of, combineLatest} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {LoadingService} from "../../../services/loading/loading.service";

@Component({
  selector: "app-action-buttons",
  templateUrl: "./action-buttons.component.html",
  styleUrls: ["./action-buttons.component.scss"]
})
export class ActionButtonsComponent implements OnInit {

  @Input()
  pedido: Pedido;

  @Output()
  beforeOnClick = new EventEmitter();

  @Output()
  afterOnClick = new EventEmitter();

  // Desabilita os botões
  disableButton: boolean = false;

  // Ocultar o botão quando não tiver mais ação
  @Input()
  hideWhenFinish: boolean = false;

  constructor(public service: GerenciadorPedidosService,
              public printer: PrinterService,
              public loading: LoadingService,
              public dialog: DialogService) {
  }

  ngOnInit() {
  }

  callFunction(pedido) {
    // Desabilitar o botão
    this.disableButton = true;
    // Emitir o evento de click
    this.beforeOnClick.emit();
    if (pedido.isAprovado()) {
      const deliveryDialog = {
        tittle: "Confirmar que o pedido saiu para entrega?",
        message: "Ao confirmar você estará notificando seu cliente que o produto já saiu para a entrega!"
      };
      const balcaoDialog = {
        tittle: "Confirmar que o pedido está pronto?",
        message: "Ao confirmar você estará notificando seu cliente que o produto já está pronto e disponível para retirada no balcão!"
      };
      this.dialog.confirmDialog()
        .title(pedido.isDelivery()? deliveryDialog.tittle : pedido.isBuscarPedidoNoBalcao()? balcaoDialog.tittle : "")
        .message(pedido.isDelivery()? deliveryDialog.message : pedido.isBuscarPedidoNoBalcao()? balcaoDialog.message : "")
        .acceptButton("Confirmar")
        .show()
        .subscribe(accept => {
          if (accept) {
            this.changeStatus(pedido);
          } else {
            this.disableButton = false;
          }
        });
    } else {
      this.changeStatus(pedido);
    }
  }

  changeStatus(pedido) {

    const afterCall = () => {
      this.disableButton = false;
      this.afterOnClick.emit();
      this.loading.hideTopBar();
    };

    this.loading.showTopBar();
    this.service.callFunction(pedido).pipe(
      mergeMap(() => {
        // Verificar se o status foi aprovado
        if (pedido.getNewStatus() === StatusPedido[StatusPedido.APROVADO]) {
          afterCall();
          const printObservables: Observable<any>[] = [
            this.printer.imprimirEspelho(pedido, true),
            this.printer.imprimirParaProducao(pedido)
          ];

          // Imprimir o pedido para produção
          return combineLatest(printObservables);
        }
        return of();
      })
    ).subscribe(() => {
      afterCall();
    }, error => {
      afterCall();

      const dialog = this.dialog.messageDialog();
      if (error.message.toUpperCase() === "INTERNAL") {
        dialog.message("Ops, parece que você está sem internet!");
        dialog.show();
      } else if (error.toString().includes("Error: Specified printer could not be found")) {
        dialog.message("Erro ao imprimir pedido!");
        dialog.show();
      }
    }, () => {
      afterCall();
    });
  }

}
