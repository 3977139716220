import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Component, Inject} from "@angular/core";
import {AuthService} from "../../../modules/login/auth.service";
import {InvoiceIuguService} from "../../../services/iugu/invoice-iugu.service";
import {FormControl, Validators} from "@angular/forms";
import * as moment from "moment";
import {finalize} from "rxjs/operators";
import {LoadingService} from "../../../services/loading/loading.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {EmpresaComissao} from "../../../models/empresa-comissao.model";
import {Observable} from "rxjs";

@Component({
  selector: "app-gerar-fatura-dialog",
  templateUrl: "./gerar-fatura-dialog.component.html",
  styleUrls: ["./gerar-fatura-dialog.component.scss"]
})

export class GerarFaturaDialogComponent {

  dataVencimentoForm: FormControl;

  disableGerarButton: boolean = false;
  valorTotal: number;

  minDate = moment().add(1, "day");
  maxDate = moment().add(10, "day");

  constructor(public dialofRef: MatDialogRef<GerarFaturaDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Observable<any>,
              public auth: AuthService,
              public invoiceIuguService: InvoiceIuguService,
              public loadingService: LoadingService,
              public dialogService: DialogService) {
    this.data.subscribe((value) => {},
      err => {
        this.dialofRef.close();
        this.dialogService
          .messageDialog()
          .message(err.message)
          .show();
      }
    );
    this.dataVencimentoForm = new FormControl(null);
    this.dataVencimentoForm.setValidators(Validators.required);
    this.dataVencimentoForm.updateValueAndValidity();
  }

  gerarFatura() {
    this.disableGerarButton = true;
    this.loadingService.showTopBar();
    this.invoiceIuguService.generateParcialInvoice(this.auth.currentEmpresa.cnpj, true, this.dataVencimentoForm.value.format("YYYY-MM-DD"))
      .pipe(finalize(() => {
        this.loadingService.hideTopBar();
        this.disableGerarButton = false;
        this.dialofRef.close(true);
      })).subscribe(() => {
      },
      error => {
        this.dialogService
          .messageDialog()
          .message(error.message)
          .show();
      });
  }

  formatDate(data): string {
    return moment(data._seconds * 1000).format("DD/MM");
  }

  invalidButton(): boolean {
    return this.dataVencimentoForm.invalid || this.disableGerarButton;
  }

  setTotal(total: number) {
    this.valorTotal = total;
  }

  getValorComissao(empresaComissao: EmpresaComissao): number {
    return empresaComissao.comissao - (empresaComissao.comissaoPagtoOnline ? empresaComissao.comissaoPagtoOnline : 0);
  }

  getValorDelivery(empresaComissao: EmpresaComissao): number {
    return empresaComissao.delivery - (empresaComissao.deliveryPagtoOnline ? empresaComissao.deliveryPagtoOnline : 0);
  }

}
