import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Entregador } from "../../models/entregador.model";
import { Repository } from "../../repository/Repository";
import { OrderBy } from "../../services/firebase/criteria/order-by";
import { OrderByAsc } from "../../services/firebase/criteria/order-by-asc";
import {AngularFireFunctions} from "@angular/fire/functions";
import {Observable} from "rxjs/Rx";
import {AuthService} from "../../modules/login/auth.service";

@Injectable({
  providedIn: "root"
})
export class EntregadoresService extends Repository<Entregador> {

  constructor(private dataBase: AngularFirestore, public fns: AngularFireFunctions, public auth: AuthService) {
    super(dataBase, "entregadores", true);
  }

  deserialize(value: any): Entregador {
    return new Entregador(value);
  }

  protected orderBy(): OrderBy {
    return new OrderByAsc("nome");
  }

  addOrUpdateEntregador(entregador): Observable<any> {
    return this.fns.httpsCallable("call-entregadores-addOrUpdate")({entregador: entregador, userName: this.auth.user.name});
  }
}
