import {Injectable} from "@angular/core";
import {Repository} from "../../repository/Repository";
import {AngularFirestore} from "@angular/fire/firestore";
import {isNotNullOrUndefined} from "../../utils/commons";
import {DeliveryBairro} from "../../models/delivery-bairro.model";
import {isNullOrUndefined} from "util";

@Injectable({
  providedIn: "root"
})
export class PedeQueChegaBairrosService extends Repository<DeliveryBairro> {

  constructor(db: AngularFirestore) {
    super(db, "pedeQueChegaBairros", true);
  }

  saveBairrosPedeQueChega(deliveryBairros: DeliveryBairro[]) {
    const batch = this.db.firestore.batch();
    for (const deliveryBairro of deliveryBairros) {
      // Novo bairro delivery pede que chega
      if (isNullOrUndefined(deliveryBairro.idPedeQueChega) && (deliveryBairro.bloqueado || isNotNullOrUndefined(deliveryBairro.valorEntrega))) {
        batch.set(this.db.firestore.collection(this.collectionName).doc(this.db.createId()), this.getData(deliveryBairro));
      } else if (isNotNullOrUndefined(deliveryBairro.idPedeQueChega) && (deliveryBairro.bloqueado || isNotNullOrUndefined(deliveryBairro.valorEntrega))) {
        // Atualizar o bairro pede que chega
        batch.update(this.db.firestore.collection(this.collectionName).doc(deliveryBairro.idPedeQueChega), this.getData(deliveryBairro));
      } else if (isNotNullOrUndefined(deliveryBairro.idPedeQueChega) && !deliveryBairro.bloqueado && isNullOrUndefined(deliveryBairro.valorEntrega)) {
        // Remover o pede que chega
        batch.delete(this.db.firestore.collection(this.collectionName).doc(deliveryBairro.idPedeQueChega));
      }
    }
    return batch.commit();
  }

  getData(val: DeliveryBairro) {
    return {
      bairro: {
        cidade: {
          id: val.bairro.cidade.id,
          nome: val.bairro.cidade.nome,
          ref: isNotNullOrUndefined(val.bairro.cidade.ref) ? val.bairro.cidade.ref : null,
          uf: val.bairro.cidade.uf
        },
        id: val.bairro.id,
        nome: val.bairro.nome,
        ref: val.bairro.ref
      },
      valorEntrega: isNotNullOrUndefined(val.valorEntrega)? val.valorEntrega : null,
      bloqueado: val.bloqueado
    };
  }
}
