import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {PedeQueChega, StatusPedeQueChega} from "../../../models/pedequechega.model";
import {PageService} from "../../../services/page.service";
import { MatDialog } from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CentralEntregasService} from "../central-entregas.service";
import {SelectEntregadorDialogComponent} from "../../entregadores/select-entregador-dialog/select-entregador-dialog.component";
import {map, tap} from "rxjs/operators";
import {isNotNullOrUndefined} from "../../../utils/commons";
import {TipoPagamento} from "../../../models/forma-pagamento.model";
import {EmpresaEnderecoDialogComponent} from "../../empresa/empresa-endereco-dialog/empresa-endereco-dialog.component";
import * as firebase from "firebase";
import * as moment from "moment";
import {ResumoDeEntregasDialogComponent} from "../resultados-entregas-dialog/resumo-de-entregas-dialog.component";
import {Pedido} from "../../../models/pedido.model";
import {AsYouType} from "libphonenumber-js";
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: "app-central-entregas",
  templateUrl: "./central-entregas.component.html",
  styleUrls: ["./central-entregas.component.scss"]
})
export class CentralEntregasComponent extends PageService<PedeQueChega> implements OnInit {
  $entregas;
  StatusPedeQueChega = StatusPedeQueChega;
  TipoPagamento = TipoPagamento;
  entregas: PedeQueChega[] = [];

  constructor(public service: CentralEntregasService,
              dialog: MatDialog,
              dialogService: DialogService,
              protected loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              public snackBar: SnackService) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/central/");
    this.$entregas = this.service.getEntregas().pipe(
      map(entregas => {
        return entregas.sort((a, b) => {
          if (a.status === StatusPedeQueChega[StatusPedeQueChega.PENDENTE] && b.status !== StatusPedeQueChega[StatusPedeQueChega.PENDENTE]) {
            return -1;
          } else if (a.status === StatusPedeQueChega[StatusPedeQueChega.PENDENTE] && b.status !== StatusPedeQueChega[StatusPedeQueChega.PENDENTE]) {
            return 1;
          } else {
            return 0;
          }
        });
      }),
      tap((_entregas) => {
        if (_entregas.length > this.entregas.length) {
          this.beep();
        }
        this.entregas = _entregas;
      }));
  }

  private beep() {
    new Audio("assets/sounds/beep.mp3")
      .play()
      .then(() => {
      });
  }

  ngOnInit() {
  }

  enderecoUpdatedLastMonth(lastUpdate: Timestamp): boolean {
    return isNotNullOrUndefined(lastUpdate) && moment().diff(lastUpdate.toDate(), "days") <= 30;
  }

  momentFromNow(lastUpdate) {
    return moment(lastUpdate.toDate()).fromNow();
  }

  cancelarEntrega(item) {
    this.dialogService.promptDialog()
      .title("Cancelar entrega?")
      .message("Informe o motivo de cancelamento. Após cancelar essa entrega não será possivel selecionar um novo entregador. Tem certeza?")
      .show()
      .subscribe(confirmation => {
        if (confirmation) {
          this.service.save(item, this.StatusPedeQueChega[this.StatusPedeQueChega.CANCELADO], isNotNullOrUndefined(item.entregador) ? item.entregador : null, confirmation)
            .subscribe(() => {}, error => {
              this.dialogService.messageDialog().message(error).show();
            });
        }
      });
  }

  selecionarEntregador(item: PedeQueChega) {
    SelectEntregadorDialogComponent.openDialog(this.dialog).subscribe(entregadorSelected => {
      if (entregadorSelected) {
        this.service.save(item, StatusPedeQueChega[StatusPedeQueChega.APROVADO], entregadorSelected)
          .subscribe(() => {}, err => {
            this.dialogService
              .messageDialog()
              .message(err)
              .show();
          });
      }
    });
  }

  removerEntregador(item: PedeQueChega) {
    this.service.save(item, StatusPedeQueChega[StatusPedeQueChega.PENDENTE])
      .subscribe(() => {}, err => {
        this.dialogService
          .messageDialog()
          .message(err)
          .show();
      });
  }

  getTempoEntregaDescricao(tempoEntrega): string {
    return `${tempoEntrega.tempoMinimo} - ${tempoEntrega.tempoMaximo} min`;
  }

  protected newItem(): PedeQueChega {
    return new PedeQueChega;
  }

  openEndereco(empresa: any) {
    EmpresaEnderecoDialogComponent.openDialog(this.dialog, empresa);
  }

  getEntregadoresAtivos(entregas: any): number {
    const entregadorJaRegistrado: any[] = []; // Lista de nomes dos entregadores que já foram adicionados na totalização
    let entregadoresAtivos: number = 0;

    for (const item of entregas) {
      if (item.status === StatusPedeQueChega.APROVADO && item.entregador && !entregadorJaRegistrado.includes(item.entregador.nome)) {
        entregadoresAtivos++;
        entregadorJaRegistrado.push(item.entregador.nome);
      }
    }
    return entregadoresAtivos;
  }

  getEntregasCanceladas(entregas: any): number {
    let entregasCanceladas: number = 0;
    for (const item of entregas) {
      if (item.status === "CANCELADO") {
        entregasCanceladas++;
      }
    }
    return entregasCanceladas;
  }

  showResultadosEntregas() {
    ResumoDeEntregasDialogComponent.openDialog(this.dialog, this.entregas);
  }

  /**
   * Método para copiar os dados ta entregar para a àrea de transferência
   * **/
  copyClipboard(pedido: Pedido) {
    //Formatar numero de Telefone
    let formatedPhone = pedido.cliente.telefone.toString().trim().replace(/^\+/, "");
    if (!formatedPhone.match(/[^0-9]/)) {
      formatedPhone = new AsYouType("BR").input(formatedPhone);
    }

    let copy = `*#${pedido.numero}   -   ${pedido.empresa.nomeFantasia.toString().trim()}*`;
    if (this.enderecoUpdatedLastMonth(pedido.empresa.localizacao.lastUpdate)) {
      copy += ` (Endereço alterado ${this.momentFromNow(pedido.empresa.localizacao.lastUpdate)})`;
    }
    copy += `\n${pedido.logs[0].data.toDate().toLocaleDateString()}    ${moment(pedido.logs[0].data.toDate()).format("HH:mm")}\n`;
    copy += `\nEntregar até às *${moment(pedido.logs[0].data.toDate()).add(pedido.empresa.tempoEntrega.tempoMaximo, "minutes").format("HH:mm")}* hrs\n`;
    copy += `\n*Cliente:* ${pedido.cliente.nome}\n`;
    copy += `*Endereço:* ${pedido.enderecoEntrega.logradouro}, ${pedido.enderecoEntrega.numero} - ${pedido.enderecoEntrega.bairro.nome}\n`;
    if (isNotNullOrUndefined(pedido.enderecoEntrega.complemento) && pedido.enderecoEntrega.complemento.trim().length > 0) {
      copy += `*Complemento:* ${pedido.enderecoEntrega.complemento.trim()}\n`;
    }
    if (isNotNullOrUndefined(pedido.enderecoEntrega.pontoReferencia) && pedido.enderecoEntrega.pontoReferencia.trim().length > 0) {
      copy += `*Ponto de Referência:* ${pedido.enderecoEntrega.pontoReferencia.trim()}\n`;
    }
    copy += `*Telefone:* ${formatedPhone}\n`;
    copy += `*Forma Pagto:* ${pedido.formaPagamento.descricao}\n`;
    copy += `*Valor Pedido:* R$ ${pedido.total.toFixed(2)}`;
    if (pedido.formaPagamento.tipoPagamento === TipoPagamento[TipoPagamento.dinheiro] && pedido.troco > 0) {
      copy += `\n*>>> Troco para R$ ${pedido.troco.toFixed(2)} <<<*`;
    }

    // Retornar texto para cópia
    return copy;
  }
}
