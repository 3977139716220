import {Injectable} from "@angular/core";
import {Repository} from "../repository/Repository";
import {Segmento} from "../models/segmento.model";
import {AngularFirestore} from "@angular/fire/firestore";
import {OrderByAsc} from "./firebase/criteria/order-by-asc";
import {OrderBy} from "./firebase/criteria/order-by";

@Injectable()
export class SegmentoService extends Repository<Segmento> {

  constructor(db: AngularFirestore) {
    super(db, "segmentos");
  }

  deserialize(segmento): Segmento {
    return new Segmento(segmento);
  }

  protected orderBy(): OrderBy {
    return new OrderByAsc("nome");
  }
}
