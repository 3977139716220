import {Observable, of} from "rxjs";
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {PageService} from "../../../services/page.service";
import {Avaliacao} from "../../../models/avaliacao.model";
import { MatDialog } from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AvaliacaoService} from "../avaliacao.service";
import {Empresa} from "../../../models/empresa";
import {AuthService} from "../../../modules/login/auth.service";
import {EmpresaService} from "../../empresa/empresa.service";
import {Pedido} from "../../../models/pedido.model";
import {PedidosService} from "../../../services/pedidos.service";
import {tap, map, mergeMap, take} from "rxjs/operators";
import {forkJoin} from "rxjs";
import {Role} from "../../../models/perfil-acesso.model";
import {PedidoDetalhesDialogComponent} from "../../gerenciador-pedidos/pedido-detalhes-dialog/pedido-detalhes-dialog.component";
import {AvaliacaoPaginationService} from "../avaliacao-pagination.service";
import {HasPermissionService} from "../../../modules/login/has-permission.service";

@Component({
  selector: "app-avaliacoes-list",
  templateUrl: "./avaliacoes-list.component.html",
  styleUrls: ["./avaliacoes-list.component.scss"]
})
export class AvaliacoesListComponent extends PageService<Avaliacao> implements OnInit, OnDestroy {

  empresa$: Observable<Empresa>;
  avaliacoes$: Observable<Avaliacao[]>;
  pedidos: Pedido[];

  avaliacoes: Avaliacao[];
  loggedUserHasReplyPermission: boolean = false;

  constructor(service: AvaliacaoService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              empresaService: EmpresaService,
              pedidoService: PedidosService,
              auth: AuthService,
              public avaliacaoPaginationService: AvaliacaoPaginationService,
              public hasPermissionService: HasPermissionService) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/avaliacoes");
    this.empresa$ = empresaService.getById(auth.currentEmpresa.id);

    this.hasPermissionService.hasPermission([Role[Role.AvaliacaoUpdate]]).subscribe((hasPermission: boolean) => {
      this.loggedUserHasReplyPermission = hasPermission;
    });

    this.avaliacoes$ = this.avaliacaoPaginationService.infiniteScroll()
      .pipe(
        mergeMap((avaliacoes: Avaliacao[]) => {
          if (avaliacoes.length === 0) {
            return of(avaliacoes);
          } else {
            return forkJoin(
              avaliacoes.map((avaliacao: Avaliacao) => {
                return pedidoService.getById(avaliacao.pedidoId, false)
                  .pipe(take(1));
              })
            ).pipe(
              tap((pedidos: Pedido[]) => {
                this.pedidos = pedidos;
              })).pipe(
              map(() => {
                return avaliacoes;
              }));
          }
        })
      );
  }

  verPedido(pedido: Pedido) {
    PedidoDetalhesDialogComponent.showDialog(this.dialog, pedido, false);
  }

  ngOnInit() {
  }

  loadMore() {
    this.avaliacaoPaginationService.loadMore();
  }

  newItem(): Avaliacao {
    return new Avaliacao();
  }

  ngOnDestroy(): void {
    this.avaliacaoPaginationService.resetService();
  }

}
