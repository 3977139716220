import {Component, Inject, Injectable} from "@angular/core";
import {ComplementoProdutoService} from "../../complemento-produto/complemento-produto.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import {BehaviorSubject, Observable, of} from "rxjs";
import {FormControl} from "@angular/forms";
import {ComplementoProduto, ComplementosProdutoByGrupo, groupComplementosProdutoByGrupo} from "../../../models/complemento-produto.model";
import {FlatTreeControl} from "@angular/cdk/tree";
import {SelectionModel} from "@angular/cdk/collections";
import {isNullOrUndefined} from "util";
import {LoadingService} from "../../../services/loading/loading.service";
import {isNotNullOrUndefined} from "../../../utils/commons";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ComplementoProdutoItemDatasource} from "../../../models/datasource/complemento-produto-item.datasource";
import {mergeMap, map} from "rxjs/operators";
import {ComplementoItem} from "../../../models/produto.model";
import {StringUtils} from "../../../utils/string-utils";

/** Classe com os nós da árvore usadas no componente tree do angular material*/
export class ComplementosProdutoByGrupoNode {
  children: ComplementosProdutoByGrupoNode[];
  item: string;
  complemento?: ComplementoProduto;

  constructor(comp: any = {}) {
    this.children = comp.children;
    this.item = comp.item;
    this.complemento = comp.complemento;
  }
}

/** Classe com os nós e níveis utilizado no componente de tree do angular material */
export class ComplementosProdutoByGrupoFlatNode {
  item: string;
  complemento?: ComplementoProduto;
  level: number;
  expandable: boolean;
}

@Injectable()
export class ChecklistDatabase {
  dataChange: BehaviorSubject<ComplementosProdutoByGrupoNode[]> = new BehaviorSubject<ComplementosProdutoByGrupoNode[]>([]);
  searchTerm$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  get data(): ComplementosProdutoByGrupoNode[] {
    return this.dataChange.value;
  }

  private _complementosItem: ComplementoItem[];

  set complementosItem(val: ComplementoItem[]) {
    this._complementosItem = val;
  }

  get complementosItem(): ComplementoItem[] {
    return this._complementosItem;
  }

  complementosProduto$: Observable<ComplementosProdutoByGrupo[]>;

  constructor(public service: ComplementoProdutoService) {
    this.initialize();
  }

  initialize() {
    this.complementosProduto$ = this.service.col$().pipe(
      map((complementosProduto: ComplementoProduto[]) => {
        return complementosProduto.filter((complementoProduto: ComplementoProduto) => {
          return !this.complementosItem.some(val => (val.complementoProduto && complementoProduto) && val.complementoProduto.id === complementoProduto.id);
        });
      }),
      mergeMap((complementosProduto: ComplementoProduto[]) => {
        return this.searchTerm$.asObservable().pipe(
          mergeMap((searchTerm: string) => {
            const complementosFiltered: ComplementoProduto[] = complementosProduto.filter((complementoProduto: ComplementoProduto) => {
              return StringUtils.normalize(complementoProduto.nome).toLowerCase().includes(StringUtils.normalize(searchTerm).toLowerCase());
            });

            return groupComplementosProdutoByGrupo(complementosFiltered);
          })
        );
      })
    );

    this.complementosProduto$.subscribe(data => {
      const _data = this.buildFileTreeArr(this.listToArray(data));
      this.dataChange.next(_data);
    });
  }

  listToArray(data: ComplementosProdutoByGrupo[]): any {
    return Object.keys(data).map(res => {
      return [data[res].grupo, Object.keys(data[res].complementos).map(comp => [data[res].complementos[comp].nome, data[res].complementos[comp]])];
    });
  }

  buildFileTreeArr(arr): ComplementosProdutoByGrupoNode[] {
    return arr.map(value => {
      const node = new ComplementosProdutoByGrupoNode();
      node.item = value[0];

      node.children = value[1].map(comp => {
        return new ComplementosProdutoByGrupoNode({
          children: undefined,
          item: comp[0],
          complemento: comp[1]
        });
      });

      return node;
    });
  }

  /** Transforma o objeto dos dados na lista de nodes */
  buildFileTree(obj: { [key: string]: any }, level: number): ComplementosProdutoByGrupoNode[] {
    return Object.keys(obj).reduce<ComplementosProdutoByGrupoNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new ComplementosProdutoByGrupoNode();
      node.item = `${key}`;

      if (isNullOrUndefined(value.complemento)) {
        node.children = this.buildFileTree(value, level + 1);
      } else {
        node.complemento = value.complemento;
      }

      return accumulator.concat(node);
    }, []);
  }
}

@Component({
  selector: "app-complemento-produto-list-dialog",
  templateUrl: "./complemento-produto-list-dialog.component.html",
  styleUrls: ["./complemento-produto-list-dialog.component.scss"],
  providers: [ChecklistDatabase]
})
export class ComplementoProdutoListDialogComponent {
  public static showDialog(dialog: MatDialog, data: ComplementoProdutoItemDatasource): Observable<any> {
    const config = {
      width: "600px",
      data: data
    };
    return dialog.open(ComplementoProdutoListDialogComponent, config).afterClosed();
  }

  public searchControl = new FormControl();
  public flatNodeMap = new Map<ComplementosProdutoByGrupoFlatNode, ComplementosProdutoByGrupoNode>();
  public nestedNodeMap = new Map<ComplementosProdutoByGrupoNode, ComplementosProdutoByGrupoFlatNode>();
  public treeControl: FlatTreeControl<ComplementosProdutoByGrupoFlatNode>;
  public treeFlattener: MatTreeFlattener<ComplementosProdutoByGrupoNode, ComplementosProdutoByGrupoFlatNode>;
  public dataSource: MatTreeFlatDataSource<ComplementosProdutoByGrupoNode, ComplementosProdutoByGrupoFlatNode>;
  public checklistSelection = new SelectionModel<ComplementosProdutoByGrupoFlatNode>(true);
  public loadingName: string = "ComplementoProdutoListDialogComponent";
  public selectedItemsLength: number = 0;

  constructor(public dialogRef: MatDialogRef<ComplementoProdutoListDialogComponent>,
              private service: ComplementoProdutoService,
              private checklistDatabase: ChecklistDatabase,
              private loadingService: LoadingService,
              @Inject(MAT_DIALOG_DATA) public complementoDatasource: ComplementoProdutoItemDatasource) {

    /** Variáveis do componente tree do angular material */
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<ComplementosProdutoByGrupoFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.checklistDatabase.complementosItem = this.complementoDatasource.data;

    this.loadingService.register(this.loadingName);

    checklistDatabase.dataChange.asObservable().subscribe(value => {
      this.dataSource.data = value;
      this.treeControl.expandAll();
      this.loadingService.timeOut(this.loadingName);
    });

    this.searchControl.valueChanges.subscribe(searchTerm => {
      this.checklistDatabase.searchTerm$.next(searchTerm);
    });
  }

  /** Funções do componente tree do angular material */
  getLevel = (node: ComplementosProdutoByGrupoFlatNode) => node.level;

  isExpandable = (node: ComplementosProdutoByGrupoFlatNode) => node.expandable;

  hasChild = (_: number, _nodeData: ComplementosProdutoByGrupoFlatNode) => _nodeData.expandable;

  getChildren = (node: ComplementosProdutoByGrupoNode): Observable<ComplementosProdutoByGrupoNode[]> => {
    return of(node.children);
  }

  /**
   * Converte o objeto que possui os nós recursivos em um objeto plano com os niveis de camadas
   */
  transformer = (node: ComplementosProdutoByGrupoNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new ComplementosProdutoByGrupoFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    flatNode.complemento = node.complemento;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  updateSelectedItemsLength(): void {
    this.selectedItemsLength = this.checklistSelection.selected.filter(complementosNode => {
      return isNotNullOrUndefined(complementosNode.complemento);
    }).length;
  }

  /** Verifica se todos os complementos de um grupo estão selecionados */
  descendantsAllSelected(node: ComplementosProdutoByGrupoFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
  }

  /** Verifica se o grupo possui alguns complementos selecionados */
  descendantsPartiallySelected(node: ComplementosProdutoByGrupoFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Seleciona/Desmarca todos os complementos de um grupo */
  todoItemSelectionToggle(node: ComplementosProdutoByGrupoFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);

    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node);
  }

  /** Seleciona/Desmarca um complemento e checa se essa mudança afeta o checkbox do grupo */
  todoLeafItemSelectionToggle(node: ComplementosProdutoByGrupoFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /** Checa todos os grupos quando um complemento é selecionado/desmarcado */
  checkAllParentsSelection(node: ComplementosProdutoByGrupoFlatNode): void {
    let parent: ComplementosProdutoByGrupoFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
    this.updateSelectedItemsLength();
  }

  /** Checa o estado do checkbox do grupo de acordo com os estados dos checkbox dos componentes desse grupo */
  checkRootNodeSelection(node: ComplementosProdutoByGrupoFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /** Retorna o grupo de um complemento */
  getParentNode(node: ComplementosProdutoByGrupoFlatNode): ComplementosProdutoByGrupoFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  submit() {
    const complementosSelecteds = this.checklistSelection.selected.filter(complementoNode => {
      return isNotNullOrUndefined(complementoNode.complemento);
    }).map(complementoNode => {
      return complementoNode.complemento;
    });
    this.dialogRef.close(complementosSelecteds);
  }
}
