import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import * as moment from "moment";
import {Moment} from "moment";
import {MatDialog} from "@angular/material/dialog";
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";
import {catchError, map, mergeMap, take} from "rxjs/operators";
import {Observable} from "rxjs/Rx";
import {PedidosService} from "../../../services/pedidos.service";
import {CustomersIuguService} from "../../../services/iugu/customers.iugu.service";
import {AuthService} from "../../../modules/login/auth.service";
import {ExtratoService} from "../../../services/extrato.service";
import {Extrato, StatusSaque, TipoExtrato, tipoExtratoToString} from "../../../models/extrato.model";
import * as firebase from "firebase";
import {ExtratoDetalhesDialogComponent} from "../extrato-detalhes-dialog/extrato-detalhes-dialog.component";
import {animate, style, transition, trigger} from "@angular/animations";
import {SaqueDialogComponent} from "../saque-dialog/saque-dialog.component";
import {EmpresaService} from "../../empresa/empresa.service";
import {Empresa} from "../../../models/empresa";
import {of} from "rxjs/observable/of";
import {DialogService} from "../../../services/dialog/dialog.service";
import {$query} from "../../../services/firebase/criteria/query";
import {Criterion} from "../../../services/firebase/criteria/criterion";
import {Role} from "../../../models/perfil-acesso.model";
import {StatusInvoice} from "../../../models/iugu/invoice.iugu.model";
import {OrderByDesc} from "../../../services/firebase/criteria/order-by-desc";
import {OrderByAsc} from "../../../services/firebase/criteria/order-by-asc";
import {PagamentoOnline} from "../../../models/pagamento-online.model";
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: "app-extrato-pagamento-online-list",
  templateUrl: "./extrato-pagamento-online-list.component.html",
  styleUrls: ["./extrato-pagamento-online-list.component.scss"],
  animations: [
    trigger("slideIn", [
      transition(":enter", [
        style({transform: "translateX(-10%)"}),
        animate("150ms ease-in", style({transform: "translateX(0%)"}))
      ])
    ]),
    trigger("slideOut", [
      transition(":leave", [
        style({transform: "translateX(10%)"}),
        animate("150ms ease-out", style({transform: "translateX(-10%)"}))
      ])
    ])
  ]
})
export class ExtratoPagamentoOnlineListComponent implements OnInit {

  Role = Role;
  StatusInvoice = StatusInvoice;
  StatusSaque = StatusSaque;
  extratosObservable$: Observable<Extrato[]>;
  empresaTemSubconta: Observable<boolean>;
  extratoGlobal: boolean = false;
  form: FormGroup;
  empresas: Observable<Empresa[]>;
  currentEmpresa: Empresa;
  currentTipo: string = null;

  readonly nome_fantasia_filter: string = "nome_fantasia";
  readonly razao_social_filter: string = "razao_social";

  TipoExtrato = TipoExtrato;
  tipoExtratoToString = tipoExtratoToString;
  isNullOrUndefined = isNullOrUndefined;

  saldos$: Observable<{
    saldoPendente: number;
    saldoLiberado: number;
    saldoDisponivel: number;
  }>;

  saldos: {
    saldoPendente: number;
    saldoLiberado: number;
    saldoDisponivel: number;
  };

  auxEmpresas: Empresa[];
  auxEmpresasPorCnpj: Empresa[];

  constructor(public formBuilder: FormBuilder,
              public pedidosService: PedidosService,
              public customersIuguService: CustomersIuguService,
              public auth: AuthService,
              public extratoService: ExtratoService,
              public dialog: MatDialog,
              public dialogService: DialogService,
              public empresaService: EmpresaService) {
    if (isNotNullOrUndefined(this.auth.currentEmpresa)) { // Caso tenha uma empresa selecionada no sistema
      this.empresaTemSubconta = this.empresaService.getById(this.auth.currentEmpresa.id).pipe(
        mergeMap((empresa: Empresa) => {
          // Salvar a empresa selecionada no sistema
          this.currentEmpresa = empresa;

          // Caso a empresa tenha pagamento online cadastrado
          if (isNotNullOrUndefined(empresa.pagamentoOnline) && isNotNullOrUndefined(empresa.pagamentoOnline.accountId)) {
            //Buscar totalização de saldo
            this.buscarDadosSaldo(empresa);

            // Buscar empresas para o filtro de empresa
            this.empresas = this.empresaService.col$(
              $query(new Criterion("pagamentoOnline.accountId", "==", empresa.pagamentoOnline.accountId)))
              .pipe(take(1));

            // Buscar os registros de extrato
            this.extratosObservable$ = this.getExtratosList(empresa.pagamentoOnline);
          }

          // Retornar se a empresa tem pagamento online
          return of(isNotNullOrUndefined(empresa.pagamentoOnline) && isNotNullOrUndefined(empresa.pagamentoOnline.accountId));
        })
      );
    } else { //Caso esteja acessando o extrato global
      this.extratoGlobal = true;

      // Buscar as empresas para o filtro de empresas
      this.empresas = this.empresaService.col$($query(
        new Criterion("pagamentoOnline.accountId", "!=", false),
        new OrderByAsc("pagamentoOnline.accountId")
      )).pipe(take(1), map((empresas) => {
        // Salvar registros de empresas para caso hája mudanção no tipo de filtro
        this.auxEmpresas = empresas.sort((a, b) => a.nomeFantasia.localeCompare(b.nomeFantasia));

        // Salvar registros de empresas filtradas por cnpj, caso hája mudança no tipo de filtro
        this.auxEmpresasPorCnpj = empresas.reduce((array, current) => {
          if (!array.some(item => item.cnpj === current.cnpj)) {
            array.push(current);
          }
          return array;
        }, []).sort((a, b) => a.razaoSocial.localeCompare(b.razaoSocial));

        // Zerar a totalização de saque
        this.saldos$ = of({
          saldoPendente: 0,
          saldoLiberado: 0,
          saldoDisponivel: 0
        });

        // Buscar os registros de extrato
        this.extratosObservable$ = this.getExtratosList();

        // Retorna o auxEmpresa, pois ela já está ordenada
        return this.auxEmpresas;
      }));
    }

    this.form = this.formBuilder.group({
      dataInicial: [moment(new Date().setHours(0, 0))],
      dataFinal: [moment(new Date().setHours(23, 59))],
      tipoFiltro: [this.nome_fantasia_filter],
      tipo: [],
      empresa: [isNotNullOrUndefined(this.auth.currentEmpresa) ? this.auth.currentEmpresa : null],
      status: [],
      numero: []
    });
  }

  ngOnInit() {
    this.form.get("tipoFiltro").valueChanges.subscribe(value => {
      if (value === this.razao_social_filter) {
        // Selecionar a primeira empresa que tenha o mesmo cnpj da empresa selecionada
        // (isso faz com que, ao mudar o tipo de filtro para CNPJ, o filtro de empresa já venha preenchido com uma empresa com o mesmo cnpj)
        const selectedEmpresa = this.form.get("empresa").value;
        if (isNotNullOrUndefined(selectedEmpresa)) {
          this.form.get("empresa").patchValue(this.auxEmpresasPorCnpj.find(empresa => empresa.cnpj === selectedEmpresa.cnpj));
        }

        // Troca a lista de empresas por id, pela lista de empresas por cnpj
        this.empresas = of(this.auxEmpresasPorCnpj);

      } else {
        // Troca a lista de empresas por cnpj, pela lista de empresas por id
        this.empresas = of(this.auxEmpresas);
      }
    });

    this.form.get("tipo").valueChanges.subscribe(() => {
      // Limpar o campo de número
      this.form.get("numero").setValue(null);
    });
  }

  getExtratosList(pagamentoOnline?: PagamentoOnline): Observable<any[]> {
    const dataInicial = moment(this.form.get("dataInicial").value).startOf("day").toDate();
    const dataFinal = moment(this.form.get("dataFinal").value).endOf("day").toDate();
    const tipoFilter = this.form.get("tipo").value;
    const statusFilter = this.form.get("status").value;
    const numeroFilter = this.form.get("numero").value;

    // Filtros para a busca de extratos
    const filters: Criterion[] = [];

    // Filtrar por numero de pedido
    if (!isEmpty(numeroFilter)) {
      filters.push(new Criterion("pedido.numero", "==", numeroFilter.toString()));
    } else {
      // Ordenar por data decrescente
      filters.push(new OrderByDesc("data"));

      // Filtrar por data
      filters.push(new Criterion("data", ">=", dataInicial));
      filters.push(new Criterion("data", "<=", dataFinal));

      // Filtrar por tipo
      if (tipoFilter) {
        filters.push(new Criterion("tipo", "==", tipoFilter));
      }

      // Filtrar status da fatura do pedido
      if (tipoFilter === TipoExtrato[TipoExtrato.pedido] && statusFilter) {
        filters.push(new Criterion("statusFatura", "==", statusFilter));
      }
    }

    // Filtrar por Empresa
    const empresaFilter = this.form.get("empresa").value;
    const filtrarPor = this.form.get("tipoFiltro").value;
    if (empresaFilter) {
      if (isNullOrUndefined(filtrarPor) || filtrarPor === this.nome_fantasia_filter) {
        // Se o tipo do filtro for por empresa
        filters.push(new Criterion("empresa.id", "==", empresaFilter.id));
      }
    }

    return this.extratoService.getExtratoBy(pagamentoOnline, filters).pipe(take(1),
      mergeMap((extratos) => {
        // Caso não tenha um tipo informado ou caso o tipo informado seja de saque
        if ((isNullOrUndefined(tipoFilter) || tipoFilter === TipoExtrato[TipoExtrato.saque]) && (filtrarPor === this.nome_fantasia_filter && empresaFilter)) {
          // Filtros para buscar os extratos de saque
          const saquesFilters: Criterion[] = [];
          saquesFilters.push(new OrderByDesc("data"));
          saquesFilters.push(new Criterion("data", ">=", dataInicial));
          saquesFilters.push(new Criterion("data", "<=", dataFinal));
          saquesFilters.push(new Criterion("tipo", "==", TipoExtrato[TipoExtrato.saque]));

          // Caso a página de extrato seja global e tenha uma empresa selecionada no filtro, adicionar o accountId da empresa na busca de extratos
          if (this.extratoGlobal && empresaFilter) {
            pagamentoOnline = empresaFilter.pagamentoOnline;
          }

          // Chamada que irá buscar os registros de extrato, e irá adicioná-los aos registros já encontrados
          return this.extratoService.getExtratoBy(pagamentoOnline, saquesFilters).pipe(take(1), map((saques) => {
            // Adicionar os saques encontrados na lista de extratos
            for (const saque of saques) {
              extratos.push(saque);
            }
            // Retorna os extratos já ordenados pela data
            return extratos.sort((a, b) => b.data.toDate().getTime() - a.data.toDate().getTime());
          }));
        }
        return of(extratos);
      }),
      catchError(err => {
        this.dialogService
          .messageDialog()
          .message(err)
          .show();

        return of(new Array<Extrato>());
      }));
  }

  filterButtom() {
    // Salva o tipo filtrado, para alterar os valores a serem exibídos nas totalizações da lista
    this.currentTipo = this.form.get("tipo").value;

    if (!this.extratoGlobal) { // Caso hája uma empresa selecionada no sistema
      // Buscar os registros de extrato
      this.extratosObservable$ = this.getExtratosList(this.currentEmpresa.pagamentoOnline);

    } else { // Caso a página de extrato seja global
      if (isNotNullOrUndefined(this.form.get("empresa").value)) { // Caso tenha uma empresa selecionada no filtro
        const empresa = this.form.get("empresa").value;

        // Buscar dados da totalização de saque
        this.buscarDadosSaldo(empresa);

        // Buscar os registros de extrato
        this.extratosObservable$ = this.getExtratosList(empresa.pagamentoOnline);
      } else { // Caso não tenha uma empresa selecionada no filtro
        // Buscar os registros de extrato
        this.extratosObservable$ = this.getExtratosList();

        // Zerar os dados da totalização de saque
        this.saldos$ = of({
          saldoPendente: 0,
          saldoLiberado: 0,
          saldoDisponivel: 0
        });
      }
    }
  }

  // Abrir os detalhes de extrato
  openExtratoDialog(extrato: Extrato) {
    ExtratoDetalhesDialogComponent.showDialog(this.dialog, extrato);
  }

  // Abrir os detalhes de saque
  openSaqueDialog() {
    SaqueDialogComponent.showDialog(this.dialog).subscribe(val => {
      if (val) {
        this.extratosObservable$ = this.getExtratosList(this.currentEmpresa.pagamentoOnline);
        this.buscarDadosSaldo(this.currentEmpresa);
      }
    });
  }

  // Muda o formato das datas dos itens da lista, para moment
  timestampToMoment(data: Timestamp): Moment {
    return moment(data.toDate());
  }

  // Busca os tipos de extrato
  getTipoExtrato(): string[] {
    return Object.keys(this.TipoExtrato).map(val => val);
  }

  // Método para buscar as totalizações de saque
  private buscarDadosSaldo(empresa: Empresa) {
    this.saldos$ = this.customersIuguService.buscarDadosSaque(empresa.cnpj).pipe(
      map(value => {
        return {
          saldoPendente: value.saldoPendente,
          saldoLiberado: value.saldoLiberado,
          saldoDisponivel: value.saldoDisponivel
        };
      }));
  }

  // Calcula o valor total dos pedidos a partir do seus status
  getPedidoStatusValue(extratos: Extrato[], status: string): number {
    return extratos.filter(item => item.statusFatura === status).reduce((sum, current) => sum + current.valor, 0);
  }

  // Calcula o valor total dos saques, a partir de seus status
  getSaqueStatusValue(extratos: Extrato[], status: string): number {
    return extratos.filter(item => item.statusSaque === status).reduce((sum, current) => sum + current.valor, 0);
  }

  // Calcula o valor total dos extratos, a partir do seu tipo
  getTipoValue(extratos: Extrato[], tipo: string): number {
    return extratos.filter(item => item.tipo === tipo).reduce((sum, current) => sum + current.valor, 0);
  }
}
