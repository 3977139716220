import {Directive, ElementRef, HostListener, Inject, Input, OnInit, Renderer2} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

/**
 * Diretiva de máscara para campos com valores inteiros apenas.
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: "[pomMaskIntOnly]",
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: PomMaskIntOnlyDirective,
    multi: true
  }]
})
export class PomMaskIntOnlyDirective implements ControlValueAccessor, OnInit {

  onTouched: any;
  onChange: any;

  separadorMilhar: string;

  @Input("pomMaskIntOnly") config: {
    milhar: string
  };

  constructor(@Inject(Renderer2) private renderer: Renderer2, @Inject(ElementRef) private el: ElementRef) {
  }

  ngOnInit() {
    this.separadorMilhar = this.config.milhar || ".";
  }

  writeValue(value: any): void {

    if (value != null && value !== "") {
      if (!isNaN(value)) {
        value = parseInt(value, 10).toFixed(0);
      }
      this.renderer.setAttribute(this.el.nativeElement, "value", this.aplicarMascara(String(value)));
    } else {
      this.renderer.setAttribute(this.el.nativeElement, "value", "");
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @HostListener("keyup", ["$event"])
  onKeyup($event: any) {

    const valor: string = this.aplicarMascara($event.target.value);

    if (valor === "") {
      this.onChange("");
      $event.target.value = "";
      return;
    }

    this.onChange(parseInt(valor, 10));

    $event.target.value = valor;
  }

  @HostListener("blur", ["$event"])
  onBlur($event: any) {
    this.onTouched($event);
  }

  /**
   * Aplica a máscara.
   *
   * @return string
   * @param valorConverter
   */
  aplicarMascara(valorConverter: string): string {
    const valorNum = parseInt(valorConverter.replace(/\D/g, ""), 10);
    return isNaN(valorNum) ? "" : valorNum.toString();
  }
}
