import {Component, Inject, OnInit} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {PedeQueChega, StatusPedeQueChega, StatusPedeQueChegaToString, styleByStatus} from "../../../models/pedequechega.model";
import {Observable} from "rxjs";
import {DialogService} from "../../../services/dialog/dialog.service";
import {PedidoDetalhesDialogComponent} from "../../gerenciador-pedidos/pedido-detalhes-dialog/pedido-detalhes-dialog.component";
import {Entregador} from "../../../models/entregador.model";
import {$query} from "../../../services/firebase/criteria/query";
import {Criterion} from "../../../services/firebase/criteria/criterion";
import {EntregadoresService} from "../../entregadores/entregadores.service";
import {isNotNullOrUndefined} from "../../../utils/commons";
import {SelectEntregadorDialogComponent} from "../../entregadores/select-entregador-dialog/select-entregador-dialog.component";
import {isNullOrUndefined} from "util";
import {CentralEntregasService} from "../../central-entregas/central-entregas.service";
import {Role} from "../../../models/perfil-acesso.model";

@Component({
  selector: "app-entregas-detalhes-dialog",
  templateUrl: "./entregas-detalhes-dialog.component.html",
  styleUrls: ["./entregas-detalhes-dialog.component.scss"]
})
export class EntregasDetalhesDialogComponent implements OnInit {

  Role = Role;

  static showDialog(dialog: MatDialog, entrega: PedeQueChega): Observable<any> {
    const config = {
      width: "900px",
      data: {
        entrega: entrega
      }
    };
    return dialog.open(EntregasDetalhesDialogComponent, config).afterClosed();
  }

  entregadores: Observable<Entregador[]>;
  entrega: PedeQueChega;
  StatusPedeQueChega = StatusPedeQueChega;
  newEntregador: Entregador;
  newStatus: string;
  motivo: string;
  readonly statusPedeQueChegaToString = StatusPedeQueChegaToString;
  readonly styleByStatus = styleByStatus;
  readonly isNotNullOrUndefined = isNotNullOrUndefined;

  constructor(public dialogRef: MatDialogRef<PedidoDetalhesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogService: DialogService,
              public dialog: MatDialog,
              entregadoresService: EntregadoresService,
              public centralService: CentralEntregasService) {
    this.entrega = data["entrega"];
    this.entregadores = entregadoresService.col$($query(new Criterion("inactive", "==", false)));
  }

  changeStatus() {
    if (isNullOrUndefined(this.newStatus)) {
      this.newStatus = this.entrega.status;
    }
    if (this.newStatus === StatusPedeQueChega[StatusPedeQueChega.CANCELADO]) {
      // CANCELADO -> APROVADO
      this.newStatus = StatusPedeQueChega[StatusPedeQueChega.APROVADO];
      this.motivo = null;
      if (isNotNullOrUndefined(this.entrega.entregador)) {
        this.newEntregador = this.entrega.entregador;
      }
    } else if (this.newStatus === StatusPedeQueChega[StatusPedeQueChega.APROVADO] || this.newStatus === StatusPedeQueChega[StatusPedeQueChega.PENDENTE]) {
      // APROVADO/PENDENTE -> CANCELADO

      this.dialogService.promptDialog()
        .title("Cancelar entrega?")
        .message("Informe o motivo de cancelamento. Após cancelar essa entrega não será possivel selecionar um novo entregador para realizar a entrega. Tem certeza?")
        .show()
        .subscribe(message => {
          if (message) {
            this.newStatus = StatusPedeQueChega[StatusPedeQueChega.CANCELADO];
            this.motivo = message;
            if (isNotNullOrUndefined(this.entrega.entregador)) {
              this.newEntregador = this.entrega.entregador;
            }
          }
        });
    }
  }

  selecionarEntregador() {
    SelectEntregadorDialogComponent.openDialog(this.dialog).subscribe(result => {
      if (result) {
        this.newEntregador = result;
        this.newStatus = StatusPedeQueChega[StatusPedeQueChega.APROVADO];
      }
    });
  }

  save() {
    if (this.newStatus === StatusPedeQueChega[StatusPedeQueChega.APROVADO] && isNullOrUndefined(this.newEntregador)) {
      this.dialogService.messageDialog()
        .title("Selecione um entregador")
        .message("É necessário selecionar um entregador para poder salvar")
        .show();
    } else {
      if (isNotNullOrUndefined(this.newStatus)) {
        this.centralService.save(this.entrega, this.newStatus, this.newEntregador, this.motivo)
          .subscribe(() => {
            this.closeDialog();
          }, error => {
            this.dialogService
              .messageDialog()
              .message(error)
              .show();
          });
      } else {
        this.closeDialog();
      }
    }
  }

  getEndereco() {
    let endereco = this.entrega.pedido.enderecoEntrega.logradouro;
    if (this.entrega.pedido.enderecoEntrega.numero) {
      endereco += ", " + this.entrega.pedido.enderecoEntrega.numero;
    }
    if (this.entrega.pedido.enderecoEntrega.bairro) {
      endereco += ", " + this.entrega.pedido.enderecoEntrega.bairro.nome;
    }
    return endereco;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  getMotivoCancelamento(): string {
    if (this.entrega.status === StatusPedeQueChega[StatusPedeQueChega.CANCELADO]) {
      return this.entrega.logs[this.entrega.logs.length - 1].motivo;
    }
  }
}
