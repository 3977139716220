import {Cidade} from "./cidade.model";
import {FireModel} from "./fire.model";

export class Bairro extends FireModel {

  public nome: string;
  public cidade: Cidade;
  public importado: boolean;
  /*public valorEntrega: number;
  public bloqueado: boolean;*/

  constructor(bairro: any = {}) {
    super(bairro.id, bairro.ref);
    this.id = bairro.id;
    this.ref = bairro.ref;
    this.nome = bairro.nome;
    this.cidade = new Cidade(bairro.cidade);
    this.importado = bairro.importado;
  }

  toAny(): any {
    const a: any = {};
    if (this.id) a.id = this.id;
    if (this.ref) a.ref = this.ref;
    if (this.nome) a.nome = this.nome;
    if (this.cidade) a.cidade = this.cidade.toAny();
    if (this.importado) a.importado = this.importado;
    return a;
  }

}
