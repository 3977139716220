import {Injectable} from "@angular/core";
import {IuguService} from "./iugu.service";
import {InvoiceIugu} from "../../models/iugu/invoice.iugu.model";
import {Observable} from "rxjs";
import {AuthService} from "../../modules/login/auth.service";
import {AngularFireFunctions} from "@angular/fire/functions";
import {Empresa} from "../../models/empresa";

@Injectable({
  providedIn: "root"
})
export class InvoiceIuguService extends IuguService<InvoiceIugu> {

  constructor(public fns: AngularFireFunctions, public authService: AuthService) {
    super(fns);
  }

  listPendingInvoicesByEmpresa(empresa?: Empresa): Observable<InvoiceIugu[]> {
    return super.requestList("call-iugu-invoices-listPendingInvoicesByEmpresa", {iuguCustomerId: empresa ? empresa.iuguCustomerId : this.authService.currentEmpresa.iuguCustomerId});
  }

  generateExpiredInvoice(prevInvoiceId: string, newDueDate: string): Observable<any> {
    return this.fns.httpsCallable("call-iugu-invoices-generateExpiredInvoice")({prevInvoiceId: prevInvoiceId, newDueDate: newDueDate});
  }

  checkInvoiceAndGenerate(iuguInvoiceId: string, segundaVia?: boolean): Observable<any> {
    return this.fns.httpsCallable("call-iugu-invoices-checkInvoiceAndGenerate")({iuguInvoiceId: iuguInvoiceId, segundaVia: segundaVia});
  }

  getInvoicesByEmpresa(): Observable<any> {
    return super.requestList("call-iugu-invoices-listInvoicesByCustomer", {iuguCustomerId: this.authService.currentEmpresa.iuguCustomerId});
  }

  listInvoicesPaginated(createdAtFrom: string, createdAtTo: string, statusFilter: string, customerId: string, sortByField: string, direction: string): Observable<any> {
    return super.requestListPaginated("call-iugu-invoices-listInvoicesPaginated", {
      createdAtFrom,
      createdAtTo,
      statusFilter,
      customerId,
      sortByField,
      direction
    });
  }

  darBaixaInvoice(iuguInvoiceId: string, motivo: string): Observable<any> {
    return this.fns.httpsCallable("call-iugu-invoices-darBaixaInvoice")({iuguInvoiceId: iuguInvoiceId, motivo: motivo});
  }

  generateParcialInvoice(cnpj: string, emitInvoice?: boolean, vencimento?: any): Observable<any> {
    return this.fns.httpsCallable("call-iugu-invoices-generateParcialInvoice")({cnpj: cnpj, emitInvoice: emitInvoice, vencimento: vencimento});
  }

  getInvoiceById(invoiceId: string): Observable<any> {
    return this.fns.httpsCallable("call-iugu-invoices-getInvoiceById")({invoiceId: invoiceId, empresaId: this.authService.currentEmpresa.id});
  }

  deserialize(value: any): InvoiceIugu {
    return new InvoiceIugu(value);
  }
}
