import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import { LoadingTopbarService} from "../../../services/loading-topbar.service";
import {Subscription} from "rxjs";

export interface LoaderState {
  show: boolean;
}

@Component({
  selector: "loading-topbar",
  templateUrl: "./loading-topbar.component.html",
  styleUrls: ["./loading-topbar.component.scss"]
})
export class LoadingTopbarComponent implements OnInit, OnDestroy {

  public show: boolean = false;
  private subscription: Subscription;

  constructor(private loadingService: LoadingTopbarService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.subscription = this.loadingService
      .loaderState
      .subscribe((state: LoaderState) => {
        this.show = state.show;
        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
