import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {CovalentModule} from "../covalent/covalent.module";
import {MaterialDesignerModule} from "../material-designer/material-designer.module";
import {NgxFileDropModule} from "ngx-file-drop";
import {ImageCropperModule} from "ngx-image-cropper";
import {ClipboardModule} from "ngx-clipboard";

// Masks
import {TextMaskModule} from "angular2-text-mask";

// Directives
import {HasPermissionDirective} from "./directives/has-permission.directive";
import {PomMaskIntOnlyDirective} from "./directives/pom-mask-int-only.directive";
import {PomMaskMoneyDirective} from "./directives/pom-mask-money.directive";
import {ResponsiveSpanDirective} from "./directives/responsive-span.directive";
import {ScrollableDirective} from "./directives/scrollable.directive";
import {StatusFaturaChipDirective} from "./directives/status-fatura-chip.directive";

// Pipes
import {FilterPipe} from "./pipes/filter.pipe";
import {DocPipe} from "./pipes/doc-ref.pipe";
import {PhonePipe} from "./pipes/phone.pipe";
import {SafePipe} from "./pipes/safe.pipe";
import {FilterPipeOr} from "./pipes/filter.pipe";
import {TimezonePipe} from "./pipes/timezone.pipe";

// Components
import {CardFormComponent} from "./components/layout/card-form/card-form.component";
import {CardScrollableComponent} from "./components/layout/card-scrollable/card-scrollable.component";
import {ScreenFrameComponent} from "./components/layout/screen-frame/screen-frame.component";
import {ScreenFrameListsComponent} from "./components/layout/screen-frame-lists/screen-frame-lists.component";
import {NoRecordsFoundComponent} from "./components/layout/no-records-found/no-records-found.component";
import {CardScrollablePaginatorComponent} from "./components/layout/card-scrollable-paginator/card-scrollable-paginator.component";
import {CropperDialogComponent} from "./components/layout/cropper/cropper-dialog/cropper-dialog.component";
import {DialogFormComponent} from "./components/layout/dialog-form/dialog-form.component";
import {ExtendedFabComponent} from "./components/layout/extended-fab/extended-fab.component";
import {ImageComponent} from "./components/layout/image/image.component";
import {InputChipAutocompleteComponent} from "./components/layout/input-chip-autocomplete/input-chip-autocomplete.component";
import {ListMoreOptionsComponent} from "./components/layout/list-more-options/list-more-options.component";
import {LoadingTopbarComponent} from "./components/layout/loading-topbar/loading-topbar.component";

// Services
import {CropperService} from "./services/cropper.service";
import {LoadingTopbarService} from "./services/loading-topbar.service";
import {EmpresaSelectComponent} from "./components/empresa-select/empresa-select.component";
import {ProdutoSelectComponent} from "./components/produto-select/produto-select.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CovalentModule,
    MaterialDesignerModule,
    NgxFileDropModule,
    ImageCropperModule,
    TextMaskModule,
    ClipboardModule
  ],
  providers: [
    CropperService,
    LoadingTopbarService,
  ],
  declarations: [
    FilterPipe,
    DocPipe,
    PhonePipe,
    SafePipe,
    FilterPipeOr,
    TimezonePipe,
    HasPermissionDirective,
    PomMaskIntOnlyDirective,
    PomMaskMoneyDirective,
    ResponsiveSpanDirective,
    ScrollableDirective,
    StatusFaturaChipDirective,
    CardFormComponent,
    CardScrollableComponent,
    ScreenFrameComponent,
    ScreenFrameListsComponent,
    NoRecordsFoundComponent,
    CardScrollablePaginatorComponent,
    CropperDialogComponent,
    DialogFormComponent,
    ExtendedFabComponent,
    ImageComponent,
    InputChipAutocompleteComponent,
    ListMoreOptionsComponent,
    LoadingTopbarComponent,
    EmpresaSelectComponent,
    ProdutoSelectComponent
  ],
  exports: [
    TextMaskModule,
    FilterPipe,
    DocPipe,
    PhonePipe,
    SafePipe,
    FilterPipeOr,
    TimezonePipe,
    HasPermissionDirective,
    PomMaskIntOnlyDirective,
    PomMaskMoneyDirective,
    ResponsiveSpanDirective,
    ScrollableDirective,
    StatusFaturaChipDirective,
    CardFormComponent,
    CardScrollableComponent,
    ScreenFrameComponent,
    ScreenFrameListsComponent,
    NoRecordsFoundComponent,
    CardScrollablePaginatorComponent,
    CropperDialogComponent,
    DialogFormComponent,
    ExtendedFabComponent,
    ImageComponent,
    InputChipAutocompleteComponent,
    ListMoreOptionsComponent,
    LoadingTopbarComponent,
    EmpresaSelectComponent,
    ProdutoSelectComponent,
    ClipboardModule
  ],
  entryComponents: [
    CropperDialogComponent
  ]
})
export class SharedModule {
}
