import {Injectable} from "@angular/core";
import {Repository} from "../repository/Repository";
import {EmpresaComissao} from "../models/empresa-comissao.model";
import {AngularFirestore} from "@angular/fire/firestore";
import {AuthService} from "../modules/login/auth.service";
import {Observable} from "rxjs";
import {mergeMap} from "rxjs/operators";
import { Empresa } from "../models/empresa";
import {PoMQuery} from "./firebase/criteria/query";
import {Criterion} from "./firebase/criteria/criterion";
import {OrderBy} from "./firebase/criteria/order-by";
import {OrderByDesc} from "./firebase/criteria/order-by-desc";

@Injectable({
  providedIn: "root"
})
export class EmpresaComissaoService extends Repository<EmpresaComissao> {

  constructor(db: AngularFirestore,
              public auth: AuthService) {
    super(db, "companies-commissions", true);
  }

  getEmpresaComissoesCurrentEmpresa(criterions: Criterion[]): Observable<EmpresaComissao[]> {
    const queryFn: PoMQuery = new PoMQuery();
    for (const criterion of criterions) {
      queryFn.add(criterion);
    }
    return this.auth.currentEmpresaObservable().pipe(
      mergeMap((currentEmpresa: Empresa) => {
        return super.col$(queryFn.add(new Criterion("empresaId", "==", currentEmpresa.id)));
      })
    );
  }

  protected orderBy(): OrderBy {
    return new OrderByDesc("dataInicial");
  }
}
