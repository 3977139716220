import { Component, OnInit, Inject } from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";

@Component({
  selector: "app-delivery-bairros-import-dialog",
  templateUrl: "./delivery-bairros-import-dialog.component.html",
  styleUrls: ["./delivery-bairros-import-dialog.component.scss"]
})
export class DeliveryBairrosImportDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeliveryBairrosImportDialogComponent>,
              public dialogService: DialogService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  fechar() {
    this.dialogRef.close(null);
  }

  importar($event) {
    const fileReader = new FileReader();
    if ($event.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      fileReader.readAsArrayBuffer($event.target.files[0]);
      fileReader.onloadend = (e) => {
        this.dialogRef.close(fileReader.result);
      };
    } else {
      this.dialogService.messageDialog()
        .title("Formato de arquivo inválido!")
        .message("O arquivo importado precisa ser no formato .xlsx")
        .show();
    }
  }

}
