import {IFormCanDeactivate} from "../../../guards/iform-canDeactivate";
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MaskUtil} from "../../../utils/mask-util";
import {MatDialog} from "@angular/material/dialog";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {EmpresaService} from "../empresa.service";
import {PageService} from "../../../services/page.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DiaVencimento, Empresa, StatusEmpresa, StatusLogActivity, TipoPessoa, TipoPessoaToString} from "../../../models/empresa";
import {Telefone} from "../../../models/telefone";
import {from, merge, Observable, of, Subject, Subscription, timer} from "rxjs";
import {finalize, mergeMap, pairwise, skip, startWith, take, takeUntil, tap} from "rxjs/operators";
import {ImageUploaderService} from "../../../services/image-uploader/image-uploader.service";
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UsersService} from "../../users/users.service";
import {AppUser} from "../../../models/appUser";
import {HubDoDevService} from "../../../services/hub-do-dev.service";
import * as firebase from "firebase";
import {Cidade} from "../../../models/cidade.model";
import {CidadeService} from "../../cidade/cidade.service";
import {AngularFireFunctions} from "@angular/fire/functions";
import {Localizacao} from "../../../models/localizacao";
import {EmpresaEnderecoFormComponent} from "../empresa-endereco-form/empresa-endereco-form.component";
import * as moment from "moment";
import {AuthService} from "../../../modules/login/auth.service";
import {EmpresaTelefoneDialogComponent} from "../empresa-telefone-dialog/empresa-telefone-dialog.component";
import {CpfCnpjValidationService} from "../../../services/cpf-cnpj-validation.service";
import {ConfigCropper} from "../../../models/config-cropper.model";
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: "empresa-form",
  templateUrl: "./empresa-form.component.html",
  styleUrls: ["./empresa-form.component.scss"]
})

export class EmpresaFormComponent extends PageService<Empresa> implements OnInit, IFormCanDeactivate, OnDestroy {

  @ViewChild(EmpresaEnderecoFormComponent, {static: true})
  empresaEnderecoFormComponent: EmpresaEnderecoFormComponent;

  public form: FormGroup;

  readonly DiaVencimento = DiaVencimento;
  readonly TipoPessoaToString = TipoPessoaToString;
  readonly TipoPessoa = TipoPessoa;

  readonly maskEmail = MaskUtil.maskEmail;
  readonly maskCnpj = MaskUtil.maskCnpj;
  readonly maskCpf = MaskUtil.maskCpf;
  readonly maskDate = MaskUtil.maskDate;

  // Booleano que verifica o status da atualização de um formulário
  formMudou: boolean = null;

  consultores: Observable<AppUser[]>;
  comissaoConsultor: FormControl;

  Object = Object;
  objectErrors: any = {};

  _disableBuscarButton: boolean = false;
  getEmpresaPessoaSubscription: Subscription;
  disableSaveButton: boolean = false;
  localizacaoStatus: string;

  snackMessage;
  timerSubject = new Subject<void>();

  subtitle: string = "Informe os dados abaixo";

  loggedUser: AppUser;
  statusLogActivities$: Observable<StatusLogActivity[]>;
  statusLogIsEmpty: boolean = false;

  // Variável para verificar se os horários da empresa batem com os horários do PQC, caso ele esteja selecionado.
  pqcErrorStatus: boolean = false;

  // CPF/CNPJ inicial da empresa, caso ela já seja cadastrada
  cnpjOriginal: string;

  constructor(public service: EmpresaService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              public formBuilder: FormBuilder,
              private imageUploader: ImageUploaderService,
              public userService: UsersService,
              public cidadeService: CidadeService,
              public hubDev: HubDoDevService,
              public authService: AuthService,
              public fns: AngularFireFunctions,
              public cpfCnpjValidation: CpfCnpjValidationService) {

    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/empresa/");

    this.authService.currentUser.pipe(take(1)).subscribe(user => {
      this.loggedUser = user;
    });

    this.form = this.formBuilder.group({
      nomeFantasia: ["", [Validators.required, Validators.minLength(5)]],
      razaoSocial: ["", [Validators.required, Validators.minLength(5)]],
      descricao: [""],
      pessoa: [TipoPessoa.JURIDICA, [Validators.required]],
      cnpj: ["", [Validators.required, Validators.pattern(MaskUtil.regexCPFCNPJ(this.item.pessoa)), this.validar.bind(this)]],
      dataNascimento: [],
      email: ["", [Validators.email]],
      observacao: [""],
      comissao: ["", [Validators.required, this.validatorComissao.bind(this)]],
      diaVencimento: ["", [Validators.required]],
      consultor: [{value: "", disabled: this.disabledComissaoConsultor()}, [Validators.required]],
      dataFechamentoContrato: ["", [this.validatorDataFechamento.bind(this), Validators.pattern(MaskUtil.regexDate)]],
      logoUrl: ["", [Validators.required]],
      bannerUrl: ["", [Validators.required]],
      localizacao: [new Localizacao(), [Validators.required]],
      emiteNfseAutomatico: [false],
      tags: [[]],
    });

    this.comissaoConsultor = new FormControl({value: "", disabled: this.disabledComissaoConsultor()}, [Validators.required]);

    this.consultores = this.userService.getAdministradoresAndConsultores().pipe(tap((users: AppUser[]) => {
      // Quando o usuário logado é Consultor só é retornado o usuario dele
      // Quando o usuário logado é Assistente só é retornado o usuario do consultor dele
      if (users.length === 1) {
        this.form.get("consultor").patchValue(users[0]);
        if (isEmpty(this.comissaoConsultor.value)) {
          this.comissaoConsultor.patchValue(users[0].comissao);
        }
        this.formMudou = false;
      }
    }));

    this.form.valueChanges
      .subscribe(value => {
        Object.keys(value).forEach(key => this.item[key] = value[key]);
      });
  }

  formatarDataVerificacao(data: Timestamp) {
    return moment(data.toDate()).calendar();
  }

  validatorComissao(control: AbstractControl): { [key: string]: boolean } | null {
    const data = control.value;
    if (this.loggedUser) {
      if (this.loggedUser.isAdministrador()) {
        if (data < 8) {
          return {"minAdm": true};
        }
      } else {
        if (data < 10) {
          return {"min": true};
        }
      }
    }
  }

  disabledComissaoConsultor(): boolean {
    return !this.loggedUser.isAdministrador();
  }

  showMessage(message?) {
    if (message) {
      this.dialogService
        .messageDialog()
        .message(message)
        .show();
    }
  }

  buscarEmpresa() {
    this.loadingService.showTopBar();
    this._disableBuscarButton = true;
    const cpfOrCnpj: string = this.form.get("cnpj").value;
    const dataNascimento: string = this.form.get("dataNascimento").value;

    // Implementar o snack de aviso que a consulta ta demorando
    this.getEmpresaPessoaSubscription = merge(
      this.hubDev.checkAndGetEmpresaPessoa(cpfOrCnpj, dataNascimento),
      timer(1000).pipe(takeUntil(this.timerSubject))
    ).pipe(
      tap(res => {
        if (res === 0) {
          this.snackMessage = this.snack.show(
            "Buscando dados, por favor aguarde...",
            null,
            0,
            "top"
          );
        } else {
          this.timerSubject.next();
        }
      }),
      finalize(() => {
        if (this.snackMessage) {
          this.snackMessage.dismiss();
        }
        this.loadingService.hideTopBar();
        this._disableBuscarButton = false;
      })
    ).subscribe(value => {
      if (value !== 0 && value.status) {
        if (this.item.pessoa === TipoPessoa[TipoPessoa.FISICA]) {
          this.form.get("razaoSocial").patchValue(value.result.nome_da_pf);
          if (value.result.data_nascimento !== dataNascimento) {
            this.form.get("dataNascimento").patchValue(value.result.data_nascimento);
          }
        } else {
          this.form.get("razaoSocial").patchValue(value.result.nome);
          // Verificar se é um registro novo e se tem um CEP no resultado
          if (isNullOrUndefined(this.item.id) && isNotNullOrUndefined(value.result.cep) && !isEmpty(value.result.cep)) {
            this.form.patchValue({
              localizacao: {
                endereco: {
                  cep: value.result.cep.toString().replace(/[^\d]+/g, ""),
                  numero: value.result.numero,
                  complemento: value.result.complemento
                }
              }
            });
            setTimeout(() => {
              this.empresaEnderecoFormComponent.buscarCep(true);
            }, 500);
          }
        }
      }
    }, err => {
      this.showMessage(err.name === "TimeoutError" ? "Não estamos encontrando informações para o CPF/CNPJ informado. Verifique!" : err);
    });
  }

  disableBuscarButton(): boolean {
    // Se já tiver Pagamento Online configurado, não precisa desabilitar o botão de buscar, pois já existe configuração
    if (!isEmpty(this.item.cnpj) && !isEmpty(this.item.pagamentoOnline) && !isEmpty(this.item.pagamentoOnline.accountId)) {
      return false;
    }
    if (this.item.pessoa === TipoPessoa[TipoPessoa.FISICA]) {
      return !(this.form.get("cnpj").valid && this.form.get("dataNascimento").valid);
    } else {
      return !this.form.get("cnpj").valid;
    }
  }

  diaVencimento(): Array<string> {
    return Object.keys(DiaVencimento);
  }

  tipoPessoa(): Array<string> {
    return Object.keys(TipoPessoa);
  }

  validatorDataFechamento(control: AbstractControl): { [key: string]: boolean } | null {
    const data = control.value;

    if (!data) {
      return null;
    }

    if (data.toString().replace(/[^\d]+/g, "").length < 8) {
      return {"invalido": true};
    } else {
      if (moment().diff(moment(data, "DD/MM/YYYY")) < 0) {
        return {"future": true};
      }
    }

    return null;
  }

  validatorData(control: AbstractControl): { [key: string]: boolean } | null {
    let data = control.value;

    if (data) {
      data = data.toString().replace(/[^\d]+/g, "");
      const age = moment().diff(moment(data, "DD/MM/YYYY"), "years");

      if (age < 18) {
        return {"underage": true};
      }
    } else {
      return {"invalido": true};
    }

    if (data.length > 0 && data.length < 8) {
      return {"invalido": true};
    }

    return null;
  }

  validar(control: AbstractControl): { [key: string]: boolean } | null {
    if (this.item.pessoa === TipoPessoa[TipoPessoa.JURIDICA]) {
      return this.cpfCnpjValidation.validarCnpj(control);
    } else if (this.item.pessoa === TipoPessoa[TipoPessoa.FISICA]) {
      return this.cpfCnpjValidation.validarCpf(control);
    }
  }

  static validarCnpj(control: AbstractControl): { [key: string]: boolean } | null {
    let cnpj = control.value;
    if (isNullOrUndefined(cnpj)) {
      return null;
    }

    // Remove a máscara
    cnpj = cnpj.toString().replace(/[^\d]+/g, "");

    if (cnpj.length === 0) {
      return null;
    }

    if (cnpj.length >= 0 && cnpj.length < 14) {
      return {"cnpj": true};
    }

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999") {
      return {"cnpj": true};
    }

    // Valida DVs
    // Tamanho do cnpj sem os dígitos verificadores
    let tamanho = cnpj.length - 2;

    // Separa apenas os números do CPNJ sem os digitos verificadores
    let numeros = cnpj.substring(0, tamanho);

    // Separa apenas os dígitos verificadores
    const digitos = cnpj.substring(tamanho);

    let soma = 0;
    let pos = tamanho - 7;

    // Faz o cálculo do primeiro digito verificador baseado nos números do CNPJ
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    // Compara se o número calculado bate com o primeiro dígito verificador
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado.toString() !== digitos.charAt(0)) {
      return {"cnpj": true};
    }

    // Faz o cálculo do segundo digito baseado nos números do CNPJ
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    // Compara se o número calculado bate com o segundo dígito do verificador
    if (resultado.toString() !== digitos.charAt(1)) {
      return {"cnpj": true};
    }

    return null;
  }

  checkCpfCnpjChanges() {
    this.form.valueChanges.pipe(
      pairwise()
    ).subscribe(([before, after]) => {
      // Verifica se houve alguma alteração no tipo de pessoa, cnpj/cpf ou data de nascimento
      if ((before.pessoa && after.pessoa && before.pessoa !== after.pessoa) ||
        (before.cnpj && after.cnpj && before.cnpj !== after.cnpj) ||
        (before.dataNascimento && after.dataNascimento && before.dataNascimento !== after.dataNascimento)) {
        // Seta um erro na razão social para obrigar o usuário buscar a empresa novamente
        this.form.get("razaoSocial").setErrors({"outdated": true});
        this.form.get("razaoSocial").markAsTouched();
      }
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.checkCpfCnpjChanges();

    this.statusEnderecoChanged("INVALID");

    // Impedir de salvar caso não tenha valor em comissaoConsultor
    this.comissaoConsultor.valueChanges.pipe().subscribe(value => {
      this.disableSaveButton = isEmpty(value) || this.localizacaoStatus === "INVALID";
    });

    // Preencher o valor default do campo de comissão de consultor, ao mudar o consultor
    if (!this.loggedUser.isConsultorOrAssistente()) {
      this.form.controls["consultor"].valueChanges.pipe(tap(consultor => {
        this.comissaoConsultor.patchValue(consultor.comissao === null ? 0 : consultor.comissao);
      })).subscribe();
    }

    this.itemSubject.asObservable()
      .pipe(
        mergeMap(value => {
          const dataFechamentoContrato = value.dataFechamentoContrato ? value.dataFechamentoContrato.toDate().toLocaleDateString() : null;
          value.dataFechamentoContrato = null;
          const dataNascimento = value.dataNascimento ? value.dataNascimento.toDate().toLocaleDateString() : null;
          value.dataNascimento = null;
          this.form.patchValue(value);
          this.form.get("dataFechamentoContrato").patchValue(dataFechamentoContrato);
          this.form.get("dataNascimento").patchValue(dataNascimento);

          this.comissaoConsultor.patchValue(value.consultor.comissao ? value.consultor.comissao : 0);

          if (value.abertura) {
            this.subtitle = "Última abertura: " + moment(value.abertura.toDate()).format("llll");
          }

          this.cnpjOriginal = value.cnpj;

          if (isNotNullOrUndefined(value.id) && isNotNullOrUndefined(value.pagamentoOnline) && isNotNullOrUndefined(this.item.pagamentoOnline.accountId)) {
            this.form.get("pessoa").disable({emitEvent: false});
            this.form.get("cnpj").disable({emitEvent: false});
            this.form.get("dataNascimento").disable({emitEvent: false});
          }

          return this.authService.currentUser.pipe(
            mergeMap(loggedUser => {
              this.loggedUser = loggedUser;
              if (!loggedUser.isAdministrador() && value.comissao < 10) {
                this.form.get("comissao").disable();
              }
              return of(value);
            })
          );
        })
      ).subscribe();

    this.form.valueChanges
      .subscribe(value => {
        Object.keys(value).forEach(key => this.item[key] = value[key]);
      });

    // Se for um novo registro, sempre poderá limpar o CPF/CNPJ ao mudar o tipo da pessoa
    let isUpdatedTipoPessoa = this.isNewRecord();
    this.form.get("pessoa").valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([valueBefore, valueAfter]: [any, any]) => {
        if (isUpdatedTipoPessoa) {

          // Limpar o campo
          this.form.get("cnpj").reset();
          this.form.get("dataNascimento").reset();

          // Marcar que o campo foi clicado para habilitar os erros
          this.form.get("cnpj").markAsTouched();
          this.form.get("dataNascimento").markAsTouched();
        }

        if (valueAfter === TipoPessoa[TipoPessoa.JURIDICA]) {
          this.form.get("dataNascimento").clearValidators();
          this.form.get("dataNascimento").updateValueAndValidity({onlySelf: true, emitEvent: false});
        }

        if (valueAfter === TipoPessoa[TipoPessoa.FISICA]) {
          this.form.get("dataNascimento").setValidators([this.validatorData, Validators.pattern(MaskUtil.regexDate)]);
          this.form.get("dataNascimento").updateValueAndValidity({onlySelf: true, emitEvent: false});
        }
        isUpdatedTipoPessoa = true;
      });

    this.onChanges();
    this.formMudou = false;
  }

  afterLoadItem() {
    this.statusLogActivities$ = this.service.getAllStatusLogActivityByEmpresa(this.item.id).pipe(
      tap(statusLogs => this.statusLogIsEmpty = isEmpty(statusLogs))
    );
  }

  compareWithPessoa(c1: TipoPessoa, c2: TipoPessoa): boolean {
    return c1 && c2 && c1 === c2;
  }

  compareWithVencimento(c1: DiaVencimento, c2: DiaVencimento): boolean {
    return c1 && c2 && c1 === c2;
  }

  compareWithConsultor(c1: AppUser, c2: AppUser): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  newItem(): Empresa {
    this.item = new Empresa();
    if (!this.isNewRecord()) {
      this.item.horario = null;
    }
    return this.item;
  }

  createConfigsCropperBanner(): ConfigCropper {
    return new ConfigCropper({
      width: 800,
      height: 450,
      canvasWidth: 800,
      canvasHeight: 450,
      aspectRatio: 800 / 450
    });
  }

  removeTelefone(telefone: Telefone) {
    this.dialogService.confirmDeleteDialog()
      .title("Excluir contato?")
      .message("Deseja realmente excluir este contato?")
      .show()
      .subscribe(accept => {
        if (accept) {
          this.item.telefones = this.item.telefones.filter(t => t !== telefone);
        }
      });
  }

  openTelefoneDialog(phone?: Telefone, index?: number) {
    this.dialog.open(EmpresaTelefoneDialogComponent, {
      data: {
        editPhone: isNotNullOrUndefined(phone),
        itemPhones: this.item.telefones,
        phoneForm: isNotNullOrUndefined(phone) ? phone : null
      }
    })
      .afterClosed()
      .subscribe(value => {
        if (isNotNullOrUndefined(value)) {
          const telefone = new Telefone(value);

          if (isNotNullOrUndefined(phone) && isNotNullOrUndefined(index)) {
            this.item.telefones[index] = telefone;
          } else {
            this.item.telefones.push(telefone);
          }
        }
      }, error => {
        this.showMessage(error);
      });
  }

  hasVisiblePhone(telefones: Telefone[]): boolean {
    return telefones.some(telefone => telefone.visivelNoSmartphone === true);
  }

  hasResponsiblePhone(telefones: Telefone[]): boolean {
    return telefones.some(telefone => telefone.responsavel === true);
  }

  saveOrUpdate(empresa: Empresa): Observable<void> {
    if (this.pqcErrorStatus === true) {
      this.dialogService.messageDialog()
        .message("Horários da empresa não batem com os horários de funcionamento do PedeQueChega! Alterar horários para depois das 9:00 ou antes das 23:45.")
        .show();
    } else if ((empresa.status === StatusEmpresa[StatusEmpresa.LIBERADO]) && (!this.hasVisiblePhone(empresa.telefones))) {
      this.dialogService.messageDialog()
        .message("A empresa precisa ter ao menos um telefone visível para o cliente, caso ela esteja liberada.")
        .show();
    } else if (isNullOrUndefined(this.item.id) && !this.hasResponsiblePhone(empresa.telefones)) {
      this.dialogService.messageDialog()
        .message("A empresa precisa ter ao menos um telefone de responsável pela empresa.")
        .show();
    } else if (isNotNullOrUndefined(this.item.id) && (this.form.get("cnpj").value !== this.cnpjOriginal) &&
      (isNotNullOrUndefined(this.item.pagamentoOnline) && isNotNullOrUndefined(this.item.pagamentoOnline.accountId))) {
      this.dialogService.messageDialog()
        .message("O CPF/CNPJ da empresa não pode ser mudado, pois pode gerar problemas com o pagamento online.")
        .show();
    } else {
      this.disableSaveButton = true;
      this.loading(true);
      this.formMudou = false;
      if (this.item.dataFechamentoContrato) {
        this.item.dataFechamentoContrato = Timestamp.fromDate(
          new Date(this.item.dataFechamentoContrato.toString().replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"))
        );
      }

      if (this.item.dataNascimento) {
        this.item.dataNascimento = Timestamp.fromDate(
          new Date(this.item.dataNascimento.toString().replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"))
        );
      }

      if (this.item.consultor) {
        this.item.consultor = this.form.get("consultor").value;
        this.item.consultor.comissao = this.comissaoConsultor.value;
      }

      // Gerar o id da empresa para fazer o upload das imagens usando o próprio id
      if (isNullOrUndefined(this.item.id)) {
        this.item.id = this.service.createId();
      }

      // Fazer upload do logo
      const promiseLogo = isNullOrUndefined(empresa.logoUrl) ? Promise.reject("O Logo da empresa não foi informado!") :
        this.imageUploader.uploadString(empresa.logoUrl, "/Pictures/Empresas/" + this.item.id + "_logo")
          .then(url => {
            empresa.logoUrl = url;
            return url;
          });

      // Fazer upload do banner
      const promiseBanner = isNullOrUndefined(empresa.bannerUrl) ? Promise.reject("O Banner da empresa não foi informado!") :
        this.imageUploader.uploadString(empresa.bannerUrl, "/Pictures/Empresas/" + this.item.id + "_banner")
          .then(url => {
            empresa.bannerUrl = url;
            return url;
          });

      const cidade: Cidade = new Cidade({
        nome: empresa.localizacao.endereco.cidade,
        uf: empresa.localizacao.endereco.estado,
        consultorId: empresa.consultor.id
      });

      empresa.nomeFantasiaNormalized = new Empresa().makeNormalizedNomeFantasia(empresa.nomeFantasia);

      const save = from(Promise.all([promiseLogo, promiseBanner])).pipe(
        mergeMap(() => {
          return this.cidadeService.saveOrUpdate(cidade, true).pipe(
            take(1),
            mergeMap((_cidade) => {
              if (isNotNullOrUndefined(_cidade)) {
                this.service.filters.cidade = _cidade;
              }

              if (isEmpty(empresa.id)) {
                this.service.filters.status = null;
                this.service.filters.searchValue = null;
                this.service.filters.pagtoOnline = false;
              }

              return super.saveOrUpdate(new Empresa(empresa).toAny());
            })
          );
        })
      );

      save.subscribe(() => {
      }, error => {
        this.dialogService
          .messageDialog()
          .message(error)
          .show();
      }, () => {
        this.disableSaveButton = false;
        this.loading(false);
      });

      return save;
    }
  }

  // Verifica se ocorreram mudanças no formulário,alterando o valor da variável formMudou para true, ou mantendo em null por padrão.
  onChanges() {
    let mudanca = 0;
    this.form.valueChanges.pipe(
      skip(3)
    ).subscribe(() => {
      if (mudanca === 0) {
        this.formMudou = false;
        mudanca++;
      } else {
        this.formMudou = true;
      }
    });
  }

  pqcError(value) {
    this.pqcErrorStatus = !!value;
  }

  setErrors(dia: string) {
    this.objectErrors[dia] = {
      "invalid": true
    };
  }

  clearErrors(dia: string) {
    delete this.objectErrors[dia];
  }

  clearUnderscore(nome: string): string {
    return nome.replace("_", " ");
  }

  ngOnDestroy(): void {
    if (this.getEmpresaPessoaSubscription) {
      this.getEmpresaPessoaSubscription.unsubscribe();
    }

    if (this.snackMessage) {
      this.snackMessage.dismiss();
    }
  }

  statusEnderecoChanged($event: any) {
    this.localizacaoStatus = $event;
    this.disableSaveButton = $event === "INVALID";
    this.cdRef.detectChanges();
  }

  deleteLogoImage() {
    this.form.get("logoUrl").patchValue(null);
  }

  deleteBannerImage() {
    this.form.get("bannerUrl").patchValue(null);
  }
}
