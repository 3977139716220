import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormGroup} from "@angular/forms";
@Component({
  selector: "app-dialog-form",
  templateUrl: "./dialog-form.component.html",
  styleUrls: ["./dialog-form.component.scss"]
})
export class DialogFormComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  subtitle: string;

  @Input()
  form: FormGroup;

  @Input()
  disableSaveButtonWhen: boolean;

  @Input()
  titleSaveButton: string = "Salvar";

  @Input()
  showOnlyCloseButton: boolean = false;

  @Output()
  saveEvent = new EventEmitter();


  constructor() { }

  ngOnInit() {}

  disableSaveButton() {
    return this.disableSaveButtonWhen || (this.form && !this.form.valid);
  }

  salvar() {
    this.saveEvent.emit();
  }
}
