import {isNullOrUndefined} from "util";

export class Funcionamento {

  public aberturaManha: string;
  public fechamentoManha: string;
  public aberturaTarde: string;
  public fechamentoTarde: string;

  constructor(funcionamento: any = {}) {
    if (!isNullOrUndefined(funcionamento)) {
      this.aberturaManha = funcionamento.aberturaManha;
      this.fechamentoManha = funcionamento.fechamentoManha;
      this.aberturaTarde = funcionamento.aberturaTarde;
      this.fechamentoTarde = funcionamento.fechamentoTarde;
    }
  }

  public toAny(): any {
    const a: any = {};
    if (this.aberturaManha) a.aberturaManha = this.aberturaManha;
    if (this.fechamentoManha) a.fechamentoManha = this.fechamentoManha;
    if (this.aberturaTarde) a.aberturaTarde = this.aberturaTarde;
    if (this.fechamentoTarde) a.fechamentoTarde = this.fechamentoTarde;
    return a;
  }
}
