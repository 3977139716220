import {ChangeDetectorRef, Component, OnDestroy} from "@angular/core";
import {PageService} from "../../../services/page.service";
import {PerfilAcesso} from "../../../models/perfil-acesso.model";
import {PerfilService} from "../perfil.service";
import { MatDialog } from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable, Subscription} from "rxjs";
import {AppUser} from "../../../models/appUser";
import {AuthService} from "../../../modules/login/auth.service";
import {isNotNullOrUndefined} from "../../../utils/commons";

@Component({
  selector: "app-perfil-list",
  templateUrl: "./perfil-list.component.html",
  styleUrls: ["./perfil-list.component.scss"]
})
export class PerfilListComponent extends PageService<PerfilAcesso> implements OnDestroy {
  $perfis: Observable<PerfilAcesso[]>;
  loggedUser: AppUser;
  userSubscription: Subscription;

  constructor(public service: PerfilService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              public authService: AuthService) {

    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/perfis/");
    this.$perfis = this.service.getPerfisByLoggedUser(true);

    this.userSubscription = this.authService.currentUser.subscribe(user => {
      this.loggedUser = user;
    });
  }

  protected newItem(): PerfilAcesso {
    return new PerfilAcesso();
  }

  ngOnDestroy(): void {
    if (isNotNullOrUndefined(this.userSubscription)) {
      this.userSubscription.unsubscribe();
    }
  }

}
