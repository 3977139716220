import {Component, OnInit, Inject, ViewEncapsulation} from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {isEmpty} from "../../../utils/commons";
import {NfseService} from "../../../services/nfse.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {SnackService} from "../../../services/snack/snack.service";

@Component({
  selector: "app-enviar-nfse-dialog",
  templateUrl: "./enviar-nfse-dialog.component.html",
  styleUrls: ["./enviar-nfse-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EnviarNfseDialogComponent implements OnInit {

  public emailControls = [];
  public emailCount = 1;
  public isSending = false;

  constructor(public nfseService: NfseService,
              public loadingService: LoadingService,
              public dialogService: DialogService,
              public dialogRef: MatDialogRef<EnviarNfseDialogComponent>,
              public snackService: SnackService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _formBuilder: FormBuilder) {
    this.emailControls.push(new FormControl("", [Validators.required, Validators.email]));
  }

  ngOnInit() {
  }

  enviarEmail(): void {
    this.loadingService.showTopBar();

    const nfseReference = this.data.ref;
    const emails = {"emails": []};

    this.emailControls.forEach(control => {
      if (!isEmpty(control.value)) {
        emails.emails.push(control.value);
      }
    });

    this.isSending = true;

    this.nfseService.sendEmail(nfseReference, emails).subscribe(() => {
    }, (error) => {
      this.isSending = false;
      this.dialogService.messageDialog().message(error.message).show();
      this.loadingService.hideTopBar();
    }, () => {
      this.isSending = false;
      const message = emails.emails.length === 1 ? "O e-mail foi enviado com sucesso!" : "Os e-mails foram enviados com sucesso!";
      this.dialogRef.close();
      this.loadingService.hideTopBar();
      this.snackService.show(message);
    });
  }

  addNewInput(): void {
    if (this.emailCount < 10) {
      this.emailCount += 1;
      this.emailControls.push(new FormControl("", [Validators.required, Validators.email]));
    }
  }

  removeLastInput(): void {
    if (this.emailCount >= 1 && this.emailCount <= 10) {
      this.emailCount--;
      this.emailControls.pop();
    }
  }

  verifyFormIsValid(): boolean {
    return this.emailControls.some(control => control.invalid);
  }
}
