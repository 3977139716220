import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {CidadeService} from "../cidade.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Estado, estados} from "../../../models/estado.model";
import {Observable, Subscription} from "rxjs";
import {Cidade} from "../../../models/cidade.model";
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";
import {AppUser} from "../../../models/appUser";
import {AuthService} from "../../../modules/login/auth.service";
import {UsersService} from "../../users/users.service";
import {take} from "rxjs/operators";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";

@Component({
  selector: "app-cidade-dialog",
  templateUrl: "./cidade-dialog.component.html",
  styleUrls: ["./cidade-dialog.component.scss"]
})
export class CidadeDialogComponent implements OnInit, OnDestroy {

  static showDialog(dialog: MatDialog): Observable<any> {
    const config = {
      width: "450px"
    };
    return dialog.open(CidadeDialogComponent, config).afterClosed();
  }

  disableSaveButton = false;
  form: FormGroup;
  estados: Estado[] = estados;
  cidade: Cidade = new Cidade();

  userSubscription: Subscription;
  loggedUser: AppUser;

  consultores$: Observable<AppUser[]>;
  loadingName: string = "loadingForm";

  constructor(public dialogRef: MatDialogRef<CidadeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private cidadeService: CidadeService,
              formBuilder: FormBuilder,
              public auth: AuthService,
              public usersService: UsersService,
              public dialogService: DialogService,
              public loadingService: LoadingService,
              public cdRef: ChangeDetectorRef) {
    this.consultores$ = this.usersService.getAllConsultores();

    if (isNotNullOrUndefined(data)) {
      this.cidade = new Cidade(data);
    }

    this.form = formBuilder.group({
      uf: ["", [Validators.required]],
      nome: ["", [Validators.required, Validators.minLength(3)]],
      consultores: [[]]
    });
  }

  ngOnInit() {
    this.userSubscription = this.auth.currentUser.subscribe(user => {
      this.loggedUser = user;
    });

    this.form.patchValue(this.cidade);

    this.form.valueChanges.subscribe(value => {
      Object.keys(value).forEach(key => this.cidade[key] = value[key]);
    });
  }

  salvar() {
    this.disableSaveButton = true;
    this.loadingService.showTopBar();

    this.cidadeService.saveOrUpdate(this.cidade, true).pipe(take(1)).subscribe((cidade) => {
      this.disableSaveButton = false;
      this.loadingService.hideTopBar();
      if (isNullOrUndefined(cidade)) {
        this.dialogService.messageDialog()
          .title("Erro ao cadastrar!")
          .message("Essa cidade já está cadastrada no sistema.")
          .show();
      } else {
        this.dialogRef.close(cidade);
      }
    });
  }

  ngOnDestroy(): void {
    if (isNotNullOrUndefined(this.userSubscription)) {
      this.userSubscription.unsubscribe();
    }
  }

  inputChipInit() {
    if (!isEmpty(this.form.get("consultores").value)) {
      this.loadingService.register(this.loadingName);
      this.cdRef.detectChanges();
    }
  }

  inputChipFinishLoading() {
    this.loadingService.timeOut(this.loadingName);
  }
}
