import * as firebase from "firebase";
import Query = firebase.firestore.Query;
import FieldPath = firebase.firestore.FieldPath;
import WhereFilterOp = firebase.firestore.WhereFilterOp;

export class Criterion {
  fieldPath: string | FieldPath;
  opStr: WhereFilterOp;
  value: any;


  constructor(fieldPath: string | FieldPath, opStr: WhereFilterOp, value: any) {
    this.fieldPath = fieldPath;
    this.opStr = opStr;
    this.value = value;
  }

  build(ref: firebase.firestore.CollectionReference | Query): Query {
    if (ref instanceof firebase.firestore.CollectionReference) {
      return ref.where(
        this.fieldPath,
        this.opStr,
        this.value
      );
    } else {
      return (ref as Query).where(
        this.fieldPath,
        this.opStr,
        this.value
      );
    }
  }
}
