import {RecordByEmpresa} from "./record-by-empresa";
import {Complemento, Produto} from "./produto.model";

export class PedidoItem extends RecordByEmpresa {

  public produto: Produto;
  public quantidade: number;
  public total: number;
  public observacao: string;

  public complementos: Complemento[];

  constructor(item?: any) {
    super();
    this.complementos = [];
    if (item) {
      this.id = item.id;
      this.ref = item.reference;
      this.empresa = item.empresa;
      this.produto = new Produto(item.produto);
      this.quantidade = item.quantidade;
      this.total = item.total;
      this.observacao = item.observacao;
      if (item.complementos) {
        item.complementos.forEach(value => this.complementos.push(new Complemento(value)));
      }
    }
  }

}
