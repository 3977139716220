import {Pipe, PipeTransform} from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "timezone"
})
export class TimezonePipe implements PipeTransform {

  transform(date: any, companyTimezone: any, format?: string): string {
    return moment(date).tz(companyTimezone ? companyTimezone : "America/Sao_Paulo").format(format ? format : "DD/MM/YYYY HH:mm");
  }
}
