import {Injectable} from "@angular/core";
import {AngularFireFunctions} from "@angular/fire/functions";
import {Observable} from "rxjs/Rx";

@Injectable({
  providedIn: "root"
})
export class NfseService {

  constructor(public fns: AngularFireFunctions) {
  }

  create(cnpj: string): Observable<any> {
    return this.fns.httpsCallable("call-focus-nfse-sendNfse")({cnpj});
  }

  sendEmail(nfseRef: string, emailList: any): Observable<any> {
    return this.fns.httpsCallable("call-focus-email-sendEmail")({nfseRef, emailList});
  }
}
