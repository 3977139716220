import {Repository} from "./Repository";
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from "@angular/fire/firestore";
import {$query, PoMQuery} from "../services/firebase/criteria/query";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {mergeMap, tap, take, finalize, scan, takeUntil, delay} from "rxjs/operators";
import * as firebase from "firebase";
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
import {EmpresaCriterion} from "./repository-by-empresa";
import {AuthService} from "../modules/login/auth.service";
import { isEmpty } from "../utils/commons";

export abstract class RepositoryPaginationService<T> extends Repository<T> {

  private _done$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _data$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

  done$: Observable<boolean> = this._done$.asObservable();
  loading$: Observable<boolean> = this._loading$.asObservable();
  data$: Observable<T[]>;

  unsubscribe$: Subject<any> = new Subject<any>();

  constructor(public db: AngularFirestore,
              public collectionName: string,
              public limitDocs?: number,
              public byEmpresa?: boolean,
              public auth?: AuthService,
              public requiredSerialize?: boolean) {
    super(db, collectionName, requiredSerialize);
    if (!limitDocs) {
      this.limitDocs = 50;
    }

    this.data$ = this._data$.asObservable().pipe(
      scan( (acc, val) => {
        return acc.concat(val);
      })
    );
  }

  resetService() {
    this._done$.next(false);
    this._loading$.next(false);
    this._data$.next([]);
    this.data$ = this._data$.asObservable().pipe(
      scan( (acc, val) => {
        return acc.concat(val);
      })
    );
    this.unsubscribe$.next();
  }

  getCollection(queryFn: PoMQuery = new PoMQuery(null)): AngularFirestoreCollection<T> {
    if (this.byEmpresa) {
      const empresaCriterion = new EmpresaCriterion(this.auth.currentEmpresa);
      // Só adiciona o filtro de empresa, caso já não esteja recebendo o filtro
      if (queryFn && queryFn.containsCriterion(empresaCriterion)) {
        return super.getCollection(queryFn.add(super.limit(this.limitDocs)));
      } else {
        return super.getCollection(queryFn.addFirst(empresaCriterion).add(super.limit(this.limitDocs)));
      }
    } else {
      if (!queryFn) {
        queryFn = $query(super.limit(this.limitDocs));
      } else {
        queryFn.add(super.limit(this.limitDocs));
      }

      return super.getCollection(queryFn);
    }
  }

  initialQuery(queryFn?: PoMQuery): Observable<T[]> {
    return super.col$(queryFn).pipe(
      take(1),
      tap((docs: T[]) => {
        this.updateDocs(docs);
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  loadMore(queryFn?: PoMQuery) {
    if (this._done$.value || this._loading$.value) {
      return;
    }

    this._loading$.next(true);
    this.getCursorDocumentSnapshot().pipe(
      take(1),
      mergeMap((cursor: QueryDocumentSnapshot) => {

        return super.col$(queryFn ? queryFn.add(super.startAfter(cursor)) : $query(super.startAfter(cursor))).pipe(
          take(1),
          tap((docs: T[]) => {
            this.updateDocs(docs);
          })
        );
      }),
      delay(1000),
      finalize(() => this._loading$.next(false))
    ).subscribe();
  }


  getCursorDocumentSnapshot(): Observable<DocumentSnapshot> {
    const current = this._data$.value;
    if (current.length) {
      const cursorRef: AngularFirestoreDocument = super.getDoc(current[current.length - 1]["id"]);
      return cursorRef.get();
    }
    return null;
  }

  updateDocs(docs: T[]) {

    this._data$.next(docs);

    if (isEmpty(docs) || docs.length < this.limitDocs) {
      this._done$.next(true);
    }
  }
}
