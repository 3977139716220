import {Injectable} from "@angular/core";
import {Observable} from "rxjs/Rx";
import {from} from "rxjs";
import {map, mergeMap} from "rxjs/operators";
import {hextorstr, KEYUTIL, KJUR, stob64} from "jsrsasign";
import {sha256} from "js-sha256";

@Injectable({
  providedIn: "root"
})
export class QzTrayService {

  readonly certificate = "-----BEGIN CERTIFICATE-----\n" +
    "MIIDpDCCAowCCQCPPCz10CBGqjANBgkqhkiG9w0BAQsFADCBkjELMAkGA1UEBhMC\n" +
    "QlIxDjAMBgNVBAgMBUdvaWFzMRIwEAYDVQQHDAlSaW8gVmVyZGUxFDASBgNVBAoM\n" +
    "C1BlZGUgbyBNZW51MQwwCgYDVQQLDANQb00xFDASBgNVBAMMC1BlZGUgbyBNZW51\n" +
    "MSUwIwYJKoZIhvcNAQkBFhZkZXJjaWxpbWEuc2lAZ21haWwuY29tMCAXDTE4MDcy\n" +
    "NjE4MTYxNloYDzIwNTAwMTE4MTgxNjE2WjCBkjELMAkGA1UEBhMCQlIxDjAMBgNV\n" +
    "BAgMBUdvaWFzMRIwEAYDVQQHDAlSaW8gVmVyZGUxFDASBgNVBAoMC1BlZGUgbyBN\n" +
    "ZW51MQwwCgYDVQQLDANQb00xFDASBgNVBAMMC1BlZGUgbyBNZW51MSUwIwYJKoZI\n" +
    "hvcNAQkBFhZkZXJjaWxpbWEuc2lAZ21haWwuY29tMIIBIjANBgkqhkiG9w0BAQEF\n" +
    "AAOCAQ8AMIIBCgKCAQEAslrPGo1smyiwiSmQdwrjxMvzUIGBgC2+PlMrKWKegtBZ\n" +
    "GEFu4zWXKc8Mr+G9Z9QSy9vh85LauYevZ4RMu0m/BGerB0ImQUNiP6wDi2O48LSc\n" +
    "1t6mayi5OrG00ncJiu0AeGLhp35VHyWnkqHn99BoCYTrsbVwD/FfrLbeqzXFturZ\n" +
    "Y/4hDSiDRXyxKG+FF0hgJ+6A9LhhKzb7Qe0Q5oQKT2fR6gL2eQd6Lth3R1mSc5iY\n" +
    "SI0pnt/uyhNnikXod7vHcMC7vGIPQj36bwEG7DxOQ9Tgkhx2b/UBY1HhYdGmlaVr\n" +
    "Kcy3oTtOdwL5RxNgUjTXy1KzDulPtBZ/57oPd/FEMQIDAQABMA0GCSqGSIb3DQEB\n" +
    "CwUAA4IBAQCnXSEpepZ5JRb5+gVAR/uPZ9SCgdllbRofnQc3TtMTG6mSoXexzRd9\n" +
    "H5Dt1QwNcIWrRgx5QKp4LT5GkIcPIfQME2yXBj3tK4FH01BUgvamO5phxbwae54E\n" +
    "+h95EbqI/Zf80Cg0qBUN+QewpPJiBV2wQHLWrK/Bm9BdSImdHWQFFhyggnTSMM0A\n" +
    "MIQI7iQSsgylh8eFY049W7p7E/DHjjKczxo08mLtxEmJlEK2qKW0ZPY8wbpvn1dd\n" +
    "YKbAx6bvP2up2QgcUDG8USiQeRT3CGKa80LtajxaP9dgfKYkUDNmEIP8LO2W0mmg\n" +
    "Mb0BCbFZGC6LMlc173vS1cw4kXt4rG58\n" +
    "-----END CERTIFICATE-----";

  readonly privateKey = "-----BEGIN PRIVATE KEY-----\n" +
    "MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCyWs8ajWybKLCJ\n" +
    "KZB3CuPEy/NQgYGALb4+UyspYp6C0FkYQW7jNZcpzwyv4b1n1BLL2+Hzktq5h69n\n" +
    "hEy7Sb8EZ6sHQiZBQ2I/rAOLY7jwtJzW3qZrKLk6sbTSdwmK7QB4YuGnflUfJaeS\n" +
    "oef30GgJhOuxtXAP8V+stt6rNcW26tlj/iENKINFfLEob4UXSGAn7oD0uGErNvtB\n" +
    "7RDmhApPZ9HqAvZ5B3ou2HdHWZJzmJhIjSme3+7KE2eKReh3u8dwwLu8Yg9CPfpv\n" +
    "AQbsPE5D1OCSHHZv9QFjUeFh0aaVpWspzLehO053AvlHE2BSNNfLUrMO6U+0Fn/n\n" +
    "ug938UQxAgMBAAECggEACma9WUESKmJPGVCxOiAKdHmocPN8h+sjAyN6iulTcTGR\n" +
    "YJbATsPbUvbeOIujriByEC18meXgl51QH2tZxjpgx3L8SGoVLQ0u96OieUiRiogd\n" +
    "9XH6ppkzZDbk4rlKdy2nL14k5s25TR23DhgfHRasWpHD5RM9u9BJ0hrAFaAHLcmf\n" +
    "fQvTrURjChxwIdwSpErU8MTg310AqRSv5Wp+e2NgaJAgNCbTtenq/V2i3Uj3RnOG\n" +
    "0fBaAdV5Ek9Q4iihfhW62tMrpya/mEqtTt9H57kMmo+uZHyKIjI3hUyvE+LBg8YN\n" +
    "n26nYmgsUUMBt1QlO2MRAmbiY93UMB4LcBRJpIjs2QKBgQDfmpKbjkN0Yag1FMcU\n" +
    "LkuMOwu7IYdvooA15nBLm29P2C2uAkN3SbmnJEIBGXBlsK4jfZCRv0H20a3xZX+y\n" +
    "9kr2KyyRnucK4e7d6U9uXtYOlgCyY0sQmDx45dLi80bBxsmJwsX4vbJGnVBvZ4xS\n" +
    "C2HvmOTVSZQiWMNpzR+n/bxvywKBgQDMMfSmPi/sBtQzUN1SefgOfj72TYGWKreC\n" +
    "Xq0jNUE3lvCtmU/IRyyKncTuR+kjJ7wniHaitkICs6XzbsDfuPPnEgCB1paQKYAr\n" +
    "BS93qiMcvcBKTbNZVcFCdfsESbbfjE8gqqiEJIlABJw8SYANXshudjh/JNUbRouD\n" +
    "ir9DHmekcwKBgGEhD9Yqcfq4AQ0tvVxyz7ZWX74lzmMbv32qX9U3655PVWhk61GB\n" +
    "4PdNtK4tkrlZigs4m/fzkH/ajShy4ommmuFb17xZvhroEY6rrilPYMRtPFhA8XZ/\n" +
    "6qTJkFWtP2slIp6I0N4/6JTr+OirnjR1ZVFipORRVdulmxz2UW3ztksJAoGAZ492\n" +
    "etHTUCtud9AtT2UYj5ErsZNBg72KcI4/1TlvKBhfXEwDEJo4Vt0JQagq7295l7s4\n" +
    "H/G7rM8b/UhQfaa53JletHr2xYqmvCrXFiOtxyfdXFHoAgjiCSj49AXGv73Ta8fh\n" +
    "y5MUIOm1q0XDsgQ+NveyU5mEk17F5yL5Obsz6U0CgYBCvn3NpShd9F6miyc34eL4\n" +
    "s1Aqqu2jbz6Y8dTsdnCsHDPdr/BNKJX+eymF/+s8zsxxqeprEyeqG47e43rOAVsK\n" +
    "02/FXhghcNeXv2y4AtbxLBisnQNEVtXAhsDeFv6Ru+/+cZut5eHgLN/ndz5G/npU\n" +
    "yzqTcutR/OzB4zfebKii5Q==\n" +
    "-----END PRIVATE KEY-----";

  constructor() {
    qz.api.setSha256Type(data => sha256(data));
    qz.api.setPromiseType(resolver => new Promise(resolver));

    qz.security.setCertificatePromise((resolve, reject) => {
      resolve(this.certificate);
    });

    qz.security.setSignaturePromise((toSign) => {
      return (resolve, reject) => {
        try {
          const prvKey = KEYUTIL.getKey(this.privateKey);
          const sig = new KJUR.crypto.Signature({"alg": "SHA1withRSA"});
          sig.init(prvKey);
          sig.updateString(toSign);
          const hex = sig.sign();
          resolve(stob64(hextorstr(hex)));
        } catch (err) {
          console.error(err);
          reject(err);
        }
      };
    });

  }

  isActive(): boolean {
    return qz.websocket.isActive();
  }

  connect(): Observable<void> {
    const connect = new Promise((resolve, reject) => {
      if (qz.websocket.isActive()) {
        resolve();
      } else {
        qz.websocket.connect().then(resolve, () => {
          // if a connect was not succesful, launch the mimetime
          // window.location.assign("qz:launch");
          // try 2 more times
          // return qz.websocket.connect({retries: 2, delay: 1});
          reject(new Error("Não foi possível comunicar com a impressora! Certifique-se que o QZ esteja aberto na barra do sistema."));
        });
      }
    });

    return from(connect).pipe(map(() => {
    }));
  }

  getPrinters(): Observable<string[]> {
    return this.connect().pipe(
      mergeMap(() => {
        return from(qz.printers.find()).pipe(
          map((printers: string[]) => printers)
        );
      })
    );
  }

  getPrinter(printerName?: string): Observable<string> {
    return this.connect().pipe(
      mergeMap(() => {
        const findPrinter = printerName ? qz.printers.find(printerName) : qz.printers.getDefault();
        return from(findPrinter).pipe(map((printer: string) => printer));
      })
    );
  }

  printData(printer: string, data: any, pageDimension?: string): Observable<any> {
    const config = qz.configs.create(printer, {encoding: "IBM860"});
    if (pageDimension === "58mm") {
      //config = qz.configs.create(printer, {encoding: "Cp1252"});
    }

    return from(qz.print(config, data))
      .pipe(map((anything: any) => anything));
  }

  disconnect() {
    if (qz.websocket.isActive()) {
      qz.websocket.disconnect();
    }
  }

}
