import {Component, OnInit} from "@angular/core";
import {Settings, SettingsService} from "./settings.service";
import {Observable} from "rxjs/Rx";
import {finalize} from "rxjs/operators";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SelectImpressoraDialogComponent} from "../select-impressora-dialog/select-impressora-dialog.component";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DialogService} from "../../services/dialog/dialog.service";
import {LoadingService} from "../../services/loading/loading.service";
import {isNullOrUndefined} from "util";
import {AES} from "crypto-js";
import {environment} from "../../../environments/environment";
import {SnackService} from "../../services/snack/snack.service";
import {isEmpty} from "../../utils/commons";
import {MatCheckboxChange} from "@angular/material/checkbox";

const isElectron = require("is-electron");

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  static showDialog(dialog: MatDialog): Observable<any> {
    const config = {
      width: "500px",
    };
    return dialog.open(SettingsComponent, config).afterClosed();
  }

  form: FormGroup;
  hide = true;
  private settings = new Settings();

  // Opções de formato de impressão
  readonly default: string = "Padrão";
  readonly pdf: string = "PDF";

  // Opções de dimensões da folha
  readonly p80: string = "80mm";
  readonly p58: string = "58mm";
  readonly a4: string = "A4";

  constructor(private dialogRef: MatDialogRef<SettingsComponent>,
              private settingsService: SettingsService,
              private dialogService: DialogService,
              private loading: LoadingService,
              private dialog: MatDialog,
              private fb: FormBuilder,
              private snackService: SnackService) {
    // Desabilitar o fechamento auto automático da tela
    dialogRef.disableClose = true;

    this.form = this.fb.group({
      impressoraPadrao: [""],
      printFormat: [this.default, [Validators.required]],
      pageDimension: [this.p80, [Validators.required]],
      numeroVias: [],
      apiIntegracao: [false],
      apiKey: [""],
    });
  }

  ngOnInit() {
    this.showLoading(true);
    this.settingsService
      .getSettings()
      .pipe(finalize(() => this.showLoading(false)))
      .subscribe((settings) => {
        this.settings = settings;
        if (isNullOrUndefined(settings.numeroVias)) {
          settings.numeroVias = 1;
        }
        if (isNullOrUndefined(settings.printFormat)) {
          settings.printFormat = this.default;
        }
        if (isNullOrUndefined(settings.pageDimension)) {
          settings.pageDimension = this.p80;
        }

        if (isNullOrUndefined(settings.impressoraPadrao)) {
          settings.impressoraPadrao = "";
        }
        if (isNullOrUndefined(settings.apiIntegracao)) {
          settings.apiIntegracao = false;
        }
        if (isNullOrUndefined(settings.apiKey)) {
          settings.apiKey = "";
        }
        this.form.patchValue(settings);
      });

    this.form.valueChanges.subscribe((value) => {
      Object.keys(value).forEach((key) => (this.settings[key] = value[key]));
    });
  }

  searchImpressoras() {
    SelectImpressoraDialogComponent.showDialog(this.dialog).subscribe(
      (value) => {
        if (value) {
          this.defaultPrinter.setValue(value);
        }
      }
    );
  }

  get defaultPrinter(): AbstractControl {
    return this.form.get("impressoraPadrao");
  }

  minusVia() {
    if (parseInt(this.form.get("numeroVias").value, 10) !== 1) {
      this.form.controls["numeroVias"].setValue(
        parseInt(this.form.get("numeroVias").value, 10) - 1
      );
    }
  }

  plusVia() {
    if (parseInt(this.form.get("numeroVias").value, 10) !== 10) {
      this.form.controls["numeroVias"].setValue(
        parseInt(this.form.get("numeroVias").value, 10) + 1
      );
    }
  }

  save() {
    this.showLoading(true);
    this.settingsService
      .save(this.settings)
      .pipe(finalize(() => this.showLoading(false)))
      .subscribe(
        () => {
          this.dialogRef.close();
        },
        () => {
          this.dialogService
            .messageDialog()
            .message("Falha ao salvar as configurações!")
            .show();
        }
      );
  }

  showLoading(load: boolean) {
    if (load) {
      this.loading.register("loadingOverlay");
    } else {
      this.loading.timeOut("loadingOverlay", 500);
    }
  }

  enableApiIntegracao(event: MatCheckboxChange): void {
    const apiKey = this.form.get("apiKey").value;
    if (apiKey === undefined) {
      this.form.patchValue({apiKey: ""});
    }
    // Quando habilitar a api de integração e ainda não tiver chave
    if (event.checked && isEmpty(apiKey)) {
      // Gera uma nova chave de API
      this.generateAPIKey();
    }
  }

  generateAPIKey(): void {
    const id = this.settingsService.getIdEmpresa();

    // segredo para criptografar e descriptografar id da empresa, não alterá-lo
    const secret = environment.encriptKey;

    const hash = AES.encrypt(id, secret).toString();
    this.form.patchValue({apiKey: hash});

    this.snackService.show("Uma nova chave de API foi gerada!");
  }

  copyToClipboard(value: string): void {
    this.snackService.show(
      "Chave de API copiada para a área de transferência!"
    );

    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", value);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }

  isElectron(): boolean {
    return isElectron();
  }
}
