import {Injectable} from "@angular/core";
import {Pedido} from "../../../models/pedido.model";
import {Observable, Subject} from "rxjs/Rx";
import {OrderByDesc} from "../../../services/firebase/criteria/order-by-desc";
import {OrderBy} from "../../../services/firebase/criteria/order-by";
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";
import {catchError, map, mergeMap, pairwise, startWith, takeUntil, tap} from "rxjs/operators";
import {combineLatest, interval, merge, of, throwError} from "rxjs";
import {Repository} from "../../../repository/Repository";
import {Empresa, TempoEntrega} from "../../../models/empresa";
import {AngularFireFunctions} from "@angular/fire/functions";
import * as moment from "moment";
import {TempoEntregaDialogComponent} from "../tempo-entrega-dialog-component/tempo-entrega-dialog.component";
import {DialogService} from "../../../services/dialog/dialog.service";
import {MatDialog} from "@angular/material/dialog";
import {Funcionamento} from "../../../models/funcionamento";
import {MessagingService} from "../../../services/messaging.service";
import {$query} from "../../../services/firebase/criteria/query";
import {Criterion} from "../../../services/firebase/criteria/criterion";
import {flatten} from "@angular/compiler";
import {EmpresaGerenciadorService} from "./empresa-gerenciador.service";
import {AngularFirestoreSecondary} from "../../../services/firebase/angular-firestore-secondary";
import {ActivatedRoute, Router} from "@angular/router";
import {SnackService} from "../../../services/snack/snack.service";
import {AuthService} from "../../../modules/login/auth.service";
import {IpcElectronService} from "../../../services/electron/ipc-electron.service";
import isElectron from "is-electron";


@Injectable({
  providedIn: "root"
})
export class GerenciadorPedidosService extends Repository<Pedido> {

  private readonly bell = new Audio("assets/sounds/beep.mp3");

  pedidoPendente$ = new Subject<void>();

  constructor(public db: AngularFirestoreSecondary,
              public auth: AuthService,
              public messagingService: MessagingService,
              public empresaService: EmpresaGerenciadorService,
              public fns: AngularFireFunctions,
              public dialog: MatDialog,
              public dialogService: DialogService,
              public route: ActivatedRoute,
              public router: Router,
              public snackBar: SnackService,
              private ipcElectronService: IpcElectronService) {
    super(db, "pedidos", true);
  }

  /**
   * Faz chamada da function changeStatusPedidoGerenciador no firebase functions
   * @param pedido
   */
  callFunction(pedido): Observable<void> {
    const changeStatusFunction = this.fns.httpsCallable("call-pedidos-changeStatus");
    const newStatus = pedido.getNewStatus();

    return changeStatusFunction({
      id: pedido.id,
      newStatus: newStatus,
    });
  }

  /**
   * Faz chamada da function changeStatusPedidoGerenciador no firebase functions, exclusivo para a rejeição/cancelamento de pedidos
   * @param pedido
   * @param status
   * @param motivo
   * @param motivoCod
   * @param bloquearBairro
   */
  callRejectFunction(pedidoId: string, status: string, motivo: string, motivoCod: string, bloquearBairro?: boolean): Observable<void> {
    return this.fns.httpsCallable("call-pedidos-changeStatus")
    ({
      id: pedidoId,
      newStatus: status,
      motivo: motivo,
      motivoCod: motivoCod,
      bloquearBairro: bloquearBairro
    });
  }

  /**
   * Retorna os dados e estilos utilizados para formar os action buttons
   * @param pedido
   * @returns {{...}}
   */
  getBotao(pedido: Pedido): { title: string, disable: boolean, class: string, color: string, tip: string } {
    if (pedido.isPendente()) {
      return {title: "Aprovar", disable: false, class: "mat-raised-button", color: "accent", tip: "Aprovar pedido"};
    } else if (pedido.isDelivery()) {
      return {title: "Delivery", disable: false, class: "mat-raised-button", color: null, tip: "Despachar entrega"};
    } else if (pedido.isBuscarPedidoNoBalcao()) {
      return {title: "Pronto", disable: false, class: "mat-raised-button", color: "dark-blue-grey", tip: "Pedido ficou pronto"};
    } else if (pedido.isPronto()) {
      return {title: "Concluir", disable: false, class: "mat-raised-button", color: "dark-green", tip: "Concluir pedido"};
    } else if (pedido.isConcluido()) {
      return {title: "done", disable: true, class: "mat-button", color: "green", tip: null};
    } else if (pedido.isRejeitado()) {
      return {title: "block", disable: true, class: "mat-button", color: "red", tip: null};
    } else if (pedido.isCancelado()) {
      return {title: "cancel", disable: true, class: "mat-button", color: "red", tip: null};
    }
  }

  /**
   * Busca os pedidos da empresa desde o horário de abertura da empresa
   * @returns {Observable<Pedido[]>}
   */
  getPedidos(): Observable<Pedido[]> {

    const hasPedidoPendente = (pedidos) => pedidos.some(pedido => pedido.isPendente());

    return this.empresaService.getEmpresas().pipe(
      mergeMap((empresas: Empresa[]) => {
        return this.getPedidosGerenciadorByEmpresas(empresas).pipe(
          startWith({}),
          pairwise(),
          map(([previousPedidos, currentPedidos]: [Pedido[], Pedido[]]) => {

            if ((isNullOrUndefined(previousPedidos.length) || currentPedidos.length > previousPedidos.length) && hasPedidoPendente(currentPedidos)) {
              this.pedidoPendente$.next();
              this.callBeepPendente();
              this.ipcElectronService.onForeground();
            }
            if (!hasPedidoPendente(currentPedidos)) {
              this.pedidoPendente$.next();
            }

            return currentPedidos;
          })
        );
      }),
      mergeMap(pedidos => {
        return this.empresaService.getEmpresas().pipe(
          map(empresas => {
            return pedidos
              // Remover os pedidos das empresas que estão fechadas
              .filter((pedido: Pedido) => {
                const empresa = empresas.find((_empresa: Empresa) => _empresa.id === pedido.empresa.id);
                return empresa.aberto;
              });
          })
        );
      })
    );
  }

  getPedidosGerenciadorByEmpresas(empresas: Empresa[]): Observable<Pedido[]> {
    // Percorre as empresas e retorna os observables das consultas dos pedidos de todas as empresas abertas
    const pedidosEmpresas$: Observable<Pedido[]>[] = empresas
      .filter(empresa => empresa.aberto && empresa.abertura)
      .map((empresa: Empresa) => {
        return this.col$($query(
          new Criterion("empresa.id", "==", empresa.id),
          new Criterion("data", ">", empresa.abertura.toDate())
        ));
      });

    return isEmpty(pedidosEmpresas$) ? of([]) : combineLatest(pedidosEmpresas$).pipe(
      map(pedidos => {
        return flatten(pedidos).map(pedido => new Pedido(pedido))
          .sort((a, b) => {
            return Number(b.data.toDate().getTime()) - Number(a.data.toDate().getTime());
          });
      })
    );
  }

  /**
   * Chama o beep() na primeira vez e depois a cada 10 segundos.
   * Completa o fluxo quando não existem mais pedidos pendentes na lista, ou quando o gerenciador é encerrado, ou quando o componente é destruido
   */
  private callBeepPendente() {
    interval(10000).pipe(
      startWith(0),
      mergeMap(() => this.auth.currentUser),
      tap((user) => {
        if (user) {
          this.showSnackBarNovoPedido();
          this.beep();
        } else {
          this.pedidoPendente$.next();
        }
      }),
      takeUntil(merge(this.pedidoPendente$))
    ).subscribe();
  }

  private showSnackBarNovoPedido() {
    // Verificar se está fora do gerenciador de pedidos
    if (this.router.url === "/home/gerenciador/list") return;

    // Disparar a SnackBar
    this.snackBar.show("Oba! Chegou pedido pra você :D", "Ver agora", 8000)
      .afterDismissed()
      .subscribe(action => {
        if (action && action.dismissedByAction) {
          // Redireciona para o gerenciador de pedidos
          this.router.navigate(["/home/gerenciador/list"]);
        }
      });
  }

  private beep() {
    // Emitir uma notificação
    this.messagingService.showNotification();
    // Tocar o beep
    this.bell.play().then();
  }

  /**
   * Faz a verificação se a empresa está no horário de funcionamento e abre a empresa
   * @param empresa
   */
  abrirEmpresaAutomaticamente(empresa: Empresa) {
    if (isNotNullOrUndefined(empresa)) {
      if (!empresa.isAberto()) {
        const horarioDeFuncionamento: Funcionamento = this.getHorarioDeFuncionamento(empresa);
        if (isNotNullOrUndefined(horarioDeFuncionamento)) {
          const isOnHorarioFuncionamentoManha: boolean = this.isOnHorarioFuncionamento(
            this.stringHorarioToDate(horarioDeFuncionamento.aberturaManha),
            this.stringHorarioToDate(horarioDeFuncionamento.fechamentoManha)
          );

          const isOnHorarioFuncionamentoTarde: boolean = this.isOnHorarioFuncionamento(
            this.stringHorarioToDate(horarioDeFuncionamento.aberturaTarde),
            this.stringHorarioToDate(horarioDeFuncionamento.fechamentoTarde)
          );

          if (isOnHorarioFuncionamentoManha || isOnHorarioFuncionamentoTarde) {
            this.iniciarGerenciador(empresa).subscribe();
          }
        }
      }
    }
  }

  getHorarioDeFuncionamento(empresa: Empresa, dia?: string): Funcionamento {
    const dias = ["domingo", "segunda", "terca", "quarta", "quinta", "sexta", "sabado"];
    if (isNullOrUndefined(dia)) {
      dia = dias[moment().toDate().getDay()];
    }

    if (empresa.horario[dia].aberto) {
      return new Funcionamento({
        aberturaManha: empresa.horario[dia].funcionamento.aberturaManha,
        fechamentoManha: empresa.horario[dia].funcionamento.fechamentoManha,
        aberturaTarde: empresa.horario[dia].funcionamento.aberturaTarde,
        fechamentoTarde: empresa.horario[dia].funcionamento.fechamentoTarde
      });
    } else {
      return null;
    }
  }

  isOnHorarioFuncionamento(abertura: Date, fechamento: Date): boolean {
    const horaNow = moment().toDate();
    return horaNow > abertura && horaNow < fechamento;
  }

  stringHorarioToDate(hora: string): Date {
    if (isNullOrUndefined(hora)) {
      return undefined;
    }
    if (hora === "00:00") {
      hora = "23:59";
    }
    const sanitizeHora = hora.replace(":", "");
    return moment(sanitizeHora, "hmm").toDate();
  }

  getHorarioAbertura(empresa: Empresa): Observable<any> {
    return this.fns.httpsCallable("getHorarioAbertura")({id: empresa.id});
  }

  alterarTempoEntrega(empresa: Empresa): Observable<TempoEntrega> {
    return TempoEntregaDialogComponent.openDialog(this.dialog, empresa.tempoEntrega, empresa);
  }

  showMessage(message?) {
    if (message) {
      this.dialogService
        .messageDialog()
        .message(message)
        .show();
    }
  }

  checkEmpresaCallIniciarGerenciador(empresa: Empresa): Observable<any> {
    return this.checkEmpresa(empresa).pipe(
      mergeMap((_empresa: Empresa) => {
        const functionCall = this.fns.httpsCallable("iniciarGerenciador");
        return functionCall({id: _empresa.id, origin: isElectron() ? "desktop" : "web"});
      })
    );
  }

  /**
   * Faz a chamada da function iniciarGerenciador
   * @returns {Observable<any>}
   */
  iniciarGerenciador(empresa: Empresa): Observable<any> {
    return this.checkEmpresaCallIniciarGerenciador(empresa).pipe(
      catchError(err => {
        if (err.details && err.details.hasTempoEntrega && !err.details.hasTempoEntrega) {
          return this.alterarTempoEntrega(empresa).pipe(
            mergeMap(tempoEntrega => {
              if (tempoEntrega instanceof TempoEntrega) {
                return this.checkEmpresaCallIniciarGerenciador(empresa);
              } else {
                return throwError(new Error("É necessário informar o tempo de entrega!"));
              }
            })
          );
        }
        return throwError(err);
      })
    );
  }

  /**
   * Verifica se a empresa não possui pedidos pendentes e encerra o gerenciador
   * @returns {Observable<void>}
   */
  encerrarGerenciador(empresa: Empresa): Observable<any> {
    const functionCall = this.fns.httpsCallable("encerrarGerenciador");
    return this.checkEmpresa(empresa)
      .pipe(
        mergeMap(_empresa => {
          return functionCall({id: _empresa.id})
            .pipe(
              catchError(error => {
                this.showMessage(error && error.message ?
                  error.message.toUpperCase() === "INTERNAL" ? "Ops, parece que você está sem internet!" : error.message
                  : "Ocorreu um erro ao encerrar o Gerenciador! Tente novamente.");
                return of(error);
              })
            );
        })
      );
  }

  /**
   * Altera o campo disponível da empresa
   * @param disponivel
   * @returns {Observable<void>}
   * @param empresa
   */
  setDisponivel(empresa: Empresa, disponivel: boolean): Observable<any> {
    const functionCall = this.fns.httpsCallable("setDisponivel");
    return this.checkEmpresa(empresa)
      .pipe(
        mergeMap(_empresa => {
          return functionCall({id: _empresa.id, disponivel: disponivel})
            .pipe(
              mergeMap(result => {
                if (typeof result === "number") {
                  return throwError(result);
                }
                return of(result);
              })
            );
        })
      );
  }

  private checkEmpresa(empresa: Empresa): Observable<Empresa> {
    return of(empresa)
      .pipe(map(_empresa => {
        if (isNullOrUndefined(_empresa) || isNullOrUndefined(_empresa.id)) {
          throw new Error("Problemas ao carregar os dados da empresa! Tente atualizar a página.");
        }
        return empresa;
      }));
  }

  protected orderBy(): OrderBy {
    return new OrderByDesc("data");
  }

}
