import {Component, OnDestroy, OnInit} from "@angular/core";
import {QzTrayService} from "../../services/qz-tray.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {Observable, Subscription} from "rxjs/Rx";
import {map, finalize} from "rxjs/operators";
import {LoadingService} from "../../services/loading/loading.service";

@Component({
  selector: "app-select-impressora-dialog",
  templateUrl: "./select-impressora-dialog.component.html",
  styleUrls: ["./select-impressora-dialog.component.scss"]
})
export class SelectImpressoraDialogComponent implements OnInit, OnDestroy {

  static showDialog(dialog: MatDialog): Observable<any> {
    const config = {
      width: "400px"
    };
    return dialog.open(SelectImpressoraDialogComponent, config).afterClosed();
  }

  loadingName = "printerLoading";
  private printersSubscription: Subscription;
  printers: string[];

  constructor(public dialog: MatDialogRef<SelectImpressoraDialogComponent>,
              public loadingService: LoadingService,
              public qz: QzTrayService) {
  }

  ngOnInit() {
    this.loading(true);
    this.printersSubscription = this.qz.getPrinters()
      .pipe(
        map(printers => printers),
        map(printers => printers.filter(p => p)),
        finalize(() => this.loading(false))
      )
      .subscribe((printers: string[]) => {
        this.printers = printers;
      }, (error) => {
        console.log("Error in getPrinters()", error);
      });
  }

  loading(show: boolean) {
    if (show) {
      this.loadingService.register(this.loadingName);
    } else {
      this.loadingService.timeOut(this.loadingName);
    }
  }

  select(printer) {
    this.dialog.close(printer);
  }

  ngOnDestroy() {
    if (this.printersSubscription) {
      this.printersSubscription.unsubscribe();
    }
  }
}
