import {isEmpty, isNotNullOrUndefined} from "../utils/commons";
import * as firebase from "firebase";
import Timestamp = firebase.firestore.Timestamp;
import {Bairro} from "./bairro.model";
import {AppUser} from "./appUser";

export class Cliente {
  public id: string;
  public nome: string;
  public cpf: string;
  public dataNascimento: Timestamp;
  public email: string;
  public imageProfile: string;
  public telefone: string;
  public numeroPedidos: number;
  public numeroPedidosEmpresa: number;

  // Atributos novos
  public sexo: string;
  public appVersion: AppVersion;
  public bloqueado: boolean;
  public contaVerificada: boolean;
  public dataCadastro: Timestamp;
  public dataUltimaEdicao: Timestamp;
  public iuguCustomerId: string;
  public lastActive: Timestamp;
  public origem: string;
  public pedidos: Pedidos;
  public phoneVerified: boolean;
  public poms: Poms;
  public tokens: string[];
  public tokensOneSignal: string[];
  public nomeReceita: string;
  public tipoBloqueio: TipoBloqueioCliente;

  constructor(cliente?: any) {
    if (cliente) {
      this.id = cliente.id;
      this.nome = cliente.nome;
      this.cpf = cliente.cpf;
      this.dataNascimento = cliente.dataNascimento;
      this.email = cliente.email;
      this.imageProfile = cliente.imageProfile;
      this.telefone = cliente.telefone;
      this.numeroPedidos = isNotNullOrUndefined(cliente.numeroPedidos) ? cliente.numeroPedidos : null;
      this.numeroPedidosEmpresa = isNotNullOrUndefined(cliente.numeroPedidosEmpresa) ? cliente.numeroPedidosEmpresa : null;

      // atributos novos
      this.sexo = cliente.sexo;
      this.appVersion = cliente.appVersion;
      this.bloqueado = cliente.bloqueado;
      this.contaVerificada = cliente.contaVerificada;
      this.dataCadastro = cliente.dataCadastro;
      this.dataUltimaEdicao = cliente.dataUltimaEdicao;
      this.iuguCustomerId = cliente.iuguCustomerId;
      this.lastActive = cliente.lastActive;
      this.origem = cliente.origem;
      this.pedidos = cliente.pedidos;
      this.phoneVerified = cliente.phoneVerified;
      this.poms = cliente.poms;
      this.tokens = cliente.tokens;
      this.tokensOneSignal = cliente.tokensOneSignal;
      this.nomeReceita = cliente.nomeReceita;
      this.tipoBloqueio = this.bloqueado && isEmpty(cliente.tipoBloqueio) ? TipoBloqueioCliente.PAGAMENTO_ONLINE : cliente.tipoBloqueio;
    }
  }
}

export class AppVersion {
  public android?: string;
  public ios?: string;

  constructor(data: any) {
    this.android = data.android ? data.android : null;
    this.ios = data.ios ? data.ios : null;
  }
}

export class Pedidos {
  public aprovados: number;
  public cancelados: number;
  public rejeitados: number;
  public todos: number;

  constructor(data: any) {
    this.aprovados = data.aprovados;
    this.cancelados = data.cancelados;
    this.rejeitados = data.rejeitados;
    this.todos = data.todos;
  }
}

export class Poms {
  public lastUpdate: Timestamp;
  public saldoDisponivel: number;
  public saldoPendente: number;

  constructor(data: any) {
    this.lastUpdate = data.lastUpdate;
    this.saldoDisponivel = data.saldoDisponivel;
    this.saldoPendente = data.saldoPendente;
  }
}

export class Address {
  public id: string;
  public bairro: Bairro;
  public cep: string;
  public complemento: string;
  public logradouro: string;
  public numero: string;
  public pontoReferencia: string;
  public deletedAt: Timestamp;

  constructor(data: any) {
    this.id = data.id;
    this.bairro = data.bairro;
    this.cep = data.cep;
    this.complemento = data.complemento;
    this.logradouro = data.logradouro;
    this.numero = data.numero;
    this.pontoReferencia = data.pontoReferencia;
    this.deletedAt = data.deletedAt;
  }
}

export class Log {
  data: Timestamp;
  motivo: string;
  operacao: TipoOperacaoCliente;
  usuario: AppUser;
  tipoBloqueio: TipoBloqueioCliente;

  constructor(data: any) {
    if (data) {
      this.data = data.data;
      this.motivo = data.motivo;
      this.operacao = data.operacao;
      this.usuario = data.usuario;
      this.tipoBloqueio = data.tipoBloqueio;
    }
  }
}

export enum TipoOperacaoCliente {
  BLOQUEIO = "BLOQUEIO",
  DESBLOQUEIO = "DESBLOQUEIO",
  ADD_POMS = "ADD_POMS"
}

export enum TipoBloqueioCliente {
  GERAL = "GERAL",
  PAGAMENTO_ONLINE = "PAGAMENTO_ONLINE"
}
