import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EmpresaService} from "../empresa.service";
import {Empresa} from "../../../models/empresa";
import {Observable, Subscription} from "rxjs";
import {AuthService} from "../../../modules/login/auth.service";
import {isNotNullOrUndefined} from "../../../utils/commons";
import {FormControl} from "@angular/forms";
import {UsersService} from "../../users/users.service";
import {debounceTime} from "rxjs/operators";
import {of} from "rxjs/observable/of";

@Component({
  selector: "app-select-empresa-dialog",
  templateUrl: "./select-empresa-dialog.component.html",
  styleUrls: ["./select-empresa-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SelectEmpresaDialogComponent implements OnInit, OnDestroy {

  static openDialog(dialog: MatDialog): Observable<any> {
    const config = {
      width: "500px",
      minHeight: "600px"
    };

    const ref = dialog.open(SelectEmpresaDialogComponent, config);
    return ref.afterClosed();
  }

  readonly loadingName = "loadEmpresas";
  public disableSearch: boolean = false;
  public empresas$: Observable<Empresa[]>;
  public empresasSubscription: Subscription;
  public searchControl = new FormControl();
  public subtitulo = "Digite o que deseja buscar.";
  public icon = "search";

  constructor(public dialogRef: MatDialogRef<SelectEmpresaDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data: any,
              public auth: AuthService,
              private empresaService: EmpresaService,
              private userService: UsersService) {
    this.disableSearch = this.userService.authService.user.isLojista();
    this.empresas$ = of([]);
  }

  ngOnInit() {
    this.buscarEmpresas();
  }

  select(empresa: Empresa) {
    this.userService.setEmpresaSelecionada(empresa)
      .subscribe(() => {
        // Manter a empresa selecionada
        this.auth.currentEmpresa = empresa;
        // Fechar o dialog e retornar a empresa selecionada
        this.dialogRef.close(true);
      });
  }

  sairDaEmpresa() {
    this.userService.setEmpresaSelecionada(null)
      .subscribe(() => {
        // Limpar a empresa selecionada
        this.auth.currentEmpresa = null;
        // Fechar o dialog
        this.dialogRef.close(true);
      });
  }

  isShowSairDaEmpresaButton(): boolean {
    // Se tem uma empresa selecionada
    return isNotNullOrUndefined(this.auth.currentEmpresa) &&
      // E o usuário é administrador
      isNotNullOrUndefined(this.auth.user) && !this.auth.user.isLojista();
  }

  ngOnDestroy() {
    if (this.empresasSubscription) {
      this.empresasSubscription.unsubscribe();
    }
  }

  public buscarEmpresas(): void {
    if (this.userService.authService.user.isLojista()) {
      this.empresas$ = this.empresaService.getEmpresasLojista(this.auth.user).take(1);
    } else {
      this.empresasSubscription = this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(value => {
        this.subtitulo = "Nenhum registro encontrado!";
        this.icon = "receipt";
        this.empresas$ = this.empresaService.getCompaniesByNomeFantasia(value, false, false);
      });
    }
  }
}
