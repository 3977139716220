import * as firebase from "firebase";
import DocumentReference = firebase.firestore.DocumentReference;

export abstract class FireModel {

  id: string;
  ref: DocumentReference;

  constructor(id?: string, ref?: DocumentReference) {
    this.id = id;
    this.ref = ref ? ref : null;
  }

}
