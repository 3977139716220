import {isNullOrUndefined} from "./commons";


export class StringUtils {

  static equalsTo(first: string, second: string): boolean {
    return StringUtils.normalize(first) === StringUtils.normalize(second);
  }

  static equalsToIgnoreCase(first: string, second: string): boolean {
    let _first = StringUtils.normalize(first);
    _first = !isNullOrUndefined(_first) ? _first.toUpperCase() : _first;
    let _second = StringUtils.normalize(second);
    _second = !isNullOrUndefined(_second) ? _second.toUpperCase() : _second;
    return _first === _second;
  }

  static normalize(value: string): string {
    return !isNullOrUndefined(value) ? value.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : value;
  }

  static wordToTitleCase(word: string): string {
    return word.trim()[0].toUpperCase() + word.trim().substr(1).toLowerCase();
  }

}
