import {RecordByEmpresa} from "./record-by-empresa";
import {from, Observable, of} from "rxjs";
import {groupBy, map, mergeMap, scan, toArray} from "rxjs/operators";
import {flatten} from "@angular/compiler";
import {isEmpty, isNullOrUndefined} from "../utils/commons";

export class ComplementoProduto extends RecordByEmpresa {
  public nome: string;
  public preco: number;
  public descricao: string;
  public grupo: string;
  public codigoPDV: string;
  public inactive: boolean;

  constructor(complemento: any = {}) {
    super(complemento.id, complemento.ref, complemento.empresa);
    this.nome = complemento.nome;
    this.preco = complemento.preco;
    this.descricao = complemento.descricao;
    this.grupo = complemento.grupo;
    this.codigoPDV = complemento.codigoPDV;
    this.inactive = complemento.inactive;
  }
}

export class ComplementosProdutoByGrupo {
  grupo: string;
  complementos: ComplementoProduto[];

  constructor(data: any = {}) {
    this.grupo = data.grupo;
    this.complementos = data.complementos;
  }
}

/** Transforma uma lista de ComplementoProduto em uma lista de ComplementosProdutoByGrupo*/
export function groupComplementosProdutoByGrupo(complementosProduto: ComplementoProduto[]): Observable<ComplementosProdutoByGrupo[]> {
  if (isNullOrUndefined(complementosProduto) || isEmpty(complementosProduto)) {
    return of([]);
  }
  return from(complementosProduto).pipe(
    groupBy((complementoProduto) => complementoProduto.grupo),
    mergeMap((group) => {
      return group.pipe(
        toArray(),
        map(
          (complementos) =>
            new ComplementosProdutoByGrupo({
              grupo: group.key,
              complementos: complementos,
            })
        )
      );
    }),
    scan((a, c) => [...a, c], []),
    map((res) => flatten(res)),
    map((complementoByGrupo) => {
      return complementoByGrupo.sort((a, b) =>
        a.grupo ? a.grupo.localeCompare(b.grupo) : null
      );
    })
  );
}
