export class CustomersIugu {
  public cc_emails: string;
  public city: string;
  public complement: string;
  public cpf_cnpj: string;
  public created_at: string;
  public default_payment_method_id: string;
  public district: string;
  public email: string;
  public id: string;
  public name: string;
  public notes: string;
  public number: string;
  public proxy_payments_from_customer_id: string;
  public state: string;
  public street: string;
  public updated_at: string;
  public zip_code: string;
  public custom_variables: any[];

  constructor(customer: any = {}) {
    this.cc_emails = customer.cc_emails;
    this.city = customer.city;
    this.complement = customer.complement;
    this.cpf_cnpj = customer.cpf_cnpj;
    this.created_at = customer.created_at;
    this.default_payment_method_id = customer.default_payment_method_id;
    this.district = customer.district;
    this.email = customer.email;
    this.id = customer.id;
    this.name = customer.name;
    this.notes = customer.notes;
    this.number = customer.number;
    this.proxy_payments_from_customer_id = customer.proxy_payments_from_customer_id;
    this.state = customer.state;
    this.street = customer.street;
    this.updated_at = customer.updated_at;
    this.zip_code = customer.zip_code;
    this.custom_variables = customer.custom_variables;
  }
}
