import {IFormCanDeactivate} from "./../../../guards/iform-canDeactivate";
import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {UsersService} from "../users.service";
import {AppUser, UserType} from "../../../models/appUser";
import {PageService} from "../../../services/page.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {MaskUtil} from "../../../utils/mask-util";
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {AuthService} from "../../../modules/login/auth.service";
import {PerfilService} from "../../perfil-acesso/perfil.service";
import {PerfilAcesso} from "../../../models/perfil-acesso.model";
import * as firebase from "firebase";
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";
import {Empresa} from "../../../models/empresa";
import {map, mergeMap, pairwise, startWith, tap, skip} from "rxjs/operators";
import DocumentReference = firebase.firestore.DocumentReference;

@Component({
  selector: "app-users-form",
  templateUrl: "./users-form.component.html",
  styleUrls: ["./users-form.component.scss"]
})
export class UsersFormComponent extends PageService<AppUser> implements OnInit, AfterViewInit, OnDestroy, IFormCanDeactivate {

  form: FormGroup;

  // Booleano que verifica o status da atualização de um formulário
  formMudou: boolean = null;

  // Variável que guarda o usuário logado
  loggedUser: AppUser = new AppUser();

  userSubscription: Subscription;
  perfilSubscription: Subscription;

  readonly maskCellPhone = MaskUtil.maskCell;

  // Exibe o botão de importar usuário
  showImportUserButton: boolean = false;

  showDesimportUserButton: boolean = false;

  // Perfis de acesso
  perfisObservable: Observable<PerfilAcesso[]>;
  perfis: PerfilAcesso[] = [];
  perfilSelected: PerfilAcesso;

  readonly UserType = UserType;
  loggedUserIsAdministrador = new BehaviorSubject<boolean>(false);
  consultorSubject = new BehaviorSubject<string>("");
  userTypeSubject = new BehaviorSubject<string>("");

  consultores: Observable<AppUser[]>;

  isNullOrUndefined = isNullOrUndefined;
  canSubscribeToChanges: boolean = false;

  constructor(public service: UsersService, dialog: MatDialog,
              dialogService: DialogService, loadingService: LoadingService,
              snack: SnackService, cdRef: ChangeDetectorRef,
              route: ActivatedRoute, router: Router,
              public formBuilder: FormBuilder,
              public perfilService: PerfilService,
              public authService: AuthService) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/users/");

    // Configurar o Formulário
    this.form = formBuilder.group({
      name: ["", [Validators.required, Validators.minLength(5)]],
      phone: ["", []],
      email: ["", [Validators.required, Validators.email]],
      perfilAcesso: ["", [Validators.required]],
      userType: ["", [this.userTypeRequired.bind(this)]],
      comissao: [null, [this.validateComissao.bind(this)]],
      consultorId: [null, [this.validateConsultorId.bind(this)]],
      inactive: [false]
    });

    this.getPerfis();

    /**
     * Inicializa método que verifica se ocorreram mudanças no conteúdo do formulário,
     * para decidir o comportamento do método podeMudarRota (canDeactivate)
     */
    this.onChanges();
  }

  getPerfis() {
    this.perfisObservable = this.loggedUserIsAdministrador.asObservable()
      .pipe(mergeMap((isAdministrador: boolean) => {
        if (isAdministrador) {
          return this.userTypeSubject.asObservable().pipe(mergeMap((userType: string) => {
            if (userType === UserType.Assistente) {
              return this.consultorSubject.asObservable().pipe(mergeMap((consultorId) => {
                if (isNotNullOrUndefined(consultorId) && !isEmpty(consultorId)) {
                  return this.perfilService.getPerfisByConsultor(consultorId);
                } else {
                  return of([]);
                }
              }));
            } else if (userType !== UserType.Assistente && !isEmpty(userType) || this.authService.currentEmpresa) {
              return this.perfilService.getPerfisByLoggedUser();
            } else {
              return of([]);
            }
          }));
        } else if (this.loggedUser.isConsultorOrAssistente()) {
          return this.perfilService.getPerfisByLoggedUser().pipe(mergeMap((perfis: PerfilAcesso[]) => {
            if (this.item.id === this.loggedUser.id) {
              return this.perfilService.getById(this.item.perfilAcesso.id)
                .pipe(mergeMap((perfil: PerfilAcesso) => {
                  return of([perfil]);
                }));
            } else {
              return of(perfis);
            }
          }));
        } else {
          return this.perfilService.getPerfisByLoggedUser();
        }
      }), tap(perfis => {
        this.perfis = perfis;
      }));
  }

  userTypeRequired(control: AbstractControl): ValidationErrors | null {
    if (this.loggedUser.isAdministrador() && !this.authService.currentEmpresa) {
      return Validators.required(control);
    } else {
      return null;
    }
  }

  validateConsultorId(control: AbstractControl): ValidationErrors | null {
    if (this.item.isAssistente()) {
      return Validators.required(control);
    } else {
      return null;
    }
  }

  validateComissao(control: AbstractControl): ValidationErrors | null {
    if (this.item.isConsultor()) {
      return Validators.required(control);
    } else {
      return null;
    }
  }

  subscribeToFormChanges() {
    this.form.valueChanges.pipe(startWith({}), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (this.isNewRecord() || this.canSubscribeToChanges) {
          if (this.loggedUser.isAdministrador()) {
            if (isNullOrUndefined(prev.userType) || isEmpty(prev.userType)) {
              if (next.userType === UserType[UserType.Assistente] && isNotNullOrUndefined(next.consultorId)) {
                // Emitir os subjects para carregar os perfis do consultor primeira vez
                this.consultorSubject.next(next.consultorId);
                this.userTypeSubject.next(next.userType);
              } else {
                // Emitir os usbjects para carregar os perfis geral primeira vez
                this.userTypeSubject.next(next.userType);
              }
              // Verifica se alterou o tipo de Assistente para qualquer outro tipo
            } else if (prev.userType === UserType[UserType.Assistente] && next.userType !== UserType[UserType.Assistente]) {
              // Se alterou para Consultor, adiciona o campo comissão como required
              if (next.userType === UserType[UserType.Consultor]) {
                this.form.get("comissao").setValidators(Validators.required);
              }
              // Tira a obrigatoriedade de informar os consultor
              this.clearValidators("consultorId");
              this.userTypeSubject.next(next.userType);
            } else if (next.userType === UserType[UserType.Assistente]) {
              // Se o tipo for assistente, os validators do campo comissão são limpos e o consultor é obrigatorio
              this.form.get("consultorId").setValidators(Validators.required);
              this.clearValidators("comissao");
              if (isNullOrUndefined(next.consultorId)) {
                this.userTypeSubject.next(next.userType);
              } else if (prev.userType === next.userType && prev.consultorId !== next.consultorId ||
                prev.userType !== next.userType && prev.consultorId === next.consultorId) {
                this.consultorSubject.next(next.consultorId);
                this.form.get("perfilAcesso").patchValue("", {emitEvent: false});
              }
            }
          }
        }
      });
  }

  ngOnInit() {
    super.ngOnInit();

    this.userSubscription = this.authService.currentUser
      .subscribe((user: AppUser) => {
        this.loggedUser = user;

        this.loggedUserIsAdministrador.next(this.loggedUser.isAdministrador());

        this.subscribeToFormChanges();

        this.consultores = this.loggedUser.isAdministrador() ? this.service.getAllConsultores() : null;
        this.showImportUserButton = this.isShowImportUser(user);
      });

    this.itemSubject.asObservable()
      .subscribe(user => {
        this.item = user;

        this.consultorSubject.next(user.consultorId);
        this.userTypeSubject.next(user.userType);

        if (this.verifyIfUserCanAccessLoadedUser(this.loggedUser, this.item)) {
          this.form.patchValue(user);
        }

        if (user.emailVerificado) {
          this.form.get("email").disable();
        }
      });

    this.form.valueChanges
      .subscribe((value: FormControl[]) => {
        if (value) {
          Object.keys(value).forEach(key => {
            if (key !== "perfilAcesso") {
              this.item[key] = value[key];
            }
          });

          if (isNotNullOrUndefined(value["perfilAcesso"])) {
            this.perfilSelected = value["perfilAcesso"];
          }
        }
      });
  }

  verifyIfUserCanAccessLoadedUser(loggedUser: AppUser, loadedUser: AppUser): boolean | void {
    // Se o loggedUser for um Consultor e o loadedUser não for Lojista
    if (loggedUser.isConsultor() && !loadedUser.isLojista()) {
      // Se o loadedUser for igual ao loggedUser ou o loadedUser for Assistente do loggedUser retorna true
      if (loggedUser.id === loadedUser.id || loggedUser.id === loadedUser.consultorId) {
        return true;
      } else {
        // Caso contrário redireciona o usuario para a tela anterior
        this.item.lastLogin = null;
        this.showMessageAndClose("Sem permissão para acessar esse usuário");
      }
      // Se o loggedUser for um Assistente e o loadedUser não for Lojista
    } else if (loggedUser.isAssistente() && !loadedUser.isLojista()) {
      if (loggedUser.consultorId === loadedUser.consultorId) {
        // Se o consultorId do loggedUser for igual o mesmo do loadedUser retorna true
        return true;
      } else {
        // Caso contrário redireciona o usuario para a tela anterior
        this.item.lastLogin = null;
        this.showMessageAndClose("Sem permissão para acessar esse usuário");
      }
    } else {
      // Se o loggedUser for Administrador ou Lojista essa verificação é feita no afterLoadItem()
      return true;
    }
  }

  clearValidators(formControlName: string) {
    this.form.get(formControlName).setErrors(null);
    this.form.get(formControlName).clearValidators();
  }

  getConsultorId(): string {
    if (this.loggedUser.isConsultor()) {
      return this.loggedUser.id;
    } else if (this.loggedUser.isAssistente()) {
      return this.loggedUser.consultorId;
    } else {
      return this.item.consultorId;
    }
  }

  getUserType(): string {
    if (this.loggedUser.isConsultorOrAssistente()) {
      return UserType.Assistente;
    } else {
      return this.item.userType;
    }
  }

  userTypesKeys(): Array<string> {
    return Object.keys(this.UserType);
  }

  empresaIsSelected(): boolean {
    return isNotNullOrUndefined(this.authService.currentEmpresa);
  }

  private isShowImportUser(user: AppUser): boolean {
    // Primeiro passo, verificar se tem uma empresa selecionada
    if (isNotNullOrUndefined(this.authService.currentEmpresa)) {
      // Verifica se o usuário está logado
      if (isNotNullOrUndefined(user)) {
        // Se o usuário logado for adminstrador
        return !user.isLojista();
      }
    }
    return false;
  }

  private isShowDesimportUser(user: AppUser): boolean {
    return this.isShowImportUser(user) && this.item.lojistaHasMultipleEmpresas();
  }

  getPerfilSelectionRef(): DocumentReference {
    return this.perfilService.getDoc(this.perfilSelected.id).ref;
  }

  save() {
    this.formMudou = false;
    this.loading(true);

    // Verifica se já existe usuário com o e-mail informado já cadastrado na base de dados
    this.service.findByEmail(this.item.email)
      .pipe(map(user => {
        // Verificar se o usuário existe
        // Caso exista, verificar se é o mesmo que está sendo salvo,
        // nesse caso, o usuário está apenas sendo atualizado, mas não mudou o email
        return isNullOrUndefined(user) ||
          (isNotNullOrUndefined(user) && isNotNullOrUndefined(this.item) && user.id === this.item.id);
      }))
      .subscribe((isNewUserOrEditUser: boolean) => {
        if (isNewUserOrEditUser) {
          this.validateAndSave();
        } else {
          this.loading(false);

          // Informa o usuário que o usuário cadastrado já existe na base de dados
          this.dialogService
            .messageDialog()
            .message(`Já existe outro usuário com o email ${this.item.email}. Verifique!`)
            .show();
        }
      });

  }

  private validateAndSave(): Observable<void> {

    // Manter a empresa atual
    const currentEmpresa = this.authService.currentEmpresa;

    // Verificar se o usuário é novo
    if (isNullOrUndefined(this.item.id)) {

      // Verificar se tem uma empresa selecionada
      if (currentEmpresa) {

        // Salva o id da empresa no registro
        if (!this.item.empresas.some((empresa) => empresa === currentEmpresa.id)) {
          this.item.empresas.push(currentEmpresa.id);
        }

        // Salvar o perfil por empresa
        this.item.perfisByEmpresa[currentEmpresa.id] = this.getPerfilSelectionRef();

        // Marcar o usuário como lojista
        this.item.userType = UserType[UserType.Lojista];

      } else {

        this.item.consultorId = this.getConsultorId();
        this.item.userType = this.getUserType();

        // Salvar o perfil no usuário
        this.item.perfilAcesso = this.getPerfilSelectionRef();

        // Marcar o usuário como sendo Administrador
        //this.item.userType = UserType[UserType.Administrador];
      }

    } else {

      // Está editando um usuário

      // Verificar se o usuário que será salvo não é do tipo Lojista
      if (!this.item.isLojista()) {

        // Salva o perfil selecionado no usuário
        this.item.perfilAcesso = this.getPerfilSelectionRef();

      } else if (isNotNullOrUndefined(currentEmpresa)) {

        // Adicionar a empresa na lista caso não exista ainda
        if (!this.item.empresas.some((empresa) => empresa === currentEmpresa.id)) {
          this.item.empresas.push(currentEmpresa.id);
        }

        // Salvar o perfil na empresa
        this.item.perfisByEmpresa[currentEmpresa.id] = this.getPerfilSelectionRef();

      } else {
        throw new Error("Não foi possível salvar o usuário. É necessário estar logado em uma empresa!");
      }

    }

    return super.saveOrUpdate(this.item);
  }

  afterLoadItem() {
    this.showDesimportUserButton = this.isShowDesimportUser(this.loggedUser);
    // Verificar se tem um perfil informado no usuário, lembrando que,
    // apenas Administradores e Representantes tem perfil informado no usuário
    if (this.item.perfilAcesso) {
      // Carrega o perfil
      this.loadPerfilSelected(this.item.perfilAcesso);
    } else {
      // Para esse tipo de usuário, é necessário ter uma empresa selecionada

      // Verificar se tem uma empresa selecionada
      if (isNullOrUndefined(this.authService.currentEmpresa)) {
        this.showMessageAndClose("É necessário que esteja logado em uma empresa!");
        return;
      }

      // Buscar a permissão do usuário nessa empresa
      const perfilByEmpresa = this.item.perfisByEmpresa ?
        this.item.perfisByEmpresa[this.authService.currentEmpresa.id] : null;

      if (perfilByEmpresa) {
        // Carregar o perfil do usuário na empresa
        this.loadPerfilSelected(perfilByEmpresa);
      } else {
        this.showMessageAndClose("Não foi encontrado um perfil de acesso para esse usuário nessa empresa!");
      }
    }
    this.canSubscribeToChanges = true;
  }

  protected isNewRecord(): boolean {
    return super.isNewRecord();
  }

  showMessageAndClose(message) {
    this.dialogService
      .messageDialog()
      .message(message)
      .show()
      .subscribe(() => this.goBack());
  }

  loadPerfilSelected(perfil: DocumentReference) {
    this.perfilService.getById(perfil.id)
      .subscribe((value: PerfilAcesso) => {
        if (value) {
          // Verificar se o perfil do usuário está na lista de perfis da empresa
          if (this.perfis.some(pf => pf.id === value.id)) {
            this.perfilSelected = value;
            this.form.get("perfilAcesso").patchValue(this.perfilSelected);
          }
          if ((this.item && this.item.id === this.loggedUser.id)) {
            this.form.get("perfilAcesso").patchValue(value);
            this.form.get("userType").disable();
            this.form.get("comissao").disable();
            this.form.get("perfilAcesso").disable();
          }
        }
      });
  }

  appUserCompareWith(c1: string, c2: string): boolean {
    return c1 === c2;
  }

  perfilCompareWith(p1: PerfilAcesso, p2: PerfilAcesso): boolean {
    return p1 && p2 && p1.id === p2.id;
  }

  userTypeCompareWith(p1: UserType, p2: UserType): boolean {
    return p1 === p2;
  }

  newItem(): AppUser {
    return new AppUser();
  }

  importUser() {
    this.dialogService.promptDialog()
      .title("Importar usuário")
      .message("Informe o e-mail do usuário que gostaria de importar:")
      .disableClose(true)
      .show()
      .subscribe((email: string) => {
        if (email) {
          this.loadingService.showTopBar();
          // Buscar o usuário pelo email informado
          (this.service as UsersService)
            .findByEmail(email)
            .subscribe(user => {
              this.loadingService.hideTopBar();

              // Verificar se encontrou um usuário com o email informado
              if (isNotNullOrUndefined(user)) {

                // Verificar se o usuário é lojista, pois essa operação só é permitida para usuários lojistas
                if (UserType[user.userType] as UserType !== UserType.Lojista) {
                  this.dialogService
                    .messageDialog()
                    .message("Essa operação é permitida apenas para usuários lojistas!")
                    .show();
                  return;
                }

                // Verificar se o usuário já não está cadastrado na mesma empresa
                const currentEmpresa: Empresa = this.authService.currentEmpresa;
                if (user.empresas.includes(currentEmpresa.id)) {
                  this.dialogService
                    .messageDialog()
                    .message(`O usuário "${user.name}" já está cadastrado na empresa "${currentEmpresa.nomeFantasia}". Verifique!`)
                    .show();
                  return;
                }

                // Caso esteja tudo OK, carrega os dados do usuário
                this.itemSubject.next(user);

              } else {
                this.dialogService
                  .messageDialog()
                  .message(`Nenhum usuário encontrado com o e-mail "${email}"`)
                  .show();
              }
            });
        }
      });
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    if (isNotNullOrUndefined(this.userSubscription)) {
      this.userSubscription.unsubscribe();
    }

    if (isNotNullOrUndefined(this.perfilSubscription)) {
      this.perfilSubscription.unsubscribe();
    }
  }

  // Verifica se ocorreram mudanças no formulário,alterando o valor da variável formMudou para true, ou mantendo em null por padrão.
  onChanges() {
    let mudanca = 0;
    this.form.valueChanges.pipe(
      skip(2)
    ).subscribe(() => {
      if (mudanca === 0) {
        this.formMudou = false;
        mudanca++;
      } else {
        this.formMudou = true;
      }
    });
  }

  // Caso o formulário foi modificado
  podeMudarRota(): boolean {
    if (this.formMudou === true) {
      return confirm("Os dados ainda não foram salvos. Deseja sair mesmo assim?");
    }
    return true;
  }

  /**
   * Desvincula o usuário da empresa caso ele esteja vinculado a mais de uma empresa.
   */
  desimportUser() {
    this.dialogService.confirmDialog()
      .title("Desvincular usuário")
      .message("Ao desvincular o usuário ele não terá mais acesso a essa empresa.")
      .acceptButton("Confirmar")
      .cancelButton("Cancelar")
      .show()
      .subscribe(accept => {
        if (accept && this.item.empresas.length > 1) {
          this.item.empresas = this.item.empresas.filter(empresa => this.authService.currentEmpresa.id !== empresa);
          this.item.empresaSelectedId = null;
          delete this.item.perfisByEmpresa[this.authService.currentEmpresa.id];
          super.saveOrUpdate(this.item);
        }
      });
  }
}
