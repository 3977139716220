import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from "@angular/core";
import {PageService} from "../../../services/page.service";
import {Produto} from "../../../models/produto.model";
import {ProdutoService} from "../produto.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CategoriaProduto} from "../../../models/categoria.model";
import {tap} from "rxjs/operators";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {Subscription} from "rxjs";
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";
import {IFormCanDeactivate} from "../../../guards/iform-canDeactivate";
import {PoMQuery} from "../../../services/firebase/criteria/query";
import {OrderByAsc} from "../../../services/firebase/criteria/order-by-asc";
import {Criterion} from "../../../services/firebase/criteria/criterion";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {FormControl} from "@angular/forms";

@Component({
  selector: "app-produto-list",
  templateUrl: "./produto-list.component.html",
  styleUrls: ["./produto-list.component.scss"]
})
export class ProdutoListComponent extends PageService<Produto> implements OnInit, OnDestroy, IFormCanDeactivate {

  produtosSubscription: Subscription;
  produtos: Produto[] = [];
  grupos: Group[] = [];
  gruposFiltered: Group[] = [];
  produtosArquivadosSubscription: Subscription;
  gruposArquivados: Group[] = [];
  isReordering: boolean = false;
  newOrder: Produto[];
  orderMudou: boolean = false;
  hideReordenar: boolean = false;
  arquivando: boolean = false;
  showSearch: boolean = false;
  searchControl: FormControl = new FormControl();

  constructor(service: ProdutoService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/produtos/");
  }

  ngOnInit() {
    super.ngOnInit();

    this.buscarProdutos();

    this.loadingService.register("loadArquivados");

    this.searchControl.valueChanges.subscribe(searchTerm => {
      const produtosFiltered = this.produtos.filter(produto => {
        return isEmpty(searchTerm) ? true : produto.nome.toLowerCase().includes(searchTerm.toLowerCase());
      });
      this.createGroups(produtosFiltered);
    });
  }

  buscarProdutos() {
    const queryFn: PoMQuery = new PoMQuery();
    queryFn.add(new OrderByAsc("categoria.ordem"));
    queryFn.add(new OrderByAsc("nomeNormalized"));
    queryFn.add(new Criterion("arquivado", "==", false));

    this.produtosSubscription = this.service.getCollection(queryFn)
      .valueChanges()
      .pipe(
        tap(() => this.grupos = [])
      )
      .subscribe(produtos => {
        // Montar a matriz de produtos e categorias
        this.produtos = produtos;
        this.createGroups(produtos);
      });
  }

  createGroups(produtos: Produto[]) {
    this.grupos = [];
    produtos.forEach(produto => {
      this.addProduto(produto);
    });
  }

  addProduto(produto: Produto) {
    // Verificar se a categoria já existe
    const grupo = this.findGroupByCategoria(produto.categoria);
    if (!grupo) {
      this.grupos.push(new Group(produto));
    } else {
      grupo.produtos.push(produto);
      // Ordenar
      grupo.produtos.sort((p1, p2) => {
        if (p1.sequence > p2.sequence) {
          return 1;
        }
        if (p1.sequence < p2.sequence) {
          return -1;
        }
        return 0;
      });
    }
  }

  findGroupByCategoria(categoria: CategoriaProduto): Group {
    for (let i = 0; i < this.grupos.length; i++) {
      if (this.grupos[i].categoria.id === categoria.id) {
        return this.grupos[i];
      }
    }

    return undefined;
  }

  /** ==================================BUSCAR=LISTA=DE=PRODUTOS=ARQUIVADOS========================================== */
  buscarProdutosArquivados() {
    if (isNullOrUndefined(this.produtosArquivadosSubscription)) {
      const queryFn: PoMQuery = new PoMQuery();
      queryFn.add(new OrderByAsc("categoria.ordem"));
      queryFn.add(new OrderByAsc("nomeNormalized"));
      queryFn.add(new Criterion("arquivado", "==", true));

      this.produtosArquivadosSubscription = this.service.getCollection(queryFn)
        .valueChanges()
        .pipe(
          tap(() => this.gruposArquivados = [])
        )
        .subscribe(produtos => {
          // Montar a matriz de produtos e categorias
          produtos.forEach(produto => {
            this.addProdutoArquivado(produto);
          });
          this.loadingService.timeOut("loadArquivados");
        });
    }
  }

  addProdutoArquivado(produto: Produto) {
    // Verificar se a categoria já existe
    const grupo = this.findGroupByCategoriaArquivado(produto.categoria);
    if (!grupo) {
      this.gruposArquivados.push(new Group(produto));
    } else {
      grupo.produtos.push(produto);
      // Ordenar
      grupo.produtos.sort((p1, p2) => {
        if (p1.sequence > p2.sequence) {
          return 1;
        }
        if (p1.sequence < p2.sequence) {
          return -1;
        }
        return 0;
      });
    }
  }

  findGroupByCategoriaArquivado(categoria: CategoriaProduto): Group {
    for (let i = 0; i < this.gruposArquivados.length; i++) {
      if (this.gruposArquivados[i].categoria.id === categoria.id) {
        return this.gruposArquivados[i];
      }
    }

    return undefined;
  }

  /** =============================================================================================================== */

  onTabChange(event: MatTabChangeEvent) {
    if (isNotNullOrUndefined(event.tab) && event.tab.textLabel === "Arquivados") {
      this.hideReordenar = true;
      this.buscarProdutosArquivados();
    } else {
      this.hideReordenar = false;
    }
  }

  newItem(): Produto {
    return new Produto();
  }

  ativarOuInativar(event: EventEmitter<any>, item: Produto) {
    if (!this.arquivando) {
      (this.service as ProdutoService).changeInactive(item)
        .subscribe(() => {
          if (event) event.next(true);
        }, error => {
          if (event) event.next(false);
          this.dialogService
            .messageDialog()
            .title("Atenção")
            .message(error)
            .show();
        });
    }
  }

  duplicarProduto(item: Produto) {
    this.dialogService
      .confirmDialog()
      .message(`Duplicar o produto "${item.nome.trim()}"?`)
      .acceptButton("Duplicar")
      .show()
      .subscribe(confirm => {
        if (confirm) {
          this.edit(item.id, true);
        }
      });
  }

  arquivarProduto(item: Produto) {
    this.arquivando = true;
    this.loadingService.showTopBar();
    (this.service as ProdutoService).arquivar(item)
      .subscribe(() => {
        this.arquivando = false;
        this.loadingService.hideTopBar();
      }, error => {
        this.arquivando = false;
        this.loadingService.hideTopBar();
        this.dialogService
          .messageDialog()
          .title("Atenção")
          .message(error)
          .show();
      });
  }

  drop(event: CdkDragDrop<CategoriaProduto[]>, produtos: Produto[]) {
    moveItemInArray(produtos, event.previousIndex, event.currentIndex);
    produtos = produtos.map((produto, index) => {
      produto.sequence = index;
      return produto;
    });

    this.orderMudou = true;
    this.newOrder = produtos;
  }

  ativarReordenacao() {
    this.isReordering = true;
    this.searchControl.setValue(null);
  }

  cancelarOrdenacao() {
    if (this.orderMudou) {
      this.dialogService.confirmDialog()
        .message("Deseja realmente cancelar a ordenação?")
        .acceptButton("Sim")
        .cancelButton("Não")
        .show()
        .subscribe(accept => {
          if (accept) {
            this.isReordering = false;
            this.buscarProdutos();
            this.orderMudou = false;
            this.showSearch = false;
          }
        });
    } else {
      this.isReordering = false;
      this.showSearch = false;
    }
  }

  reordenar(produtos: Produto[]) {
    this.isReordering = false;
    this.orderMudou = false;
    this.showSearch = false;

    if (isNotNullOrUndefined(produtos)) {
      return (this.service as ProdutoService).reordenar(produtos)
        .catch(err => {
          this.dialogService
            .messageDialog()
            .message("Erro ao ordenar os produtos! " + err.message)
            .show();
        });
    }
  }

  ativarCampoPesquisa() {
    this.showSearch = !this.showSearch;
    this.searchControl.setValue(null);
  }

  ngOnDestroy(): void {
    if (this.produtosSubscription) {
      this.produtosSubscription.unsubscribe();
    }
    if (this.produtosArquivadosSubscription) {
      this.produtosArquivadosSubscription.unsubscribe();
    }
  }

  podeMudarRota(): boolean {
    if (this.isReordering) {
      return confirm(
        "A ordem dos itens não foram salvas! Deseja sair mesmo assim?"
      );
    }
    return true;
  }
}

class Group {
  categoria: CategoriaProduto;
  produtos: Produto[] = [];

  constructor(produto: Produto) {
    this.categoria = produto.categoria;
    this.produtos.push(produto);
  }
}
