import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {PageService} from "../../../services/page.service";
import {ComplementoProduto} from "../../../models/complemento-produto.model";
import {ComplementoProdutoService} from "../complemento-produto.service";
import { MatDialog } from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {IFormCanDeactivate} from "../../../guards/iform-canDeactivate";
import {Observable} from "rxjs";
import {map, mergeMap, startWith} from "rxjs/operators";
import {StringUtils} from "../../../utils/string-utils";

@Component({
  selector: "app-complemento-produto-form",
  templateUrl: "./complemento-produto-form.component.html",
  styleUrls: ["./complemento-produto-form.component.scss"],
})
export class ComplementoProdutoFormComponent extends PageService<ComplementoProduto> implements OnInit, IFormCanDeactivate {
  form: FormGroup;

  // Booleano que verifica o status da atualização de um formulário
  formMudou: boolean = null;

  grupos$: Observable<string[]>;

  constructor(public service: ComplementoProdutoService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              public formBuilder: FormBuilder) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/complementos-produto/");

    this.form = this.formBuilder.group({
      nome: ["", [Validators.required, Validators.minLength(3)]],
      preco: ["", [Validators.required]],
      descricao: [""],
      grupo: ["", [Validators.required]],
      codigoPDV: [""],
      inactive: [false],
    });

    this.grupos$ = this.service.getGrupos().pipe(
      mergeMap((grupos: string[]) => {
        return this.form.get("grupo").valueChanges.pipe(
          startWith(""),
          map((controlValue) => {
            if (controlValue) {
              return grupos.filter((grupo) =>
                StringUtils.normalize(grupo.toLowerCase()).includes(
                  StringUtils.normalize(controlValue.toLowerCase())
                )
              );
            } else {
              return grupos;
            }
          })
        );
      })
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.itemSubject.asObservable().subscribe((value) => {
      this.form.patchValue(value);
    });

    this.form.valueChanges.subscribe((value) => {
      Object.keys(value).forEach((key) => (this.item[key] = value[key]));
    });

    /**
     * Inicializa método que verifica se ocorreram mudanças no conteúdo do formulário,
     * para decidir o comportamento do método podeMudarRota (canDeactivate)
     */
    this.onChanges();
  }

  protected newItem(): ComplementoProduto {
    return new ComplementoProduto();
  }

  onChanges() {
    let mudanca = 0;
    this.form.valueChanges.subscribe(() => {
      if (mudanca === 0) {
        this.formMudou = false;
        mudanca++;
      } else {
        this.formMudou = true;
      }
    });
  }

  // Caso o formulário foi modificado
  podeMudarRota(): boolean {
    if (this.formMudou === true) {
      return confirm(
        "Os dados ainda não foram salvos. Deseja sair mesmo assim?"
      );
    }
    return true;
  }
}
