import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {Observable, of, Subscription} from "rxjs";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {LogStatus, Pedido} from "../../../models/pedido.model";
import {GerenciadorPedidosService} from "../service/gerenciador-pedidos.service";
import {StatusPedido} from "../../../models/status-pedido.enum";
import {DialogService} from "../../../services/dialog/dialog.service";
import {isNullOrUndefined} from "../../../utils/commons";
import {PrinterService} from "../../../services/printer.service";
import {PedidoItemService} from "../../../services/pedido-item.service";
import {PedidoItem} from "../../../models/pedido-item.model";
import {DeviceDetectorService} from "ngx-device-detector";
import {LoadingService} from "../../../services/loading/loading.service";
import {CozinhasService} from "../../cozinhas/cozinhas.service";
import {SettingsService} from "../../settings/settings.service";
import {finalize, map, mergeMap} from "rxjs/operators";
import {AngularFireFunctions} from "@angular/fire/functions";
import {PedidosService} from "../../../services/pedidos.service";
import {$query} from "../../../services/firebase/criteria/query";
import {Criterion} from "../../../services/firebase/criteria/criterion";
import {AuthService} from "../../../modules/login/auth.service";
import {RegraTotalizacao} from "../../../models/produto.model";
import {TipoPagamento} from "../../../models/forma-pagamento.model";
import { Role } from "../../../models/perfil-acesso.model";
import {StatusInvoice} from "../../../models/iugu/invoice.iugu.model";
import {MotivoRejeicaoDialogComponent} from "./motivo-rejeicao-dialog/motivo-rejeicao-dialog.component";
import {UsersService} from "../../users/users.service";
import {UserType} from "../../../models/appUser";

@Component({
  selector: "app-pedido-detalhes-dialog",
  templateUrl: "./pedido-detalhes-dialog.component.html",
  styleUrls: ["./pedido-detalhes-dialog.component.scss"]
})
export class PedidoDetalhesDialogComponent implements OnInit, OnDestroy {

  static showDialog(dialog: MatDialog, pedido: Pedido, buttons?: boolean, showCancelarButton?: boolean): Observable<any> {
    const config = {
      width: "1000px",
      data: {
        pedido: pedido,
        buttons: buttons,
        showCancelarButton: showCancelarButton
      }
    };
    return dialog.open(PedidoDetalhesDialogComponent, config).afterClosed();
  }

  // Objecto de pedido
  pedido: Pedido;
  buttons: boolean;
  showCancelarButton: boolean;
  isNullOrUndefined = isNullOrUndefined;
  Role = Role;

  pedidoItem: Observable<PedidoItem[]>;

  // Status do pedido para String
  readonly statusPedidoToString = StatusPedido.statusPedidoToString;

  // Define o style do status
  readonly styleByStatus = StatusPedido.styleByStatus;

  // Define o style do marcador de status
  readonly markerStyleByStatus = StatusPedido.markerStyleByStatus;

  hasImpressora: boolean;
  hasSomeImpressora: boolean;

  StatusPedido = StatusPedido;
  StatusInvoice = StatusInvoice;
  TipoPagamento = TipoPagamento;
  disableExtraButtons: boolean = false;

  pedidoSubscription: Subscription;

  regrasTotalizacao = RegraTotalizacao;

  rejectionInfo: {user, info};
  rejectionInfoSubscription = new Subscription();

  constructor(public dialogRef: MatDialogRef<PedidoDetalhesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: DialogService,
              public printer: PrinterService,
              public balcao: GerenciadorPedidosService,
              public deviceDetector: DeviceDetectorService,
              public pedidoService: PedidosService,
              public itemService: PedidoItemService,
              public loadingService: LoadingService,
              public cozinhasService: CozinhasService,
              public settingsService: SettingsService,
              public auth: AuthService,
              public fns: AngularFireFunctions,
              public matDialog: MatDialog,
              public userService: UsersService) {
    this.pedido = data["pedido"];
    this.buttons = data["buttons"];
    this.showCancelarButton = data["showCancelarButton"];
    this.pedidoItem = this.itemService.getItensByPedido(this.pedido);

    this.pedidoSubscription = this.pedidoService.col$($query(new Criterion("id", "==", this.pedido.id)), false)
      .pipe(map(pedidos => pedidos[0]))
      .subscribe(_pedido => {
        this.pedido = _pedido;
      });

    this.settingsService.hasImpressoraPadrao().subscribe(_hasImpressora => {
      this.hasImpressora = _hasImpressora;
    });

    this.cozinhasService.hasSomePrinter().subscribe(_hasSomeImpressora => {
      this.hasSomeImpressora = _hasSomeImpressora;
    });

    this.getRejectionInfo();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.pedidoSubscription) {
      this.pedidoSubscription.unsubscribe();
    }
    if (this.rejectionInfoSubscription) {
      this.rejectionInfoSubscription.unsubscribe();
    }
  }

  isAdministrador(): Observable<boolean> {
    return this.auth.currentUser.pipe(
      map(user => user.isAdministrador())
    );
  }

  getEndereco() {
    if (this.pedido.buscarNoBalcao) {
      return "O cliente irá retirar o pedido no balcão!";
    } else {
      let endereco = this.pedido.enderecoEntrega.logradouro;
      if (!this.isNullOrUndefined(this.pedido.enderecoEntrega.numero)) {
        endereco += ", " + this.pedido.enderecoEntrega.numero;
      }
      if (!isNullOrUndefined(this.pedido.enderecoEntrega.bairro)) {
        if (!isNullOrUndefined(this.pedido.enderecoEntrega.bairro.nome)) {
          endereco += ", " + this.pedido.enderecoEntrega.bairro.nome;
        }
      }
      return endereco;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  rejectOrCancelPedido(status: string) {
    this.disableExtraButtons = true;
    const bairroName = !this.pedido.buscarNoBalcao ? this.pedido.enderecoEntrega.bairro.nome : null;
    MotivoRejeicaoDialogComponent.openDialog(this.matDialog, status, this.pedido.id, bairroName)
      .subscribe(value => {
        this.disableExtraButtons = false;
        if (value) {
          this.dialogRef.close(true);
        }
      });
  }

  imprimirEspelho() {
    this.printer.imprimirEspelho(this.pedido)
      .subscribe(() => {
        console.log("print sucess!");
      }, error => {
        console.log("print error!", error);
        this.dialog.messageDialog()
          .message(error)
          .show();
      });
  }

  imprimirParaProducao() {
    this.printer.imprimirParaProducao(this.pedido)
      .subscribe(() => {
      }, error => {
        console.log("print error!", error);
        this.dialog.messageDialog()
          .message(error)
          .show();
      });
  }

  revert() {
    this.dialog.confirmDialog()
      .title("Reverter status?")
      .message(`Após esse procedimento o status de ${this.pedido.isRejeitado() ? "REJEITADO" : "CANCELADO"} será desfeito.`)
      .acceptButton("Reverter")
      .cancelButton("Cancelar")
      .show()
      .pipe(
        mergeMap(accept => {
          if (accept) {
            this.loadingService.showTopBar();
            this.disableExtraButtons = true;
            return this.fns.httpsCallable("call-pedidos-revertPedido")({pedidoId: this.pedido.id}).pipe(
              finalize(() => {
                this.loadingService.hideTopBar();
                this.disableExtraButtons = false;
                this.dialogRef.close(true);
              })
            );
          } else {
            return of();
          }
        })
      ).subscribe(() => {
    }, error => {
      this.dialog.messageDialog().title("Atenção").message(error).show();
    });
  }

  // Busca as informações exibídas no marcador de status, quando um pedido estiver REJEITADO ou CANCELADO
  getRejectionInfo() {
    if (this.pedido.isCanceladoOrRejeitado() && this.pedido.logs.length > 0) {
      const log: LogStatus = this.pedido.logs.find(value => value.status === StatusPedido[StatusPedido.REJEITADO] || value.status === StatusPedido[StatusPedido.CANCELADO]);

      if (isNullOrUndefined(log)) {
        this.rejectionInfo = {
          user: null,
          info: null
        };
      }

      if (isNullOrUndefined(log.userId)) {
        this.rejectionInfo = {
          user: null,
          info: "automaticamente"
        };
      } else {
        this.rejectionInfoSubscription = this.userService.getById(log.userId).pipe(
          map(user => {
            if (user.userType === UserType[UserType.Lojista]) {
              this.rejectionInfo = {
                user: user.name,
                info: "pela empresa"
              };
            } else {
              if (this.auth.user.isLojista()) {
                this.rejectionInfo = {
                  user: null,
                  info: "pelo aplicativo"
                };
              } else {
                this.rejectionInfo = {
                  user: user.name,
                  info: "pelo aplicativo"
                };
              }
            }
          })
        ).subscribe();
      }
    }
  }
}
