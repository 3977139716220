import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {PageService} from "../../../services/page.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ProdutoService} from "../produto.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";
import {forkJoin, from, Observable, of, Subscription} from "rxjs";
import {map, mergeMap, take} from "rxjs/operators";
import {CategoriaProdutoService} from "../../categoria-produto/categoria-produto.service";
import {Complemento, Produto} from "../../../models/produto.model";
import {CategoriaProduto} from "../../../models/categoria.model";
import {ImageUploaderService} from "../../../services/image-uploader/image-uploader.service";
import {ComplementoService} from "../complemento.service";
import {Criterion} from "../../../services/firebase/criteria/criterion";
import {$query} from "../../../services/firebase/criteria/query";
import {OrderByAsc} from "../../../services/firebase/criteria/order-by-asc";
import {Cozinha} from "../../../models/cozinha.model";
import {CozinhasService} from "../../cozinhas/cozinhas.service";
import {ComplementoItemService} from "../complemento-item.service";
import {IFormCanDeactivate} from "../../../guards/iform-canDeactivate";
import {isNullOrUndefined} from "util";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import * as firebase from "firebase";
import {CategoriaProdutoDialogComponent} from "../../categoria-produto/categoria-produto-dialog/categoria-produto-dialog.component";
import {CozinhasDialogComponent} from "../../cozinhas/cozinhas-dialog/cozinhas-dialog.component";
import {Role} from "../../../models/perfil-acesso.model";
import {isEmpty, isNotNullOrUndefined} from "../../../utils/commons";
import WriteBatch = firebase.firestore.WriteBatch;
import {AuthService} from "../../../modules/login/auth.service";

@Component({
  selector: "app-produto-form",
  templateUrl: "./produto-form.component.html",
  styleUrls: ["./produto-form.component.scss"],
})
export class ProdutoFormComponent extends PageService<Produto> implements OnInit, OnDestroy, IFormCanDeactivate {
  form: FormGroup;

  // Booleano que verifica o status da atualização de um formulário
  formMudou: boolean = null;

  // Categorias da empresa
  categorias: Observable<CategoriaProduto[]>;

  // Cozinhas
  cozinhas: Observable<Cozinha[]>;

  // Indica que está carregando os complementos
  loadingItens: boolean = false;

  // Caso ocorra algum erro ao carregar os complementos
  errorOnLoadComplementos: string;

  // Subscription de complementos
  complementosSubscription: Subscription;

  novo: boolean = false;

  // Output do produto selecionado para edição
  complementoSelected: Complemento;

  // Variável responsável por receber o indíce para navegação entre tabs
  selectedIndex: number = 0;

  Role = Role;

  isCopyRecord: boolean;

  constructor(public service: ProdutoService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              private categoriaService: CategoriaProdutoService,
              private cozinhaService: CozinhasService,
              private formBuilder: FormBuilder,
              private imageUploader: ImageUploaderService,
              private complementoService: ComplementoService,
              private complementoItemService: ComplementoItemService,
              public auth: AuthService) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/produtos/");

    this.route.queryParams.pipe(
      map(
        (params: any) =>
          params &&
          Object.keys(params).some((key) => key === "copy" && params[key])
      )
    ).subscribe(value => {
      this.isCopyRecord = value;
    }).unsubscribe();

    this.form = this.formBuilder.group({
      bannerUrl: [null, []],
      nome: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      categoria: ["", Validators.required],
      preco: ["", [Validators.required]],
      descricao: [""],
      cozinha: ["", Validators.required],
      codigoPDV: [""],
      inactive: [false],
    });
    this.categorias = this.categoriaService.col$();
    this.cozinhas = this.cozinhaService.col$();

    /**
     * Inicializa método que verifica se ocorreram mudanças no conteúdo do formulário,
     * para decidir o comportamento do método podeMudarRota (canDeactivate)
     */
    this.onChanges();

  }

  protected newItem(): Produto {
    return new Produto();
  }

  afterLoadItem() {
    this.loadingItens = true;
    this.complementosSubscription = this.complementoService
      .col$(
        $query(
          new Criterion("produto", "==", this.item.ref),
          new OrderByAsc("sequence")
        )
      )
      .pipe(
        mergeMap((complementos: Complemento[]) => {
          if (complementos && complementos.length > 0) {
            // Transformar a lista de complementos em uma lista de observable para os itens dos complementos
            const compItensObservable = complementos.map((comp) =>
              this.complementoItemService.getItens(this.item, comp)
            );
            // Carregar os itens dos complementos
            return forkJoin(compItensObservable).pipe(
              map((resultItens) => {
                resultItens.forEach(
                  (itens, index: number) => (complementos[index].itens = itens)
                );
                return complementos;
              })
            );
          }
          return of(complementos);
        })
      )
      .subscribe(
        (complementos) => {
          this.item.complementos = complementos;
          this.loadingItens = false;
        },
        (error) => {
          this.errorOnLoadComplementos = error;
          this.loadingItens = false;
        }
      );
  }

  categoriaCompareWith(c1: CategoriaProduto, c2: CategoriaProduto): boolean {
    return (
      c1 === c2 || (c1 !== undefined && c2 !== undefined && c1.id === c2.id)
    );
  }

  cozinhaCompareWith(c1: Cozinha, c2: Cozinha): boolean {
    return (
      c1 === c2 || (c1 !== undefined && c2 !== undefined && c1.id === c2.id)
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.itemSubject.asObservable().subscribe((value) => {
      this.form.patchValue(value);
      this.form.get("nome").markAsTouched({onlySelf: true});
    });

    this.form.valueChanges.subscribe((value) => {
      Object.keys(value).forEach((key) => (this.item[key] = value[key]));
    });

  }

  removeAdicional(adicional: Complemento) {
    this.dialogService
      .confirmDeleteDialog()
      .message("Deseja realmente excluir este complemento?")
      .show()
      .subscribe((accept) => {
        if (accept) {
          // Move o complemento dos Complementos para Complementos removed
          this.item.removeComplemento(adicional);
        }
      });
  }

  saveOrUpdate(item: Produto = this.item): Observable<void> {
    this.formMudou = false;
    this.loading(true);
    // Obter os parâmetros para identificar se o produto recebido é uma cópia de outro
    const isCopyRecord = this.route.queryParams.pipe(
      map(
        (params) =>
          params &&
          Object.keys(params).some((key) => key === "copy" && params[key])
      )
    );

    // Salvar as sequências dos complementos
    const indexOf = (complemento: Complemento): number =>
      this.item.complementos.indexOf(complemento);
    this.item.complementos.forEach((comp) => (comp.sequence = indexOf(comp)));

    // Se não tiver id, gera um novo pra fazer upload das imagens para o storage
    if (isNullOrUndefined(this.item.id)) {
      this.item.id = this.service.createId();
    }

    // Fazer upload da imagem do produto
    const uploadImageObservable = (produto: Produto) => {
      return from(
        this.imageUploader
          .uploadString(produto.bannerUrl, "/Pictures/Produtos/" + produto.id)
          .then((url) => {
            produto.bannerUrl = url;
            return produto;
          })
      );
    };

    // Se estiver copiando o produto, gera novos ids para os complementos e para os itens do complemento
    const prepareProdutoForCopy = (isCopy: boolean) => {
      if (isCopy) {
        // Remover o id e a referência do produto
        item.id = this.service.createId();
        item.ref = null;
        item.sequence = null;

        // Gerar um novo produto
        const produto = new Produto(item);

        // Remover o id dos complementos
        produto.complementos.forEach((complemento: Complemento) => {
          complemento.id = null;
          // Remover o id dos itens do complemento
          complemento.itens.forEach((cItem) => (cItem.id = null));
        });

        return of(produto);
      } else {
        // Se não tiver id, gera um novo pra fazer upload das imagens para o storage
        if (isNullOrUndefined(item.id)) {
          item.id = this.service.createId();
        }

        return of(item);
      }
    };

    // Reordena os produtos caso um produto novo for cadastrado
    const updateSequence = (produto: Produto) => {
      // Verifica se o produto que está sendo cadastrado possui o campo sequence
      if (isNotNullOrUndefined(produto.sequence)) {
        // Se possuir, prossegue com a gravação e não reordena

        return of(produto);
      }

      // Caso não possua atribui 0 ao sequence para o produto ser o primeiro da lista
      produto.sequence = 0;

      // Busca os produtos da categoria
      return this.service.getAllProdutosByCategoria(produto.categoria.id).pipe(
        take(1),
        mergeMap((produtos: Produto[]) => {
          // Verifica se a lista de produtos é vazia
          if (isEmpty(produtos)) {
            // Se for vazia retorna o produto
            return of(produto);
          }

          const batch: WriteBatch = this.service.db.firestore.batch();

          produtos.forEach((_produto) => {
            batch.update(_produto.ref, {sequence: _produto.sequence + 1});
          });

          return from(batch.commit().then(() => {
          })).pipe(
            mergeMap(() => of(produto))
          );
        })
      );
    };


    const save = isCopyRecord.pipe(
      mergeMap(prepareProdutoForCopy),
      mergeMap(uploadImageObservable),
      mergeMap(updateSequence),
      mergeMap((produto) => {
        produto.nomeNormalized = new Produto().makeNormalizedNomeProduto(produto.nome);
        if (isNullOrUndefined(produto.arquivado)) produto.arquivado = false;
        return super.saveOrUpdate(produto);
      })
    );
    save.subscribe(() => {
      this.formMudou = false;
    });
    return save;
  }

  ngOnDestroy() {
    if (this.complementosSubscription) {
      this.complementosSubscription.unsubscribe();
    }
  }

  // Verifica se ocorreram mudanças no formulário,alterando o valor da variável formMudou para true, ou mantendo em null por padrão.
  onChanges() {
    let mudanca = 0;
    this.form.valueChanges.subscribe(() => {
      if (mudanca === 0) {
        this.formMudou = false;
        mudanca++;
      } else {
        this.formMudou = true;
      }
    });
  }

  // Caso o formulário foi modificado
  podeMudarRota(): boolean {
    if (this.formMudou === true) {
      return confirm(
        "Os dados ainda não foram salvos. Deseja sair mesmo assim?"
      );
    }
    return true;
  }

  // Reseta o input do complemento e faz navegação de volta para o formulário de complementos.
  editouComplemento() {
    this.complementoSelected = new Complemento();
    this.selectedIndex = 1;
  }

  tabChanged(tabChangeEvent) {
    this.selectedIndex = tabChangeEvent.index;
  }

  newComplemento(novo: boolean, complemento?: Complemento) {
    if (!this.flagMenuTrigger) {
      this.complementoSelected = novo
        ? new Complemento()
        : new Complemento(complemento);
      this.selectedIndex = 2;
      // Para não perguntar se o usuário deseja sair ao salvar o formulário eu forço a variável em falso.
      this.formMudou = false;
    } else {
      this.flagMenuTrigger = !this.flagMenuTrigger;
    }
  }

  drop(event: CdkDragDrop<Complemento[]>, complementos: Complemento[]) {
    moveItemInArray(complementos, event.previousIndex, event.currentIndex);
    this.item.complementos = complementos.map((complemento, index) => {
      complemento.sequence = index;
      return complemento;
    });
  }

  ativarOuInativarComplemento(index) {
    this.item.complementos[index].inactive = !this.item.complementos[index]
      .inactive;
  }

  addCategoria() {
    this.dialog
      .open(CategoriaProdutoDialogComponent, {width: "400px"})
      .afterClosed()
      .subscribe((val) => {
        this.form.controls.categoria.patchValue(val);
      });
  }

  addCozinha() {
    this.dialog
      .open(CozinhasDialogComponent, {width: "600px"})
      .afterClosed()
      .subscribe((val) => {
        this.form.controls.cozinha.patchValue(val);
      });
  }

  deleteImage() {
    this.form.get("bannerUrl").patchValue(null);
  }
}
