import * as firebase from "firebase";
import { Empresa } from "./empresa";

export enum ConsultorComissaoStatus {
  ABERTO = "ABERTO",
  FECHADO = "FECHADO",
  PAGO = "PAGO",
}

export class ConsultorComissao {
  public id: string;
  public dataInicial: firebase.firestore.Timestamp;
  public dataFinal: firebase.firestore.Timestamp;
  public dataLiberacao: firebase.firestore.Timestamp | null;
  public dataPagamento: firebase.firestore.Timestamp | null;
  public empresa: Empresa;
  public companyCommissionId: string;
  public status: string;
  public consultorId: string;
  public valor: number;
  public baseDeCalculo: number;
  public porcentagemComissaoConsultor: number;

  constructor(comissaoConsultor: any = {}) {
    this.id = comissaoConsultor.id;
    this.dataInicial = comissaoConsultor.dataInicial;
    this.dataFinal = comissaoConsultor.dataFinal;
    this.dataLiberacao = comissaoConsultor.dataLiberacao;
    this.dataPagamento = comissaoConsultor.dataPagamento;
    this.empresa = comissaoConsultor.empresa;
    this.companyCommissionId = comissaoConsultor.companyCommissionId;
    this.status = comissaoConsultor.status;
    this.consultorId = comissaoConsultor.consultorId;
    this.valor = comissaoConsultor.valor;
    this.baseDeCalculo = comissaoConsultor.baseDeCalculo;
    this.porcentagemComissaoConsultor =
      comissaoConsultor.porcentagemComissaoConsultor;
  }
}
