import * as firebase from "firebase";
import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {AuthService} from "../../modules/login/auth.service";
import {combineLatest, from, merge, Observable, of} from "rxjs";
import {Repository} from "../../repository/Repository";
import {Bloqueio, Empresa, StatusEmpresa, StatusLogActivity, TempoEntrega} from "../../models/empresa";
import {OrderBy} from "../../services/firebase/criteria/order-by";
import {OrderByAsc} from "../../services/firebase/criteria/order-by-asc";
import {AppUser} from "../../models/appUser";
import {$query, PoMQuery} from "../../services/firebase/criteria/query";
import {catchError, map, mergeMap, tap} from "rxjs/operators";
import {Criterion} from "../../services/firebase/criteria/criterion";
import {Cidade} from "../../models/cidade.model";
import {Limit} from "../../services/firebase/criteria/limit";
import {AngularFireFunctions} from "@angular/fire/functions";
import {isEmpty} from "../../utils/commons";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {StringUtils} from "../../utils/string-utils";
import {DialogService} from "../../services/dialog/dialog.service";
import DocumentReference = firebase.firestore.DocumentReference;
import Timestamp = firebase.firestore.Timestamp;

interface ResponseApiTimezone {
  dstOffset: number;
  rawOffset: number;
  status: string;
  timeZoneId: string;
  timeZoneName: string;
}

interface Filters {
  cidade: Cidade;
  status: string;
  searchValue: string;
  pagtoOnline: boolean;
}

@Injectable({
  providedIn: "root"
})
export class EmpresaService extends Repository<Empresa> {

  private _filters: Filters = {cidade: undefined, pagtoOnline: false, searchValue: "", status: ""};

  constructor(public db: AngularFirestore,
              private auth: AuthService,
              private fns: AngularFireFunctions,
              private httpClient: HttpClient,
              private dialogService: DialogService) {
    super(db, "companies", true);
  }

  get filters(): Filters {
    return this._filters;
  }

  remove(id: string): Observable<void> {
    return this.fns.httpsCallable("call-companies-remove")({id: id});
  }


  getEmpresasByUser(user: AppUser): Observable<Empresa[]> {
    if (!user.isLojista()) {
      // Se for administrador, retorna todas as empresas
      return this.col$();
    } else {
      // Se não for, retorna apenas as empresas que o usuário está cadastrado

      // Remove os ids duplicados
      // Criar uma lista de empresas e converte para observables
      // E, executa todos os observables
      return this.getEmpresasLojista(user);
    }
  }

  getEmpresasLojista(user: AppUser): Observable<Empresa[]> {
    return combineLatest(user.empresas.filter((v, i) => user.empresas.indexOf(v) === i).map(empresaId => this.getEmpresa(empresaId)));
  }

  getEmpresa(empresaId: string): Observable<Empresa> {
    return this.getDoc(empresaId).snapshotChanges().pipe(
      map((doc) => this.deserialize(doc.payload.data()))
    );
  }

  getAllStatusLogActivityByEmpresa(empresaId: string): Observable<StatusLogActivity[]> {
    return super.getDoc(empresaId).collection("activities")
      .snapshotChanges()
      .pipe(
        map(docs => {
          return docs.map(a => {
            const data = a.payload.doc.data();
            data["id"] = a.payload.doc.id;
            data["ref"] = a.payload.doc.ref;
            return this.deserializeStatusLogActivity ? this.deserializeStatusLogActivity(data) : data;
          }) as StatusLogActivity[];
        }),
        map((logs: StatusLogActivity[]) => {
          // Reordenar pela data
          return logs.sort((a, b) => b.data.toMillis() - a.data.toMillis());
        })
      );
  }

  deserializeStatusLogActivity(value: any): StatusLogActivity {
    return new StatusLogActivity(value);
  }

  col$(queryFn: PoMQuery = new PoMQuery()): Observable<Empresa[]> {
    return this.auth.currentUser.pipe(
      mergeMap(user => {
        if (user.isConsultorOrAssistente()) {
          return super.col$(queryFn.add(new Criterion("consultor.id", "==", user.isConsultor() ? user.id : user.consultorId)));
        } else {
          return super.col$(queryFn);
        }
      })
    );
  }

  saveOrUpdate(item: Empresa): Observable<void> {
    return this.auth.currentUser.pipe(
      mergeMap(user => {
        item.lastUpdate = {
          data: Timestamp.now(),
          userId: user.id,
          userName: user.name
        };
        return super.saveOrUpdate(item);
      })
    );
  }

  deserialize(value: any): Empresa {
    return new Empresa(value);
  }

  protected limit(): Limit {
    return new Limit(25);
  }

  protected orderBy(): OrderBy {
    return new OrderByAsc("nomeFantasiaNormalized");
  }

  changeStatus(empresa: Empresa, status: string, motivo?: string): Observable<void> {
    return this.auth.currentUser.pipe(
      mergeMap(user => {
        return from(this.getDoc(empresa.id).update({
          status: status,
          motivoStatusChange: motivo ? motivo : null,
          lastUpdate: {
            data: Timestamp.now(),
            userId: user.id,
            userName: user.name
          }
        })).pipe(
          tap(() => empresa.status = status)
        );
      })
    );
  }

  marcarPedeQueChega(empresaRef: DocumentReference, isPedeQueChega: boolean): Observable<any> {
    return from(empresaRef.update({pedeQueChega: isPedeQueChega}));
  }

  marcarPronto(empresa: Empresa): Observable<void> {
    return this.changeStatus(empresa, StatusEmpresa.PRONTO);
  }

  marcarPendente(empresa: Empresa): Observable<void> {
    return this.changeStatus(empresa, StatusEmpresa.PENDENTE);
  }

  // Bloquear / Desbloquear a empresa
  bloquear(empresa: Empresa, bloqueio: Bloqueio) {
    return this.auth.currentUser.pipe(
      mergeMap(user => {
        return from(this.getDoc(empresa.id).update({
          bloqueio: bloqueio,
          lastUpdate: {
            data: Timestamp.now(),
            userId: user.id,
            userName: user.name
          }
        })).pipe(
          tap(() => {
            empresa.bloqueio = bloqueio;
          })
        );
      })
    );
  }

  horarioFuncionamentoIsEmpty(empresa: Empresa): Observable<boolean> {
    return of(empresa).pipe(
      mergeMap(_empresa => {
        return of(Object.keys(_empresa.horario).every(dia => !_empresa.horario[dia].aberto));
      })
    );
  }

  telefoneIsEmpty(empresa: Empresa): Observable<boolean> {
    return of(empresa).pipe(
      mergeMap(_empresa => {
        return of(_empresa.telefones.every(telefone => !telefone.visivelNoSmartphone));
      })
    );
  }

  alterarTempoAtendimento(empresa: Empresa, tempoEntrega: TempoEntrega): Observable<void> {
    return from(this.getDoc(empresa.id).update({tempoEntrega: Object.assign({}, tempoEntrega)}));
  }

  getEmpresasByIuguCustomerId(iuguCustomerId: string): Observable<Empresa[]> {
    return this.col$($query(new Criterion("iuguCustomerId", "==", iuguCustomerId)));
  }

  public getTimezoneByCoords(latitude: string, longitude: string): Observable<string> {
    return this.httpClient
      .get<ResponseApiTimezone>(`https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=1331766000&key=${environment.google_maps_api_key}`)
      .map(response => response.timeZoneId);

  }

  public getCompaniesByNomeFantasia(companyName: string, onlyPedeQueChega: boolean, onlyLiberadoEPronto?: boolean): Observable<Empresa[]> {
    // Se o usuário for logista retorna somente os dados da empresa dele
    if (this.auth.user.isLojista()) {
      return this.getEmpresasLojista(this.auth.user);
    }

    // Preciso de duas queries, para buscar status liberado e pronto, pois o firebase não permite esse tipo de busca na mesma query
    const primaryQuery = $query();
    const secondaryQuery = $query();

    // Se o usuario for consultor eu adiciono um criterion para buscar somente as empresas dele.
    if (this.auth.user.isConsultor()) {
      primaryQuery.add(new Criterion("consultor.id", "==", this.auth.user.id));
    }

    // Verificando se foi passado o nome da empresa
    if (!isEmpty(companyName)) {
      const nomeEmpresa = StringUtils.normalize(companyName).toLowerCase();
      primaryQuery.add(new Criterion("nomeFantasiaNormalized", ">=", nomeEmpresa));
      primaryQuery.add(new Criterion("nomeFantasiaNormalized", "<=", nomeEmpresa + "\uf8ff"));
      secondaryQuery.add(new Criterion("nomeFantasiaNormalized", ">=", nomeEmpresa));
      secondaryQuery.add(new Criterion("nomeFantasiaNormalized", "<=", nomeEmpresa + "\uf8ff"));
    }

    primaryQuery.add(this.limit());
    secondaryQuery.add(this.limit());

    // Se for somente Pede que Chega
    if (onlyPedeQueChega) {
      primaryQuery.add(new Criterion("localizacao.endereco.cidade", "==", "Rio Verde"));
      primaryQuery.add(new Criterion("localizacao.endereco.estado", "==", "GO"));
      secondaryQuery.add(new Criterion("localizacao.endereco.cidade", "==", "Rio Verde"));
      secondaryQuery.add(new Criterion("localizacao.endereco.estado", "==", "GO"));
    }

    // Se eu quiser buscar somente as empresas com status liberado e pronto, devo usar a query secundária
    if (onlyLiberadoEPronto) {
      primaryQuery.add(new Criterion("status", "==", StatusEmpresa[StatusEmpresa.PRONTO]));
      secondaryQuery.add(new Criterion("status", "==", StatusEmpresa[StatusEmpresa.LIBERADO]));
      const pronto = this.col$(primaryQuery);
      const liberado = this.col$(secondaryQuery);
      return merge(pronto, liberado);
    }

    return this.col$(primaryQuery);
  }

  search(cidadeId: string, status: string, searchValue: string, pagtoOnline: boolean): Observable<Empresa[]> {
    return this.fns.httpsCallable("call-companies-search")(
      {
        cidadeId: cidadeId,
        status: status,
        searchValue: searchValue,
        pagtoOnline: pagtoOnline
      }
    ).pipe(
      catchError(err => {
        this.dialogService
          .messageDialog()
          .message(err)
          .show();
        return of([]);
      })
    );
  }

}


