import {Component, Input, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Avaliacao} from "../../../models/avaliacao.model";
import {isNotNullOrUndefined} from "../../../utils/commons";
import {AvaliacaoService} from "../avaliacao.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";

@Component({
  selector: "app-resposta",
  templateUrl: "./resposta.component.html",
  styleUrls: ["./resposta.component.scss"]
})
export class RespostaComponent implements OnInit {
  form: FormGroup;
  disableButton: boolean = false;

  @Input()
  avaliacao: Avaliacao;

  @Input()
  canReply: boolean;

  // Exibe o campo para digitar uma resposta
  response: boolean = false;

  // Editar a resposta
  editResponse: boolean = false;

  constructor(public service: AvaliacaoService,
              public fb: FormBuilder,
              public dialog: DialogService,
              public loadingService: LoadingService) {
    this.form = fb.group({
      resposta: ["", [Validators.required, Validators.minLength(5), Validators.maxLength(500)]]
    });
  }

  ngOnInit() {
    this.form.patchValue(this.avaliacao.resposta);
  }

  responder() {
    const avaliacaoId = this.avaliacao.id;
    this.loadingService.showTopBar();
    this.disableButton = true;

    // Chamar Function para salvar a resposta
    this.service.salvarResposta(this.form.get("resposta").value, avaliacaoId)
      .subscribe(res => {
        // Atualizar resposta da avaliação recebida
        this.avaliacao.resposta = res;

        // Fechar campo de resposta
        this.loadingService.hideTopBar();
        this.disableButton = false;
        this.form.get("resposta").patchValue("");
        this.setResponse(false);
      }, err => {
        this.loadingService.hideTopBar();
        this.disableButton = false;
        this.form.get("resposta").patchValue("");
        this.setResponse(false);
        this.dialog
          .messageDialog()
          .message(err)
          .show();
      });
  }

  setResponse(res: boolean) {
    this.response = res;
    this.editResponse = res;
  }

  editar() {
    this.form.get("resposta").patchValue(isNotNullOrUndefined(this.avaliacao.resposta) ? this.avaliacao.resposta.resposta : "");
    this.setResponse(true);
    this.editResponse = true;
  }

  showResponse(): boolean {
    return (
      isNotNullOrUndefined(this.avaliacao) &&
      isNotNullOrUndefined(this.avaliacao.resposta) &&
      isNotNullOrUndefined(this.avaliacao.resposta.data) &&
      isNotNullOrUndefined(this.avaliacao.resposta.resposta)
    );
  }
}
