import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {InvoiceIugu, StatusInvoice} from "../../../models/iugu/invoice.iugu.model";
import {GerenciadorMessage} from "../../../models/gerenciador-message.model";
import {isEmpty, isNullOrUndefined} from "../../../utils/commons";
import {InvoiceIuguService} from "../../../services/iugu/invoice-iugu.service";
import {LoadingService} from "../../../services/loading/loading.service";
import { MatDialogConfig } from "@angular/material/dialog";

@Component({
  selector: "app-empresa-inadimplente-dialog",
  templateUrl: "./empresa-inadimplente-dialog.component.html",
  styleUrls: ["./empresa-inadimplente-dialog.component.scss"]
})
export class EmpresaInadimplenteDialogComponent implements OnInit {

  static openDialog(dialog: MatDialog, gerenciadorMessage: GerenciadorMessage): Observable<any> {
    const config = <MatDialogConfig>{
      width: isEmpty(gerenciadorMessage.pendingInvoices) ? "400px" : "800px",
      data: gerenciadorMessage
    };

    return dialog.open(EmpresaInadimplenteDialogComponent, config).afterClosed();
  }

  isNullOrUndefined = isNullOrUndefined;

  disableButton: boolean = false;

  constructor(public dialogRef: MatDialogRef<EmpresaInadimplenteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: GerenciadorMessage,
              public invoiceIuguService: InvoiceIuguService,
              public loadingService: LoadingService) {
  }

  ngOnInit() {
  }

  getStatusInvoiceColor(invoice: InvoiceIugu): { color: string, weight?: string } {
    if (invoice.isOverdue) {
      return {color: "red", weight: "bold"};
    } else {
      if (invoice.status === StatusInvoice[StatusInvoice.pending]) {
        return {color: "#fbba13"};
      } else if (invoice.status === StatusInvoice[StatusInvoice.expired]) {
        return {color: "#9e9e9e"};
      }
    }
  }

  pagarFatura(invoice) {
    this.loadingService.showTopBar();
    this.disableButton = true;
    this.invoiceIuguService.checkInvoiceAndGenerate(invoice.id).subscribe(response => {
      this.loadingService.hideTopBar();
      this.disableButton = false;
      if (response && response.data) {
        this.openUrl(response.data.iuguInvoiceUrl);
      }
      this.dialogRef.close();
    });
  }

  openUrl(url: string) {
    window.open(url);
  }
}
