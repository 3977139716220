import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Role} from "../../../../../models/perfil-acesso.model";

@Component({
  selector: "app-screen-frame-lists",
  templateUrl: "./screen-frame-lists.component.html",
  styleUrls: ["./screen-frame-lists.component.scss"],
})
export class ScreenFrameListsComponent implements OnInit {
  @Output()
  onCreateClick = new EventEmitter();

  @Input()
  fabTooltip = "";

  @Input()
  bottom;

  @Input()
  hideFab = false;

  Roles = Role;
  role;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.role = this.route.snapshot.data.role.create;
  }

  createNewRecord() {
    this.onCreateClick.emit();
  }
}
