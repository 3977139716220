import {Injectable} from "@angular/core";
import {Repository} from "../../repository/Repository";
import {PedeQueChega} from "../../models/pedequechega.model";
import {AngularFirestore} from "@angular/fire/firestore";
import {Observable} from "rxjs";
import {OrderBy} from "../../services/firebase/criteria/order-by";
import {OrderByDesc} from "../../services/firebase/criteria/order-by-desc";
import {PoMQuery} from "../../services/firebase/criteria/query";
import {map, take} from "rxjs/operators";
import {Entregador} from "../../models/entregador.model";
import {AngularFireFunctions} from "@angular/fire/functions";
import {Empresa} from "../../models/empresa";

@Injectable()
export class EntregasService extends Repository<PedeQueChega> {

  private filters = {};

  constructor(public db: AngularFirestore, public fns: AngularFireFunctions) {
    super(db, "pedeQueChega", true);
  }

  deserialize(value: any): PedeQueChega {
    return new PedeQueChega(value);
  }

  orderBy(): OrderBy {
    return new OrderByDesc("data");
  }

  setFilters(exibicao: string, empresa: Empresa, dataInicial: Date, dataFinal: Date, entregador: Entregador, tipo: string) {
    this.filters = {
      exibicao: exibicao,
      empresa: empresa,
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      entregador: entregador,
      tipo: tipo
    };
  }

  getFilters(): any {
    return this.filters;
  }

  col$(queryFn: PoMQuery = new PoMQuery()): Observable<PedeQueChega[]> {
    return super.col$(queryFn)
      .pipe(
        take(1),
        // Adicionar a referência aos pedidos
        map((entregas: PedeQueChega[]) => {
          return entregas.map(entrega => {
            entrega.ref = this.getDoc(entrega.id).ref;
            return entrega;
          });
        })
      );
  }

  updateEntregasToPaid(entregas: PedeQueChega[]): Observable<any> {
    const entregasObj = entregas.map(ent => {
      return {
        id: ent.id,
        status: ent.status,
      };
    });
    return this.fns.httpsCallable("call-pedeQueChega-updateEntregasToPaid")({entregas: entregasObj});
  }
}
