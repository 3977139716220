import {isNullOrUndefined} from "util";

export class Telefone {

  public tipo: string;
  public contato: string;
  public numero: string;
  public visivelNoSmartphone: boolean;
  public responsavel: boolean;
  public visivelNoTicket: boolean;

  constructor(telefone: any = {}) {
    if (!isNullOrUndefined(telefone)) {
      this.tipo = telefone.tipo;
      this.contato = telefone.contato ? telefone.contato : "";
      this.numero = telefone.numero;
      this.visivelNoSmartphone = telefone.visivelNoSmartphone ? telefone.visivelNoSmartphone : false;
      this.responsavel = telefone.responsavel ? telefone.responsavel : false;
      this.visivelNoTicket = telefone.visivelNoTicket ? telefone.visivelNoTicket : false;
    }
  }

}
