import {Component, OnInit} from "@angular/core";
import {OneSignalService} from "../../../services/onesignal.service";
import {Observable, of} from "rxjs";
import {mergeMap} from "rxjs/operators";
import * as moment from "moment";
import {SnackService} from "../../../services/snack/snack.service";
import {isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";
import {Router} from "@angular/router";
import {LoadingService} from "../../../services/loading/loading.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {PerfilService} from "../../perfil-acesso/perfil.service";
import {Role} from "../../../models/perfil-acesso.model";

@Component({
  selector: "app-notificacoes-list",
  templateUrl: "./notificacoes-list.component.html",
  styleUrls: ["./notificacoes-list.component.scss"]
})
export class NotificacoesListComponent implements OnInit {

  notificacoes$: Observable<any>;
  flagMenuTrigger: boolean = false;

  constructor(public oneSignalService: OneSignalService,
              public snack: SnackService,
              public router: Router,
              public loadingService: LoadingService,
              public dialogService: DialogService,
              public perfil: PerfilService) {
    this.notificacoes$ = this.oneSignalService.getNotifications();
  }

  filteredNotifications(notificacoes): any[] {
    if (isNotNullOrUndefined(notificacoes)) {
      const notifications: any[] = notificacoes.notifications.filter(not => {
        if (isNotNullOrUndefined(not.data)) {
          return ((not.data.notification_type === "promo" || isNullOrUndefined(not.data.notification_type)) && !not.canceled);
        }
        return !not.canceled;
      });
      return notifications.sort((a, b) => {
        if (a.send_after > b.send_after) {
          return -1;
        }
        if (a.send_after < b.send_after) {
          return 1;
        }
        return 0;
      });
    } else {
      return [];
    }
  }

  ngOnInit() {
  }

  convertToDateFormat(data: number): Date {
    return new Date(data * 1000);
  }

  isEnviada(notificacao): boolean {
    return notificacao.send_after < moment.now() / 1000;
  }

  menuTrigger() {
    this.flagMenuTrigger = true;
  }

  validaEdicao(notificacao) {
    if (!this.flagMenuTrigger) {
      if (!this.isEnviada(notificacao)) {
        this.update(notificacao);
      } else {
        this.snack.show(
          "O registro não poderá ser editado, somente visualizado! Essa notificação já foi enviada."
        );
        this.update(notificacao);
      }
    }
    this.flagMenuTrigger = !this.flagMenuTrigger;
  }

  validaExclusao(notificacao) {
    if (!this.isEnviada(notificacao) || !notificacao.id) {
      this.dialogService.confirmDeleteDialog().show().pipe(mergeMap(accept => {
        if (accept) {
          return this.perfil.getPerfilAcessoLoggedUser().pipe(mergeMap(perf => {
            if (perf.hasRole(Role.NotificacaoDelete)) {
              this.loadingService.showTopBar();
              return this.oneSignalService.deleteNotification(notificacao.id);
            } else {
              return this.dialogService
                .messageDialog()
                .title("Atenção!")
                .message("Seu usuário não tem permissão para excluir notificações!")
                .show();
            }
          }));
        } else {
          return of();
        }
      })).subscribe(() => {
        this.loadingService.hideTopBar();
        this.notificacoes$ = this.oneSignalService.getNotifications();
      }, err => {
        this.dialogService.messageDialog()
          .title("Erro ao excluir!")
          .message(err)
          .show();
      });
    } else {
      this.snack.show(
        "O registro não pode ser excluído! Essa notificação já foi enviada."
      );
    }
    this.flagMenuTrigger = !this.flagMenuTrigger;
  }

  create() {
    this.perfil.getPerfilAcessoLoggedUser().subscribe(perf => {
      if (perf.hasRole(Role.NotificacaoCreate)) {
        this.router.navigate(["/home/notificacao/form"]);
      } else {
        this.dialogService.messageDialog()
          .title("Atenção!")
          .message("Seu usuário não tem permissão para criar notificações!")
          .show();
      }
    });
  }

  private update(notificacao) {
    this.perfil.getPerfilAcessoLoggedUser().subscribe(perf => {
      if (perf.hasRole(Role.NotificacaoUpdate)) {
        this.router.navigate(["/home/notificacao/form"], {queryParams: {notificationId: notificacao.id}});
      } else {
        this.dialogService.messageDialog()
          .title("Atenção!")
          .message("Seu usuário não tem permissão para editar notificações!")
          .show();
      }
    });
  }

}
