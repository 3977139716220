import {ChangeDetectorRef, Component} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable, Subscription} from "rxjs";
import {AppUser, UserType} from "../../../models/appUser";
import {AuthService} from "../../../modules/login/auth.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {PageService} from "../../../services/page.service";
import {SnackService} from "../../../services/snack/snack.service";
import {UsersService} from "../users.service";

@Component({
  selector: "app-users-list",
  templateUrl: "./users-list.component.html",
  styleUrls: ["./users-list.component.scss"]
})
export class UsersListComponent extends PageService<AppUser> {
  $users: Observable<AppUser[]>;

  readonly UserType = UserType;

  userSubscription: Subscription;
  loggedUser: AppUser;

  constructor(public service: UsersService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              private auth: AuthService) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/users/");

    this.$users = this.service.getUsersByUserLoggedType();

    this.userSubscription = this.auth.currentUser.subscribe((user: AppUser) => {
      this.loggedUser = user;
    });
  }

  newItem(): AppUser {
    return null;
  }

  getAssistenteToolTip(users: AppUser[], id: string): string {
    return UserType.Assistente + " do " + users.find(user => user.id === id).name;
  }

  isDisabled(item) {
    return item.isConsultor() && this.loggedUser.isAssistente();
  }

  edit(item?: any, isCopyRecord?: boolean) {
    if (!this.isDisabled(item)) {
      super.edit(item.id, isCopyRecord);
    }
  }

  ativarOuInativar(item: AppUser) {
    this.service.getDoc(item.id).update({
      inactive: !item.inactive
    })
      .then(() => {
      })
      .catch(() => {
        this.dialogService
          .messageDialog()
          .title("Atenção")
          .message("Não foi possível mudar o status! Por favor, tente novamente.")
          .show();
      });
  }
}
