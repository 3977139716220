import {Component, Inject, OnInit} from "@angular/core";
import {TipoBloqueio, TipoBloqueioToString} from "../../../models/empresa";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import * as moment from "moment";
import {Observable} from "rxjs";
import {InvoiceIugu} from "../../../models/iugu/invoice.iugu.model";
import {InvoiceIuguService} from "../../../services/iugu/invoice-iugu.service";
import {LoadingService} from "../../../services/loading/loading.service";

@Component({
  selector: "app-block-empresa-dialog",
  templateUrl: "./block-empresa-dialog.component.html",
  styleUrls: ["./block-empresa-dialog.component.scss"]
})
export class BlockEmpresaDialogComponent implements OnInit {
  tipoBloqueio = TipoBloqueio;
  tipoBloqueioToString = TipoBloqueioToString;

  form: FormGroup;

  verifyCheckbox: FormControl;
  $invoice: Observable<InvoiceIugu[]>;
  minDate = moment(new Date()).add(1, "day");

  constructor(public dialogRef: MatDialogRef<BlockEmpresaDialogComponent>,
              formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data,
              public invoiceService: InvoiceIuguService,
              public loadingService: LoadingService) {
    this.form = formBuilder.group({
      tipo: [null, data.bloqueado ? [] : [Validators.required]],
      motivo: [null, [Validators.required, Validators.minLength(10), this.noWhitespaceValidator]],
      date: [null],
      invoice: [null]
    });
    this.verifyCheckbox = new FormControl(false);
    this.$invoice = invoiceService.listPendingInvoicesByEmpresa(data.empresa);
  }

  ngOnInit() {
    this.verifyCheckbox.valueChanges.pipe().subscribe(value => {
      if (value) {
        this.form.get("date").patchValue(this.minDate);
        this.form.get("date").setValidators(Validators.required);
        this.form.get("date").updateValueAndValidity();
        this.form.get("invoice").patchValue("");
        this.form.get("invoice").setValidators(Validators.required);
        this.form.get("invoice").updateValueAndValidity();
      }
      if (!value) {
        this.form.get("date").patchValue(null);
        this.form.get("date").setValidators(null);
        this.form.get("date").updateValueAndValidity();
        this.form.get("invoice").patchValue(null);
        this.form.get("invoice").setValidators(null);
        this.form.get("invoice").updateValueAndValidity();
      }
    });
  }

  noWhitespaceValidator(control: FormControl): { [key: string]: boolean } {
    const charLength = (control.value || "").trim().length;
    const isWhitespace = charLength < 10;
    const isValid = !isWhitespace;
    if (charLength === 0) {
      return { "onlyWhitespace": true };
    }
    return isValid ? null : { "whitespace": true };
  }

  getTipoBloqueio(): string[] {
    return Object.keys(this.tipoBloqueio).map(val => val);
  }

  submit() {
    this.dialogRef.close(this.form.value);
  }

  convertDate(date: string): string {
    return new Date(date).toLocaleDateString();
  }
}
