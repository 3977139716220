/**
 * Diretiva de máscara para campos monetários.
 *
 */

import {Directive, ElementRef, HostListener, Inject, Input, OnInit, Renderer2} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: "[pomMaskMoney]",
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: PomMaskMoneyDirective,
    multi: true
  }]
})
export class PomMaskMoneyDirective implements ControlValueAccessor, OnInit {

  onTouched: any;
  onChange: any;

  separadorDecimal: string;
  separadorMilhar: string;
  prefixo: string;
  sufixo: string;
  isNegativo: boolean = false;

  @Input("pomMaskMoney") config: {
    decimal: string,
    milhar: string,
    prefix: string,
    sufix: string
  };

  constructor(@Inject(Renderer2) private renderer: Renderer2, @Inject(ElementRef) private el: ElementRef) {
  }

  ngOnInit() {
    this.separadorDecimal = this.config.decimal || ",";
    this.separadorMilhar = this.config.milhar || ".";
    this.prefixo = this.config.prefix || "";
    this.sufixo = this.config.sufix || "";
  }

  writeValue(value: any): void {

    if (value !== null && value !== "") {
      if (!isNaN(value)) {
        value = value.toFixed(2);
      }
      this.renderer.setAttribute(this.el.nativeElement, "value", this.aplicarMascara(String(value)));
    } else {
      this.renderer.setAttribute(this.el.nativeElement, "value", "");
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @HostListener("keyup", ["$event"])
  onKeyup($event: any) {

    const valor: string = this.aplicarMascara($event.target.value);

    if (valor === "") {
      this.onChange("");
      $event.target.value = "";
      return;
    }

    if (this.separadorDecimal === ",") {
      this.onChange(parseFloat(valor.replace(/\./g, "").replace(",", ".")));
    } else {
      this.onChange(valor.replace(/\,/g, ""));
    }

    $event.target.value = valor;
  }

  @HostListener("blur", ["$event"])
  onBlur($event: any) {
    this.onTouched($event);
  }

  /**
   * Aplica a máscara a determinado valor.
   */
  aplicarMascara(valorConverter: string): string {

    const valorNum = parseInt(valorConverter.replace(/\D/g, ""), 10);
    this.isNegativo = valorConverter.startsWith("-") && valorNum !== 0;
    let valorMask = "";
    let valor: string;

    if (isNaN(valorNum)) {
      if (this.isNegativo) {
        return "-";
      } else {
        return "";
      }
    }

    valor = valorNum.toString();
    switch (valor.length) {
      case 1:
        valorMask = "0" + this.separadorDecimal +
          "0" + valor;
        break;
      case 2:
        valorMask = "0" + this.separadorDecimal + valor;
        break;
      case 3:
        valorMask = valor.substr(0, 1) + this.separadorDecimal +
          valor.substr(1, 2);
        break;
      default:
        break;
    }

    if (valorMask === "") {
      let sepMilhar = 0;
      for (let i = (valor.length - 3); i >= 0; i--) {
        if (sepMilhar === 3) {
          valorMask = this.separadorMilhar + valorMask;
          sepMilhar = 0;
        }
        valorMask = valor.charAt(i) + valorMask;
        sepMilhar++;
      }
      valorMask = valorMask + this.separadorDecimal +
        valor.substr(valor.length - 2, 2);
    }

    if (this.prefixo !== "") {
      valorMask = this.prefixo + " " + valorMask;
    }
    if (this.isNegativo) {
      valorMask = "-" + valorMask;
    }
    if (this.sufixo !== "") {
      valorMask = valorMask + this.prefixo;
    }

    return valorMask;
  }

}
