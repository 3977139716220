import {Criterion} from "./criterion";
import * as firebase from "firebase";
import Query = firebase.firestore.Query;
import CollectionReference = firebase.firestore.CollectionReference;

export class Limit extends Criterion {
  constructor(public limit: number) {
    super(null, null, null);
  }

  build(ref: CollectionReference | Query): Query {
    return (ref as Query).limit(this.limit);
  }
}
