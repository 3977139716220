import {Observable} from "rxjs/Rx";
import {Role} from "../../models/perfil-acesso.model";

export interface MenuItem {
  icon: any;
  nameItem: string;
  link: string;
  roles: Role[];
  onlyForAdministrador?: boolean;
  needCompany?: boolean;
  showImage?: Observable<boolean>;
}

export const itensMenu: MenuItem[] = [
  {
    icon: {materialIcon: "store_mall_directory"},
    nameItem: "Empresas",
    link: "/home/empresa",
    roles: [Role.EmpresaRead],
    needCompany: false,
  },
  {
    icon: {materialIcon: "people"},
    nameItem: "Usuários",
    link: "/home/users",
    roles: [Role.UsuarioRead],
  },
  {
    icon: {materialIcon: "security"},
    nameItem: "Perfis de Acesso",
    link: "/home/perfis",
    roles: [Role.PerfilAcessoRead],
  },
  {
    icon: {materialIcon: "map"},
    nameItem: "Bairros",
    link: "/home/bairros",
    roles: [Role.BairroRead],
  },
  {
    icon: {materialIcon: "motorcycle"},
    nameItem: "Delivery",
    link: "/home/delivery",
    roles: [Role.DeliveryRead],
    needCompany: true,
  },
  {
    icon: {materialIcon: "payments"},
    nameItem: "Formas de Pagamento",
    link: "/home/formas-pagamento",
    roles: [Role.FormaPagamentoRead],
  },
  {
    icon: {mdiIcon: "mdi-food"},
    nameItem: "Produtos",
    link: "/home/produtos",
    roles: [Role.ProdutoRead],
    needCompany: true,
  },
  {
    icon: {materialIcon: "label"},
    nameItem: "Categorias",
    link: "/home/categorias-produto",
    roles: [Role.CategoriaProdutoRead],
    needCompany: true,
  },
  {
    icon: {materialIcon: "extension"},
    nameItem: "Complementos",
    link: "/home/complementos-produto",
    roles: [Role.ComplementoProdutoRead],
    needCompany: true,
  },
  {
    icon: {materialIcon: "restaurant_menu"},
    nameItem: "Cozinhas",
    link: "/home/cozinhas",
    roles: [Role.CozinhaRead],
    needCompany: true,
  },
  {
    icon: {materialIcon: "receipt"},
    nameItem: "Relatório",
    link: "/home/relatorio-empresa",
    roles: [Role.PedidoRead],
    needCompany: true,
  },
  {
    icon: {materialIcon: "desktop_mac"},
    nameItem: "Gerenciador de pedidos",
    link: "/home/gerenciador",
    roles: [Role.GerenciadorPedidosRead],
    needCompany: true,
  },
  {
    icon: {materialIcon: "star_rate"},
    nameItem: "Avaliações",
    link: "/home/avaliacoes",
    roles: [Role.AvaliacaoRead],
    needCompany: true,
  },
  {
    icon: {materialIcon: "message"},
    nameItem: "Notificações",
    link: "/home/notificacao",
    roles: [Role.NotificacaoRead],
    onlyForAdministrador: true,
    needCompany: false,
  },
  {
    icon: {materialIcon: "personal_video"},
    nameItem: "Central de Entregas",
    link: "/home/central",
    roles: [Role.CentralEntregasRead],
    onlyForAdministrador: true,
    needCompany: false,
  },
  {
    icon: {materialIcon: "people_outline"},
    nameItem: "Entregadores",
    link: "/home/entregadores",
    roles: [Role.EntregadoresRead],
    onlyForAdministrador: true,
    needCompany: false,
  },
  {
    icon: {materialIcon: "local_shipping"},
    nameItem: "Entregas",
    link: "/home/entregas",
    roles: [Role.EntregasRead],
    onlyForAdministrador: true,
    needCompany: false,
  },
  {
    icon: {materialIcon: "dvr"},
    nameItem: "Monitor de Pedidos",
    link: "/home/monitor",
    roles: [Role.MonitorPedidosRead],
    needCompany: false,
  },
  {
    icon: {materialIcon: "receipt"},
    nameItem: "Relatório",
    link: "/home/relatorio-geral",
    roles: [Role.RelatorioPedidosRead],
    needCompany: false,
  },
  {
    icon: {materialIcon: "shop"},
    nameItem: "Promoções",
    link:"/home/promocoes",
    roles: [Role.PromocaoRead],
    needCompany: false,
  },
  {
    icon: {materialIcon: "attach_money"},
    nameItem: "Comissões | Novo",
    link: "/home/comissoes-consultores",
    roles: [Role.ComissoesConsultoresRead],
    needCompany: false,
  },
  {
    icon: {materialIcon: "attach_money"},
    nameItem: "Comissões",
    link: "/home/relatorio-comissoes",
    roles: [Role.RelatorioComissoesRead],
    needCompany: false,
  },
  {
    icon: {materialIcon: "monetization_on"},
    nameItem: "Financeiro",
    link: "/home/financeiro",
    roles: [Role.FinanceiroRead],
    needCompany: false,
  },
  {
    icon: {materialIcon: "description"},
    nameItem: "Faturas",
    link: "/home/faturas",
    roles: [Role.FaturasRead],
    needCompany: true,
  },
  {
    icon: {materialIcon: "account_balance_wallet"},
    nameItem: "Extrato Vendas Online",
    link: "/home/extrato",
    roles: [Role.ExtratoRead],
  },
  {
    icon: {materialIcon: "timeline"},
    nameItem: "Abatimentos",
    link: "/home/abatimentos",
    roles: [Role.AbatimentosRead],
    needCompany: false,
  },
  {
    icon: {materialIcon: "group"},
    nameItem: "Clientes",
    link: "/home/clientes",
    roles: [Role.ClientesRead],
    needCompany: false,
  }
];
