import {Injectable} from "@angular/core";
import {Cozinha} from "../../models/cozinha.model";
import {AngularFirestore} from "@angular/fire/firestore";
import {AuthService} from "../../modules/login/auth.service";
import {OrderBy} from "../../services/firebase/criteria/order-by";
import {OrderByAsc} from "../../services/firebase/criteria/order-by-asc";
import {RepositoryByEmpresa} from "../../repository/repository-by-empresa";
import {Observable, of} from "rxjs";
import {mergeMap, take} from "rxjs/operators";
import {AngularFireFunctions} from "@angular/fire/functions";
import {isEmpty} from "../../utils/commons";

@Injectable({
  providedIn: "root"
})
export class CozinhasService extends RepositoryByEmpresa<Cozinha> {

  constructor(dataBase: AngularFirestore, auth: AuthService, public fns: AngularFireFunctions) {
    super(dataBase, auth, "cozinhas", true);
  }

  hasSomePrinter(): Observable<boolean> {
    if (this.auth.currentEmpresa) {
      return super.col$().pipe(
        take(1),
        mergeMap((cozinhas: Cozinha[]) => {
          return of(cozinhas.some((cozinha: Cozinha) => {
            return cozinha.printer && !isEmpty(cozinha.printer);
          }));
        })
      );
    } else {
      return of(false);
    }
  }

  remove(id: string): Observable<void> {
    return this.fns.httpsCallable("call-cozinhas-remove")({id: id});
  }

  deserialize(value: any): Cozinha {
    return new Cozinha(value);
  }

  protected orderBy(): OrderBy {
    return new OrderByAsc("nome");
  }
}
