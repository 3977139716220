import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ImageCroppedEvent} from "ngx-image-cropper";
import {FileSystemFileEntry, NgxFileDropEntry} from "ngx-file-drop";

interface Detail {
  configsCropper: {
    canvasWidth: string | number;
    canvasHeight: string | number;
    width: string | number;
    height: string | number;
    aspectRatio: DoubleRange | number | ConstrainDoubleRange | boolean;
  };
}

@Component({
  selector: "app-cropper-dialog",
  templateUrl: "./cropper-dialog.component.html",
  styleUrls: ["./cropper-dialog.component.scss"]
})
export class CropperDialogComponent implements OnInit {
  public showCropper = false;
  public fileOver: boolean = false;

  public data: any = {};

  constructor(public dialogRef: MatDialogRef<CropperDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public detail: Detail) {
    dialogRef.afterOpened().subscribe(() => {
      this.showCropper = true;
    });
    dialogRef.afterClosed().subscribe(() => {
      this.showCropper = false;
    });
  }

  public dropped(files: NgxFileDropEntry[]) {
    console.log(files);
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file(
          (ev) => {
            this.imageChangedEvent = {target: {files: [ev]}};
          }
        );
      }
    }
  }

  public close() {
    if (this.croppedImage) {
      return this.dialogRef.close(this.croppedImage);
    } else {
      return this.dialogRef.close();
    }
  }

  ngOnInit() {
  }

  public imageChangedEvent: any = "";
  public croppedImage: any;
  public imageFormat: string;


  public fileChangeEvent(event: any): void {
    this.imageFormat = event.target.files[0].type.replace("image/", "");
    this.imageChangedEvent = event;
  }

  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  public deleteImage() {
    this.fileOver = false;
    this.imageChangedEvent = "";
  }

  public onFileOver() {
    this.fileOver = true;
  }

  public onFileLeave() {
    this.fileOver = false;
  }
}
