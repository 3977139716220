import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {PedidoItem} from "../models/pedido-item.model";
import {Observable} from "rxjs/Rx";
import {Criterion} from "./firebase/criteria/criterion";
import {$query} from "./firebase/criteria/query";
import {Pedido} from "../models/pedido.model";
import {Repository} from "../repository/Repository";
import {GerenciadorPedidosService} from "../components/gerenciador-pedidos/service/gerenciador-pedidos.service";

@Injectable({
  providedIn: "root"
})
export class PedidoItemService extends Repository<PedidoItem> {

  constructor(db: AngularFirestore, public pedidoService: GerenciadorPedidosService) {
    super(db, "pedidos-itens", true);
  }

  deserialize(value: any): PedidoItem {
    return new PedidoItem(value);
  }

  getItensByPedido(pedido: Pedido): Observable<PedidoItem[]> {
    // Adiciona a referência do pedido
    return this.col$($query(new Criterion("pedido", "==", this.pedidoService.getDoc(pedido.id).ref)));
  }

}
