import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {AuthService} from "../../../../login/auth.service";
import {isNotNullOrUndefined, isNullOrUndefined} from "../../../../../utils/commons";
import {PerfilService} from "../../../../../components/perfil-acesso/perfil.service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {FormGroup} from "@angular/forms";

@Component({
  selector: "app-card-form",
  templateUrl: "./card-form.component.html",
  styleUrls: ["./card-form.component.scss"]
})
export class CardFormComponent implements OnInit, OnDestroy {
  private _perfilSubscription: Subscription;

  @Input()
  public item: any;
  @Input()
  public title: string;
  @Input()
  public subtitle: string;
  @Input()
  public form: FormGroup;
  @Input()
  public disableSaveButtonWhen: boolean;
  @Input()
  public titleSaveButton: string = "Salvar";
  @Input()
  titleCancelarButton: string = "Cancelar";
  @Input()
  public hideMatCardActions: boolean = false;
  @Input()
  public hideSaveButton: boolean = false;
  @Input()
  public hideSubtitle: boolean = false;
  @Output()
  public cancelEvent = new EventEmitter();
  @Output()
  public saveEvent = new EventEmitter();

  // Indica se o usuário tem permissão para salvar o registro
  hasPermission: boolean = false;

  // Define um tooltip caso o usuário não tenha permissão para salvar o registro
  tooltipSaveButton: string;

  constructor(private auth: AuthService, private perfil: PerfilService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    // Verificar se item foi passado como parâmetro
    if (isNullOrUndefined(this.item)) {
      throw new Error("Attribute 'item' is required");
    }

    this._perfilSubscription = this.perfil.getPerfilAcessoLoggedUser()
      .subscribe(perfil => {
        // Regra atual
        let currentRole;

        // Mensagem de permissão
        let messagePermission;

        // Verificar se é um novo registro
        if (isNullOrUndefined(this.item["id"]) || this.item["id"] === "") {
          // Recuperar a regra de create da rota
          currentRole = this.route.snapshot.data.role.create;
          messagePermission = "Sem permissão para criar novos registros";
        } else {
          // Recuperar a regra de update da rota
          currentRole = this.route.snapshot.data.role.update;
          messagePermission = "Sem permissão para atualizar registros";
        }

        // Verificar se tem permissão
        this.hasPermission = perfil.hasRole(currentRole);

        // Mensagem de permissão
        this.tooltipSaveButton = !this.hasPermission ? messagePermission : undefined;
      });
  }


  ngOnDestroy() {
    this._perfilSubscription.unsubscribe();
  }

  public getSubtitle() {
    if (isNotNullOrUndefined(this.subtitle)) {
      return this.subtitle;
    } else if (isNotNullOrUndefined(this.auth.currentEmpresa)) {
      return this.auth.currentEmpresa.nomeFantasia;
    } else {
      return "Informe os dados abaixo";
    }
  }

  public cancel() {
    this.cancelEvent.emit();
  }

  public save() {
    this.saveEvent.emit();
  }

  public disableSaveButton(): boolean {
    // Desabilita o botão de salvar caso:
    // 1 - disableSaveButtonWhen seja verdadeira
    // 2 - ou, não tenha permissão
    return this.disableSaveButtonWhen || (this.form && !this.form.valid) || !this.hasPermission;
  }
}
