import {Injectable} from "@angular/core";
import {Repository} from "../../repository/Repository";
import {Empresa} from "../../models/empresa";
import {AngularFirestore} from "@angular/fire/firestore";

@Injectable()
export class EmpresaAuthService extends Repository<Empresa> {

  constructor(db: AngularFirestore) {
    super(db, "companies");
  }
}
