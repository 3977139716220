import {Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges, Input} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {Empresa} from "../../../../models/empresa";
import {Observable} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {EmpresaService} from "../../../../components/empresa/empresa.service";
import {MatOptionSelectionChange} from "@angular/material/core";
import {isEmpty} from "../../../../utils/commons";

@Component({
  selector: "app-empresa-select",
  templateUrl: "./empresa-select.component.html",
  styleUrls: ["./empresa-select.component.scss"]
})
export class EmpresaSelectComponent implements OnInit, OnChanges {

  public empresaControl = new FormControl();
  public companies$: Observable<Empresa[]>;
  public empresa: Empresa;

  @Input()
  public empresaId: string;

  @Output()
  public empresaSelecionada = new EventEmitter();

  @Input()
  public disable: boolean = false;

  @Input()
  public required: boolean = false;

  @Input()
  public onlyLiberadoEPronto: boolean = false;

  @Input()
  public onlyPedeQueChega: boolean = false;

  constructor(public empresaService: EmpresaService) {
  }

  ngOnInit() {
    this.loadEmpresas();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.empresaId) {
      this.carregarEmpresa(this.empresaId);
    }
    if (this.disable) {
      this.empresaControl.disable();
    }
    if (this.required) {
      this.empresaControl.setValidators(Validators.required);
    }
  }

  public onEmpresaChanges(event: MatOptionSelectionChange, empresa: Empresa): void {
    this.empresa = event.isUserInput ? empresa : null;
    this.empresaSelecionada.emit(this.empresa);
  }

  private carregarEmpresa(empresaId: string): void {
    this.empresaService.getById(empresaId).subscribe((empresa: Empresa) => {
      this.empresaControl.patchValue(empresa.nomeFantasia);
      this.empresa = empresa;
      this.empresaSelecionada.emit(empresa);
    });
  }

  public loadOnFirstFocus(): void {
    if (!this.empresaControl.value && !this.empresa) {
      this.companies$ = this.getCompanies();
    }
  }

  public loadEmpresas(): void {
    this.empresaControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(value => {
      if (isEmpty(value) && !isEmpty(this.empresa)) {
        this.empresaSelecionada.emit(null);
        this.companies$ = this.getCompanies("");
      } else {
        this.companies$ = this.getCompanies(value);
      }
    });
  }

  private getCompanies(value?: string): Observable<Array<Empresa>> {
    return this.empresaService.getCompaniesByNomeFantasia(value ? value : "", this.onlyPedeQueChega, this.onlyLiberadoEPronto);
  }
}


