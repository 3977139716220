
export class Nfse {
  public ref: string;
  public numeroRps: string;
  public status: NfseResponseStatus;
  public tipoRps: string;
  public numero: string;
  public codigoVerificacao: string;
  public dataEmissao: Date;
  public url: string;
  public caminhoXmlNotaFiscal: string;
  public urlDanfse: string;

  constructor(data: any) {
    this.ref = data.ref;
    this.numeroRps = data.numero_rps;
    this.status = data.status;
    this.tipoRps = data.tipo_rps;
    this.numero = data.numero;
    this.codigoVerificacao = data.codigo_verificacao;
    this.dataEmissao = data.data_emissao;
    this.url = data.url;
    this.caminhoXmlNotaFiscal = data.caminho_xml_nota_fiscal;
    this.urlDanfse = data.url_danfse;
  }
}

export enum NfseResponseStatus {
  autorizado = "autorizado",
  processando_autorizacao = "processando_autorizacao",
  erro_autorizacao = "erro_autorizacao"
}

