import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {MatPaginator, PageEvent} from "@angular/material/paginator";

@Component({
  selector: "app-card-scrollable-paginator",
  templateUrl: "./card-scrollable-paginator.component.html",
  styleUrls: ["./card-scrollable-paginator.component.scss"]
})
export class CardScrollablePaginatorComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  subtitle: string;

  @Input()
  hideCustomHeaderDivider: boolean = false;

  @Input()
  length: number;

  @Input()
  pageSize: number;

  @Input()
  pageIndex: number;

  @Input()
  pageSizeOptions: number[];

  @Output()
  paginatorChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  change($event: PageEvent, matPaginator: MatPaginator) {
    this.paginatorChange.emit(matPaginator);
  }
}
