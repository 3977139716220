export enum FunctionsErrorCode {
    cancelled = "cancelled",
    unknown = "unknown",
    invalid_argument = "invalid-argument",
    deadline_exceeded = "deadline-exceeded",
    not_found = "not-found",
    already_exists = "already-exists",
    permission_denied = "permission-denied",
    resource_exhausted = "resource-exhausted",
    failed_precondition = "failed-precondition",
    aborted = "aborted",
    out_of_range = "out-of-range",
    unimplemented = "unimplemented",
    internal = "internal",
    unavailable = "unavailable",
    data_loss = "data-loss",
    unauthenticated = "unauthenticated"
}
