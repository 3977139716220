import emailMask from "text-mask-addons/dist/emailMask";
import {createNumberMask} from "text-mask-addons/dist/textMaskAddons";

export class MaskUtil {
  public static get defaultMask() {
    return false;
  }

  public static get maskEmail() {
    return emailMask;
  }

  public static get maskCep() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];
  }

  public static get regexCep() {
    return "([0-9]{5}[-]?[0-9]{3})";
  }

  public static get maskCnpj() {
    return [/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/];
  }

  public static get maskCpf() {
    return [/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/];
  }

  public static get maskFone() {
    return ["(", /[1-9]/, /[1-9]/, ")", " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
  }

  public static get regexFone() {
    return ".((10)|([1-9][1-9]).)\\s[2-5][0-9]{3}-[0-9]{4}";
  }

  public static get maskCell() {
    return ["(", /[1-9]/, /[1-9]/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
  }

  public static get regexCell() {
    return ".((10)|([1-9][1-9]).)\\s9[1-9][0-9]{3}-[0-9]{4}";
  }

  public static regexCPFCNPJ(tipo: string) {
    if (tipo === "juridica") {
      return this.regexCNPJ;
    } else if (tipo === "fisica") {
      return this.regexCPF;
    }
  }

  public static formatCPF(cpf: string) {
    return cpf.replace(/^(\d{3})\D*(\d{3})\D*(\d{3})\D*(\d{2})$/g, "$1.$2.$3-$4");
  }

  public static get regexCPF() {
    return "([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})";
  }

  public static get regexCNPJ() {
    /* Explicação:
    [0-9]{2} Faixa de caracteres: 0 a 9, quantidade: 2 caracteres;
    [0-9]{3} Faixa de caracteres: 0 a 9, quantidade: 3 caracteres;
    [0-9]{4} Faixa de caracteres: 0 a 9, quantidade: 4 caracteres;
    [\.]?Um ponto, opcional. Foi usado \ no ponto, pois ele sozinho é caractere especial;
    [-]? Um traço, opcional (se acrescentar outros caracteres, comece pelo - sempre);
    [\/]? Uma barra, opcional. Tambem "escapada" com \ pra agradar o PCRE;
    (grupo1)|(grupo2) Se um dos grupos validar, a expressão é válida.
    Caso queira aceitar outros separadores, basta acrescentar entre os [ ].
    Exemplo: [-\.\/]? vai aceitar tanto - quanto . e / naquela posição (? = ou nada).
    Para adaptar para outros "dialetos" de regexp, algumas variações possíveis seriam tirar o
    escape da barra (\/ => /) e opcionalmente colocar um ^ no começo e um $ no fim da linha. */
    return "([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})";
  }

  public static get regexDate() {
    return "^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$";
  }

  public static get maskDate() {
    return [/[0-3]/, /[0-9]/, "/", /[0-1]/, /[0-9]/, "/", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  }

  public static get regexTime() {
    return "^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$";
  }

  public static get maskTime() {
    return [/[0-2]/, /\d/, ":", /[0-5]/, /\d/];
  }

  public static get maskMoney() {
    return createNumberMask({prefix: "", thousandsSeparatorSymbol: ".", decimalSymbol: ",", allowDecimal: true, requireDecimal: true});
  }

  public static get numberInteger() {
    return {mask: createNumberMask({prefix: "", thousandsSeparatorSymbol: ".", decimalSymbol: ",", allowDecimal: false, requireDecimal: false})};
  }
}
