import {Component, Inject, OnInit} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material";
import {Observable} from "rxjs/Rx";

@Component({
  selector: "app-not-found-bairros-dialog",
  templateUrl: "./not-found-bairros-dialog.component.html",
  styleUrls: ["./not-found-bairros-dialog.component.scss"]
})
export class NotFoundBairrosDialogComponent implements OnInit {

  static openDialog(dialog: MatDialog, bairros: any[]): Observable<any> {
    const config = {
      width: "500px",
      disableClose: true,
      data: bairros.sort((a, b) => {
        if(a.bairro.nome < b.bairro.nome) { return -1; }
        if(a.bairro.nome > b.bairro.nome) { return 1; }
        return 0;
      })
    };

    return dialog.open(NotFoundBairrosDialogComponent, config).afterClosed();
  }

  constructor(public dialogRef: MatDialogRef<NotFoundBairrosDialogComponent>,
              public dialogService: DialogService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
