import { IFormCanDeactivate } from "../../../guards/iform-canDeactivate";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { PageService } from "../../../services/page.service";
import { Bairro } from "../../../models/bairro.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BairroService } from "../bairro.service";
import { MatDialog } from "@angular/material/dialog";
import { DialogService } from "../../../services/dialog/dialog.service";
import { SnackService } from "../../../services/snack/snack.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService } from "../../../services/loading/loading.service";
import { Estado, estados } from "../../../models/estado.model";
import { CidadeDialogComponent } from "../../cidade/cidade-dialog/cidade-dialog.component";
import { Cidade } from "../../../models/cidade.model";
import { CidadeService } from "../../cidade/cidade.service";
import { isEmpty, isNotNullOrUndefined } from "../../../utils/commons";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { $query } from "../../../services/firebase/criteria/query";
import { Criterion } from "../../../services/firebase/criteria/criterion";

@Component({
  selector: "app-bairro-form",
  templateUrl: "./bairro-form.component.html",
  styleUrls: ["./bairro-form.component.scss"]
})
export class BairroFormComponent extends PageService<Bairro>
  implements OnInit, IFormCanDeactivate {
  form: FormGroup;

  // Booleano que verifica o status da atualização de um formulário
  formMudou: boolean = null;

  estados: Estado[] = estados;
  cidades: Observable<Cidade[]>;

  constructor(service: BairroService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              private formBuilder: FormBuilder,
              private cidadeService: CidadeService) {

    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/bairros/");

    this.form = this.formBuilder.group({
      estado: ["", [Validators.required]],
      cidade: ["", [Validators.required]],
      nome: ["", [Validators.required, Validators.minLength(3)]]
    });
  }

  ngOnInit() {
    super.ngOnInit();

    this.form.get("estado").valueChanges.subscribe(value => {
      if (value) {
        // Buscar as cidades do estado selecionado
        this.cidades = this.cidadeService
          .col$($query(new Criterion("uf", "==", value)))
          .pipe(
            tap((cidades: Cidade[]) => {
              const containsCidade = cidades.some(cidade => {
                return (
                  this.item &&
                  this.item.cidade &&
                  cidade.id === this.item.cidade.id
                );
              });

              // Se não tiver a cidade na lista, então limpa do objeto e do formulário
              if (!containsCidade) {
                this.item.cidade = null;
                this.form.get("cidade").reset();
              }
            })
          );
      }

      /**
       * Inicializa método que verifica se ocorreram mudanças no conteúdo do formulário,
       * para decidir o comportamento do método podeMudarRota (canDeactivate)
       */
      this.onChanges();
    });

    this.itemSubject.asObservable().subscribe((bairro: Bairro) => {
      this.form.patchValue({
        estado: bairro.cidade.uf,
        cidade: bairro.cidade,
        nome: bairro.nome
      });
    });

    // Se tiver criando um novo registro, atualiza o formulário,
    // caso tenha recebido uma cidade já carrega nos campos
    if (isEmpty(this.getParam("id"))) {
      this.itemSubject.next(this.item);
    }
  }

  newItem(): Bairro {
    // Por conveniência, já carrega a cidade selecionada do ultimo cadastro
    const cidadeSelecionada = (this.service as BairroService).cidadeSelecionada;
    return isNotNullOrUndefined(cidadeSelecionada)
      ? new Bairro({ cidade: cidadeSelecionada })
      : new Bairro();
  }

  addCidade() {
    // Abrir o Dialog para Cadastrar uma nova cidade
    this.dialog.open(CidadeDialogComponent, {width: "450px"}).afterClosed().subscribe(cidade => {
      if (cidade) {
        this.item.cidade = cidade;
        this.item.cidade.uf = cidade.uf;
        this.itemSubject.next(this.item);
      }
    });
  }

  cidadeCompare(c1: Cidade, c2: Cidade): boolean {
    return c1 && c2 && c1.id === c2.id;
  }

  saveOrUpdate(item: Bairro = this.item): Observable<void> {
    // Para não perguntar se o usuário deseja sair ao salvar o formulário eu forço a variável em falso.
    this.formMudou = false;

    const value = this.form.value;
    this.item.cidade = value.cidade;
    this.item.nome = value.nome;

    // Manter a cidade selecionada no serviço
    (this.service as BairroService).cidadeSelecionada = this.item.cidade;
    // Salvar
    return super.saveOrUpdate(item);
  }

  // Verifica se ocorreram mudanças no formulário,alterando o valor da variável formMudou para true, ou mantendo em null por padrão.
  onChanges() {
    let mudanca = 0;
    this.form.valueChanges.subscribe(() => {
      if (mudanca === 0) {
        this.formMudou = false;
        mudanca++;
      } else {
        this.formMudou = true;
      }
    });
  }

  // Caso o formulário foi modificado
  podeMudarRota(): boolean {
    if (this.formMudou === true) {
      return confirm(
        "Os dados ainda não foram salvos. Deseja sair mesmo assim?"
      );
    }
    return true;
  }
}
