import {AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output} from "@angular/core";
import {LoadingService} from "../../../../../services/loading/loading.service";
import {Role} from "../../../../../models/perfil-acesso.model";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: "app-card-scrollable",
  templateUrl: "./card-scrollable.component.html",
  styleUrls: ["./card-scrollable.component.scss"]
})
export class CardScrollableComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input()
  title: string;

  @Input()
  subtitle: string;

  @Input()
  showLoading: boolean = false;

  @Input()
  hideCustomHeaderDivider: boolean = false;

  @Input()
  hideFab = true;

  @Input()
  fabTooltip = "";

  @Input()
  triggerOnChanges;

  @Output()
  scrollableListHeightEvent: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  bottom: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  top: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  more: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onCreateClick = new EventEmitter();

  scrollableListHeight: number;

  Roles = Role;
  role;


  constructor(public loadingService: LoadingService, private route: ActivatedRoute) {
    this.role = this.route.snapshot.data.role.create ? this.route.snapshot.data.role.create : this.route.snapshot.data.role.read;
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  ngAfterViewChecked() {
    const scrollableList: HTMLElement = document.getElementById("scrollable-list");
    if (scrollableList && scrollableList.clientHeight !== this.scrollableListHeight) {
      this.scrollableListHeight = scrollableList.clientHeight;
      this.scrollableListHeightEvent.emit(this.scrollableListHeight);
    }
  }

  _bottom() {
    this.bottom.emit(true);
  }

  _top() {
    this.top.emit(true);
  }

  createNewRecord() {
    this.onCreateClick.emit();
  }
}
