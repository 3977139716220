import {ChangeDetectorRef, Component, OnDestroy} from "@angular/core";
import {PageService} from "../../../services/page.service";
import {FormaPagamento, tipoPagamentoToString} from "../../../models/forma-pagamento.model";
import {ActivatedRoute, Router} from "@angular/router";
import {SnackService} from "../../../services/snack/snack.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import { MatDialog } from "@angular/material/dialog";
import {FormaPagamentoService} from "../forma-pagamento.service";
import {AuthService} from "../../../modules/login/auth.service";
import {map, mergeMap} from "rxjs/operators";
import {combineLatest, Observable, of} from "rxjs";
import {PerfilService} from "../../perfil-acesso/perfil.service";
import {Role} from "../../../models/perfil-acesso.model";
import {isNotNullOrUndefined} from "../../../utils/commons";
import {EmpresaService} from "../../empresa/empresa.service";
import {tap} from "rxjs/internal/operators";
import {Subscription} from "rxjs/Rx";

@Component({
  selector: "app-forma-pagamento-list",
  templateUrl: "./forma-pagamento-list.component.html",
  styleUrls: ["./forma-pagamento-list.component.scss"],
})
export class FormaPagamentoListComponent extends PageService<FormaPagamento> implements OnDestroy {

  $formasPagamento: Observable<FormaPagamento[]>;
  $isAllFormasPagamentoMigradas: Observable<boolean>;

  tipoPagamentoToString = tipoPagamentoToString;

  pagamentoOnlineEnabledObservable: Observable<boolean>;
  hasPagamentoOnlinePermission: boolean = false;
  pagamentoOnlineChipLoading: boolean = false;

  perfilSubscription: Subscription;

  constructor(service: FormaPagamentoService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              public auth: AuthService,
              public perfil: PerfilService,
              public empresaService: EmpresaService) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/formas-pagamento/");

    this.$isAllFormasPagamentoMigradas = this.service
      .getCollection()
      .valueChanges()
      .pipe(
        map((snapshot) => {
          return (
            snapshot.filter(
              (formaPagamento) => !formaPagamento.formaPagamentoGeralId
            ).length <= 0
          );
        })
      );

    this.$formasPagamento = !this.auth.currentEmpresa
      ? this.service.getCollection().valueChanges()
      : combineLatest([
        this.service.getCollection().valueChanges(),
        service.getFormasGeraisPagamento(),
      ]).pipe(
        mergeMap(([formasEmpresa, formasGerais]) => {
          const todasFormasPagamento = formasGerais.filter((formaGeral) =>
            formasEmpresa.every(
              (formaEmpresa) =>
                formaEmpresa.formaPagamentoGeralId !== formaGeral.id
            )
          );

          formasEmpresa.forEach((formaPagamento) =>
            todasFormasPagamento.unshift(formaPagamento)
          );

          return of(
            todasFormasPagamento.sort((formaA, formaB) =>
              formaA.descricao.localeCompare(formaB.descricao)
            )
          );
        })
      );

    this.perfilSubscription = this.perfil.getPerfilAcessoLoggedUser().subscribe(perf => {
      this.hasPagamentoOnlinePermission = perf.hasRole(Role.FormaPagamentoOnlineUpdate);
    });

    if (isNotNullOrUndefined(this.auth.currentEmpresa)) {
      this.pagamentoOnlineEnabledObservable = this.empresaService.getEmpresa(this.auth.currentEmpresa.id)
        .pipe(
          // Atualizar a empresa no auth
          tap(empresa => this.auth.currentEmpresa = empresa),
          // Verificar se o pagamento online está habilitado na empresa
          map(emp => {
            return isNotNullOrUndefined(emp.pagamentoOnline) && emp.pagamentoOnline.enabled;
          })
        );
    }
  }

  pagamentoOnlineRouter() {
    this.router.navigate(["/home/formas-pagamento/pagamento-online"]);
  }

  protected newItem(): FormaPagamento {
    return null;
  }

  ativarOuInativar(item: FormaPagamento) {
    (this.service as FormaPagamentoService).changeInactive(item).subscribe(
      () => {
      },
      (error) => {
        this.dialogService
          .messageDialog()
          .title("Atenção")
          .message(error)
          .show();
      }
    );
  }

  ativarOuInativarPagamentoOnline() {
    if (!this.auth.user.isLojista()) {
      if (this.hasPagamentoOnlinePermission) {
        if (isNotNullOrUndefined(this.auth.currentEmpresa.pagamentoOnline)) {
          this.pagamentoOnlineChipLoading = true;
          (this.service as FormaPagamentoService).changeInactivePagamentoOnline(this.auth.currentEmpresa.id)
            .subscribe(
              () => {
                this.pagamentoOnlineChipLoading = false;
              },
              (error) => {
                this.pagamentoOnlineChipLoading = false;
                this.dialogService
                  .messageDialog()
                  .title("Atenção")
                  .message(error)
                  .show();
              });
        } else {
          this.dialogService.messageDialog().message("A empresa não tem pagamento online configurado!").show();
        }
      } else {
        this.dialogService.messageDialog().message("Seu perfil não tem permissão para editar as configurações de pagamento online!").show();
      }
    }
  }

  ngOnDestroy() {
    if (this.perfilSubscription) {
      this.perfilSubscription.unsubscribe();
    }
  }

}
