import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {PageService} from "../../../services/page.service";
import {Abatimento, TipoAbatimento, TipoAbatimentoToString} from "../../../models/abatimento.model";
import {AbatimentosService} from "../abatimentos.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {EmpresaService} from "../../empresa/empresa.service";
import {Observable, of} from "rxjs";
import {Empresa} from "../../../models/empresa";
import {mergeMap, take} from "rxjs/operators";
import * as firebase from "firebase";
import Timestamp = firebase.firestore.Timestamp;
import {AuthService} from "../../../modules/login/auth.service";
import {EmpresaComissaoService} from "../../../services/empresa-comissao.service";
import {$query} from "../../../services/firebase/criteria/query";
import {Criterion} from "../../../services/firebase/criteria/criterion";
import * as moment from "moment";
import {isEmpty} from "../../../utils/commons";

@Component({
  selector: "app-abatimentos-form",
  templateUrl: "./abatimentos-form.component.html",
  styleUrls: ["./abatimentos-form.component.scss"],
})
export class AbatimentosFormComponent
  extends PageService<Abatimento>
  implements OnInit {
  public form: FormGroup;
  public selectedEmpresa = new FormControl();
  public matHintConfig: { message: string; color: string };
  public minDate = moment(new Date()).subtract(1, "month").startOf("day");
  public tipoAbatimentoToString = TipoAbatimentoToString;
  public disableEmpresaComponent: boolean = false;
  public empresaId: string;

  constructor(
    service: AbatimentosService,
    dialog: MatDialog,
    dialogService: DialogService,
    loadingService: LoadingService,
    snack: SnackService,
    cdRef: ChangeDetectorRef,
    route: ActivatedRoute,
    router: Router,
    public formBuilder: FormBuilder,
    public empresaService: EmpresaService,
    public authService: AuthService,
    public empresaComissaoService: EmpresaComissaoService
  ) {
    super(
      service,
      dialog,
      dialogService,
      loadingService,
      snack,
      cdRef,
      route,
      router,
      "/home/abatimentos"
    );

    this.form = formBuilder.group({
      empresa: ["", [Validators.required]],
      data: ["", [Validators.required]],
      valor: ["", [Validators.required]],
      motivo: ["", [Validators.required]],
      tipo: ["", Validators.required],
      emiteNF: [this.isNewRecord()]
    });

    this.form.valueChanges.subscribe((value) => {
      if (isEmpty(value["emiteNF"])) {
        value["emiteNF"] = false;
      }
      Object.keys(value).forEach((key) => (this.item[key] = value[key]));
    });

    this.getMatHintConfig().subscribe((config) => {
      this.matHintConfig = config;
    });
  }

  getMatHintConfig(): Observable<{ message: string; color: string }> {
    return this.form.get("valor").valueChanges.pipe(
      mergeMap((valor) => {
        if (valor && !isNaN(valor)) {
          if (valor < 0) {
            return of({
              message: "Desconto",
              color: "red",
            });
          } else {
            return of({
              message: "Acréscimo",
              color: "blue",
            });
          }
        }
        return of({
          message: "",
          color: "",
        });
      })
    );
  }

  ngOnInit() {
    super.ngOnInit();

    this.itemSubject.asObservable().subscribe((item) => {
      this.selectedEmpresa.patchValue(item.empresa.nomeFantasia);
      this.form.patchValue(item);
      this.form.get("data").patchValue(item.data.toDate());
      this.empresaId = item.empresa.id;

      if (!item.canUpdate) {
        this.form.disable();
        this.disableEmpresaComponent = true;
        this.selectedEmpresa.disable();
      }
    });
  }

  showMotivo(motivo: string) {
    this.dialogService
      .messageDialog()
      .title("Motivo")
      .message(motivo)
      .show()
      .pipe(take(1))
      .subscribe();
  }

  protected newItem(): Abatimento {
    return new Abatimento();
  }

  public validaAbatimento(item: any) {
    item.data = Timestamp.fromDate(new Date(item.data));
    item.lastUpdate = Timestamp.now();

    const filters = $query(
      new Criterion("empresaId", "==", item.empresa.id),
      new Criterion(
        "dataInicial",
        ">=",
        moment(item.data.toDate()).startOf("month").toDate()
      ),
      new Criterion(
        "dataInicial",
        "<=",
        moment(item.data.toDate()).endOf("month").toDate()
      )
    );

    this.empresaComissaoService
      .col$(filters)
      .take(1)
      .subscribe((data) => {
        // Caso não existe um documento de comissão, salvar
        if (data.length === 0) {
          return super.saveOrUpdate(item);
        } else {
          const index = data.findIndex(comissao => comissao.status === "ABERTO" && comissao.iuguInvoiceId === null);

          if (index > -1) {
            return super.saveOrUpdate(item);
          }

          return this.dialogService
            .messageDialog()
            .title("Data inválida")
            .message(
              "Não existe comissão aberta no período da data selecionada. Selecione outra data para prosseguir"
            )
            .show();
        }
      });
  }

  tiposAbatimento(): Array<string> {
    return Object.keys(TipoAbatimento);
  }

  public receiveEmpresa(empresa: Empresa): void {
    this.form.get("empresa").patchValue(empresa);
  }
}
