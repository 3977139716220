import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from "@angular/core";
import {Dia} from "../../../models/dia";
import {MaskUtil} from "../../../utils/mask-util";
import {isNotNullOrUndefined} from "../../../utils/commons";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {distinctUntilChanged} from "rxjs/operators";
import { MatCheckboxChange } from "@angular/material/checkbox";
import * as moment from "moment";

@Component({
  selector: "app-horario-dia",
  templateUrl: "./horario-dia.component.html",
  styleUrls: ["./horario-dia.component.scss"]
})
export class HorarioDiaComponent implements OnInit, OnChanges {
  readonly maskTime = MaskUtil.maskTime;

  @Input()
  dia: Dia;

  @Input()
  pedeQueChega: boolean;

  @Output()
  horarioValido = new EventEmitter();

  @Output()
  horarioInvalido = new EventEmitter();

  @Output()
  pqcError = new EventEmitter();

  private _showSegundoHorario: boolean = false;

  form: FormGroup;

  constructor(public formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      aberto: [],
      aberturaManha: ["", [Validators.pattern(MaskUtil.regexTime)]],
      fechamentoManha: ["", [Validators.pattern(MaskUtil.regexTime)]],
      aberturaTarde: ["", [Validators.pattern(MaskUtil.regexTime)]],
      fechamentoTarde: ["", [Validators.pattern(MaskUtil.regexTime)]]
    });
  }

  ngOnInit() {
    this.form.valueChanges
      .subscribe(value => {
        this.dia.aberto = value.aberto;
        Object.keys(value).forEach(key => {
          this.dia.funcionamento[key] = value[key];
        });
      });

    this.form.valueChanges
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe(value => {
        const aberto = value.aberto;

        if (aberto) {
          this.form.get("aberturaManha").setValidators([Validators.required, Validators.pattern(MaskUtil.regexTime)]);
          this.form.get("aberturaManha").updateValueAndValidity();
          this.form.get("fechamentoManha").setValidators([Validators.required, Validators.pattern(MaskUtil.regexTime)]);
          this.form.get("fechamentoManha").updateValueAndValidity();
        } else {
          this.form.get("aberturaManha").setValidators(Validators.pattern(MaskUtil.regexTime));
          this.form.get("aberturaManha").updateValueAndValidity();
          this.form.get("fechamentoManha").setValidators(Validators.pattern(MaskUtil.regexTime));
          this.form.get("fechamentoManha").updateValueAndValidity();
        }
      });

    this.form.statusChanges.subscribe(status => {
      if (status === "VALID") {
        this.horarioValido.emit();
        this.verificarPedeQueChega();
      } else if (status === "INVALID") {
        this.horarioInvalido.emit();
      }
    });
  }

  // Verifica se a empresa utiliza o PedeQueChega, e compara os horários.
  // Caso os horários não batam, retornará um boolean que mostrará uma mensagem de erro ao salvar.
  verificarPedeQueChega() {
    const horarioPQCInicial = moment("09:00", "hh:mm");
    const horarioPQCFinal = moment("23:45", "hh:mm");

    const aberturaM = moment(this.form.get("aberturaManha").value, "hh:m");
    const fechamentM = moment(this.form.get("fechamentoManha").value, "hh:m");
    const aberturaT = moment(this.form.get("aberturaTarde").value, "hh:m");
    const fechamentoT = moment(this.form.get("fechamentoTarde").value, "hh:m");

    if (this.pedeQueChega === true) {
      if ((aberturaM.isBefore(horarioPQCInicial) || aberturaM.isAfter(horarioPQCFinal)) ||
        (fechamentM.isBefore(horarioPQCInicial) || fechamentM.isAfter(horarioPQCFinal)) ||
        (aberturaT.isBefore(horarioPQCInicial) || aberturaT.isAfter(horarioPQCFinal)) ||
        (fechamentoT.isBefore(horarioPQCInicial) || fechamentoT.isAfter(horarioPQCFinal))) {
        this.pqcError.emit(true);
      } else {
        this.pqcError.emit(false);
      }
    }
  }

  ngOnChanges(): void {
    this.form.get("aberto").patchValue(this.dia.aberto);
    this.form.patchValue(this.dia.funcionamento);
  }

  addHorario() {
    this.form.get("aberturaTarde").setValidators([Validators.required, Validators.pattern(MaskUtil.regexTime)]);
    this.form.get("aberturaTarde").updateValueAndValidity();
    this.form.get("fechamentoTarde").setValidators([Validators.required, Validators.pattern(MaskUtil.regexTime)]);
    this.form.get("fechamentoTarde").updateValueAndValidity();
    this._showSegundoHorario = !this._showSegundoHorario;
  }

  removeHorario() {
    this._showSegundoHorario = !this.showSegundoHorario;
    // Limpa o horário da tarde
    this.form.get("aberturaTarde").reset();
    this.form.get("fechamentoTarde").reset();
    this.form.get("aberturaTarde").setValidators(Validators.pattern(MaskUtil.regexTime));
    this.form.get("aberturaTarde").updateValueAndValidity();
    this.form.get("fechamentoTarde").setValidators(Validators.pattern(MaskUtil.regexTime));
    this.form.get("fechamentoTarde").updateValueAndValidity();
  }

  onDiaSelected(event: MatCheckboxChange) {
    // Se desmarcar o dia
    if (!event.checked) {
      // Limpa os horários
      if (this.showSegundoHorario) {
        this.removeHorario();
      }
      this.form.reset();
      this.form.get("aberto").patchValue(false);
    }
  }

  get showSegundoHorario(): boolean {
    // Verificar se exibe o segundo horário
    return this._showSegundoHorario ||
      isNotNullOrUndefined(this.dia.funcionamento.aberturaTarde) ||
        isNotNullOrUndefined(this.dia.funcionamento.fechamentoTarde);
  }

  set showSegundoHorario(value: boolean) {
    this._showSegundoHorario = value;
  }
}
