import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material";
import {Observable} from "rxjs/Rx";
import {DialogService} from "../../../services/dialog/dialog.service";
import {EntregasService} from "../entregas.service";
import {finalize, mergeMap, take} from "rxjs/operators";
import {LoadingService} from "../../../services/loading/loading.service";
import {of} from "rxjs/observable/of";

@Component({
  selector: "app-pagar-entregador-dialog",
  templateUrl: "./pagar-entregador-dialog.component.html",
  styleUrls: ["./pagar-entregador-dialog.component.scss"]
})
export class PagarEntregadorDialogComponent implements OnInit {

  static showDialog(dialog: MatDialog, data: any): Observable<any> {
    const config = {
      width: "400px",
      data: {
        entregador: data.entregador,
        dataInicial: data.dataInicial,
        dataFinal: data.dataFinal,
        total: data.total,
        entregas: data.entregas
      },
      disableClose: true
    };
    return dialog.open(PagarEntregadorDialogComponent, config).afterClosed();
  }

  loadingOn: boolean = false;

  constructor(public dialogRef: MatDialogRef<PagarEntregadorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: DialogService,
              public service: EntregasService,
              public loading: LoadingService) {

  }

  ngOnInit() {
  }

  pagarEntregas() {
    this.dialog.confirmDialog()
      .message("Deseja alterar o status das entregas para PAGO?")
      .show()
      .pipe(
        take(1),
        mergeMap(accept => {
        if (accept) {
          this.loading.showTopBar();
          this.loadingOn = true;
          return this.service.updateEntregasToPaid(this.data.entregas)
            .pipe(
              finalize(() => {
                this.loading.hideTopBar();
                this.loadingOn = false;
                this.dialogRef.close(true);
              })
            );
        } else {
          return of();
        }
      })).subscribe(() => {}, (error) => {
        this.dialog.messageDialog().title("Erro").message(error.message).show();
    });
  }

}
