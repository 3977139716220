import * as firebase from "firebase";
import {PagamentoOnline} from "./pagamento-online.model";
import {isNotNullOrUndefined} from "../utils/commons";
import Timestamp = firebase.firestore.Timestamp;

export class Extrato {

  public id: string;
  public accountId: string;

  // Informações genêricas
  public descricao: string;
  public valor: number;
  public data: Timestamp;
  public tipo: string;

  // Transação Pedido
  public pedido: DadosPedido;
  public empresa: DadosEmpresa;
  public statusFatura: string;
  public valorPedido: string;
  public valorComissao: string;
  public valorTaxa: string;
  public invoiceId: string;
  public dataLiberacao: string;
  public dataRefund: string;
  public taxaAntecipacao: number;

  // Desconto em comissão
  public comissaoId: string;
  public comissaoReferente: string;

  // Saque
  public statusSaque: string;
  public withdrawRequestId: string;
  public withdrawFeedback: string;
  public taxaSaque: number;
  public requestUser: {
    id: string,
    nome: string
  };

  public logs: LogExtrato[];

  constructor(extrato: any = {}) {
    this.id = extrato.id;
    this.accountId = extrato.accountId;

    this.descricao = extrato.descricao;
    this.valor = extrato.valor;
    this.data = extrato.data;
    this.tipo = extrato.tipo;

    // Transação Pedido
    this.pedido = extrato.pedido;
    this.empresa = extrato.empresa;
    this.statusFatura = extrato.statusFatura;
    this.valorPedido = extrato.valorPedido;
    this.valorComissao = extrato.valorComissao;
    this.valorTaxa = extrato.valorTaxa;
    this.invoiceId = extrato.invoiceId;
    this.dataLiberacao = extrato.dataLiberacao;
    this.dataRefund = extrato.dataRefund;
    this.taxaAntecipacao = extrato.taxaAntecipacao;

    // Desconto e comissão
    this.comissaoId = extrato.comissaoId;
    this.comissaoReferente = extrato.comissaoReferente;

    // Saque
    this.statusSaque = extrato.statusSaque;
    this.withdrawRequestId = extrato.withdrawRequestId;
    this.withdrawFeedback = extrato.withdrawFeedback;
    if (extrato.tipo === TipoExtrato[TipoExtrato.saque]) {
      this.taxaSaque = isNotNullOrUndefined(extrato.taxaSaque) ? extrato.taxaSaque : 0;
    }
    if (extrato.requestUser) this.requestUser = extrato.requestUser;

    if (extrato.logs) {
      this.logs = [];
      extrato.logs.forEach(log => this.logs.push(new LogExtrato(log)));
    }
  }
}

interface DadosEmpresa {
  id: string;
  nomeFantasia: string;
  cnpj: string;
  pagamentoOnline: PagamentoOnline;
}

interface DadosPedido {
  id: string;
  numero: string;
  total: number;
  delivery: number;
}


export enum TipoExtrato {
  pedido = "pedido",
  saque = "saque",
  amortizacao = "amortizacao",
  amortizacao_credito = "amortizacao_credito"
}

export enum StatusSaque {
  pending = "pending",
  processing = "processing",
  accepted = "accepted",
  rejected = "rejected"
}

export function tipoExtratoToString(tipo: string): string {
  switch (tipo) {
    case TipoExtrato.pedido:
      return "Pedido";

    case TipoExtrato.saque:
      return "Saque";

    case TipoExtrato.amortizacao:
      return "Amortização de Débito";

    case TipoExtrato.amortizacao_credito:
      return "Amortização de Crédito";

    default:
      return null;
  }
}

export class LogExtrato {

  public data: Timestamp;
  public status: string;

  constructor(log?: any) {
    if (log) {
      this.data = log.data;
      this.status = log.status;
    }
  }
}
