import {AngularFireFunctions} from "@angular/fire/functions";
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {FormControl} from "@angular/forms";
import {EmpresaService} from "../empresa/empresa.service";
import {AuthService} from "../../modules/login/auth.service";
import {AppUser} from "../../models/appUser";
import {UsersService} from "../users/users.service";
import {map, tap} from "rxjs/operators";
import {ConsultorCommissionService} from "../../services/consultor-commission.service";
import {Criterion} from "../../services/firebase/criteria/criterion";
import {$query} from "../../services/firebase/criteria/query";
import * as moment from "moment";
import {ConsultorComissao} from "../../models/consultor-commission.model";
import {PageService} from "../../services/page.service";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSelectionList } from "@angular/material/list";
import {DialogService} from "../../services/dialog/dialog.service";
import {LoadingService} from "../../services/loading/loading.service";
import {SnackService} from "../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter,} from "@angular/material-moment-adapter";
import {MY_FORMATS} from "../financeiro/financeiro-list/financeiro-list.component";
import {OrderByAsc} from "../../services/firebase/criteria/order-by-asc";
import {isNullOrUndefined} from "../../utils/commons";
import {InformarDataPagamentoDialogComponent} from "./informar-data-pagamento-dialog/informar-data-pagamento-dialog.component";

interface ComissaoAgrupadaPorData {
  periodo: Date;
  comissoes: ConsultorComissao[];
  totalPeriodo: number;
}

export interface DadosPagamentoComissao {
  idComissoes: string[];
  valorTotal: number;
  qtdComissoesSelecionadas: number;
  dataLimitePagamento: Date;
}

@Component({
  selector: "app-comissoes-consultores",
  templateUrl: "./comissoes-consultores.component.html",
  styleUrls: ["./comissoes-consultores.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ComissoesConsultoresComponent
  extends PageService<ConsultorComissao>
  implements OnInit, OnDestroy {
  public userSubscription: Subscription;
  public resumoComissaoSubscription: Subscription;
  public historicoRecebimentoSubscription: Subscription;

  public readonly consultorTodos: string = "Selecione o consultor";
  public readonly loadingResumoComissoes: string = "LoadingResumoComissoes";
  public readonly loadingHistoricoPagamento: string = "LoadingHistoricoPagamento";

  public consultores$: Observable<AppUser[]>;
  public comissaoGeral: ConsultorComissao[] | ComissaoAgrupadaPorData[] = [];
  public loggedUser: AppUser;

  public statusComissao = new FormControl();
  public consultorControl = new FormControl(this.consultorTodos);
  public dataComissao = new FormControl({value: moment(), disabled: true});

  public dataMinimaComissao = moment(new Date("09-01-2020"));

  // Totalizadores Resumo Comissão
  public totalizadorPendente = 0;
  public totalizadorDisponivel = 0;
  public totalizadorMesAtual = 0;

  // Totalizador Histórico de Pagamento/Recebimento
  public totalizadorHistoricoRecebimento = 0;

  // Variáveis Comissões Resumo Comissão
  public comissoesPendentes: | ConsultorComissao[] | ComissaoAgrupadaPorData[] = [];
  public comissoesDisponiveis: | ConsultorComissao[] | ComissaoAgrupadaPorData[] = [];
  public comissoesMesAtual: ConsultorComissao[] = [];

  // Variáveis Comissões Histório de Pagamento/Recebimento
  public comissoesHistoricoRecebimento: ConsultorComissao[] = [];

  // Dados Comissão Disponível para Pagamento
  public comissoesSelecionadas: ConsultorComissao[] = [];
  public valorComissoesSelecionadas: number = 0;
  public qtdComissoesSelecionadas: number = 0;
  public comissaoList: MatSelectionList[];

  constructor(
    public empresaService: EmpresaService,
    public authService: AuthService,
    public userService: UsersService,
    public consultorCommissionService: ConsultorCommissionService,
    public fns: AngularFireFunctions,
    dialog: MatDialog,
    dialogService: DialogService,
    loadingService: LoadingService,
    snack: SnackService,
    cdRef: ChangeDetectorRef,
    route: ActivatedRoute,
    router: Router
  ) {
    super(
      consultorCommissionService,
      dialog,
      dialogService,
      loadingService,
      snack,
      cdRef,
      route,
      router,
      "/home/comissoes-consultores/"
    );

    this.consultores$ = this.userService.getAllConsultores();

    this.statusComissao.valueChanges.subscribe((status) => {
      this.limpaAoMudarStatus();
      this.limpaTotalizadores();

      if (status === "pendente") {
        this.comissaoGeral = this.comissoesPendentes;
      } else if (status === "disponivel") {
        this.comissaoGeral = this.comissoesDisponiveis;
      } else if (status === "atual") {
        this.comissaoGeral = this.comissoesMesAtual;
      }
    });
  }

  ngOnInit() {
    this.userSubscription = this.authService.currentUser
      .pipe(
        tap((user) => {
          this.loggedUser = user;
        })
      )
      .subscribe(() => {
        if (this.loggedUser.isConsultor()) {
          this.consultorControl.patchValue(this.loggedUser);
          this.consultorControl.disable();
          this.carregaResumo();
          this.carregaHistorico();
        }
        this.statusComissao.setValue("pendente");
      });
  }

  ngOnDestroy() {
    if (this.historicoRecebimentoSubscription) {
      this.historicoRecebimentoSubscription.unsubscribe();
    }

    if (this.resumoComissaoSubscription) {
      this.resumoComissaoSubscription.unsubscribe();
    }

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  public newItem(): ConsultorComissao {
    return null;
  }

  public onChangeConsultor(): void {
    this.limpaDadosResumo();
    this.limpaTotalizadores();
    this.limpaDadosHistorico();
    this.carregaResumo();
    this.carregaHistorico();
  }

  public compareConsultor(c1: AppUser, c2: AppUser): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  public carregaResumo(): void {
    this.loading(true, this.loadingResumoComissoes);
    const consultorId = this.consultorControl.value.id;

    if (isNullOrUndefined(consultorId)) {
      this.loading(false, this.loadingResumoComissoes);
      return;
    }

    // Montar o filtro
    const filters = $query(new Criterion("consultorId", "==", consultorId),
      new OrderByAsc("empresa.nomeFantasia"),
      new OrderByAsc("valor"));


    this.resumoComissaoSubscription = this.consultorCommissionService
      .col$(filters)
      .pipe(
        map((comissoes: ConsultorComissao[]) => {
          return comissoes.filter((comissao) => comissao.valor > 0);
        }),
        map((comissoes: ConsultorComissao[]) => {
          const commissionGroup = {
            pendente: {
              totalizador: 0,
              comissoes: Array<ConsultorComissao>(),
            },
            disponivel: {
              totalizador: 0,
              comissoes: Array<ConsultorComissao>(),
            },
            mesAtual: {
              totalizador: 0,
              comissoes: Array<ConsultorComissao>(),
            },
          };

          comissoes.map((comissao) => {
            if (
              comissao.status === "ABERTO" &&
              moment().isSame(comissao.dataInicial.toDate(), "month")
            ) {
              commissionGroup.mesAtual.totalizador += comissao.valor;
              commissionGroup.mesAtual.comissoes.push(comissao);
            } else if (
              comissao.status === "ABERTO" &&
              isNullOrUndefined(comissao.dataLiberacao)
            ) {
              commissionGroup.pendente.totalizador += comissao.valor;
              commissionGroup.pendente.comissoes.push(comissao);
            } else if (
              comissao.status === "FECHADO" &&
              comissao.dataLiberacao
            ) {
              commissionGroup.disponivel.totalizador += comissao.valor;
              commissionGroup.disponivel.comissoes.push(comissao);
            }
          });

          return commissionGroup;
        })
      )
      .subscribe(
        (comissoes) => {
          this.comissoesPendentes = this.agruparComissoesPorData(comissoes.pendente.comissoes);
          this.totalizadorPendente = comissoes.pendente.totalizador;

          this.comissoesDisponiveis = this.agruparComissoesPorData(comissoes.disponivel.comissoes);
          this.totalizadorDisponivel = comissoes.disponivel.totalizador;

          this.comissoesMesAtual = comissoes.mesAtual.comissoes;
          this.totalizadorMesAtual = comissoes.mesAtual.totalizador;


          if (
            isNullOrUndefined(this.statusComissao.value) ||
            this.statusComissao.value === "pendente"
          ) {
            this.statusComissao.setValue("pendente");
            this.comissaoGeral = this.comissoesPendentes;
          } else if (this.statusComissao.value === "disponivel") {
            this.comissaoGeral = this.comissoesDisponiveis;
          } else {
            this.comissaoGeral = this.comissoesMesAtual;
          }

          this.loading(false, this.loadingResumoComissoes);
        },
        (err) => {
          this.dialogService
            .messageDialog()
            .title("Atenção")
            .message(err)
            .show();
        }
      );
  }

  public carregaHistorico(): void {
    this.loading(true, this.loadingHistoricoPagamento);

    const consultorId = this.consultorControl.value.id;

    if (isNullOrUndefined(consultorId)) {
      this.loading(false, this.loadingHistoricoPagamento);
      return;
    }

    const dataPesquisada = this.dataComissao.value;

    const mesInicial = moment(dataPesquisada).startOf("month").toDate();
    const mesFinal = moment(dataPesquisada).endOf("month").toDate();

    // Montar o filtro
    const filters = $query(
      new Criterion("consultorId", "==", consultorId),
      new Criterion("status", "==", "PAGO"),
      new Criterion("dataPagamento", ">=", mesInicial),
      new Criterion("dataPagamento", "<=", mesFinal));


    this.historicoRecebimentoSubscription = this.consultorCommissionService
      .col$(filters)
      .pipe(
        map((comissoes) => {
          let totalizador = 0;

          for (const comissao of comissoes) {
            totalizador += comissao.valor;
          }

          return {totalizador, comissoes};
        })
      )
      .subscribe(
        (data) => {
          this.comissoesHistoricoRecebimento = data.comissoes.sort((a, b) => a.empresa.nomeFantasia.localeCompare(b.empresa.nomeFantasia));
          this.totalizadorHistoricoRecebimento = data.totalizador;
          this.loading(false, this.loadingHistoricoPagamento);
        },
        (err) => {
          this.dialogService
            .messageDialog()
            .title("Atenção")
            .message(err)
            .show();
        }
      );
  }

  public chosenYearHandler(normalizedYear: moment.Moment) {
    const ctrlValue = this.dataComissao.value;
    ctrlValue.year(normalizedYear.year());
    this.dataComissao.setValue(ctrlValue);
  }

  public chosenMonthHandler(
    normalizedMonth: any,
    datepicker: any
  ): void {
    const ctrlValue = this.dataComissao.value;
    ctrlValue.month(normalizedMonth.month());
    this.dataComissao.setValue(ctrlValue);
    datepicker.close();
    this.carregaHistorico();

  }

  public limpaDadosResumo(): void {
    this.totalizadorDisponivel = 0;
    this.totalizadorMesAtual = 0;
    this.totalizadorPendente = 0;
    this.comissaoList = [];
    this.comissoesPendentes = [];
    this.comissoesDisponiveis = [];
    this.comissoesMesAtual = [];
    this.comissaoGeral = [];
  }

  public limpaTotalizadores(): void {
    if (this.comissaoList.length > 0 && this.comissaoList) {
      this.deselectAll();
    }
    this.qtdComissoesSelecionadas = 0;
    this.valorComissoesSelecionadas = 0;
    this.comissoesSelecionadas = [];
  }

  public limpaDadosHistorico(): void {
    this.totalizadorHistoricoRecebimento = 0;
    this.comissoesHistoricoRecebimento = [];
  }

  public limpaAoMudarStatus(): void {
    this.valorComissoesSelecionadas = 0;
    this.qtdComissoesSelecionadas = 0;
    this.comissaoList = [];
    this.comissaoGeral = [];
  }

  public agruparComissoesPorData(
    comissoes: ConsultorComissao[]
  ): ComissaoAgrupadaPorData[] {
    const comissoesAgrupadas: ComissaoAgrupadaPorData[] = [];
    const mesesComissoes = this.getPeriodoComissoesDisponiveis(comissoes);
    const mesesComissaoParaDate = mesesComissoes.map(comissao => new Date(comissao));

    for (const periodo of mesesComissaoParaDate) {
      comissoesAgrupadas.push({
        periodo,
        comissoes: Array<ConsultorComissao>(),
        totalPeriodo: 0,
      });
    }

    comissoesAgrupadas.forEach((grupo) => {
      comissoes.forEach((comissao) => {
        if (
          moment(comissao.dataInicial.toDate()).isSame(grupo.periodo)
        ) {
          grupo.totalPeriodo += comissao.valor;
          grupo.comissoes.push(comissao);
        }
      });
    });

    return comissoesAgrupadas.sort((a, b) => {
      if (moment(a.periodo).isBefore(b.periodo)) return -1;
      if (moment(a.periodo).isAfter(b.periodo)) return 1;
      else return 0;
    });
  }

  public getPeriodoComissoesDisponiveis(
    comissoes: ConsultorComissao[]
  ): string[] {
    return comissoes
      .map((comissao) => {
        return moment(comissao.dataInicial.toDate()).toString();
      })
      .filter((element, index, self) => {
        return index === self.indexOf(element);
      });
  }

  public selectCommissions(comissao: ConsultorComissao): void {
    const index = this.comissoesSelecionadas.findIndex(
      (data) => data.id === comissao.id
    );

    if (index >= 0 && this.comissoesSelecionadas.length === 1) {
      this.valorComissoesSelecionadas = 0;
      this.qtdComissoesSelecionadas = 0;
      this.comissoesSelecionadas = [];
      return;
    }
    if (index > -1) {
      this.comissoesSelecionadas.splice(index, 1);
    } else {
      this.comissoesSelecionadas.push(comissao);
    }

    this.valorComissoesSelecionadas = this.comissoesSelecionadas
      .map((data) => data.valor)
      .reduce((acc, atual) => acc + atual);

    this.qtdComissoesSelecionadas = this.comissoesSelecionadas.length;
  }

  public selectAllCommissions(
    comissoes: ComissaoAgrupadaPorData,
    list: MatSelectionList
  ): void {
    this.comissaoList.push(list);

    comissoes.comissoes.forEach((comissao) => {
      const innerIndex = this.comissoesSelecionadas.findIndex(
        (data) => data.id === comissao.id
      );

      if (innerIndex === -1) {
        this.comissoesSelecionadas.push(comissao);
      }
    });

    this.valorComissoesSelecionadas = this.comissoesSelecionadas
      .map((data) => data.valor)
      .reduce((acc, atual) => acc + atual);

    this.qtdComissoesSelecionadas = this.comissoesSelecionadas.length;

    list.selectAll();
  }

  public deselectAllCommissions(
    comissoes: ComissaoAgrupadaPorData,
    list: MatSelectionList
  ): void {

    const indexComissaoList = this.comissaoList.findIndex(lista => lista === list);

    if (indexComissaoList > -1) this.comissaoList.splice(indexComissaoList, 1);

    comissoes.comissoes.forEach(comissaoRemover => {
      this.comissoesSelecionadas = this.comissoesSelecionadas.filter((comissao) => comissaoRemover.id !== comissao.id);
    });

    this.valorComissoesSelecionadas = this.comissoesSelecionadas.length > 0 ? this.comissoesSelecionadas
      .map((data) => data.valor)
      .reduce((acc, atual) => acc + atual) : 0;

    this.qtdComissoesSelecionadas = this.comissoesSelecionadas.length;

    list.deselectAll();
  }

  public deselectAll(): void {
    this.comissaoList.forEach(list => list.deselectAll());
  }

  public async pagarComissao() {
    const dadosPagamento: DadosPagamentoComissao = {
      idComissoes: this.comissoesSelecionadas.map((comissao) => comissao.id),
      valorTotal: this.valorComissoesSelecionadas,
      qtdComissoesSelecionadas: this.qtdComissoesSelecionadas,
      dataLimitePagamento: Math.max.apply(null, this.comissoesSelecionadas.map(comissao => comissao.dataLiberacao.toDate()))
    };

    const dialogRef = this.dialog.open(InformarDataPagamentoDialogComponent, {width: "400px", data: dadosPagamento});

    dialogRef.afterClosed().subscribe((data) => {
      if (!data) return;

      this.dataComissao.setValue(dialogRef.componentInstance.dataPagamentoControl.value);
      this.carregaHistorico();
      this.loading(true, this.loadingResumoComissoes);
      this.loading(true, this.loadingHistoricoPagamento);
      this.limpaTotalizadores();
      this.deselectAll();
    }, () => {
      this.loading(false, this.loadingResumoComissoes);
      this.loading(false, this.loadingHistoricoPagamento);
    }, () => {
      this.loading(false, this.loadingResumoComissoes);
      this.loading(false, this.loadingHistoricoPagamento);
    });

    return dialogRef;
  }
}

