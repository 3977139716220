import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot} from "@angular/router";
import "rxjs/add/operator/do";
import "rxjs/add/operator/map";
import "rxjs/add/operator/take";
import {AngularFireAuth} from "@angular/fire/auth";
import {Observable} from "rxjs";
import {map, take, tap} from "rxjs/operators";

@Injectable()
export class AuthGuard implements CanLoad, CanActivate {

  constructor(private fbAuth: AngularFireAuth, private router: Router) {
  }

  private isAuthenticated(): Observable<boolean> {
    return this.fbAuth.authState
      .pipe(
        take(1),
        map((states: any) => !!states),
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(["/login"]);
          }
        })
      );
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.isAuthenticated();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.isAuthenticated();
  }

}
