import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {Pedido} from "../../../models/pedido.model";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PageService} from "../../../services/page.service";
import {GerenciadorPedidosService} from "../service/gerenciador-pedidos.service";
import {AuthService} from "../../../modules/login/auth.service";
import {Empresa, TempoEntrega} from "../../../models/empresa";
import {interval, merge, Observable, of, Subject, Subscription, throwError, timer} from "rxjs";
import {catchError, finalize, map, mergeMap, repeatWhen, retryWhen, take, takeUntil, takeWhile, tap} from "rxjs/operators";
import {StatusPedido} from "../../../models/status-pedido.enum";
import {PedidoDetalhesDialogComponent} from "../pedido-detalhes-dialog/pedido-detalhes-dialog.component";
import {isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";
import {AppUser} from "../../../models/appUser";
import {DetalhesEmpresaDialogComponent} from "../detalhes-empresa-dialog/detalhes-empresa-dialog.component";
import {QzTrayService} from "../../../services/qz-tray.service";
import {DeviceDetectorService} from "ngx-device-detector";
import * as moment from "moment";
import {FunctionsErrorCode} from "../../../utils/https-errors/functions-error-code.enum";
import {MatSnackBarRef} from "@angular/material/snack-bar";
import {HorariosFuncionamentoDialogComponent} from "../horarios-funcionamento-dialog/horarios-funcionamento-dialog.component";
import {EmpresaInadimplenteDialogComponent} from "../empresa-inadimplente-dialog/empresa-inadimplente-dialog.component";
import {InvoiceIugu} from "../../../models/iugu/invoice.iugu.model";
import {InvoiceIuguService} from "../../../services/iugu/invoice-iugu.service";
import {FormaPagamentoService} from "../../forma-pagamento/forma-pagamento.service";
import {TipoPagamento} from "../../../models/forma-pagamento.model";
import {GerenciadorMessage, GerenciadorMessageType} from "../../../models/gerenciador-message.model";
import {EmpresaGerenciadorService} from "../service/empresa-gerenciador.service";

@Component({
  selector: "app-gerenciador-pedidos",
  templateUrl: "./gerenciador-pedidos.component.html",
  styleUrls: ["./gerenciador-pedidos.component.scss"]
})
export class GerenciadorPedidosComponent extends PageService<Pedido> implements OnInit, OnDestroy {

  empresas$: Observable<Empresa[]>;

  isNullOrUndefined = isNullOrUndefined;

  // Lista de pedidos recebidos
  pedidos$: Observable<Pedido[]>;

  loadingName: string = "GerenciadorPedidosComponent";

  loggedUser: AppUser;
  encerrarGerenciador$ = new Subject<void>();

  // Status do pedido para String
  disableButtonsEmpresaId: string;

  iniciarGerenciadorSubscriptions: {
    empresaId: string,
    subscription: Subscription
  }[] = [];
  setDisponivelSubscription: Subscription;
  snackHorario: MatSnackBarRef<any>;

  pendingInvoices: InvoiceIugu[] = [];

  pendingInvoices$: Observable<InvoiceIugu[]>;

  havePedido: boolean;

  // Variável para indicar para a totalização diária que a empresa tem forma de pagamento com taxa extra
  haveTaxaAdicional: boolean;
  TipoPagamento = TipoPagamento;

  constructor(public service: GerenciadorPedidosService,
              public empresaService: EmpresaGerenciadorService,
              private authService: AuthService,
              dialog: MatDialog,
              dialogService: DialogService,
              protected loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              public qz: QzTrayService,
              public deviceDetector: DeviceDetectorService,
              public invoiceIuguService: InvoiceIuguService,
              public formaPagamentoService: FormaPagamentoService) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/gerenciador/");
    this.pendingInvoices$ = this.invoiceIuguService.listPendingInvoicesByEmpresa();
    this.formaPagamentoService.col$().subscribe(val => {
      this.haveTaxaAdicional = val.some(form => isNotNullOrUndefined(form.taxaAdicional));
    });
  }

  hasSomeEmpresaAberta(empresas: Empresa[]): boolean {
    return empresas.some(empresa => empresa.aberto);
  }

  hasSomeEmpresaDisponivel(empresas: Empresa[]): boolean {
    return empresas.some(empresa => empresa.disponivel);
  }

  getPlaceholderMessage(empresas: Empresa[]): { title: string, message: string } {
    if (this.hasSomeEmpresaAberta(empresas) && this.hasSomeEmpresaDisponivel(empresas)) {
      return {
        title: "Você já está visível para seus clientes!",
        message: "Já já chega seu primeiro pedido :)"
      };
    } else if (this.hasSomeEmpresaAberta(empresas) && !this.hasSomeEmpresaDisponivel(empresas)) {
      return {
        title: "Você NÃO está visível para seus clientes!",
        message: "Nenhuma das suas empresas estão on-line."
      };
    } else if (!this.hasSomeEmpresaAberta(empresas)) {
      return {
        title: "Você NÃO está visível para seus clientes!",
        message: "Inicie uma empresa para começar a receber pedidos."
      };
    }
  }

  getHorarioAbertura(empresa: Empresa): string {
    const horarioDeFuncionamento = this.service.getHorarioDeFuncionamento(empresa);

    if (horarioDeFuncionamento) {
      const now = moment(new Date().setSeconds(0, 0)).add(1, "minute").toDate();
      // Quando tem somente um horário de abertura
      if (isNotNullOrUndefined(horarioDeFuncionamento.aberturaManha) && !isNotNullOrUndefined(horarioDeFuncionamento.aberturaTarde)) {
        if (now < this.service.stringHorarioToDate(horarioDeFuncionamento.aberturaManha)) {
          return horarioDeFuncionamento.aberturaManha;
        } else {
          return null;
        }
      }
      // Quando tem os dois horários de abertura
      if (isNotNullOrUndefined(horarioDeFuncionamento.aberturaManha) && isNotNullOrUndefined(horarioDeFuncionamento.aberturaTarde)) {
        if (now < this.service.stringHorarioToDate(horarioDeFuncionamento.aberturaManha)) {
          return horarioDeFuncionamento.aberturaManha;
        } else if (now < this.service.stringHorarioToDate(horarioDeFuncionamento.aberturaTarde)) {
          return horarioDeFuncionamento.aberturaTarde;
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  }

  getHorarioDeFuncionamento(empresa: Empresa, dia?: string): string {
    const horarioDeFuncionamento = this.service.getHorarioDeFuncionamento(empresa, dia);

    if (horarioDeFuncionamento) {
      const hasAllHorario = Object.keys(horarioDeFuncionamento).map(key => horarioDeFuncionamento[key]).every(res => isNotNullOrUndefined(res));
      let horarioManha: string = "";
      let horarioTarde: string = "";

      if (isNotNullOrUndefined(horarioDeFuncionamento.aberturaManha) && isNotNullOrUndefined(horarioDeFuncionamento.fechamentoManha)) {
        horarioManha = horarioDeFuncionamento.aberturaManha + " às " + horarioDeFuncionamento.fechamentoManha;
      }

      if (isNotNullOrUndefined(horarioDeFuncionamento.aberturaTarde) && isNotNullOrUndefined(horarioDeFuncionamento.fechamentoTarde)) {
        horarioTarde = horarioDeFuncionamento.aberturaTarde + " às " + horarioDeFuncionamento.fechamentoTarde;
      }

      return horarioManha + (hasAllHorario ? " - " : "") + horarioTarde;

    } else {
      if (isNullOrUndefined(dia)) {
        return "Fechado";
      } else {
        return "Fora do horário!";
      }
    }
  }

  newItem(): Pedido {
    return null;
  }

  ngOnInit() {
    let first: boolean = true;

    this.empresas$ = this.empresaService.getEmpresas().pipe(tap(empresas => {
      if (first && this.loggedUser.isLojista()) {
        empresas.forEach(empresa => this.iniciarGerenciador(empresa));
        first = false;
      }
    }));

    this.authService.currentUser.subscribe(user => {
      this.loggedUser = user;
    });

    if (this.deviceDetector.isDesktop()) {
      this.qz.connect().pipe(take(1)).subscribe();
    }

    this.pedidos$ = this.receberPedidos().pipe(tap(pedidos => {
      this.havePedido = pedidos.length > 0 && pedidos.some(pedido => {
        return pedido.status !== StatusPedido[StatusPedido.REJEITADO] && pedido.status !== StatusPedido[StatusPedido.CANCELADO];
      });
    }));
  }

  receberPedidos(): Observable<Pedido[]> {
    const reload = (): Observable<any> => {
      return interval(60000).pipe(
        map(() => of(navigator.onLine)),
        takeWhile(status => !status, true)
      );
    };

    return this.service.getPedidos()
      .pipe(
        takeUntil(merge(reload(), this.encerrarGerenciador$)),
        repeatWhen(res => res.pipe(takeUntil(this.encerrarGerenciador$)))
      );
  }

  showMessage(message?) {
    if (message) {
      this.dialogService
        .messageDialog()
        .message(message)
        .show();
    }
  }

  iniciarGerenciador(empresa: Empresa, showError?: boolean) {
    this.loadingService.showTopBar();
    this.loadingService.register(empresa.id);
    this.disableButtonsEmpresaId = empresa.id;

    if (isNotNullOrUndefined(this.snackHorario)) {
      this.snackHorario.dismiss();
    }

    const subscriptionExists: boolean = isNotNullOrUndefined(this.iniciarGerenciadorSubscriptions.find(value => value.empresaId === empresa.id));

    if (subscriptionExists) {
      this.iniciarGerenciadorSubscriptions.splice(this.iniciarGerenciadorSubscriptions.findIndex(value => {
        if (value.empresaId === empresa.id) {
          if (isNotNullOrUndefined(value.subscription)) {
            value.subscription.unsubscribe();
          }
        }
        return value.empresaId === empresa.id;
      }), 1);
    }

    this.iniciarGerenciadorSubscriptions.push({
      empresaId: empresa.id,
      subscription: this.service.iniciarGerenciador(empresa)
        .pipe(
          finalize(() => {
            this.loadingService.hideTopBar();
            this.loadingService.timeOut(empresa.id);
            this.disableButtonsEmpresaId = undefined;
          }),
          retryWhen(errors => {
            return errors.pipe(
              mergeMap(error => {
                if (error.code === FunctionsErrorCode.out_of_range) {
                  const timeToOpen: number = error.details.timeToOpen;
                  if (timeToOpen > 0) {
                    return timer(timeToOpen);
                  }
                  return throwError(error);
                } else if (error.code === FunctionsErrorCode.already_exists) {
                  return of();
                }
                return throwError(error);
              }),
              take(1)
            );
          }),
          mergeMap(value => {
            if (value) {
              const gerenciadorMessage: GerenciadorMessage = new GerenciadorMessage(value);
              this.pendingInvoices = gerenciadorMessage.pendingInvoices;
              if (gerenciadorMessage.type === GerenciadorMessageType.BEFORE_HAS_PENDING_INVOICES) {
                return of(value);
              }
              return EmpresaInadimplenteDialogComponent.openDialog(this.dialog, gerenciadorMessage);
            }
            return of(value);
          }),
          catchError(err => this.handleErrorBlockedCompany(this.dialog, err)),
          catchError(error => {
            if (showError) {
              this.showMessage(error && error.message ?
                error.message.toUpperCase() === "INTERNAL" ? "Ops, parece que você está sem internet!" : error.message
                : "Ocorreu um erro ao abrir o Gerenciador! Tente novamente.");
            }
            return of(error);
          })
        ).subscribe()
    });
  }

  handleErrorBlockedCompany(dialog: MatDialog, error) {
    if (error.code === FunctionsErrorCode.permission_denied && error.details && error.details.bloqueado && error.details.gerenciador_message) {
      const gerenciadorMessage: GerenciadorMessage = new GerenciadorMessage(error.details.gerenciador_message);
      this.pendingInvoices = gerenciadorMessage.pendingInvoices;
      return EmpresaInadimplenteDialogComponent.openDialog(dialog, gerenciadorMessage);
    } else {
      throw error;
    }
  }

  fecharEmpresa(empresa: Empresa) {
    this.encerrarGerenciador(empresa).subscribe();
  }

  encerrarGerenciador(empresa: Empresa): Observable<any> {
    return this.dialogService.confirmDialog()
      .title("Encerrar Gerenciador de Pedidos")
      .message("Após encerrado o Gerenciador, sua empresa aparecerá como FECHADA para seus clientes no aplicativo. Gostaria de encerrar?")
      .acceptButton("Encerrar")
      .show()
      .pipe(
        take(1), // take(1) para executar o observable só uma vez e completar ao finalizar
        mergeMap((accept: boolean) => {
          if (accept) {
            this.loadingService.showTopBar();
            this.loadingService.register(empresa.id);
            this.disableButtonsEmpresaId = empresa.id;
            return this.service.encerrarGerenciador(empresa)
              .pipe(
                finalize(() => {
                  this.loadingService.hideTopBar();
                  this.loadingService.timeOut(empresa.id);
                  this.disableButtonsEmpresaId = undefined;
                }),
                catchError((err) => {
                  return this.dialogService.messageDialog()
                    .title("Atenção")
                    .message(err)
                    .show();
                })
              );
          } else {
            return of();
          }
        }));
  }

  suspenderPedidos(empresa: Empresa, disponivel: boolean) {
    this.loadingService.showTopBar();

    if (isNotNullOrUndefined(this.setDisponivelSubscription)) {
      this.setDisponivelSubscription.unsubscribe();
    }

    if (isNotNullOrUndefined(this.snackHorario)) {
      this.snackHorario.dismiss();
    }

    this.setDisponivelSubscription = this.service.setDisponivel(empresa, disponivel).pipe(
      finalize(() => this.loadingService.hideTopBar()),
      retryWhen(errors => {
        return errors.pipe(
          mergeMap(error => {
            if (error.code === FunctionsErrorCode.out_of_range) {
              const timeToOpen: number = error.details.timeToOpen;
              if (timeToOpen > 0) {
                return this.service.getHorarioAbertura(empresa).pipe(
                  mergeMap(horario => {
                    this.snackHorario = this.snack.show(
                      empresa.nomeFantasia + " abrirá automaticamente às " + moment(horario).format("HH:mm"),
                      "Entendi",
                      timeToOpen,
                      "top"
                    );
                    return timer(timeToOpen);
                  })
                );
              }
              return throwError(error);
            }
            return throwError(error);
          }),
          take(1)
        );
      }),
      catchError(err => this.handleErrorBlockedCompany(this.dialog, err)),
      catchError(error => {
        this.showMessage(error && error.message ?
          error.message.toUpperCase() === "INTERNAL" ? "Ops, parece que você está sem internet!" : error.message
          : "Ocorreu um erro. Tente novamente.");
        return of(error);
      })
    ).subscribe();
  }

  // Abrir o dialog para exibir os detalhes do pedido
  open(pedido: Pedido) {
    if (!this.flagMenuTrigger) {
      PedidoDetalhesDialogComponent.showDialog(this.dialog, pedido);
    }
    this.flagMenuTrigger = false;
  }

  getTempoEntregaDescricao(empresa: Empresa): string {
    if (!empresa.tempoEntrega) {
      empresa.tempoEntrega = new TempoEntrega();
    }
    return `${empresa.tempoEntrega.tempoMinimo} - ${empresa.tempoEntrega.tempoMaximo} min`;
  }

  alterarTempoEntrega(empresa: Empresa): Observable<TempoEntrega> {
    return this.service.alterarTempoEntrega(empresa);
  }

  showTotalizacao(empresas: Empresa[]): Observable<any> {
    return DetalhesEmpresaDialogComponent.openDialog(this.dialog, empresas, this.haveTaxaAdicional);
  }

  showHorariosFuncionamento(empresa: Empresa): Observable<any> {
    return HorariosFuncionamentoDialogComponent.openDialog(this.dialog, empresa);
  }

  ngOnDestroy() {
    if (this.encerrarGerenciador$) {
      this.encerrarGerenciador$.next();
      this.encerrarGerenciador$.unsubscribe();
    }

    if (this.service.pedidoPendente$ && !this.authService.user.isLojista()) {
      this.service.pedidoPendente$.next();
    }

    if (isNotNullOrUndefined(this.snackHorario)) {
      this.snackHorario.dismiss();
    }

    if (this.iniciarGerenciadorSubscriptions.length > 0) {
      this.iniciarGerenciadorSubscriptions.forEach(value => {
        if (isNotNullOrUndefined(value.subscription)) {
          value.subscription.unsubscribe();
        }
      });
    }

    if (isNotNullOrUndefined(this.setDisponivelSubscription)) {
      this.setDisponivelSubscription.unsubscribe();
    }
  }

  onClickSpanInfo() {
    this.router.navigate([]).then(() => {
      window.open("/home/faturas/list", "_blank");
    });
  }
}
