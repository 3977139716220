import {Action, AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from "@angular/fire/firestore";
import {from, Observable} from "rxjs";
import {Logg} from "../utils/logger/Logger";
import {isArray, isBoolean, isDate, isNotNullOrUndefined, isNullOrUndefined, isNumber, isObject, isString} from "../utils/commons";
import * as firebase from "firebase";
import {$query, PoMQuery} from "../services/firebase/criteria/query";
import {OrderBy} from "../services/firebase/criteria/order-by";
import {map, take} from "rxjs/operators";
import {StartAfter} from "../services/firebase/criteria/start-after";
import {Limit} from "../services/firebase/criteria/limit";
import DocumentReference = firebase.firestore.DocumentReference;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import Timestamp = firebase.firestore.Timestamp;
import {OrderByAsc} from "../services/firebase/criteria/order-by-asc";
import {OrderByDesc} from "../services/firebase/criteria/order-by-desc";

export abstract class Repository<T> {
  readonly log: Logg;

  constructor(public db: AngularFirestore,
              public collectionName: string,
              protected requiredSerialize?: boolean) {
    this.log = Logg.of(collectionName);
    this.requiredSerialize = isNullOrUndefined(this.requiredSerialize) ? false : this.requiredSerialize;
  }

  // Return a Collections
  getCollection(queryFn?: PoMQuery): AngularFirestoreCollection<T> {
    if (this.orderBy()) {
      if (!queryFn) {
        queryFn = $query(this.orderBy());
      } else {
        if (!queryFn.criterions.some(criterion => criterion instanceof OrderByAsc || criterion instanceof OrderByDesc)) {
          queryFn.addFirst(this.orderBy());
        }
      }
    }
    this.log.info("getCollection", queryFn);
    return this.db.collection<T>(this.collectionName, ref => queryFn && !queryFn.isEmpty() ? queryFn.build(ref) : ref);
  }

  protected startAfter(cursor: DocumentSnapshot): StartAfter {
    return new StartAfter(cursor);
  }

  protected limit(max: number): Limit {
    return new Limit(max);
  }

  protected orderBy(): OrderBy {
    return null;
  }

  col$(queryFn?: PoMQuery): Observable<T[]> {
    return this.getCollection(queryFn)
      .snapshotChanges()
      .pipe(
        map(docs => {
          return docs.map(a => {
            const data = a.payload.doc.data();
            data["id"] = a.payload.doc.id;
            data["ref"] = a.payload.doc.ref;
            return this.deserialize ? this.deserialize(data) : data;
          }) as T[];
        })
      );
  }

  getDoc(id: string): AngularFirestoreDocument<T> {
    return this.db.doc<T>(`${this.collectionName}/${id}`);
  }

  getById(id: string): Observable<T> {
    return this.getDoc(id).snapshotChanges()
      .pipe(
        take(1),
        map((doc: Action<DocumentSnapshot>) => {
          const data = doc.payload.data();
          data["id"] = doc.payload.id;
          data["ref"] = doc.payload.ref;
          return (this.deserialize ? this.deserialize(data) : data) as T;
        }));
  }

  getStreamById(id: string): Observable<T> {
    return this.getDoc(id).snapshotChanges().pipe(
      map((doc: Action<DocumentSnapshot>) => {
          const data = doc.payload.data();
          data["id"] = doc.payload.id;
          data["ref"] = doc.payload.ref;
          return (this.deserialize ? this.deserialize(data) : data) as T;
        }
      ));
  }

  saveOrUpdate(item: T, keepId?: boolean, merge?: boolean): Observable<void> {
    this.log.info(`saveOrUpdate()`, item);
    // Serializa o objeto recebido se a flag keepId for true
    const value = keepId ? item : this.serialize(item);

    // Verifica se tem um id
    let id = value["id"];
    if (!id) {
      id = this.createId();
      value["id"] = id;
    }
    // Verifica se tem uma referência
    if (!value["ref"]) {
      value["ref"] = this.getDoc(id).ref;
    }
    // Persistir no banco de dados
    return from(this.getDoc(id).set(keepId ? this.serialize(item) : value, {merge: merge}));
  }

  createId(): string {
    return this.db.createId();
  }

  serialize(value: any): any {
    if (this.requiredSerialize) {
      let result = {};
      //se for um array devemos percorrer todos os indices
      if (isArray(value)) {
        //percorrendo indices
        result = value.map(item => this.serialize(item));
      } else if (isObject(value)) {
        //pegando todas as chaves do objeto
        Object.keys(value).forEach(key => {
          //pegando o campo da chave atual
          const path = value[key];
          //se for qualquer tipo de dados do firebase devemos deixar passar direto
          if (path instanceof AngularFirestoreDocument || path instanceof AngularFirestoreCollection ||
            path instanceof AngularFirestore || path instanceof DocumentReference || path instanceof Timestamp) {
            result[key] = path;
          } else {
            //so iremos serializar campo não nullos
            if (isNotNullOrUndefined(path)) {
              //se o campo for algum tipo primitivo, basta apenas adicionar o mesmo no retorno
              if (isString(path) || isBoolean(path) || isDate(path) || isNumber(path)) {
                result[key] = path;
                //se for um object faz recursividade
              } else if (isObject(path)) {
                result[key] = this.serialize(path);
              } else {
                //caso contrario deixa a porra do jeito que ta
                result[key] = path;
              }
            } else {
              result[key] = null;
            }
          }
        });
      } else {
        result = value ? value : null;
      }
      return result;
    } else {
      return Object.assign({}, value);
    }
  }

  deserialize?(value: any): T;

  remove(id: string): Observable<any> {
    this.log.info(`remove(${id})`);
    const promise = this.getDoc(id).delete();
    return from(promise);
  }

  /*removeItem(item: AngularFirestoreDocument<T>): Observable<void> {
    this.log.info(`remove()`, item);
    const promise = item.delete();
    return from(promise);
  }*/

}
