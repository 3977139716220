import {Funcionamento} from "./funcionamento";
import {isNullOrUndefined} from "util";

export class Dia {

  public desc: string = "";
  public aberto: boolean = false;
  public funcionamento: Funcionamento = new Funcionamento();

  constructor(dia: any = {}) {
    if (!isNullOrUndefined(dia)) {
      this.desc = dia.desc;
      this.aberto = dia.aberto;
      this.funcionamento = new Funcionamento(dia.funcionamento);
    }
  }

  public toAny(): any {
    const dia: any = {};
    if (this.desc) dia.desc = this.desc;
    if (this.aberto) dia.aberto = this.aberto;
    if (this.funcionamento) dia.funcionamento = this.funcionamento.toAny();
    return dia;
  }
}
