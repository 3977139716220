import {isString} from "../utils/commons";

export enum StatusPedido {
  // Pedido realizado pelo cliente
  PENDENTE,
  // Pedido é aprovado pelo lojista
  APROVADO,
  // Pedido é rejeitado pelo lojista, precisa informar o motivo
  REJEITADO,
  // Pedido ficou pronto (usuário escolheu a opção de buscar no balcão)
  PRONTO,
  // Pedido saiu para entrega
  DELIVERY,
  // Pedido entregue ao cliente (seja por delivery ou no balcão)
  CONCLUIDO,
  //CONCLUIDO
  CANCELADO
}

export namespace StatusPedido {

  export function statusPedidoToString(status: StatusPedido | string): string {
    if (isString(status)) {
      status = StatusPedido[status];
    }
    switch (+status) {
      case StatusPedido.PENDENTE:
        return "Pendente";

      case StatusPedido.APROVADO:
        return "Aprovado";

      case StatusPedido.REJEITADO:
        return "Rejeitado";

      case StatusPedido.PRONTO:
        return "Pronto";

      case StatusPedido.DELIVERY:
        return "Delivery";

      case StatusPedido.CONCLUIDO:
        return "Concluído";

      case StatusPedido.CANCELADO:
        return "Cancelado";

      default:
        return "";
    }
  }

  export function styleByStatus(status) {
    switch (status) {
      case StatusPedido[StatusPedido.PENDENTE]:
        return {
          "color": "#f89406",
        };

      case StatusPedido[StatusPedido.APROVADO]:
        return {
          "color": "#4caf50",
        };

      case StatusPedido[StatusPedido.REJEITADO]:
        return {
          "color": "red",
        };

      case StatusPedido[StatusPedido.CANCELADO]:
        return {
          "color": "red",
        };

      default:
        return {
          "color": "dimgray",
        };
    }
  }

  export function markerStyleByStatus(status) {
    switch (status) {
      case StatusPedido[StatusPedido.PENDENTE]:
        return {
          "border-left-color": "#fbba13",
          "background-color": "rgba(248,148,6,0.15)"
        };

      case StatusPedido[StatusPedido.APROVADO]:
        return {
          "border-left-color": "#4caf50",
          "background-color": "rgba(76,175,80,0.15)"
        };

      case StatusPedido[StatusPedido.REJEITADO]:
        return {
          "border-left-color": "red",
          "background-color": "rgba(252,100,105,0.15)"
        };

      case StatusPedido[StatusPedido.CANCELADO]:
        return {
          "border-left-color": "red",
          "background-color": "rgba(252,100,105,0.15)"
        };

      default:
        return {};
    }
  }

}
