import {BehaviorSubject, Observable} from "rxjs";
import {finalize, map, mergeMap, tap} from "rxjs/operators";
import {PageEvent} from "@angular/material/paginator";
import {AngularFireFunctions} from "@angular/fire/functions";

export abstract class IuguService<T> {

  pageEvent$: BehaviorSubject<PageEvent> = new BehaviorSubject<PageEvent>({pageSize: 25, pageIndex: 0, length: 100});
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _totalItems: number;

  constructor(public fns: AngularFireFunctions) {}

  get totalItems(): number {
    return this._totalItems;
  }

  requestListPaginated(callableName: string, data: any): Observable<T[]> {
    return this.pageEvent$.asObservable().pipe(
      mergeMap((pageEvent: PageEvent) => {
        this.loading$.next(true);
        data.limit = pageEvent.pageSize;
        data.start = pageEvent.pageIndex * pageEvent.pageSize;
        return this.requestList(callableName, data).pipe(
          finalize(() => {
            this.loading$.next(false);
          })
        );
      })
    );
  }

  requestList(callableName: string, data: any): Observable<T[]> {
    return this.fns.httpsCallable(callableName)(data).pipe(
      tap(res => {
        this._totalItems = res.totalItems;
      }),
      map(res => {
        if (res.items) {
          return res.items.map(item => this.deserialize(item)) as T[];
        } else {
          return res.map(item => this.deserialize(item)) as T[];
        }
      })
    );
  }

  deserialize?(value: any): T;
}
