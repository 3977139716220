import { Pipe, PipeTransform } from "@angular/core";
import {isNullOrUndefined} from "util";
import {StringUtils} from "../../../utils/string-utils";

@Pipe({
  name: "filter"
})
export class FilterPipe implements PipeTransform {

  /**
   * Recebe a lista de objetos e o path com o caminho do campo que será filtrado dentro do objeto
   * Exemplo do caminho:
   * {
   *   a: {
   *     b: {
   *       foo: "bar"
   *     }
   *   }
   * }
   *
   * Caminho para acessar o campo foo: ["a", "b", "foo"]
   *
   * @param values: lista de objetos para fazer o filtro
   * @param path: caminho do campo dentro do objeto em um array
   * @param term: termo de pesquisa
   * @returns {any}
   */
  transform(values: any, path: string[], term: any): any {
    // Checar se o value é undefined
    if (isNullOrUndefined(values) || isNullOrUndefined(term)) {
      return values;
    } else {
      return values.filter((value) => {
        return StringUtils.normalize(path.reduce((o, n) => o[n], value)).toLowerCase().includes(StringUtils.normalize(term).toLowerCase());
      });
    }
  }

}

@Pipe({
  name: "filterOr"
})
export class FilterPipeOr implements PipeTransform {
  transform(values: any, paths: string[][], term: any): any {
    if (isNullOrUndefined(values) || isNullOrUndefined(term)) {
      return values;
    } else {
      return values.filter(value => {
        return paths.map(path => {
          return StringUtils.normalize(path.reduce((o, n) => o[n], value).replace(/[.-]+/g, "").toLowerCase()).includes(StringUtils.normalize(term.replace(/[\.-]+/g, "").toLowerCase()));
        }).reduce((o, n) => o || n);
      });
    }
  }

}
