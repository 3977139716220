import {Component, Inject, OnInit} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {Empresa} from "../../../models/empresa";
import {Observable} from "rxjs";
import {isNotNullOrUndefined} from "../../../utils/commons";
import {GerenciadorPedidosService} from "../service/gerenciador-pedidos.service";
import * as moment from "moment";

@Component({
  selector: "app-horarios-funcionamento-dialog.component.ts",
  templateUrl: "./horarios-funcionamento-dialog.component.html",
  styleUrls: ["./horarios-funcionamento-dialog.component.scss"]
})
export class HorariosFuncionamentoDialogComponent implements OnInit {

  static openDialog(dialog: MatDialog, empresa: Empresa): Observable<any> {
    const config = {
      width: "400px",
      data: {
        empresa: empresa
      }
    };

    return dialog.open(HorariosFuncionamentoDialogComponent, config).afterClosed();
  }

  empresa: Empresa;

  constructor(public dialogRef: MatDialogRef<HorariosFuncionamentoDialogComponent>,
              public service: GerenciadorPedidosService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.empresa = data["empresa"];
  }

  ngOnInit(): void {
  }

  getDias(): string[] {
    const sorter = {
      "domingo": 1,
      "segunda": 2,
      "terca": 3,
      "quarta": 4,
      "quinta": 5,
      "sexta": 6,
      "sabado": 7
    };
    return Object.keys(this.empresa.horario).sort((a,b) => {
      return sorter[a] - sorter[b];
    });
  }

  doisHorarios(empresa: Empresa, dia: string): boolean {
    const horarioDeFunc = this.service.getHorarioDeFuncionamento(empresa, dia);

    if (horarioDeFunc) {
      return isNotNullOrUndefined(horarioDeFunc.aberturaManha) && isNotNullOrUndefined(horarioDeFunc.fechamentoManha)
        && isNotNullOrUndefined(horarioDeFunc.aberturaTarde) && isNotNullOrUndefined(horarioDeFunc.fechamentoTarde);
    } else {
      return false;
    }
  }

  isDiaAtual(dia: string): boolean {
    const dias = ["domingo", "segunda", "terca", "quarta", "quinta", "sexta", "sabado"];
    const diaAtual = dias[moment().toDate().getDay()];

    return dia === diaAtual;
  }

  getHorarioDeFuncionamento(empresa: Empresa, dia: string): string {
    const horarioDeFuncionamento = this.service.getHorarioDeFuncionamento(empresa, dia);

    if (horarioDeFuncionamento) {
      const hasAllHorario = Object.keys(horarioDeFuncionamento).map(key => horarioDeFuncionamento[key]).every(res => isNotNullOrUndefined(res));
      let horarioManha: string = "";
      let horarioTarde: string = "";

      if (isNotNullOrUndefined(horarioDeFuncionamento.aberturaManha) && isNotNullOrUndefined(horarioDeFuncionamento.fechamentoManha)) {
        horarioManha = horarioDeFuncionamento.aberturaManha + " às " + horarioDeFuncionamento.fechamentoManha;
      }

      if (isNotNullOrUndefined(horarioDeFuncionamento.aberturaTarde) && isNotNullOrUndefined(horarioDeFuncionamento.fechamentoTarde)) {
        horarioTarde = horarioDeFuncionamento.aberturaTarde + " às " + horarioDeFuncionamento.fechamentoTarde;
      }

      return horarioManha + (hasAllHorario ? " - " : "") + horarioTarde;

    } else {
      return "Fechado";
    }
  }

  getHorarioDeFuncionamentoManha(empresa: Empresa, dia: string): string {
    const horarioDeFuncionamento = this.service.getHorarioDeFuncionamento(empresa, dia);
    if (horarioDeFuncionamento) {
      return horarioDeFuncionamento.aberturaManha + " às " + horarioDeFuncionamento.fechamentoManha;
    } else {
      return "Fechado";
    }
  }

  getHorarioDeFuncionamentoTarde(empresa: Empresa, dia: string): string {
    const horarioDeFuncionamento = this.service.getHorarioDeFuncionamento(empresa, dia);
    if (horarioDeFuncionamento) {
      return horarioDeFuncionamento.aberturaTarde + " às " + horarioDeFuncionamento.fechamentoTarde;
    } else {
      return "Fechado";
    }
  }

  formatarHorario(dia: string): string {
    return dia ? this.empresa.horario[dia].desc : "";
  }

}
