import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs/Rx";
import {Extrato, StatusSaque, TipoExtrato, tipoExtratoToString} from "../../../models/extrato.model";
import {StatusInvoice} from "../../../models/iugu/invoice.iugu.model";
import * as moment from "moment";
import {Moment} from "moment";
import {isEmpty, isNotNullOrUndefined} from "../../../utils/commons";
import {AuthService} from "../../../modules/login/auth.service";
import {EmpresaService} from "../../empresa/empresa.service";
import {query} from "@angular/animations";
import {$query} from "../../../services/firebase/criteria/query";
import {Criterion} from "../../../services/firebase/criteria/criterion";
import {mergeMap} from "rxjs/operators";
import {of} from "rxjs/observable/of";

@Component({
  selector: "app-extrato-detalhes-dialog",
  templateUrl: "./extrato-detalhes-dialog.component.html",
  styleUrls: ["./extrato-detalhes-dialog.component.scss"]
})
export class ExtratoDetalhesDialogComponent implements OnInit {

  static showDialog(dialog: MatDialog, extrato: Extrato, isGlobal?: boolean): Observable<any> {
    const config = {
      width: extrato.tipo === TipoExtrato[TipoExtrato.pedido] ? "500px" : "450px",
      data: extrato
    };
    return dialog.open(ExtratoDetalhesDialogComponent, config).afterClosed();
  }

  TipoExtrato = TipoExtrato;
  StatusInvoice = StatusInvoice;
  StatusSaque = StatusSaque;
  isNotNullOrUndefined = isNotNullOrUndefined;
  tipoExtratoToString= tipoExtratoToString;
  accountData$: Observable<any>;

  constructor(public dialogRef: MatDialogRef<ExtratoDetalhesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Extrato,
              public auth: AuthService,
              public empresaService: EmpresaService) {
    if (this.data.tipo === TipoExtrato[TipoExtrato.saque]) {
      this.accountData$ = this.empresaService.col$(
        $query(new Criterion("pagamentoOnline.accountId", "==", this.data.accountId)))
        .pipe(
          mergeMap((empresas) => {
            if (isEmpty(empresas)) {
              // Buscar pelo oldAccounts do Pagamento Online
              return this.empresaService.col$(
                $query(new Criterion("pagamentoOnline.oldAccounts", "array-contains", {accountId: this.data.accountId}))
              );
            }
            return of(empresas);
          }),
          mergeMap((empresas) => {
            if (empresas.length > 0) {
              return of({
                razaoSocial: empresas[0].razaoSocial,
                empresas: empresas
              });
            } else {
              return of([]);
            }
          })
        );
    }
  }

  ngOnInit() {
  }

  timestampToMoment(data): Moment {
    return moment(data.toDate());
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
