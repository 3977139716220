import {Component, EventEmitter, OnInit, Output, Input, OnChanges, SimpleChanges} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {Produto} from "../../../../models/produto.model";
import {MatOptionSelectionChange} from "@angular/material/core";
import {debounceTime} from "rxjs/operators";
import {ProdutoService} from "../../../../components/produto/produto.service";

@Component({
  selector: "app-produto-select",
  templateUrl: "./produto-select.component.html",
  styleUrls: ["./produto-select.component.scss"]
})
export class ProdutoSelectComponent implements OnInit, OnChanges {
  public produtoControl = new FormControl("");
  public produtos$: Observable<Produto[]>;
  public produto: Produto;

  @Output()
  public produtoSelecionado = new EventEmitter();

  @Input()
  public empresaId: string;

  @Input()
  public produtoId: string;

  @Input()
  public disable: boolean;

  @Input()
  public required: boolean = false;

  @Input()
  set removeProduto(error: boolean) {
    if (error) {
      this.produto = null;
      this.produtoControl.setValue("");
      this.produtoSelecionado.emit(null);
    }
  }

  constructor(private produtoService: ProdutoService) {
  }

  ngOnInit() {
    this.loadProducts();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.produtoId) {
      this.carregarProduto(this.produtoId);
    }
    if (this.disable) {
      this.produtoControl.disable();
    }

    if (this.required) {
      this.produtoControl.setValidators(Validators.required);
    }
  }

  public onProdutoChanges(event: MatOptionSelectionChange, produto: Produto): void {
    if (event.isUserInput) {
      this.produto = produto;
    } else {
      this.produto = null;
    }
    this.produtoSelecionado.emit(this.produto);
  }


  private carregarProduto(produtoId: string): void {
    this.produtoService.getById(produtoId).subscribe((produto: Produto) => {
      this.produtoControl.patchValue(produto.nome);
      this.produtoSelecionado.emit(produto);
    });
  }

  loadOnFirstFocus(): void {
    if (!this.produto && !this.produtoControl.value) {
      this.produtos$ = this.produtoService.getProdutosByEmpresaENome(this.empresaId, "");
    }
  }

  private loadProducts(): void {
    this.produtoControl.valueChanges.pipe(debounceTime(500)).subscribe((value: string) => {
      this.produtos$ = this.produtoService.getProdutosByEmpresaENome(this.empresaId, value);
    });
  }
}
