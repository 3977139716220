import {OrderBy} from "./order-by";
import {Operators} from "./operators";

export class OrderByAsc extends OrderBy {
  constructor(value: string | string[]);
  constructor(value?: any) {
    super(Operators.ORDER_BY_ASC, value);
  }
}

export function $orderByAsc(value: string | string[]);
export function $orderByAsc(value?: any): OrderByAsc {
  return new OrderByAsc(value);
}
