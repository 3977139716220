import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: "app-no-records-found",
  templateUrl: "./no-records-found.component.html",
  styleUrls: ["./no-records-found.component.scss"]
})
export class NoRecordsFoundComponent implements OnInit {

  @Input()
  records: any;

  @Input()
  public titulo: string = "Nada por aqui";

  @Input()
  public subtitulo: string = "Nenhum registro encontrado!";

  @Input()
  public icone: string = "receipt";

  constructor() {
  }

  ngOnInit() {
  }

}
