import {AngularFirestore} from "@angular/fire/firestore";
import {Injectable, NgZone} from "@angular/core";
import {environment} from "../../../environments/environment";

@Injectable()
export class AngularFirestoreSecondary extends AngularFirestore {

  static secondaryInstanceName: string = "secondary";

}

export function AngularFirestoreSecondaryFactory(platformId: Object, zone: NgZone): AngularFirestoreSecondary {
  return new AngularFirestoreSecondary(environment.firebase, AngularFirestoreSecondary.secondaryInstanceName, false, null, platformId, zone, null);
}
