import {FireModel} from "./fire.model";

export class Cidade extends FireModel {

  public nome: string;
  public uf: string;
  public consultores: string[];

  constructor(cidade?: any) {
    super();
    if (cidade) {
      this.id = cidade.id;
      this.ref = cidade.ref;
      this.nome = cidade.nome;
      this.uf = cidade.uf;
      this.consultores = cidade.consultores ? cidade.consultores : [];
      if (cidade.consultorId) {
        this.consultores.push(cidade.consultorId);
      }
    }
  }

  toAny(): any {
    return Object.assign({}, this);
  }

}
