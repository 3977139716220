import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {Observable} from "rxjs/Rx";
import {PedidosService} from "../../services/pedidos.service";
import {Repository} from "../../repository/Repository";
import {Pedido} from "../../models/pedido.model";
import {AuthService} from "../../modules/login/auth.service";
import {OrderBy} from "../../services/firebase/criteria/order-by";
import {OrderByDesc} from "../../services/firebase/criteria/order-by-desc";
import {PoMQuery} from "../../services/firebase/criteria/query";
import {Criterion} from "../../services/firebase/criteria/criterion";
import {mergeMap} from "rxjs/operators";
import {AppUser} from "../../models/appUser";
import {Limit} from "../../services/firebase/criteria/limit";

@Injectable()
export class MonitorPedidosService extends Repository<Pedido> {

  constructor(public db: AngularFirestore,
              public auth: AuthService,
              public pedidosService: PedidosService) {
    super(db, "pedidos", true);
  }

  getAllPedidos(queryFn: PoMQuery = new PoMQuery()): Observable<Pedido[]> {
    return this.auth.currentUser.pipe(mergeMap((user: AppUser) => {
      const limitPedidos = new Limit(500);
      if (user.isAdministrador()) {
        return this.pedidosService.getAllPedidos(queryFn.add(limitPedidos));
      } else if (user.isConsultorOrAssistente()) {
        return this.pedidosService.getAllPedidos(queryFn.add(new Criterion("empresa.consultor.id", "==", user.isConsultor() ? user.id : user.consultorId)).add(limitPedidos));
      }
    }));
  }

  protected orderBy(): OrderBy {
    return new OrderByDesc("data");
  }
}
