import {Injectable} from "@angular/core";
import {Repository} from "../../repository/Repository";
import {AppUser} from "../../models/appUser";
import {AngularFirestore} from "@angular/fire/firestore";
import {from, Observable} from "rxjs";
import {$query} from "../../services/firebase/criteria/query";
import {Criterion} from "../../services/firebase/criteria/criterion";
import {map, take} from "rxjs/operators";
import * as firebase from "firebase";
import FieldValue = firebase.firestore.FieldValue;

@Injectable({
  providedIn: "root"
})
export class UsersAuthService extends Repository<AppUser> {

  constructor(private dataBase: AngularFirestore) {
    super(dataBase, "users", true);
  }

  public findByEmail(email: string): Observable<AppUser> {
    return this.col$($query(new Criterion("email", "==", email))).pipe(
      take(1),
      map(users => (users && users.length > 0 ? users[0] : undefined))
    );
  }

  public setLastLogin(user: AppUser): Observable<void> {
    return from(this.dataBase
      .collection(this.collectionName)
      .doc(user.id)
      .update({
        lastLogin: FieldValue.serverTimestamp()
      }));
  }

  deserialize(value: any): AppUser {
    return new AppUser(value);
  }
}
