import {Component, Inject, OnInit} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {Observable} from "rxjs";
import {EmpresaService} from "../../empresa/empresa.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../modules/login/auth.service";
import {Empresa, TempoEntrega} from "../../../models/empresa";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {tap} from "rxjs/internal/operators";

@Component({
  selector: "app-tempo-entrega-dialog",
  templateUrl: "./tempo-entrega-dialog.component.html",
  styleUrls: ["./tempo-entrega-dialog.component.scss"]
})
export class TempoEntregaDialogComponent implements OnInit {

  static openDialog(dialog: MatDialog, tempoEntrega: TempoEntrega, empresa: Empresa): Observable<any> {
    const config = {
      width: "400px",
      data: {
        empresa: empresa,
        tempoEntrega: tempoEntrega
      }
    };

    return dialog.open(TempoEntregaDialogComponent, config).afterClosed();
  }

  // Formulário
  form: FormGroup;

  // Tempo de entrega
  readonly tempoEntrega = new TempoEntrega();
  empresa: Empresa;

  constructor(public dialogRef: MatDialogRef<TempoEntregaDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              formBuilder: FormBuilder,
              public authService: AuthService,
              public dialog: DialogService,
              public loading: LoadingService,
              public empresaService: EmpresaService) {

    // Receber o tempo de entrega
    const tempoEntrega = data["tempoEntrega"];

    this.empresa = data["empresa"];

    // Mostrar os dados
    this.form = formBuilder.group({
      minimo: [tempoEntrega.tempoMinimo, [Validators.required, Validators.min(10)]],
      maximo: [tempoEntrega.tempoMaximo, [Validators.required, Validators.max(120)]]
    });
  }

  ngOnInit() {
  }

  confirmar() {
    this.tempoEntrega.tempoMinimo = this.form.get("minimo").value;
    this.tempoEntrega.tempoMaximo = this.form.get("maximo").value;
    if (this.tempoEntrega.tempoMaximo < this.tempoEntrega.tempoMinimo) {
      this.dialog.messageDialog()
        .message("O tempo máximo não pode ser menor que o tempo mínimo!")
        .show();
      return;
    }
    this.loading.showTopBar();
    this.empresaService.alterarTempoAtendimento(this.empresa, this.tempoEntrega)
      .pipe(tap(() => this.loading.hideTopBar()))
      .subscribe(() => {
        // Fechar o dialog e retonar o tempo de entrega
        this.dialogRef.close(this.tempoEntrega);
      }, () => {
        this.dialog.messageDialog()
          .message("Erro ao mudar o tempo de atendimento!")
          .show();
      });
  }
}
