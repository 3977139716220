import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {Pedido, TipoPedido, TipoPedidoToString} from "../../../models/pedido.model";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PedidosService} from "../../../services/pedidos.service";
import {PageService} from "../../../services/page.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {$query} from "../../../services/firebase/criteria/query";
import {Criterion} from "../../../services/firebase/criteria/criterion";
import * as moment from "moment";
import {isEmpty, isNotNullOrUndefined} from "../../../utils/commons";
import {finalize, map, tap} from "rxjs/operators";
import {StatusPedido} from "../../../models/status-pedido.enum";
import {Observable} from "rxjs";
import {Empresa} from "../../../models/empresa";
import {EmpresaService} from "../../empresa/empresa.service";
import {Subscription} from "rxjs/Rx";
import {AuthService} from "../../../modules/login/auth.service";
import {AppUser} from "../../../models/appUser";
import {PedidoDetalhesDialogComponent} from "../../gerenciador-pedidos/pedido-detalhes-dialog/pedido-detalhes-dialog.component";
import {CidadeService} from "../../cidade/cidade.service";
import {GeradorDePdfService} from "../../../services/pdf/gerador-de-pdf.service";
import {PdfRelatorioFiltros} from "../../../services/pdf/gerador-pdf-relatorio-filtros";
import {TipoPagamento} from "../../../models/forma-pagamento.model";
import {Cidade} from "../../../models/cidade.model";

class DadosPDF {
  dataInicial;
  dataFinal;
  status;
  empresa;
  tipo;
  numero;
  tipoPagamento;
  cidade;
}

@Component({
  selector: "app-relatorio-pedidos-list",
  templateUrl: "./relatorio-pedidos-geral-list.component.html",
  styleUrls: ["./relatorio-pedidos-geral-list.component.scss"]
})
export class RelatorioPedidosGeralListComponent extends PageService<Pedido> implements OnInit, OnDestroy {

  selectedEmpresa: Empresa;
  cidades$: Observable<Cidade[]>;
  readonly statusTodos: string = "Todos";
  readonly statusTodas: string = "Todas";
  form: FormGroup;
  tipoPedidoToString = TipoPedidoToString;
  TipoPagamento = TipoPagamento;
  StatusPedido = StatusPedido;
  // Lista de Pedidos
  pedidos: Pedido[];
  // Totalização dos pedidos
  valorTotalProdutos: number = 0;
  valorTotalPedidos: number = 0;
  valorTotalPoMs: number = 0;
  valorDelivery: number = 0;
  valorDeliveryPQC: number = 0;
  valorAReceber: number = 0;
  valorPQCEntregaLojista: number = 0;
  valorPQCEntregaCliente: number = 0;
  qtdePQCEntregaLojista: number = 0;
  // Subscription de pedidos
  pedidosSubscription: Subscription;
  userSubscription: Subscription;
  loggedUser: AppUser;
  // Status
  readonly statusList: { key: string, value: string }[] = [];
  showAdvancedFilters: boolean = false;
  disableButton: boolean = false;
  listHeight: number;
  triggerOnChanges: boolean = false;
  dadosPDF: DadosPDF = new DadosPDF();

  constructor(service: PedidosService,
              public authService: AuthService,
              empresaService: EmpresaService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              public formBuilder: FormBuilder,
              public cidadeService: CidadeService,
              public geradorDePdfService: GeradorDePdfService) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/pedidos/");

    this.form = this.formBuilder.group({
      dataInicial: [new Date()],
      dataFinal: [new Date()],
      status: [this.statusTodos],
      tipo: [this.statusTodos],
      empresa: [null],
      cidade: [],
      numero: [],
      tipoPagamento: [this.statusTodos]
    });

    this.cidades$ = this.cidadeService.col$();

    // Carregar os status
    Object.keys(StatusPedido).forEach(item => {
      const isValueProperty = parseInt(item, 10) >= 0;
      if (isValueProperty) {
        if (StatusPedido[item] === StatusPedido[StatusPedido.REJEITADO] || StatusPedido[item] === StatusPedido[StatusPedido.CANCELADO]) {
          this.statusList.push({key: StatusPedido[item], value: StatusPedido.statusPedidoToString(StatusPedido[item])});
        }
      }
    });

    const dataInicialFormControl = this.form.get("dataInicial");
    const dataFinalFormControl = this.form.get("dataFinal");

    dataInicialFormControl.valueChanges
      .subscribe(dataInicial => {
        const dataFinal = dataFinalFormControl.value;
        if (dataInicial > dataFinal) {
          dataFinalFormControl.patchValue(dataInicial);
        }
      });

  }

  ngOnInit() {
    // Enfim, buscar os pedidos
    this.userSubscription = this.authService.currentUser
      .pipe(tap(user => {
        this.loggedUser = user;
      })).subscribe(() => this.buscarPedidos());
  }

  tiposKeys(): Array<string> {
    return Object.keys(TipoPedido);
  }

  compareFn(c1: TipoPedido, c2: TipoPedido): boolean {
    return c1 && c2 && TipoPedido[c1] === TipoPedido[c2];
  }

  compareCidade(c1: Cidade, c2: Cidade): boolean {
    return c1 && c2 && c1.id === c2.id;
  }

  newItem(): Pedido {
    return null;
  }

  buscarPedidos() {

    // Atualizar variáveis Form
    this.dadosPDF.dataInicial = this.form.get("dataInicial").value;
    this.dadosPDF.dataFinal = this.form.get("dataFinal").value;
    this.dadosPDF.status = this.form.get("status").value;
    this.dadosPDF.empresa = this.form.get("empresa").value;
    this.dadosPDF.tipo = this.form.get("tipo").value;
    this.dadosPDF.numero = this.form.get("numero").value;
    this.dadosPDF.tipoPagamento = this.form.get("tipoPagamento").value;
    this.dadosPDF.cidade = this.form.get("cidade").value;

    this.disableButton = true;
    this.loading(true);

    // Filtros
    const dataInicial = moment(this.form.get("dataInicial").value).startOf("day").toDate();
    const dataFinal = moment(this.form.get("dataFinal").value).endOf("day").toDate();
    let status = this.form.get("status").value;
    let tipo = this.form.get("tipo").value;
    const empresa = this.selectedEmpresa;
    let cidade = this.form.get("cidade").value;
    const numero = this.form.get("numero").value ? this.form.get("numero").value.toString() : this.form.get("numero").value;
    let tipoPagamento = this.form.get("tipoPagamento").value;

    status = status === this.statusTodos ? undefined : status;
    tipo = tipo === this.statusTodos ? undefined : tipo;
    cidade = cidade === this.statusTodas ? undefined : cidade;
    tipoPagamento = tipoPagamento === this.statusTodos ? undefined : tipoPagamento;

    // Montar o filtro
    const filters = $query(new Criterion("data", ">=", dataInicial), new Criterion("data", "<=", dataFinal));
    if (isNotNullOrUndefined(status) && status !== StatusPedido[StatusPedido.APROVADO]) {
      filters.add(new Criterion("status", "==", status));
    }

    if (isNotNullOrUndefined(tipo)) {
      if (tipo === TipoPedido[TipoPedido.BALCAO]) {
        filters.add(new Criterion("buscarNoBalcao", "==", true));
      } else if (tipo === TipoPedido[TipoPedido.DELIVERY]) {
        filters.add(new Criterion("buscarNoBalcao", "==", false));
      }
    }

    if (isNotNullOrUndefined(empresa)) {
      filters.add(new Criterion("empresa.id", "==", empresa.id));
    } else {
      if (this.loggedUser.isConsultorOrAssistente()) {
        filters.add(
          new Criterion("empresa.consultor.id", "==", this.loggedUser.isConsultor() ? this.loggedUser.id : this.loggedUser.consultorId)
        );
      }
    }

    if (isNotNullOrUndefined(cidade)) {
      filters.add(new Criterion("empresa.localizacao.endereco.cidade", "==", cidade.nome));
    }

    if (isNotNullOrUndefined(tipoPagamento)) {
      if (tipoPagamento === "online") {
        filters.add(new Criterion("formaPagamento.tipoPagamento", "==", TipoPagamento[TipoPagamento.online]));
      }
    }

    if (!isEmpty(numero)) {
      // Se o filtro for por número, então ignora todos os demais filtros
      filters.clear();
      filters.add(new Criterion("numero", "==", numero));
    }


    // Limpar a lista de pedidos
    this.pedidos = [];

    // Zerar a totalização
    this.valorTotalProdutos = 0;
    this.valorTotalPedidos = 0;
    this.valorTotalPoMs = 0;
    this.valorDelivery = 0;
    this.valorDeliveryPQC = 0;
    this.valorAReceber = 0;
    this.valorPQCEntregaCliente = 0;
    this.valorPQCEntregaLojista = 0;
    this.qtdePQCEntregaLojista = 0;

    // Buscar os pedidos
    this.pedidosSubscription = (this.service as PedidosService).col$(filters)
      .take(1)
      .pipe(
        map(pedidos => {
          if (isNotNullOrUndefined(tipoPagamento) && tipoPagamento === "entrega") {
            pedidos = pedidos.filter(pedido => {
              return pedido.formaPagamento.tipoPagamento !== TipoPagamento[TipoPagamento.online];
            });
          }
          if (status === StatusPedido[StatusPedido.APROVADO]) {
            return pedidos.filter(pedido => {
              return !(pedido.status === StatusPedido[StatusPedido.CANCELADO] || pedido.status === StatusPedido[StatusPedido.REJEITADO]);
            });
          } else {
            return pedidos;
          }
        }),
        tap(pedidos => {
          // Totalizar os valores
          pedidos.forEach((pedido: Pedido) => {
            if (pedido.status !== StatusPedido[StatusPedido.REJEITADO] && pedido.status !== StatusPedido[StatusPedido.CANCELADO]) {
              this.valorTotalProdutos += pedido.subtotal;
              this.valorTotalPedidos += pedido.total;
              this.valorTotalPoMs += pedido.pontos;
              if (pedido.pedeQueChega) {
                if (isNotNullOrUndefined(pedido.valorEntregaCancelada) && pedido.valorEntregaCancelada > 0) {
                  this.valorDelivery += pedido.valorEntrega;
                } else {
                  this.valorDeliveryPQC += pedido.valorEntrega;
                  this.valorPQCEntregaCliente += pedido.valorEntrega;
                  if (isNotNullOrUndefined(pedido.valorEntregaLojista)) {
                    this.valorDeliveryPQC += pedido.valorEntregaLojista;
                    this.valorPQCEntregaLojista += pedido.valorEntregaLojista;
                    if (pedido.valorEntregaLojista > 0) {
                      this.qtdePQCEntregaLojista++;
                    }
                  }
                }
              } else {
                this.valorDelivery += pedido.valorEntrega;
              }
            }

            // Verificar se o pedido não está CANCELADO ou REJEITADO
            this.valorAReceber += pedido.getValorAPagar();
          });
        }),
        finalize(() => {
          this.disableButton = false;
          this.loading(false);
        })
      )
      .subscribe(values => {
        this.pedidos = values;
      }, err => {
        this.dialogService
          .messageDialog()
          .title("Atenção")
          .message(err)
          .show();
      });
  }

  open(pedido: Pedido) {
    PedidoDetalhesDialogComponent.showDialog(this.dialog, pedido, false, true).subscribe(result => {
      if (result) {
        this.buscarPedidos();
      }
    });
  }

  gerarPdf() {
    const filtrosRelatorio: PdfRelatorioFiltros = new PdfRelatorioFiltros();

    //Formatar preço para BRL
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    });

    filtrosRelatorio.empresaNome = this.dadosPDF.empresa;
    filtrosRelatorio.dataInicialSimples = String(moment(this.dadosPDF.dataInicial).format("DD/MM/YYYY"));
    filtrosRelatorio.dataFinalSimples = String(moment(this.dadosPDF.dataFinal).format("DD/MM/YYYY"));
    filtrosRelatorio.totalPreco = String(formatter.format(this.valorTotalProdutos));
    filtrosRelatorio.totalEntrega = this.valorDelivery;
    filtrosRelatorio.totalPQC = this.valorDeliveryPQC;
    filtrosRelatorio.totalPoMs = this.valorTotalPoMs.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2});
    filtrosRelatorio.totalValor = String(formatter.format(this.valorTotalPedidos));
    filtrosRelatorio.tipoPedidos = this.dadosPDF.tipo;
    filtrosRelatorio.statusPedidos = this.dadosPDF.status;
    filtrosRelatorio.tipoPagamento = this.dadosPDF.tipoPagamento;
    filtrosRelatorio.numeroPedidos = this.dadosPDF.numero;
    filtrosRelatorio.relatorioGeral = true;
    filtrosRelatorio.valorEntregaCliente = this.valorPQCEntregaCliente;
    filtrosRelatorio.valorEntregaLojista = this.valorPQCEntregaLojista;
    const _cidadePedidos = this.dadosPDF.cidade;
    if (isNotNullOrUndefined(_cidadePedidos)) {
      filtrosRelatorio.cidadePedidos = _cidadePedidos.nome;
    }

    this.geradorDePdfService.gerarPdfRelatorio(this.pedidos, filtrosRelatorio);
  }

  pedeQueChegaInfoTooltip():string {
    const moneyFormat = new Intl.NumberFormat("pt", {style: "currency", currency: "BRL"});
    if (this.valorPQCEntregaCliente > 0) {
      return `${moneyFormat.format(this.valorPQCEntregaCliente)} pago pelo cliente | ${moneyFormat.format(this.valorPQCEntregaLojista)} pago pela empresa`;
    } else {
      return `${moneyFormat.format(this.valorPQCEntregaLojista)} pago pela empresa`;
    }
  }

  scrollableListChanged($event: number) {
    this.listHeight = $event;
    this.cdRef.detectChanges();
  }

  toggleFilters() {
    this.showAdvancedFilters = !this.showAdvancedFilters;
    setTimeout(() => {
      this.triggerOnChanges = !this.triggerOnChanges;
    }, 250);
  }

  ngOnDestroy() {
    if (this.pedidosSubscription) {
      this.pedidosSubscription.unsubscribe();
    }
  }

  public receiveEmpresa(empresa: Empresa): void {
    this.selectedEmpresa = empresa;
  }
}
