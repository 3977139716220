import {Injectable} from "@angular/core";
import {ComplementoProduto, ComplementosProdutoByGrupo, groupComplementosProdutoByGrupo} from "../../models/complemento-produto.model";
import {AngularFirestore} from "@angular/fire/firestore";
import {OrderByAsc} from "../../services/firebase/criteria/order-by-asc";
import {RepositoryByEmpresa} from "../../repository/repository-by-empresa";
import {AuthService} from "../../modules/login/auth.service";
import {OrderBy} from "../../services/firebase/criteria/order-by";
import {Observable, of, zip} from "rxjs";
import {catchError, combineAll, groupBy, map, mergeMap, take} from "rxjs/operators";
import {AngularFireFunctions} from "@angular/fire/functions";
import {isNotNullOrUndefined} from "../../utils/commons";

@Injectable()
export class ComplementoProdutoService extends RepositoryByEmpresa<ComplementoProduto> {

  constructor(dataBase: AngularFirestore, auth: AuthService, public fns: AngularFireFunctions) {
    super(dataBase, auth, "complementos-produto", true);
  }

  getGrupos(): Observable<string[]> {
    return this.col$().pipe(
      take(1),
      mergeMap(res => res),
      groupBy(complemento => complemento.grupo),
      mergeMap(group => zip(of(group.key))),
      combineAll(),
      map(key => key.filter(_key => isNotNullOrUndefined(_key)))
    );
  }

  getComplementosProdutoByGrupo(): Observable<ComplementosProdutoByGrupo[]> {
    return super.col$().pipe(
      take(1),
      mergeMap(complementosProduto => groupComplementosProdutoByGrupo(complementosProduto))
    );
  }

  remove(id: string): Observable<void> {
    return this.fns.httpsCallable("call-complementos-remove")({id: id});
  }

  changeInactive(complemento: ComplementoProduto): Observable<any> {
    return this.fns.httpsCallable("call-complementos-changeInactive")({
      complemento: {id: complemento.id}
    }).pipe(
      catchError(err => this.handleCallableFunctionsError(err))
    );
  }

  deserialize(value: any): ComplementoProduto {
    return new ComplementoProduto(value);
  }

  protected orderBy(): OrderBy {
    return new OrderByAsc("nome");
  }
}
