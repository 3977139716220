import {FireModel} from "./fire.model";
import * as firebase from "firebase";
import {Empresa} from "./empresa";
import {isNullOrUndefined} from "../utils/commons";
import Timestamp = firebase.firestore.Timestamp;

export class Abatimento extends FireModel {
  empresa: Empresa;
  data: Timestamp;
  valor: number;
  motivo: string;
  lastUpdate: any;
  canUpdate: boolean;
  userId: string;
  comissaoId: string;
  logs: LogAbatimento[] = [];
  tipo: string;
  emiteNF: boolean;

  constructor(abatimento: any = {}) {
    super(abatimento.id, abatimento.ref);
    this.empresa = abatimento.empresa;
    this.data = abatimento.data;
    this.valor = abatimento.valor;
    this.motivo = abatimento.motivo;
    this.lastUpdate = abatimento.lastUpdate;
    this.canUpdate = isNullOrUndefined(abatimento.canUpdate) ? true : abatimento.canUpdate;
    this.userId = abatimento.userId;
    this.comissaoId = abatimento.comissaoId;
    if (abatimento.logs) {
      abatimento.logs.forEach(log => this.logs.push(new LogAbatimento(log)));
    }

    this.tipo = abatimento.tipo;
    this.emiteNF = !isNullOrUndefined(abatimento.emiteNF) ? abatimento.emiteNF : true;
  }
}

export class LogAbatimento {
  public empresaId: string;
  public motivo: string;
  public valor: number;
  public data: Timestamp;
  public updatedAt: any;
  public userId: string;

  constructor(log?: any) {
    this.empresaId = log.empresaId;
    this.motivo = log.motivo;
    this.valor = log.valor;
    this.data = log.data;
    this.updatedAt = log.updatedAt;
    this.userId = log.userId;
  }
}

export enum TipoAbatimento {
  PROMOCAO_PRODUTO = "PROMOCAO_PRODUTO",
  ENTREGA_GRATIS = "ENTREGA_GRATIS",
  COMPENSACAO = "COMPENSACAO",
  AMORTIZACAO = "AMORTIZACAO",
  CHARGEBACK = "CHARGEBACK",
  OUTROS = "OUTROS",
}

export function TipoAbatimentoToString(tipo: string): string {
  switch (tipo) {
    case TipoAbatimento.PROMOCAO_PRODUTO:
      return "Produto Promocional";
    case TipoAbatimento.ENTREGA_GRATIS:
      return "Entrega Grátis";
    case TipoAbatimento.COMPENSACAO:
      return "Compensação";
    case TipoAbatimento.AMORTIZACAO:
      return "Amortização";
    case TipoAbatimento.CHARGEBACK:
      return "Chargeback";
    case TipoAbatimento.OUTROS:
      return "Outros";
  }
}
