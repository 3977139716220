import {Injectable} from "@angular/core";
import {Repository} from "../../repository/Repository";
import {Log, PedeQueChega} from "../../models/pedequechega.model";
import {AngularFirestore} from "@angular/fire/firestore";
import {AuthService} from "../../modules/login/auth.service";
import {Observable} from "rxjs";
import {OrderBy} from "../../services/firebase/criteria/order-by";
import {OrderByDesc} from "../../services/firebase/criteria/order-by-desc";
import {Entregador} from "../../models/entregador.model";
import * as firebase from "firebase";
import {firestore} from "firebase";
import {isNullOrUndefined} from "util";
import {$query} from "../../services/firebase/criteria/query";
import {Criterion} from "../../services/firebase/criteria/criterion";
import {mergeMap} from "rxjs/operators";
import Timestamp = firebase.firestore.Timestamp;

@Injectable()
export class CentralEntregasService extends Repository<PedeQueChega> {

  constructor(public db: AngularFirestore, public auth: AuthService) {
    super(db, "pedeQueChega", true);
  }

  orderBy(): OrderBy {
    return new OrderByDesc("data");
  }

  save(item: PedeQueChega, status: string, entregador?: Entregador, motivo?: string): Observable<any> {
    item.entregador = entregador;
    item.status = status;
    return this.auth.currentUser.pipe(
      mergeMap(user => {
        // Montar o log
        const log: Log = {
          data: firestore.Timestamp.now(),
          userId: user.id,
          userName: user.name,
          entregador: entregador,
          status: status,
          motivo: motivo ? motivo : null
        };

        if (isNullOrUndefined(item.logs)) {
          item.logs = [];
        }

        // Adiciona o log
        item.logs.push(log);

        return this.saveOrUpdate(item, false, true);
      })
    );
  }

  getHoraInicial(): Timestamp {
    const now = firestore.Timestamp.now().toDate();
    if (now.getHours() < 6) {
      now.setDate(now.getDate() - 1);
      now.setHours(6,0,0,0);
    } else {
      now.setHours(6, 0,0,0);
    }
    return firestore.Timestamp.fromDate(now);
  }

  getHoraFinal(): Timestamp {
    const now = firestore.Timestamp.now().toDate();
    if (now.getHours() < 6) {
      now.setHours(6,0,0,0);
    } else {
      now.setDate(now.getDate() + 1);
      now.setHours(6, 0,0,0);
    }
    return firestore.Timestamp.fromDate(now);
  }

  getEntregas(): Observable<PedeQueChega[]> {
    return this.col$($query(new Criterion("data", ">=", this.getHoraInicial()), new Criterion("data","<=", this.getHoraFinal())));
  }
}
