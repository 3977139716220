import {Injectable} from "@angular/core";
import {RepositoryPaginationService} from "../../repository/repository-pagination.service";
import {Avaliacao} from "../../models/avaliacao.model";
import { AngularFirestore } from "@angular/fire/firestore";
import {AuthService} from "../../modules/login/auth.service";
import {OrderByDesc} from "../../services/firebase/criteria/order-by-desc";
import {OrderBy} from "../../services/firebase/criteria/order-by";
import {PoMQuery} from "../../services/firebase/criteria/query";
import {Observable} from "rxjs";
import {mergeMap} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class  AvaliacaoPaginationService extends RepositoryPaginationService<Avaliacao> {

  constructor(public db: AngularFirestore, public auth: AuthService) {
    super(db, "avaliacoes", 10, true, auth);
  }

  infiniteScroll(queryFn?: PoMQuery): Observable<Avaliacao[]> {
    return this.initialQuery(queryFn).pipe(
      mergeMap(() => {
        return this.data$;
      })
    );
  }

  protected orderBy(): OrderBy {
    return new OrderByDesc("data");
  }

  deserialize(value: any): Avaliacao {
    return new Avaliacao(value);
  }
}
