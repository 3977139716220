import {RecordByEmpresa} from "./record-by-empresa";

export class CategoriaProduto extends RecordByEmpresa {

  public nome: string;
  public descricao: string;
  public inactive: boolean;
  public ordem: number;

  constructor(categoria: any = {}) {
    super(categoria.id, categoria.ref, categoria.empresa);
    this.nome = categoria.nome;
    this.descricao = categoria.descricao;
    this.inactive = categoria.inactive;
    this.ordem = categoria.ordem;
  }
}
