import {Pipe, PipeTransform} from "@angular/core";
import {AsYouType} from "libphonenumber-js";
import {isEmpty} from "../../../utils/commons";

@Pipe({
  name: "phone"
})
export class PhonePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    // Tratativa se o valor vier vazio
    if (isEmpty(value)) {
      return "";
    }

    // Remover os espaços
    value = value.toString().trim().replace(/^\+/, "");

    // Verificar se todos os dígitos são números
    if (value.match(/[^0-9]/)) {
      return value;
    }

    return new AsYouType("BR").input(value);
  }

}
