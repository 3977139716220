import {isNotNullOrUndefined} from "../utils/commons";

export class PagamentoOnline {

  public static taxaDeSaque: number = 2;

  public status: string;
  public taxa: number;
  public accountId: string;
  public userToken: string;
  public testApiToken: string;
  public liveApiToken: string;
  public enabled: boolean;
  public feedback: string;
  public verificationType: string;
  public saqueAutomatico: SaqueAutomatico;
  public mirrorPagamentoOnline: PagamentoOnline;
  public oldAccounts: OldAccount[];

  constructor(pgtOnline: any = {}) {
    this.status = pgtOnline.status;
    this.taxa = pgtOnline.taxa;
    this.accountId = pgtOnline.accountId;
    this.userToken = pgtOnline.userToken;
    this.testApiToken = pgtOnline.testApiToken;
    this.liveApiToken = pgtOnline.liveApiToken;
    this.enabled = pgtOnline.enabled;
    this.feedback = pgtOnline.feedback;
    this.verificationType = pgtOnline.verificationType;
    this.saqueAutomatico = pgtOnline.saqueAutomatico;
    if (isNotNullOrUndefined(pgtOnline.mirrorPagamentoOnline)) this.mirrorPagamentoOnline = pgtOnline.mirrorPagamentoOnline;
    this.oldAccounts = pgtOnline.oldAccounts;
  }
}

export class SaqueAutomatico {
  active: boolean;
  valorMinimo: number;
  diaSemana: string;

  constructor(saqueAutomatico: any = {}) {
    this.active = saqueAutomatico.active;
    this.valorMinimo = saqueAutomatico.valorMinimo;
    this.diaSemana = saqueAutomatico.diaSemana;
  }
}

export enum PgtOnlineStatus {
  PENDING,
  ACCEPTED,
  REJECTED
}

export enum PagtOnlineVerificationType {
  ACCOUNT_VERIFICATION, BANK_VERIFICATION
}

export enum DiaSemana {
  segunda = "segunda",
  terca = "terca",
  quarta = "quarta",
  quinta ="quinta",
  sexta = "sexta"
}

export interface OldAccount {
  accountId: string;
}
