import {Injectable} from "@angular/core";
import {CropperDialogComponent} from "../components/layout/cropper/cropper-dialog/cropper-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Injectable()
export class CropperService {

  constructor(public dialog: MatDialog) {
  }

  public open(imageName: string, configsCropper: any, onImageUrlUpload: Function) {
    const dialogRef = this.dialog.open(CropperDialogComponent, {
      data: {
        imageName: imageName,
        configsCropper: configsCropper
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      onImageUrlUpload(result);
    });
  }

}
