import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Empresa} from "../../../models/empresa";
import {Observable} from "rxjs";
import {Pedido} from "../../../models/pedido.model";
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";
import {PrinterService} from "../../../services/printer.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {TipoPagamento} from "../../../models/forma-pagamento.model";
import {GerenciadorPedidosService} from "../service/gerenciador-pedidos.service";

@Component({
  selector: "app-detalhes-empresa-dialog",
  templateUrl: "./detalhes-empresa-dialog.component.html",
  styleUrls: ["./detalhes-empresa-dialog.component.scss"]
})
export class DetalhesEmpresaDialogComponent {

  static openDialog(dialog: MatDialog, empresas: Empresa[], haveTaxa: boolean): Observable<any> {
    const config = {
      width: "900px",
      data: {
        empresas: empresas,
        haveTaxa: haveTaxa
      }
    };

    return dialog.open(DetalhesEmpresaDialogComponent, config).afterClosed();
  }

  empresas: Empresa[];
  pedidos: Pedido[] = [];
  itens: any = [];
  haveTaxa: boolean;
  TipoPagamento = TipoPagamento;

  constructor(public dialogRef: MatDialogRef<DetalhesEmpresaDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public pedidosService: GerenciadorPedidosService,
              public printer: PrinterService,
              public dialog: DialogService) {

    this.haveTaxa = data.haveTaxa;
    this.empresas = data.empresas;

    this.pedidosService.getPedidosGerenciadorByEmpresas(this.empresas)
      .subscribe(pedidos => {
        for (const empresa of this.empresas) {
          this.itens.push({
            empresa: empresa,
            pedidos: pedidos.filter(pedido => {
              return !(pedido.isRejeitado() || pedido.isCancelado()) && pedido.empresa.id === empresa.id;
            })
          });
        }
      });
  }

  getTotal(pedidos: Pedido[], tipo: string, formPagamento?: string): number {
    if (!isEmpty(pedidos)) {
      return pedidos
        .map(pedido => {
          // Verifica se os valores de totalização estão sendo feitos para a totalização geral ou das formas de pagamento
          if (isNotNullOrUndefined(pedido.formaPagamento.tipoPagamento) && pedido.formaPagamento.tipoPagamento === formPagamento) {
            return this.changeTipo(pedido, tipo);
          } else if (isNullOrUndefined(formPagamento)) {
            return this.changeTipo(pedido, tipo);
          } else {
            return 0;
          }
        })
        .reduce((prev, curr) => {
          return prev + curr;
        });
    } else {
      return 0;
    }
  }

  changeTipo(pedido: Pedido, tipo: string): number {
    if (tipo === "total") {
      return pedido.total;
    } else if (tipo === "valorEntrega") {
      return pedido.valorEntrega;
    } else if (tipo === "subtotal") {
      return pedido.subtotal;
    } else if (tipo === "pontos") {
      return pedido.pontos;
    } else if (tipo === "taxa") {
      return isNotNullOrUndefined(pedido.taxaAdicional) ? pedido.taxaAdicional : 0;
    } else {
      return 0;
    }
  }

  imprimirTotal(empresa: Empresa, pedidos: Pedido[]) {
    const totalGeral = {
      pedidos: pedidos.length,
      entregas: this.getTotal(pedidos, "valorEntrega"),
      taxa: this.haveTaxa ? this.getTotal(pedidos, "taxa") : null,
      pontos: this.getTotal(pedidos, "pontos"),
      produtos: this.getTotal(pedidos, "subtotal"),
      total: this.getTotal(pedidos, "total")
    };

    let totalDinheiro = null;
    if (this.getPedidosLength(pedidos, TipoPagamento[TipoPagamento.dinheiro]) > 0) {
      totalDinheiro = {
        pedidos: this.getPedidosLength(pedidos, TipoPagamento[TipoPagamento.dinheiro]),
        produtos: this.getTotal(pedidos, "subtotal", TipoPagamento[TipoPagamento.dinheiro]),
        entregas: this.getTotal(pedidos, "valorEntrega", TipoPagamento[TipoPagamento.dinheiro]),
        total: this.getTotal(pedidos, "total", TipoPagamento[TipoPagamento.dinheiro])
      };
    }

    let totalCartao = null;
    if ((this.getPedidosLength(pedidos, TipoPagamento[TipoPagamento.debito]) + this.getPedidosLength(pedidos, TipoPagamento[TipoPagamento.credito])) > 0) {
      totalCartao = {
        pedidos: this.getPedidosLength(pedidos, TipoPagamento[TipoPagamento.debito]) + this.getPedidosLength(pedidos, TipoPagamento[TipoPagamento.credito]),
        produtos: this.getTotal(pedidos, "subtotal", TipoPagamento[TipoPagamento.debito]) + this.getTotal(pedidos, "subtotal", TipoPagamento[TipoPagamento.credito]),
        entregas: this.getTotal(pedidos, "valorEntrega", TipoPagamento[TipoPagamento.debito]) + this.getTotal(pedidos, "valorEntrega", TipoPagamento[TipoPagamento.credito]),
        total: this.getTotal(pedidos, "total", TipoPagamento[TipoPagamento.debito]) + this.getTotal(pedidos, "total", TipoPagamento[TipoPagamento.credito])
      };
    }

    let totalOnline = null;
    if (this.getPedidosLength(pedidos, TipoPagamento[TipoPagamento.online]) > 0) {
      totalOnline = {
        pedidos: this.getPedidosLength(pedidos, TipoPagamento[TipoPagamento.online]),
        produtos: this.getTotal(pedidos, "subtotal", TipoPagamento[TipoPagamento.online]),
        entregas: this.getTotal(pedidos, "valorEntrega", TipoPagamento[TipoPagamento.online]),
        total: this.getTotal(pedidos, "total", TipoPagamento[TipoPagamento.online])
      };
    }

    const itens = {
      empresa: empresa.nomeFantasia,
      totalGeral: totalGeral,
      totalDinheiro: totalDinheiro,
      totalCartao: totalCartao,
      totalOnline: totalOnline
    };

    this.printer.imprimirTotal(itens)
      .subscribe(() => {
        console.log("print sucess!");
      }, error => {
        console.log("print error!", error);
        this.dialog.messageDialog()
          .message(error)
          .show();
      });
  }

  getPedidosLength(pedidos: Pedido[], tipo: string): number {
    return pedidos.filter(pedido => pedido.formaPagamento.tipoPagamento === tipo).length;
  }

}
