import {FireModel} from "./fire.model";
import * as firebase from "firebase";
import {Entregador} from "./entregador.model";
import {Pedido} from "./pedido.model";


export enum StatusPedeQueChega {
  PENDENTE = "PENDENTE",
  APROVADO = "APROVADO",
  CANCELADO = "CANCELADO",
  PAGO = "PAGO"
}

export function StatusPedeQueChegaToString(tipo: string): string {
  switch (tipo) {
    case StatusPedeQueChega[StatusPedeQueChega.PENDENTE]:
      return "Pendente";
    case StatusPedeQueChega[StatusPedeQueChega.APROVADO]:
      return "Aprovado";
    case StatusPedeQueChega[StatusPedeQueChega.CANCELADO]:
      return "Cancelado";
    case StatusPedeQueChega[StatusPedeQueChega.PAGO]:
      return "Pago";
  }
}

export function styleByStatus(status) {
  switch (status) {
    case StatusPedeQueChega[StatusPedeQueChega.CANCELADO]:
      return {
        "color": "red"
      };

    case StatusPedeQueChega[StatusPedeQueChega.APROVADO]:
      return {
        "color": "#2196f3"
      };

    case StatusPedeQueChega[StatusPedeQueChega.PENDENTE]:
      return {
        "color": "#ffeb3b"
      };

    case StatusPedeQueChega[StatusPedeQueChega.PAGO]:
      return {
        "color": "#4caf50"
      };

    default:
      return {};
  }
}

export class PedeQueChega extends FireModel {
  public id: string;
  public data: firebase.firestore.Timestamp;
  public entregador: Entregador;
  public pedido: Pedido;
  public status: string;
  public valorEntrega: number;
  public taxa: number;
  public valor: number;
  public logs: Log[] = [];

  constructor(pedeQueChega: any = {}) {
    super();
    this.id = pedeQueChega.id;
    this.data = pedeQueChega.data;
    this.entregador = pedeQueChega.entregador ? new Entregador(pedeQueChega.entregador) : null;
    this.pedido = pedeQueChega.pedido;
    this.status = pedeQueChega.status;
    this.valorEntrega = pedeQueChega.valorEntrega;
    this.taxa = pedeQueChega.taxa;
    this.valor = pedeQueChega.valor;
    if (pedeQueChega.logs) {
      pedeQueChega.logs.forEach(log => this.logs.push(new Log(log)));
    }
  }

}

export class Log {
  public data: firebase.firestore.Timestamp;
  public userId: string;
  public userName: string;
  public entregador: Entregador;
  public status: string;
  public motivo: string;

  constructor(log?: any) {
    if (log) {
      this.data = log.data;
      this.userId = log.userId;
      this.userName = log.userName;
      this.entregador = log.entregador ? new Entregador(log.entregador) : null;
      this.status = log.status;
      this.motivo = log.motivo;
    }
  }

}
