import {Component, Inject, OnInit, ViewChild} from "@angular/core";
import {CategoriaProdutoFieldsComponent} from "../categoria-produto-fields/categoria-produto-fields.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {CategoriaProdutoService} from "../categoria-produto.service";
import {CategoriaProduto} from "../../../models/categoria.model";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";

@Component({
  selector: "app-categoria-produto-dialog",
  templateUrl: "./categoria-produto-dialog.component.html",
  styleUrls: ["./categoria-produto-dialog.component.scss"]
})
export class CategoriaProdutoDialogComponent implements OnInit {

  @ViewChild(CategoriaProdutoFieldsComponent, { static: true })
  fieldsComponent: CategoriaProdutoFieldsComponent;

  constructor(public dialogRef: MatDialogRef<CategoriaProdutoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public item: CategoriaProdutoDialogComponent,
              public service: CategoriaProdutoService,
              public dialogService: DialogService,
              public loading: LoadingService) {
  }

  ngOnInit() {
  }

  save(item: CategoriaProduto) {
    this.loading.showTopBar();
    this.service.saveOrUpdate(item, true).subscribe(() => {
        this.dialogRef.close(item);
      },
      () => {
        this.dialogService.messageDialog()
          .message("Ocorreu um erro ao salvar o item!")
          .show();
      }, () => {
        this.loading.hideTopBar();
      });
  }
}
