import * as jsPDF from "jspdf";
import {applyPlugin} from "jspdf-autotable";
import {Injectable} from "@angular/core";
import {Pedido} from "../../models/pedido.model";
import {Cozinha} from "../../models/cozinha.model";
import * as moment from "moment";
import {isEmpty, isNotNullOrUndefined} from "../../utils/commons";
import {PedidoItem} from "../../models/pedido-item.model";
import {AsYouType} from "libphonenumber-js";
import {RegraTotalizacao} from "../../models/produto.model";
import {TipoPagamento} from "../../models/forma-pagamento.model";

applyPlugin(jsPDF);

@Injectable()
export class GeradorDeTicketPedidoPdfService {

  constructor() {
  }

  gerarPdfEspelho(pedido: Pedido, pageDimension?: string) {

    let doc = new jsPDF("p", "mm", [153, 243]);
    if (pageDimension === "A4") {
      doc = new jsPDF("p", "mm", [420, 595]);
    }

    // Mudar margin das tables dependendo das dimensões da página
    let marginValue = 1;
    let itemWidth = 32;
    let obsLimit = 40;
    let centralization = 53;
    let marginTotal = 24;
    if (pageDimension === "58mm") {
      marginValue = 6;
      itemWidth = 22;
      obsLimit = 28;
      centralization = 42;
      marginTotal= 18.5;
    }
    if (pageDimension === "A4") {
      marginValue = 10;
      marginTotal = 33.5;
    }

    //Estilo de colunas, styles e margin
    const _columnStyles = {
      0: {
        cellWidth: 7
      },
      1: {
        cellWidth: itemWidth
      },
      2:{
        cellWidth: 13,
        halign: "right"
      }
    };
    const _styles = {
      overflow: "linebreak",
      cellPadding: 0,
      fontSize: 7,
    };
    const _stylesBold = {
      overflow: "linebreak",
      cellPadding: 0,
      fontSize: 7,
      fontStyle: "bold",
    };
    const _stylesCenter = {
      cellPadding: 0,
      fontSize: 7,
      halign: "center",
      cellWidth: centralization
    };
    const _margin = {
      left: marginValue,
      right: marginValue,
      top: 0,
      bottom: 0
    };

    let data = [];
    let aumentoFonte = 0; // Variável para aumentar a fonte em folhas 58mm

    if (pageDimension === "58mm") {
      data.push(["PEDE O MENU"]);
      data.push([pedido.empresa.nomeFantasia.toUpperCase()]);
      aumentoFonte = 1;
      if (pedido.empresa.telefones.some(tel => tel.visivelNoTicket === true)) {
        const telefones = pedido.empresa.telefones.filter(tel => tel.visivelNoTicket === true);
        for (let i = 0; i < telefones.length; i++) {
          data.push([telefones[i].numero]);
        }
      }
    } else {
      data.push(["PEDE O MENU"]);
      data.push([pedido.empresa.nomeFantasia.toUpperCase()]);
      if (pedido.empresa.telefones.some(tel => tel.visivelNoTicket === true)) {
        const telefones = pedido.empresa.telefones.filter(tel => tel.visivelNoTicket === true);
        for (let i = 0; i < telefones.length; i+=2) {
          if (isNotNullOrUndefined(telefones[i])) {
            data.push([telefones[i].numero + (isNotNullOrUndefined(telefones[i+1]) ? ("  |  " + telefones[i+1].numero) : "")]);
          }
        }
      }
    }
    doc.autoTable({
      startY: pageDimension === "A4" ? 10 : 0,
      theme: "plain",
      margin: _margin,
      didParseCell(dataCenter) {
        if (dataCenter.row.index > 1) {
          dataCenter.cell.styles.fontStyle = "normal";
          dataCenter.cell.styles.fontSize = 6;
        }
      },
      styles: {
        overflow: "linebreak",
        cellPadding: 0,
        fontSize: 8,
        fontStyle: "bold",
        cellWidth: centralization
      },
      columnStyles: {0: {halign: "center"}, 1: {halign: "center"}},
      body: data
    });

    //Dados do pedido em autotable
    data = [];
    data.push(["Pedido: " , "#" + pedido.numero]);
    data.push(["Cliente: " , pedido.cliente.nome]);
    // Table de Pedido e de Cliente
    doc.autoTable({
      startY: doc.previousAutoTable.finalY + 3,
      theme: "plain",
      margin: _margin,
      styles: {
        overflow: "linebreak",
        cellPadding: 0,
        fontSize: 7 + aumentoFonte,
      },
      columnStyles: {0: {cellWidth: 10}, 1: {fontSize: 8 + aumentoFonte, fontStyle: "bold", halign: "left"}},
      body: data
    });

    let buscarBalcao = false;
    data = [];
    data.push(["Data: " + moment(pedido.data.toDate()).format("DD/MM/YYYY HH:mm")]);
    data.push(["Telefone: " + new AsYouType("BR").input(pedido.cliente.telefone)]);
    if (pedido.buscarNoBalcao) {
      buscarBalcao = true;
    } else {
      data.push(["Endereço: " + pedido.enderecoEntrega.logradouro + ", " + pedido.enderecoEntrega.numero]);
      data.push(["Bairro: " + pedido.enderecoEntrega.bairro.nome]);
      if (isNotNullOrUndefined(pedido.enderecoEntrega.complemento) && !isEmpty(pedido.enderecoEntrega.complemento)) {
        data.push(["Comp: " + pedido.enderecoEntrega.complemento]);
      }
      if (isNotNullOrUndefined(pedido.enderecoEntrega.pontoReferencia) && !isEmpty(pedido.enderecoEntrega.pontoReferencia)) {
        data.push(["Ref: " + pedido.enderecoEntrega.pontoReferencia]);
      }
    }
    //Table dos dados
    doc.autoTable({
      startY: doc.previousAutoTable.finalY,
      theme: "plain",
      margin: _margin,
      styles: {
        overflow: "linebreak",
        cellPadding: 0,
        fontSize: 7 + aumentoFonte,
      },
      body: data
    });

    //Retirar no balcão
    if (buscarBalcao === true) {
      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 3,
        theme: "plain",
        margin: _margin,
        styles: _stylesCenter,
        body: [["RETIRAR NO BALCÃO"]]
      });
    }

    //Cabeçalho da Table
    doc.autoTable({
      startY: doc.previousAutoTable.finalY + 3,
      theme: "plain",
      margin: _margin,
      styles: _stylesBold,
      columnStyles: _columnStyles,
      body: [["Qtde", "Item", "Preço"]]
    });

    //Criando Table de itens
    for (let i = 0; i < pedido.itens.length; i++) {
      const item = pedido.itens[i];
      let firstStartY: number;
      if (i === 0) {
        firstStartY = doc.previousAutoTable.finalY + 1;
      } else {
        firstStartY = doc.previousAutoTable.finalY + 4;
      }
      // Verificar se passou do limite da página caso seja A4
      if ((pageDimension === "A4") && ((210 - firstStartY) < 11)) {
        doc.addPage();
        firstStartY = 11;
      }
      //Table do produto
      doc.autoTable({
        startY: firstStartY,
        theme: "plain",
        margin: _margin,
        styles: _styles,
        columnStyles: _columnStyles,
        body: [[item.quantidade, item.produto.nome, item.produto.preco > 0 ? this.moneyFormat(item.produto.preco * item.quantidade) : ""]]
      });
      if (item.complementos.length > 0) {
        for (let j = 0; j < item.complementos.length; j++) {
          const complemento = item.complementos[j];
          if (complemento.itens.length > 0) {
            // Verificar se passou do limite da página caso seja A4
            let complementoY = doc.previousAutoTable.finalY;
            if ((pageDimension === "A4") && ((210 - complementoY) < 11)) {
              doc.addPage();
              complementoY = 11;
            }
            //Table do titulo do complemento
            doc.autoTable({
              startY: complementoY,
              theme: "plain",
              margin: _margin,
              styles: _stylesBold,
              columnStyles: _columnStyles,
              body: [["", complemento.titulo, complemento.regraTotalizacao === RegraTotalizacao.Soma ? ""
                : complemento.getComplementoTotalValue(item.quantidade) <= 0 ? "" : this.moneyFormat(complemento.getComplementoTotalValue(item.quantidade))]]
            });
            const showQtdeItem = complemento.itens.length > 1 && complemento.itens.some((_item) => _item.quantidade > 1);
            for (let k = 0; k < complemento.itens.length; k++) {
              const compItem = complemento.itens[k];
              // Verificar se passou do limite da página caso seja A4
              let complementoItemY = doc.previousAutoTable.finalY;
              if ((pageDimension === "A4") && ((210 - complementoItemY) < 11)) {
                doc.addPage();
                complementoItemY = 11;
              }
              //Table do item do complemento
              doc.autoTable({
                startY: complementoItemY,
                theme: "plain",
                margin: _margin,
                styles: _styles,
                columnStyles: _columnStyles,
                body: [
                  ["", (showQtdeItem || compItem.quantidade > 1 ? compItem.quantidade + "x" : " ") + " " + compItem.nome,
                    (complemento.regraTotalizacao === RegraTotalizacao.Soma) && (compItem.preco > 0) ?
                      this.moneyFormat(compItem.preco * compItem.quantidade * item.quantidade) : ""]
                ]
              });
            }
          }
        }
      }
      if (!isEmpty(item.observacao)) {
        // Verificar se passou do limite da página caso seja A4
        let obsY = doc.previousAutoTable.finalY;
        if ((pageDimension === "A4") && ((210 - obsY) < 11)) {
          doc.addPage();
          obsY = 11;
        }
        //Table da observação do produto
        doc.autoTable({
          startY: obsY,
          theme: "plain",
          margin: _margin,
          styles: _styles,
          columnStyles: {
            0: {cellWidth: 7},
            1: {cellWidth: 6.5},
            2: {fontStyle: "bold", cellWidth: obsLimit}
          },
          body: [["", "Obs.:", item.observacao]]
        });
      }
    }

    // Verificar se passou do limite da página caso seja A4
    let valueY = doc.previousAutoTable.finalY + 5;
    if ((pageDimension === "A4") && ((210 - valueY) < 40)) {
      doc.addPage();
      valueY = 11;
    }
    //Resultados do total
    data = [];
    let taxaAdicional: boolean = false;
    data.push(["Subtotal:  (+)" , this.moneyFormat(pedido.subtotal)]);
    if (buscarBalcao === false) {
      data.push(["Delivery:  (+)" , this.moneyFormat(pedido.valorEntrega)]);
    }
    if (isNotNullOrUndefined(pedido.taxaAdicional) && pedido.taxaAdicional > 0) {
      taxaAdicional = true;
      data.push(["Adicional:(+)" , this.moneyFormat(pedido.taxaAdicional)]);
    }
    data.push(["PoMs:      (-)" , pedido.pontos.toLocaleString("pt-BR", {maximumFractionDigits: 2})]);
    data.push(["Total:        " , this.moneyFormat(pedido.total)]);
    doc.autoTable({
      startY: valueY,
      theme: "plain",
      margin: {
        left: marginTotal,
        right: marginValue,
        top: 0,
        bottom: 0,
      },
      styles: _styles,
      columnStyles: {
        0: {cellWidth: 14},
        1: {halign: "right", cellWidth: 15.5}
      },
      didParseCell(dataStyles) {
        //Transformar row de Total em negrito
        if (dataStyles.row.index === 4 && (buscarBalcao === false && taxaAdicional === true)) {
          dataStyles.cell.styles.fontStyle = "bold";
        } else if (dataStyles.row.index === 3 && ((buscarBalcao === false && taxaAdicional === false) || (buscarBalcao === true && taxaAdicional === true))) {
          dataStyles.cell.styles.fontStyle = "bold";
        } else if (dataStyles.row.index === 2 && (buscarBalcao === true && taxaAdicional === false)) {
          dataStyles.cell.styles.fontStyle = "bold";
        }
        //Centralizar valor de PoMs
        if (dataStyles.row.index === 3 && dataStyles.column.index === 1 && (buscarBalcao === false && taxaAdicional === true)) {
          dataStyles.cell.styles.halign = "center";
        } else if (dataStyles.row.index === 2 && dataStyles.column.index === 1 &&
          ((buscarBalcao === false && taxaAdicional === false) || (buscarBalcao === true && taxaAdicional === true))) {
          dataStyles.cell.styles.halign = "center";
        } else if (dataStyles.row.index === 1 && dataStyles.column.index === 1 && (buscarBalcao === true && taxaAdicional === false)) {
          dataStyles.cell.styles.halign = "center";
        }
      },
      body: data
    });

    // Verificar se passou do limite da página caso seja A4
    valueY = doc.previousAutoTable.finalY + 3;
    if ((pageDimension === "A4") && ((210 - valueY) < 11)) {
      doc.addPage();
      valueY = 11;
    }

    if (pedido.formaPagamento.tipoPagamento === TipoPagamento[TipoPagamento.online]) {
      doc.autoTable({
        startY: valueY,
        theme: "plain",
        margin: {
          cellPadding: 0,
          fontSize: 7,
          halign: "center",
          cellWidth: centralization,
          left: marginValue,
          right: marginValue
        },
        styles: {
          cellPadding: 0,
          fontSize: 7,
          halign: "center",
          cellWidth: centralization,
          fillColor: "black",
          textColor: "white"
        },
        body: [["Pedido pago pelo aplicativo."], ["Não cobrar do cliente!"]]
      });
      valueY = valueY + 10;
    }

    //Forma de pagamento
    doc.autoTable({
      startY: valueY,
      theme: "plain",
      margin: {
        left: marginValue,
        top: 0,
        bottom: 0
      },
      styles: _styles,
      columnStyles: {
        0: {cellWidth: 16},
        1: {fontStyle: "bold", halign: "left"}
      },
      body: [["Forma Pagto: " , pedido.formaPagamento.descricao.toUpperCase()]]
    });

    data = [];
    if (pedido.troco > 0) {
      data.push(["*** Troco p/ " + this.moneyFormat(pedido.troco) + " ***"]);
      data.push(["Levar " + this.moneyFormat(pedido.troco - pedido.total) + " de troco"]);
    }
    // Verificar se passou do limite da página caso seja A4
    valueY = doc.previousAutoTable.finalY + 3;
    if ((pageDimension === "A4") && ((210 - valueY) < 11)) {
      doc.addPage();
      valueY = 11;
    }
    doc.autoTable({
      startY: valueY,
      theme: "plain",
      margin: _margin,
      styles: _stylesCenter,
      didParseCell(dataCenter) {
        if (dataCenter.row.index === 1) {
          dataCenter.cell.styles = {
            cellPadding: 0,
            fontSize: 7,
            halign: "center",
            cellWidth: centralization,
            fontStyle: "bold"
          };
        }
      },
      body: data
    });

    // Retorna o PDF em um formato aceitavel para impressora
    const out = doc.output("datauri");
    return out.split("base64,")[1];
  }

  gerarPdfCozinha(ifp: ItensPorCozinha, comanda?: string, pageDimension?: string) {
    let doc = new jsPDF("p", "mm", [153, 243]);
    if (pageDimension === "A4") {
      doc = new jsPDF("p", "mm", [420, 595]);
    }

    // Mudar margin das tables dependendo das dimensões da página
    let marginValue = 1;
    let centralization = 53;
    if (pageDimension === "58mm") {
      marginValue = 6;
      centralization = 42;
    }
    if (pageDimension === "A4") {
      marginValue = 10;
    }

    // Estilos da AutoTable
    const _columnStyles = {0: {cellWidth: 7}};
    const _columnStylesCenter = {0: {halign: "center", cellWidth: centralization}};
    const _margin = {left: marginValue, right: marginValue, top: 0, bottom: 0};
    const _styles = {overflow: "linebreak", cellPadding: 0, fontSize: 7};
    const _stylesBold = {overflow: "linebreak", cellPadding: 0, fontSize: 7, fontStyle: "bold"};

    doc.autoTable({
      startY: pageDimension === "A4" ? 10 : 0,
      theme: "plain",
      margin: _margin,
      styles: {overflow: "linebreak", cellPadding: 0, fontSize: 6, fontStyle: "bold"},
      columnStyles: _columnStylesCenter,
      body: [["PEDE O MENU"]]
    });
    doc.autoTable({
      startY: doc.previousAutoTable.finalY + 3,
      theme: "plain",
      margin: _margin,
      styles: {overflow: "linebreak", cellPadding: 0, fontSize: 10, fontStyle: "bold"},
      columnStyles: _columnStylesCenter,
      body: [[ifp.cozinha.nome.toUpperCase()]]
    });

    // Tables de dados
    doc.autoTable({
      startY: doc.previousAutoTable.finalY + 3,
      theme: "plain",
      margin: _margin,
      styles: _styles,
      columnStyles: {0: {cellWidth: 9}, 1: {fontSize: 8, fontStyle: "bold", halign: "left"}},
      body: [
        ["Pedido: " , "#" + ifp.pedido.numero],
        ["Cliente: " , ifp.pedido.cliente.nome]
      ]
    });
    doc.autoTable({
      startY: doc.previousAutoTable.finalY,
      theme: "plain",
      margin: _margin,
      styles: _styles,
      body: [["Data: " + moment(ifp.pedido.data.toDate()).format("DD/MM/YYYY HH:mm")]]
    });

    // Cabeçalho
    doc.autoTable({
      startY: doc.previousAutoTable.finalY + 3,
      theme: "plain",
      margin: _margin,
      styles: _styles,
      columnStyles: _columnStyles,
      body: [["Qtde", "Item"]]
    });

    // Contrução da table dos itens
    for (let i = 0; i < ifp.itens.length; i++) {
      const item = ifp.itens[i];
      let firstStartY: number;
      if (i === 0) {
        firstStartY = doc.previousAutoTable.finalY + 1;
      } else {
        firstStartY = doc.previousAutoTable.finalY + 4;
      }
      // Verificar se passou do limite da página caso seja A4
      if ((pageDimension === "A4") && ((210 - firstStartY) < 11)) {
        doc.addPage();
        firstStartY = 11;
      }
      //Table do produto
      doc.autoTable({
        startY: firstStartY,
        theme: "plain",
        margin: _margin,
        styles: {overflow: "linebreak", cellPadding: 0, fontSize: 8, fontStyle: "bold"},
        columnStyles: _columnStyles,
        body: [[item.quantidade, item.produto.nome]]
      });
      if (item.complementos.length > 0) {
        for (let j = 0; j < item.complementos.length; j++) {
          const complemento = item.complementos[j];
          if (complemento.itens.length > 0) {
            // Verificar se passou do limite da página caso seja A4
            let complementoY = doc.previousAutoTable.finalY;
            if ((pageDimension === "A4") && ((210 - complementoY) < 11)) {
              doc.addPage();
              complementoY = 11;
            }
            //Table do titulo do complemento
            doc.autoTable({
              startY: complementoY,
              theme: "plain",
              margin: _margin,
              styles: _stylesBold,
              columnStyles: _columnStyles,
              body: [["", complemento.titulo]]
            });
            const showQtdeItem = complemento.itens.length > 1 && complemento.itens.some((_item) => _item.quantidade > 1);
            for (let k = 0; k < complemento.itens.length; k++) {
              const compItem = complemento.itens[k];
              // Verificar se passou do limite da página caso seja A4
              let complementoItemY = doc.previousAutoTable.finalY;
              if ((pageDimension === "A4") && ((210 - complementoItemY) < 11)) {
                doc.addPage();
                complementoItemY = 11;
              }
              //Table do item do complemento
              doc.autoTable({
                startY: complementoItemY,
                theme: "plain",
                margin: _margin,
                styles: _styles,
                columnStyles: _columnStyles,
                body: [["", (showQtdeItem || compItem.quantidade > 1 ? compItem.quantidade + "x" : " ") + " " + compItem.nome]]
              });
            }
          }
        }
      }
      if (!isEmpty(item.observacao)) {
        // Verificar se passou do limite da página caso seja A4
        let obsY = doc.previousAutoTable.finalY;
        if ((pageDimension === "A4") && ((210 - obsY) < 11)) {
          doc.addPage();
          obsY = 11;
        }
        //Table da observação do produto
        doc.autoTable({
          startY: obsY,
          theme: "plain",
          margin: _margin,
          styles: _styles,
          columnStyles: {
            0: {cellWidth: 7},
            1: {cellWidth: 6.5},
            2: {fontStyle: "bold"}
          },
          body: [["", "Obs.:", item.observacao]]
        });
      }
    }

    // Verificar se passou do limite da página caso seja A4
    let valueY = doc.previousAutoTable.finalY + 3;
    if ((pageDimension === "A4") && ((210 - valueY) < 40)) {
      doc.addPage();
      valueY = 11;
    }
    // Identificação da comanda
    doc.autoTable({
      startY: valueY,
      theme: "plain",
      margin: _margin,
      styles: {overflow: "linebreak", cellPadding: 0, fontSize: 10, fontStyle: "bold"},
      columnStyles: _columnStylesCenter,
      body: [[comanda]]
    });

    // Mostrar se é Delivery ou Balcão
    let deliveryOuBalcao: string;
    if (ifp.pedido.buscarNoBalcao) {
      deliveryOuBalcao = "BALCÃO";
    } else {
      deliveryOuBalcao = "DELIVERY";
    }
    valueY = doc.previousAutoTable.finalY + 2;
    if ((pageDimension === "A4") && ((210 - valueY) < 40)) {
      doc.addPage();
      valueY = 11;
    }
    doc.autoTable({
      startY: valueY,
      theme: "plain",
      margin: _margin,
      styles: {overflow: "linebreak", cellPadding: 0, fontSize: 8, fontStyle: "bold"},
      columnStyles: _columnStylesCenter,
      body: [[deliveryOuBalcao]]
    });

    // Retorna o PDF em um formato aceitavel para impressora
    const out = doc.output("datauri");
    return out.split("base64,")[1];
  }

  private moneyFormat(value: number): string {
    const moneyFormat = new Intl.NumberFormat("pt", {style: "currency", currency: "BRL"});
    return moneyFormat.format(value);
  }

}
class ItensPorCozinha {
  cozinha: Cozinha;
  pedido: Pedido;
  itens: PedidoItem[] = [];

  constructor(cozinha: Cozinha, pedido: Pedido, item: PedidoItem) {
    this.cozinha = cozinha;
    this.pedido = pedido;
    this.itens.push(item);
  }
}
