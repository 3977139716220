import * as jsPDF from "jspdf";
import {applyPlugin} from "jspdf-autotable";
import {Injectable} from "@angular/core";
import * as moment from "moment";
import {isNotNullOrUndefined, isNullOrUndefined} from "../../utils/commons";

applyPlugin(jsPDF);

@Injectable()
export class GeradorPdfTotalizacaoService {

  constructor() {
  }

  gerarPdfTotalizacao(totals: any, pageDimension?: string) {

    let doc = new jsPDF("p", "mm", [153, 370]);
    if (pageDimension === "A4") {
      doc = new jsPDF("p", "mm", [420, 595]);
    }

    let data = [];
    let aumentoFonte = 0; // Variável para aumentar a fonte em folhas 58mm
    let centralization = 53;
    if (pageDimension === "58mm") {
      aumentoFonte = 1;
      centralization = 42;
    }

    if (pageDimension === "58mm") {
      data.push(["PEDE O MENU"]);
      data.push([totals.empresa.toUpperCase()]);
    } else {
      data.push(["PEDE O MENU | " + totals.empresa.toUpperCase()]);
    }
    data.push([" "]);
    data.push(["Relatório do dia"]);
    data.push([moment().format("DD/MM/YYYY")]);

    const margin1 = {
      left: pageDimension === "58mm" ? 6 : pageDimension === "A4" ? 10 : 1,
      right: pageDimension === "58mm" ? 6 : 1
    };
    const marginItens = {
      left: pageDimension === "A4" ? 21 : 11,
      right: 10
    };
    const _columnStyles = {
      0: {cellWidth: 10},
      1: {cellWidth: 11},
      2: {cellWidth: 12, halign: "right"}
    };
    const styleVendas = {
      fontStyle: "bold",
      fontSize: 7,
      halign: "center",
      cellWidth: centralization
    };
    const _styles = {
      cellPadding: 0,
      fontSize: 7,
      cellWidth: centralization
    };

    doc.autoTable({
      startY: pageDimension === "A4" ? 10 : 0,
      theme: "plain",
      margin: margin1,
      styles: {
        overflow: "linebreak",
        cellPadding: 0,
        fontSize: 7 + aumentoFonte,
        fontStyle: "bold",
        halign: "center",
        cellWidth: centralization
      },
      didParseCell(dataStyles) {
        if (dataStyles.row.index === 2 && pageDimension !== "58mm") {
          dataStyles.cell.styles.fontStyle = "normal";
        }
        if (dataStyles.row.index === 3 && pageDimension === "58mm") {
          dataStyles.cell.styles.fontStyle = "normal";
        }
      },
      body: data
    });

    doc.autoTable({
      startY: doc.previousAutoTable.finalY + 5,
      theme: "plain",
      margin: margin1,
      styles: {
        fontStyle: "bold",
        fontSize: 10,
        halign: "center",
        cellWidth: centralization
      },
      body: [["Total Geral"]]
    });

    data = [];
    data.push(["Pedidos", " ", (totals.totalGeral.pedidos + "       ")]);
    data.push(["Produtos", " ", this.moneyFormat(totals.totalGeral.produtos)]);
    data.push(["Entregas", " ", this.moneyFormat(totals.totalGeral.entregas)]);

    if (isNotNullOrUndefined(totals.totalGeral.taxa)) {
      data.push(["Adicional", " ", this.moneyFormat(totals.totalGeral.taxa)]);
    }

    data.push(["PoMs", " ", (this.pomFormat(totals.totalGeral.pontos) + "     ")]);
    data.push(["Total", " ", this.moneyFormat(totals.totalGeral.total)]);

    doc.autoTable({
      startY: doc.previousAutoTable.finalY + 1,
      theme: "plain",
      margin: marginItens,
      styles: _styles,
      columnStyles: _columnStyles,
      didParseCell(dataStyles) {
        if (dataStyles.row.index === 5 && isNotNullOrUndefined(totals.totalGeral.taxa)) {
          dataStyles.cell.styles.fontStyle = "bold";
        }
        if (dataStyles.row.index === 4 && isNullOrUndefined(totals.totalGeral.taxa)) {
          dataStyles.cell.styles.fontStyle = "bold";
        }
      },
      body: data
    });

    doc.autoTable({
      startY: doc.previousAutoTable.finalY + 5,
      theme: "plain",
      margin: margin1,
      styles: styleVendas,
      body: [[" "]]
    });

    if ((isNotNullOrUndefined(totals.totalDinheiro)) && (totals.totalDinheiro.pedidos > 0)) {
      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 3,
        theme: "plain",
        margin: margin1,
        styles: styleVendas,
        body: [["Dinheiro"]]
      });

      data = [];
      data.push(["Pedidos", " ", (totals.totalDinheiro.pedidos + "       ")]);
      data.push(["Produtos", " ", this.moneyFormat(totals.totalDinheiro.produtos)]);
      data.push(["Entregas", " ", this.moneyFormat(totals.totalDinheiro.entregas)]);
      if (isNotNullOrUndefined(totals.totalDinheiro.taxa)) {
        data.push(["Adicional", " ", (totals.totalDinheiro.taxa + "     ")]);
      }
      data.push(["Total", " ", this.moneyFormat(totals.totalDinheiro.total)]);

      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 1,
        theme: "plain",
        margin: marginItens,
        styles: _styles,
        columnStyles: _columnStyles,
        body: data
      });
    }

    if ((isNotNullOrUndefined(totals.totalCartao)) && (totals.totalCartao.pedidos > 0)) {
      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 3,
        theme: "plain",
        margin: margin1,
        styles: styleVendas,
        body: [["Maquina Móvel"]]
      });

      data = [];
      data.push(["Pedidos", " ", (totals.totalCartao.pedidos + "       ")]);
      data.push(["Produtos", " ", this.moneyFormat(totals.totalCartao.produtos)]);
      data.push(["Entregas", " ", this.moneyFormat(totals.totalCartao.entregas)]);
      if (isNotNullOrUndefined(totals.totalCartao.taxa)) {
        data.push(["Adicional", " ", this.moneyFormat(totals.totalCartao.taxa)]);
      }
      data.push(["Total", " ", this.moneyFormat(totals.totalCartao.total)]);

      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 1,
        theme: "plain",
        margin: marginItens,
        styles: _styles,
        columnStyles: _columnStyles,
        body: data
      });
    }

    if ((isNotNullOrUndefined(totals.totalOnline)) && (totals.totalOnline.pedidos > 0)) {
      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 3,
        theme: "plain",
        margin: margin1,
        styles: styleVendas,
        body: [["Online"]]
      });

      data.push(["Pedidos", " ", (totals.totalOnline.pedidos + "       ")]);
      data.push(["Produtos", " ", this.moneyFormat(totals.totalOnline.produtos)]);
      data.push(["Entregas", " ", this.moneyFormat(totals.totalOnline.entregas)]);
      if (isNotNullOrUndefined(totals.totalOnline.taxa)) {
        data.push(["Adicional", " ", this.moneyFormat(totals.totalOnline.taxa)]);
      }
      data.push(["Total", " ", this.moneyFormat(totals.totalOnline.total)]);

      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 1,
        theme: "plain",
        margin: marginItens,
        styles: _styles,
        columnStyles: _columnStyles,
        body: data
      });
    }

    doc.autoTable({
      startY: doc.previousAutoTable.finalY + 4,
      theme: "plain",
      margin: margin1,
      styles: styleVendas,
      body: [[" "]]
    });

    // Retorna o PDF em um formato aceitavel para impressora
    const out = doc.output("datauri");
    return out.split("base64,")[1];

  }

  private moneyFormat(value: number): string {
    const moneyFormat = new Intl.NumberFormat("pt", {style: "currency", currency: "BRL"});
    return moneyFormat.format(value);
  }

  private pomFormat(value: number): string {
    const moneyFormat = new Intl.NumberFormat("pt", {style: "decimal", maximumFractionDigits: 2});
    return moneyFormat.format(value);
  }
}
