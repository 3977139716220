import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
  selector: "app-inactive-chip",
  templateUrl: "./inactive-chip.component.html",
  styleUrls: ["./inactive-chip.component.scss"]
})
export class InactiveChipComponent implements OnInit {
  @Input()
  item: any;

  @Output()
  clickEvent = new EventEmitter<EventEmitter<boolean>>();

  // Evento para receber a notificação de quando deve parar o loading
  private resultEvent = new EventEmitter<boolean>();

  loading: boolean = false;

  constructor() { }

  ngOnInit() {
    this.resultEvent.subscribe((success) => {
      this.loading = false;
      if (success && this.item) {
        this.item.inactive = !this.item.inactive;
      }
    });
  }

  click() {
    if (!this.loading) {
      this.loading = true;
      this.clickEvent.emit(this.resultEvent);
    }
  }

}
