import {Injectable} from "@angular/core";
import {IuguService} from "./iugu.service";
import {CustomersIugu} from "../../models/iugu/customers.iugu.model";
import {AngularFireFunctions} from "@angular/fire/functions";
import {Observable} from "rxjs";
import {AuthService} from "../../modules/login/auth.service";
import {PagamentoOnline} from "../../models/pagamento-online.model";
import {SubcontaIugu} from "../../models/iugu/subconta.iugu.model";
import {DadosSaque} from "../../components/extrato-pagamento-online/saque-dialog/saque-dialog.component";

@Injectable({
  providedIn: "root"
})
export class CustomersIuguService extends IuguService<CustomersIugu> {

  constructor(public fns: AngularFireFunctions, public authService: AuthService) {
    super(fns);
  }

  cadastrarSubcontaIugu(cnpj: string, data: any, saqueAutomatico: any, antecipacao: any, isSubcontaEspelho: boolean): Observable<any> {
    return this.fns.httpsCallable("call-iugu-customers-addOrUpdateSubconta")
    ({cnpj: cnpj, data: data, saqueAutomatico: saqueAutomatico, antecipacao: antecipacao, isSubcontaEspelho: isSubcontaEspelho});
  }

  buscarSubcontaInfo(pagamentoOnline: PagamentoOnline): Observable<SubcontaIugu> {
    return this.fns.httpsCallable("call-iugu-customers-getSubcontaInfo")(pagamentoOnline);
  }

  buscarDadosSaque(cnpj: string): Observable<DadosSaque> {
    return this.fns.httpsCallable("call-iugu-customers-getWithdrawData")({cnpj: cnpj});
  }

  requestWithdraw(cnpj: string, valor: number): Observable<any> {
    return this.fns.httpsCallable("call-iugu-customers-requestWithdraw")({cnpj: cnpj, valor: valor});
  }

  deserialize(value: any): CustomersIugu {
    return new CustomersIugu(value);
  }
}
