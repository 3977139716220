import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {Repository} from "../repository/Repository";
import {ConsultorComissao} from "../models/consultor-commission.model";
import {AngularFireFunctions} from "@angular/fire/functions";
import {DialogService} from "./dialog/dialog.service";

export interface PagarComissao {
  commissions: string[];
  dataPagamento: string;
}

@Injectable({
  providedIn: "root",
})
export class ConsultorCommissionService extends Repository<ConsultorComissao> {
  constructor(db: AngularFirestore, public fns: AngularFireFunctions, public dialogService: DialogService) {
    super(db, "consultores-commissions", true);
  }

  deserialize(value: any): ConsultorComissao {
    return new ConsultorComissao(value);
  }

  public async pagarComissao(dadosComissao: PagarComissao) {
    return new Promise((resolve, reject) => {
      this.fns.httpsCallable("call-consultoresCommissions-pagarComissao")(dadosComissao).subscribe(() => {
      }, (error) => {
        reject(error);
      }, () => {
        resolve();
      });
    });
  }
}
