import {Component, Inject} from "@angular/core";
import {EntregadoresService} from "../entregadores.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {Observable} from "rxjs";
import {$query} from "../../../services/firebase/criteria/query";
import {Entregador} from "../../../models/entregador.model";
import {Criterion} from "../../../services/firebase/criteria/criterion";

@Component({
  selector: "app-select-entregador-dialog",
  templateUrl: "./select-entregador-dialog.component.html",
  styleUrls: ["./select-entregador-dialog.component.scss"]
})
export class SelectEntregadorDialogComponent {

  $entregadores: Observable<Entregador[]>;

  static openDialog(dialog: MatDialog): Observable<any> {
    const config = {
      width: "500px"
    };

    const ref = dialog.open(SelectEntregadorDialogComponent, config);
    return ref.afterClosed();
  }

  constructor(service: EntregadoresService,
              public dialogRef: MatDialogRef<SelectEntregadorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
    this.$entregadores = service.getCollection($query(new Criterion("inactive", "==", false))).valueChanges();
  }

}
