import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {FormControl, Validators} from "@angular/forms";
import {StatusPedido} from "../../../../models/status-pedido.enum";
import {StringUtils} from "../../../../utils/string-utils";
import {MotivoRejeicaoTipo, MotivoRejeicao} from "../../../../models/pedido.model";
import {GerenciadorPedidosService} from "../../service/gerenciador-pedidos.service";
import {LoadingService} from "../../../../services/loading/loading.service";
import {DialogService} from "../../../../services/dialog/dialog.service";
import {mergeMap} from "rxjs/operators";
import {of} from "rxjs/observable/of";
import {PedidosService} from "../../../../services/pedidos.service";

@Component({
  selector: "app-motivo-rejeicao-dialog",
  templateUrl: "./motivo-rejeicao-dialog.component.html",
  styleUrls: ["./motivo-rejeicao-dialog.component.scss"]
})
export class MotivoRejeicaoDialogComponent implements OnInit {

  static openDialog(dialog: MatDialog, status: string, pedidoId: string, bairroName: string): Observable<any> {
    const config = {
      width: "550px",
      data: {
        action: status === StatusPedido[StatusPedido.REJEITADO] ? "rejeitar" : "cancelar",
        status: status,
        pedidoId: pedidoId,
        bairroName: bairroName
      }
    };

    return dialog.open(MotivoRejeicaoDialogComponent, config).afterClosed();
  }

  MotivoRejeicao = MotivoRejeicaoTipo;

  motivoForm = new FormControl("", [Validators.required]);
  messageForm = new FormControl({value: "", disabled: true}, [Validators.required, Validators.minLength(10), Validators.maxLength(100)]);

  motivosObservable: Observable<MotivoRejeicao[]>;
  disableButton: boolean = false;

  constructor(public dialogRef: MatDialogRef<MotivoRejeicaoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public pedidoService: PedidosService,
              public gerenciadorService: GerenciadorPedidosService,
              public loadingService: LoadingService,
              public dialogService: DialogService) {

    this.motivosObservable = this.pedidoService.getMotivosRejeicao(data.pedidoId);
  }

  ngOnInit() {

  }

  wordToTitleCase(word: string) {
    return StringUtils.wordToTitleCase(word);
  }

  selectMotivo(reason) {
    this.motivoForm.patchValue(reason.motivo);
    this.messageForm.patchValue(reason.mensagem);

    if (reason.motivo === MotivoRejeicaoTipo[MotivoRejeicaoTipo.outro]) {
      this.messageForm.enable();
      this.messageForm.markAsTouched();
    } else {
      this.messageForm.disable();
    }
  }

  disableRejectButton() {
    if (this.motivoForm.value === MotivoRejeicaoTipo[MotivoRejeicaoTipo.outro]) {
      return !this.motivoForm.valid || !this.messageForm.valid;
    } else {
      return  !this.motivoForm.valid;
    }
  }

  // Botão para rejeitar/cancelar o pedido
  rejectButton() {
    this.disableButton = true;
    const motivo = this.motivoForm.value;

    // TODO Implementar essa parte do código, quando o card de bairros do GoogleMaps for lançado na produção
    /*// Caso esteja rejeitando por endereço indisponível
    if (motivo === MotivoRejeicao[MotivoRejeicao.localIndisponivel]) {
      // Exibir dialog perguntando se deseja bloquear o bairro do pedido
      this.dialogService.confirmDialog()
        .message(`Deseja bloquear o bairro "${this.data.bairroName}"?`)
        .acceptButton(`${this.data.action} e bloquear`)
        .cancelButton(`Apenas ${this.data.action}`)
        .show()
        .pipe(
          mergeMap(accept => {
          if (accept === true) {
            // Se confirmar o bloqueio
            return this.rejectPedido(true);
          } else if (accept === false) {
            // Se rejeitar o bloqueio
            return this.rejectPedido(false);
          } else {
            // Se fechar o dialog sem selecionar nenhuma opção
            this.disableButton = false;
            return of();
          }
          })
        ).subscribe(() => {
        this.finishRejection();
        this.dialogRef.close(true);
      }, err => {
        this.finishRejection();
        this.dialogService.messageDialog().message(err).show();
      });

    } else*/
      // Caso esteja rejeitando por fim de expediênte, falta de entregador, ou pedido expirado
      if (motivo === MotivoRejeicaoTipo[MotivoRejeicaoTipo.fimExpediente] ||
      motivo === MotivoRejeicaoTipo[MotivoRejeicaoTipo.semEntregador] ||
      motivo === MotivoRejeicaoTipo[MotivoRejeicaoTipo.pedidoExpirado]) {
        // Exibir o dialog para confirmar que a empresa ficará offline
        this.dialogService.confirmDialog()
          .cancelButton("Cancelar")
          .acceptButton("Continuar")
          .message("Ao rejeitar o pedido por esse motivo, a empresa ficará off-line!")
          .show()
          .pipe(
            mergeMap(accept => {
              if (accept) {
                return this.rejectPedido();
              } else {
                this.disableButton = false;
                return of();
              }
            })
          )
          .subscribe(() => {
            this.finishRejection();
          this.dialogRef.close(true);
        }, err => {
            this.finishRejection();
          this.dialogService.messageDialog().message(err).show();
        });

    } else {
      // Caso o motivo não seja nenhum dos anteriores, apénas rejeitar o pedido
      this.rejectPedido().subscribe(() => {
        this.finishRejection();
        this.dialogRef.close(true);
      }, err => {
        this.finishRejection();
        this.dialogService.messageDialog().message(err).show();
      });

    }
  }

  // Chamar função para rejeitar/cancelar o pedido
  rejectPedido(bloquearBairro?: boolean): Observable<void> {
    this.loadingService.showTopBar();
    return this.gerenciadorService.callRejectFunction(this.data.pedidoId, this.data.status, this.messageForm.value, this.motivoForm.value, bloquearBairro);
  }

  // Esconder loading do TopBar, e habiliar o botão
  finishRejection() {
    this.loadingService.hideTopBar();
    this.disableButton = false;
  }

}
