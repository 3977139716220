/**
 *  Componente Angular Google Maps
 *  Essa classe implementa um mapa que mostra a localização de determinada empresa.
 *  Através dele é possível, obter latitude e longitude clicando em determinando ponto, buscar ponto no mapa
 *  por endereço e por CEP.
 *  @author Vyctor
 */
/// <reference types="@types/googlemaps" />
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation} from "@angular/core";
import {Location} from "@angular-material-extensions/google-maps-autocomplete";
import {MapsAPILoader} from "@agm/core";

@Component({
  selector: "app-angular-google-maps",
  templateUrl: "./angular-google-maps.component.html",
  styleUrls: ["./angular-google-maps.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class AngularGoogleMapsComponent implements OnInit, OnChanges {

  public zoom: number;

  /**
   * Inputs: Recuperam o valor inicializado da latitude e longitude cadastrada
   * no banco de dados para o cliente que está na tela. São utilizadas na inicialização do mapa,
   * para mostrar a localização salva e na função feedBackMapCoordinates.
   */
  @Input()
  clientMapsLatitude;
  @Input()
  clientMapsLongitude;
  @Input()
  enderecoFormatado;
  @Input()
  disableChooseLocation: boolean = false;
  @Input()
  scrollWheel: boolean = false;

  // Envia as coordenadas para os inputs do formulário ao haver evento de clique.
  @Output()
  mapsCoordinates = new EventEmitter();

  constructor(private loader: MapsAPILoader) {
  }

  ngOnInit() {
    // inicializa o zoom do mapa para o valor de 17
    this.zoom = 17;
  }

  // Fica buscando mudanças no formulário
  ngOnChanges(changes: SimpleChanges) {
    this.getLocationByAddress();
  }

  // Atualiza a marcação no mapa ao pesquisar pelo endereço no input autocomplete
  onLocationSelected(location: Location) {
    this.clientMapsLatitude = location.latitude;
    this.clientMapsLongitude = location.longitude;
    this.mapsCoordinates.emit(location);
  }

  // Atualiza a marcação no mapa ao clicar em algum ponto sobre ele.
  onChooseLocation(event) {
    if (!this.disableChooseLocation) {
      this.clientMapsLatitude = event.coords.lat;
      this.clientMapsLongitude = event.coords.lng;
      this.mapsCoordinates.emit(event);
    }
  }

  // Função retorna a latitude e longitude com base no endereço obtido ao clicar em buscar o CEP
  getLocationByAddress() {
    navigator.geolocation
      .getCurrentPosition(() => {
        this.loader.load().then(() => {
          const geoCoder = new google.maps.Geocoder();
          geoCoder.geocode({"address": this.enderecoFormatado}, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              // retorna a latidude e a longitude com base no endereço
              this.clientMapsLatitude = results[0].geometry.location.lat();
              this.clientMapsLongitude = results[0].geometry.location.lng();
              const coordinates = {
                "latitude": results[0].geometry.location.lat(),
                "longitude": results[0].geometry.location.lng()
              };
              this.mapsCoordinates.emit(coordinates);
            }
          });
        });
      });

  }
}



