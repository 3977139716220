import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {BehaviorSubject, of} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "./dialog/dialog.service";
import {LoadingService} from "./loading/loading.service";
import {SnackService} from "./snack/snack.service";
import {ChangeDetectorRef, OnInit} from "@angular/core";
import {Repository} from "../repository/Repository";
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../utils/commons";
import {Subject} from "rxjs/Rx";
import {IFormCanDeactivate} from "../guards/iform-canDeactivate";
import {catchError, mergeMap} from "rxjs/operators";

export abstract class PageService<T> implements OnInit, IFormCanDeactivate {

  public loadingName: string;
  public item: T = this.newItem();
  public itemSubject = new Subject<T>();
  public formMudou: boolean = undefined;
  public afterItemRemovedObservable = new BehaviorSubject(null);

  constructor(protected service: Repository<T>,
              protected dialog: MatDialog,
              protected dialogService: DialogService,
              protected loadingService: LoadingService,
              protected snack: SnackService,
              protected cdRef: ChangeDetectorRef,
              protected route: ActivatedRoute,
              protected router: Router,
              protected routeBase: string) {
    this.loadingName = `${this.service.collectionName}.load`;
  }

  ngOnInit() {
    this.loading(true);
    this.item = this.newItem();
    if (!this.isNewRecord()) {
      this.service.getById(this.getParam("id"))
        .subscribe(item => {
          this.item = item || this.newItem();
          this.itemSubject.next(item);
          this.afterLoadItem();
          this.loading(false);
        }, () => this.loading(false), () => this.loading(false));
    } else {
      this.loading(false);
    }
  }

  protected isNewRecord(): boolean {
    return isEmpty(this.getParam("id"));
  }

  protected abstract newItem(): T;

  protected afterLoadItem() {
  }

  protected loading(show: boolean, loadingName?: string) {
    if (isNullOrUndefined(loadingName)) {
      loadingName = this.loadingName;
    }
    if (show) {
      this.loadingService.register(loadingName);
    } else {
      this.loadingService.timeOut(loadingName);
    }
  }

  protected goTo(route: string, extra?: NavigationExtras) {
    this.router.navigate([`${this.routeBase}/${route}`], extra);
  }

  getParam(paramName: string): string {
    return this.route.snapshot.paramMap.get(paramName);
  }

  saveOrUpdate(item: T = this.item) {
    // Para não perguntar se o usuário deseja sair ao salvar o formulário eu forço a variável em falso.
    this.formMudou = false;
    this.loading(true);
    const hide = (error?) => {
      if (error) {
        this.dialogService
          .messageDialog()
          .title("Erro")
          .message(error.message)
          .show();
      }
      this.loading(false);
    };

    try {

      const returnValue = this.service.saveOrUpdate(item);
      returnValue.subscribe(() => {
        this.goBack();
      }, error => hide(error), hide);

      return returnValue;

    } catch (error) {
      hide(error);
      return of(null);
    }
  }

  goBack() {
    this.goTo("list");
  }

  remove(id: string) {
    this.dialogService
      .confirmDeleteDialog()
      .show()
      .pipe(
        mergeMap(accept => {
          if (accept) {
            this.loadingService.showTopBar();
            return this.service.remove(id ? id : this.item["id"]).pipe(
              mergeMap(res => {
                if (isNotNullOrUndefined(res.message)) {
                  this.snack.show(res.message);
                } else {
                  this.snack.show("Registro excluído!");
                }
                this.loadingService.hideTopBar();
                return of(res);
              }),
              catchError(err => {
                let message;
                if (err.message) {
                  message = err.message;
                } else if (err.code === "permission-denied") {
                  message = "Sem permissão para excluir esse registro!";
                } else {
                  message = "Falha ao excluir. Por favor, tente novamente!";
                }
                this.loadingService.hideTopBar();
                return this.dialogService
                  .messageDialog()
                  .title("Atenção")
                  .message(message)
                  .show();
              })
            );
          }
          return of();
        })
      ).subscribe((value) => {
        if (!isEmpty(value)) {
          this.afterItemRemoved(id);
        }
    });
  }

  // Esse método deve ser sobrescrito na classe que herdar PageService
  afterItemRemoved(id: string) {
    // Notificar o observable
    this.afterItemRemovedObservable.next(id);
  }

  // Indica que clicou em algum botão de um item da lista,
  // e que não deve chamar o método de editar
  flagMenuTrigger: boolean = false;

  menuTrigger() {
    this.flagMenuTrigger = true;
  }

  edit(id?: string, isCopyRecord?: boolean) {
    if (!this.flagMenuTrigger) {
      const extra = isCopyRecord ? {
        queryParams: {
          copy: true
        }
      } : {};
      if (id) {
        this.goTo(`form/${id}`, extra);
      } else {
        if (isNotNullOrUndefined(this.item)) {
          this.goTo(`form/${this.item["id"]}`, extra);
        } else {
          this.create();
        }
      }
    }
    this.flagMenuTrigger = !this.flagMenuTrigger;
  }

  create() {
    this.goTo("form");
  }

  // Caso o formulário foi modificado
  podeMudarRota(): boolean {
    if (this.formMudou === true) {
      return confirm("Os dados ainda não foram salvos. Deseja sair mesmo assim?");
    }
    return true;
  }

  onChanges() {
    throw new Error("Method not implemented.");
  }

}
