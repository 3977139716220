import {Dia} from "./dia";
import {isNullOrUndefined} from "../utils/commons";

export class Horario {

  public segunda: Dia = new Dia();
  public terca: Dia = new Dia();
  public quarta: Dia = new Dia();
  public quinta: Dia = new Dia();
  public sexta: Dia = new Dia();
  public sabado: Dia = new Dia();
  public domingo: Dia = new Dia();

  constructor(horario: any = {}) {
    if (!isNullOrUndefined(horario)) {
      this.domingo = !isNullOrUndefined(horario.domingo) ? new Dia(horario.domingo) : new Dia({desc: "Domingo"});
      this.segunda = !isNullOrUndefined(horario.segunda) ? new Dia(horario.segunda) : new Dia({desc: "Segunda"});
      this.terca = !isNullOrUndefined(horario.terca) ? new Dia(horario.terca) : new Dia({desc: "Terça"});
      this.quarta = !isNullOrUndefined(horario.quarta) ? new Dia(horario.quarta) : new Dia({desc: "Quarta"});
      this.quinta = !isNullOrUndefined(horario.quinta) ? new Dia(horario.quinta) : new Dia({desc: "Quinta"});
      this.sexta = !isNullOrUndefined(horario.sexta) ? new Dia(horario.sexta) : new Dia({desc: "Sexta"});
      this.sabado = !isNullOrUndefined(horario.sabado) ? new Dia(horario.sabado) : new Dia({desc: "Sábado"});
    }
  }

  public toAny(): any {
    return {
      domingo: this.domingo.toAny(),
      segunda: this.segunda.toAny(),
      terca: this.terca.toAny(),
      quarta: this.quarta.toAny(),
      quinta: this.quinta.toAny(),
      sexta: this.sexta.toAny(),
      sabado: this.sabado.toAny()
    };
  }
}
