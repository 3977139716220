import {ChangeDetectorRef, Component, ViewChild} from "@angular/core";
import {PageService} from "../../../services/page.service";
import {Cozinha} from "../../../models/cozinha.model";
import {CozinhasService} from "../cozinhas.service";
import { MatDialog } from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CozinhasFieldsComponent} from "../cozinhas-fields/cozinhas-fields.component";

@Component({
  selector: "app-cozinhas-form",
  templateUrl: "./cozinhas-form.component.html",
  styleUrls: ["./cozinhas-form.component.scss"]
})
export class CozinhasFormComponent extends PageService<Cozinha> {

  @ViewChild(CozinhasFieldsComponent, { static: true })
  fieldsComponent: CozinhasFieldsComponent;

  constructor(service: CozinhasService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/cozinhas");

  }

  protected newItem(): Cozinha {
    return undefined;
  }

  podeMudarRota(): boolean {
    return this.fieldsComponent.podeMudarRota();
  }
}
