import {AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {DeliveryBairro} from "../../../models/delivery-bairro.model";
import {PedeQueChegaBairrosService} from "../pede-que-chega-bairros.service";
import {DeliveryService} from "../delivery.service";
import {PerfilService} from "../../perfil-acesso/perfil.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {StringUtils} from "../../../utils/string-utils";
import {Role} from "../../../models/perfil-acesso.model";
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";
import {Delivery, TipoDelivery} from "../../../models/delivery.model";
import {mergeMap} from "rxjs/operators";
import {AuthService} from "../../../modules/login/auth.service";
import {ImportarExportarBairros} from "../../../services/importar-exportar-bairros";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DeliveryBairrosImportDialogComponent} from "../delivery-bairros-import-dialog/delivery-bairros-import-dialog.component";
import {NotFoundBairrosDialogComponent} from "../not-found-bairros-dialog/not-found-bairros-dialog.component";

@Component({
  selector: "app-delivery-bairros-form",
  templateUrl: "./delivery-bairros-form.component.html",
  styleUrls: ["./delivery-bairros-form.component.scss"],
})
export class DeliveryBairrosFormComponent implements OnInit, OnDestroy, AfterViewChecked {

  @Input()
  delivery: Delivery;

  @Output()
  bairrosChanged = new EventEmitter();

  @Output()
  disableSave = new EventEmitter();

  @ViewChild("virtualView", {static: false})
  virtualView: CdkVirtualScrollViewport;

  form: FormGroup;
  formArray: FormArray;
  searchControl = new FormControl();
  selectAllControl = new FormControl();

  bairrosObservable: Observable<DeliveryBairro[]>;
  bairrosSubscription: Subscription = new Subscription();
  bairros: DeliveryBairro[];
  // Lista com os bairros filtrados pela busca
  filteredItemsList: DeliveryBairro[] = [];
  // Lista de bairros que será utilizada no virtual scroll
  listArray: DeliveryBairro[] = [];

  isNullOrUndefined = isNullOrUndefined;
  pedeQueChega = TipoDelivery[TipoDelivery.pede_que_chega];

  hasUpdatePermission: boolean = false;
  hasImportPermission: boolean = false;
  disableVirtualList: boolean = false;

  // Totalização dos bairros
  bairrosLenght: number;
  bloqueadosLenght: number;

  constructor(public formBuilder: FormBuilder,
              public pqcBairrosService: PedeQueChegaBairrosService,
              public deliveryService: DeliveryService,
              public perfilService: PerfilService,
              public cdRef: ChangeDetectorRef,
              public dialogService: DialogService,
              public auth: AuthService,
              public importExport: ImportarExportarBairros,
              public dialog: MatDialog
  ) {

    this.form = this.formBuilder.group({
      bairros: this.formBuilder.array([])
    });

    this.formArray = this.form.get("bairros") as FormArray;
  }

  openImportDialog() {
    this.dialog.open(DeliveryBairrosImportDialogComponent, <MatDialogConfig>{
      width: "900px",
      disableClose: true
    }).afterClosed().subscribe(file => {
      if (isNotNullOrUndefined(file)) {
        this.disableSave.emit(true);
        this.importExport.importBairros(file, this.bairros)
          .then((newDeliveryBairros) => {
            this.filteredItemsList = [];
            // Scrollar lista para o utilmo valor, para resetar os valores da lista
            this.virtualView.scrollToIndex(this.virtualView.getRenderedRange().end);

            // Restar lista de bairros com os novos valores
            const auxDelivery = this.delivery;
            auxDelivery.bairros = this.buscarNovosDeliveryBairros(newDeliveryBairros);
            this.buscarBairros(auxDelivery, true);

            if (isNotNullOrUndefined(newDeliveryBairros.newBairros) && !isEmpty(newDeliveryBairros.newBairros)) {
              NotFoundBairrosDialogComponent.openDialog(this.dialog, newDeliveryBairros.newBairros);
            }

            this.disableSave.emit(false);
            this.bairrosChanged.emit(true);
          });
      }
    });
  }

  // Buscar DeliveryBairros que serão salvos no Delivery
  buscarNovosDeliveryBairros(bairrosObj: any): DeliveryBairro[] {
    return bairrosObj.bairros.filter(bairro => isNotNullOrUndefined(bairro.valorEntrega) || bairro.bloqueado);
  }

  // Exportar lista de bairros para um arquivo .xlsx
  exportarExcel() {
    this.importExport.exportBairros(this.bairros);
  }

  buscarBairros(delivery: Delivery, refreshScroll?: boolean) {
    // Se o tipo selecionado for pede_que_chega e o usuário não for lojista, carrega os bairros configurados do pedeQueChega
    if (delivery.tipo === TipoDelivery[TipoDelivery.pede_que_chega] && !this.auth.user.isLojista()) {
      this.bairrosObservable = this.pqcBairrosService.col$().pipe(mergeMap((pqcBairros: DeliveryBairro[]) => {
        pqcBairros.map(bairro => {
          if (bairro.idPedeQueChega === "") {
            bairro.idPedeQueChega = null;
          }
          return bairro;
        });
        return this.deliveryService.loadBairrosAndMerge(pqcBairros);
      }));

      // Se não, carrega todos os bairros do sistema e mescla com os bairros configurados do delivery
    } else {
      this.bairrosObservable = this.deliveryService.loadBairrosAndMerge(delivery.bairros).pipe();
    }

    this.bairrosSubscription.unsubscribe();
    this.bairrosSubscription = this.bairrosObservable.subscribe((bairros: DeliveryBairro[]) => {
      this.bairros = bairros;
      this.bairrosLenght = bairros.length;
      this.bloqueadosLenght = bairros.filter(value => value.bloqueado).length;
      this.buildForm();
      this.form.get("bairros").patchValue(this.bairros);
      this.searchBairros();
      this.fillArray();
      if (refreshScroll === true) {
        // Caso o código tenha sido chamado pela importação, scrollar lista para o topo
        if (isNotNullOrUndefined(this.virtualView)) this.virtualView.scrollToIndex(0);
      }
    });
  }

  ngOnInit() {
    // Verificar permissão do usuário
    this.perfilService.getPerfilAcessoLoggedUser().subscribe(perfilAcesso => {
      // Verifica se possui permissão de update ou se possui permissão de criar e o delivery é novo
      this.hasUpdatePermission = perfilAcesso.hasRole(Role.DeliveryUpdate) || isNullOrUndefined(this.delivery.id) && perfilAcesso.hasRole(Role.DeliveryCreate);

      // Verifica se possui prmissão de importar configurações de bairro
      this.hasImportPermission = perfilAcesso.hasRole(Role.DeliveryImport);
    });

    this.buscarBairros(this.delivery);

    this.form.get("bairros").valueChanges.subscribe((bairros: DeliveryBairro[]) => {
      this.bloqueadosLenght = bairros.filter(value => value.bloqueado).length;
      if (!this.disableVirtualList) {
        if (this.formArray.dirty) {
          // Emitir evento de valor alterado
          this.bairrosChanged.emit(true);
        }
      }
    });
  }

  toggleCheckBoxes(): void {
    let index = 0;
    for (const bairro of this.filteredItemsList) {
      if (isNotNullOrUndefined(bairro)) {
        this.formArray.controls[index].get("bloqueado").patchValue(this.selectAllControl.value);
        this.formArray.controls[index].get("bloqueado").markAsDirty();
      }
      index++;
    }
  }

  getFilteredBairros(): AbstractControl[] {
    return this.formArray.controls.filter((formGroup: FormGroup, index: number) => {
      return this.filteredItemsList.some(filteredItemsList => isNotNullOrUndefined(filteredItemsList) && filteredItemsList.bairro === formGroup.get("bairro").value);
    });
  }

  checkIfAllSelected(): boolean {
    const selectAll = this.getFilteredBairros().every(formGroup => formGroup.get("bloqueado").value);
    this.selectAllControl.patchValue(selectAll);
    return selectAll;
  }

  hasSomeSelected(): boolean {
    return this.getFilteredBairros().some(formGroup => formGroup.get("bloqueado").value);
  }

  // Função para pequisar os bairros sem alterar a ordem do formArray
  searchBairros() {
    this.searchControl.valueChanges.subscribe((searchTerm: string) => {
      this.virtualView.scrollToIndex(0);
      this.filteredItemsList = this.bairros.filter(bairro => StringUtils.normalize(bairro.bairro.nome.toLowerCase()).includes(StringUtils.normalize(searchTerm.toLowerCase())));
      this.fillArray();
    });
  }

  // Cria o formArray dinamicamente
  buildForm() {
    let index = 0;
    this.filteredItemsList = [];
    this.formArray.clear();
    for (const val of this.bairros) {
      this.filteredItemsList.push(val);
      this.formArray.push(
        this.formBuilder.group({
            bairro: [],
            valorEntrega: [{value: "", disabled: false}],
            bloqueado: [false],
            idPedeQueChega: [null],
            gratis: [false]
          }
        ));
      this.disableForms(this.formArray.at(index));
      index++;
    }
  }

  // Modificar valores dos FormControls dinamicamente dependendo das mudanças realizadas no FormGroup de cada bairro
  disableForms(group: AbstractControl) {
    group.get("bloqueado").valueChanges.subscribe(block => {
      if (block === true) {
        group.get("valorEntrega").disable({emitEvent: false});
        group.get("gratis").disable({emitEvent: false});
      } else {
        group.get("valorEntrega").enable({emitEvent: false});
        group.get("gratis").enable({emitEvent: false});
      }
    });
    group.get("valorEntrega").valueChanges.subscribe(val => {
      if (val === 0) {
        group.get("gratis").patchValue(true);
      } else {
        group.get("gratis").patchValue(false);
      }
    });
  }

  // Preencher a lista com os valores filtrados do filteredArrayList
  fillArray() {
    /*this.listArray = [];
    for (const value of this.filteredItemsList) {
      if (isNotNullOrUndefined(value)) this.listArray.push(value);
    }*/
    this.listArray = this.filteredItemsList;
  }

  // Procurar a posição do FormControl dentro do FormArray correspondente com a row do VirtualScroll
  indexFinder(row: DeliveryBairro): string {
    const index = this.formArray.controls.findIndex(control => isNotNullOrUndefined(control.value.bairro) && (control.value.bairro.id === row.bairro.id));
    if (index >= 0) {
      return index.toString();
    } else {
      return null;
    }
  }

  // Modificar estilo do item da lista caso o mesmo esteja bloqueado
  getStyleBairro(bairro: DeliveryBairro) {
    const index = this.formArray.controls.findIndex(control => control.value.bairro === bairro.bairro);
    if (isNotNullOrUndefined(index) && index >= 0) {
      return {
        color: (this.formArray.at(index).get("bloqueado").value ? "gray" : null),
        "text-decoration-line": this.formArray.at(index).get("bloqueado").value ? "line-through" : null
      };
    } else {
      return {color: null};
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (isNotNullOrUndefined(this.bairrosSubscription)) {
      this.bairrosSubscription.unsubscribe();
    }
  }

}
