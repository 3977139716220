import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, of, throwError} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {environment} from "../../environments/environment";
import * as moment from "moment";

@Injectable({
  providedIn: "root"
})
export class HubDoDevService {

  readonly url: string = "https://ws.hubdodesenvolvedor.com.br/v2/";
  readonly token: string = environment.hub_do_dev_token;

  constructor(private http: HttpClient) { }

  sanitizeString(stringToSanitize: string): string {
    return stringToSanitize.toString().replace(/[^\d]+/g, "");
  }

  requestData(url: string): Observable<any> {
    return this.http.get<any>(url).pipe(
      mergeMap(result => {
        if (!result.status) {
          return throwError(result.message ? result.message : "Erro desconhecido!");
        } else {
          return of(result);
        }
      })
    );
  }

  getLastUpdate(cpfOrCnpj: string, dataNascimento?: string): Observable<any> {
    const cpfOrCnpjSanitized: string = this.sanitizeString(cpfOrCnpj);
    if (dataNascimento) {
      return this.http.get<any>(`${this.url}cpf/?cpf=${cpfOrCnpjSanitized}&data=${dataNascimento}&last_update=2&token=${this.token}`);
    } else {
      return this.http.get<any>(`${this.url}cnpj/?cnpj=${cpfOrCnpjSanitized}&last_update=2&token=${this.token}`);
    }
  }

  getEmpresaPessoa(cpfOrCnpj: string, ignoreDb: boolean, dataNascimento?: string): Observable<any> {
    const cpfOrCnpjSanitized: string = this.sanitizeString(cpfOrCnpj);
    if (dataNascimento) {
      if (ignoreDb) {
        return this.requestData(`${this.url}cpf/?cpf=${cpfOrCnpjSanitized}&data=${dataNascimento}&ignore_db&token=${this.token}`);
      } else {
        return this.requestData(`${this.url}cpf/?cpf=${cpfOrCnpjSanitized}&data=${dataNascimento}&token=${this.token}`);
      }
    } else {
      if (ignoreDb) {
        return this.requestData(`${this.url}cnpj/?cnpj=${cpfOrCnpjSanitized}&ignore_db&token=${this.token}`);
      } else {
        return this.requestData(`${this.url}cnpj/?cnpj=${cpfOrCnpjSanitized}&token=${this.token}`);
      }
    }
  }

  checkAndGetEmpresaPessoa(cpfOrCnpj: string, dataNascimento?: string): Observable<any> {
    return this.getLastUpdate(cpfOrCnpj, dataNascimento).pipe(
      mergeMap(value => {
        if (value.status) {
          const lastUpdate = moment(value.result.last_update, "DD/MM/YYYY");
          const monthsDiff = moment().diff(lastUpdate, "months");
          return this.getEmpresaPessoa(cpfOrCnpj, monthsDiff >= 1, dataNascimento);
        }
        return this.getEmpresaPessoa(cpfOrCnpj, false, dataNascimento);
      })
    );
  }

}
