import {AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {forkJoin, Observable, of} from "rxjs";
import {finalize, mergeMap, take, tap} from "rxjs/operators";
import {Empresa, StatusEmpresa, TipoBloqueio} from "../../../models/empresa";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {PageService} from "../../../services/page.service";
import {SnackService} from "../../../services/snack/snack.service";
import {DeliveryService} from "../../delivery/delivery.service";
import {FormaPagamentoService} from "../../forma-pagamento/forma-pagamento.service";
import {ProdutoService} from "../../produto/produto.service";
import {UsersService} from "../../users/users.service";
import {EmpresaService} from "../empresa.service";
import {FormControl} from "@angular/forms";
import {CidadeService} from "../../cidade/cidade.service";
import {Cidade} from "../../../models/cidade.model";
import {StringUtils} from "../../../utils/string-utils";
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";
import {AuthService} from "../../../modules/login/auth.service";
import {BlockEmpresaDialogComponent} from "../block-empresa-dialog/block-empresa-dialog.component";
import * as firebase from "firebase";
import {Role} from "../../../models/perfil-acesso.model";
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: "empresa-list",
  templateUrl: "./empresa-list.component.html",
  styleUrls: ["./empresa-list.component.scss"]
})
export class EmpresaListComponent extends PageService<Empresa> implements OnInit, AfterViewChecked {

  readonly StatusEmpresa = StatusEmpresa;
  readonly TipoBloqueio = TipoBloqueio;

  empresas$: Observable<Empresa[]> = of([]);
  empresasLength: number;

  cidades$: Observable<Cidade[]>;

  cidadeControl = new FormControl();
  statusControl = new FormControl();
  searchControl: FormControl = new FormControl();
  onlineControl = new FormControl(false);

  // Indica que está fazendo a pesquisa de empresas
  isLoadingCompanies: boolean = false;

  listHeight: number;
  Role = Role;

  private empresas: Empresa[] = [];
  empresasAbertasLength: number = 0;
  empresasFechadasLength: number = 0;
  empresasPendentesLength: number = 0;
  empresasProntasLength: number = 0;
  empresasLiberadasLength: number = 0;
  empresasSuspensasLength: number = 0;
  empresasBloqueadasLength: number = 0;
  empresasPtoOnlineLength: number = 0;

  constructor(public service: EmpresaService,
              private produtoService: ProdutoService,
              private userService: UsersService,
              private deliveryService: DeliveryService,
              private formasPagamentoService: FormaPagamentoService,
              private cidadeService: CidadeService,
              dialog: MatDialog,
              public dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              public authService: AuthService) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/empresa/");
    this.cidades$ = this.cidadeService.col$().pipe(
      tap(cidades => {
        if (cidades.length === 1) {
          this.cidadeControl.patchValue(cidades[0]);
        }
      })
    );

    if (this.service.filters.cidade) {
      this.cidadeControl.patchValue(this.service.filters.cidade);
    }

    if (this.service.filters.status) {
      this.statusControl.patchValue(this.service.filters.status);
    }

    if (this.service.filters.searchValue) {
      this.searchControl.patchValue(this.service.filters.searchValue);
    }

    if (this.service.filters.pagtoOnline) {
      this.onlineControl.patchValue(this.service.filters.pagtoOnline);
    }

    if (this.service.filters.cidade || !isEmpty(this.service.filters.status) ||
      !isEmpty(this.service.filters.searchValue) || this.service.filters.pagtoOnline) {
      this.buscar();
    }

  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewChecked(): void {
    this.listHeight = this.getListHeight();
    this.cdRef.detectChanges();
  }

  getListHeight(): number {
    const matCardElement: HTMLElement = document.getElementById("mat-card");
    const titleGroupElement: HTMLElement = document.getElementById("title-group");
    const footerElement: HTMLElement = document.getElementById("footer");

    if (matCardElement && titleGroupElement) {
      return matCardElement.clientHeight - titleGroupElement.clientHeight - (footerElement ? footerElement.clientHeight : 0);
    }
    return null;
  }

  compareWithCidade(c1: Cidade, c2: Cidade): boolean {
    return c1 && c2 && c1.id === c2.id;
  }

  newItem(): Empresa {
    return null;
  }

  getPhones(item): string {
    const phones = item.telefones;
    return phones.length > 0 ? phones[0].numero : "";
  }

  statusFilter(): string[] {
    const status: string[] = ["Aberto", "Fechado"];
    Object.keys(StatusEmpresa).forEach(_status => {
      status.push(StringUtils.wordToTitleCase(_status));
    });
    status.push("Bloqueado");
    return status;
  }

  private marcarPronto(empresa: Empresa) {
    if (empresa.status === StatusEmpresa.SUSPENSO) {
      this.callMotivoDialog(
        `Marcar ${empresa.nomeFantasia.trim()} como Pronta?`,
        "Para colocar uma empresa que está Suspensa como Pronta é necessário informar o motivo.",
        `${empresa.nomeFantasia} foi marcada como PRONTA! A partir de agora ela estará visível para os usuários do aplicativo como "Em Breve".`,
        empresa,
        StatusEmpresa.PRONTO
      );
    } else {
      (this.service as EmpresaService)
        .marcarPronto(empresa)
        .subscribe(() => {
          this.loadingService.hideTopBar();
          this.atualizarTotais();
          this.dialogService
            .messageDialog()
            .title("Informação")
            .message(`${empresa.nomeFantasia} ficou PRONTA! A partir de agora ela ficará visível no aplicativo com a informação "Em breve!".`)
            .show();
        });
    }
  }

  private marcarPendente(empresa: Empresa, showMotivoDialog?: boolean) {
    if (empresa.status === StatusEmpresa.SUSPENSO || showMotivoDialog) {
      this.callMotivoDialog(
        `Marcar ${empresa.nomeFantasia.trim()} como Pendente?`,
        "Para colocar uma empresa que está Suspensa como Pendente é necessário informar o motivo.",
        `${empresa.nomeFantasia} foi marcada como PENDENTE!`,
        empresa,
        StatusEmpresa.PENDENTE
      );
    } else {
      (this.service as EmpresaService)
        .marcarPendente(empresa)
        .subscribe(() => {
          this.loadingService.hideTopBar();
          this.atualizarTotais();
          this.dialogService
            .messageDialog()
            .title("Informação")
            .message(`${empresa.nomeFantasia} está pendente!`)
            .show();
        });
    }
  }

  callMotivoDialog(titleMessage: string, message: string, infoMessage, empresa: Empresa, status: string) {
    this.dialogService.promptDialog()
      .title(titleMessage)
      .message(message)
      .show()
      .pipe(
        take(1),
        mergeMap(motivo => {
          // Clicou em cancelar
          if (isNullOrUndefined(motivo)) {
            return of();
          }

          // Verificar se o usuário informou o motivo
          if (motivo.trim().length < 10) {
            return this.dialogService.messageDialog()
              .message("Informe um motivo com mínimo de 10 caracteres!")
              .show();
          } else {
            return this.service.changeStatus(empresa, status, motivo).pipe(
              take(1),
              mergeMap(() => {
                this.atualizarTotais();
                return this.dialogService
                  .messageDialog()
                  .title("Atenção")
                  .message(infoMessage)
                  .show();
              })
            );
          }
        }),
        finalize(() => this.loadingService.hideTopBar())
      ).subscribe();
  }

  private liberarProducao(empresa) {

    const message = `${empresa.nomeFantasia} foi LIBERADA PARA PRODUÇÃO! A qualquer momento o restaurante poderá iniciar o recebimento de pedidos.`;

    const callback = (error?) => {
        this.loadingService.hideTopBar();
      this.atualizarTotais();
        this.dialogService
          .messageDialog()
          .title(error ? "Erro" : "Informação")
          .message(error ? error : message)
          .show();
      };

      if (empresa.status === StatusEmpresa.SUSPENSO) {
        this.callMotivoDialog(
          `Liberar ${empresa.nomeFantasia.trim()}?`,
          "Para Liberar uma Empresa que está Suspensa para Produção é necessário informar o motivo.",
          message,
          empresa,
          StatusEmpresa.LIBERADO
        );
      } else {
        this.service.changeStatus(empresa, StatusEmpresa.LIBERADO).subscribe(callback, callback);
      }
  }

  private suspenderEmpresa(empresa) {
    this.callMotivoDialog(
      `Suspender ${empresa.nomeFantasia.trim()}?`,
      "Ao suspender a empresa ela não aparecerá no aplicativo e nem poderá abrir o gerenciador de pedidos.",
      `${empresa.nomeFantasia} foi suspensa!`,
      empresa,
      StatusEmpresa[StatusEmpresa.SUSPENSO]
    );
  }

  validaEmpresa(_empresa: Empresa, status: StatusEmpresa) {
    this.loadingService.showTopBar();

    const onError = (err) => {
      this.loadingService.hideTopBar();
      this.dialogService
        .messageDialog()
        .message(err)
        .show();
    };

    this.service.getById(_empresa.id).subscribe(empresa => {

      // Observables validações da empresa
      const produtosIsEmpty = this.produtoService.produtoIsEmpty(empresa).pipe(take(1));
      const usersIsEmpty = this.userService.usersIsEmpty(empresa).pipe(take(1));
      const deliveryIsEmpty = this.deliveryService.deliveryIsEmpty(empresa).pipe(take(1));
      const formaPagamentoIsEmpty = this.formasPagamentoService.formaPagamentoIsEmpty(empresa).pipe(take(1));
      const horarioFuncionamentoIsEmpty = this.service.horarioFuncionamentoIsEmpty(empresa).pipe(take(1));
      const telefoneIsEmpty = this.service.telefoneIsEmpty(empresa).pipe(take(1));

      let validations: Observable<boolean[]>;

      if (status === StatusEmpresa.PRONTO) {
        validations = forkJoin(produtosIsEmpty);
      } else if (status === StatusEmpresa.LIBERADO) {
        validations = forkJoin(usersIsEmpty, deliveryIsEmpty, formaPagamentoIsEmpty, produtosIsEmpty, horarioFuncionamentoIsEmpty, telefoneIsEmpty);
      } else {
        if (status === StatusEmpresa.PENDENTE) {
          this.marcarPendente(_empresa);
        } else if (status === StatusEmpresa.SUSPENSO) {
          this.suspenderEmpresa(_empresa);
        }
        return;
      }

      this.loadingService.showTopBar();
      validations.subscribe(data => {
        this.loadingService.hideTopBar();
        if (data && data.every(value => value === false)) {
          if (status === StatusEmpresa.PRONTO) {
            this.marcarPronto(_empresa);
          } else if (status === StatusEmpresa.LIBERADO) {
            this.liberarProducao(_empresa);
          } else if (status === StatusEmpresa.PENDENTE) {
            this.marcarPendente(_empresa);
          }
        } else {
          this.loadingService.hideTopBar();

          const values: any = {
            nome: _empresa.nomeFantasia
          };

          if (data.length === 1) {
            values["produtos"] = data[0];
          } else {
            values["usuario"] = data[0];
            values["delivery"] = data[1];
            values["formaPagamento"] = data[2];
            values["produtos"] = data[3];
            values["horarioFunciomento"] = data[4];
            values["telefone"] = data[5];
          }

          this.dialog.open(EmpresaListDialogComponent, <MatDialogConfig>{
            width: "600px",
            data: values
          });
        }
      }, onError);
    }, onError);

  }

  buscar() {
    if (this.disableBuscarButton()) return;

    this.isLoadingCompanies = true;
    const cidade = isNullOrUndefined(this.cidadeControl.value) ? null : this.cidadeControl.value;
    const status = this.statusControl.value;
    const searchValue = this.searchControl.value;
    const pagtoOnline = this.onlineControl.value;

    // Manter os filtros no service
    this.service.filters.cidade = cidade;
    this.service.filters.status = status;
    this.service.filters.searchValue = searchValue;
    this.service.filters.pagtoOnline = pagtoOnline;

    const list = document.getElementById("virtualScroll");
    if (list) {
      list.scrollTop = 0;
    }

    this.empresas$ = this.service.search(cidade ? cidade.id : null, status, searchValue, pagtoOnline).pipe(
      tap((empresas) => {
        this.empresas = empresas;
        this.atualizarTotais();
      }),
      finalize(() => this.isLoadingCompanies = false)
    );
  }

  private atualizarTotais() {
    this.empresasLength = this.empresas.length;
    this.empresasAbertasLength = this.empresas.filter(empresa => empresa.disponivel).length;
    this.empresasFechadasLength = this.empresas.filter(empresa => isNullOrUndefined(empresa.disponivel) || !empresa.disponivel).length;
    this.empresasPendentesLength = this.empresas.filter(empresa => empresa.status === StatusEmpresa.PENDENTE).length;
    this.empresasProntasLength = this.empresas.filter(empresa => empresa.status === StatusEmpresa.PRONTO).length;
    this.empresasLiberadasLength = this.empresas.filter(empresa => empresa.status === StatusEmpresa.LIBERADO).length;
    this.empresasSuspensasLength = this.empresas.filter(empresa => empresa.status === StatusEmpresa.SUSPENSO).length;
    this.empresasBloqueadasLength = this.empresas.filter(empresa => empresa.bloqueio && empresa.bloqueio.bloqueado).length;
    this.empresasPtoOnlineLength = this.empresas.filter(empresa => isNotNullOrUndefined(empresa.pagamentoOnline) && isNotNullOrUndefined(empresa.pagamentoOnline)).length;
  }

  blockEmpresa(empresa: Empresa) {
    let title: string;
    let message: string;

    const bloqueado = empresa.bloqueio ? empresa.bloqueio.bloqueado : false;

    if (bloqueado) {
      title = `Desbloquear ${empresa.nomeFantasia.trim()}?`;
      message = "Informe o motivo do desbloqueio. Após desbloquear a empresa ela poderá iniciar o gerenciador de pedidos.";
    } else {
      title = `Bloquear ${empresa.nomeFantasia.trim()}?`;
      message = "Informe o motivo do bloqueio. Após bloquear a empresa ela não poderá iniciar o gerenciador de pedidos enquanto estiver bloqueada.";
    }

    this.dialog.open(BlockEmpresaDialogComponent, <MatDialogConfig>{
      width: "500px",
      data: {
        title: title,
        message: message,
        bloqueado: bloqueado,
        tipo: empresa.bloqueio ? empresa.bloqueio.tipo : null,
        empresa: empresa
      },
      disableClose: true
    }).afterClosed().pipe(
      mergeMap(value => {
        if (value) {
          this.loadingService.showTopBar();
          const momentToDate = isNullOrUndefined(value.date) ? null : value.date.toDate();
          if (isNotNullOrUndefined(momentToDate)) {
            momentToDate.setHours(11, 30, 0, 0);
          }
          return this.service.bloquear(empresa, {
            bloqueado: !bloqueado,
            tipo: value.tipo,
            motivo: value.motivo,
            dataVerificacao: momentToDate ? Timestamp.fromMillis(momentToDate) : null,
            iuguinvoiceId: value.invoice
          }).pipe(
            finalize(() => {
              this.loadingService.hideTopBar();
              this.atualizarTotais();
              this.dialogService
                .messageDialog()
                .title("Informação")
                .message(`${empresa.nomeFantasia} foi ${bloqueado ? "desbloqueada" : "bloqueada"}!`)
                .show();
            })
          );
        }
        return of();
      })
    ).subscribe();
  }

  disableBuscarButton() {
    return this.isLoadingCompanies ||
      (isEmpty(this.cidadeControl.value) && isEmpty(this.statusControl.value) &&
      isEmpty(this.searchControl.value) && this.onlineControl.value === false);
  }
}

@Component({
  selector: "empresa-list-dialog",
  templateUrl: "empresa-list-dialog.html"
})
export class EmpresaListDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data) { }
}
