import {Component, OnInit, ViewChild} from "@angular/core";
import {InvoiceIuguService} from "../../../services/iugu/invoice-iugu.service";
import {Observable, Subscription} from "rxjs";
import {getStatusInvoice, StatusInvoice} from "../../../models/iugu/invoice.iugu.model";
import {MatPaginator} from "@angular/material/paginator";
import {mergeMap, take, tap} from "rxjs/operators";
import {LoadingService} from "../../../services/loading/loading.service";
import * as moment from "moment";
import {Moment} from "moment";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {MatDatepicker} from "@angular/material/datepicker";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Role} from "../../../models/perfil-acesso.model";
import {GerarNovoInvoiceDialogComponent} from "../gerar-novo-invoice-dialog/gerar-novo-invoice-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {EmpresaService} from "../../empresa/empresa.service";
import {Empresa} from "../../../models/empresa";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {DialogService} from "../../../services/dialog/dialog.service";
import {DarBaixaInvoiceDialogComponent} from "../dar-baixa-invoice-dialog/dar-baixa-invoice-dialog.component";
import {HasPermissionService} from "../../../modules/login/has-permission.service";
import {PageEvent} from "@angular/material/paginator";

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-financeiro-list",
  templateUrl: "./financeiro-list.component.html",
  styleUrls: ["./financeiro-list.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class FinanceiroListComponent implements OnInit {
  form: FormGroup;
  invoicesSubscription: Subscription;
  StatusInvoice = StatusInvoice;
  getStatusInvoice = getStatusInvoice;
  matPaginator: MatPaginator;
  length: number;
  pageSize: number = 25;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [25, 50, 100];
  Role = Role;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  displayedColumns: string[] = ["due_date", "customer_name", "email", "total", "status", "gerarNova"];
  dataSource;
  loadingName: string = "FinanceiroListLoading";
  hasDarBaixaPermission$: Observable<boolean>;
  hasGerarFaturaPermission$: Observable<boolean>;

  constructor(public invoicesService: InvoiceIuguService,
              public loadingService: LoadingService,
              public dialog: MatDialog,
              public dialogService: DialogService,
              public empresaService: EmpresaService,
              public formBuilder: FormBuilder,
              public hasPermissionService: HasPermissionService) {

    this.hasDarBaixaPermission$ = this.hasPermissionService.hasPermission([Role[Role.FinanceiroBaixa]]);
    this.hasGerarFaturaPermission$ = this.hasPermissionService.hasPermission([Role[Role.FinanceiroGenerateInvoice]]);

    this.form = formBuilder.group({
      mes: [moment()],
      status: [null],
      iuguCustomerId: [null]
    });
  }

  ngOnInit() {
    this.buscar();

    this.invoicesService.loading$.asObservable().subscribe(loading => {
      if (loading) {
        this.loadingService.showTopBar();
      } else {
        this.loadingService.hideTopBar();
      }
    });
  }

  getStatusColor(status: string) {
    switch (status) {
      case StatusInvoice[StatusInvoice.canceled]: {
        return "#a30009";
      }

      case StatusInvoice[StatusInvoice.expired]: {
        return "#9e9e9e";
      }

      case StatusInvoice[StatusInvoice.paid]: {
        return "green";
      }

      case StatusInvoice[StatusInvoice.pending]: {
        return "#fbba13";
      }

      default: {
        return null;
      }
    }
  }

  flagMenuTrigger: boolean = false;

  menuTrigger() {
    this.flagMenuTrigger = true;
  }

  statusInvoice(): string[] {
    return Object.keys(StatusInvoice);
  }

  paginatorChange(matPaginator) {
    this.matPaginator = matPaginator;
    this.invoicesService.pageEvent$.next(<PageEvent>{
      pageSize: matPaginator.pageSize,
      pageIndex: matPaginator.pageIndex,
      length: matPaginator.length
    });
  }

  compareWithStatus(s1: StatusInvoice, s2: StatusInvoice): boolean {
    return s1 === s2;
  }

  buscar() {
    if (this.matPaginator) {
      this.matPaginator.firstPage();
    }

    if (this.invoicesSubscription) {
      this.invoicesSubscription.unsubscribe();
    }

    this.loadingService.register(this.loadingName);

    let sortByField;
    let direction;
    if (this.sort) {
      sortByField = this.sort.active;
      direction = this.sort.direction;
    }

    const filters = this.form.value;
    const createdAtFrom: string = filters.mes.startOf("month").format();
    const createdAtTo: string = filters.mes.endOf("month").format();

    this.invoicesSubscription = this.invoicesService.listInvoicesPaginated(createdAtFrom, createdAtTo, filters.status, filters.iuguCustomerId, sortByField, direction).pipe(
      tap((invoices) => {
        this.dataSource = new MatTableDataSource(invoices);
        this.length = this.invoicesService.totalItems;
        this.loadingService.timeOut(this.loadingName);
      })
    ).subscribe();
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.form.get("mes").value;
    ctrlValue.year(normalizedYear.year()).startOf("day");
    this.form.get("mes").setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<any>) {
    const ctrlValue = this.form.get("mes").value;
    ctrlValue.month(normalizedMonth.month()).startOf("day");
    this.form.get("mes").setValue(ctrlValue);
    datepicker.close();
  }

  sortChange() {
    this.buscar();
  }

  openUrl(invoice: any) {
    if (!this.flagMenuTrigger) {
      window.open(invoice.secure_url);
    }
    this.flagMenuTrigger = false;
  }

  generateInvoiceIugu(invoice: any) {
    GerarNovoInvoiceDialogComponent.openDialog(this.dialog, invoice);
  }

  darBaixa(invoice: any) {
    this.loadingService.showTopBar();
    this.empresaService.getEmpresasByIuguCustomerId(invoice.customer_id).pipe(
      take(1),
      mergeMap((empresas: Empresa[]) => {
        this.loadingService.hideTopBar();
        return DarBaixaInvoiceDialogComponent.openDialog(this.dialog, invoice.id, empresas);
      })
    ).subscribe(res => {
      if (res) {
        this.buscar();
      }
    });
  }

  public receiveEmpresa(empresa: Empresa): void {
    this.form.patchValue({
      iuguCustomerId: empresa.iuguCustomerId
    });
  }
}
