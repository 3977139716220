import {Injectable} from "@angular/core";
import {Repository} from "../../repository/Repository";
import {Complemento, ComplementoItem, Produto} from "../../models/produto.model";
import {AngularFirestore} from "@angular/fire/firestore";
import {$query} from "../../services/firebase/criteria/query";
import {Criterion} from "../../services/firebase/criteria/criterion";
import {Observable} from "rxjs";
import {map, take} from "rxjs/operators";
import {ComplementoService} from "./complemento.service";

@Injectable()
export class ComplementoItemService extends Repository<ComplementoItem> {

  public static readonly collectionName = "produtos-complementos-itens";

  constructor(db: AngularFirestore, private complementoService: ComplementoService) {
    super(db, ComplementoItemService.collectionName, true);
  }

  deserialize(value: any): ComplementoItem {
    return new ComplementoItem(value);
  }

  getItens(produto: Produto, complemento: Complemento): Observable<ComplementoItem[]> {
    return this.col$($query(
      new Criterion("produto", "==", produto.ref),
      new Criterion("complemento", "==", this.complementoService.getDoc(complemento.id).ref)
    )).pipe(
      take(1),
      map((itens: ComplementoItem[]) => {
        return itens.sort((a, b) => a.sequence - b.sequence);
      })
    );
  }

  /*protected orderBy(): OrderBy {
    return new OrderByAsc("sequence");
  }*/
}
