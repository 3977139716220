import {Segmento} from "../../../models/segmento.model";
import {SegmentoService} from "../../../services/segmento.service";
import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material";
import {Observable, of} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {isNotNullOrUndefined} from "../../../utils/commons";
import {DialogService} from "../../../services/dialog/dialog.service";
import {catchError, mergeMap} from "rxjs/operators";
import {LoadingService} from "../../../services/loading/loading.service";
import {Subscription} from "rxjs/Rx";

@Component({
  selector: "app-segmento-dialog",
  templateUrl: "./segmento-dialog.component.html",
  styleUrls: ["./segmento-dialog.component.scss"]
})
export class SegmentoDialogComponent implements OnInit, OnDestroy {

  static showDialog(dialog: MatDialog): Observable<any> {
    const config = {
      width: "400px"
    };
    return dialog.open(SegmentoDialogComponent, config).afterClosed();
  }

  form: FormGroup;
  segmento: Segmento = new Segmento();
  seguimentos$: Observable<Segmento[]>;
  segmentosArray: Segmento[];
  segmentoSubscription = new Subscription();

  constructor(public dialogRef: MatDialogRef<SegmentoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private segmentoService: SegmentoService,
              formBuilder: FormBuilder,
              public dialogService: DialogService,
              public loadingService: LoadingService) {

    if (isNotNullOrUndefined(data)) {
      this.segmento = new Segmento(data);
    }
    this.form = formBuilder.group({
      nome: ["", [Validators.required]],
    });
    this.seguimentos$ = this.segmentoService.col$();
    this.segmentoSubscription = this.seguimentos$.subscribe(value => {
      this.segmentosArray = value;
    });
  }

  ngOnInit() {
    this.form.patchValue({
      nome: this.segmento.nome
    });

    this.form.valueChanges
      .subscribe(value => {
        this.segmento.nome = value["nome"];
      });
  }

  preencherCampo(nome: string) {
    this.form.get("nome").patchValue(nome);
  }

  excluirSegmento(id: string) {
    this.dialogService.confirmDeleteDialog()
      .show()
      .pipe(
        mergeMap(accept => {
          if (accept) {
            this.loadingService.showTopBar();
            return this.segmentoService.remove(id).pipe(mergeMap(res => {
              this.loadingService.hideTopBar();
              return of(res);
            }), catchError(err => {
              return this.dialogService
                .messageDialog()
                .title("Atenção")
                .message(err.message)
                .show();
            }));
          }
          return of();
        }))
      .subscribe();
  }

  salvar() {
    if (!this.segmentosArray.find(seg => seg.nome === this.segmento.nome)) {
      // Se não existe nenhum segmento com esse nome
      this.segmentoService.saveOrUpdate(this.segmento)
        .subscribe(() => {
          this.dialogRef.close(this.segmento);
        });
    } else {
      // Caso já exista um segmento cadastrado com esse nome
      this.dialogRef.close(this.segmentosArray.find(seg => seg.nome === this.segmento.nome));
    }
  }

  closeDialog() {
    this.dialogRef.close(this.segmentosArray);
  }

  ngOnDestroy() {
    this.segmentoSubscription.unsubscribe();
  }

}
