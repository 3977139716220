import * as firebase from "firebase";
import {FireModel} from "./fire.model";
import DocumentReference = firebase.firestore.DocumentReference;
import {isNotNullOrUndefined} from "../utils/commons";
import Timestamp = firebase.firestore.Timestamp;

export class AppUser extends FireModel {

  public name: string;
  public email: string;
  public phone: string = null;
  public inactive: boolean = false;
  // Enum @UserType
  public userType: string;
  // Apenas usuários do PoM e Representantes terão um perfil informado diretamente
  public perfilAcesso: DocumentReference = null;
  // Array de IDs das empresas do usuário
  public empresas: string[];
  // Usuários lojistas terão as permissões por empresa {ID_empresa: PerfilDocumentRef}
  public perfisByEmpresa: any;
  // Data do ultimo login
  public lastLogin: Timestamp;
  // Indica que o usuário já verificou o e-mail
  public emailVerificado: boolean = false;
  // Empresa selecionada
  public empresaSelectedId: string;
  // Comissão opcional dependendo do tipo de usuário
  public comissao: number;
  // Se o usuário for do tipo Assistente ele deverá ter um consultor para acessar os dados do sistema
  public consultorId: string;
  // Tokens utilizados no Gestor Mobile
  public tokensGestor: string[] = [];

  constructor(user: any = {}) {
    super(user.id, user.ref);
      this.name = user.name;
      this.email = user.email;
      this.phone = user.phone;
      this.inactive = user.inactive;
      this.userType = user.userType;
      this.perfilAcesso = user.perfilAcesso ? user.perfilAcesso : null;
      this.empresas = isNotNullOrUndefined(user.empresas) ? user.empresas : [];
      this.perfisByEmpresa = isNotNullOrUndefined(user.perfisByEmpresa) ? user.perfisByEmpresa : {};
      this.lastLogin = user.lastLogin;
      this.emailVerificado = user.emailVerificado;
      this.empresaSelectedId = user.empresaSelectedId ? user.empresaSelectedId : null;
      this.comissao = isNotNullOrUndefined(user.comissao) ? user.comissao : null;
      this.consultorId = isNotNullOrUndefined(user.consultorId) ? user.consultorId : null;
      this.tokensGestor = isNotNullOrUndefined(user.tokensGestor) ? user.tokensGestor : null;
  }

  public toAny(): any {
    const a: any = {};
    if (this.id) a.id = this.id;
    if (this.ref) a.ref = this.ref;
    if (this.name) a.name = this.name;
    if (this.email) a.email = this.email;
    if (this.phone) a.phone = this.phone;
    if (this.inactive) a.inactive = this.inactive;
    if (this.userType) a.userType = this.userType;
    if (this.perfilAcesso) a.perfilAcesso = this.perfilAcesso;
    if (this.empresas) a.empresas = this.empresas;
    if (this.perfisByEmpresa) a.perfisByEmpresa = this.perfisByEmpresa;
    if (this.lastLogin) a.lastLogin = this.lastLogin;
    if (this.emailVerificado) a.emailVerificado = this.emailVerificado;
    if (this.empresaSelectedId) a.empresaSelectedId = this.empresaSelectedId;
    if (this.comissao) a.comissao = this.comissao;
    if (this.consultorId) a.consultorId = this.consultorId;
    if (this.tokensGestor) a.tokensGestor = this.tokensGestor;
    return a;
  }

  isLojista(): boolean {
    return this.userType === UserType[UserType.Lojista];
  }

  isConsultor(): boolean {
    return this.userType === UserType[UserType.Consultor];
  }

  isAdministrador(): boolean {
    return this.userType === UserType[UserType.Administrador];
  }

  isAssistente(): boolean {
    return this.userType === UserType[UserType.Assistente];
  }

  isConsultorOrAssistente(): boolean {
    return this.isConsultor() || this.isAssistente();
  }

  lojistaHasMultipleEmpresas(): boolean {
    return this.isLojista() && this.empresas.length > 1;
  }
}

export enum UserType {
  // Usuários gerais do PoM
  // Administradores tem acesso a todas as funcionalidades e dados do sistema
  Administrador = "Administrador",
  // Consultores do PoM
  // Consultores tem acesso a todas as funcionalidades do sistema porém só podem vizualizar dados relacionados a ele
  Consultor = "Consultor",
  // Assitentes do PoM
  // Assistentes precisam ser relacionados a um Consultor e possuem acesso aos dados do seu Consultor
  Assistente = "Assistente",
  // Usuários vinculados a empresas
  Lojista = "Lojista"
}
