import {ChangeDetectorRef, Component, ViewChild} from "@angular/core";
import {CategoriaProdutoFieldsComponent} from "../categoria-produto-fields/categoria-produto-fields.component";
import {PageService} from "../../../services/page.service";
import {CategoriaProduto} from "../../../models/categoria.model";
import {CategoriaProdutoService} from "../categoria-produto.service";
import { MatDialog } from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: "categoria-produto-form",
  templateUrl: "./categoria-produto-form.component.html",
  styleUrls: ["./categoria-produto-form.component.scss"],
})
export class CategoriaProdutoFormComponent extends PageService<CategoriaProduto> {

  @ViewChild(CategoriaProdutoFieldsComponent, { static: true })
  fieldsComponent: CategoriaProdutoFieldsComponent;

  constructor(service: CategoriaProdutoService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/categorias-produto/");
  }

  protected newItem(): CategoriaProduto {
    return undefined;
  }

  podeMudarRota(): boolean {
    return this.fieldsComponent.podeMudarRota();
  }
}
