import {Component, Inject, OnInit, ViewChild} from "@angular/core";
import {CozinhasFieldsComponent} from "../cozinhas-fields/cozinhas-fields.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {Cozinha} from "../../../models/cozinha.model";
import {CozinhasService} from "../cozinhas.service";
import {LoadingService} from "../../../services/loading/loading.service";

@Component({
  selector: "app-cozinhas-dialog",
  templateUrl: "./cozinhas-dialog.component.html",
  styleUrls: ["./cozinhas-dialog.component.scss"]
})
export class CozinhasDialogComponent implements OnInit {

  @ViewChild(CozinhasFieldsComponent, { static: true })
  fieldsComponent: CozinhasFieldsComponent;

  constructor(public dialogRef: MatDialogRef<CozinhasFieldsComponent>,
              @Inject(MAT_DIALOG_DATA) public item: CozinhasFieldsComponent,
              public service: CozinhasService,
              public dialogService: DialogService,
              public loading: LoadingService) {
  }

  ngOnInit() {
  }

  save(item: Cozinha) {
    this.loading.showTopBar();
    this.service.saveOrUpdate(item, true).subscribe(() => {
        this.dialogRef.close(item);
      },
      () => {
        this.dialogService.messageDialog()
          .message("Ocorreu um erro ao salvar o item!")
          .show();
      }, () => {
        this.loading.hideTopBar();
      });
  }

}
