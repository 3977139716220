import {Injectable, OnDestroy} from "@angular/core";
import {QzTrayService} from "./qz-tray.service";
import {Pedido} from "../models/pedido.model";
import {isEmpty, isNotNullOrUndefined} from "../utils/commons";
import {AsYouType} from "libphonenumber-js";
import * as moment from "moment";
import {ProdutoService} from "../components/produto/produto.service";
import {Observable} from "rxjs/Observable";
import {concat, forkJoin, of} from "rxjs";
import {mergeMap, tap} from "rxjs/operators";
import {Produto, RegraTotalizacao} from "../models/produto.model";
import {Cozinha} from "../models/cozinha.model";
import {CozinhasService} from "../components/cozinhas/cozinhas.service";
import {SettingsService} from "../components/settings/settings.service";
import {PedidoItem} from "../models/pedido-item.model";
import {PedidoItemService} from "./pedido-item.service";
import {isNullOrUndefined} from "util";
import {GeradorDeTicketPedidoPdfService} from "./pdf/gerador-de-ticket-pedido-pdf.service";
import {GeradorPdfTotalizacaoService} from "./pdf/gerador-pdf-totalizacao.service";
import {TipoPagamento} from "../models/forma-pagamento.model";

@Injectable({
  providedIn: "root"
})
export class PrinterService implements OnDestroy {

  /*Sample [
      { type: "raw", data: "", options: { dotDensity: "double" } },
      "\x1B" + "\x40",          // init
      "\x1B" + "\x61" + "\x31", // center align
      "Beverly Hills, CA  90210" + "\x0A",
      "\x0A",                   // line break
      "www.qz.io" + "\x0A",     // text and line break
      "\x0A",                   // line break
      "\x0A",                   // line break
      "May 18, 2016 10:30 AM" + "\x0A",
      "\x0A",                   // line break
      "\x0A",                   // line break
      "\x0A",
      "Transaction # 123456 Register: 3" + "\x0A",
      "\x0A",
      "\x0A",
      "\x0A",
      "\x1B" + "\x61" + "\x30", // left align
      "Baklava (Qty 4)       9.00" + "\x1B" + "\x74" + "\x13" + "\xAA", //print special char symbol after numeric
      "\x0A",
      "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX" + "\x0A",
      "\x1B" + "\x45" + "\x0D", // bold on
      "Here's some bold text!",
      "\x0A",
      "\x1B" + "\x45" + "\x0A", // bold off
      "\x1D" + "\x21" + "\x11", // double font size
      "Here's large text!",
      "\x0A",
      "\x1D" + "\x21" + "\x00", // standard font size
      "\x1B" + "\x61" + "\x32", // right align
      "\x1B" + "\x21" + "\x30", // em mode on
      "DRINK ME",
      "\x1B" + "\x21" + "\x0A" + "\x1B" + "\x45" + "\x0A", // em mode off
      "\x0A" + "\x0A",
      "\x1B" + "\x61" + "\x30", // left align
      "------------------------------------------" + "\x0A",
      "\x1B" + "\x4D" + "\x31", // small text
      "EAT ME" + "\x0A",
      "\x1B" + "\x4D" + "\x30", // normal text
      "------------------------------------------" + "\x0A",
      "normal text",
      "\x1B" + "\x61" + "\x30", // left align
      "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A",
      "\x1B" + "\x69",          // cut paper
      "\x10" + "\x14" + "\x01" + "\x00" + "\x05",  // Generate Pulse to kick-out cash drawer**
                                                   // **for legacy drawer cable CD-005A.  Research before using.
                                                   // see also http://keyhut.com/popopen4.htm
    ];*/

  constructor(private qz: QzTrayService,
              private produtoService: ProdutoService,
              private settings: SettingsService,
              private cozinhaService: CozinhasService,
              private pedidoItemService: PedidoItemService,
              private geradorDeTicketPedidoPdfService: GeradorDeTicketPedidoPdfService,
              private geradorPdfTotalizacao: GeradorPdfTotalizacaoService) {
  }

  /**
   * Impressão em cada impressora configurada nos produtos e categorias.
   * Essa é a impressão quando aprova um pedido
   * @param {Pedido} pedido
   */
  imprimirParaProducao(pedido: Pedido): Observable<any> {

    // Adiciona os itens ao pedido
    return this.pedidoItemService.getItensByPedido(pedido)
      .pipe(
        tap((itens) => {
          pedido.itens = itens;
        }),
        mergeMap(() => {
          // Observables de produtos
          const produtosObservable: Observable<Produto>[] = pedido.itens.map((item: PedidoItem) => {
            return this.produtoService.getById(item.produto.id);
          });

          // Executar os observables
          return forkJoin(produtosObservable).pipe(
            mergeMap((prods: Produto[]) => {

              // Lista das cozinhas
              let cozinhasList: Cozinha[] = prods.map((prod: Produto) => {
                if (prod && prod.cozinha) {
                  return prod.cozinha;
                }
              });

              // Encontra a posição da cozinha na lista
              const indexOf = (cozinha): number => {
                for (let i = 0; i < cozinhasList.length; i++) {
                  if (cozinhasList[i].id === cozinha.id) {
                    return i;
                  }
                }
                return -1;
              };
              // Remover as cozinhas duplicadas
              cozinhasList = cozinhasList.filter((cozinha, index) => indexOf(cozinha) === index);

              const cozinhasObservable: Observable<Cozinha>[] = cozinhasList.map((coz: Cozinha) => {
                if (coz) {
                  return this.cozinhaService.getById(coz.id);
                }
              });

              // Consultar as cozinhas
              return forkJoin(cozinhasObservable).pipe(
                tap((cozinhas: Cozinha[]) => {
                  // Atualizar as cozinhas nos itens do pedido
                  cozinhas.forEach((cozinha: Cozinha) => {
                    // Atualizar apenas a cozinha para carregar a configuração de impressora,
                    // pois não se pode mudar o preço do produto de quando o pedido foi feito
                    //pedido.itens[index].produto.cozinha = produto.cozinha;
                    prods.forEach((prod: Produto, index: number) => {
                      if (prod.cozinha && prod.cozinha.id === cozinha.id) {
                        // Atualizar diretamente o item do pedido
                        pedido.itens[index].produto.cozinha = cozinha;
                      }
                    });
                  });
                })
              );
            }),
            // Necessário para imprimir a primeira vez, *** não remover ***
            mergeMap(() => this.qz.getPrinter()),
            mergeMap(() => {

              // Lista de produtos por cozinha
              const itensForPrinter: ItensPorCozinha[] = [];

              // Compara se já tem a cozinha na lista
              const hasCozinhaAdded = (cozinha: Cozinha): boolean => itensForPrinter.some(ppi => ppi.cozinha.id === cozinha.id);

              // Recupera a posição da lista que a cozinha está inserida
              const indexOf = (cozinha: Cozinha): number => itensForPrinter.indexOf(itensForPrinter.filter(ppi => ppi.cozinha.id === cozinha.id)[0]);

              // Montar uma lista de produtos por cozinha
              pedido.itens.forEach(item => {
                let cozinha;
                if (isNotNullOrUndefined(item.produto.cozinha) && isNotNullOrUndefined(item.produto.cozinha.id)) {
                  cozinha = item.produto.cozinha;

                  // Verificar se já tem produtos pra essa impressora na lista
                  if (hasCozinhaAdded(cozinha)) {
                    itensForPrinter[indexOf(cozinha)].itens.push(item);
                  } else {
                    itensForPrinter.push(new ItensPorCozinha(cozinha, pedido, item));
                  }
                }
              });


              const prints: Observable<any>[] = [];
              itensForPrinter.forEach((ifp, index: number) => {
                if (!isEmpty(ifp.cozinha.printer)) {
                  // Imprimir modelo padrão
                  if (ifp.cozinha.printFormat === "Padrão" || isNullOrUndefined(ifp.cozinha.printFormat)) {
                    prints.push(
                      this.print(this.getRawData(ifp, ifp.cozinha.pageDimension, `Comanda ${index + 1} de ${itensForPrinter.length}`), ifp.cozinha.printer, ifp.cozinha.pageDimension)
                    );
                    // Imprimir modelo PDF
                  } else if (ifp.cozinha.printFormat === "PDF") {
                    const documento = this.geradorDeTicketPedidoPdfService.gerarPdfCozinha(
                      ifp, `Comanda ${index + 1} de ${itensForPrinter.length}`, ifp.cozinha.pageDimension);
                    const data = [{
                      type: "pdf",
                      format: "base64",
                      data: documento
                    }];
                    prints.push(this.print(data, ifp.cozinha.printer, ifp.cozinha.pageDimension));
                  }
                }
              });

              return concat(prints);
            })
          );
        }));
  }

  imprimirEspelho(pedido: Pedido, multiplasVias?: boolean): Observable<any> {
    return this.settings.getSettings().pipe(
      mergeMap(settings => {
        const vias = settings.numeroVias;
        if (!isNullOrUndefined(settings.impressoraPadrao)) {
          return this.qz.getPrinter(settings.impressoraPadrao)
            .pipe(
              mergeMap(printer => {
                // Adiciona os itens ao pedido
                return this.pedidoItemService.getItensByPedido(pedido)
                  .pipe(
                    tap((itens) => {
                      pedido.itens = itens;
                    }),
                    mergeMap(() => {
                      let data: any;
                      // Imprimir modelo padrão
                      if (settings.printFormat === "Padrão" || isNullOrUndefined(settings.printFormat)) {
                        // Montar o arquivo de impressão do pedido padrão
                        data = this.getRawData(pedido, settings.pageDimension);
                      } else if (settings.printFormat === "PDF") {
                        // Montar PDF
                        const documento = this.geradorDeTicketPedidoPdfService.gerarPdfEspelho(pedido, settings.pageDimension);
                        data = [{
                          type: "pdf",
                          format: "base64",
                          data: documento
                        }];
                      }
                      // Imprimir
                      if (isNullOrUndefined(multiplasVias) || !multiplasVias || isNullOrUndefined(vias)) {
                        return this.print(data, printer, settings.pageDimension);
                      } else {
                        const printerVias: Observable<any>[] = [];
                        for (let i = 0; i < vias; i++) {
                          printerVias.push(this.print(data, printer, settings.pageDimension));
                        }
                        return concat(printerVias);
                      }
                    }));
              })
            );
        } else {
          return of(null);
        }
      }));
  }

  imprimirTotal(itens: any): Observable<any> {
    return this.settings.getSettings().pipe(
      mergeMap(sett => {
        const vias = sett.numeroVias;
        if (!isNullOrUndefined(sett.impressoraPadrao)) {
          return this.qz.getPrinter(sett.impressoraPadrao).pipe(
            mergeMap(printer => {
              let data: any;
              if (sett.printFormat === "Padrão" || isNullOrUndefined(sett.printFormat)) {
                data = this.getRawTotalData(itens, sett.pageDimension);
              } else if (sett.printFormat === "PDF") {
                // Montar PDF
                const documento = this.geradorPdfTotalizacao.gerarPdfTotalizacao(itens, sett.pageDimension);
                data = [{
                  type: "pdf",
                  format: "base64",
                  data: documento
                }];
              }
              if (isNullOrUndefined(vias)) {
                return this.print(data, printer, sett.pageDimension);
              } else {
                const printerVias: Observable<any>[] = [];
                for (let i = 0; i < vias; i++) {
                  printerVias.push(this.print(data, printer, sett.pageDimension));
                }
                return concat(printerVias);
              }
            }));
        } else {
          return of(null);
        }
      }));
  }

  private print(data: any, printer: string, pageDimension?: string): Observable<any> {
    // Localizar a impressora
    return this.qz.printData(printer, data, pageDimension);
  }

  private getRawData(dataForPrinter: Pedido | ItensPorCozinha, pageDimension?: string, comandas?: string): any[] {
    const init = "\x1B" + "\x40"; // init
    // const fontDefault = "\x1B" + "\x21" + "\x00"; // font A
    // const charCode = "\x1B" + "\x74" + "\x11"; // Character code
    const boldOn = "\x1B" + "\x45" + "\x01"; // bold on
    const boldOff = "\x1B" + "\x45" + "\x00"; // bold off
    const normalFont = "\x1D" + "\x21" + "\x00"; // standard font size
    const mediumFont = "\x1D" + "\x21" + "\x10"; // medium font size
    const largeFont = "\x1D" + "\x21" + "\x11"; // double font size
    const leftAlign = "\x1B" + "\x61" + "\x30"; // left align
    const centerAlign = "\x1B" + "\x61" + "\x31"; // center align
    const rightAlign = "\x1B" + "\x61" + "\x32"; // right align
    const lineBreak = (qtde?: number) => "\x0A".repeat(qtde ? qtde : 1); // line break
    const resetTabs = "\x1b" + "\x44" + "\x00"; // Cancel previous tab settings, restores defaults
    const nextTab = "\x09"; // next tab
    const cutPaper = "\x1B" + "\x69"; // cut paper

    // Variaveis para enquadrar os dados de acordo com a dimensão do papel
    let xRightEsp = "\x21";
    let xLeftTotal = "\x14";
    let xRightCoz = "\x27";
    let tableDivider = "================================================";
    let itemDivider = "------------------------------------------------";
    if (pageDimension === "58mm") {
      xRightEsp = "\x18";
      xLeftTotal = "\x09";
      xRightCoz = "\x18";
      tableDivider = "================================";
      itemDivider = "--------------------------------";
    }

    const data = [
      {type: "raw", data: "", options: {dotDensity: "double"}}, init
    ];

    data.push(centerAlign, boldOn, mediumFont, "Pede o Menu".toUpperCase(), normalFont, boldOff);
    if (dataForPrinter instanceof Pedido) {
      data.push(lineBreak(), boldOn, (dataForPrinter as Pedido).empresa.nomeFantasia.toUpperCase(), boldOff);

      if ((dataForPrinter as Pedido).empresa.telefones.some(tel => tel.visivelNoTicket === true)) {
        const telefones = (dataForPrinter as Pedido).empresa.telefones.filter(tel => tel.visivelNoTicket === true);
        for (let i = 0; i < telefones.length; i++) {
          if (pageDimension === "58mm") {
            data.push(lineBreak(), telefones[i].numero);
          } else {
            if (i % 2) {
              data.push(boldOn, "  |  ", boldOff);
            } else {
              data.push(lineBreak());
            }
            data.push(telefones[i].numero);
          }
        }
      }
    }

    // Montar a impressora separadamente
    if (dataForPrinter instanceof Pedido) {

      data.push(
        lineBreak(2),
        leftAlign, boldOn,
        "Pedido: #" + (dataForPrinter as Pedido).numero,
        lineBreak(),
        "Cliente: " + (dataForPrinter as Pedido).cliente.nome.toUpperCase(),
        lineBreak(), boldOff,
        "Data: " + moment((dataForPrinter as Pedido).data.toDate()).format("DD/MM/YY HH:mm"),
        lineBreak(),
        "Telefone: " + new AsYouType("BR").input((dataForPrinter as Pedido).cliente.telefone),
        lineBreak()
      );

      if ((dataForPrinter as Pedido).buscarNoBalcao) {
        data.push(lineBreak(), centerAlign, "RETIRAR NO BALCÃO", lineBreak(), leftAlign);
      } else {

        let endereco = (dataForPrinter as Pedido).enderecoEntrega.logradouro;
        if ((dataForPrinter as Pedido).enderecoEntrega.numero) {
          endereco += ", " + (dataForPrinter as Pedido).enderecoEntrega.numero;
        }
        data.push("Endereço: " + endereco.toUpperCase(), lineBreak());
        if (isNotNullOrUndefined((dataForPrinter as Pedido).enderecoEntrega.bairro) && isNotNullOrUndefined((dataForPrinter as Pedido).enderecoEntrega.bairro.nome)) {
          data.push("Bairro: " + (dataForPrinter as Pedido).enderecoEntrega.bairro.nome, lineBreak());
        }
        if (isNotNullOrUndefined((dataForPrinter as Pedido).enderecoEntrega.complemento) && !isEmpty((dataForPrinter as Pedido).enderecoEntrega.complemento)) {
          data.push("Comp: " + (dataForPrinter as Pedido).enderecoEntrega.complemento, lineBreak());
        }
        if (isNotNullOrUndefined((dataForPrinter as Pedido).enderecoEntrega.pontoReferencia) && !isEmpty((dataForPrinter as Pedido).enderecoEntrega.pontoReferencia)) {
          data.push("Ref: " + (dataForPrinter as Pedido).enderecoEntrega.pontoReferencia, lineBreak());
        }
      }

      data.push(centerAlign, tableDivider, leftAlign, lineBreak());
      data.push(resetTabs, leftAlign);
      data.push("\x1b", "\x44", "\x06", xRightEsp, "\x00"); // Set tab stops at 5, 38, and 10 characters
      data.push(boldOn, "Qtde", nextTab, "Item", nextTab, "Preço", boldOff);

      for (let i = 0; i < (dataForPrinter as Pedido).itens.length; i++) {
        const item = (dataForPrinter as Pedido).itens[i];
        // Para não quebrar o layout, limita a quantidade de caracteres
        let descProd = item.produto.nome;
        descProd = descProd.length > 26 ? descProd.substring(0, 26) : descProd;
        data.push(lineBreak(), leftAlign, item.quantidade.toString(), nextTab, descProd, nextTab,
          item.produto.preco > 0 ? this.moneyFormat(item.produto.preco * item.quantidade) : " ");

        // Complementos do produto
        if (item.complementos.length > 0) {
          for (let j = 0; j < item.complementos.length; j++) {
            const complemento = item.complementos[j];
            if (complemento.itens.length > 0) {
              data.push(lineBreak(2), leftAlign, nextTab, boldOn, complemento.titulo, nextTab, complemento.regraTotalizacao === RegraTotalizacao.Soma ? " " :
                complemento.getComplementoTotalValue(item.quantidade) <= 0 ? " " : this.moneyFormat(complemento.getComplementoTotalValue(item.quantidade)), boldOff);
              const showQtdeItem = complemento.itens.length > 1 && complemento.itens.some((_item) => _item.quantidade > 1);
              for (let k = 0; k < complemento.itens.length; k++) {
                const compItem = complemento.itens[k];
                data.push(lineBreak(), leftAlign, nextTab, (showQtdeItem || compItem.quantidade > 1 ? compItem.quantidade + "-" : " ") + " "
                  + compItem.nome, nextTab, (complemento.regraTotalizacao === RegraTotalizacao.Soma) && (compItem.preco > 0) ?
                  this.moneyFormat(compItem.preco * compItem.quantidade * item.quantidade) : " ");
              }
            }
          }
        }
        // Observação do item
        if (!isEmpty(item.observacao)) {
          data.push(lineBreak(2), leftAlign, nextTab, "Obs.:", boldOn, item.observacao, boldOff, nextTab);
        }
        // Quebra de linha entre os produtos
        data.push(lineBreak());
        if ((dataForPrinter as Pedido).itens.length > 1 && i < ((dataForPrinter as Pedido).itens.length - 1)) {
          data.push(centerAlign, itemDivider, leftAlign);
        }
      }

      data.push(centerAlign, tableDivider, leftAlign);
      data.push(lineBreak());

      data.push(resetTabs);
      data.push(leftAlign,
        "\x1b", "\x44", xLeftTotal, xRightEsp, "\x00", // Set tab stops at 26 and 39 characters
        nextTab, "Subtotal: (+)", nextTab, this.moneyFormat((dataForPrinter as Pedido).subtotal),
        ((dataForPrinter as Pedido).buscarNoBalcao ? "" : lineBreak() + nextTab + "Delivery: (+)" + nextTab + this.moneyFormat((dataForPrinter as Pedido).valorEntrega)),
        ((isNotNullOrUndefined((dataForPrinter as Pedido).taxaAdicional) && (dataForPrinter as Pedido).taxaAdicional > 0) ?
          lineBreak() + nextTab + "Adicional:(+)" + nextTab + this.moneyFormat((dataForPrinter as Pedido).taxaAdicional) : ""),
        lineBreak() + nextTab + "PoMs:     (-)   " + nextTab + this.pomFormat((dataForPrinter as Pedido).pontos), lineBreak(),
        boldOn, nextTab, "Total:       ", this.moneyFormat((dataForPrinter as Pedido).total), boldOff
      );
      data.push(resetTabs);

      if ((dataForPrinter as Pedido).formaPagamento.tipoPagamento === TipoPagamento[TipoPagamento.online] ||
        (dataForPrinter as Pedido).formaPagamento.tipoPagamento === TipoPagamento[TipoPagamento.pontos]) {
        data.push(lineBreak(2), centerAlign, "\x1d", "\x42", "\x01", boldOn, "Pedido pago pelo aplicativo.");
        data.push(lineBreak(),  "Não cobrar do cliente!", boldOff, "\x1d", "\x42", "\x00", leftAlign);
      }
      data.push(lineBreak(2), "Forma Pagto: ", (dataForPrinter as Pedido).formaPagamento.descricao.toUpperCase());
      if ((dataForPrinter as Pedido).troco > 0) {
        data.push(lineBreak(2), centerAlign, "*** Troco p/ " + this.moneyFormat((dataForPrinter as Pedido).troco) + " ***", leftAlign);
        data.push(lineBreak(),
          boldOn,
          centerAlign,
          "Levar " + this.moneyFormat((dataForPrinter as Pedido).troco - (dataForPrinter as Pedido).total) + " de troco", leftAlign);
      }

      if (dataForPrinter instanceof Pedido) {
        data.push(lineBreak(3), centerAlign, boldOn, "Obrigado!", boldOff, leftAlign);
      }

      // ----------- End ----------- //

    } else {

      // Cozinha
      data.push(lineBreak(2), centerAlign, largeFont, (dataForPrinter as ItensPorCozinha).cozinha.nome, normalFont);

      data.push(
        lineBreak(2),
        leftAlign,
        "Pedido: ", mediumFont, "#" + (dataForPrinter as ItensPorCozinha).pedido.numero, normalFont, lineBreak(),
        "Data: " + moment((dataForPrinter as ItensPorCozinha).pedido.data.toDate()).format("DD/MM/YY HH:mm"),
        lineBreak(),
        "Cliente: " + (dataForPrinter as ItensPorCozinha).pedido.cliente.nome,
        lineBreak(),
      );

      data.push(lineBreak());
      data.push(resetTabs,
        "\x1b", "\x44", "\x06", xRightCoz, "\x00", // Set tab stops at 5 and 38 characters
        "Qtde", nextTab, "Item"
      );

      for (let i = 0; i < (dataForPrinter as ItensPorCozinha).itens.length; i++) {
        if (i > 0) {
          // Pular uma linha a partir do segundo item da lista
          data.push(lineBreak());
        }
        const item = (dataForPrinter as ItensPorCozinha).itens[i];
        data.push(lineBreak(), mediumFont, item.quantidade.toString(), nextTab, item.produto.nome, normalFont);

        // Complementos do produto
        if (item.complementos.length > 0) {
          for (let j = 0; j < item.complementos.length; j++) {
            const complemento = item.complementos[j];
            if (complemento.itens.length > 0) {
              data.push(lineBreak(), nextTab, boldOn, complemento.titulo, boldOff);
              // Verificar se mostra a quantidade
              const showQtdeItem = complemento.itens.length > 1 && complemento.itens.some((_item) => _item.quantidade > 1);
              for (let k = 0; k < complemento.itens.length; k++) {
                const compItem = complemento.itens[k];
                data.push(lineBreak(), nextTab, normalFont, (showQtdeItem || compItem.quantidade > 1 ? compItem.quantidade + "x" : " ") + " " + compItem.nome, normalFont);
              }
            }
          }
        }
        // Observação do item
        if (!isEmpty(item.observacao)) {
          data.push(lineBreak(), nextTab, "Obs.:", boldOn, item.observacao, nextTab);
        }
      }

      data.push(resetTabs);

      if (comandas) {
        data.push(lineBreak(2), centerAlign, largeFont, comandas, normalFont, leftAlign);
      }

      data.push(lineBreak(2), centerAlign, mediumFont, (dataForPrinter as ItensPorCozinha).pedido.buscarNoBalcao ? "BALCÃO" : "DELIVERY");
    }
    lineBreak();
    data.push(lineBreak(6), cutPaper);

    return data;
  }

  getRawTotalData(totals: any, pageDimension?: string): any[] {

    const init = "\x1B" + "\x40"; // init
    const boldOn = "\x1B" + "\x45" + "\x01"; // bold on
    const boldOff = "\x1B" + "\x45" + "\x00"; // bold off
    const normalFont = "\x1D" + "\x21" + "\x00"; // standard font size
    const mediumFont = "\x1D" + "\x21" + "\x10"; // medium font size
    const largeFont = "\x1D" + "\x21" + "\x11"; // double font size
    const leftAlign = "\x1B" + "\x61" + "\x30"; // left align
    const centerAlign = "\x1B" + "\x61" + "\x31"; // center align
    const rightAlign = "\x1B" + "\x61" + "\x32"; // right align
    const lineBreak = (qtde?: number) => "\x0A".repeat(qtde ? qtde : 1); // line break
    const resetTabs = "\x1b" + "\x44" + "\x00"; // Cancel previous tab settings, restores defaults
    const nextTab = "\x09"; // next tab
    const cutPaper = "\x1B" + "\x69"; // cut paper


    const data = [
      {type: "raw", data: "", options: {dotDensity: "double"}}, init
    ];

    data.push(
      centerAlign,
      boldOn,
      "Pede o Menu".toUpperCase() + " | " + totals.empresa.toUpperCase(),
      boldOff
    );

    data.push(
      lineBreak(2),
      centerAlign,
      "Relatório do dia",
      lineBreak(),
      boldOn,
      moment().format("DD/MM/YYYY"),
      boldOff
    );

    data.push(
      lineBreak(3),
      mediumFont,
      centerAlign,
      boldOn,
      "Total Geral",
      boldOff,
      normalFont,
    );

    data.push(
      "\x1b", "\x44", "\x09", "\x20", "\x00",
      lineBreak(2), leftAlign, " ", nextTab, "Pedidos", nextTab, " ", nextTab, ("   " + (totals.totalGeral.pedidos)),
      lineBreak(), " ", nextTab, "Produtos", nextTab, " ", this.moneyFormat(totals.totalGeral.produtos),
      lineBreak(), " ", nextTab, "Entregas", nextTab, " ", nextTab, this.moneyFormat(totals.totalGeral.entregas),
      isNotNullOrUndefined(totals.totalGeral.taxa) ? (lineBreak() + " " + nextTab + "Adicional" + nextTab + " " + nextTab + this.moneyFormat(totals.totalGeral.taxa)) : "",
      lineBreak(), " ", nextTab, "PoMs", nextTab, " ", nextTab, ("   " + this.pomFormat(totals.totalGeral.pontos)),
      lineBreak(), boldOn, " ", nextTab, "Total", nextTab, " ", nextTab, this.moneyFormat(totals.totalGeral.total), boldOff, resetTabs
    );

    if ((isNotNullOrUndefined(totals.totalDinheiro)) && (totals.totalDinheiro.pedidos) > 0) {
      data.push(
        lineBreak(5),
        centerAlign,
        boldOn,
        "Dinheiro",
        boldOff
      );
      data.push(
        "\x1b", "\x44", "\x09", "\x20", "\x00",
        lineBreak(2), leftAlign, " ", nextTab, "Pedidos", nextTab, " ", nextTab, ("   " + totals.totalDinheiro.pedidos),
        lineBreak(), " ", nextTab, "Produtos", nextTab, " ", nextTab, this.moneyFormat(totals.totalDinheiro.produtos),
        lineBreak(), " ", nextTab, "Entregas", nextTab, " ", nextTab, this.moneyFormat(totals.totalDinheiro.entregas),
        isNotNullOrUndefined(totals.totalDinheiro.taxa) ? (lineBreak() + " " + nextTab + "Adicional" + nextTab + " " + nextTab + ("   " + totals.totalDinheiro.taxa)) : "",
        lineBreak(), " ", nextTab, "Total", nextTab, " ", nextTab, this.moneyFormat(totals.totalDinheiro.total), resetTabs
      );
    }

    if ((isNotNullOrUndefined(totals.totalCartao)) && (totals.totalCartao.pedidos > 0)) {
      data.push(
        lineBreak(3),
        centerAlign,
        boldOn,
        "Maquina Móvel",
        boldOff
      );
      data.push(
        "\x1b", "\x44", "\x09", "\x20", "\x00",
        lineBreak(2), leftAlign, " ", nextTab, "Pedidos", nextTab, " ", nextTab, ("   " + totals.totalCartao.pedidos),
        lineBreak(), " ", nextTab, "Produtos", nextTab, " ", nextTab, this.moneyFormat(totals.totalCartao.produtos),
        lineBreak(), " ", nextTab, "Entregas", nextTab, " ", nextTab, this.moneyFormat(totals.totalCartao.entregas),
        isNotNullOrUndefined(totals.totalCartao.taxa) ? (lineBreak() + " " + nextTab + "Adicional" + nextTab + " " + nextTab + this.moneyFormat(totals.totalCartao.taxa)) : "",
        lineBreak(), " ", nextTab, "Total", nextTab, " ", nextTab, this.moneyFormat(totals.totalCartao.total), resetTabs
      );
    }

    if ((isNotNullOrUndefined(totals.totalOnline)) && (totals.totalOnline.pedidos) > 0) {
      data.push(
        lineBreak(3),
        centerAlign,
        boldOn,
        "Online",
        boldOff
      );
      data.push(
        "\x1b", "\x44", "\x09", "\x20", "\x00",
        lineBreak(2), leftAlign, " ", nextTab, "Pedidos", nextTab, " ", nextTab, ("   " + totals.totalOnline.pedidos),
        lineBreak(), " ", nextTab, "Produtos", nextTab, " ", nextTab, this.moneyFormat(totals.totalOnline.produtos),
        lineBreak(), " ", nextTab, "Entregas", nextTab, " ", nextTab, this.moneyFormat(totals.totalOnline.entregas),
        isNotNullOrUndefined(totals.totalOnline.taxa) ? (lineBreak() + " " + nextTab + "Adicional" + nextTab + " " + nextTab + this.moneyFormat(totals.totalOnline.taxa)) : "",
        lineBreak(), " ", nextTab, "Total", nextTab, " ", nextTab, this.moneyFormat(totals.totalOnline.total), resetTabs
      );
    }

    data.push(
      lineBreak(8), cutPaper
    );

    return data;
  }

  private moneyFormat(value: number): string {
    const moneyFormat = new Intl.NumberFormat("pt", {style: "currency", currency: "BRL"});
    return moneyFormat.format(value);
  }

  private pomFormat(value: number): string {
    const moneyFormat = new Intl.NumberFormat("pt", {style: "decimal", maximumFractionDigits: 2});
    return moneyFormat.format(value);
  }

  ngOnDestroy() {
    // Desconectar da impressora
    this.qz.disconnect();
  }

}

class ItensPorCozinha {
  cozinha: Cozinha;
  pedido: Pedido;
  itens: PedidoItem[] = [];

  constructor(cozinha: Cozinha, pedido: Pedido, item: PedidoItem) {
    this.cozinha = cozinha;
    this.pedido = pedido;
    this.itens.push(item);
  }
}
