import {Injectable} from "@angular/core";

@Injectable()
export class MessagingService {
  permission = Notification.permission;

  payload = {
    notification: {
      title: "Novo Pedido!",
      body: "Acabou de chegar um novo pedido para você!",
      icon: "favicon.ico",
      tag: "pedido",
      vibrate: [100, 50, 100],
      requireInteraction: false,
      renotify: true
    }
  };

  constructor() {
  }

  getPermission() {
    if (!("Notification" in window)) {
      console.log("Browser não suporta notificações!");
    } else if (!(this.permission === "granted")) {
      // @ts-ignore
      if (this.permission !== "denied" || this.permission === "default") {
        Notification.requestPermission(() => {
        });
      }
    }
  }

  showNotification() {
    if (!navigator.serviceWorker) return;

    navigator.serviceWorker.register("../../sw.js");

    Notification.requestPermission((result) => {
      if (result === "granted") {
        navigator.serviceWorker.ready.then((registration) => {
          setTimeout(() => {
              registration.showNotification(
                this.payload.notification.title, {
                  body: this.payload.notification.body,
                  icon: this.payload.notification.icon,
                  vibrate: this.payload.notification.vibrate,
                  tag: this.payload.notification.tag,
                  requireInteraction: this.payload.notification.requireInteraction,
                  renotify: this.payload.notification.renotify
                });
            },
            3000);
        });
      }
    });
  }
}
