import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {FormControl, Validators} from "@angular/forms";
import {Empresa} from "../../../models/empresa";
import {InvoiceIuguService} from "../../../services/iugu/invoice-iugu.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {catchError} from "rxjs/operators";
import {DialogService} from "../../../services/dialog/dialog.service";
import { MatDialogConfig } from "@angular/material/dialog";

@Component({
  selector: "app-dar-baixa-invoice-dialog",
  templateUrl: "./dar-baixa-invoice-dialog.component.html",
  styleUrls: ["./dar-baixa-invoice-dialog.component.scss"]
})
export class DarBaixaInvoiceDialogComponent implements OnInit {

  static openDialog(dialog: MatDialog, invoiceId: string, empresas: Empresa[]): Observable<any> {
    const config = <MatDialogConfig>{
      width: "400px",
      data: {
        invoiceId: invoiceId,
        empresas: empresas
      }
    };

    return dialog.open(DarBaixaInvoiceDialogComponent, config).afterClosed();
  }

  motivoControl: FormControl = new FormControl("", [Validators.required, Validators.minLength(10), this.noWhitespaceValidator]);
  disableButton: boolean = false;

  empresas: Empresa[];
  invoiceId: string;

  subtitle: string;

  constructor(public dialogRef: MatDialogRef<DarBaixaInvoiceDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public invoicesService: InvoiceIuguService,
              public loadingService: LoadingService,
              public dialogService: DialogService) {
    this.empresas = data["empresas"];
    this.invoiceId = data["invoiceId"];
    if (this.empresas.length > 1) {
      this.subtitle = "A fatura está relacionada com as empresas: ";
      this.empresas.forEach((empresa: Empresa, index) => {
        if (index < (this.empresas.length - 1)) {
          this.subtitle += `${empresa.nomeFantasia.trim()}, `;
        } else {
          this.subtitle += empresa.nomeFantasia;
        }
      });
      this.subtitle += ".";
    } else {
      this.subtitle = `A fatura está relacionada com a empresa: ${this.empresas[0].nomeFantasia}.`;
    }
  }

  noWhitespaceValidator(control: FormControl):  {[key: string]: boolean} | null {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { "whitespace": true };
  }

  ngOnInit() {
  }

  darBaixa() {
    this.loadingService.showTopBar();
    this.disableButton = true;
    this.invoicesService.darBaixaInvoice(this.invoiceId, this.motivoControl.value).pipe(
      catchError(err => {
        return this.dialogService.messageDialog()
          .title("Atenção")
          .message(err.message)
          .show();
      })
    ).subscribe(() => {
      this.dialogRef.close(true);
      this.loadingService.hideTopBar();
    });
  }

  close() {
    this.dialogRef.close(false);
  }
}
