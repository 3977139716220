import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {PerfilAcesso} from "../../models/perfil-acesso.model";
import {RepositoryByEmpresa} from "../../repository/repository-by-empresa";
import {AuthService} from "../../modules/login/auth.service";
import {$query, PoMQuery} from "../../services/firebase/criteria/query";
import {OrderByAsc} from "../../services/firebase/criteria/order-by-asc";
import {AppUser} from "../../models/appUser";
import {Observable, of} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {Empresa} from "../../models/empresa";
import {Criterion} from "../../services/firebase/criteria/criterion";
import {AngularFireFunctions} from "@angular/fire/functions";
import {isNotNullOrUndefined} from "../../utils/commons";

@Injectable()
export class PerfilService extends RepositoryByEmpresa<PerfilAcesso> {

  private perfil: PerfilAcesso;

  constructor(db: AngularFirestore, private authService: AuthService, public fns: AngularFireFunctions) {
    super(db, authService, "perfis-acesso", true);
  }

  col$(queryFn: PoMQuery = new PoMQuery(null)): Observable<PerfilAcesso[]> {
    return super.col$(queryFn.add(new OrderByAsc("descricao")));
  }

  remove(id: string): Observable<void> {
    return this.fns.httpsCallable("call-perfisAcesso-remove")({id: id});
  }

  deserialize(value: any): PerfilAcesso {
    return new PerfilAcesso(value);
  }

  isAllowedRecordWithoutCompanie(): boolean {
    // Permite cadastrar um perfil sem informar a empresa
    // apenas se o usuário não possuir uma empresa no cadastro
    return true;
  }

  public getPerfisByLoggedUser(includePerfisOfConsultores?: boolean): Observable<PerfilAcesso[]> {
    return this.authService.currentUser.pipe(mergeMap((currentUser: AppUser) => {
      if (currentUser.isAdministrador()) {
        // Se for Administrador, verifica se quer que inclua os perfis cadastrados por consultores
        if (includePerfisOfConsultores) {
          // Busca todos os perfis
          return this.col$();
        } else {
          // Busca todos os perfis menos os dos Consultores
          return this.getPerfisGeral();
        }
      } else if (currentUser.isConsultor() && !this.authService.currentEmpresa) {
        // Se for Consultor, busca os perfis com o id dele
        return this.getPerfisByConsultor(currentUser.id);
      } else if (currentUser.isAssistente() && !this.authService.currentEmpresa) {
        // Se for Assistente, busca os perfis do Consultor dele
        return this.getPerfisByConsultor(currentUser.consultorId);
      } else {
        return this.col$();
      }
    }));
  }

  getPerfisGeral(): Observable<PerfilAcesso[]> {
    return this.col$().pipe(mergeMap((perfis: PerfilAcesso[]) => {
      return of(perfis.filter((perfil: PerfilAcesso) => {
        return !perfil.hasConsultor();
      }));
    }));
  }

  getPerfisByConsultor(consultorId: string): Observable<PerfilAcesso[]> {
    return this.col$($query(new Criterion("consultorId", "==", consultorId)));
  }

  getPerfilAcessoLoggedUser(): Observable<PerfilAcesso> {
    // Se já tiver carregado o perfil
    if (this.perfil) {
      // Então, retorna o perfil já carregado
      return of(this.perfil);
    } else {
      // Do contrário, carrega os dados do perfil
      // Buscar o perfil do usuário
      return this.authService.currentUser
        .pipe(
          mergeMap(user => {
            return isNotNullOrUndefined(user) ? this.extractUserPerfil(user) : of(null);
          })
        );
    }
  }

  private extractUserPerfil(currentUser: AppUser): Observable<PerfilAcesso> {
    // Se o usuário tiver um perfil de acesso
    if (currentUser.perfilAcesso) {
      return this.getById(currentUser.perfilAcesso.id);
    } else {
      // Se tiver uma empresa selecionada, recuperar o perfil do usuário na empresa
      if (this.authService.currentEmpresa) {
        return this.getById(currentUser.perfisByEmpresa[this.authService.currentEmpresa.id].id);
      } else {
        // Se não, aguarda até selecionar uma empresa
        return this.authService.currentEmpresaObservable()
          .pipe(mergeMap((empresa: Empresa) => {
            return empresa ? this.getById(currentUser.perfisByEmpresa[empresa.id].id) : of(null);
          }));
      }
    }
  }

}
