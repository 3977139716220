import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {BehaviorSubject, Observable, of, throwError, timer} from "rxjs";
import {catchError, distinctUntilChanged, map, mergeMap, pairwise, startWith, tap} from "rxjs/operators";
import {PageService} from "../../../services/page.service";
import {Pedido} from "../../../models/pedido.model";
import {StatusPedido} from "../../../models/status-pedido.enum";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {MonitorPedidosService} from "../monitor-pedidos.service";
import {SnackService} from "../../../services/snack/snack.service";
import {Empresa} from "../../../models/empresa";
import {FormControl} from "@angular/forms";
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";
import {PedidoDetalhesDialogComponent} from "../../gerenciador-pedidos/pedido-detalhes-dialog/pedido-detalhes-dialog.component";
import {MatOptionSelectionChange} from "@angular/material/core";
import {StringUtils} from "../../../utils/string-utils";
import {TipoPagamento} from "../../../models/forma-pagamento.model";
import * as moment from "moment";

@Component({
  selector: "app-gerenciador-pedidos",
  templateUrl: "./monitor-pedidos.component.html",
  styleUrls: ["./monitor-pedidos.component.scss"]
})
export class MonitorPedidosComponent extends PageService<Pedido> implements OnInit, AfterViewInit {

  // Empresas com pedidos
  empresas: Empresa[];
  filteredOptions: Observable<Empresa[]>;

  empresaControl = new FormControl();

  // Lista de pedidos recebidos
  pedidos$: Observable<Pedido[]>;

  // Número de pedidos
  pedidosLength: Number = -1;
  pedidosPendentesLength;

  // Status do pedido para String
  readonly statusPedidoToString = StatusPedido.statusPedidoToString;

  TipoPagamento = TipoPagamento;

  // Alerta sonoro
  private readonly beel = new Audio("assets/sounds/beep.mp3");

  listHeight: number;

  selectedEmpresa$: BehaviorSubject<Empresa> = new BehaviorSubject<Empresa>(null);

  constructor(public service: MonitorPedidosService,
              dialog: MatDialog,
              dialogService: DialogService,
              protected loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/gerenciador/");
    this.empresaControl.valueChanges.subscribe(res => {
      if (isEmpty(res)) {
        this.selectedEmpresa$.next(null);
      }
    });
  }

  displayFn(empresa: Empresa): string {
    return empresa && empresa.nomeFantasia ? empresa.nomeFantasia : "";
  }

  selectEmpresa(event: MatOptionSelectionChange, empresa: Empresa) {
    if (event.isUserInput) {
      this.selectedEmpresa$.next(empresa);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.buscarPedidos();

    // Filtras as opções do select
    this.empresaControl.valueChanges.pipe(
      startWith(""),
      map(value => (typeof value === "string") || (value === null) ? value : value.nomeFantasia),
      tap(nome => {
        if (isNotNullOrUndefined(this.empresas)) {
          this.filteredOptions = of(nome ? this._filter(nome) : this.empresas.slice());
        }
      })
    ).subscribe();
  }

  private _filter(nome: string): Empresa[] {
    const filterValue = nome.toLowerCase();
    return this.empresas.filter(empresa => StringUtils.normalize(empresa.nomeFantasia.toLowerCase()).includes(StringUtils.normalize(filterValue)));
  }

  newItem(): Pedido {
    return null;
  }

  ngAfterViewInit() {
    this.empresaControl.setValue(null);
  }

  buscarPedidos() {
    const ordenar = (pedidos: Pedido[]) => {
      pedidos = pedidos.sort((a, b) => {
        if (a.isPendente()) {
          if (b.isPendente()) {
            if (a.data.toDate().getTime() > b.data.toDate().getTime()) {
              return -2;
            }
            return -1;
          }
          return -1;
        }
        return 1;
      });
      return pedidos;
    };

    this.pedidos$ = this.selectedEmpresa$.asObservable()
      .pipe(
        distinctUntilChanged(),
        tap(() => {
          // Limpar o tamanho da lista de pedidos
          this.pedidosLength = -1;
        }),
        mergeMap((empresa: Empresa) => {
          return this.service.getAllPedidos()
            .pipe(
              startWith([]),
              pairwise(),
              tap(([pedidosPrev, pedidosCurr]: [Pedido[], Pedido[]]) => {

                // Verifica se o primeiro pedido da lista anterior é igual da lista atual
                const isEqualFirstPedido = (previous, current): Boolean => {
                  if (isEmpty(previous) && isEmpty(current)) return true;
                  if (isEmpty(previous) && !isEmpty(current)) return false;
                  if (!isEmpty(previous) && isEmpty(current)) return false;
                  const pedidoPrev = previous[0];
                  const pedidoCurr = current[0];
                  return isNotNullOrUndefined(pedidoPrev) && isNotNullOrUndefined(pedidoCurr) && pedidoPrev.id === pedidoCurr.id;
                };

                if (isEmpty(pedidosPrev) || pedidosCurr.length > pedidosPrev.length || !isEqualFirstPedido(pedidosPrev, pedidosCurr)) {
                  // Se não tiver uma empresa selecionada
                  if (isNullOrUndefined(empresa) && isNullOrUndefined(this.empresaControl.value)) {
                    // Limpar a lista de empresas
                    this.empresas = [];
                    // Carregar as empresas
                    pedidosCurr.forEach((pedido: Pedido) => {
                      if (!this.empresas.some((emp: Empresa) => emp.id === pedido.empresa.id)) {
                        this.empresas.push(pedido.empresa);
                      }
                    });

                    // Ordenar
                    this.empresas.sort((emp1, emp2) => emp1.razaoSocial.localeCompare(emp2.razaoSocial));

                    // Acionar evento de valueChanges para empresaControl
                    this.empresaControl.updateValueAndValidity();
                  }

                  // Verificar se tem uma empresa selecionada
                  if (isNullOrUndefined(empresa) && isNotNullOrUndefined(this.empresaControl.value)) {
                    // Limpa o campo
                    this.empresaControl.setValue(null, {onlySelf: true, emitEvent: true});
                  }

                  // Verificar se a lista de pedidos recebido é maior que a lista atual
                  if (this.pedidosLength >= 0 && pedidosCurr.length > this.pedidosLength) {
                    this.beep();
                    this.scrollListToTop();
                  }
                }
                this.pedidosLength = pedidosCurr.length;
                this.pedidosPendentesLength = this.filterPendentes(pedidosCurr).length;
              }),
              map(([pedidosPrev, predidosCurr]: [Pedido[], Pedido[]]) => {
                // Se tiver uma empresa selecionada, filtra apenas os pedidos da empresa
                if (isNotNullOrUndefined(this.empresaControl.value) && (typeof this.empresaControl.value) !== "string") {
                  const _empresa = empresa ? empresa : this.empresaControl.value;
                  const pedidosEmpresa = predidosCurr.filter(pedido => pedido.empresa.id === _empresa.id);
                  this.pedidosLength = pedidosEmpresa.length;
                  this.pedidosPendentesLength = this.filterPendentes(pedidosEmpresa).length;
                  return ordenar(pedidosEmpresa);
                } else {
                  return ordenar(predidosCurr);
                }
              })
            );
        }),
        catchError(err => {
          this.dialogService
            .messageDialog()
            .message("Falha ao carregar os pedidos. Verifique!")
            .show();
          return throwError(err);
        })
      );
  }

  private beep() {
    this.beel.play()
      .then(() => {
      });
  }

  private scrollListToTop() {
    const lista = document.getElementById("virtualScroll");
    if (isNotNullOrUndefined(lista)) {
      lista.scrollTop = 0;
    }
  }

  getStyleColor(pedido: Pedido) {
    return pedido.isPendente() ? "red" : "black";
  }

  // Ira retornar uma observable boolean, que indicará se o pedido passou a quantidade de minutos informada sem ser aprovado.
  // Isso servirá para estilizar o item da lista quando o pedido estiver atrasado.
  pedidoAtrasado(pedido: Pedido, minutes: number): Observable<boolean> {
    if (pedido.isPendente()) {
      const latePedidoTime = moment(pedido.data.toDate()).add(minutes, "minutes").toDate().getTime();
      const currentTime = new Date().getTime();
      if (currentTime >= latePedidoTime) {
        return of(true);
      } else {
        return of(false).pipe(mergeMap(() => {
          const time = latePedidoTime - currentTime;
          if (currentTime < latePedidoTime) {
            return timer(time).pipe(mergeMap(() => {
              return of(true);
            }));
          } else {
            return of(false);
          }
        }));
      }
    } else {
      return of(false);
    }
  }

  open(pedido) {
    PedidoDetalhesDialogComponent.showDialog(this.dialog, pedido, true, true);
  }

  filterPendentes(pedidos: Pedido[]): Pedido[] {
    return isNotNullOrUndefined(pedidos)
      ? pedidos.filter(pedido => pedido.isPendente())
      : [];
  }

  scrollableListChanged($event: number) {
    this.listHeight = $event;
    this.cdRef.detectChanges();
  }
}
