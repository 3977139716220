import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import {Router} from "@angular/router";
import {TdMediaService} from "@covalent/core/media";
import {tap} from "rxjs/operators";
import {Observable, Subscription} from "rxjs/Rx";
import {environment} from "../../../environments/environment";
import {AppUser, UserType} from "../../models/appUser";
import {Empresa} from "../../models/empresa";
import {PerfilAcesso, Role} from "../../models/perfil-acesso.model";
import {AuthService} from "../../modules/login/auth.service";
import {LoadingService} from "../../services/loading/loading.service";
import {SnackService} from "../../services/snack/snack.service";
import {isNotNullOrUndefined, isNullOrUndefined} from "../../utils/commons";
import {DeliveryService} from "../delivery/delivery.service";
import {SelectEmpresaDialogComponent} from "../empresa/select-empresa-dialog/select-empresa-dialog.component";
import {FormaPagamentoService} from "../forma-pagamento/forma-pagamento.service";
import {PerfilService} from "../perfil-acesso/perfil.service";
import {SettingsComponent} from "../settings/settings.component";
import {UsersService} from "../users/users.service";
import {ProdutoService} from "../produto/produto.service";
import {itensMenu, MenuItem} from "./menu-itens";
import {MessagingService} from "../../services/messaging.service";
import {GerenciadorPedidosService} from "../gerenciador-pedidos/service/gerenciador-pedidos.service";

@Component({
  selector: "home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {

  // Opções do drawer
  private readonly itens = itensMenu;
  itensMenu: MenuItem[] = [];

  Roles = Role;
  usuarioCanSelectEmpresa: boolean = true;

  // Usuário atualmente logado
  currentUser: AppUser;

  // Subscriptions do gerenciador de pedidos
  private gerenciadorDePedidosSubscription: Subscription;

  @ViewChild("sidenav", { static: true })
  public sidenav: MatSidenav;

  constructor(private authService: AuthService,
              private produtoService: ProdutoService,
              private userService: UsersService,
              private deliveryService: DeliveryService,
              private formasPagamentoService: FormaPagamentoService,
              private dialog: MatDialog,
              private router: Router,
              private loading: LoadingService,
              private perfilService: PerfilService,
              private media: TdMediaService,
              private snackbar: SnackService,
              private messagingService: MessagingService,
              private gerenciadorService: GerenciadorPedidosService) {
  }

  ngOnInit() {
    this.loading.showTopBar();
    this.checkNetworkStatus();
    this.validarMenuItensObrigatorios();
    this.loadMenuItens();

    // Esperar um usuário logado
    this.authService.currentUser.subscribe((user: AppUser) => {
      if (user) {
        // Manter o usuário logado
        this.currentUser = user;
        this.perfilService.getPerfilAcessoLoggedUser().subscribe((value) => {
          this.usuarioCanSelectEmpresa = value.hasRole(Role.UsuarioSelectEmpresa);
          if (user.userType === UserType[UserType.Lojista]) {
            if (value.hasRole(Role.GerenciadorPedidosRead)) {
              if (this.router.url === "/home") {
                this.router.navigate(["/home/gerenciador/list"]);
              }
            }
            this.messagingService.getPermission();
          }
        });

        // Se o usuário for lojista
        if (user.isLojista()) {
          // Monitora o recebimento de pedidos
          this.gerenciadorDePedidosSubscription = this.gerenciadorService.getPedidos().subscribe();
        }

        // Parar o loading
        this.loading.hideTopBar();
      }
    });
  }

  validarMenuItensObrigatorios(): void {
    this.itens.forEach((item) => {
      if (item.nameItem === "Usuários") {
        item.showImage = this.userService.usersIsEmpty();
      } else if (item.nameItem === "Delivery") {
        item.showImage = this.deliveryService.deliveryIsEmpty();
      } else if (item.nameItem === "Formas de Pagamento") {
        item.showImage = this.formasPagamentoService.formaPagamentoIsEmpty();
      } else if (item.nameItem === "Produtos") {
        item.showImage = this.produtoService.produtoIsEmpty();
      }
    });
  }

  checkNetworkStatus() {
    Observable.fromEvent(window, "online").subscribe(() => {
      this.snackbar.show("Conexão estabelecida!", "Entendi", undefined, "top");
    });

    Observable.fromEvent(window, "offline").subscribe(() => {
      this.snackbar.show(
        "Desculpe, mas parece que você está off-line!",
        "",
        0,
        "top"
      );
    });
  }

  isOpened(): boolean {
    return this.getMode() === ("side" || "push");
  }

  getMode(): string {
    if (this.media.query("md")) return "side";
    else if (this.media.query("sm")) return "push";
    else if (this.media.query("gt-gm")) return "side";
    else if (this.media.query("xs")) return "over";
    else return "side";
  }

  empresaSelectorStyle() {
    return !this.isShownSelectEmpresa()
      ? {}
      : {"text-decoration": "underline"};
  }

  getUserName(): string {
    return this.currentUser ? this.currentUser.name : null;
  }

  getNomeEmpresa(): string {
    return this.hasEmpresaSelected()
      ? this.currentEmpresa().nomeFantasia
      : "Selecionar Empresa";
  }

  hasEmpresaSelected(): boolean {
    return isNotNullOrUndefined(this.currentEmpresa());
  }

  currentEmpresa(): Empresa {
    return this.authService.currentEmpresa;
  }

  currentEmpresaObservable(): Observable<Empresa> {
    return this.authService.currentEmpresaObservable();
  }

  logout() {
    this.loading.showTopBar();
    this.authService.logout().subscribe(() => {
      this.loading.hideTopBar();
    });
  }

  selecionarEmpresa() {
    if (this.isShownSelectEmpresa()) {
      SelectEmpresaDialogComponent.openDialog(this.dialog).subscribe(
        (value) => {
          if (value) {
            // Redirecionar para a home
            this.router.navigate(["/home"]);
            this.loadMenuItens();
          }
        }
      );
    }
  }

  isShownSelectEmpresa(): boolean {
    /*
     * Exibe a opção de selecionar empresa caso:
     * o usuário seja administrador
     * ou, o usuário seja um lojista e tenha mais de uma empresa
     */
    if (isNotNullOrUndefined(this.currentUser)) {
      // Verificar se o usuário é administrador
      if (!this.currentUser.isLojista()) {
        return true;
      }

      // Verificar se tem mais de uma empresa no cadastro do usuário
      return (
        isNotNullOrUndefined(this.currentUser.empresas) &&
        this.currentUser.empresas.length > 1
      );
    }

    return false;
  }

  onNavItemClick(item) {
    if (this.media.query("sm") || this.media.query("xs")) {
      this.sidenav.toggle();
    }
    if (item.link) {
      this.router.navigate([item.link]);
    } else if (item.openDialog) {
      item.openDialog(this.dialog);
    }
  }

  private loadMenuItens() {
    this.perfilService
      .getPerfilAcessoLoggedUser()
      // Limpar a lista de itens do menu
      .pipe(tap(() => (this.itensMenu = [])))
      .subscribe((perfil: PerfilAcesso) => {
        if (!isNullOrUndefined(perfil)) {
          this.itens.forEach((item) => {
            const contains = item.roles.some((role: Role) => {
              return perfil.roles.some(
                (rolePerfil) => role === Role[rolePerfil]
              );
            });

            if (contains) {
              // Verificar se precisar de uma empresa logada
              if (
                isNullOrUndefined(item.needCompany) ||
                (!item.needCompany && !this.hasEmpresaSelected()) ||
                (item.needCompany && this.hasEmpresaSelected())
              ) {
                if (isNotNullOrUndefined(item.onlyForAdministrador)) {
                  if (
                    item.onlyForAdministrador &&
                    this.currentUser.isAdministrador()
                  ) {
                    this.itensMenu.push(item);
                  }
                } else {
                  this.itensMenu.push(item);
                }
              }
            }
          });
        }
      });
  }

  settings() {
    SettingsComponent.showDialog(this.dialog).subscribe(() => {
    });
  }

  get version(): String {
    return environment.version;
  }

  ngOnDestroy() {
    if (this.gerenciadorDePedidosSubscription) {
      this.gerenciadorDePedidosSubscription.unsubscribe();
    }
  }
}
