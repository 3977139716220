import {Injectable} from "@angular/core";
import {Repository} from "../../repository/Repository";
import {Bairro} from "../../models/bairro.model";
import {AngularFirestore} from "@angular/fire/firestore";
import {Cidade} from "../../models/cidade.model";
import {OrderByAsc} from "../../services/firebase/criteria/order-by-asc";
import {OrderBy} from "../../services/firebase/criteria/order-by";
import WriteBatch = firebase.firestore.WriteBatch;
import {isNotNullOrUndefined} from "../../utils/commons";
import * as firebase from "firebase";

@Injectable()
export class BairroService extends Repository<Bairro> {

  // Cidade selecionados no ultimo cadastro.
  // Utilizado para que o usuário não precise selecionar a cidade/UF em todos os bairros.
  // Isso fica em memória até ser atualizado a página da aplicação
  cidadeSelecionada: Cidade;

  constructor(db: AngularFirestore) {
    super(db, "bairros", true);
  }

  deserialize(bairro): Bairro {
    return new Bairro(bairro);
  }

  protected orderBy(): OrderBy {
    return new OrderByAsc("nome");
  }

  salvarNovosBairros(bairros: Bairro[]) {
    const batch: WriteBatch = this.db.firestore.batch();

    bairros.forEach(bairro => {
      batch.set(this.db.firestore.collection(this.collectionName).doc(bairro.id), {
        id: bairro.id,
        nome: bairro.nome,
        importado: bairro.importado,
        ref: bairro.ref,
        cidade: {
          nome: bairro.cidade.nome,
          id: bairro.cidade.id,
          ref: isNotNullOrUndefined(bairro.cidade.ref) ? bairro.cidade.ref : null,
          consultores: bairro.cidade.consultores,
          uf: bairro.cidade.uf
        }
      });
    });

    return batch.commit();
  }

}
