import {Component, Input, OnInit} from "@angular/core";
import { LoadingService} from "../../../../../services/loading/loading.service";

@Component({
  selector: "app-extended-fab",
  templateUrl: "./extended-fab.component.html",
  styleUrls: ["./extended-fab.component.scss"]
})
export class ExtendedFabComponent implements OnInit {

  @Input()
  matIconName: string;

  @Input()
  text: string;

  @Input()
  borderColor: string;

  @Input()
  isLoading: boolean = false;

  @Input()
  fontColor: string;

  @Input()
  textUpperCase: boolean = false;

  constructor(public loadingService: LoadingService) { }

  ngOnInit() {
  }

}
