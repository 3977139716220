import {Injectable} from "@angular/core";
import {SessionStorageService} from "ngx-store";
import {Empresa} from "../../models/empresa";
import {isNotNullOrUndefined} from "../../utils/commons";
import {EmpresaAuthService} from "../../components/empresa/empresa-auth.service";

@Injectable()
export class PomStorageService {

  // Definir as constantes
  readonly empresaKey = "empresa";

  constructor(public storageService: SessionStorageService,
              private empresaService: EmpresaAuthService) {
  }

  getEmpresa(): Empresa {
    const empresaJson = this.storageService.get(this.empresaKey);
    if (isNotNullOrUndefined(empresaJson)) {
      const empresa = JSON.parse(empresaJson, (key, value) => {
        if (key === "ref" && isNotNullOrUndefined(value) && value.length > 0) {
          return this.empresaService.getDoc(value).ref;
        }
        return value;
      });
      return new Empresa(empresa);
    }
    return null;
  }

  setEmpresa(empresa: Empresa) {
    const JSON = require("circular-json");
    const empresaJson = JSON.stringify(empresa, (key: string, value: any) => {
      if (key === "ref" && value) {
        return value.id;
      }
      return value;
    });
    this.storageService.set(this.empresaKey, empresaJson);
  }

  removeEmpresa() {
    this.storageService.remove(this.empresaKey);
  }

}
