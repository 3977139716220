import {BehaviorSubject} from "rxjs";
import {Authority} from "./authorities.datasource";

export class PerfilByGroupNode {
  children: PerfilByGroupNode[];
  item: string;
  perfil?: TreeData;

  constructor(perf: any = {}) {
    this.children = perf.children;
    this.item = perf.item;
    this.perfil = perf.perfil;
  }
}

export class PerfilByGroupFlatNode {
  item: string;
  perfil?: TreeData;
  level: number;
  expandable: boolean;
}

export class TreeData {
  constructor(public title: string,
              public dataSource: Authority[]) {
  }
}

export class CreateDataTreePerfilAcesso {

  dataChange: BehaviorSubject<PerfilByGroupNode[]> = new BehaviorSubject<PerfilByGroupNode[]>([]);
  dataTree: TreeData[];

  get data(): PerfilByGroupNode[] {
    return this.dataChange.value;
  }

  constructor(dataTree: TreeData[]) {
    this.dataTree = dataTree;
  }

  initialize() {
    const data = this.buildFileTreeArr(this.listToArray(this.dataTree));
    this.dataChange.next(data);
  }

  listToArray(data: TreeData[]): any {
    return Object.keys(data).map(res => {
      return [data[res].title, Object.keys(data[res].dataSource).map(perf => [data[res].dataSource[perf].descricao, data[res].dataSource[perf].role])];
    });
  }

  buildFileTreeArr(arr): PerfilByGroupNode[] {
    return arr.map(value => {
      const node = new PerfilByGroupNode();
      node.item = value[0];

      node.children = value[1].map(perf => {
        return new PerfilByGroupNode({
          children: undefined,
          item: perf[0],
          perfil: perf[1]
        });
      });

      return node;
    });
  }
}
