import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Pedido, TipoPedido, TipoPedidoToString } from "../../../models/pedido.model";
import { MatDialog } from "@angular/material/dialog";
import { DialogService } from "../../../services/dialog/dialog.service";
import { LoadingService } from "../../../services/loading/loading.service";
import { SnackService } from "../../../services/snack/snack.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PedidosService } from "../../../services/pedidos.service";
import { PageService } from "../../../services/page.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { $query } from "../../../services/firebase/criteria/query";
import { Criterion } from "../../../services/firebase/criteria/criterion";
import * as moment from "moment";
import { isEmpty, isNotNullOrUndefined } from "../../../utils/commons";
import { finalize, map, mergeMap, tap } from "rxjs/operators";
import { StatusPedido } from "../../../models/status-pedido.enum";
import { Subscription } from "rxjs/Rx";
import { AuthService } from "../../../modules/login/auth.service";
import { PedidoDetalhesDialogComponent } from "../../gerenciador-pedidos/pedido-detalhes-dialog/pedido-detalhes-dialog.component";
import { Observable, of } from "rxjs";
import { Empresa } from "../../../models/empresa";
import { EmpresaService } from "../../empresa/empresa.service";
import { AppUser } from "../../../models/appUser";
import { GeradorDePdfService } from "../../../services/pdf/gerador-de-pdf.service";
import { PdfRelatorioFiltros } from "../../../services/pdf/gerador-pdf-relatorio-filtros";
import { TipoPagamento } from "../../../models/forma-pagamento.model";

class DadosPDF {
  dataInicial;
  dataFinal;
  status;
  empresa;
  tipo;
  numero;
  tipoPagamento;
}

@Component({
  selector: "app-pedidos-list",
  templateUrl: "./relatorio-pedidos-empresa-list.component.html",
  styleUrls: ["./relatorio-pedidos-empresa-list.component.scss"]
})
export class RelatorioPedidosEmpresaListComponent extends PageService<Pedido> implements OnInit, OnDestroy {

  empresa$: Observable<Empresa[]>;

  readonly statusTodos: string = "Todos";

  form: FormGroup;

  tipoPedidoToString = TipoPedidoToString;
  TipoPagamento = TipoPagamento;
  StatusPedido = StatusPedido;

  // Lista de Pedidos
  pedidos: Pedido[];

  // Totalização dos pedidos
  valorTotalProdutos: number = 0;
  valorTotalPedeQueChega: number = 0;
  valorTotalDelivery: number = 0;
  valorTotalPoMs: number = 0;
  valorTotal: number = 0;
  valorAPagar: number = 0;
  valorPQCEntregaCliente: number = 0;
  valorPQCEntregaLojista: number = 0;
  qtdePQCEntregaLojista: number = 0;

  // Status
  readonly statusList: { key: string, value: string }[] = [];

  // Subscriptions
  pedidosSubscription: Subscription;
  dataInicialValueChangesSubscription: Subscription;

  showAdvancedFilters: boolean = false;
  disableButton: boolean = false;

  listHeight: number;
  triggerOnChanges: boolean = false;

  dadosPdf: DadosPDF = new DadosPDF();

  constructor(service: PedidosService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              public authService: AuthService,
              public formBuilder: FormBuilder,
              public empresaServive: EmpresaService,
              public geradorDePdfService: GeradorDePdfService) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/pedidos/");

    this.empresa$ = this.authService.currentUser.pipe(
      mergeMap((currentUser: AppUser) => {
        if (currentUser.isLojista()) {
          return this.empresaServive.getEmpresasLojista(currentUser);
        } else {
          return of([this.authService.currentEmpresa]);
        }
      })
    );

    this.form = this.formBuilder.group({
      dataInicial: [new Date()],
      dataFinal: [new Date()],
      status: [StatusPedido[StatusPedido.APROVADO]],
      empresa: [this.authService.currentEmpresa],
      tipo: [this.statusTodos],
      numero: [],
      tipoPagamento: [this.statusTodos]
    });

    // Carregar os status
    Object.keys(StatusPedido).forEach(item => {
      const isValueProperty = parseInt(item, 10) >= 0;
      if (isValueProperty) {
        if (StatusPedido[item] === StatusPedido[StatusPedido.REJEITADO] || StatusPedido[item] === StatusPedido[StatusPedido.CANCELADO]) {
          this.statusList.push({ key: StatusPedido[item], value: StatusPedido.statusPedidoToString(StatusPedido[item]) });
        }
      }
    });

    const dataInicialFormControl = this.form.get("dataInicial");
    const dataFinalFormControl = this.form.get("dataFinal");

    this.dataInicialValueChangesSubscription = dataInicialFormControl.valueChanges
      .subscribe(dataInicial => {
        const dataFinal = dataFinalFormControl.value;
        if (dataInicial > dataFinal) {
          dataFinalFormControl.patchValue(dataInicial);
        }
      });

  }

  ngOnInit() {
    this.buscarPedidos();
  }

  tiposKeys(): Array<string> {
    return Object.keys(TipoPedido);
  }

  compareFn(c1: TipoPedido, c2: TipoPedido): boolean {
    return c1 && c2 && TipoPedido[c1] === TipoPedido[c2];
  }

  compareEmpresa(e1: Empresa, e2: Empresa): boolean {
    return e1 && e2 && e1.id === e2.id;
  }

  compareSt(c1: StatusPedido, c2: StatusPedido): boolean {
    return c1 && c2 && StatusPedido[c1] === StatusPedido[c2];
  }

  newItem(): Pedido {
    return null;
  }

  buscarPedidos() {
    if (this.authService.currentEmpresa) {

      // Atualizar variáveis Form
      this.dadosPdf.dataInicial = this.form.get("dataInicial").value;
      this.dadosPdf.dataFinal = this.form.get("dataFinal").value;
      this.dadosPdf.status = this.form.get("status").value;
      this.dadosPdf.empresa = this.form.get("empresa").value;
      this.dadosPdf.tipo = this.form.get("tipo").value;
      this.dadosPdf.numero = this.form.get("numero").value;
      this.dadosPdf.tipoPagamento = this.form.get("tipoPagamento").value;

      this.disableButton = true;
      this.loading(true);
      // Filtros
      const dataInicial = moment(this.form.get("dataInicial").value).startOf("day").toDate();
      const dataFinal = moment(this.form.get("dataFinal").value).endOf("day").toDate();
      let status = this.form.get("status").value;
      const empresa = this.form.get("empresa").value;
      let tipo = this.form.get("tipo").value;
      const numero = this.form.get("numero").value ? this.form.get("numero").value.toString() : this.form.get("numero").value;
      let tipoPagamento = this.form.get("tipoPagamento").value;

      status = status === this.statusTodos ? undefined : status;
      tipo = tipo === this.statusTodos ? undefined : tipo;
      tipoPagamento = tipoPagamento === this.statusTodos ? undefined : tipoPagamento;

      // Montar o filtro
      const filters = $query(
        new Criterion("data", ">=", dataInicial),
        new Criterion("data", "<=", dataFinal),
        new Criterion("empresa.id", "==", empresa.id)
      );

      if (isNotNullOrUndefined(status) && status !== StatusPedido[StatusPedido.APROVADO]) {
        filters.add(new Criterion("status", "==", status));
      }

      if (isNotNullOrUndefined(tipo)) {
        if (tipo === TipoPedido[TipoPedido.BALCAO]) {
          filters.add(new Criterion("buscarNoBalcao", "==", true));
        } else if (tipo === TipoPedido[TipoPedido.DELIVERY]) {
          filters.add(new Criterion("buscarNoBalcao", "==", false));
        }
      }

      if (isNotNullOrUndefined(tipoPagamento)) {
        if (tipoPagamento === "online") {
          filters.add(new Criterion("formaPagamento.tipoPagamento", "==", TipoPagamento[TipoPagamento.online]));
        }
      }

      if (!isEmpty(numero)) {
        // Se o filtro for por número, então ignora todos os demais filtros
        filters.clear();
        filters.add(new Criterion("numero", "==", numero));
        filters.add(new Criterion("empresa.id", "==", empresa.id));
      }

      // Limpar a lista de pedidos
      this.pedidos = [];

      // Zerar a totalização
      this.valorTotalProdutos = 0;
      this.valorTotal = 0;
      this.valorTotalPoMs = 0;
      this.valorTotalDelivery = 0;
      this.valorTotalPedeQueChega = 0;
      this.valorAPagar = 0;
      this.valorPQCEntregaCliente = 0;
      this.valorPQCEntregaLojista = 0;
      this.qtdePQCEntregaLojista = 0;

      // Buscar os pedidos
      this.pedidosSubscription = (this.service as PedidosService).col$(filters)
        .take(1)
        .pipe(
          map(pedidos => {
            if (isNotNullOrUndefined(tipoPagamento) && tipoPagamento === "entrega") {
              pedidos = pedidos.filter(pedido => {
                return pedido.formaPagamento.tipoPagamento !== TipoPagamento[TipoPagamento.online];
              });
            }
            if (status === StatusPedido[StatusPedido.APROVADO]) {
              return pedidos.filter(pedido => {
                return !(pedido.status === StatusPedido[StatusPedido.CANCELADO] || pedido.status === StatusPedido[StatusPedido.REJEITADO]);
              });
            } else {
              return pedidos;
            }
          }),
          tap(pedidos => {
            // Totalizar os valores
            pedidos.forEach(pedido => {
              if (pedido.status !== StatusPedido[StatusPedido.REJEITADO] && pedido.status !== StatusPedido[StatusPedido.CANCELADO]) {
                this.valorTotalProdutos += pedido.subtotal;
                this.valorTotal += pedido.total;
                this.valorTotalPoMs += pedido.pontos;
                if (pedido.pedeQueChega) {
                  if (isNotNullOrUndefined(pedido.valorEntregaCancelada) && pedido.valorEntregaCancelada > 0) {
                    this.valorTotalDelivery += pedido.valorEntrega;
                  } else {
                    this.valorTotalPedeQueChega += pedido.valorEntrega;
                    this.valorPQCEntregaCliente += pedido.valorEntrega;
                    if (isNotNullOrUndefined(pedido.valorEntregaLojista)) {
                      this.valorTotalPedeQueChega += pedido.valorEntregaLojista;
                      this.valorPQCEntregaLojista += pedido.valorEntregaLojista;
                      if (pedido.valorEntregaLojista > 0 && !this.authService.user.isLojista()) {
                        this.qtdePQCEntregaLojista++;
                      }
                    }
                  }
                } else {
                  this.valorTotalDelivery += pedido.valorEntrega;
                }
              }

              this.valorAPagar += pedido.getValorAPagar();
            });
          }),
          finalize(() => {
            this.disableButton = false;
            this.loading(false);
          })
        )
        .subscribe(pedidos => {
          this.pedidos = pedidos;
        }, err => {
          this.dialogService
            .messageDialog()
            .title("Atenção")
            .message(err)
            .show();
        });
    }
  }

  open(pedido: Pedido) {
    PedidoDetalhesDialogComponent.showDialog(this.dialog, pedido, false, true).subscribe(result => {
      if (result) {
        this.buscarPedidos();
      }
    });
  }

  gerarPdf() {

    const filtrosRelatorio: PdfRelatorioFiltros = new PdfRelatorioFiltros();

    //Formatar preço para BRL
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    });

    const _empresaNome = this.dadosPdf.empresa;
    filtrosRelatorio.empresaNome = _empresaNome.nomeFantasia;
    filtrosRelatorio.dataInicialSimples = String(moment(this.dadosPdf.dataInicial).format("DD/MM/YYYY"));
    filtrosRelatorio.dataFinalSimples = String(moment(this.dadosPdf.dataFinal).format("DD/MM/YYYY"));
    filtrosRelatorio.totalPreco = String(formatter.format(this.valorTotalProdutos));
    filtrosRelatorio.totalEntrega = this.valorTotalDelivery;
    filtrosRelatorio.totalPQC = this.valorTotalPedeQueChega;
    filtrosRelatorio.totalPoMs = this.valorTotalPoMs.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    filtrosRelatorio.totalValor = String(formatter.format(this.valorTotal));
    filtrosRelatorio.tipoPedidos = this.dadosPdf.tipo;
    filtrosRelatorio.statusPedidos = this.dadosPdf.status;
    filtrosRelatorio.tipoPagamento = this.dadosPdf.tipoPagamento;
    filtrosRelatorio.numeroPedidos = this.dadosPdf.numero;
    filtrosRelatorio.valorEntregaCliente = this.valorPQCEntregaCliente;
    filtrosRelatorio.valorEntregaLojista = this.valorPQCEntregaLojista;

    this.geradorDePdfService.gerarPdfRelatorio(this.pedidos, filtrosRelatorio);
  }

  pedeQueChegaInfoTooltip():string {
    const moneyFormat = new Intl.NumberFormat("pt", {style: "currency", currency: "BRL"});
    if (this.valorPQCEntregaCliente > 0) {
      return `${moneyFormat.format(this.valorPQCEntregaCliente)} pago pelo cliente | ${moneyFormat.format(this.valorPQCEntregaLojista)} pago pela empresa`;
    } else {
      return `${moneyFormat.format(this.valorPQCEntregaLojista)} pago pela empresa`;
    }
  }

  scrollableListChanged($event: number) {
    this.listHeight = $event;
    this.cdRef.detectChanges();
  }

  toggleFilters() {
    this.showAdvancedFilters = !this.showAdvancedFilters;
    setTimeout(() => {
      this.triggerOnChanges = !this.triggerOnChanges;
    }, 250);
  }

  ngOnDestroy() {
    if (this.pedidosSubscription) {
      this.pedidosSubscription.unsubscribe();
    }
    if (this.dataInicialValueChangesSubscription) {
      this.dataInicialValueChangesSubscription.unsubscribe();
    }
  }
}
