import {Directive, ElementRef, EventEmitter, HostListener, Output} from "@angular/core";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: "[scrollable]"
})
export class ScrollableDirective {
  @Output() bottom = new EventEmitter();

  @Output() top = new EventEmitter();

  constructor(public el: ElementRef) { }

  @HostListener("scroll", ["$event"])
  onScroll(event) {
    try {

      const top = event.target.scrollTop;
      const height = this.el.nativeElement.scrollHeight;
      const offset = this.el.nativeElement.offsetHeight;

      if (top > height - offset - 1) {
        this.bottom.emit("bottom");
      }

      if (top === 0) {
        this.top.emit("top");
      }

    } catch (err) {}
  }

}
