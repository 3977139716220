import {isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";
import {Criterion} from "./criterion";
import {CollectionReference, Query} from "@angular/fire/firestore";

export class PoMQuery {
  readonly criterions: Array<Criterion>;

  constructor(criterions: Array<Criterion>);
  constructor(...criterions: Criterion[]);
  constructor(criterions?: any) {
    if (!isNullOrUndefined(criterions)) {
      this.criterions = criterions;
    } else {
      this.criterions = new Array();
    }
  }

  addFirst(criterion: Criterion): PoMQuery {
    // Adiciona o critério na primeira posição do array
    this.criterions.unshift(criterion);
    return this;
  }

  add(...criterions: Criterion[]): PoMQuery {
    criterions.forEach(c => {
      this.criterions.push(c);
    });
    return this;
  }

  isEmpty(): boolean {
    return !this.criterions || this.criterions.length === 0;
  }

  clear() {
    while (this.criterions.length) {
      this.criterions.pop();
    }
  }

  containsCriterion(criterion: Criterion): boolean {
    return !this.isEmpty() && this.criterions.some((c: Criterion) => criterion.fieldPath === c.fieldPath);
  }

  build(ref: CollectionReference): Query {
    const criterions = this.criterions
      .filter((c) => isNotNullOrUndefined(c));
    let q: Query = null;
    for (let i = 0; i < criterions.length; i++) {
      if (isNullOrUndefined(q)) {
        q = criterions[i].build(ref);
      } else {
        q = criterions[i].build(q);
      }
    }
    return q;
  }
}

export function $query(...criterions: Criterion[]): PoMQuery {
  return new PoMQuery(criterions);
}
