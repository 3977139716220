import {RecordByEmpresa} from "./record-by-empresa";

export class Cozinha extends RecordByEmpresa {

  nome: string;
  printFormat: string;
  pageDimension: string;
  printer: string;
  inactive: boolean;

  constructor(cozinha?: any) {
    super();
    if (cozinha) {
      this.id = cozinha.id;
      this.empresa = cozinha.empresa;
      this.ref = cozinha.ref;
      this.nome = cozinha.nome;
      this.printFormat = cozinha.printFormat;
      this.pageDimension = cozinha.pageDimension;
      this.printer = cozinha.printer;
      this.inactive = cozinha.inactive;
    }
  }

}
