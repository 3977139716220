import {Component, Input, OnInit} from "@angular/core";
import {Pedido} from "../../models/pedido.model";
import {StatusPedido} from "../../models/status-pedido.enum";

@Component({
  selector: "app-pedido-stepper-status",
  templateUrl: "./pedido-stepper-status.component.html",
  styleUrls: ["./pedido-stepper-status.component.scss"]
})
export class PedidoStepperStatusComponent implements OnInit {

  @Input()
  pedido: Pedido;
  StatusPedido = StatusPedido;

  constructor() {
  }

  ngOnInit() {
  }

}
