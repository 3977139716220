import {InvoiceIugu} from "./iugu/invoice.iugu.model";

export enum GerenciadorMessageType {
  BEFORE_HAS_PENDING_INVOICES = "BEFORE_HAS_PENDING_INVOICES",
  DUE_DAY = "DUE_DAY",
  AFTER_HAS_PENDING_INVOICES = "AFTER_HAS_PENDING_INVOICES",
  AFTER_MAX_PAYMENT_DAYS = "AFTER_MAX_PAYMENT_DAYS",
  COMPANY_BLOCKED_PAYMENT = "COMPANY_BLOCKED_PAYMENT",
  COMPANY_BLOCKED = "COMPANY_BLOCKED"
}

export class GerenciadorMessage {
  type: string;
  title: string;
  subtitle: string;
  showHeader: boolean;
  pendingInvoices: InvoiceIugu[];

  constructor(gerenciadorMessage: any = {}) {
    this.type = gerenciadorMessage.type;
    this.title = gerenciadorMessage.title;
    this.subtitle = gerenciadorMessage.subtitle;
    this.showHeader = gerenciadorMessage.showHeader;
    this.pendingInvoices = gerenciadorMessage.pendingInvoices;
  }
}
