export function isNullOrUndefined(value: any): boolean {
  return value === null || value === undefined;
}

export function isNotNullOrUndefined(value: any): boolean {
  return !isNullOrUndefined(value);
}

export function isEmpty(value: any): boolean {
  if (isNullOrUndefined(value)) {
    return true;
  }
  if (isString(value)) {
    return value.trim().length === 0;
  }
  if (isArray(value)) {
    return value.length === 0;
  }
  if (isObject(value)) {
    return Object.keys(value).length === 0;
  }
  return value.length <= 0;
}

export function isObject(value: any): boolean {
  return value instanceof Object;
}

export function isString(value: any): boolean {
  return typeof value === "string";
}

export function isBoolean(value: any): boolean {
  return typeof value === "boolean";
}

export function isDate(value: any): boolean {
  return value instanceof Date;
}

export function isArray(value: any): boolean {
  return Array.isArray(value);
}

export function isNumber(value: any): boolean {
  return !isNaN(value);
}

