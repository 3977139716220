import {Component, OnInit, ViewChild} from "@angular/core";
import {map, mergeMap} from "rxjs/operators";
import {AuthService} from "../../../modules/login/auth.service";
import {Empresa, TipoPessoa} from "../../../models/empresa";
import {isNotNullOrUndefined} from "../../../utils/commons";
import {Observable} from "rxjs";
import {LoadingService} from "../../../services/loading/loading.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {CustomersIuguService} from "../../../services/iugu/customers.iugu.service";
import {EmpresaService} from "../../empresa/empresa.service";
import {Location} from "@angular/common";
import {SubcontaFormComponent} from "./subconta-form/subconta-form.component";
import {PagamentoOnline, PagtOnlineVerificationType, PgtOnlineStatus} from "../../../models/pagamento-online.model";
import {MatTab, MatTabGroup, MatTabHeader} from "@angular/material/tabs";
import {Role} from "../../../models/perfil-acesso.model";
import {PerfilService} from "../../perfil-acesso/perfil.service";

@Component({
  selector: "app-forma-pagamento-online-form",
  templateUrl: "./forma-pagamento-online-form.component.html",
  styleUrls: ["./forma-pagamento-online-form.component.scss"]
})
export class FormaPagamentoOnlineFormComponent implements OnInit {

  @ViewChild(SubcontaFormComponent, {static: false})
  private subcontaFormComponent: SubcontaFormComponent;

  @ViewChild("matTabGroup", {static: true}) matTabGroup: MatTabGroup;

  Role = Role;

  empresa: Empresa;

  formMudou: boolean = null;
  formValid: boolean = null;

  isNotNullOrUndefined = isNotNullOrUndefined;

  saving: boolean = false;
  sendVerificationError: boolean = false;
  pagamentoIsEnabled: Observable<boolean>;

  contaEspelhoActive: boolean = false;
  contaEspelhoPermission: boolean = false;

  tabIndex: number = 0;

  constructor(public auth: AuthService,
              public loading: LoadingService,
              public customersIuguService: CustomersIuguService,
              public dialog: DialogService,
              public empresaService: EmpresaService,
              public perfilService: PerfilService,
              private location: Location,) {

    this.empresa = this.auth.currentEmpresa;

    // Verifica de o pagamento online está habilitado, para a exibição do chip de Disponível/Indisponível
    this.pagamentoIsEnabled = this.empresaService.getEmpresa(this.empresa.id).pipe(mergeMap(emp => {

      return this.perfilService.getPerfilAcessoLoggedUser().pipe(map((perf) => {
        this.contaEspelhoPermission = perf.hasRole(Role.FormaPagamentoSubcontaEspelho);
        if (isNotNullOrUndefined(emp.pagamentoOnline)
          && isNotNullOrUndefined(emp.pagamentoOnline.mirrorPagamentoOnline)
          && isNotNullOrUndefined(emp.pagamentoOnline.mirrorPagamentoOnline.accountId)
          && this.contaEspelhoPermission) {
          // Caso exista dados de conta espelho, e o usuário tenha permição, exibe a aba de criação de conta espelho
          this.contaEspelhoActive = true;
        }
        if (isNotNullOrUndefined(emp.pagamentoOnline)) {
          return emp.pagamentoOnline.enabled;
        } else {
          return false;
        }
      }));
    }));
  }

  ngOnInit() {
    // Chama o método de interceptação de mudança de abas, ao clicar em uma aba
    this.matTabGroup._handleClick = this.interceptTabChange.bind(this);
  }

  // Interrompe a mudança de abas quando houver mudança nos formulários
  interceptTabChange(tab: MatTab, tabHeader: MatTabHeader, index: number) {
    if (this.formMudou) {
      const result = confirm("Os dados ainda não foram salvos. Deseja trocar da aba mesmo assim?");
      return result && MatTabGroup.prototype._handleClick.apply(this.matTabGroup, arguments);
    } else {
      return MatTabGroup.prototype._handleClick.apply(this.matTabGroup, arguments);
    }
  }

  // Método para retornar para a página anterior
  goBack() {
    this.location.back();
  }

  // Constroi o objeto que será usado para a verificação da subconta
  buildData(): any {
    const form = this.subcontaFormComponent.form.value;
    const data: any = {};

    data.price_range = "Mais que R$ 500,00";
    data.physical_products = true;
    data.business_type = form.business_type;
    data.person_type = form.person_type === TipoPessoa[TipoPessoa.FISICA] ? "Pessoa Física" : "Pessoa Jurídica";
    data.automatic_transfer = false;
    if (form.person_type === TipoPessoa[TipoPessoa.JURIDICA]) data.cnpj = form.cnpj;
    if (form.person_type === TipoPessoa[TipoPessoa.FISICA]) data.cpf = form.cnpj;
    if (form.person_type === TipoPessoa[TipoPessoa.JURIDICA]) data.company_name = form.razaoSocial;
    if (form.person_type === TipoPessoa[TipoPessoa.FISICA]) data.name = form.razaoSocial;
    data.address = form.address;
    data.cep = form.cep;
    data.city = form.city;
    data.state = form.state;
    data.telephone = form.telephone;
    if (form.person_type === TipoPessoa[TipoPessoa.JURIDICA]) data.resp_name = form.resp_name;
    if (form.person_type === TipoPessoa[TipoPessoa.JURIDICA]) data.resp_cpf = form.resp_cpf;
    data.bank = form.bank;
    data.bank_ag = form.bank_ag;
    data.account_type = form.account_type;
    if (form.bank === "Caixa Econômica") {
      // Formatar bank_cc e account_type para se adequar aos formatos requeridos pela Caixa Econômica
      data.account_type = (form.account_type === "001 – Conta Corrente de Pessoa Física") || (form.account_type === "002 – Conta Simples de Pessoa Física")
      || (form.account_type === "003 – Conta Corrente de Pessoa Jurídica") ? "Corrente" : "Poupança";
      if (form.account_type === "001 – Conta Corrente de Pessoa Física") data.bank_cc = "001" + form.bank_cc;
      if (form.account_type === "002 – Conta Simples de Pessoa Física") data.bank_cc = "002" + form.bank_cc;
      if (form.account_type === "003 – Conta Corrente de Pessoa Jurídica") data.bank_cc = "003" + form.bank_cc;
      if (form.account_type === "013 – Poupança de Pessoa Física") data.bank_cc = "013" + form.bank_cc;
      if (form.account_type === "1288 – Poupança de Pessoa Física (para contas de novo formato)") data.bank_cc = "1288" + form.bank_cc;
      if (form.account_type === "022 – Poupança de Pessoa Jurídica") data.bank_cc = "022" + form.bank_cc;
    } else {
      data.account_type = form.account_type === "Conta Corrente" ? "Corrente" : "Poupança";
      data.bank_cc = form.bank_cc;
    }

    data.bank_cc = data.bank_cc.toUpperCase();

    return data;
  }

  buildAntecipacaoData() {
    const form: { active, type, option } = this.subcontaFormComponent.formAntecipacao.value;
    return {
      auto_advance: form.active,
      auto_advance_type: form.type,
      auto_advance_option: form.option
    };
  }

  salvar() {
    // Identifica se é cadastro de conta espelho, a partir da aba selecionada
    const isSubcontaEspelho: boolean = this.matTabGroup.selectedIndex !== 0;
    if (!isSubcontaEspelho && isNotNullOrUndefined(this.empresa.pagamentoOnline) &&
      isNotNullOrUndefined(this.empresa.pagamentoOnline.mirrorPagamentoOnline) &&
      isNotNullOrUndefined(this.empresa.pagamentoOnline.mirrorPagamentoOnline.accountId)) {
      // Impede que a subconta ATUAL seja atualizada quando uma subconta ESPELHO esteja em processo de criação
      this.dialog.messageDialog()
        .title("Atenção")
        .message("Não é possível salvar as configurações enquanto uma conta espelho estiver sendo criada!")
        .show();

    } else if (this.subcontaFormComponent.formSaque.get("active").value && this.subcontaFormComponent.formSaque.get("valorMinimo").value < 5) {
      // Impede que a configuração seja salva caso o valour minimo do saque seja abaixo de R$ 5,00
      this.dialog.messageDialog()
        .title("Atenção")
        .message("O valor mínimo do saque não pode ser abaixo de R$ 5,00")
        .show();

    } else {

      this.formMudou = false;
      this.loading.showTopBar();
      this.saving = true;

      // Se for cadastro de conta espelho, formSaque será null
      const formSaque = !isSubcontaEspelho ? this.subcontaFormComponent.formSaque.value : null;

      // Chamar função para cadastrar a subconta
      this.customersIuguService
        .cadastrarSubcontaIugu(this.auth.currentEmpresa.cnpj, this.buildData(), formSaque, this.buildAntecipacaoData(), isSubcontaEspelho)
        .subscribe(value => {
          console.log(value);
          if (!value || value.success) {
            this.goBack();
          }
          this.loading.hideTopBar();
          this.saving = false;
          this.formMudou = false;
        }, error => {
          this.dialog.messageDialog().message(error).show();
          this.loading.hideTopBar();
          this.saving = false;
        });
    }
  }

  // Abre o dialog de confirmação, para iniciar o cadastro de uma conta espelho
  criarSubcontaEspelho() {
    this.dialog.confirmDialog()
      .title("Criar Conta Espelho?")
      .message("A conta espelho será utilizada para substituir a conta atual. Deseja continuar?")
      .show().subscribe(accept => {
      if (accept) {
        // Ativar a aba de conta espelho, e mudar para essa aba automaticamente
        this.contaEspelhoActive = true;
        this.tabIndex = 1;
      }
    });
  }

  // Busca os dados da subconta espelho (caso ela exista)
  getSubcontaEspelho(): PagamentoOnline {
    if (isNotNullOrUndefined(this.empresa.pagamentoOnline)
      && isNotNullOrUndefined(this.empresa.pagamentoOnline.mirrorPagamentoOnline)
      && isNotNullOrUndefined(this.empresa.pagamentoOnline.mirrorPagamentoOnline.accountId)) {
      return this.empresa.pagamentoOnline.mirrorPagamentoOnline;
    } else {
      return null;
    }
  }

  // Valida se o botão de criação de subconta espelho deve ser exibído
  // (uma subconta que não esteja com o status ACCEPTED, porem seu verificationType seja BANK_VERIFICATION, não impede que o botão seja exibído).
  showEspelhoButton(): boolean {
    return this.contaEspelhoPermission && !this.contaEspelhoActive
      && (this.empresa.pagamentoOnline.status === PgtOnlineStatus[PgtOnlineStatus.ACCEPTED]
        || (this.empresa.pagamentoOnline.status !== PgtOnlineStatus[PgtOnlineStatus.ACCEPTED]
          && this.empresa.pagamentoOnline.verificationType === PagtOnlineVerificationType[PagtOnlineVerificationType.BANK_VERIFICATION]));
  }

  // Ao mudar de aba, reseta o formMudou e o formValid
  onTabChange() {
    this.formValid = false;
    this.formMudou = false;
  }

  // Método que recebe o evento que verifica se houve mudanças no formulário
  changedForm(event: boolean) {
    if (event) {
      this.formMudou = true;
    }
  }

  // Método que recebe o evento que verifica se o formulário está válido
  getFormValidation(event: boolean) {
    this.formValid = event;
  }

  podeMudarRota() {
    if (this.formMudou === true) {
      return confirm(
        "Os dados ainda não foram salvos. Deseja sair mesmo assim?"
      );
    }
    return true;
  }

}
