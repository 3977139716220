import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { Empresa } from "../../../models/empresa";
import { FormControl } from "@angular/forms";
import { Pedido } from "../../../models/pedido.model";
import { PedidosService } from "../../../services/pedidos.service";
import { EmpresaService } from "../../empresa/empresa.service";
import { MatDialog } from "@angular/material/dialog";
import { DialogService } from "../../../services/dialog/dialog.service";
import { LoadingService } from "../../../services/loading/loading.service";
import { SnackService } from "../../../services/snack/snack.service";
import { ActivatedRoute, Router } from "@angular/router";
import { $query } from "../../../services/firebase/criteria/query";
import { Criterion } from "../../../services/firebase/criteria/criterion";
import { isNotNullOrUndefined } from "../../../utils/commons";
import { finalize, map, tap } from "rxjs/operators";
import { PageService } from "../../../services/page.service";
import * as moment from "moment";
import { AppUser } from "../../../models/appUser";
import { UsersService } from "../../users/users.service";
import { AuthService } from "../../../modules/login/auth.service";
import { NumberUtils } from "../../../utils/number-utils";
import { StatusPedido } from "../../../models/status-pedido.enum";

@Component({
  selector: "app-relatorio-comissoes-list",
  templateUrl: "./relatorio-comissoes-list.component.html",
  styleUrls: ["./relatorio-comissoes-list.component.scss"],
})
export class RelatorioComissoesListComponent
  extends PageService<Pedido>
  implements OnInit, OnDestroy {
  empresas$: Observable<Empresa[]>;
  consultores$: Observable<AppUser[]>;

  readonly consultorTodos: string = "Todos";
  readonly todasEmpresas: string = "Todas";

  maxDate = moment(new Date("2020-07-31T23:59:59-03:00"));

  date = new Date();
  dataInicialControl = new FormControl({
    value: new Date(this.maxDate.year(), this.maxDate.month(), 1),
    disabled: true,
  });
  dataFinalControl = new FormControl({
    value: new Date(this.maxDate.year(), this.maxDate.month(), 31),
    disabled: true,
  });
  consultorControl = new FormControl(this.consultorTodos);
  empresaControl = new FormControl(this.todasEmpresas);
  analiticoControl = new FormControl(false);
  analitico = false;

  // Lista de Pedidos
  pedidos: Pedido[];

  // Totalização dos pedidos
  valorTotalEmpresa: number = 0;
  valorTotalConsultores: number = 0;

  // Subscription de pedidos
  pedidosSubscription: Subscription;
  userSubscription: Subscription;

  loggedUser: AppUser;

  constructor(
    service: PedidosService,
    public empresaService: EmpresaService,
    dialog: MatDialog,
    dialogService: DialogService,
    loadingService: LoadingService,
    snack: SnackService,
    cdRef: ChangeDetectorRef,
    route: ActivatedRoute,
    router: Router,
    public userService: UsersService,
    public authService: AuthService
  ) {
    super(
      service,
      dialog,
      dialogService,
      loadingService,
      snack,
      cdRef,
      route,
      router,
      "/home/relatorio-comissoes/"
    );
    this.empresas$ = empresaService.col$();
    this.consultores$ = this.userService.getAdministradoresAndConsultores();
  }

  onChangeConsultor(data: any) {
    if (isNotNullOrUndefined(data)) {
      this.empresas$ = this.empresaService.col$(
        $query(new Criterion("consultor.id", "==", data.id))
      );
    } else {
      this.empresas$ = this.empresaService.col$();
    }
    this.empresaControl.patchValue(this.todasEmpresas);
  }

  onChangeEmpresa(data: any) {
    if (data !== this.todasEmpresas) {
      this.consultorControl.patchValue(data.consultor);
    }
  }

  ngOnInit() {
    this.userSubscription = this.authService.currentUser
      .pipe(
        tap((user) => {
          this.loggedUser = user;
        })
      )
      .subscribe(() => {
        if (this.loggedUser.isConsultor()) {
          this.buscarPedidos();
          this.consultorControl.patchValue(this.loggedUser);
          this.consultorControl.disable();
        }
      });
  }

  ngOnDestroy() {
    if (this.pedidosSubscription) {
      this.pedidosSubscription.unsubscribe();
    }

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  compareConsultor(c1: AppUser, c2: AppUser): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  newItem(): Pedido {
    return null;
  }

  buscarPedidos() {
    this.loading(true);
    // Filtros
    const dataInicial = moment(this.dataInicialControl.value)
      .startOf("day")
      .toDate();
    const dataFinal = moment(this.dataFinalControl.value).endOf("day").toDate();
    let consultor = this.consultorControl.value;
    let empresa = this.empresaControl.value;
    this.analitico = this.analiticoControl.value;

    consultor = consultor === this.consultorTodos ? undefined : consultor;
    empresa = empresa === this.todasEmpresas ? undefined : empresa;

    // Montar o filtro
    const filters = $query(
      new Criterion("data", ">=", dataInicial),
      new Criterion("data", "<=", dataFinal),
      new Criterion("status", "==", StatusPedido[StatusPedido.CONCLUIDO])
    );
    if (isNotNullOrUndefined(consultor)) {
      filters.add(new Criterion("empresa.consultor.id", "==", consultor.id));
    }

    if (isNotNullOrUndefined(empresa)) {
      filters.add(new Criterion("empresa.id", "==", empresa.id));
    } else {
      if (this.loggedUser.isConsultorOrAssistente()) {
        filters.add(
          new Criterion(
            "empresa.consultor.id",
            "==",
            this.loggedUser.isConsultor()
              ? this.loggedUser.id
              : this.loggedUser.consultorId
          )
        );
      }
    }

    // Limpar a lista de pedidos
    this.pedidos = [];

    // Zerar a totalização
    this.valorTotalEmpresa = 0;
    this.valorTotalConsultores = 0;

    // Buscar os pedidos
    this.pedidosSubscription = (this.service as PedidosService)
      .col$(filters)
      .take(1)
      .pipe(
        map((pedidos) => {
          // Filtrar apenas pedidos com consultor informado
          return pedidos.filter((pedido) => {
            return isNotNullOrUndefined(pedido.empresa.consultor);
          });
        }),
        tap((pedidos) => {
          pedidos.forEach((pedido: Pedido) => {
            // Verificar se usa o serviço da Central Pede Que Chega
            this.valorTotalEmpresa += pedido.getValorComissaoEmpresa();
            this.valorTotalConsultores += pedido.getValorComissaoConsultor();
          });
        }),
        map((pedidos) => {
          if (!this.analitico) {
            const pedidosByEmpresa: Pedido[] = [];
            pedidos.forEach((pedido) => {
              if (
                !pedidosByEmpresa.some(
                  (val) => val.empresa.id === pedido.empresa.id
                )
              ) {
                pedido.numero = 1;
                pedidosByEmpresa.push(pedido);
              } else {
                pedidosByEmpresa.forEach((pedidoByEmpresa) => {
                  if (pedidoByEmpresa.empresa.id === pedido.empresa.id) {
                    pedidoByEmpresa.numero += 1;
                    pedidoByEmpresa.subtotal += NumberUtils.round(
                      pedido.subtotal,
                      2
                    );
                    pedidoByEmpresa.pontos += NumberUtils.round(
                      pedido.pontos,
                      2
                    );
                  }
                });
              }
            });
            pedidos = pedidosByEmpresa;
          }
          return pedidos;
        }),
        finalize(() => this.loading(false))
      )
      .subscribe(
        (values) => {
          this.pedidos = values;
        },
        (err) => {
          this.dialogService
            .messageDialog()
            .title("Atenção")
            .message(err)
            .show();
        }
      );
  }
}
