import {Injectable} from "@angular/core";
import {Avaliacao, Resposta} from "../../models/avaliacao.model";
import {AuthService} from "../../modules/login/auth.service";
import {AngularFirestore} from "@angular/fire/firestore";
import {OrderBy} from "../../services/firebase/criteria/order-by";
import {OrderByDesc} from "../../services/firebase/criteria/order-by-desc";
import {Observable} from "rxjs/Rx";
import {RepositoryByEmpresa} from "../../repository/repository-by-empresa";
import {AngularFireFunctions} from "@angular/fire/functions";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class AvaliacaoService extends RepositoryByEmpresa<Avaliacao> {

  constructor(public db: AngularFirestore,
              public auth: AuthService,
              public fns: AngularFireFunctions) {
    super(db, auth, "avaliacoes");
  }

  deserialize(avaliacao): Avaliacao {
    return new Avaliacao(avaliacao);
  }

  orderBy(): OrderBy {
    return new OrderByDesc("data");
  }

  salvarResposta(respostaStr: string, avaliacaoId: string): Observable<Resposta> {
    return this.fns.httpsCallable("call-avaliacoes-addOrUpdateResponse")({avaliacaoId: avaliacaoId, resposta: respostaStr})
      .pipe(
        map(resposta => new Resposta(resposta))
      );
  }
}
