import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {PageService} from "../../../services/page.service";
import {Cozinha} from "../../../models/cozinha.model";
import {IFormCanDeactivate} from "../../../guards/iform-canDeactivate";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CozinhasService} from "../cozinhas.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SelectImpressoraDialogComponent} from "../../select-impressora-dialog/select-impressora-dialog.component";

@Component({
  selector: "app-cozinhas-fields",
  templateUrl: "./cozinhas-fields.component.html",
  styleUrls: ["./cozinhas-fields.component.scss"]
})
export class CozinhasFieldsComponent extends PageService<Cozinha> implements OnInit, IFormCanDeactivate {
  form: FormGroup;

  // Opções de formato de impressão
  readonly default: string = "Padrão";
  readonly pdf: string = "PDF";

  // Opções de dimensões da folha
  readonly p80: string = "80mm";
  readonly p58: string = "58mm";
  readonly a4: string = "A4";

  // Booleano que verifica o status da atualização de um formulário
  formMudou: boolean = null;

  constructor(service: CozinhasService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              formBuilder: FormBuilder) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/cozinhas");

    this.form = formBuilder.group({
      nome: ["", [Validators.required, Validators.minLength(3)]],
      printFormat: [this.default],
      pageDimension: [this.p80],
      printer: [""],
      inactive: [false]
    });

    this.form.valueChanges.subscribe(value => {
      Object.keys(value).forEach(key => this.item[key] = value[key]);
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.itemSubject.asObservable()
      .subscribe(item => {
        this.form.patchValue(item);
      });

    /**
     * Inicializa método que verifica se ocorreram mudanças no conteúdo do formulário,
     * para decidir o comportamento do método podeMudarRota (canDeactivate)
     */
    this.onChanges();
  }

  newItem(): Cozinha {
    return new Cozinha();
  }

  searchImpressoras() {
    SelectImpressoraDialogComponent.showDialog(this.dialog)
      .subscribe(value => {
        if (value) {
          this.form.get("printer").setValue(value);
        }
      });
  }

  onChanges() {
    let mudanca = 0;
    this.form.valueChanges.subscribe(() => {
      if (mudanca === 0) {
        this.formMudou = false;
        mudanca++;
      } else {
        this.formMudou = true;
      }
    });
  }

  // Caso o formulário foi modificado
  podeMudarRota(): boolean {
    if (this.formMudou === true) {
      return confirm("Os dados ainda não foram salvos. Deseja sair mesmo assim?");
    }
    return true;
  }

}
