import {of} from "rxjs/observable/of";
import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {Observable} from "rxjs";
import {map, mergeMap} from "rxjs/operators";
import {Delivery} from "../../models/delivery.model";
import {AuthService} from "../../modules/login/auth.service";
import {EmpresaCriterion, RepositoryByEmpresa} from "../../repository/repository-by-empresa";
import {isNotNullOrUndefined} from "../../utils/commons";
import {Empresa} from "../../models/empresa";
import {DeliveryBairro} from "../../models/delivery-bairro.model";
import {BairroService} from "../bairro/bairro.service";
import {Bairro} from "../../models/bairro.model";
import {$query} from "../../services/firebase/criteria/query";
import {Criterion} from "../../services/firebase/criteria/criterion";
import {Endereco} from "../../models/endereco";
import {isNullOrUndefined} from "util";

@Injectable()
export class DeliveryService extends RepositoryByEmpresa<Delivery> {

  constructor(db: AngularFirestore, auth: AuthService, public bairrosService: BairroService) {
    super(db, auth, "delivery", true);
  }

  getDelivery(): Observable<Delivery> {
    return this.col$().pipe(
      map((value: Delivery[]) => {
        return new Delivery(isNotNullOrUndefined(value) && value.length > 0 ? value[0] : undefined);
      }));
  }

  // Recebe uma lista de bairros e
  loadBairrosAndMerge(bairros: any[]): Observable<DeliveryBairro[]> {
    bairros = DeliveryBairro.makeNewList(bairros);
    return this.getBairrosCidade().pipe(map((bairrosCidade: Bairro[]) => {
      bairrosCidade.map((bairroCidade: Bairro) => {
        if (!bairros.some(deliveryBairro => deliveryBairro.bairro.id === bairroCidade.id)) {
          bairros.push(DeliveryBairro.makeNew({
            bairro: bairroCidade,
            //bloqueado: isNotNullOrUndefined(bairroCidade.bloqueado)? bairroCidade.bloqueado : false,
            //valorEntrega: isNotNullOrUndefined(bairroCidade.valorEntrega)? bairroCidade.valorEntrega : null
          }));
        }
      });
      bairros = this.sortBairros(bairros);
      return bairros;
    }));
  }

  // Ordenar os bairros colocando os bairros com configurações na frente
  sortBairros(bairros: DeliveryBairro[]): DeliveryBairro[] {
    const touchedBairros: DeliveryBairro[] = bairros.filter(value => isNotNullOrUndefined(value.valorEntrega) || value.bloqueado);
    const untouchedBairros: DeliveryBairro[] = bairros.filter(value => isNullOrUndefined(value.valorEntrega) && !value.bloqueado);

    touchedBairros.sort((a,b) => a.bairro.nome.localeCompare(b.bairro.nome));
    untouchedBairros.sort((a,b) => a.bairro.nome.localeCompare(b.bairro.nome));

    bairros = [...touchedBairros, ...untouchedBairros];

    return bairros;
  }

  getBairrosDelivery(): Observable<DeliveryBairro[]> {
    return this.getDelivery().pipe(mergeMap(delivery => {
      return of(delivery.bairros);
    }));
  }

  getBairrosCidade(): Observable<Bairro[]> {
    if (this.enderecoEmpresa) {
      return this.bairrosService.col$($query(
        new Criterion("cidade.nome", "==", this.enderecoEmpresa.cidade),
        new Criterion("cidade.uf", "==", this.enderecoEmpresa.estado)
      ));
    }
    return of([]);
  }

  get empresa(): Empresa {
    return this.auth.currentEmpresa;
  }

  get enderecoEmpresa(): Endereco {
    try {
      return this.empresa.localizacao.endereco;
    } catch (error) {
      return undefined;
    }
  }

  deliveryIsEmpty(empresa?: Empresa): Observable<boolean> {
    return (empresa ? of(empresa) : this.auth.currentEmpresaObservable())
      .pipe(mergeMap(_empresa => {
        if (isNullOrUndefined(_empresa) || isNullOrUndefined(_empresa.ref)) {
          return of(false);
        }
        return this.col$($query(new EmpresaCriterion(_empresa)))
          .pipe(map((data) => !(data && data.length > 0)));
      }));
  }
}
