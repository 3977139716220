import {Role} from "../../models/perfil-acesso.model";

/**
 * Representa cada linha de permissão dentro de cada Tab
 */
export class Authority {
  constructor(public role: Role, public descricao: string) {
  }
}

/**
 * Permissões da empresa
 * @returns {Authority[]}
 */
export function getEmpresaAuthorities(): Authority[] {
  return [
    new Authority(Role.EmpresaCreate, "Cadastrar nova empresa"),
    new Authority(Role.EmpresaRead, "Visualizar dados da empresa"),
    new Authority(Role.EmpresaUpdate, "Atualizar dados da empresa"),
    new Authority(Role.EmpresaDelete, "Excluir empresa"),
    new Authority(Role.EmpresaBlock, "Bloquear empresa"),
  ];
}

/**
 * Permissões de usuário
 * @returns {Authority[]}
 */
export function getUsuarioAuthorities(): Authority[] {
  return [
    new Authority(Role.UsuarioCreate, "Cadastrar novo usuário"),
    new Authority(Role.UsuarioRead, "Visualizar dados do usuário"),
    new Authority(Role.UsuarioUpdate, "Atualizar dados do usuário"),
    new Authority(Role.UsuarioDelete, "Excluir usuário"),
    new Authority(Role.UsuarioSelectEmpresa, "Selecionar Empresa"),
    new Authority(Role.UsuarioUpdateOwnPerfilAcesso, "Atualizar o próprio perfil de acesso"),
  ];
}

/**
 * Permissões de perfil de acesso
 * @returns {Authority[]}
 */
export function getPerfilAcessoAuthorities(): Authority[] {
  return [
    new Authority(Role.PerfilAcessoCreate, "Cadastrar novo perfil"),
    new Authority(Role.PerfilAcessoRead, "Visualizar dados do perfil"),
    new Authority(Role.PerfilAcessoUpdate, "Atualizar dados do perfil"),
    new Authority(Role.PerfilAcessoDelete, "Excluir perfil"),
  ];
}

/**
 * Permissões de bairros
 * @returns {Authority[]}
 */
export function getBairrosAuthorities(): Authority[] {
  return [
    new Authority(Role.BairroCreate, "Cadastrar novo bairro"),
    new Authority(Role.BairroRead, "Visualizar dados do bairro"),
    new Authority(Role.BairroUpdate, "Atualizar dados do bairro"),
    new Authority(Role.BairroDelete, "Excluir bairro"),
  ];
}

/**
 * Permissões de formas de pagamento
 * @returns {Authority[]}
 */
export function getFormasPagamentoAuthorities(isLojista: boolean, haveCompany: boolean): Authority[] {
  if (isLojista) {
    return [
      new Authority(Role.FormaPagamentoRead, "Visualizar dados da forma de pagamento"),
      new Authority(Role.FormaPagamentoUpdate, "Atualizar dados da forma de pagamento"),
    ];
  } else {
    if (haveCompany) {
      return [
        new Authority(Role.FormaPagamentoCreate, "Cadastrar nova forma de pagamento"),
        new Authority(Role.FormaPagamentoRead, "Visualizar dados da forma de pagamento"),
        new Authority(Role.FormaPagamentoUpdate, "Atualizar dados da forma de pagamento"),
        new Authority(Role.FormaPagamentoDelete, "Excluir forma de pagamento")
      ];
    } else {
      return [
        new Authority(Role.FormaPagamentoCreate, "Cadastrar nova forma de pagamento"),
        new Authority(Role.FormaPagamentoRead, "Visualizar dados da forma de pagamento"),
        new Authority(Role.FormaPagamentoUpdate, "Atualizar dados da forma de pagamento"),
        new Authority(Role.FormaPagamentoDelete, "Excluir forma de pagamento"),
        new Authority(Role.FormaPagamentoOnlineUpdate, "Editar configuração de pagamento online"),
        new Authority(Role.FormaPagamentoSubcontaEspelho, "Criar subconta espelho"),
      ];
    }
  }
}

/**
 * Permissões de delivery
 * @returns {Authority[]}
 */
export function getDeliveryAuthorities(): Authority[] {
  return [
    new Authority(Role.DeliveryCreate, "Configurar o delivery"),
    new Authority(Role.DeliveryRead, "Visualizar os dados do delivery"),
    new Authority(Role.DeliveryUpdate, "Atualizar os dados do delivery"),
    new Authority(Role.DeliveryImport, "Importar configurações de bairros")
  ];
}

/**
 * Permissões de pedidos
 * @returns {Authority[]}
 */
export function getPedidosAuthorities(isLojista: boolean, isEmpresaSelecionada: boolean): Authority[] {
  if (isLojista || isEmpresaSelecionada) {
    return [new Authority(Role.PedidoRead, "Visualizar dados de pedidos")];
  } else {
    return [
      new Authority(Role.PedidoRead, "Visualizar dados de pedidos"),
      new Authority(Role.PedidoCancel, "Cancelar pedidos"),
      new Authority(Role.PedidoRevert, "Reverter cancelamento ou rejeição"),
    ];
  }
}

/**
 * Permissões do balcão
 * @returns {Authority[]}
 */
export function getGerenciadorPedidosAuthorities(): Authority[] {
  return [
    new Authority(Role.GerenciadorPedidosRead, "Visualizar dados do gerenciador")
  ];
}

/**
 * Permissões de produtos
 * @returns {Authority[]}
 */
export function getProdutosAuthorities(): Authority[] {
  return [
    new Authority(Role.ProdutoCreate, "Cadastrar novo produto"),
    new Authority(Role.ProdutoRead, "Visualizar dados do produto"),
    new Authority(Role.ProdutoUpdate, "Atualizar dados do produto"),
    new Authority(Role.ProdutoDelete, "Excluir produto"),
  ];
}

/**
 * Permissões de categorias de produto
 * @returns {Authority[]}
 */
export function getCategoriasDeProdutoAuthorities(): Authority[] {
  return [
    new Authority(Role.CategoriaProdutoCreate, "Cadastrar nova categoria de produto"),
    new Authority(Role.CategoriaProdutoRead, "Visualizar dados da categoria de produto"),
    new Authority(Role.CategoriaProdutoUpdate, "Atualizar dados da categoria de produto"),
    new Authority(Role.CategoriaProdutoDelete, "Excluir categoria de produto"),
  ];
}

/**
 * Permissões de complementos de produto
 * @returns {Authority[]}
 */
export function getComplementosDeProdutoAuthorities(): Authority[] {
  return [
    new Authority(Role.ComplementoProdutoCreate, "Cadastrar novo complemento de produto"),
    new Authority(Role.ComplementoProdutoRead, "Visualizar dados do complemento de produto"),
    new Authority(Role.ComplementoProdutoUpdate, "Atualizar dados do complemento de produto"),
    new Authority(Role.ComplementoProdutoDelete, "Excluir complemento de produto"),
  ];
}

/**
 * Permissões de cozinhas
 * @returns {Authority[]}
 */
export function getCozinhasAuthorities(): Authority[] {
  return [
    new Authority(Role.CozinhaCreate, "Cadastrar nova cozinha"),
    new Authority(Role.CozinhaRead, "Visualizar dados da cozinha"),
    new Authority(Role.CozinhaUpdate, "Atualizar dados da cozinha"),
    new Authority(Role.CozinhaDelete, "Excluir cozinha"),
  ];
}

/**
 * Permissões de avaliações
 * @returns {Authority[]}
 */
export function getAvaliacoesAuthorities(): Authority[] {
  return [
    new Authority(Role.AvaliacaoRead, "Visualizar as avaliações dos usuários"),
    new Authority(Role.AvaliacaoUpdate, "Responder aos comentários das avaliações"),
  ];
}

/**
 * Permissões de notificacoes
 * @returns {Authority[]}
 */
export function getNotificacoesAuthorities(): Authority[] {
  return [
    new Authority(Role.NotificacaoCreate, "Cadastrar nova notificação"),
    new Authority(Role.NotificacaoRead, "Visualizar dados da notificação"),
    new Authority(Role.NotificacaoUpdate, "Atualizar dados da notificação"),
    new Authority(Role.NotificacaoDelete, "Excluir notificação"),
  ];
}

/**
 * Permissôes de Entregadores
 * @returns {Authority[]}
 */
export function getEntregadoresAuthorities(): Authority[] {
  return [
    new Authority(Role.EntregadoresCreate, "Cadastrar novo entregador"),
    new Authority(Role.EntregadoresRead, "Visualizar dados dos entregadores"),
    new Authority(Role.EntregadoresUpdate, "Atualizar dados dos entregadores"),
    new Authority(Role.EntregadoresDelete, "Excluir entregadores"),
  ];
}

/**
 * Permissôes da Central de Entregas
 * @returns {Authority[]}
 */
export function getCentralEntregaAuthorities(): Authority[] {
  return [
    new Authority(Role.CentralEntregasRead, "Visualizar dados da Central de entregas"),
  ];
}

/**
 * Permissôes da Central de Entregas
 * @returns {Authority[]}
 */
export function getEntregasAuthorities(): Authority[] {
  return [
    new Authority(Role.EntregasRead, "Visualizar dados das entregas"),
    new Authority(Role.EntregasUpdate, "Atualizar dados das entregas"),
    new Authority(Role.EntregasPagar, "Pagar entregas")
  ];
}

/**
 * Permissôes do Monitoramento de pedidos
 * @returns {Authority[]}
 */
export function getMonitorAuthorities(): Authority[] {
  return [
    new Authority(Role.MonitorPedidosRead, "Visualizar dados dos pedidos"),
  ];
}

/**
 * Permissôes do Relatorio de Pedidos
 * @returns {Authority[]}
 */
export function getRelatorioPedidosAuthorities(): Authority[] {
  return [
    new Authority(Role.RelatorioPedidosRead, "Visualizar relatórios de pedidos"),
  ];
}

/**
 * Permissões do Relatorio de Comissões
 * @returns {Authority[]}
 */
export function getRelatorioComissoesAuthorities(): Authority[] {
  return [
    new Authority(Role.RelatorioComissoesRead, "Visualizar relatórios de comissões"),
  ];
}

/**
 * Permissões das Comissões de Consultores *Nova
 * @returns {Authority[]}
 */
export function getComissoesConsultoresAuthorities(): Authority[] {
  return [
    new Authority(Role.ComissoesConsultoresRead, "Visualizar comissões de consultores"),
  ];
}

/**
 * Permissões do Financeiro
 * @returns {Authority[]}
 */
export function getFinanceiroAuthorities(): Authority[] {
  return [
    new Authority(Role.FinanceiroRead, "Visualizar dados do financeiro"),
    new Authority(Role.FinanceiroGenerateInvoice, "Gerar uma nova fatura que foi expirada"),
    new Authority(Role.FinanceiroBaixa, "Dar baixas em faturas"),
  ];
}

export function getFaturasAuthorities(isLojista: boolean, isEmpresaSelecionada: boolean): Authority[] {
  if (isLojista || isEmpresaSelecionada) {
    return [new Authority(Role.FaturasRead, "Visualizar as faturas da empresa")];
  } else {
    return [
      new Authority(Role.FaturasRead, "Visualizar as faturas da empresa"),
      new Authority(Role.FaturasGenerateParcial, "Gerar faturas parciais"),
      new Authority(Role.GenerateNfse, "Gerar NFS-e")
    ];
  }
}

export function getExtratoOnlineAuthorities(): Authority[] {
  return [
    new Authority(Role.ExtratoRead, "Visualizar o extrato de pagamentos online da empresa"),
    new Authority(Role.SacarSaldoDisponivel, "Sacar saldo disponível")
  ];
}

export function getAbatimentosAuthorities(): Authority[] {
  return [
    new Authority(Role.AbatimentosCreate, "Cadastrar um novo abatimento"),
    new Authority(Role.AbatimentosRead, "Visualizar os abatimentos"),
    new Authority(Role.AbatimentosUpdate, "Atualizar os abatimentos"),
    new Authority(Role.AbatimentosDelete, "Excluir abatimentos"),
  ];
}

export function getPromocaoAuthorities(): Authority[] {
  return [
    new Authority(Role.PromocaoCreate, "Cadastrar uma nova promoção"),
    new Authority(Role.PromocaoRead, "Visualizar as promoções"),
    new Authority(Role.PromocaoUpdate, "Atualizar a promoção"),
    new Authority(Role.PromocaoDelete, "Excluir promoção"),
  ];
}

export function getClientesAuthorities(): Authority[] {
  return [
    new Authority(Role.ClientesRead, "Visualizar os clientes"),
    new Authority(Role.ClientesUpdate, "Editar Cliente"),
    new Authority(Role.ClientesBloquearCliente, "Bloquear o acesso de determinado cliente"),
    new Authority(Role.ClientesAdicionarPomsCliente, "Adicionar PoMs a determinado cliente"),
  ];
}
