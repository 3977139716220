import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PageService} from "../../../services/page.service";
import {CategoriaProduto} from "../../../models/categoria.model";
import {IFormCanDeactivate} from "../../../guards/iform-canDeactivate";
import {CategoriaProdutoService} from "../categoria-produto.service";
import { MatDialog } from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: "app-categoria-produto-fields",
  templateUrl: "./categoria-produto-fields.component.html",
  styleUrls: ["./categoria-produto-fields.component.scss"]
})
export class CategoriaProdutoFieldsComponent extends PageService<CategoriaProduto> implements OnInit, IFormCanDeactivate {
  form: FormGroup;

  // Booleano que verifica o status da atualização de um formulário
  formMudou: boolean = null;

  constructor(service: CategoriaProdutoService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              public formBuilder: FormBuilder) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/categorias-produto/");

    this.form = this.formBuilder.group({
      nome: ["", Validators.compose([Validators.required, Validators.minLength(4)])],
      descricao: [""],
      inactive: [false]
    });

    this.form.valueChanges.subscribe(value => {
      Object.keys(value).forEach(key => this.item[key] = value[key]);
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.itemSubject.asObservable()
      .subscribe(value => {
        this.form.patchValue(value);
      });

    /**
     * Inicializa método que verifica se ocorreram mudanças no conteúdo do formulário,
     * para decidir o comportamento do método podeMudarRota (canDeactivate)
     */
    this.onChanges();
  }

  protected newItem(): CategoriaProduto {
    return new CategoriaProduto();
  }

  onChanges() {
    let mudanca = 0;
    this.form.valueChanges.subscribe(() => {
      if( mudanca === 0) {
        this.formMudou = false;
        mudanca++;
      } else {
        this.formMudou = true;
      }
    });
  }

  // Caso o formulário foi modificado
  podeMudarRota(): boolean {
    if(this.formMudou === true) {
      return confirm("Os dados ainda não foram salvos. Deseja sair mesmo assim?");
    }
    return true;
  }

}
