import {Injectable} from "@angular/core";
import * as Excel from "exceljs";
import * as fs from "file-saver";
import {EmpresaService} from "../components/empresa/empresa.service";
import {isNotNullOrUndefined} from "../utils/commons";
import {Style} from "exceljs";
import {DeliveryBairro} from "../models/delivery-bairro.model";
import {StringUtils} from "../utils/string-utils";
import {Cidade} from "../models/cidade.model";
import {Bairro} from "../models/bairro.model";
import {BairroService} from "../components/bairro/bairro.service";
import {DialogService} from "./dialog/dialog.service";
import {AuthService} from "../modules/login/auth.service";

@Injectable()
export class ImportarExportarBairros {

  constructor(public empresaService: EmpresaService,
              public bairroService: BairroService,
              public dialogService: DialogService,
              public auth: AuthService) {}

  // Exportar lista de bairros em formato .xlsx
  exportBairros(bairros: DeliveryBairro[]) {

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("Configuração de Bairros");

    // Criar header das colunas
    worksheet.columns = [
      { header: "Bairros", key: "bairros", width: 30 },
      { header: "Valor", key: "valor", width: 10 },
      { header: "Bloqueado", key: "bloqueado", width: 10 }
    ];

    // Estilizar headers
    const headerStyles: Partial<Style> = {
      font: {bold: true, name: "Helvetica"},
      fill: {type: "pattern", pattern: "darkVertical", fgColor: {argb: "949494"}},
      border: {bottom: {style: "thick"}, top: {style: "thick"}, left: {style: "thick"}, right: {style: "thick"}},
      alignment: {horizontal: "center"}
    };

    // Estilizar colunas
    worksheet.getCell("A1").style = headerStyles;
    worksheet.getCell("B1").style = headerStyles;
    worksheet.getCell("C1").style = headerStyles;
    worksheet.getColumn("bairros").alignment = {wrapText: true};
    worksheet.getColumn("valor").alignment = {horizontal: "center"};
    worksheet.getColumn("bloqueado").alignment = {horizontal: "center"};

    // Preencher arquivo com os valores dos bairros
    for (const bairro of bairros) {
      worksheet.addRows([{
        bairros: bairro.bairro.nome,
        valor: bairro.valorEntrega,
        bloqueado: bairro.bloqueado ? "sim" : "não"
      }]);
    }

    // Escrever e dalvar arquivo
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      fs.saveAs(blob, "delivery_" + (this.auth.currentEmpresa.nomeFantasia.toUpperCase()) + ".xlsx");
    }).catch((err) => {
      console.log("ERROR: ", err);
    });

  }


  // Importar lista de bairros a partir de um arquivo .xlsx
  async importBairros(file: any, deliveryBairros: DeliveryBairro[]): Promise<any> {

    const workbook = new Excel.Workbook();

    const bairros: BairroAux[] = [];

    // Lista de bairros que serão cadastrados no sistema
    const newBairros: DeliveryBairro[] = [];

    // Variável que busca a cidade para o caso de criação de um novo DeliveryBairro
    const cidadeBairros: Cidade = deliveryBairros[0].bairro.cidade;

    // Carregar arquivo para importação
    await workbook.xlsx.load (file).then (() => {

      const worksheet = workbook.worksheets[0];

      // Verificar se existe apénas 3 colunas
      if (worksheet.actualColumnCount !== 3) {
        this.dialogService.messageDialog ()
          .title ("Atenção")
          .message ("A quantidade de colunas do arquivo está incorreta!")
          .show ();
        throw Error ("Numero de colunas do arquivo está incorreto");
      }

      worksheet.eachRow ((row, index) => {
        // Valores deve ser escritos após a primeira coluna (header)
        if (index > 1) {
          // Criar bairro auxiliar
          const bairro = new BairroAux ();
          // Buscar valores de bairros
          const nome = JSON.parse (JSON.stringify (row.getCell (1).value));
          const valor = isNotNullOrUndefined (row.getCell (2).value) ? JSON.stringify (row.getCell (2).value) : null;
          const bloqueado = isNotNullOrUndefined (row.getCell (3).value) ?
            StringUtils.normalize (JSON.parse (JSON.stringify (row.getCell (3).value))).trim ().toLowerCase () : null;


          // Tratar e escrever valores do bairro no bairro auxiliar
          bairro.nome = nome;
          bairro.valor = isNotNullOrUndefined (valor) ? parseFloat (valor) : null;
          if (bloqueado === "sim") bairro.bloqueado = true;
          if (bloqueado === "nao") bairro.bloqueado = false;
          if (bloqueado === null) bairro.bloqueado = false;

          // Verificar valor de bloqueio incorreto
          if ((bloqueado !== "sim") && (bloqueado !== "nao") && (bloqueado !== null)) {
            console.log (bloqueado);
            this.dialogService.messageDialog ()
              .title ("Atenção")
              .message ("Valor de bloqueio com formato incorreto.")
              .show ();
            throw Error ("Uma ou mais células de bloqueio está com formato indevido");
          }

          // Verificar formato da célula incorreto
          if (isNotNullOrUndefined (bairro.valor) && isNaN (bairro.valor)) {
            this.dialogService.messageDialog ()
              .title ("Atenção")
              .message ("Valor de bairro com formato incorreto.")
              .show ();
            throw Error ("Uma ou mais células de valor está com formato indevido");
          }

          // Adicionar bairro
          bairros.push (bairro);
        }
      });

      // Limpar configurações dos bairros
      for (const bairro of deliveryBairros) {
        bairro.valorEntrega = null;
        bairro.bloqueado = false;
      }

      for (const bairro of bairros) {
        // Variável para identificar que o nome do bairro não foi encontrado nos bairros cadastrados no sistema
        let bairroNotFound: boolean = true;

        // Tratar nome do bairro
        const nomeBairro = StringUtils.normalize (bairro.nome).trim ().toLowerCase ();

        // Procurar bairro cujo o nome corresponde com o bairro que será modificado
        for (const value of deliveryBairros) {
          // Tratar o nome do bairro
          const valueName = StringUtils.normalize (value.bairro.nome.trim ().toLowerCase ());

          if (StringUtils.equalsTo (valueName, nomeBairro)) {
            value.valorEntrega = bairro.valor;
            value.bloqueado = bairro.bloqueado;
            // Indicar que o bairro foi encontrado
            bairroNotFound = false;
          }
        }

        // Caso o bairro não tenha sido encontrado, criar um novo bairro
        if (bairroNotFound) {
          const newBairro = new Bairro ();

          newBairro.cidade = cidadeBairros;
          newBairro.nome = bairro.nome;
          newBairro.id = this.bairroService.createId ();
          newBairro.ref = null;
          newBairro.importado = true;

          newBairros.push(new DeliveryBairro (newBairro, bairro.valor, bairro.bloqueado, null));
        }
      }
    });


    // Retornar lista de bairros atualizada, junto com os novos bairros que serão adicionados no sistema
    return {bairros: deliveryBairros, newBairros: newBairros};

  }

}

// Objeto com valores para auxiliar criação de bairros na lista, sem id, idPedeQueChega e ref
class BairroAux {

  nome: string;
  valor: number;
  bloqueado: boolean;

  constructor() {}

}

