/* tslint:disable:directive-selector */
import {Directive, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {HasPermissionService} from "../../login/has-permission.service";
import {take} from "rxjs/operators";

@Directive({
  selector: "[hasPermission]"
})
export class HasPermissionDirective {
  private permissions = [];
  private elseTemplate: TemplateRef<any>;

  constructor(private hasPermissionService: HasPermissionService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
  }

  @Input()
  set hasPermissionElse(template: TemplateRef<any>) {
    this.elseTemplate = template;
  }

  @Input()
  set hasPermission(val) {
    this.permissions = val;
    this.updateView();
  }

  private updateView() {
    this.hasPermissionService.hasPermission(this.permissions)
      .pipe(take(1))
      .subscribe((hasPermission: boolean) => {
        if (hasPermission) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else if (this.elseTemplate) {
          this.viewContainer.createEmbeddedView(this.elseTemplate);
        } else {
          this.viewContainer.clear();
        }
      });
  }

}
