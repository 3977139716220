import {OrderBy} from "./order-by";
import {Operators} from "./operators";

export class OrderByDesc extends OrderBy {
  constructor(value: string | string[]);
  constructor(value?: any) {
    super(Operators.ORDER_BY_DESC, value);
  }
}

export function $orderByDesc(value: string | string[]);
export function $orderByDesc(value?: any): OrderByDesc {
  return new OrderByDesc(value);
}
