export class CreditCard {
  public id: string;
  public number: string;
  public holderName: string;
  public expiryDate: string;
  public verificationValue: string;
  public description: string;
  public type: string;

  constructor(creditCard: any = {}) {
    if (creditCard) {
      this.id = creditCard.id;
      this.number = creditCard.number;
      this.description = creditCard.description;
      this.verificationValue = creditCard.verificationValue;
      this.holderName = creditCard.holderName;
      this.expiryDate = creditCard.expiryDate;
      this.type = creditCard.type;
    }
  }

  toIugu() {
    return {
      number: this.number,
      first_name: this.holderName.split(" ")[0],
      last_name: this.holderName.split(" ").slice(1).join(" "),
      verification_value: this.verificationValue,
      month: this.expiryDate.split("\/")[0],
      year: this.expiryDate.split("\/")[1]
    };
  }
}

enum CardType {
  UNKNOWN_CARD,
  AMEX_CARD,
  MASTER_CARD,
  VISA_CARD,
  DINERS_CARD,
  ELO_CARD,
}

export function getCardType(brand: string): string {
  switch (brand.toUpperCase()) {
    case "VISA":
      return CardType[CardType.VISA_CARD];
    case "AMEX":
      return CardType[CardType.AMEX_CARD];
    case "MASTER":
      return CardType[CardType.MASTER_CARD];
    case "ELO":
      return CardType[CardType.ELO_CARD];
    case "DINERS":
      return CardType[CardType.DINERS_CARD];
    default:
      return CardType[CardType.UNKNOWN_CARD];
  }
}
