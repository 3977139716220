import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {StatusInvoice} from "../../../models/iugu/invoice.iugu.model";

@Directive({
  selector: "[appStatusFaturaChip]"
})
export class StatusFaturaChipDirective implements OnInit {

  @Input()
  statusInvoice: string;

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    this.updateStyle();
  }

  private updateStyle() {
    switch (this.statusInvoice) {
      case StatusInvoice[StatusInvoice.expired]: {
        this.el.nativeElement.style.border = "1px solid #9e9e9e";
        this.el.nativeElement.style.backgroundColor = "white";
        this.el.nativeElement.style.width = "90px";
        this.el.nativeElement.childNodes[0].style.color = "#9e9e9e";
        break;
      }

      case StatusInvoice[StatusInvoice.canceled]: {
        this.el.nativeElement.style.border = "1px solid #a30009";
        this.el.nativeElement.style.backgroundColor = "white";
        this.el.nativeElement.style.width = "90px";
        this.el.nativeElement.childNodes[0].style.color = "#a30009";
        break;
      }

      case StatusInvoice[StatusInvoice.paid]: {
        this.el.nativeElement.style.border = "1px solid green";
        this.el.nativeElement.style.backgroundColor = "white";
        this.el.nativeElement.style.width = "90px";
        this.el.nativeElement.childNodes[0].style.color = "green";
        break;
      }

      case StatusInvoice[StatusInvoice.pending]: {
        this.el.nativeElement.style.border = "1px solid #fbba13";
        this.el.nativeElement.style.backgroundColor = "white";
        this.el.nativeElement.style.width = "90px";
        this.el.nativeElement.childNodes[0].style.color = "#fbba13";
        break;
      }
    }
  }
}
