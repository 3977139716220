import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {PageService} from "../../../services/page.service";
import {Entregador, TurnoEntragador} from "../../../models/entregador.model";
import {MatDialog} from "@angular/material";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {EntregadoresService} from "../entregadores.service";
import {MaskUtil} from "../../../utils/mask-util";
import {IFormCanDeactivate} from "../../../guards/iform-canDeactivate";
import * as firebase from "firebase";
import * as moment from "moment";
import {isNotNullOrUndefined} from "../../../utils/commons";
import {animate, style, transition, trigger} from "@angular/animations";
import {CpfCnpjValidationService} from "../../../services/cpf-cnpj-validation.service";
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: "app-entregadores-form",
  templateUrl: "./entregadores-form.component.html",
  styleUrls: ["./entregadores-form.component.scss"],
  animations: [
    trigger("slideIn", [
      transition(":enter", [
        style({transform: "translateX(-20%)"}),
        animate("150ms ease-in", style({transform: "translateX(0%)"}))
      ])
    ])
  ]
})
export class EntregadoresFormComponent extends PageService<Entregador> implements OnInit, IFormCanDeactivate {

  form: FormGroup;

  TurnoEntregador = TurnoEntragador;

  readonly maskCellPhone = MaskUtil.maskCell;
  readonly maskDate = MaskUtil.maskDate;
  readonly maskCpf = MaskUtil.maskCpf;
  // Booleano que verifica o status da atualização de um formulário
  formMudou: boolean = null;
  disableSaveButtton: boolean = false;
  haveDataSaidaValue: boolean = false;
  selectedTurno: string = "";

  constructor(public service: EntregadoresService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              public formBuilder: FormBuilder,
              public cpfCnpjValidation: CpfCnpjValidationService) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/entregadores/");

    this.form = formBuilder.group({
      nome: ["", [Validators.required, Validators.minLength(5)]],
      dataNascimento: ["", [Validators.required, Validators.pattern(MaskUtil.regexDate)]],
      cpf: ["", [Validators.required, Validators.pattern(MaskUtil.regexCPF), this.cpfCnpjValidation.validarCpf.bind(this)]],
      cnh: ["", [Validators.required]],
      telefone: ["", [Validators.required]],
      placaMoto: ["", [Validators.required]],
      modeloMoto: ["", [Validators.required]],
      turno: ["", [Validators.required]],
      dataEntrada: ["", [Validators.required]],
      dataSaida: [""],
      motivoSaida: [""],
      endereco: ["", [Validators.required, Validators.minLength(10)]],
      observacao: [""],
      inactive: [false]
    });
    this.form.get("dataSaida").disable({onlySelf: true, emitEvent: false});
    this.form.valueChanges
      .subscribe(value => {
        Object.keys(value).forEach(key => this.item[key] = value[key]);
      });

    /**
     * Inicializa método que verifica se ocorreram mudanças no conteúdo do formulário,
     * para decidir o comportamento do método podeMudarRota (canDeactivate)
     */
    this.onChanges();
  }

  onChanges() {
    let mudanca = 0;
    this.form.valueChanges.subscribe(() => {
      if (mudanca === 0) {
        this.formMudou = false;
        mudanca++;
      } else {
        this.formMudou = true;
      }
    });
  }

  ngOnInit() {
    super.ngOnInit();

    this.form.get("turno").valueChanges.subscribe(value => {
      this.selectedTurno = value;
    });

    this.itemSubject.asObservable()
      .subscribe((item) => {
        const dataNascimento = item.dataNascimento ? item.dataNascimento.toDate().toLocaleDateString() : null;
        item.dataNascimento = null;
        const dataEntrada = item.dataEntrada ? moment(item.dataEntrada.toDate()) : null;
        item.dataEntrada = null;
        const dataSaida = item.dataSaida ? moment(item.dataSaida.toDate()) : null;
        item.dataSaida = null;
        if (isNotNullOrUndefined(item.dataSaida)) {
          this.haveDataSaidaValue = true;
          this.form.get("motivoSaida").setValidators(Validators.required);
          this.form.get("motivoSaida").updateValueAndValidity({onlySelf: true, emitEvent: false});
        }
        this.form.get("dataSaida").enable({onlySelf: true, emitEvent: false});
        this.form.patchValue(item, {emitEvent: false});
        this.form.get("dataNascimento").patchValue(dataNascimento, {emitEvent: false});
        this.form.get("dataEntrada").patchValue(dataEntrada, {emitEvent: false});
        this.form.get("dataSaida").patchValue(dataSaida, {emitEvent: false});
        this.selectedTurno = this.form.get("turno").value;
        this.formMudou = false;
      });

    this.form.get("dataSaida").valueChanges.subscribe(value => {
      if (isNotNullOrUndefined(value) && value !== "") {
        this.haveDataSaidaValue = true;
        this.form.get("motivoSaida").setValidators(Validators.required);
        this.form.get("motivoSaida").updateValueAndValidity({onlySelf: true, emitEvent: false});
      } else {
        this.haveDataSaidaValue = false;
        this.form.get("motivoSaida").patchValue(null, {emitEvent: false});
        this.form.get("motivoSaida").clearValidators();
        this.form.get("motivoSaida").updateValueAndValidity({onlySelf: true, emitEvent: false});
      }
    });
  }

  protected newItem(): Entregador {
    return new Entregador;
  }

  save(entregador: Entregador) {
    this.disableSaveButtton = true;
    this.loadingService.showTopBar();

    if (this.item.dataNascimento) {
      this.item.dataNascimento = Timestamp.fromDate(
        new Date(this.item.dataNascimento.toString().replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"))
      );
    }
    if (this.item.dataEntrada) {
      this.item.dataEntrada = Timestamp.fromDate(this.item.dataEntrada.toDate());
    }
    if (this.item.dataSaida) {
      this.item.dataSaida = Timestamp.fromDate(this.item.dataSaida.toDate());
    }

    this.service.addOrUpdateEntregador(entregador.toAny()).subscribe(() => {
    }, error => {
      this.disableSaveButtton = false;
      this.loadingService.hideTopBar();
      this.dialogService
        .messageDialog()
        .message(error)
        .show();
    }, () => {
      this.formMudou = false;
      this.disableSaveButtton = false;
      this.loadingService.hideTopBar();
      this.goBack();
    });
  }

  compareWith(p1: any, p2: any) {
    return p1 && p2 && p1 === p2;
  }
}
