import {isNullOrUndefined} from "util";

export class Endereco {

  public cep: string;
  public logradouro: string;
  public numero: string;
  public complemento: string;
  public bairro: string;
  public cidade: string;
  public estado: string;
  public codigoMunicipio: string;

  constructor(endereco: any = {}) {
    if (!isNullOrUndefined(endereco)) {
      this.cep = endereco.cep;
      this.logradouro = endereco.logradouro;
      this.numero = endereco.numero;
      this.complemento = endereco.complemento;
      this.bairro = endereco.bairro;
      this.cidade = endereco.cidade;
      this.estado = endereco.estado;
      this.codigoMunicipio = endereco.codigoMunicipio;
    }
  }

  public toAny(): any {
    const endereco: any = {};
    if (this.cep) endereco.cep = this.cep;
    if (this.logradouro) endereco.logradouro = this.logradouro;
    if (this.numero) endereco.numero = this.numero;
    if (this.complemento) endereco.complemento = this.complemento;
    if (this.bairro) endereco.bairro = this.bairro;
    if (this.cidade) endereco.cidade = this.cidade;
    if (this.estado) endereco.estado = this.estado;
    if (this.codigoMunicipio) endereco.codigoMunicipio = this.codigoMunicipio;
    return endereco;
  }
}
