import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {PerfilService} from "../components/perfil-acesso/perfil.service";
import {isNotNullOrUndefined} from "../utils/commons";
import {PerfilAcesso, Role} from "../models/perfil-acesso.model";
import {map, tap} from "rxjs/operators";

@Injectable()
export class AccessControlGuard implements CanActivate {

  constructor(private perfilAcesso: PerfilService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const rolePermission = route.data.role.read;
    return this.perfilAcesso.getPerfilAcessoLoggedUser()
      .pipe(
        map((perfil: PerfilAcesso) => {
          // Verificar se tem a regra no perfil do usuário
          const roles = isNotNullOrUndefined(perfil) && perfil.roles.filter(role => rolePermission === Role[role]);
          return isNotNullOrUndefined(roles) && roles.length > 0;
        }),
        tap(authorized => {
          if (!authorized) {
            this.router.navigate(["page-not-found"]);
          }
        })
      );
  }
}
