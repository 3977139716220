import * as firebase from "firebase";
import DocumentReference = firebase.firestore.DocumentReference;
import {FireModel} from "./fire.model";

export abstract class RecordByEmpresa extends FireModel {

  empresa: DocumentReference;

  constructor(id?: string, ref?: DocumentReference, empresa?: DocumentReference) {
    super(id, ref);
    this.empresa = empresa;
  }

}
