import {ChangeDetectorRef, Component} from "@angular/core";
import {Observable} from "rxjs/Rx";
import {Cozinha} from "../../../models/cozinha.model";
import {PageService} from "../../../services/page.service";
import {CozinhasService} from "../cozinhas.service";
import { MatDialog } from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: "app-cozinhas-list",
  templateUrl: "./cozinhas-list.component.html",
  styleUrls: ["./cozinhas-list.component.scss"]
})
export class CozinhasListComponent extends PageService<Cozinha> {
  $cozinhas: Observable<Cozinha[]>;

  constructor(service: CozinhasService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router) {

    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/cozinhas/");
    this.$cozinhas = this.service.getCollection().valueChanges();

  }

  newItem(): Cozinha {
    return new Cozinha();
  }

  ativarOuInativar(item: Cozinha) {
    this.service.getDoc(item.id).update({
      inactive: !item.inactive
    })
      .then(() => {
      })
      .catch(() => {
        this.dialogService
          .messageDialog()
          .title("Atenção")
          .message("Não foi possível mudar o status! Por favor, tente novamente.")
          .show();
      });
  }

}
