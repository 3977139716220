import {Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild, EventEmitter, Output} from "@angular/core";
import {AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {tap} from "rxjs/operators";
import {Observable, Subscription} from "rxjs";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import { isNullOrUndefined } from "../../../utils/commons";

@Component({
  selector: "app-input-autocomplete-custom",
  templateUrl: "./input-autocomplete-custom.component.html",
  styleUrls: ["./input-autocomplete-custom.component.scss"],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputAutocompleteCustomComponent),
    multi: true
  }]
})
export class InputAutocompleteCustomComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  autocompleteSources$: Observable<any[]>;
  autocompleteSourcesSubscription: Subscription;
  autoCompleteSouces: any[];

  /****
   * Exemplo do caminho:
   * {
   *   a: {
   *     b: {
   *       foo: "bar"
   *     }
   *   }
   * }
   *
   * Caminho para acessar o campo foo: ["a", "b", "foo"]
   ****/

    // Caminho do valor que vai ser retornado para o array do input
  @Input()
  returnValuePath: string[];

  // Caminho do valor que será exibido nos chips do input
  @Input()
  viewValuePath: string[];

  @Input()
  placeholder: string;

  @Input()
  formControlName: string;

  // Observable com o array dos valores já selecionados no input
  @Input()
  initialSelectedSources$: Observable<any[]>;

  @Input()
  label: string;

  @Input()
  nullOption: string;

  @Input()
  optionSubtitle: boolean = false;

  @Input()
  optionSubtitlePath: string[];

  @Output()
  onInit: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  finishLoading: EventEmitter<any> = new EventEmitter<any>();

  // Array que armazena os objetos das opções selecionadas
  selectedSource: any;

  inputCtrl = new FormControl();
  control?: AbstractControl;

  @ViewChild("customInput", { static: false }) customInput: ElementRef<HTMLInputElement>;

  constructor() {}

  accessField(value: any, path: string[]): any {
    if (path) {
      return path.reduce((o, n) => o[n], value);
    } else {
      return value;
    }
  }

  ngOnInit() {
    this.onInit.emit();
    if (isNullOrUndefined(this.autocompleteSources$)) {
      throw new Error("Attribute 'autocompleteSource$' is required");
    }

    this.autocompleteSourcesSubscription = this.autocompleteSources$.pipe(
      tap((autoCompleteSources: any[]) => {
        this.autoCompleteSouces = autoCompleteSources;
      })
    ).subscribe(() => {
      this.finishLoading.emit();
    });
  }

  // Seleciona uma opção da lista do autocomplete
  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedSource = event.option.value;
    this.inputCtrl.setValue(this.selectedSource ? this.accessField(this.selectedSource, this.viewValuePath) : this.selectedSource);

    this.writeValue(this.selectedSource);
  }

  /***
   * Funções do ControlValueAcessor
   ***/
  onChange: any = () => {};
  onTouch: any = () => {};

  val = "";
  set value(val) {
    if (val) {
      val = this.accessField(val, this.returnValuePath);
    }
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }

  // this method sets the value programmatically
  writeValue(value: any) {
    this.value = value;
  }

  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  ngOnDestroy(): void {
    if (this.autocompleteSourcesSubscription) {
      this.autocompleteSourcesSubscription.unsubscribe();
    }
  }

}
