import {DeliveryBairro} from "./delivery-bairro.model";
import {RecordByEmpresa} from "./record-by-empresa";
import {isNotNullOrUndefined} from "../utils/commons";

export class Delivery extends RecordByEmpresa {

  public tipo: string;
  // Valor da entrega
  public valor: number = 0;
  // Valor a ser cobrado do lojista
  public valorLojista: number = 0;
  // Tipo do valorLojista
  public valorLojistaIsPercent: boolean = false;
  // Valor mínimo de pedido (para frete grátis)
  public valorMinimoDePedido: number = 0;
  // Valor do pedido para entrega grátis
  public valorPedidoEntregaGratis: number = 0;
  // Valor por bairro
  public bairros: DeliveryBairro[];
  // Flag para a empresa permitir que o usuario busque o pedido no balcão
  public buscarNoBalcao: boolean = false;

  constructor(delivery: any = {}) {
    super();
    this.id = delivery.id;
    this.ref = delivery.ref;
    this.empresa = delivery.empresa;
    this.tipo = delivery.tipo;
    this.valorLojista = delivery.valorLojista;
    this.valorLojistaIsPercent = delivery.valorLojistaIsPercent;
    this.valor = delivery.valor;
    this.valorMinimoDePedido = delivery.valorMinimoDePedido;
    this.valorPedidoEntregaGratis = delivery.valorPedidoEntregaGratis;
    this.buscarNoBalcao = delivery.buscarNoBalcao;
    this.bairros = DeliveryBairro.makeNewList(delivery.bairros);
  }

  toAny(): any {
    const a: any = {};
    if (this.id) a.id = this.id;
    if (this.ref) a.ref = this.ref;
    if (this.empresa) a.empresa = this.empresa;
    if (this.tipo) a.tipo = this.tipo;
    a.valorLojista = this.valorLojista ? this.valorLojista : 0;
    a.valorLojistaIsPercent = this.valorLojistaIsPercent ? this.valorLojistaIsPercent : false;
    a.valorMinimoDePedido = this.valorMinimoDePedido ? this.valorMinimoDePedido : 0;
    a.valorPedidoEntregaGratis = this.valorPedidoEntregaGratis ? this.valorPedidoEntregaGratis : 0;
    a.valor = this.valor ? this.valor : 0;
    a.buscarNoBalcao = isNotNullOrUndefined(this.buscarNoBalcao) ? this.buscarNoBalcao : false;
    if (this.bairros) a.bairros = this.bairros.map(bairro => bairro);
    return a;
  }

}

export enum TipoDelivery {
  pede_que_chega,
  gratis,
  valor_fixo
}
