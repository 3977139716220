import {Injectable} from "@angular/core";
import {FirebaseApp} from "@angular/fire";
import "firebase/storage";
import {isNotNullOrUndefined} from "../../utils/commons";

@Injectable()
export class ImageUploaderService {
  storage: any;

  constructor(firebaseApp: FirebaseApp) {
    this.storage = firebaseApp.storage().ref();
  }

  private uploadFile(file, name) {
    return this.storage.child(name).put(file);
  }

  public uploadString(dataUrl, name: string): Promise<string> {
    if (this.isDataUrl(dataUrl)) {
      const uploadTask = this.uploadFile(this.dataURLtoBlob(dataUrl), name);
      return new Promise<string>((resolve, reject) => {
        uploadTask.on("state_changed",
          () => {}, // 1. 'state_changed' observer, called any time the state changes
          (error) => reject(error), // 2. Error observer, called on failure
          () => { // 3. Completion observer, called on successful completion
            resolve(uploadTask.snapshot.ref.getDownloadURL());
          });
      });
    } else {
      return Promise.resolve(dataUrl);
    }
  }

  isDataUrl(dataUrl): boolean {
    const regex = /^\s*data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@\/?%\s]*)\s*$/i;
    return isNotNullOrUndefined(dataUrl) && !!dataUrl.match(regex);
  }

  dataURLtoBlob(dataurl) {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

}
