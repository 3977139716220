import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {PageService} from "../../../services/page.service";
import {BairroService} from "../bairro.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SnackService} from "../../../services/snack/snack.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import {Bairro} from "../../../models/bairro.model";
import {Observable, Subscription} from "rxjs";
import {Cidade} from "../../../models/cidade.model";
import {CidadeService} from "../../cidade/cidade.service";
import {isNotNullOrUndefined} from "../../../utils/commons";
import {$query} from "../../../services/firebase/criteria/query";
import {Criterion} from "../../../services/firebase/criteria/criterion";
import {CidadeDialogComponent} from "../../cidade/cidade-dialog/cidade-dialog.component";
import {AuthService} from "../../../modules/login/auth.service";
import { AppUser } from "../../../models/appUser";

@Component({
  selector: "app-bairro-list",
  templateUrl: "./bairro-list.component.html",
  styleUrls: ["./bairro-list.component.scss"]
})
export class BairroListComponent extends PageService<Bairro> implements OnInit, OnDestroy {

  cidades: Observable<Cidade[]>;
  bairros: Bairro[];

  @ViewChild(MatSelect, { static: true })
  cidadeMatSelect: MatSelect;

  loggedUser: AppUser;
  userSubscription: Subscription;
  bairrosSubscription: Subscription;

  constructor(service: BairroService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              public cidadeService: CidadeService,
              public authService: AuthService) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/bairros/");

    // Carregar as cidades
    this.cidades = this.cidadeService.col$();
  }

  ngOnInit() {
    // Verificar se tem uma cidade selecionada
    const cidade = (this.service as BairroService).cidadeSelecionada;
    if (isNotNullOrUndefined(cidade)) {
      this.cidadeMatSelect.value = cidade;
      this.carregarBairros(cidade);
    }

    this.cidadeMatSelect.registerOnChange((value: Cidade) => {
      this.carregarBairros(value);
    });

    this.userSubscription = this.authService.currentUser.subscribe(user => {
      this.loggedUser = user;
    });
  }

  carregarBairros(cidade: Cidade) {
    this.loadingService.register("meuLoad");

    if (isNotNullOrUndefined(cidade)) {
      // Filtrar os bairros pela cidade selecionada
      this.bairrosSubscription = (this.service as BairroService)
        .getCollection($query(new Criterion("cidade.id", "==", cidade.id)))
        .valueChanges()
        .subscribe((bairros: Bairro[]) => {
          this.bairros = bairros;
          this.loadingService.timeOut("meuLoad");
        });

      // Manter a cidade selecionada no service
      (this.service as BairroService).cidadeSelecionada = new Cidade(cidade);
    } else {
      this.bairros = [];
    }
  }

  protected newItem(): Bairro {
    return new Bairro();
  }

  compareWith(i1: Cidade, i2: Cidade): boolean {
    if (isNotNullOrUndefined(i1) && isNotNullOrUndefined(i2)) {
      return i1.id === i2.id;
    } else {
      return false;
    }
  }

  editCidade() {
    this.dialog.open(CidadeDialogComponent, {width: "450px", data: this.cidadeMatSelect.value})
      .afterClosed()
      .subscribe(cidade => {
        if (cidade) {
          this.cidadeMatSelect.value = cidade;
          (this.service as BairroService).cidadeSelecionada = new Cidade(cidade);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.bairrosSubscription) {
      this.bairrosSubscription.unsubscribe();
    }
  }
}
