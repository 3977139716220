import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {combineLatest, Observable, of} from "rxjs";
import {map, mergeMap, take, tap} from "rxjs/operators";
import {PedidoItemService} from "./pedido-item.service";
import {PedidoItem} from "../models/pedido-item.model";
import * as firebase from "firebase";
import {firestore} from "firebase";
import {Repository} from "../repository/Repository";
import {Pedido, MotivoRejeicao} from "../models/pedido.model";
import {AuthService} from "../modules/login/auth.service";
import {PoMQuery} from "./firebase/criteria/query";
import {Criterion} from "./firebase/criteria/criterion";
import {Empresa} from "../models/empresa";
import {OrderBy} from "./firebase/criteria/order-by";
import {OrderByDesc} from "./firebase/criteria/order-by-desc";
import Timestamp = firebase.firestore.Timestamp;
import {AngularFireFunctions} from "@angular/fire/functions";

@Injectable({
  providedIn: "root"
})
export class PedidosService extends Repository<Pedido> {

  constructor(db: AngularFirestore,
              public auth: AuthService,
              public itemService: PedidoItemService,
              public fns: AngularFireFunctions) {
    super(db, "pedidos", true);
  }

  deserialize(value: any): Pedido {
    return new Pedido(value);
  }

  private getHoraInicial(): Timestamp {
    const now = firestore.Timestamp.now().toDate();
    if (now.getHours() < 5) {
      now.setDate(now.getDate() - 1);
      now.setHours(5, 0, 0, 0);
    } else {
      now.setHours(5, 0, 0, 0);
    }
    return firestore.Timestamp.fromDate(now);
  }

  getAllPedidos(queryFn: PoMQuery = new PoMQuery()): Observable<Pedido[]> {
    return super.col$(queryFn.addFirst((new Criterion("data", ">", this.getHoraInicial())))).pipe(
      map((pedidos: Pedido[]) => {
        return pedidos.map(pedido => {
          pedido.ref = this.getDoc(pedido.id).ref;
          return pedido;
        });
      })
    );
  }

  col$(queryFn: PoMQuery = new PoMQuery(), getItens?: boolean): Observable<Pedido[]> {
    return super.col$(queryFn)
      .pipe(
        // Adicionar a referência aos pedidos
        map((pedidos: Pedido[]) => {

          return pedidos.map(pedido => {
            pedido.ref = this.getDoc(pedido.id).ref;
            return pedido;
          });
        }),
        mergeMap((pedidos: Pedido[]) => {
          // Se o getItens for true, carrega os itens dos pedidos e os agrupa
          if (getItens) {
            // Criar uma lista de observables para os itens de cada pedido
            const itensObservable = pedidos.map(pedido => {
              return this.itemService.getItensByPedido(pedido);
            });
            if (pedidos.length === 0) {
              return of([]);
            }
            return combineLatest(itensObservable)
              .pipe(
                map((itensResult: PedidoItem[][]) => {
                  // Carregar os itens para o pedido
                  return pedidos.map((pedido, index) => {
                    // Atribuir os itens ao pedido
                    pedido.itens = itensResult[index];
                    return pedido;
                  });
                })
              );
          } else {
            return of(pedidos);
          }
        })
      );
  }

  getById(id: string, includeItens: boolean = true): Observable<Pedido> {
    return super.getById(id)
      .pipe(
        tap(pedido => {
          pedido.ref = this.getDoc(pedido.id).ref;
        }),
        mergeMap(pedido => {
          if (includeItens) {
            return this.itemService.getItensByPedido(pedido)
              .pipe(
                take(1),
                map((itens: PedidoItem[]) => {
                  pedido.itens = itens;
                  return pedido;
                })
              );
          } else {
            return of(pedido);
          }
        })
      );
  }

  get empresa(): Empresa {
    return this.auth.currentEmpresa;
  }

  protected orderBy(): OrderBy {
    return new OrderByDesc("data");
  }

  public getPedidosByUser(idCliente: string): Observable<Pedido[]> {
    const query = new PoMQuery();
    query.add(new Criterion("cliente.id", "==", idCliente));
    return this.col$(query);
  }

  public getMotivosRejeicao(id: string): Observable<MotivoRejeicao[]> {
    return this.fns.httpsCallable("call-pedidos-getReasonsRejection")({id: id});
  }
}
