import {Component, Inject, OnInit} from "@angular/core";
import {FormControl} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import * as moment from "moment";
import {ConsultorCommissionService} from "../../../services/consultor-commission.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {DadosPagamentoComissao} from "../comissoes-consultores.component";
import {LoadingService} from "../../../services/loading/loading.service";

@Component({
  selector: "app-informar-data-pagamento-dialog",
  templateUrl: "./informar-data-pagamento-dialog.component.html",
  styleUrls: ["./informar-data-pagamento-dialog.component.scss"]
})
export class InformarDataPagamentoDialogComponent implements OnInit {
  public dataPagamentoControl = new FormControl({
    value: moment(new Date()),
    disabled: true,
  });
  public minDate: moment.Moment;
  public valorTotalComissoes: number;
  public quantidadeComissoesSelecionadas: number;

  // Indica que está processando alguma informação
  public isLoading: boolean = false;

  constructor(public dialogRef: MatDialogRef<InformarDataPagamentoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dadosPagamento: DadosPagamentoComissao,
              public consultorCommissionService: ConsultorCommissionService,
              public dialogService: DialogService,
              public loading: LoadingService) {
    this.minDate = moment((new Date(this.dadosPagamento.dataLimitePagamento)));
    this.valorTotalComissoes = this.dadosPagamento.valorTotal;
    this.quantidadeComissoesSelecionadas = this.dadosPagamento.qtdComissoesSelecionadas;

    // Desabilita o fechamento do dialog
    this.dialogRef.disableClose = true;

    // Verifica se o usuário clicou fora do dialog ou apertou a tecla ESC para sair do dialog.
    this.dialogRef.backdropClick().subscribe(() => {
      if (!this.isLoading) {
        this.dialogRef.close(false);
      }
    });
    this.dialogRef.keydownEvents().subscribe(next => {
      if (next.key === "Escape" && !this.isLoading) {
        this.dialogRef.close(false);
      }
    });
  }

  ngOnInit(): void {
    this.loading.isLoadingTopBar().subscribe(_loading => this.isLoading = _loading);
  }

  public async pagarComissao() {
    this.loading.showTopBar();
    const dataPagamento = this.dataPagamentoControl.value.toString();
    return this.consultorCommissionService.pagarComissao({commissions: this.dadosPagamento.idComissoes, dataPagamento})
      .then(() => {
      this.dialogService.messageDialog().title("Comissões pagas").message("Comissões pagas com sucesso!").show();
    }).catch((error) => {
      this.dialogService.messageDialog().title("Erro ao pagar comissões").message(error.message).show();
    }).then(() => {
      this.dialogRef.close(true);
      this.loading.hideTopBar();
    });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
