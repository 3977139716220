export class BancoMaskUtils {

  /**
   * Mask de Agências
   * **/

  public static get maskAgencia4() {
    return [/\d/, /\d/, /\d/, /\d/];
  }
  public static get regexAgencia4() {
    return "([0-9]{4})";
  }

  public static get maskAgencia3() {
    return [/\d/, /\d/, /\d/];
  }
  public static get regexAgencia3() {
    return "([0-9]{3})";
  }

  public static get maskAgencia4D() {
    return [/\d/, /\d/, /\d/, /\d/, "-", /\w/];
  }
  public static get regexAgencia4D() {
    return "([0-9]{4}[-]?[a-zA-Z0-9])";
  }

  public static get maskAgencia1() {
    return [/\d/];
  }
  public static get regexAgencia1() {
    return "([0-9])";
  }


  /**
   * Mask de Conta
   * **/

  public static get maskBanco11D() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\w/];
  }
  public static get regexBanco11D() {
    return "([0-9]{11}[-]?[a-zA-Z0-9])";
  }

  public static get maskBanco10D() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\w/];
  }
  public static get regexBanco10D() {
    return "([0-9]{10}[-]?[a-zA-Z0-9])";
  }

  public static get maskBanco9D() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\w/];
  }
  public static get regexBanco9D() {
    return "([0-9]{9}[-]?[a-zA-Z0-9])";
  }

  public static get maskBanco8D() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\w/];
  }
  public static get regexBanco8D() {
    return "([0-9]{8}[-]?[a-zA-Z0-9])";
  }

  public static get maskBanco7D() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\w/];
  }
  public static get regexBanco7D() {
    return "([0-9]{7}[-]?[a-zA-Z0-9])";
  }

  public static get maskBanco6D() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\w/];
  }
  public static get regexBanco6D() {
    return "([0-9]{6}[-]?[a-zA-Z0-9])";
  }

  public static get maskBanco5D() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\w/];
  }
  public static get regexBanco5D() {
    return "([0-9]{5}[-]?[a-zA-Z0-9])";
  }

  public static get maskBanco10() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  }
  public static get regexBanco10() {
    return "([0-9]{10})";
  }

  public static get maskBanco11() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  }
  public static get regexBanco11() {
    return "([0-9]{11})";
  }

  public static get maskBanco7() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\w/];
  }
  public static get regexBanco7() {
    return "([0-9]{6}[a-zA-Z0-9])";
  }

  public static get maskBanco8() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  }
  public static get regexBanco8() {
    return "([0-9]{8})";
  }

  public static get maskBancoX8D() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\w/];
  }
  public static get regexBancoX8D() {
    return "([0-9]{11}[-]?[a-zA-Z0-9])";
  }
}
