import {Cliente} from "./cliente";
import "moment/locale/pt-br";
import * as firebase from "firebase";
import Timestamp = firebase.firestore.Timestamp;
import {AppUser} from "./appUser";
import {RecordByEmpresa} from "./record-by-empresa";
import {isNullOrUndefined} from "../utils/commons";
import {DateTimeUtils} from "../utils/date-time-utils";

export class Avaliacao extends RecordByEmpresa {

  public usuario: Cliente;
  public data: Timestamp;
  public comentario: string;
  public nota: number;
  public resposta: Resposta;
  public pedidoId: string;

  constructor(avaliacao: any = {}) {
    super(avaliacao.id, avaliacao.ref, avaliacao.empresa);
    this.usuario = avaliacao.usuario;
    this.data = avaliacao.data;
    this.comentario = avaliacao.comentario;
    this.nota = avaliacao.nota;
    this.resposta = new Resposta(avaliacao.resposta);
    this.pedidoId = avaliacao.pedidoId;
  }

  get moment(): string {
    return DateTimeUtils.timestampToMoment(this.data);
  }

}

export class Resposta {

  public user: AppUser;
  public data: Timestamp;
  public resposta: string;

  constructor(resposta: any = {}) {
    resposta = isNullOrUndefined(resposta) ? {} : resposta;
    this.user = new AppUser(resposta.user);
    this.data = typeof resposta.data === "string" ? Timestamp.fromDate(new Date(resposta.data)) : resposta.data;
    this.resposta = resposta.resposta;
  }

  get moment(): string {
    return DateTimeUtils.timestampToMoment(this.data);
  }

}
