import {ChangeDetectorRef, Component} from "@angular/core";
import {PageService} from "../../../services/page.service";
import {Entregador} from "../../../models/entregador.model";
import {Observable} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";
import {$query} from "../../../services/firebase/criteria/query";
import {EntregadoresService} from "../entregadores.service";
import {FormControl} from "@angular/forms";
import {Criterion} from "../../../services/firebase/criteria/criterion";

@Component({
  selector: "app-entregadores-list",
  templateUrl: "./entregadores-list.component.html",
  styleUrls: ["./entregadores-list.component.scss"]
})
export class EntregadoresListComponent extends PageService<Entregador> {
  $entregadores: Observable<Entregador[]>;

  inactives = new FormControl();

  constructor(service: EntregadoresService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/entregadores/");
    this.inactives.valueChanges.subscribe(value => {
      if (value) {
        this.$entregadores = this.service.col$();
      } else {
        this.$entregadores = this.service.col$($query(new Criterion("inactive", "==", false)));
      }
    });
    this.inactives.patchValue(false);
  }

  protected newItem(): Entregador {
    return undefined;
  }

}
