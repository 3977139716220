import {ChangeDetectorRef, Component} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";
import {CategoriaProduto} from "../../../models/categoria.model";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {PageService} from "../../../services/page.service";
import {SnackService} from "../../../services/snack/snack.service";
import {CategoriaProdutoService} from "../categoria-produto.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {isNotNullOrUndefined} from "../../../utils/commons";
import {IFormCanDeactivate} from "../../../guards/iform-canDeactivate";

@Component({
  selector: "app-categoria-produto-list",
  templateUrl: "./categoria-produto-list.component.html",
  styleUrls: ["./categoria-produto-list.component.scss"]
})
export class CategoriaProdutoListComponent extends PageService<CategoriaProduto> implements IFormCanDeactivate {

  $categorias: Observable<CategoriaProduto[]>;
  isReordering: boolean = false;
  newOrder: CategoriaProduto[];
  orderMudou: boolean = false;

  constructor(service: CategoriaProdutoService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/categorias-produto/");
    this.buscarCategorias();
  }

  buscarCategorias() {
    this.$categorias = this.service.getCollection().valueChanges();
  }

  drop(event: CdkDragDrop<CategoriaProduto[]>, categorias: CategoriaProduto[]) {
    moveItemInArray(categorias, event.previousIndex, event.currentIndex);
    categorias = categorias.map((categoria, index) => {
      categoria.ordem = index;
      return categoria;
    });

    this.orderMudou = true;
    this.newOrder = categorias;
  }

  cancelarOrdenacao() {
    if (this.orderMudou) {
      this.dialogService.confirmDialog()
        .message("Deseja realmente cancelar a ordenação?")
        .acceptButton("Sim")
        .cancelButton("Não")
        .show()
        .subscribe(accept => {
          if (accept) {
            this.isReordering = false;
            this.buscarCategorias();
            this.orderMudou = false;
          }
        });
    } else {
      this.isReordering = false;
    }
  }

  reordenar(categorias: CategoriaProduto[]) {
    this.isReordering = false;
    this.orderMudou = false;

    if (isNotNullOrUndefined(categorias)) {
      return (this.service as CategoriaProdutoService).reordenar(categorias)
        .catch(err => {
          this.dialogService
            .messageDialog()
            .message("Erro ao ordenar as categorias! " + err.message)
            .show();
        });
    }
  }

  newItem(): CategoriaProduto {
    return new CategoriaProduto();
  }

  ativarOuInativar(item: CategoriaProduto) {
    this.service.getDoc(item.id).update({
      inactive: !item.inactive
    })
      .then(() => {
      })
      .catch(() => {
        this.dialogService
          .messageDialog()
          .title("Atenção")
          .message("Não foi possível mudar o status do produto! Por favor, tente novamente.")
          .show();
      });
  }

  podeMudarRota(): boolean {
    if (this.isReordering) {
      return confirm(
        "A ordem dos itens não foram salvas! Deseja sair mesmo assim?"
      );
    }
    return true;
  }

}
