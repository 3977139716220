import {Endereco} from "./endereco";
import {isNullOrUndefined} from "util";
import * as firebase from "firebase";
import Timestamp = firebase.firestore.Timestamp;

export class Localizacao {

  public latitude: number;
  public longitude: number;
  public fusoHorario: string;
  public endereco: Endereco = new Endereco();
  public lastUpdate: Timestamp;

  constructor(localizacao: any = {}) {
    if (!isNullOrUndefined(localizacao)) {
      this.latitude = localizacao.latitude;
      this.longitude = localizacao.longitude;
      this.endereco = new Endereco(localizacao.endereco);
      this.lastUpdate = localizacao.lastUpdate;
      this.fusoHorario = localizacao.fusoHorario;
    }
  }

  public toAny(): any {
    const localizacao: any = {};
    if (this.endereco) localizacao.endereco = this.endereco.toAny();
    if (this.latitude) localizacao.latitude = this.latitude;
    if (this.longitude) localizacao.longitude = this.longitude;
    if (this.lastUpdate) localizacao.lastUpdate = this.lastUpdate;
    if (this.fusoHorario) localizacao.fusoHorario = this.fusoHorario;
    return localizacao;
  }
}
