import {AgmCoreModule} from "@agm/core";
import {CommonModule, registerLocaleData} from "@angular/common";
import {MatGoogleMapsAutocompleteModule} from "@angular-material-extensions/google-maps-autocomplete";
import {HttpClientModule} from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, NgZone, PLATFORM_ID} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MAT_LABEL_GLOBAL_OPTIONS, MatRippleModule} from "@angular/material/core";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatIconModule} from "@angular/material/icon";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {StarRatingModule} from "angular-star-rating";
import {TextMaskModule} from "angular2-text-mask";
import {AngularFireModule} from "@angular/fire";
import {AngularFireAuthModule} from "@angular/fire/auth";
import {AngularFireDatabaseModule} from "@angular/fire/database";
import {AngularFirestoreModule} from "@angular/fire/firestore";
import {ImageCropperModule} from "ngx-image-cropper";
import {WebStorageModule} from "ngx-store";
import {environment} from "../environments/environment";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {AngularGoogleMapsComponent} from "./components/angular-google-maps/angular-google-maps.component";
import {AvaliacoesListComponent} from "./components/avaliacoes/avaliacoes-list/avaliacoes-list.component";
import {RespostaComponent} from "./components/avaliacoes/resposta/resposta.component";
import {BairroFormComponent} from "./components/bairro/bairro-form/bairro-form.component";
import {BairroListComponent} from "./components/bairro/bairro-list/bairro-list.component";
import {BairroService} from "./components/bairro/bairro.service";
import {CategoriaProdutoFormComponent} from "./components/categoria-produto/categoria-produto-form/categoria-produto-form.component";
import {CategoriaProdutoListComponent} from "./components/categoria-produto/categoria-produto-list/categoria-produto-list.component";
import {CategoriaProdutoService} from "./components/categoria-produto/categoria-produto.service";
import {CidadeDialogComponent} from "./components/cidade/cidade-dialog/cidade-dialog.component";
import {CidadeService} from "./components/cidade/cidade.service";
import {ComplementoProdutoFormComponent} from "./components/complemento-produto/complemento-produto-form/complemento-produto-form.component";
import {ComplementoProdutoListComponent} from "./components/complemento-produto/complemento-produto-list/complemento-produto-list.component";
import {ComplementoProdutoService} from "./components/complemento-produto/complemento-produto.service";
import {CozinhasFormComponent} from "./components/cozinhas/cozinhas-form/cozinhas-form.component";
import {CozinhasListComponent} from "./components/cozinhas/cozinhas-list/cozinhas-list.component";
import {DeliveryComponent} from "./components/delivery/delivery-form/delivery.component";
import {DeliveryService} from "./components/delivery/delivery.service";
import {EmpresaFormComponent} from "./components/empresa/empresa-form/empresa-form.component";
import {
  EmpresaListComponent,
  EmpresaListDialogComponent
} from "./components/empresa/empresa-list/empresa-list.component";
import {HorarioDiaComponent} from "./components/empresa/horario-dia/horario-dia.component";
import {SelectEmpresaDialogComponent} from "./components/empresa/select-empresa-dialog/select-empresa-dialog.component";
import {FormaPagamentoFormComponent} from "./components/forma-pagamento/forma-pagamento-form/forma-pagamento-form.component";
import {FormaPagamentoListComponent} from "./components/forma-pagamento/forma-pagamento-list/forma-pagamento-list.component";
import {FormaPagamentoService} from "./components/forma-pagamento/forma-pagamento.service";
import {ActionButtonsComponent} from "./components/gerenciador-pedidos/action-buttons/action-buttons.component";
import {GerenciadorPedidosComponent} from "./components/gerenciador-pedidos/gerenciador-pedidos/gerenciador-pedidos.component";
import {PedidoDetalhesDialogComponent} from "./components/gerenciador-pedidos/pedido-detalhes-dialog/pedido-detalhes-dialog.component";
import {TempoEntregaDialogComponent} from "./components/gerenciador-pedidos/tempo-entrega-dialog-component/tempo-entrega-dialog.component";
import {HomeComponent} from "./components/home/home.component";
import {PageNotFoundComponent} from "./components/page-not-found/page-not-found.component";
import {RelatorioPedidosEmpresaListComponent} from "./components/relatorio-pedidos-empresa/relatorio-pedidos-empresa-list/relatorio-pedidos-empresa-list.component";
import {PerfilFormComponent} from "./components/perfil-acesso/perfil-form/perfil-form.component";
import {PerfilListComponent} from "./components/perfil-acesso/perfil-list/perfil-list.component";
import {PerfilService} from "./components/perfil-acesso/perfil.service";
import {ComplementoItemService} from "./components/produto/complemento-item.service";
import {ComplementoProdutoListDialogComponent} from "./components/produto/complemento-produto-list-dialog/complemento-produto-list-dialog.component";
import {ComplementoFormComponent} from "./components/produto/complemento-produto/complemento-form/complemento-form.component";
import {ComplementoProdutoItemDialogComponent} from "./components/produto/complemento-produto/complemento-produto-dialog/complemento-produto-dialog.component";
import {ComplementoService} from "./components/produto/complemento.service";
import {ProdutoFormComponent} from "./components/produto/produto-form/produto-form.component";
import {ProdutoListComponent} from "./components/produto/produto-list/produto-list.component";
import {ProdutoService} from "./components/produto/produto.service";
import {SelectImpressoraDialogComponent} from "./components/select-impressora-dialog/select-impressora-dialog.component";
import {SettingsComponent} from "./components/settings/settings.component";
import {UsersFormComponent} from "./components/users/users-form/users-form.component";
import {UsersListComponent} from "./components/users/users-list/users-list.component";
import {UsersService} from "./components/users/users.service";
import {FormCanDeactivateGuard} from "./guards/form-canDeactivate.guard";
import {CovalentModule} from "./modules/covalent/covalent.module";
import {AuthGuard} from "./modules/login/auth.guard";
import {MaterialDesignerModule} from "./modules/material-designer/material-designer.module";
import {AccessControlGuard} from "./services/access-control.guard";
import {DialogService} from "./services/dialog/dialog.service";
import {ImageUploaderService} from "./services/image-uploader/image-uploader.service";
import {LoadingService} from "./services/loading/loading.service";
import {SnackService} from "./services/snack/snack.service";
import {PomStorageService} from "./services/storage/pom-storage.service";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {PedidoStepperStatusComponent} from "./components/pedido-stepper-status/pedido-stepper-status.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {EntregadoresFormComponent} from "./components/entregadores/entregadores-form/entregadores-form.component";
import {EntregadoresListComponent} from "./components/entregadores/entregadores-list/entregadores-list.component";
import {EntregadoresService} from "./components/entregadores/entregadores.service";
import {CentralEntregasComponent} from "./components/central-entregas/central-entregas/central-entregas.component";
import {CentralEntregasService} from "./components/central-entregas/central-entregas.service";
import {SelectEntregadorDialogComponent} from "./components/entregadores/select-entregador-dialog/select-entregador-dialog.component";
import {EntregasListComponent} from "./components/entregas/entregas-list/entregas-list.component";
import {EntregasService} from "./components/entregas/entregas.service";
import {MonitorPedidosComponent} from "./components/monitor-pedidos/monitor-pedidos/monitor-pedidos.component";
import {MonitorPedidosService} from "./components/monitor-pedidos/monitor-pedidos.service";
import {MessagingService} from "./services/messaging.service";
import {AngularFireMessagingModule} from "@angular/fire/messaging";
import {RelatorioPedidosGeralListComponent} from "./components/relatorio-pedidos-geral/relatorio-pedidos-geral-list/relatorio-pedidos-geral-list.component";
import {InactiveChipComponent} from "./components/inactive-chip/inactive-chip/inactive-chip.component";
import {AngularFireFunctionsModule, FunctionsRegionToken} from "@angular/fire/functions";
import {EntregasDetalhesDialogComponent} from "./components/entregas/entregas-detalhes-dialog/entregas-detalhes-dialog.component";
import {PedeQueChegaBairrosService} from "./components/delivery/pede-que-chega-bairros.service";
import {RelatorioComissoesListComponent} from "./components/relatorio-comissoes/relatorio-comissoes-list/relatorio-comissoes-list.component";
import {EmpresaAuthService} from "./components/empresa/empresa-auth.service";
import {UsersAuthService} from "./components/users/users-auth.service";
import {DeviceDetectorModule} from "ngx-device-detector";
import {CategoriaProdutoDialogComponent} from "./components/categoria-produto/categoria-produto-dialog/categoria-produto-dialog.component";
import {CategoriaProdutoFieldsComponent} from "./components/categoria-produto/categoria-produto-fields/categoria-produto-fields.component";
import {CozinhasFieldsComponent} from "./components/cozinhas/cozinhas-fields/cozinhas-fields.component";
import {CozinhasDialogComponent} from "./components/cozinhas/cozinhas-dialog/cozinhas-dialog.component";
import {DetalhesEmpresaDialogComponent} from "./components/gerenciador-pedidos/detalhes-empresa-dialog/detalhes-empresa-dialog.component";
import {FinanceiroListComponent} from "./components/financeiro/financeiro-list/financeiro-list.component";
import {FaturasListComponent} from "./components/faturas/faturas-list/faturas-list.component";
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {MatPaginatorIntlPt} from "../styles/components/material-designer/paginator-pt";
import {AbatimentosListComponent} from "./components/abatimentos/abatimentos-list/abatimentos-list.component";
import {AbatimentosFormComponent} from "./components/abatimentos/abatimentos-form/abatimentos-form.component";
import {AvatarModule} from "ngx-avatar";
import {EmpresaEnderecoDialogComponent} from "./components/empresa/empresa-endereco-dialog/empresa-endereco-dialog.component";
import {EmpresaEnderecoFormComponent} from "./components/empresa/empresa-endereco-form/empresa-endereco-form.component";
import {MatTreeModule} from "@angular/material/tree";
import {MatListModule} from "@angular/material/list";
import {GerarNovoInvoiceDialogComponent} from "./components/financeiro/gerar-novo-invoice-dialog/gerar-novo-invoice-dialog.component";
import {ImageBagdeComponent} from "./components/image-bagde/image-bagde.component";
import {GeradorDePdfService} from "./services/pdf/gerador-de-pdf.service";
import {InputAutocompleteCustomComponent} from "./components/custom-inputs/input-autocomplete-custom/input-autocomplete-custom.component";
import {ResumoDeEntregasDialogComponent} from "./components/central-entregas/resultados-entregas-dialog/resumo-de-entregas-dialog.component";
import {HorariosFuncionamentoDialogComponent} from "./components/gerenciador-pedidos/horarios-funcionamento-dialog/horarios-funcionamento-dialog.component";
import {EmpresaInadimplenteDialogComponent} from "./components/gerenciador-pedidos/empresa-inadimplente-dialog/empresa-inadimplente-dialog.component";
import {BlockEmpresaDialogComponent} from "./components/empresa/block-empresa-dialog/block-empresa-dialog.component";
import {MatSortModule} from "@angular/material/sort";
import {DarBaixaInvoiceDialogComponent} from "./components/financeiro/dar-baixa-invoice-dialog/dar-baixa-invoice-dialog.component";
import {GeradorDeTicketPedidoPdfService} from "./services/pdf/gerador-de-ticket-pedido-pdf.service";
import {DeliveryBairrosFormComponent} from "./components/delivery/delivery-bairros-form/delivery-bairros-form.component";
import {ImportarExportarBairros} from "./services/importar-exportar-bairros";
import {DeliveryBairrosImportDialogComponent} from "./components/delivery/delivery-bairros-import-dialog/delivery-bairros-import-dialog.component";
import {EmpresaTelefoneDialogComponent} from "./components/empresa/empresa-telefone-dialog/empresa-telefone-dialog.component";
import {SegmentoDialogComponent} from "./components/notificacoes/segmento-dialog/segmento-dialog.component";
import {SegmentoService} from "./services/segmento.service";
import {OneSignalService} from "./services/onesignal.service";
import {NotificacoesListComponent} from "./components/notificacoes/notificacoes-list/notificacoes-list.component";
import {NotificacoesFormComponent} from "./components/notificacoes/notificacoes-form/notificacoes-form.component";
import {PickerModule} from "@ctrl/ngx-emoji-mart";
import {ComissoesConsultoresComponent} from "./components/comissoes-consultores/comissoes-consultores.component";
import {GeradorPdfTotalizacaoService} from "./services/pdf/gerador-pdf-totalizacao.service";
import {GerarFaturaDialogComponent} from "./components/faturas/gerar-fatura-dialog/gerar-fatura-dialog.component";
import {InformarDataPagamentoDialogComponent} from "./components/comissoes-consultores/informar-data-pagamento-dialog/informar-data-pagamento-dialog.component";
import {FormaPagamentoOnlineFormComponent} from "./components/forma-pagamento/forma-pagamento-online-form/forma-pagamento-online-form.component";
import {ExtratoPagamentoOnlineListComponent} from "./components/extrato-pagamento-online/extrato-pagamento-online-list/extrato-pagamento-online-list.component";
import {ExtendedModule} from "@angular/flex-layout";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {ExtratoService} from "./services/extrato.service";
import {ExtratoDetalhesDialogComponent} from "./components/extrato-pagamento-online/extrato-detalhes-dialog/extrato-detalhes-dialog.component";
import {SaqueDialogComponent} from "./components/extrato-pagamento-online/saque-dialog/saque-dialog.component";
import {NgxFileDropModule} from "ngx-file-drop";
import {EnviarNfseDialogComponent} from "./components/faturas/enviar-nfse-dialog/enviar-nfse-dialog.component";
import {NotFoundBairrosDialogComponent} from "./components/delivery/not-found-bairros-dialog/not-found-bairros-dialog.component";
import {PagarEntregadorDialogComponent} from "./components/entregas/pagar-entregador-dialog/pagar-entregador-dialog.component";
import {
  AngularFirestoreSecondary,
  AngularFirestoreSecondaryFactory
} from "./services/firebase/angular-firestore-secondary";
import {NgxElectronModule} from "ngx-electron";
import {SharedModule} from "./modules/shared/shared.module";
import {MotivoRejeicaoDialogComponent} from "./components/gerenciador-pedidos/pedido-detalhes-dialog/motivo-rejeicao-dialog/motivo-rejeicao-dialog.component";
import {SubcontaFormComponent} from "./components/forma-pagamento/forma-pagamento-online-form/subconta-form/subconta-form.component";

registerLocaleData(localePt);

@NgModule({
  imports: [
    NgxElectronModule,
    AgmCoreModule.forRoot({
      apiKey: environment.google_maps_api_key,
      libraries: ["geometry", "places"],
    }),
    MatGoogleMapsAutocompleteModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireFunctionsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CovalentModule,
    MaterialDesignerModule,
    HttpClientModule,
    TextMaskModule,
    ImageCropperModule,
    WebStorageModule,
    DragDropModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatRippleModule,
    StarRatingModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    MatPaginatorModule,
    AvatarModule,
    MatTreeModule,
    MatListModule,
    MatSortModule,
    PickerModule,
    ExtendedModule,
    ScrollingModule,
    NgxFileDropModule,
    SharedModule,
  ],
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HomeComponent,
    UsersListComponent,
    UsersFormComponent,
    CategoriaProdutoListComponent,
    CategoriaProdutoFormComponent,
    EmpresaListComponent,
    EmpresaListDialogComponent,
    EmpresaFormComponent,
    ProdutoFormComponent,
    ProdutoListComponent,
    ComplementoProdutoFormComponent,
    ComplementoProdutoListComponent,
    ComplementoFormComponent,
    ComplementoProdutoListDialogComponent,
    ComplementoProdutoListDialogComponent,
    ComplementoProdutoItemDialogComponent,
    SelectEmpresaDialogComponent,
    TempoEntregaDialogComponent,
    FormaPagamentoListComponent,
    FormaPagamentoFormComponent,
    BairroListComponent,
    BairroFormComponent,
    CidadeDialogComponent,
    HorarioDiaComponent,
    DeliveryComponent,
    RelatorioPedidosEmpresaListComponent,
    GerenciadorPedidosComponent,
    PerfilListComponent,
    PerfilFormComponent,
    PedidoDetalhesDialogComponent,
    ActionButtonsComponent,
    SelectImpressoraDialogComponent,
    CozinhasListComponent,
    CozinhasFormComponent,
    AvaliacoesListComponent,
    RespostaComponent,
    SettingsComponent,
    AngularGoogleMapsComponent,
    PedidoStepperStatusComponent,
    EntregadoresFormComponent,
    EntregadoresListComponent,
    CentralEntregasComponent,
    SelectEntregadorDialogComponent,
    EntregasListComponent,
    MonitorPedidosComponent,
    RelatorioPedidosGeralListComponent,
    InactiveChipComponent,
    EntregasDetalhesDialogComponent,
    RelatorioComissoesListComponent,
    CategoriaProdutoDialogComponent,
    CategoriaProdutoFieldsComponent,
    CozinhasFieldsComponent,
    CozinhasDialogComponent,
    DetalhesEmpresaDialogComponent,
    DeliveryBairrosImportDialogComponent,
    FinanceiroListComponent,
    FaturasListComponent,
    AbatimentosListComponent,
    AbatimentosFormComponent,
    EmpresaEnderecoDialogComponent,
    EmpresaEnderecoFormComponent,
    GerarNovoInvoiceDialogComponent,
    ImageBagdeComponent,
    InputAutocompleteCustomComponent,
    ResumoDeEntregasDialogComponent,
    HorariosFuncionamentoDialogComponent,
    EmpresaInadimplenteDialogComponent,
    BlockEmpresaDialogComponent,
    DarBaixaInvoiceDialogComponent,
    EmpresaTelefoneDialogComponent,
    SegmentoDialogComponent,
    NotificacoesListComponent,
    NotificacoesFormComponent,
    DeliveryBairrosFormComponent,
    DeliveryBairrosImportDialogComponent,
    ComissoesConsultoresComponent,
    GerarFaturaDialogComponent,
    InformarDataPagamentoDialogComponent,
    FormaPagamentoOnlineFormComponent,
    ExtratoPagamentoOnlineListComponent,
    ExtratoDetalhesDialogComponent,
    SaqueDialogComponent,
    NotFoundBairrosDialogComponent,
    PagarEntregadorDialogComponent,
    EnviarNfseDialogComponent,
    MotivoRejeicaoDialogComponent,
    SubcontaFormComponent
  ],
  entryComponents: [
    SegmentoDialogComponent,
    ComplementoProdutoListDialogComponent,
    ComplementoProdutoItemDialogComponent,
    SelectEmpresaDialogComponent,
    TempoEntregaDialogComponent,
    CidadeDialogComponent,
    PedidoDetalhesDialogComponent,
    SelectImpressoraDialogComponent,
    SettingsComponent,
    EmpresaListDialogComponent,
    SelectEntregadorDialogComponent,
    EntregasDetalhesDialogComponent,
    CategoriaProdutoDialogComponent,
    CozinhasDialogComponent,
    DetalhesEmpresaDialogComponent,
    DeliveryBairrosImportDialogComponent,
    EmpresaEnderecoDialogComponent,
    ResumoDeEntregasDialogComponent,
    HorariosFuncionamentoDialogComponent,
    EmpresaInadimplenteDialogComponent,
    BlockEmpresaDialogComponent,
    GerarNovoInvoiceDialogComponent,
    DarBaixaInvoiceDialogComponent,
    EmpresaTelefoneDialogComponent,
    GerarFaturaDialogComponent,
    InformarDataPagamentoDialogComponent,
    ExtratoDetalhesDialogComponent,
    SaqueDialogComponent,
    NotFoundBairrosDialogComponent,
    EnviarNfseDialogComponent,
    PagarEntregadorDialogComponent,
    MotivoRejeicaoDialogComponent
  ],
  providers: [
    FormCanDeactivateGuard,
    SegmentoService,
    ImageUploaderService,
    SnackService,
    DialogService,
    LoadingService,
    UsersService,
    UsersAuthService,
    EmpresaAuthService,
    CategoriaProdutoService,
    ComplementoProdutoService,
    ComplementoService,
    ComplementoItemService,
    ProdutoService,
    FormaPagamentoService,
    BairroService,
    CidadeService,
    DeliveryService,
    PomStorageService,
    PerfilService,
    AuthGuard,
    AccessControlGuard,
    EntregadoresService,
    CentralEntregasService,
    EntregasService,
    MonitorPedidosService,
    MessagingService,
    PedeQueChegaBairrosService,
    GeradorDePdfService,
    GeradorDeTicketPedidoPdfService,
    OneSignalService,
    ImportarExportarBairros,
    GeradorPdfTotalizacaoService,
    InformarDataPagamentoDialogComponent,
    ExtratoService,
    // Displaying dates, number, percentages, and currencies in a local format.
    {provide: LOCALE_ID, useValue: "pt"},
    {provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: {float: "always"}},
    {provide: FunctionsRegionToken, useValue: "us-central1"},
    {provide: MatPaginatorIntl, useClass: MatPaginatorIntlPt},
    {provide: AngularFirestoreSecondary, deps: [PLATFORM_ID, NgZone], useFactory: AngularFirestoreSecondaryFactory},
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  exports: []
})

export class AppModule {
  constructor() {
  }
}
