import {Repository} from "./Repository";
import {AngularFirestore, AngularFirestoreCollection} from "@angular/fire/firestore";
import {AuthService} from "../modules/login/auth.service";
import {Criterion} from "../services/firebase/criteria/criterion";
import {PoMQuery} from "../services/firebase/criteria/query";
import {Observable} from "rxjs";
import {isNullOrUndefined} from "../utils/commons";
import {RecordByEmpresa} from "../models/record-by-empresa";
import {Empresa} from "../models/empresa";

export abstract class RepositoryByEmpresa<T extends RecordByEmpresa> extends Repository<T> {

  constructor(public db: AngularFirestore, public auth: AuthService, collectionName: string, requiredSerialize?: boolean) {
    super(db, collectionName, requiredSerialize);
  }

  getCollection(queryFn: PoMQuery = new PoMQuery(null)): AngularFirestoreCollection<T> {
    // Adicionar filtro por empresa
    const empresaCriterion = new EmpresaCriterion(this.auth.currentEmpresa);
    // Só adiciona o filtro de empresa, caso já não esteja recebendo o filtro
    if (queryFn && queryFn.containsCriterion(empresaCriterion)) {
      return super.getCollection(queryFn);
    } else {
      return super.getCollection(queryFn.addFirst(empresaCriterion));
    }
  }

  saveOrUpdate(item: T, keepId?: boolean, merge?: boolean): Observable<void> {
    // Verificar se tem uma empresa logada selecionada
    if ((isNullOrUndefined(this.auth.currentEmpresa) || isNullOrUndefined(this.auth.currentEmpresa.ref)) && !this.isAllowedRecordWithoutCompanie()) {
      throw new ReferenceError("Não foi possível encontrar a empresa logada!");
    }

    // Carregar a empresa logada no item
    if (!item["empresa"]) {
      if (this.auth.currentEmpresa) {
        item["empresa"] = this.auth.currentEmpresa.ref;
      } else {
        item["empresa"] = null;
      }
    }

    // Salvar ou Atualizar
    return super.saveOrUpdate(item, keepId, merge);
  }

  // Permite salvar registro sem uma empresa informada. Isso acontece com
  // registros que podem ser utilizados tanto de forma geral, quanto por empresa.
  // Ex.: Usuários, Perfis de acesso
  isAllowedRecordWithoutCompanie?(): boolean;

  handleCallableFunctionsError(err): Observable<any> {
    console.log(err);
    if (err.message === "internal") {
      throw Error("Desculpe, mas parece que você está off-line!");
    }
    throw err;
  }

}

export class EmpresaCriterion extends Criterion {
  constructor(private empresa: Empresa) {
    super("empresa", "==", empresa && empresa.ref ? empresa.ref : null);
  }
}
