import {Pedido} from "../../models/pedido.model";
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../../utils/commons";
import * as moment from "moment";
import {TipoPagamento} from "../../models/forma-pagamento.model";
import * as jsPDF from "jspdf";
import {applyPlugin} from "jspdf-autotable";
import {Injectable} from "@angular/core";
import {DialogService} from "../dialog/dialog.service";
import * as Bowser from "bowser";

applyPlugin(jsPDF);

@Injectable()
export class GeradorDePdfService {

  constructor(public dialogService: DialogService) {
  }

  gerarPdfRelatorio(pedidos, filtrosRelatorio) {

    if (isNullOrUndefined(pedidos) || pedidos.length === 0) {
      this.dialogService.messageDialog()
        .message("Nenhum registro encontrado!")
        .show();
    } else {

      const doc = new jsPDF("p", "mm", "a4");

      doc.setProperties({
        title: "Relatório de vendas"
      });

      // Adicionar logo no PDF
      const logoPedeMenu = new Image();
      logoPedeMenu.src = "../assets/img/logo_app.png";
      doc.addImage(logoPedeMenu, "PNG", 10, 9, 38, 9);

      // Cabeçalho
      doc.setFont("Helvetica");
      doc.setFontSize(16);
      doc.text(105, 15, "Relatório de Vendas", {align: "center"});
      doc.setDrawColor(220, 220, 220);
      doc.line(3, 38, 207, 38);

      doc.setFontSize(8);
      doc.setFontStyle("bold");
      doc.text("Empresa:", 10, 30);
      doc.setFontStyle("normal");
      if (isNullOrUndefined(filtrosRelatorio.empresaNome)) {
        doc.text("Todas", 24, 30);
      } else {
        doc.text(filtrosRelatorio.empresaNome, 24, 30);
      }
      doc.setFontStyle("bold");
      doc.text("Data inicial: ", 110, 30);
      doc.setFontStyle("normal");
      doc.text(filtrosRelatorio.dataInicialSimples, 127, 30);
      doc.setFontStyle("bold");
      doc.text("Data final: ", 160, 30);
      doc.setFontStyle("normal");
      doc.text(filtrosRelatorio.dataFinalSimples, 175, 30);

      // Contrução autotable

      // Estilo das colunas
      const _columnStyles = {
        0: {
          cellWidth: 15,
          halign: "left",
        },
        1: {
          cellWidth: 37,
          halign: "left",
        },
        2: {
          cellWidth: 37,
          halign: "left",
        },
        3: {
          cellWidth: 25,
          halign: "left",
        },
        4: {
          cellWidth: 25,
          halign: "left",
        },
        5: {
          cellWidth: 16,
          halign: "right",
        },
        6: {
          cellWidth: 16,
          halign: "right",
        },
        7: {
          cellWidth: 25,
          halign: "left"
        },
      };

      //Cabeçalho da Table
      doc.autoTable({
        startY: 41,
        theme: "plain",
        margin: {
          right: 8,
          left: 8
        },
        styles: {
          overflow: "linebreak",
          cellPadding: 1.5,
          fontSize: 8,
          fontStyle: "bold"
        },
        columnStyles: _columnStyles,
        body: [["Pedido", "Cliente", "Empresa", "Forma Pagto", "Data", "Entrega", "Valor", "Status"]],
      });

      //Formatar preço para BRL
      const formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      });

      //Inserindo os pedidos na table
      for (const _pedido of pedidos) {

        const finalY = doc.previousAutoTable.finalY;

        doc.autoTable({
          startY: finalY + 3,
          theme: "plain",
          showHead: "firstPage",
          margin: {
            right: 8,
            left: 8
          },
          styles: {
            overflow: "linebreak",
            cellPadding: 1.3,
            fontSize: 7,
            fontStyle: "normal"
          },
          columnStyles: _columnStyles,
          body: [[
            "#" + _pedido.numero,
            _pedido.cliente.nome,
            _pedido.empresa.nomeFantasia,
            this.tipoPagamentoToString(_pedido.formaPagamento.tipoPagamento),
            String(moment(_pedido.data.toDate()).format("DD/MM/YYYY HH:mm")),
            String(formatter.format(_pedido.valorEntrega)),
            String(formatter.format(_pedido.total)),
            _pedido.status
          ]],
        });
      }

      // Preenchimento de dados para table de resultados
      const data = [];
      const dataNome = [];
      let _cellWidth;
      let marginResultado;


      dataNome.push(pedidos.length > 1 ? "Pedidos" : "Pedido", "Produtos");
      data.push(String(pedidos.length), filtrosRelatorio.totalPreco);

      if (filtrosRelatorio.totalEntrega > 0) {
        dataNome.push("Delivery Próprio");
        data.push(formatter.format(filtrosRelatorio.totalEntrega));
      }

      if (filtrosRelatorio.totalPQC > 0) {
        if (isNotNullOrUndefined(filtrosRelatorio.valorEntregaLojista) && filtrosRelatorio.valorEntregaLojista > 0) {
          dataNome.push("Entrega paga pelo Cliente", "Entrega paga pela Empresa");
          data.push(formatter.format(filtrosRelatorio.valorEntregaCliente), formatter.format(filtrosRelatorio.valorEntregaLojista));
        } else {
          dataNome.push("Pede Que Chega");
          data.push(formatter.format(filtrosRelatorio.totalPQC));
        }
      }

      dataNome.push("PoMs", "Total");
      data.push(filtrosRelatorio.totalPoMs, filtrosRelatorio.totalValor);

      if (dataNome.length <= 5) {
        _cellWidth = 35;
        marginResultado = {left: 17.5, right: 17.5, top: 0, bottom: 0};
      } else if (dataNome.length === 6) {
        _cellWidth = 30;
        marginResultado = {left: 15, right: 15, top: 0, bottom: 0};
      } else {
        _cellWidth = 29;
        marginResultado = {left: 2, right: 2, top: 0, bottom: 0};
      }


      // Construção de tables de resultados
      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 8,
        theme: "plain",
        margin: marginResultado,
        styles: {
          overflow: "linebreak",
          cellPadding: 0,
          fontSize: 12.5,
          fontStyle: "bold",
          halign: "center",
          cellWidth: _cellWidth
        },
        body: [data]
      });
      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 1,
        theme: "plain",
        margin: marginResultado,
        styles: {
          overflow: "linebreak",
          cellPadding: 0,
          fontSize: 8.5,
          halign: "center",
          cellWidth: _cellWidth
        },
        body: [dataNome]
      });

      //Pegar posição final da autotable
      let posicaoResultado: number;
      if ((290 - doc.previousAutoTable.finalY) > 10) {
        posicaoResultado = doc.previousAutoTable.finalY;
      } else {
        doc.addPage();
        posicaoResultado = 0;
      }

      //Mostrar filtros
      doc.line(3, (posicaoResultado + 4), 207, (posicaoResultado + 4));
      doc.setFontSize(7);
      posicaoResultado += 9;
      doc.text("Tipo: " + filtrosRelatorio.tipoPedidos, 15, posicaoResultado);
      posicaoResultado += 3;
      doc.text("Status: " + filtrosRelatorio.statusPedidos, 15, posicaoResultado);
      posicaoResultado += 3;
      doc.text("Tipo Pagamento: " + filtrosRelatorio.tipoPagamento, 15, posicaoResultado);
      posicaoResultado += 3;
      if (isNotNullOrUndefined(filtrosRelatorio.cidadePedidos)) {
        doc.text("Cidade: " + String(filtrosRelatorio.cidadePedidos), 15, posicaoResultado);
        posicaoResultado += 3;
      }
      if (!isEmpty(filtrosRelatorio.numeroPedidos)) {
        doc.text("Pedido: " + String(filtrosRelatorio.numeroPedidos), 15, posicaoResultado);
      }

      // Contador de páginas
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFontSize(6);
      doc.setFontStyle("normal");
      for (let i = 0; i < pageCount; i++) {
        doc.setPage(i);
        doc.text("/", 105, 291, {align: "center"});
        doc.text(String(doc.internal.getCurrentPageInfo().pageNumber), 100, 291, {align: "center"});
        doc.text(String(pageCount), 110, 291, {align: "center"});
      }

      doc.setDocumentProperties({
        title: "Relatório de Vendas - Pede o Menu"
      });

      // Abrir PDF em nova guia
      const blob = doc.output("blob");
      window.open(URL.createObjectURL(blob));
    }
  }

  tipoPagamentoToString(tipoPagamento: TipoPagamento): string {
    switch (tipoPagamento) {
      case TipoPagamento.credito:
      case TipoPagamento.debito:
        return "Máquina Móvel";
      case TipoPagamento.dinheiro:
        return "Dinheiro";
      case TipoPagamento.pontos:
        return "Programa de Pontos";
      case TipoPagamento.online:
        return "Pagamento Online";
      default:
        return "";
    }
  }

}
