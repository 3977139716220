import {Observable} from "rxjs";
import {Pipe, PipeTransform} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {map} from "rxjs/operators";

@Pipe({
  name: "doc"
})
export class DocPipe implements PipeTransform {

  constructor(private db: AngularFirestore) {
  }

  transform(value: any): Observable<any> {
    return this.db.doc(value.path)
      .snapshotChanges()
      .pipe(
        map(doc => {
          return doc.payload.data();
        })
      );
  }
}
