import {ChangeDetectorRef, Component, EventEmitter} from "@angular/core";
import {ComplementoProduto, ComplementosProdutoByGrupo} from "../../../models/complemento-produto.model";
import {ComplementoProdutoService} from "../complemento-produto.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";
import {PageService} from "../../../services/page.service";
import {map, mergeMap} from "rxjs/operators";

@Component({
  selector: "app-complemento-produto-list",
  templateUrl: "./complemento-produto-list.component.html",
  styleUrls: ["./complemento-produto-list.component.scss"]
})
export class ComplementoProdutoListComponent extends PageService<ComplementoProduto> {
  complementosByGrupos$: Observable<ComplementosProdutoByGrupo[]>;

  constructor(public service: ComplementoProdutoService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/complementos-produto/");
    this.complementosByGrupos$ = this.service.getComplementosProdutoByGrupo().pipe(
      mergeMap(complementos => {
        return this.afterItemRemovedObservable.pipe(map(idItemRemoved => {
          if (idItemRemoved) {
            for (const group of complementos) {
              for (const _complemento of group.complementos) {
                if (_complemento.id === idItemRemoved) {
                  const indexOf = group.complementos.indexOf(_complemento);
                  if (indexOf > -1) {
                    group.complementos.splice(indexOf, 1);
                  }
                }
              }
            }
          }
          return complementos;
        }));
      })
    );
  }

  ativarOuInativar(event: EventEmitter<any>, item: ComplementoProduto) {
    (this.service as ComplementoProdutoService).changeInactive(item)
      .subscribe(() => {
        if (event) event.next(true);
      }, err => {
        if (event) event.next(false);
        this.dialogService
          .messageDialog()
          .title("Atenção")
          .message(err)
          .show();
      });
  }

  newItem(): ComplementoProduto {
    return new ComplementoProduto();
  }

}
