import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ComplementoItem } from "../../../../models/produto.model";
import { isNullOrUndefined } from "../../../../utils/commons";

@Component({
  selector: "app-complemento-produto-item-dialog",
  templateUrl: "./complemento-produto-item-dialog.component.html",
  styleUrls: ["./complemento-produto-item-dialog.component.scss"],
})
export class ComplementoProdutoItemDialogComponent {
  form: FormGroup;

  constructor(
    formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ComplementoProdutoItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public item: ComplementoItem
  ) {
    this.form = formBuilder.group({
      nome: [
        "",
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
      descricao: [""],
      preco: ["", Validators.required],
      quantidadeMaxima: ["", Validators.required],
      codigoPDV: [""],
      inactive: [false],
    });

    if (isNullOrUndefined(this.item)) {
      this.item = new ComplementoItem();
    } else {
      // Se tiver um complemento informado, significa que o
      // item é originado de um complemento pré cadastrado. Então,
      // não permite alterar os dados padrões
      if (this.item.complementoProduto) {
        this.form.get("nome").disable();
        this.form.get("descricao").disable();
        this.form.get("codigoPDV").disable();
      }
    }
    this.form.patchValue(this.item);
    this.dialogRef.disableClose = false;
  }

  confirmar() {
    Object.keys(this.form.value).forEach((key) => {
      this.item[key] = this.form.value[key];
    });
    this.dialogRef.close(this.item);
  }
}
