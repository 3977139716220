import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../utils/commons";
import {RecordByEmpresa} from "./record-by-empresa";
import {CategoriaProduto} from "./categoria.model";
import {Exclude} from "class-transformer";
import * as firebase from "firebase";
import {Cozinha} from "./cozinha.model";
import DocumentReference = firebase.firestore.DocumentReference;
import {StringUtils} from "../utils/string-utils";

export class Produto extends RecordByEmpresa {
  public nome: string;
  public nomeNormalized: string;
  public descricao: string;
  public preco: number = 0;
  public bannerUrl: string;
  public categoria: CategoriaProduto;
  public cozinha: Cozinha;
  public complementos: Complemento[];
  public complementosRemoved: Complemento[];
  public inactive: boolean = false;
  public codigoPDV: string;
  public sequence: number;
  public promocao: DadosPromocao;
  public arquivado: boolean;

  constructor(fsProduto?: any) {
    super();
    this.complementos = [];
    this.complementosRemoved = [];

    if (isNotNullOrUndefined(fsProduto)) {
      this.id = fsProduto.id;
      this.ref = fsProduto.ref;
      this.empresa = fsProduto.empresa;
      this.nome = fsProduto.nome;
      this.nomeNormalized = fsProduto.nomeNormalized;
      this.descricao = fsProduto.descricao;
      this.preco = fsProduto.preco;
      this.bannerUrl = fsProduto.bannerUrl;
      this.categoria = fsProduto.categoria;
      this.inactive = !isEmpty(fsProduto.inactive) ? fsProduto.inactive : false;
      this.cozinha = fsProduto.cozinha ? new Cozinha(fsProduto.cozinha) : null;
      this.sequence = fsProduto.sequence;
      this.codigoPDV = fsProduto.codigoPDV;
      if (!isEmpty(fsProduto.complementos)) {
        fsProduto.complementos.forEach((it) =>
          this.addComplemento(new Complemento(it))
        );
      }
      this.promocao = !isEmpty(fsProduto.promocao) ? new DadosPromocao(fsProduto.promocao) : null;
      this.arquivado = !isEmpty(fsProduto.arquivado) ? fsProduto.arquivado : false;
    }
  }

  public makeNormalizedNomeProduto(nomeProduto: string): string {
    return StringUtils.normalize(nomeProduto).toLowerCase();
  }

  addComplemento(complemento: Complemento): Produto {
    // Verificar se o complemento já foi incluído
    if (!this.containsComplemento(complemento)) {
      this.complementos.push(complemento);
    } else {
      this.complementos = this.complementos.map((comp: Complemento) => {
        return comp.id === complemento.id ? complemento : comp;
      });
    }
    return this;
  }

  containsComplemento(complemento: Complemento): boolean {
    return this.complementos.some((it) => it.id === complemento.id);
  }

  removeComplemento(complemento: Complemento): Produto {
    this.complementos = this.complementos.filter(
      (i) => i.id !== complemento.id
    );
    this.complementosRemoved.push(complemento);
    return this;
  }
}

export class DadosPromocao {
  public id: string;
  public limite: number;
  public tipoDesconto: string;
  public valor: number;
  public quantidadeComprada: number;

  constructor(dadosPromocao: any) {
    this.id = dadosPromocao.id;
    this.limite = dadosPromocao.limite;
    this.tipoDesconto = dadosPromocao.tipoDesconto;
    this.valor = dadosPromocao.valor;
    this.quantidadeComprada = dadosPromocao.quantidadeComprada;
  }
}

export class Complemento {
  id: string;
  titulo: string;
  regraTotalizacao: RegraTotalizacao;
  quantidadeMinima: number;
  quantidadeMaxima: number;
  sequence: number;
  naoAplicaDesconto: boolean = false;
  inactive: boolean = false;

  @Exclude()
  itens: ComplementoItem[] = [];
  @Exclude()
  itensRemoved: ComplementoItem[] = [];

  constructor(fsComplemento: any = {}) {
    this.id = fsComplemento.id;
    this.titulo = fsComplemento.titulo;
    this.quantidadeMinima = isNotNullOrUndefined(fsComplemento.quantidadeMinima)
      ? fsComplemento.quantidadeMinima
      : 1;
    this.quantidadeMaxima = isNotNullOrUndefined(fsComplemento.quantidadeMaxima)
      ? fsComplemento.quantidadeMaxima
      : 1;
    this.regraTotalizacao = fsComplemento.regraTotalizacao;
    this.sequence = fsComplemento.sequence;
    this.naoAplicaDesconto = fsComplemento.naoAplicaDesconto || false;
    this.inactive = fsComplemento.inactive || false;
    this.itens = [];
    if (!isEmpty(fsComplemento.itens)) {
      fsComplemento.itens.forEach((it) =>
        this.itens.push(new ComplementoItem(it))
      );
    }
    this.itensRemoved = [];
    if (!isEmpty(fsComplemento.itensRemoved)) {
      fsComplemento.itensRemoved.forEach((it) =>
        this.itensRemoved.push(new ComplementoItem(it))
      );
    }
  }

  getComplementoTotalValue(itemQuantidade: number): number {
    let total = 0;
    if (this.regraTotalizacao === RegraTotalizacao.Soma) {
      this.itens.forEach(item => {
        total = total + (item.preco * item.quantidade) * itemQuantidade;
      });
    }
    if (this.regraTotalizacao === RegraTotalizacao.Maior) {
      this.itens.forEach(item => {
        if ((item.preco * item.quantidade * itemQuantidade) > total) total = item.preco * item.quantidade * itemQuantidade;
      });
    }
    if (this.regraTotalizacao === RegraTotalizacao.Media) {
      this.itens.forEach(item => {
        total = total + item.preco * item.quantidade;
      });
      total = (total / this.itens.length) * itemQuantidade;
    }
    return total;
  }

  get regraTotalizacaoDesc(): string {
    return regraTotalizacaoToString(this.regraTotalizacao);
  }
}

export class ComplementoItem {
  id: string;
  nome: string;
  descricao: string;
  preco: number;
  quantidadeMaxima: number;
  sequence: number;
  inactive: boolean = false;
  codigoPDV: string;

  // Quantidade informada no mobile (ao fazer um pedido)
  quantidade: number;

  // Relacionamento com o complemento de produto
  complementoProduto: DocumentReference;

  constructor(item: any = {}) {
    if (isNullOrUndefined(item)) {
      item = {};
    }
    this.id = item.id;
    this.nome = item.nome;
    this.descricao = item.descricao;
    this.preco = item.preco ? item.preco : 0;
    this.codigoPDV = item.codigoPDV;
    this.quantidade = item.quantidade;
    this.quantidadeMaxima = isNotNullOrUndefined(item.quantidadeMaxima)
      ? item.quantidadeMaxima
      : 1;
    this.sequence = item.sequence;
    this.complementoProduto = item.complementoProduto;
    this.inactive = item.inactive ? item.inactive : false;
  }
}

export enum RegraTotalizacao {
  Soma = "soma",
  Media = "media",
  Maior = "maior",
}

export function regraTotalizacaoToString(regra: RegraTotalizacao) {
  switch (regra) {
    case RegraTotalizacao.Maior:
      return "Maior";
    case RegraTotalizacao.Media:
      return "Média";
    case RegraTotalizacao.Soma:
      return "Soma";
  }
}
