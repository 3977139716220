import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {FormControl} from "@angular/forms";
import {PedeQueChega, StatusPedeQueChega} from "../../../models/pedequechega.model";
import {EntregadoresService} from "../../entregadores/entregadores.service";
import {Entregador} from "../../../models/entregador.model";
import {finalize, map, mergeMap} from "rxjs/operators";
import {Observable} from "rxjs";
import * as moment from "moment";
import {$query} from "../../../services/firebase/criteria/query";
import {Criterion} from "../../../services/firebase/criteria/criterion";
import {isNotNullOrUndefined} from "../../../utils/commons";
import {EntregasService} from "../entregas.service";
import {PageService} from "../../../services/page.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {SnackService} from "../../../services/snack/snack.service";
import {ActivatedRoute, Router} from "@angular/router";
import {EntregasDetalhesDialogComponent} from "../entregas-detalhes-dialog/entregas-detalhes-dialog.component";
import {PagarEntregadorDialogComponent} from "../pagar-entregador-dialog/pagar-entregador-dialog.component";
import {Role} from "../../../models/perfil-acesso.model";
import {PerfilService} from "../../perfil-acesso/perfil.service";
import {EmpresaService} from "../../empresa/empresa.service";
import {Empresa} from "../../../models/empresa";

@Component({
  selector: "app-entregas-list",
  templateUrl: "./entregas-list.component.html",
  styleUrls: ["./entregas-list.component.scss"]
})
export class EntregasListComponent extends PageService<PedeQueChega> implements OnInit {
  public TipoExibicao = TipoExibicao;
  public StatusPedeQueChega = StatusPedeQueChega;
  public Role = Role;
  public todos = "Todos";
  public exibicao: string = TipoExibicao[TipoExibicao.ANALITICO];
  public currentStatus: string = StatusPedeQueChega[StatusPedeQueChega.APROVADO];
  public empresaSelected: Empresa;
  public exibicaoControl = new FormControl({value: TipoExibicao[TipoExibicao.ANALITICO]});
  public dataInicialControl = new FormControl({value: new Date()});
  public dataFinalControl = new FormControl({value: new Date()});
  public entregadorControl = new FormControl();
  public statusControl = new FormControl({value: StatusPedeQueChega[StatusPedeQueChega.APROVADO]});
  public entregasArray: PedeQueChega[];
  public entregadores: Observable<Entregador[]>;
  public entregasObservable: Observable<any[]>;
  public disablePagarButton = true;
  public entregador: string;
  public dataInicial: string;
  public dataFinal: string;
  public valorLiquido: number = 0;
  public valorPago: number = 0;

  constructor(service: EntregasService,
              dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              entregadoresService: EntregadoresService,
              public perfil: PerfilService,
              public empresaService: EmpresaService) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/entregas/");
    this.entregadores = entregadoresService.col$($query(new Criterion("inactive", "==", false)));

    const filters = service.getFilters();
    if (filters) {
      // Carregar os filtros para os campos
      this.exibicaoControl.setValue(filters.exibicao ? filters.exibicao : TipoExibicao[TipoExibicao.ANALITICO]);
      this.dataInicialControl.setValue(filters.dataInicial ? filters.dataInicial : new Date());
      this.dataFinalControl.setValue(filters.dataFinal ? filters.dataFinal : new Date());
      this.entregadorControl.setValue(filters.entregador ? filters.entregador : this.todos);
      this.statusControl.setValue(filters.tipo ? filters.tipo : StatusPedeQueChega[StatusPedeQueChega.APROVADO]);
    }

    this.buscarEntregas();
  }

  ngOnInit() {
    // Quando o tipo de exibição for "SINTETICO", selcionar automaticamento o status "APROVADO"
    this.exibicaoControl.valueChanges.subscribe(value => {
      if (value === TipoExibicao[TipoExibicao.SINTETICO]) {
        this.statusControl.patchValue(StatusPedeQueChega[StatusPedeQueChega.APROVADO]);
      }
    });
  }


  public open(entrega: PedeQueChega) {
    if (!this.flagMenuTrigger) {
      EntregasDetalhesDialogComponent.showDialog(this.dialog, entrega);
    }
    this.flagMenuTrigger = false;
  }

  public buscarEntregas() {
    this.loading(true);
    this.disablePagarButton = true;

    // Filtros
    const exibicao = this.exibicaoControl.value;
    const dataInicial = moment(this.dataInicialControl.value).startOf("day").toDate();
    const dataFinal = moment(this.dataFinalControl.value).endOf("day").toDate();
    const entregador = this.entregadorControl.value;
    const status = this.statusControl.value;

    // Variáveis para mandar para o dialog de pagar
    this.entregador = (isNotNullOrUndefined(entregador) && entregador !== this.todos) ? entregador : null;
    this.dataInicial = dataInicial.toLocaleDateString();
    this.dataFinal = dataFinal.toLocaleDateString();
    this.exibicao = exibicao;
    this.currentStatus = status;

    // Salva os filtros no service
    (this.service as EntregasService).setFilters(exibicao, this.empresaSelected, dataInicial, dataFinal, entregador, status);

    // Montar o filtro
    const filters = $query(new Criterion("data", ">=", dataInicial), new Criterion("data", "<=", dataFinal));
    if (isNotNullOrUndefined(entregador) && entregador !== this.todos) {
      filters.add(new Criterion("entregador.id", "==", entregador.id));
    }
    if (status !== this.todos) {
      filters.add(new Criterion("status", "==", status));
    }
    if (isNotNullOrUndefined(this.empresaSelected) && exibicao === TipoExibicao[TipoExibicao.ANALITICO]) {
      filters.add(new Criterion("pedido.empresa.id", "==", this.empresaSelected.id));
    }

    // Preenche os valores da totalização
    const montarTotalizacao = (entregas) => {
      if (status === StatusPedeQueChega[StatusPedeQueChega.APROVADO] || status === StatusPedeQueChega[StatusPedeQueChega.PAGO] || status === this.todos) {
        this.valorLiquido = entregas.filter(ent => ent.status === StatusPedeQueChega[StatusPedeQueChega.APROVADO])
          .reduce((a, b) => {
            return a + (isNotNullOrUndefined(b.valor) ? b.valor : 0);
          }, 0);
        this.valorPago = entregas.filter(ent => ent.status === StatusPedeQueChega[StatusPedeQueChega.PAGO])
          .reduce((a, b) => {
            return a + (isNotNullOrUndefined(b.valor) ? b.valor : 0);
          }, 0);
      } else {
        this.valorLiquido = 0;
        this.valorPago = 0;
      }
      this.entregasArray = entregas;
    };

    // Buscar lista de entregas (Exibição Analítica)
    if (this.exibicaoControl.value === TipoExibicao[TipoExibicao.ANALITICO]) {
      this.entregasObservable = (this.service as EntregasService).col$(filters)
        .pipe(
          map(entregas => {
            montarTotalizacao(entregas);
            this.disablePagarButton = !(isNotNullOrUndefined(entregador) && entregador !== this.todos &&
              entregas.some(ent => ent.status === StatusPedeQueChega[StatusPedeQueChega.APROVADO])
            );
            return entregas;
          }),
          finalize(() => this.loading(false))
        );
    }
    // Buscar lista de entregadores (Exibição Sintética)
    if (this.exibicaoControl.value === TipoExibicao[TipoExibicao.SINTETICO]) {
      this.entregasObservable = this.entregadores.pipe(mergeMap((entregadores) => {
        return (this.service as EntregasService).col$(filters).pipe(map((entregas) => {
          montarTotalizacao(entregas);
          const results = [];
          for (const ent of entregadores) {
            const entregasEntregador = entregas.filter(entrega => isNotNullOrUndefined(entrega.entregador) && entrega.entregador.id === ent.id);
            if (entregasEntregador.length > 0) {
              results.push({
                entregador: ent,
                entregas: entregasEntregador,
                valor: entregasEntregador.reduce((prev, curr) => prev + curr.valor, 0),
                status: status
              });
            }
          }
          return results;
        }));
      }), finalize(() => this.loading(false)));
    }
  }

  protected newItem(): PedeQueChega {
    return undefined;
  }

  public openPagarDialog(entregador?: string, valor?: number, entregas?: PedeQueChega[]) {
    PagarEntregadorDialogComponent.showDialog(this.dialog, {
      entregador: isNotNullOrUndefined(entregador) ? entregador : this.entregador,
      dataInicial: this.dataInicial,
      dataFinal: this.dataFinal,
      total: isNotNullOrUndefined(valor) ? valor : this.valorLiquido,
      entregas: isNotNullOrUndefined(entregas) ? entregas : this.entregasArray.filter(entrega => entrega.status === StatusPedeQueChega[StatusPedeQueChega.APROVADO])
    }).subscribe(val => {
      if (val) {
        this.buscarEntregas();
      }
    });
  }

  public compareWith(i1: Entregador, i2: Entregador): boolean {
    if (isNotNullOrUndefined(i1) && isNotNullOrUndefined(i2)) {
      return i1.id === i2.id;
    } else {
      return false;
    }
  }

  public receiveEmpresa(empresa): void {
    this.empresaSelected = empresa;
  }
}

enum TipoExibicao {
  ANALITICO = "ANALITICO",
  SINTETICO = "SINTETICO"
}
