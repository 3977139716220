import {Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {Observable, of, Subscription} from "rxjs";
import {isNullOrUndefined} from "../../../../../utils/commons";
import {mergeMap, startWith, tap} from "rxjs/operators";

@Component({
  selector: "app-input-chip-autocomplete",
  templateUrl: "./input-chip-autocomplete.component.html",
  styleUrls: ["./input-chip-autocomplete.component.scss"],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputChipAutocompleteComponent),
    multi: true
  }]
})
export class InputChipAutocompleteComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input()
  autocompleteSources$: Observable<any[]>;
  autocompleteSourcesSubscription: Subscription;
  autoCompleteSouces: any[];

  /****
   * Exemplo do caminho:
   * {
   *   a: {
   *     b: {
   *       foo: "bar"
   *     }
   *   }
   * }
   *
   * Caminho para acessar o campo foo: ["a", "b", "foo"]
   ****/

    // Caminho do valor que vai ser retornado para o array do input
  @Input()
  returnValuePath: string[];

  // Caminho do valor que será exibido nos chips do input
  @Input()
  viewValuePath: string[];

  @Input()
  placeholder: string;

  @Input()
  formControlName: string;

  // Observable com o array dos valores já selecionados no input
  @Input()
  initialSelectedSources$: Observable<any[]>;

  @Output()
  onInit: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  finishLoading: EventEmitter<any> = new EventEmitter<any>();

  // Array que armazena os objetos das opções selecionadas
  selectedSources: any[] = [];

  chipCtrl = new FormControl();
  control?: AbstractControl;

  @ViewChild("chipInput", {static: false}) chipInput: ElementRef<HTMLInputElement>;

  constructor() {
  }

  accessField(value: any, path: string[]): any {
    if (path) {
      return path.reduce((o, n) => o[n], value);
    } else {
      return value;
    }
  }

  ngOnInit() {
    this.onInit.emit();
    if (isNullOrUndefined(this.autocompleteSources$)) {
      throw new Error("Attribute 'autocompleteSource$' is required");
    }

    this.autocompleteSourcesSubscription = this.autocompleteSources$.pipe(
      tap((autoCompleteSources: any[]) => {
        this.autoCompleteSouces = autoCompleteSources;
      }),
      mergeMap((autoCompleteSources: any[]) => {
        return this.initialSelectedSources$.pipe(
          mergeMap((initialSelectedSources: any[]) => {
            this.selectedSources = initialSelectedSources;
            return of(autoCompleteSources);
          })
        );
      }),
      mergeMap((autoCompleteSources: any[]) => {
        return this.chipCtrl.valueChanges.pipe(
          startWith({}),
          tap(() => {
            this.autoCompleteSouces = autoCompleteSources.filter((autoCompleteSource: any) => {
              return this.selectedSources.every((selectedSource: any) => selectedSource.id !== autoCompleteSource.id);
            });
          })
        );
      })
    ).subscribe(() => {
      this.finishLoading.emit();
    });
  }

  /**
   * Funções do input com os chips e o autocomplete
   * **/

  // Remove a opção selecionada dos chips no input
  remove(item: any): void {
    const index = this.selectedSources.indexOf(item);

    if (index >= 0) {
      this.selectedSources.splice(index, 1);
    }

    this.chipCtrl.setValue(null);

    this.writeValue(this.selectedSources);
  }

  // Seleciona uma opção e adiciona o chip
  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedSources.push(event.option.value);
    this.chipInput.nativeElement.value = "";
    this.chipCtrl.setValue(null);

    this.writeValue(this.selectedSources);
  }

  /***
   * Funções do ControlValueAcessor
   ***/
  onChange: any = () => {
  }
  onTouch: any = () => {
  }

  val = []; // this is the updated value that the class accesses
  set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    if (val) {
      val = val.map(_val => this.accessField(_val, this.returnValuePath));
    }
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }

  // this method sets the value programmatically
  writeValue(value: any) {
    this.value = value;
  }

  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  ngOnDestroy(): void {
    if (this.autocompleteSourcesSubscription) {
      this.autocompleteSourcesSubscription.unsubscribe();
    }
  }
}
