import {ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Delivery, TipoDelivery} from "../../../models/delivery.model";
import {isEmpty, isNotNullOrUndefined} from "../../../utils/commons";
import {DeliveryBairro} from "../../../models/delivery-bairro.model";
import {DeliveryService} from "../delivery.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {PerfilService} from "../../perfil-acesso/perfil.service";
import {Role} from "../../../models/perfil-acesso.model";
import {finalize, mergeMap, pairwise, tap} from "rxjs/operators";
import {EmpresaService} from "../../empresa/empresa.service";
import {AuthService} from "../../../modules/login/auth.service";
import {isNullOrUndefined} from "util";
import {PedeQueChegaBairrosService} from "../pede-que-chega-bairros.service";
import {UserType} from "../../../models/appUser";
import {MatDialog} from "@angular/material";
import {SnackService} from "../../../services/snack/snack.service";
import {Funcionamento} from "../../../models/funcionamento";
import * as moment from "moment";
import {DeliveryBairrosFormComponent} from "../delivery-bairros-form/delivery-bairros-form.component";
import {Observable} from "rxjs";
import {Bairro} from "../../../models/bairro.model";
import {BairroService} from "../../bairro/bairro.service";

@Component({
  selector: "app-delivery",
  templateUrl: "./delivery.component.html",
  styleUrls: ["./delivery.component.scss"]
})
export class DeliveryComponent implements OnInit {

  @ViewChild(DeliveryBairrosFormComponent, {static: false})
  private deliveryBairrosFormComponent: DeliveryBairrosFormComponent;

  readonly tiposDelivery = [
    {
      tipo: TipoDelivery[TipoDelivery.pede_que_chega],
      display: "Pede que Chega"
    },
    {
      tipo: TipoDelivery[TipoDelivery.gratis],
      display: "Grátis"
    },
    {
      tipo: TipoDelivery[TipoDelivery.valor_fixo],
      display: "Valor Fixo"
    }
  ];

  form: FormGroup;
  loadingName: string = "DeliveryComponent";
  delivery: Delivery = new Delivery();

  // Delivery ainda não configurado
  deliveryNotSetting: boolean = true;

  // Permissões para a configuração do delivery
  settingPermission: boolean = false;
  settingPermissionTooltip: string;
  updatePermission: boolean = false;
  updatePermissionTooltip: string;

  // Quando o registro é salvo, desabilita o botão de salvar
  saved = false;
  saving = false;
  disableSave = false;
  TipoDelivery = TipoDelivery;

  // Botão de porcentagem/valor de lojista
  lojistaIsPercent: boolean = false;
  disableLojistaButton: boolean = false;

  bairrosDelivery: DeliveryBairro[] = [];

  // Array para comparar com nova configurações de bairros que serão salvas
  bairrosInicial: Bairro[] = [];

  isNullOrUndefined = isNullOrUndefined;

  constructor(private service: DeliveryService,
              private empresaService: EmpresaService,
              private perfil: PerfilService,
              private formBuilder: FormBuilder,
              private loading: LoadingService,
              private changeDetector: ChangeDetectorRef,
              private dialogService: DialogService,
              private dialog: MatDialog,
              public auth: AuthService,
              private pqcBairrosService: PedeQueChegaBairrosService,
              private snackService: SnackService,
              private bairroService: BairroService) {

    this.form = this.formBuilder.group({
      [TipoDelivery[TipoDelivery.pede_que_chega]]: this.formBuilder.group({
        valorLojista: [0, [Validators.required, Validators.min(0)]],
        valorMinimoDePedido: [0, [Validators.required, Validators.min(0)]],
        valorLojistaIsPercent: [this.lojistaIsPercent]
      }),
      [TipoDelivery[TipoDelivery.gratis]]: this.formBuilder.group({
        valor: [""],
        valorMinimoDePedido: [""]
      }),
      [TipoDelivery[TipoDelivery.valor_fixo]]: this.formBuilder.group({
        valor: [""],
        valorMinimoDePedido: [""],
        valorPedidoEntregaGratis: [""],
      }),
      tipo: ["", [Validators.required]],
      buscarNoBalcao: [false]
    });

    this.tiposDelivery = this.removePedeQueChega();
    this.disableFormToLojista();
  }

  // Resetar posição da lista de bairros para não bugar o virtual scroll
  tabChange() {
    if (isNotNullOrUndefined(this.deliveryBairrosFormComponent.virtualView)) {
      this.deliveryBairrosFormComponent.virtualView.scrollToIndex(0);
    }
  }

  disableFormToLojista() {
    if (this.userType === UserType[UserType.Lojista]) {
      this.form.get(TipoDelivery[TipoDelivery.pede_que_chega]).disable();
    }
  }

  disableRadioButton(tipo): boolean {
    if (this.auth.user.isLojista()) {
      if (tipo === TipoDelivery[TipoDelivery.pede_que_chega]) {
        return this.form.get("tipo").value !== TipoDelivery[TipoDelivery.pede_que_chega];
      } else {
        return this.form.get("tipo").value === TipoDelivery[TipoDelivery.pede_que_chega];
      }
    }
    return false;
  }

  get userType() {
    return this.auth.user.userType;
  }

  ngOnInit() {
    this.loading.register(this.loadingName);

    // Carregar as permissões
    this.perfil.getPerfilAcessoLoggedUser()
      .pipe(
        tap(perfil => {
          // Carregar as permissões
          this.settingPermission = perfil.hasRole(Role.DeliveryCreate);
          this.settingPermissionTooltip = !this.settingPermission ? "Você não tem permissão para configurar o delivery" : undefined;
          this.updatePermission = perfil.hasRole(Role.DeliveryUpdate);
          this.updatePermissionTooltip = !this.updatePermission ? "Você não tem permissão para alterar as configurações de delivery" : undefined;
        }),
        // Carregar o delivery salvo
        mergeMap(() => this.service.getDelivery()),
        tap(delivery => {
          this.deliveryNotSetting = !(delivery && delivery.id);
          if (!delivery.id) {
            // Se for um registro novo, então ignora as permissões de atualização de registros
            this.updatePermission = true;
            this.updatePermissionTooltip = undefined;
          }
        })
      )
      .subscribe(delivery => {
        this.delivery = delivery;
        this.bairrosDelivery = delivery.bairros;
        this.form.patchValue(this.delivery);
        this.form.get(TipoDelivery[TipoDelivery.pede_que_chega]).patchValue(this.delivery);
        this.form.get(TipoDelivery[TipoDelivery.gratis]).patchValue(this.delivery);
        this.form.get(TipoDelivery[TipoDelivery.valor_fixo]).patchValue(this.delivery);
        this.lojistaIsPercent = this.form.get(TipoDelivery[TipoDelivery.pede_que_chega]).get("valorLojistaIsPercent").value;
        this.loading.timeOut(this.loadingName);
      });

    this.form.get("tipo").valueChanges
      .subscribe(tipo => {
        if (!this.hasTipoSelected()) {
          this.form.get(TipoDelivery[TipoDelivery.pede_que_chega]).disable();
          this.form.get(TipoDelivery[TipoDelivery.valor_fixo]).disable();
          this.form.get(TipoDelivery[TipoDelivery.gratis]).disable();
          this.disableLojistaButton = true;
        }
        if (tipo === TipoDelivery[TipoDelivery.pede_que_chega]) {
          this.disableFormGroups(TipoDelivery[TipoDelivery.gratis], TipoDelivery[TipoDelivery.valor_fixo], TipoDelivery[TipoDelivery.pede_que_chega]);
          this.delivery.valorMinimoDePedido = this.setZeroToFieldUndefined(this.delivery.valorMinimoDePedido);
          this.delivery.valorLojista = this.setZeroToFieldUndefined(this.delivery.valorLojista);
          this.form.get(TipoDelivery[TipoDelivery.pede_que_chega]).patchValue(this.delivery);
          this.disableLojistaButton = false;
        } else if (tipo === TipoDelivery[TipoDelivery.valor_fixo]) {
          this.disableFormGroups(TipoDelivery[TipoDelivery.gratis], TipoDelivery[TipoDelivery.pede_que_chega], TipoDelivery[TipoDelivery.valor_fixo]);
          this.delivery.valor = this.setZeroToFieldUndefined(this.delivery.valor);
          this.delivery.valorPedidoEntregaGratis = this.setZeroToFieldUndefined(this.delivery.valorPedidoEntregaGratis);
          this.form.get(TipoDelivery[TipoDelivery.valor_fixo]).get("valor").setValidators([Validators.required, Validators.min(0.01)]);
          this.form.get(TipoDelivery[TipoDelivery.valor_fixo]).patchValue(this.delivery);
          this.disableLojistaButton = true;
        } else if (tipo === TipoDelivery[TipoDelivery.gratis]) {
          this.delivery.valor = this.setZeroToFieldUndefined(this.delivery.valor);
          this.delivery.valorMinimoDePedido = this.setZeroToFieldUndefined(this.delivery.valorMinimoDePedido);
          this.disableFormGroups(TipoDelivery[TipoDelivery.valor_fixo], TipoDelivery[TipoDelivery.pede_que_chega], TipoDelivery[TipoDelivery.gratis]);
          this.form.get(TipoDelivery[TipoDelivery.gratis]).patchValue(this.delivery);
          this.disableLojistaButton = true;
        }
        //this.form.get(TipoDelivery[TipoDelivery.pede_que_chega]).get("valorLojistaIsPercent").patchValue(false);
      });

    this.form.valueChanges
      .subscribe((value) => {
        this.saved = false;
        for (const key of Object.keys(value)) {
          this.delivery[key] = value[key];
        }
      });

    // Recarregar lista de bairros ao mudar tipo de delivery
    this.form.get("tipo").valueChanges
      .pipe(pairwise())
      .subscribe(([tipoPrev, tipoCurr]) => {
        if ((tipoPrev === TipoDelivery[TipoDelivery.pede_que_chega] && tipoCurr !== TipoDelivery[TipoDelivery.pede_que_chega])
          || (tipoPrev !== TipoDelivery[TipoDelivery.pede_que_chega] && tipoCurr === TipoDelivery[TipoDelivery.pede_que_chega])) {
          this.deliveryBairrosFormComponent.filteredItemsList = [];
          this.deliveryBairrosFormComponent.buscarBairros(this.delivery);
        }
      });

    this.service.getBairrosCidade().subscribe(value => {
      this.bairrosInicial = value;
    });
  }

  setZeroToFieldUndefined(fieldValue): number {
    if (isNullOrUndefined(fieldValue)) {
      return 0;
    } else {
      return fieldValue;
    }
  }

  disableFormGroups(groupNameDisable1: string, groupNameDisable2: string, groupNameEnable: string) {
    this.form.get(groupNameDisable1).disable();
    this.form.get(groupNameDisable2).disable();
    this.form.get(groupNameEnable).enable();
  }

  removePedeQueChega() {
    if (this.service.enderecoEmpresa.cidade !== "Rio Verde") {
      return this.tiposDelivery.filter(tipo => {
        return tipo.tipo !== TipoDelivery[TipoDelivery.pede_que_chega];
      });
    } else {
      return this.tiposDelivery;
    }
  }

  // Este método irá pegar os horários de funcionamento da empresa, e irá comparar com os horários de funcionamento do PedeQueChega.
  // Caso os horários não batam, a variável "invalidPQC" será "true", e impedirá que o usuário salve a opção PQC no delivery.
  validarPedeQueChega(): boolean {
    const horariosDeFuncionamento: Funcionamento[] = [];
    const dia = ["domingo", "segunda", "terca", "quarta", "quinta", "sexta", "sabado"];
    const empresa = this.auth.currentEmpresa;

    for (let i = 0; i < 7; i++) {
      horariosDeFuncionamento[i] = new Funcionamento({
        aberturaManha: empresa.horario[dia[i]].funcionamento.aberturaManha,
        fechamentoManha: empresa.horario[dia[i]].funcionamento.fechamentoManha,
        aberturaTarde: empresa.horario[dia[i]].funcionamento.aberturaTarde,
        fechamentoTarde: empresa.horario[dia[i]].funcionamento.fechamentoTarde
      });
    }

    // Variáveis para pegar os valores dos horários
    let aberturaManha: any;
    let fechamentoManha: any;
    let aberturaTarde: any;
    let fechamentoTarde: any;

    // Variáveis com o valor dos horarios de abertura e fechamentodo PQC
    const horarioPQCInicial = moment("09:00", "hh:mm");
    const horarioPQCFinal = moment("23:30", "hh:mm");

    for (let i = 0; i < 7; i++) {
      aberturaManha = moment(horariosDeFuncionamento[i].aberturaManha, "hh:mm");
      fechamentoManha = moment(horariosDeFuncionamento[i].fechamentoManha, "hh:mm");
      aberturaTarde = moment(horariosDeFuncionamento[i].aberturaTarde, "hh:mm");
      fechamentoTarde = moment(horariosDeFuncionamento[i].fechamentoTarde, "hh:mm");

      // Verificar se os horarios batem com o horário do PQC
      if ((aberturaManha.isBefore(horarioPQCInicial) || aberturaManha.isAfter(horarioPQCFinal)) ||
        (fechamentoManha.isBefore(horarioPQCInicial) || fechamentoManha.isAfter(horarioPQCFinal)) ||
        (aberturaTarde.isBefore(horarioPQCInicial) || aberturaTarde.isAfter(horarioPQCFinal)) ||
        (fechamentoTarde.isBefore(horarioPQCInicial) || fechamentoTarde.isAfter(horarioPQCFinal))) {
        return false;
      }
    }
    return true;
  }

  valorLojistaButton() {
    if (!this.auth.user.isLojista()) {
      this.form.get(TipoDelivery[TipoDelivery.pede_que_chega]).get("valorLojistaIsPercent")
        .patchValue(!this.form.get(TipoDelivery[TipoDelivery.pede_que_chega]).get("valorLojistaIsPercent").value);
      this.lojistaIsPercent = this.form.get(TipoDelivery[TipoDelivery.pede_que_chega]).get("valorLojistaIsPercent").value;
    }
  }

  saveOrUpdate() {

    // Buscar modificações nas configurações de bairros, e adicionálas no delivery
    const changedBairros: DeliveryBairro[] = [];
    const bairrosForm = this.deliveryBairrosFormComponent.form.get("bairros");
    for (const index of Object.keys(bairrosForm.value)) {
      // TODO se possivel utilizar outro método alem do ts-ignore
      // @ts-ignore
      const rawValue = bairrosForm.get(index).getRawValue();

      if (bairrosForm.get(index).dirty) {

        if ((rawValue.valorEntrega === "") || (rawValue.valorEntrega === 0 && rawValue.gratis === false)) {
          rawValue.valorEntrega = null;
        }
        if (rawValue.gratis === true) {
          rawValue.valorEntrega = 0;
        }
        changedBairros.push(rawValue);
      }
    }

    // Atribuir os valores dos formGroups ao delivery
    switch (this.delivery.tipo) {
      case TipoDelivery[TipoDelivery.pede_que_chega]: {
        this.delivery.valor = 0;
        this.delivery.valorLojista = this.form.get(TipoDelivery[TipoDelivery.pede_que_chega]).value.valorLojista;
        this.delivery.valorMinimoDePedido = this.form.get(TipoDelivery[TipoDelivery.pede_que_chega]).value.valorMinimoDePedido;
        this.delivery.valorLojistaIsPercent = this.form.get(TipoDelivery[TipoDelivery.pede_que_chega]).value.valorLojistaIsPercent;
      }
        break;

      case TipoDelivery[TipoDelivery.gratis]: {
        this.delivery.valor = this.form.get(TipoDelivery[TipoDelivery.gratis]).value.valor;
        this.delivery.valorMinimoDePedido = this.form.get(TipoDelivery[TipoDelivery.gratis]).value.valorMinimoDePedido;
      }
        break;

      case TipoDelivery[TipoDelivery.valor_fixo]: {
        this.delivery.valor = this.form.get(TipoDelivery[TipoDelivery.valor_fixo]).value.valor;
        this.delivery.valorPedidoEntregaGratis = this.form.get(TipoDelivery[TipoDelivery.valor_fixo]).value.valorPedidoEntregaGratis;
        this.delivery.valorMinimoDePedido = this.form.get(TipoDelivery[TipoDelivery.valor_fixo]).value.valorMinimoDePedido;
      }
        break;
    }

    const showError = (error) => {
      this.saved = false;
      this.dialogService
        .messageDialog()
        .message(error.message)
        .show();
    };

    try {

      if (this.delivery.tipo === TipoDelivery[TipoDelivery.gratis]) {
        if (this.delivery.valorMinimoDePedido === 0 && this.delivery.valor > 0) {
          throw Error("O Valor do Delivery só deve ser informado caso tenha um valor mínimo de pedido!");
        }
      }

      if (this.delivery.tipo === TipoDelivery[TipoDelivery.pede_que_chega]) {

        if (this.delivery.valorLojistaIsPercent) {
          if (this.delivery.valorLojista > 100) {
            throw Error("O valor cobrado do lojista não pode ser maior que 100%!");
          }
        } else if (!isEmpty(bairrosForm.value)) {
          // Verificar se o valor do lojista é maior que o bairro com o preço mais alto
          const bairroMaisCaro = bairrosForm.value.reduce((prev, current) => {
            const prevValue = isNotNullOrUndefined(prev.valorEntrega) ? prev.valorEntrega : 0;
            const currValue = isNotNullOrUndefined(current.valorEntrega) ? current.valorEntrega : 0;
            return (prevValue > currValue) ? prev : current;
          });
          if (this.delivery.valorLojista > bairroMaisCaro.valorEntrega) {
            throw Error("O valor cobrado do lojista não pode ser maior que o valor de todos os bairros Pede Que Chega.");
          }
        }

        if (!this.validarPedeQueChega()) {
          throw Error("Os horários de funcionamento da empresa não podem ser antes das 9:00 ou depois das 23:30!");
        }

      }

      if (this.delivery.tipo === TipoDelivery[TipoDelivery.valor_fixo]) {
        if (this.delivery.valorPedidoEntregaGratis > 0 && this.delivery.valorMinimoDePedido > this.delivery.valorPedidoEntregaGratis) {
          throw Error("O \"Valor mínimo de pedido\" deve ser maior que o valor \"Entrega gratis acima de\"!");
        }
      }

      const saving = () => {
        this.loading.showTopBar();
        this.saving = true;
        this.deliveryBairrosFormComponent.disableVirtualList = true;
      };

      if (this.delivery.tipo === TipoDelivery[TipoDelivery.pede_que_chega]) {
        // Se for pede que chega, mantem os bairros configurados no delivery
        this.delivery.bairros = this.bairrosDelivery;

        // Verifica se houve alteração nos bairros de Pede que Chega, e se o usuário não é lojista
        if (!isEmpty(changedBairros) && !this.auth.user.isLojista()) {

          // Confirmar se o usuário realmente deseja salvar as configurações de bairro. Pois nessa ocasião, ele irá alterar as configurações
          // de de bairro geral do Pede que Chega
          this.dialogService.confirmDialog()
            .title("Salvar alterações de bairros?")
            .message("Ao confirmar, as configuraçoes de entrega dos bairros do Pede que Chega serão atualizadas.")
            .acceptButton("Confirmar")
            .show()
            .subscribe(accept => {
              if (accept) {
                saving();
                // Salvar bairros Pede que Chega
                this.pqcBairrosService.saveBairrosPedeQueChega(changedBairros)
                  .then(() => {
                    // Salvar delivery apenas se aceitar as mudanças de bairros.
                    this.salvarDelivery().subscribe(() => {
                    }, showError);
                  })
                  .catch(err => {
                    this.dialogService.messageDialog()
                      .title("Erro ao salvar os bairros!")
                      .message(err)
                      .show();
                  });
              } else {
                this.loading.hideTopBar();
                this.saving = false;
              }
            });
        } else {
          saving();
          // Caso o usuário seja lojista, as configurações de bairros serão salvas nos bairros da empresa
          this.salvarBairros(changedBairros);
          // Salvar delivery
          this.salvarDelivery().subscribe(() => {
          }, showError);
        }
      } else {
        saving();
        // Verificar e salvar bairros
        this.salvarBairros(changedBairros);

        // Salvar delivery
        this.salvarDelivery().subscribe(() => {
        }, showError);
      }
    } catch (error) {
      showError(error);
    }
  }

  // Verificar se existe bairros novos, e salvar no banco
  async buscarESalvarNovosBairros() {
    const newBairros: Bairro[] = [];
    for (const bairro of this.deliveryBairrosFormComponent.bairros) {
      if (!this.bairrosInicial.some(value => value.id === bairro.bairro.id)) {
        newBairros.push(bairro.bairro);
      }
    }
    if (!isEmpty(newBairros)) {
      this.bairroService.salvarNovosBairros(newBairros)
        .catch(err => {
          this.dialogService
            .messageDialog()
            .message("Erro ao criar novos bairros! " + err.message)
            .show();
        });
    }
  }

  // Adicionar as alterações de bairros no delivery
  salvarBairros(changedBairros: DeliveryBairro[]) {
    // TODO chamada do método que criava novos bairros no sistema
    //this.buscarESalvarNovosBairros();

    // Verificar se houve mudanças nos bairros
    if (!isEmpty(changedBairros)) {

      // Percorre todos os bairros alterados
      for (const changedBairro of changedBairros) {
        // Busca se o bairro alterado esta na lista de bairros
        const index = this.delivery.bairros.findIndex(deliveryBairro => deliveryBairro.bairro.id === changedBairro.bairro.id);
        if (isNotNullOrUndefined(index) && index !== -1) {
          // Se o bairro estiver na lista substitui o valor da posição
          this.delivery.bairros[index] = changedBairro;
        } else {
          // Se não adiciona o novo bairro no final do array
          this.delivery.bairros.push(changedBairro);
        }
      }
    }
    // Remover os DeliveryBairros que não tem nenhuma configuração
    this.delivery.bairros = this.delivery.bairros.filter(bairro => isNotNullOrUndefined(bairro.valorEntrega) || bairro.bloqueado);
  }

  // Salvar dados do delivery no banco
  salvarDelivery(): Observable<void> {
    return this.service.saveOrUpdate(this.delivery.toAny())
      .pipe(mergeMap(() => {
          return this.empresaService.marcarPedeQueChega(this.delivery.empresa, this.delivery.tipo === TipoDelivery[TipoDelivery.pede_que_chega]);
        }),
        finalize(() => {
          // Desmarcar forms de bairros como dirty, para não ativar o botão de salvar novamento
          this.deliveryBairrosFormComponent.formArray.markAsPristine();

          // Resetar lista de bairros
          this.deliveryBairrosFormComponent.filteredItemsList = [];
          this.deliveryBairrosFormComponent.buscarBairros(this.delivery);
          this.deliveryBairrosFormComponent.disableVirtualList = false;

          this.loading.hideTopBar();
          this.saving = false;
          this.saved = true;
        })
      );
  }

  hasTipoSelected(): boolean {
    return isNotNullOrUndefined(this.delivery.tipo);
  }

  // Habilitar botão de salvar caso haja mudanças nos bairros
  bairrosChanged($event: any) {
    if ($event) {
      this.saved = false;
    }
  }

  disableSaveEvent($event: any) {
    this.disableSave = !!$event;
  }

}
