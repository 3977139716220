import {RecordByEmpresa} from "./record-by-empresa";
import {isNotNullOrUndefined} from "../utils/commons";
import {Data} from "@angular/router";

export class PerfilAcesso extends RecordByEmpresa {
  public descricao: string;
  public observacao: string;
  public consultorId: string;
  public roles: string[];

  constructor(perfil: any = {}) {
    super(perfil.id, perfil.ref, perfil.empresa);
    this.descricao = perfil.descricao;
    this.observacao = perfil.observacao;
    this.consultorId = isNotNullOrUndefined(perfil.consultorId)
      ? perfil.consultorId
      : null;
    this.roles = perfil.roles;
  }

  hasRole(role: Role): boolean {
    return (
      isNotNullOrUndefined(role) && this.roles.some((r) => Role[r] === role)
    );
  }

  hasConsultor(): boolean {
    return isNotNullOrUndefined(this.consultorId);
  }
}

export enum Role {
  // Empresas
  EmpresaCreate,
  EmpresaRead,
  EmpresaUpdate,
  EmpresaDelete,
  EmpresaBlock,

  // Usuários
  UsuarioCreate,
  UsuarioRead,
  UsuarioUpdate,
  UsuarioDelete,
  UsuarioSelectEmpresa,
  UsuarioUpdateOwnPerfilAcesso, // Permite o usuário alterar o próprio perfil de acesso

  // Perfil de Acesso
  PerfilAcessoCreate,
  PerfilAcessoRead,
  PerfilAcessoUpdate,
  PerfilAcessoDelete,

  // Bairros
  BairroCreate,
  BairroRead,
  BairroUpdate,
  BairroDelete,

  // Formas de Pagamento
  FormaPagamentoCreate,
  FormaPagamentoRead,
  FormaPagamentoUpdate,
  FormaPagamentoDelete,
  FormaPagamentoOnlineUpdate,
  FormaPagamentoSubcontaEspelho,

  // Delivery
  DeliveryCreate,
  DeliveryRead,
  DeliveryUpdate,
  DeliveryImport,

  // Pedidos
  PedidoRead,
  PedidoCancel,
  PedidoRevert,

  // Balcão
  GerenciadorPedidosRead,
  GerenciadorPedidosUpdate,

  // Produtos
  ProdutoCreate,
  ProdutoRead,
  ProdutoUpdate,
  ProdutoDelete,

  // Categorias de produtos
  CategoriaProdutoCreate,
  CategoriaProdutoRead,
  CategoriaProdutoUpdate,
  CategoriaProdutoDelete,

  // Complemento de produtos
  ComplementoProdutoCreate,
  ComplementoProdutoRead,
  ComplementoProdutoUpdate,
  ComplementoProdutoDelete,

  // Cozinhas
  CozinhaCreate,
  CozinhaRead,
  CozinhaUpdate,
  CozinhaDelete,

  // Avaliações
  AvaliacaoRead,
  AvaliacaoUpdate,

  // Usuários
  NotificacaoCreate,
  NotificacaoRead,
  NotificacaoUpdate,
  NotificacaoDelete,

  // Entregadores
  EntregadoresCreate,
  EntregadoresRead,
  EntregadoresUpdate,
  EntregadoresDelete,

  // Central de entregas
  CentralEntregasRead,

  // Entregas
  EntregasRead,
  EntregasUpdate,
  EntregasPagar,

  // Monitoramento de pedidos
  MonitorPedidosRead,

  // Relatório de pedidos
  RelatorioPedidosRead,

  // Relatório de comissões
  RelatorioComissoesRead,

  // Novo Relatório de comissões
  ComissoesConsultoresRead,

  // Financeiro
  FinanceiroRead,
  FinanceiroGenerateInvoice,
  FinanceiroBaixa,

  // Faturas
  FaturasRead,
  FaturasGenerateParcial,

  // NFSe
  GenerateNfse,

  // Extrato Online
  ExtratoRead,
  SacarSaldoDisponivel,

  // Abatimentos
  AbatimentosCreate,
  AbatimentosRead,
  AbatimentosUpdate,
  AbatimentosDelete,

  // Promocoes
  PromocaoCreate,
  PromocaoRead,
  PromocaoUpdate,
  PromocaoDelete,

  // Clientes
  ClientesRead,
  ClientesUpdate,
  ClientesBloquearCliente,
  ClientesAdicionarPomsCliente,
}

export function getEmpresasRoles(): Data {
  return {
    role: {
      read: Role.EmpresaRead,
      create: Role.EmpresaCreate,
      update: Role.EmpresaUpdate,
      delete: Role.EmpresaDelete,
      block: Role.EmpresaBlock,
    },
  };
}

export function getUsersRoles(): Data {
  return {
    role: {
      read: Role.UsuarioRead,
      create: Role.UsuarioCreate,
      update: Role.UsuarioUpdate,
      delete: Role.UsuarioDelete,
      selectEmpresa: Role.UsuarioSelectEmpresa,
    },
  };
}

export function getPerfisAcessoRoles(): Data {
  return {
    role: {
      read: Role.PerfilAcessoRead,
      create: Role.PerfilAcessoCreate,
      update: Role.PerfilAcessoUpdate,
      delete: Role.PerfilAcessoDelete,
    },
  };
}

export function getBairrosRoles(): Data {
  return {
    role: {
      read: Role.BairroRead,
      create: Role.BairroCreate,
      update: Role.BairroUpdate,
      delete: Role.BairroDelete,
    },
  };
}

export function getFormasPagamentoRoles(): Data {
  return {
    role: {
      read: Role.FormaPagamentoRead,
      create: Role.FormaPagamentoCreate,
      update: Role.FormaPagamentoUpdate,
      delete: Role.FormaPagamentoDelete,
      online: Role.FormaPagamentoOnlineUpdate,
      espelho: Role.FormaPagamentoSubcontaEspelho
    },
  };
}

export function getProdutosRoles(): Data {
  return {
    role: {
      read: Role.ProdutoRead,
      create: Role.ProdutoCreate,
      update: Role.ProdutoUpdate,
      delete: Role.ProdutoDelete,
    },
  };
}

export function getCategoriasProdutoRoles(): Data {
  return {
    role: {
      read: Role.CategoriaProdutoRead,
      create: Role.CategoriaProdutoCreate,
      update: Role.CategoriaProdutoUpdate,
      delete: Role.CategoriaProdutoDelete,
    },
  };
}

export function getComplementosProdutoRoles(): Data {
  return {
    role: {
      read: Role.ComplementoProdutoRead,
      create: Role.ComplementoProdutoCreate,
      update: Role.ComplementoProdutoUpdate,
      delete: Role.ComplementoProdutoDelete,
    },
  };
}

export function getCozinhasRoles(): Data {
  return {
    role: {
      read: Role.CozinhaRead,
      create: Role.CozinhaCreate,
      update: Role.CozinhaUpdate,
      delete: Role.CozinhaDelete,
    },
  };
}

export function getPedidosRoles(): Data {
  return {
    role: {
      read: Role.PedidoRead,
      cancel: Role.PedidoCancel,
      revert: Role.PedidoRevert,
    },
  };
}

export function getGerenciadorRoles(): Data {
  return {
    role: {
      read: Role.GerenciadorPedidosRead,
      update: Role.GerenciadorPedidosUpdate,
    },
  };
}

export function getAvaliacaoRoles(): Data {
  return {
    role: {
      read: Role.AvaliacaoRead,
      update: Role.AvaliacaoUpdate,
    },
  };
}

export function getNotificacaoRoles(): Data {
  return {
    role: {
      read: Role.NotificacaoRead,
      update: Role.NotificacaoUpdate,
      delete: Role.NotificacaoDelete,
      create: Role.NotificacaoCreate,
    },
  };
}

export function getEntregadoresRoles(): Data {
  return {
    role: {
      read: Role.EntregadoresRead,
      update: Role.EntregadoresUpdate,
      delete: Role.EntregadoresDelete,
      create: Role.EntregadoresCreate,
    },
  };
}

export function getCentralEntregas(): Data {
  return {
    role: {
      read: Role.CentralEntregasRead,
    },
  };
}

export function getEntregasRoles(): Data {
  return {
    role: {
      read: Role.EntregasRead,
    },
  };
}

export function getMonitorRoles(): Data {
  return {
    role: {
      read: Role.MonitorPedidosRead,
    },
  };
}

export function getRelatorioPedidosRoles(): Data {
  return {
    role: {
      read: Role.RelatorioPedidosRead,
    },
  };
}

export function getRelatorioComissoesRoles(): Data {
  return {
    role: {
      read: Role.RelatorioComissoesRead,
    },
  };
}

export function getComissoesConsultoresRoles(): Data {
  return {
    role: {
      read: Role.ComissoesConsultoresRead,
    },
  };
}

export function getFinanceiroRoles(): Data {
  return {
    role: {
      read: Role.FinanceiroRead,
      generate: Role.FinanceiroGenerateInvoice,
    },
  };
}

export function getFaturasRoles(): Data {
  return {
    role: {
      read: Role.FaturasRead,
      generate: Role.FaturasGenerateParcial,
      nfse: Role.GenerateNfse
    },
  };
}

export function getExtratoOnlineRoles(): Data {
  return {
    role: {
      read: Role.ExtratoRead,
      withdraw: Role.SacarSaldoDisponivel,
    },
  };
}

export function getAbatimentosRoles(): Data {
  return {
    role: {
      create: Role.AbatimentosCreate,
      read: Role.AbatimentosRead,
      update: Role.AbatimentosUpdate,
      delete: Role.AbatimentosDelete,
    },
  };
}

export function getPromocaoRoles(): Data {
  return {
    role: {
      read: Role.PromocaoRead,
      create: Role.PromocaoCreate,
      update: Role.PromocaoUpdate,
      delete: Role.PromocaoDelete,
    },
  };
}

export function getClientesRoles(): Data {
  return {
    role: {
      read: Role.ClientesRead,
      update: Role.ClientesUpdate,
      block: Role.ClientesBloquearCliente,
      addPoms: Role.ClientesAdicionarPomsCliente
    }
  };
}
