import {Criterion} from "./criterion";
import * as firebase from "firebase";
import Query = firebase.firestore.Query;
import CollectionReference = firebase.firestore.CollectionReference;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

export class StartAfter extends Criterion {
  constructor(public cursor: DocumentSnapshot) {
    super(null, null, null);
  }

  build(ref: CollectionReference | Query): Query {
    return (ref as Query).startAfter(this.cursor);
  }
}
