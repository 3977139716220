import { IFormCanDeactivate } from "./iform-canDeactivate";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs/Rx";


@Injectable()
export class FormCanDeactivateGuard implements CanDeactivate<IFormCanDeactivate> {

  canDeactivate(
      component: IFormCanDeactivate,
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<boolean>|Promise<boolean>|boolean {
      return component.podeMudarRota();
  }
}
