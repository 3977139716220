import {Component, OnInit} from "@angular/core";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs/Rx";
import {CustomersIuguService} from "../../../services/iugu/customers.iugu.service";
import {AuthService} from "../../../modules/login/auth.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {tap} from "rxjs/operators";
import {FormControl, Validators} from "@angular/forms";

export interface DadosSaque {
  saldoPendente: number;
  saldoLiberado: number;
  // taxaDeSaque: number;
  totalComissoesEmAberto: number;
  saldoDisponivel: number;
  banco: string;
  agencia: string;
  conta: string;
  tipoConta: string;
  razaoSocial: string;
}

@Component({
  selector: "app-saque-dialog",
  templateUrl: "./saque-dialog.component.html",
  styleUrls: ["./saque-dialog.component.scss"]
})
export class SaqueDialogComponent implements OnInit {

  static showDialog(dialog: MatDialog): Observable<any> {
    const config = {
      width: "500px",
      disableClose: true
    };
    return dialog.open(SaqueDialogComponent, config).afterClosed();
  }

  valorDisponivel: number = 0;
  disableButton: boolean = false;

  dadosSaque: Observable<DadosSaque>;
  formValor: FormControl = new FormControl({value: 0}, [Validators.required]);

  constructor(public dialogRef: MatDialogRef<SaqueDialogComponent>,
              public customersIuguService: CustomersIuguService,
              public auth: AuthService,
              public loadingService: LoadingService,
              public dialog: DialogService) {
    this.dadosSaque = this.customersIuguService.buscarDadosSaque(this.auth.currentEmpresa.cnpj)
      .pipe(tap(saque => {
        this.valorDisponivel = saque.saldoDisponivel;
        this.formValor.patchValue(saque.saldoDisponivel);
        this.formValor.setValidators([Validators.required, Validators.min(5), Validators.max(saque.saldoDisponivel)]);
        this.formValor.updateValueAndValidity();
      }));
  }

  ngOnInit() {
  }

  sacar() {
    const valor = this.formValor.value;
    if (valor < 5) {
      this.dialog.messageDialog()
        .title("Atenção!")
        .message("Não é possível sacar um valor menor que R$ 5,00.")
        .show();
    } else if (valor > this.valorDisponivel) {
      this.dialog.messageDialog()
        .title("Atenção!")
        .message("Não é possível fazer um saque maior que o valor disponível!")
        .show();
    } else {
      this.disableButton = true;
      this.loadingService.showTopBar();
      this.customersIuguService.requestWithdraw(this.auth.currentEmpresa.cnpj, valor)
        .subscribe(res => {
          this.loadingService.hideTopBar();
          if (res) {
            this.dialogRef.close(true);
          }
        }, error => {
          this.disableButton = false;
          this.loadingService.hideTopBar();
          this.dialog.messageDialog()
            .title("Atenção!")
            .message(error)
            .show();
        });
    }
  }
}
