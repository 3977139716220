export class PdfRelatorioFiltros {

  public empresaNome: string;
  public dataInicialSimples: string;
  public dataFinalSimples: string;
  public totalPreco: string;
  public totalEntrega: number;
  public totalPQC: number;
  public totalPoMs: string;
  public totalValor: string;
  public tipoPedidos: string;
  public statusPedidos: string;
  public tipoPagamento: string;
  public numeroPedidos: number;
  public cidadePedidos: string;
  public relatorioGeral: boolean = false;
  public valorEntregaCliente: number;
  public valorEntregaLojista: number;

}
