import {Injectable} from "@angular/core";
import {Repository} from "../../repository/Repository";
import {Cidade} from "../../models/cidade.model";
import {AngularFirestore} from "@angular/fire/firestore";
import {OrderByAsc} from "../../services/firebase/criteria/order-by-asc";
import {OrderBy} from "../../services/firebase/criteria/order-by";
import {PoMQuery} from "../../services/firebase/criteria/query";
import {Observable, of} from "rxjs";
import {AuthService} from "../../modules/login/auth.service";
import {map, mergeMap, take} from "rxjs/operators";
import {AppUser} from "../../models/appUser";
import {StringUtils} from "../../utils/string-utils";
import {isNullOrUndefined} from "../../utils/commons";

@Injectable()
export class CidadeService extends Repository<Cidade> {

  constructor(db: AngularFirestore, public authService: AuthService) {
    super(db, "cidades");
  }

  col$(queryFn: PoMQuery = new PoMQuery()): Observable<Cidade[]> {
    return this.authService.currentUser.pipe(mergeMap((user: AppUser) => {
      if (user.isConsultorOrAssistente()) {
        return super.col$().pipe(
          mergeMap((cidades: Cidade[]) => {
            return this.filterCidadesConsultorOrAssistente(cidades, user);
          })
        );
      } else {
        return super.col$(queryFn);
      }
    }));
  }

  filterCidadesConsultorOrAssistente(cidades: Cidade[], user: AppUser): Observable<Cidade[]> {
    return of(
      cidades.filter((cidade: Cidade) => {
        if (cidade.consultores) {
          return cidade.consultores.includes(user.isConsultor() ? user.id : user.consultorId);
        }
      })
    );
  }

  saveOrUpdate(item: Cidade, keepId?: boolean): Observable<any> {
    return this.checkIfCidadeExists(item).pipe(
      take(1),
      mergeMap((foundCity: Cidade) => {
        return this.authService.currentUser.pipe(
          mergeMap((currentUser: AppUser) => {

            // Função para adicionar o consultor na lista
            const addConsultorInCity = (consultores: string[], consultorId: string) => {
              if (consultores) {
                // Verificar se o ID já está na lista
                if (!consultores.some((_id) => _id === consultorId)) {
                  consultores.push(consultorId);
                }
              }
            };

            if (isNullOrUndefined(item.consultores)) {
              item.consultores = [];
            }

            // Se for consultor
            if (currentUser.isConsultor()) {
              // Adiciona o ID o consultor na lista
              addConsultorInCity(item.consultores, currentUser.id);
            } else if (currentUser.isAssistente()) {
              // Adiciona o ID do Consultor do Assistente
              addConsultorInCity(item.consultores, currentUser.consultorId);
            }

            // Cidade já está cadastrada
            if (!isNullOrUndefined(foundCity)) {

              // Atualizar o nome e UF
              foundCity.nome = item.nome;
              foundCity.uf = item.uf;

              // Merge das listas sem duplicar
              foundCity.consultores = Array.from(new Set([...foundCity.consultores, ...item.consultores]));

              // Atualizar o registro
              return super.saveOrUpdate(foundCity, keepId)
                .pipe(map(() => foundCity));

            } else {
              // Gravar o novo registro
              return super.saveOrUpdate(item, keepId)
                .pipe(map(() => item));
            }
          })
        );
      })
    );
  }

  // Verifica se a cidade recebida já está cadastrada, se sim, retorna o registro gravado no banco de dados
  checkIfCidadeExists(newCidade: Cidade): Observable<Cidade> {
    return super.col$().pipe(map((cidades: Cidade[]) => {
      return cidades.find((cidade: Cidade) => {
        // Faz a verificação do nome da cidade ignorando os acentos
        return StringUtils.equalsToIgnoreCase(cidade.nome, newCidade.nome) && StringUtils.equalsToIgnoreCase(cidade.uf, newCidade.uf);
      });
    }));
  }

  deserialize(cidade): Cidade {
    return new Cidade(cidade);
  }

  protected orderBy(): OrderBy {
    return new OrderByAsc("nome");
  }

}
