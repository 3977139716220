import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {classToPlain} from "class-transformer";
import * as firebase from "firebase";
import {from, Observable, of} from "rxjs";
import {catchError, map, mergeMap} from "rxjs/operators";
import {Produto} from "../../models/produto.model";
import {AuthService} from "../../modules/login/auth.service";
import {EmpresaCriterion, RepositoryByEmpresa} from "../../repository/repository-by-empresa";
import {OrderByAsc} from "../../services/firebase/criteria/order-by-asc";
import {isEmpty} from "../../utils/commons";
import {ComplementoItemService} from "./complemento-item.service";
import {ComplementoService} from "./complemento.service";
import {Empresa} from "../../models/empresa";
import {Criterion} from "../../services/firebase/criteria/criterion";
import {$query} from "../../services/firebase/criteria/query";
import {isNullOrUndefined} from "util";
import {AngularFireFunctions} from "@angular/fire/functions";
import {EmpresaService} from "../empresa/empresa.service";
import WriteBatch = firebase.firestore.WriteBatch;

@Injectable()
export class ProdutoService extends RepositoryByEmpresa<Produto> {

  constructor(private dataBase: AngularFirestore, auth: AuthService, public fns: AngularFireFunctions, private empresaService: EmpresaService) {
    super(dataBase, auth, "produtos", true);
  }

  // O que eu vou fazer com isso aqui?
  //protected orderBy(): OrderBy {
  //  return new OrderByAsc("categoria.ordem");
  //}

  deserialize(value: any): Produto {
    return new Produto(value);
  }

  getAllProdutosByCategoria(categoriaId): Observable<Produto[]> {
    return this.col$($query(new Criterion("categoria.id", "==", categoriaId), new OrderByAsc("sequence")));
  }

  public getProdutosByEmpresaENome(empresaId: string, nome: string): Observable<Produto[]> {

    const query = $query();

    if (isEmpty(nome)) {
      query.add(new Criterion("nomeNormalized", ">=", ""));
    } else {
      query.add(new Criterion("nomeNormalized", ">=", nome));
    }

    query.add(new Criterion("empresa", "==", this.empresaService.getDoc(empresaId).ref),
      new Criterion("nomeNormalized", "<=", nome + "\uf8ff"));

    return this.col$(query);
  }

  saveOrUpdate(item: Produto): Observable<void> {
    // Remover o campo que não devem ser atualizados no banco de dados
    delete item.promocao;

    return super.saveOrUpdate(item, false, true).pipe(
      mergeMap(() => {
        // Gravar os complementos
        const batch: WriteBatch = this.dataBase.firestore.batch();
        // Percorrer a lista de complementos
        if (item.complementos) {
          item.complementos
            .map(complemento => {
              if (isEmpty(complemento.id)) {
                // Gerar um id para o complemento
                complemento.id = this.dataBase.createId();
              }
              return complemento;
            })
            .forEach(complemento => {
              // Criar um referência para o complemento
              const complementoDocRef = this.dataBase
                .collection(ComplementoService.collectionName)
                .doc(complemento.id).ref;
              // Converte o objeto para json
              const compJson = classToPlain(complemento);
              // Adiciona a referência do produto no complemento
              const produtoDocRef = this.getDoc(item.id).ref;
              compJson["produto"] = produtoDocRef;
              // Criar a operação para o complemento
              batch.set(complementoDocRef, compJson);

              // Adicionar os itens
              if (complemento.itens) {
                complemento.itens
                  .map(ci => {
                    if (isEmpty(ci.id)) {
                      // Gerar um novo id para o item do complemento
                      ci.id = this.dataBase.createId();
                    }
                    return ci;
                  })
                  .forEach(compItem => {
                    // Criar um referência para o item
                    const itemDocRef = this.dataBase
                      .collection(ComplementoItemService.collectionName)
                      .doc(compItem.id).ref;
                    // Converter o objeto para json
                    const itemSerialized = this.serialize(compItem);
                    // Adiciona a referência do produto
                    itemSerialized["produto"] = produtoDocRef;
                    // Adiciona a referência do complemento
                    itemSerialized["complemento"] = complementoDocRef;
                    // Criar a operação para o complemento
                    batch.set(itemDocRef, itemSerialized);
                  });
              }

              // Excluir os itens
              if (complemento.itensRemoved) {
                complemento.itensRemoved.forEach(itemRemoved => {
                  // Criar um referência para o item
                  const itemRemovedDocRef = this.dataBase
                    .collection(ComplementoItemService.collectionName)
                    .doc(itemRemoved.id).ref;
                  // Criar a operação de exclusão
                  batch.delete(itemRemovedDocRef);
                });
              }
            });
        }

        // Excluir os complementos
        if (item.complementosRemoved) {
          item.complementosRemoved.forEach(complemento => {
            // Montar a referência do complemento excluído
            const complementoDocRef = this.dataBase
              .collection(ComplementoService.collectionName)
              .doc(complemento.id).ref;
            // Criar a operação de exclusão do complemento
            batch.delete(complementoDocRef);
            // Os itens do complemento serão excluídos pelo Firebase Functions
          });
        }

        return from(batch.commit());
      })
    );
  }

  remove(id: string): Observable<void> {
    return this.fns.httpsCallable("call-produtos-remove")({id: id});
  }

  serialize(value: any): any {
    const val = super.serialize(value);
    // Quando for serializar o produto
    if (value instanceof Produto) {
      // Remove o atributo complementos
      delete val.complementos;
      delete val.complementosRemoved;
    }
    return val;
  }

  produtoIsEmpty(empresa?: Empresa): Observable<boolean> {
    return (empresa ? of(empresa) : this.auth.currentEmpresaObservable())
      .pipe(mergeMap(_empresa => {
        if (isNullOrUndefined(_empresa) || isNullOrUndefined(_empresa.ref)) {
          return of(false);
        }
        return this.col$($query(new EmpresaCriterion(_empresa)))
          .pipe(map((data) => !(data && data.length > 0)));
      }));
  }

  changeInactive(produto: Produto): Observable<any> {
    return this.fns.httpsCallable("call-produtos-changeInactive")({id: produto.id, inactive: produto.inactive})
      .pipe(catchError((err) => this.handleCallableFunctionsError(err)));
  }

  arquivar(produto: Produto): Observable<any> {
    return this.fns.httpsCallable("call-produtos-arquivar")({id: produto.id, arquivado: produto.arquivado})
      .pipe(catchError((err) => this.handleCallableFunctionsError(err)));
  }

  reordenar(produtos: Produto[]) {
    const batch: WriteBatch = this.dataBase.firestore.batch();

    produtos.forEach(produto => {
      batch.update(produto.ref, {"sequence": produto.sequence});
    });

    return batch.commit();
  }
}
