import {RelatorioPedidosEmpresaListComponent} from "./components/relatorio-pedidos-empresa/relatorio-pedidos-empresa-list/relatorio-pedidos-empresa-list.component";
import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {HomeComponent} from "./components/home/home.component";
import {PageNotFoundComponent} from "./components/page-not-found/page-not-found.component";
import {AuthGuard} from "./modules/login/auth.guard";
import {UsersFormComponent} from "./components/users/users-form/users-form.component";
import {UsersListComponent} from "./components/users/users-list/users-list.component";
import {CategoriaProdutoFormComponent} from "./components/categoria-produto/categoria-produto-form/categoria-produto-form.component";
import {CategoriaProdutoListComponent} from "./components/categoria-produto/categoria-produto-list/categoria-produto-list.component";
import {ProdutoListComponent} from "./components/produto/produto-list/produto-list.component";
import {ProdutoFormComponent} from "./components/produto/produto-form/produto-form.component";
import {ComplementoFormComponent} from "./components/produto/complemento-produto/complemento-form/complemento-form.component";
import {EmpresaListComponent} from "./components/empresa/empresa-list/empresa-list.component";
import {EmpresaFormComponent} from "./components/empresa/empresa-form/empresa-form.component";
import {ComplementoProdutoListComponent} from "./components/complemento-produto/complemento-produto-list/complemento-produto-list.component";
import {ComplementoProdutoFormComponent} from "./components/complemento-produto/complemento-produto-form/complemento-produto-form.component";
import {FormaPagamentoListComponent} from "./components/forma-pagamento/forma-pagamento-list/forma-pagamento-list.component";
import {FormaPagamentoFormComponent} from "./components/forma-pagamento/forma-pagamento-form/forma-pagamento-form.component";
import {BairroListComponent} from "./components/bairro/bairro-list/bairro-list.component";
import {FormaPagamentoOnlineFormComponent} from "./components/forma-pagamento/forma-pagamento-online-form/forma-pagamento-online-form.component";
import {BairroFormComponent} from "./components/bairro/bairro-form/bairro-form.component";
import {DeliveryComponent} from "./components/delivery/delivery-form/delivery.component";
import {GerenciadorPedidosComponent} from "./components/gerenciador-pedidos/gerenciador-pedidos/gerenciador-pedidos.component";
import {PerfilListComponent} from "./components/perfil-acesso/perfil-list/perfil-list.component";
import {PerfilFormComponent} from "./components/perfil-acesso/perfil-form/perfil-form.component";
import {AccessControlGuard} from "./services/access-control.guard";
import {CozinhasListComponent} from "./components/cozinhas/cozinhas-list/cozinhas-list.component";
import {CozinhasFormComponent} from "./components/cozinhas/cozinhas-form/cozinhas-form.component";
import {AvaliacoesListComponent} from "./components/avaliacoes/avaliacoes-list/avaliacoes-list.component";
import {FormCanDeactivateGuard} from "./guards/form-canDeactivate.guard";
import {EntregadoresFormComponent} from "./components/entregadores/entregadores-form/entregadores-form.component";
import {EntregadoresListComponent} from "./components/entregadores/entregadores-list/entregadores-list.component";
import {
  getAbatimentosRoles,
  getAvaliacaoRoles,
  getBairrosRoles,
  getCategoriasProdutoRoles,
  getCentralEntregas,
  getClientesRoles,
  getComissoesConsultoresRoles,
  getComplementosProdutoRoles,
  getCozinhasRoles,
  getEmpresasRoles,
  getEntregadoresRoles,
  getEntregasRoles,
  getExtratoOnlineRoles,
  getFaturasRoles,
  getFinanceiroRoles,
  getFormasPagamentoRoles,
  getGerenciadorRoles,
  getMonitorRoles,
  getNotificacaoRoles,
  getPedidosRoles,
  getPerfisAcessoRoles,
  getProdutosRoles,
  getPromocaoRoles,
  getRelatorioComissoesRoles,
  getRelatorioPedidosRoles,
  getUsersRoles,
  Role,
} from "./models/perfil-acesso.model";
import {CentralEntregasComponent} from "./components/central-entregas/central-entregas/central-entregas.component";
import {EntregasListComponent} from "./components/entregas/entregas-list/entregas-list.component";
import {MonitorPedidosComponent} from "./components/monitor-pedidos/monitor-pedidos/monitor-pedidos.component";
import {RelatorioPedidosGeralListComponent} from "./components/relatorio-pedidos-geral/relatorio-pedidos-geral-list/relatorio-pedidos-geral-list.component";
import {RelatorioComissoesListComponent} from "./components/relatorio-comissoes/relatorio-comissoes-list/relatorio-comissoes-list.component";
import {FinanceiroListComponent} from "./components/financeiro/financeiro-list/financeiro-list.component";
import {FaturasListComponent} from "./components/faturas/faturas-list/faturas-list.component";
import {AbatimentosListComponent} from "./components/abatimentos/abatimentos-list/abatimentos-list.component";
import {AbatimentosFormComponent} from "./components/abatimentos/abatimentos-form/abatimentos-form.component";
import {NotificacoesListComponent} from "./components/notificacoes/notificacoes-list/notificacoes-list.component";
import {NotificacoesFormComponent} from "./components/notificacoes/notificacoes-form/notificacoes-form.component";
import {ComissoesConsultoresComponent} from "./components/comissoes-consultores/comissoes-consultores.component";
import {ExtratoPagamentoOnlineListComponent} from "./components/extrato-pagamento-online/extrato-pagamento-online-list/extrato-pagamento-online-list.component";

const routes: Routes = [
  {path: "", redirectTo: "login", pathMatch: "full"},
  {
    path: "home",
    component: HomeComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    children: [
      {path: "empresa", redirectTo: "empresa/list"},
      {
        path: "empresa/list",
        component: EmpresaListComponent,
        canActivate: [AccessControlGuard],
        data: getEmpresasRoles(),
      },
      {
        path: "empresa/form",
        component: EmpresaFormComponent,
        canActivate: [AccessControlGuard],
        data: getEmpresasRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },
      {
        path: "empresa/form/:id",
        component: EmpresaFormComponent,
        canActivate: [AccessControlGuard],
        data: getEmpresasRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },

      {path: "users", redirectTo: "users/list"},
      {
        path: "users/list",
        component: UsersListComponent,
        canActivate: [AccessControlGuard],
        data: getUsersRoles(),
      },
      {
        path: "users/form",
        component: UsersFormComponent,
        canActivate: [AccessControlGuard],
        data: getUsersRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },
      {
        path: "users/form/:id",
        component: UsersFormComponent,
        canActivate: [AccessControlGuard],
        data: getUsersRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },

      {path: "perfis", redirectTo: "perfis/list"},
      {
        path: "perfis/list",
        component: PerfilListComponent,
        canActivate: [AccessControlGuard],
        data: getPerfisAcessoRoles(),
      },
      {
        path: "perfis/form",
        component: PerfilFormComponent,
        canActivate: [AccessControlGuard],
        data: getPerfisAcessoRoles(),
      },
      {
        path: "perfis/form/:id",
        component: PerfilFormComponent,
        canActivate: [AccessControlGuard],
        data: getPerfisAcessoRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },

      {path: "bairros", redirectTo: "bairros/list"},
      {
        path: "bairros/list",
        component: BairroListComponent,
        canActivate: [AccessControlGuard],
        data: getBairrosRoles(),
      },
      {
        path: "bairros/form",
        component: BairroFormComponent,
        canActivate: [AccessControlGuard],
        data: getBairrosRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },
      {
        path: "bairros/form/:id",
        component: BairroFormComponent,
        canActivate: [AccessControlGuard],
        data: getBairrosRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },

      {path: "formas-pagamento", redirectTo: "formas-pagamento/list"},
      {
        path: "formas-pagamento/list",
        component: FormaPagamentoListComponent,
        canActivate: [AccessControlGuard],
        data: getFormasPagamentoRoles(),
      },
      {
        path: "formas-pagamento/form",
        component: FormaPagamentoFormComponent,
        canActivate: [AccessControlGuard],
        data: getFormasPagamentoRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },
      {
        path: "formas-pagamento/form/:id",
        component: FormaPagamentoFormComponent,
        canActivate: [AccessControlGuard],
        data: getFormasPagamentoRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },
      {
        path: "formas-pagamento/pagamento-online",
        component: FormaPagamentoOnlineFormComponent,
        canActivate: [AccessControlGuard],
        data: getFormasPagamentoRoles(),
        canDeactivate: [FormCanDeactivateGuard]
      },

      {
        path: "delivery",
        component: DeliveryComponent,
        canActivate: [AccessControlGuard],
        data: {
          role: {
            read: Role.DeliveryRead,
          },
        },
      },

      {path: "produtos", redirectTo: "produtos/list"},
      {
        path: "produtos/list",
        component: ProdutoListComponent,
        canActivate: [AccessControlGuard],
        data: getProdutosRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },
      {
        path: "produtos/form",
        component: ProdutoFormComponent,
        canActivate: [AccessControlGuard],
        data: getProdutosRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },
      {
        path: "produtos/form/:id",
        component: ProdutoFormComponent,
        canActivate: [AccessControlGuard],
        data: getProdutosRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },
      {
        path: "produtos/form/:id/adicionais-produto",
        component: ComplementoFormComponent,
        data: getComplementosProdutoRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },
      {
        path: "produtos/form/:id/adicionais-produto/:id_adicional",
        component: ComplementoFormComponent,
        canDeactivate: [FormCanDeactivateGuard],
      },

      {path: "categorias-produto", redirectTo: "categorias-produto/list"},
      {
        path: "categorias-produto/list",
        component: CategoriaProdutoListComponent,
        canActivate: [AccessControlGuard],
        data: getCategoriasProdutoRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },
      {
        path: "categorias-produto/form",
        component: CategoriaProdutoFormComponent,
        canActivate: [AccessControlGuard],
        data: getCategoriasProdutoRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },
      {
        path: "categorias-produto/form/:id",
        component: CategoriaProdutoFormComponent,
        canActivate: [AccessControlGuard],
        data: getCategoriasProdutoRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },

      {path: "complementos-produto", redirectTo: "complementos-produto/list"},
      {
        path: "complementos-produto/list",
        component: ComplementoProdutoListComponent,
        canActivate: [AccessControlGuard],
        data: getComplementosProdutoRoles(),
      },
      {
        path: "complementos-produto/form",
        component: ComplementoProdutoFormComponent,
        canActivate: [AccessControlGuard],
        data: getComplementosProdutoRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },
      {
        path: "complementos-produto/form/:id",
        component: ComplementoProdutoFormComponent,
        canActivate: [AccessControlGuard],
        data: getComplementosProdutoRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },

      {path: "cozinhas", redirectTo: "cozinhas/list"},
      {
        path: "cozinhas/list",
        component: CozinhasListComponent,
        canActivate: [AccessControlGuard],
        data: getCozinhasRoles(),
      },
      {
        path: "cozinhas/form",
        component: CozinhasFormComponent,
        canActivate: [AccessControlGuard],
        data: getCozinhasRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },
      {
        path: "cozinhas/form/:id",
        component: CozinhasFormComponent,
        canActivate: [AccessControlGuard],
        data: getCozinhasRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },

      {path: "relatorio-empresa", redirectTo: "relatorio-empresa/list"},
      {
        path: "relatorio-empresa/list",
        component: RelatorioPedidosEmpresaListComponent,
        canActivate: [AccessControlGuard],
        data: getPedidosRoles(),
      },

      {path: "gerenciador", redirectTo: "gerenciador/list"},
      {
        path: "gerenciador/list",
        component: GerenciadorPedidosComponent,
        canActivate: [AccessControlGuard],
        data: getGerenciadorRoles(),
      },

      {path: "avaliacoes", redirectTo: "avaliacoes/list"},
      {
        path: "avaliacoes/list",
        component: AvaliacoesListComponent,
        canActivate: [AccessControlGuard],
        data: getAvaliacaoRoles(),
      },

      {path: "notificacao", redirectTo: "notificacao/list"},
      {
        path: "notificacao/list",
        component: NotificacoesListComponent,
        canActivate: [AccessControlGuard],
        data: getNotificacaoRoles(),
      },
      {
        path: "notificacao/form",
        component: NotificacoesFormComponent,
        canActivate: [AccessControlGuard],
        data: getNotificacaoRoles(),
      },
      {
        path: "notificacao/form/:id",
        component: NotificacoesFormComponent,
        canActivate: [AccessControlGuard],
        data: getNotificacaoRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },

      {path: "central", redirectTo: "central/list"},
      {
        path: "central/list",
        component: CentralEntregasComponent,
        canActivate: [AccessControlGuard],
        data: getCentralEntregas(),
      },

      {path: "entregadores", redirectTo: "entregadores/list"},
      {
        path: "entregadores/list",
        component: EntregadoresListComponent,
        canActivate: [AccessControlGuard],
        data: getEntregadoresRoles(),
      },
      {
        path: "entregadores/form",
        component: EntregadoresFormComponent,
        canActivate: [AccessControlGuard],
        data: getEntregadoresRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },
      {
        path: "entregadores/form/:id",
        component: EntregadoresFormComponent,
        canActivate: [AccessControlGuard],
        data: getEntregadoresRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },

      {path: "entregas", redirectTo: "entregas/list"},
      {
        path: "entregas/list",
        component: EntregasListComponent,
        canActivate: [AccessControlGuard],
        data: getEntregasRoles(),
      },

      {path: "monitor", redirectTo: "monitor/list"},
      {
        path: "monitor/list",
        component: MonitorPedidosComponent,
        canActivate: [AccessControlGuard],
        data: getMonitorRoles(),
      },

      {path: "relatorio-geral", redirectTo: "relatorio-geral/list"},
      {
        path: "relatorio-geral/list",
        component: RelatorioPedidosGeralListComponent,
        canActivate: [AccessControlGuard],
        data: getRelatorioPedidosRoles(),
      },

      {path: "relatorio-comissoes", redirectTo: "relatorio-comissoes/list"},
      {
        path: "relatorio-comissoes/list",
        component: RelatorioComissoesListComponent,
        canActivate: [AccessControlGuard],
        data: getRelatorioComissoesRoles(),
      },

      {
        path: "comissoes-consultores",
        redirectTo: "comissoes-consultores/list",
      },
      {
        path: "comissoes-consultores/list",
        component: ComissoesConsultoresComponent,
        canActivate: [AccessControlGuard],
        data: getComissoesConsultoresRoles(),
      },

      {
        path: "promocoes", redirectTo: "promocoes/list",
      },
      {path: "financeiro", redirectTo: "financeiro/list"},
      {
        path: "financeiro/list",
        component: FinanceiroListComponent,
        canActivate: [AccessControlGuard],
        data: getFinanceiroRoles(),
      },

      {path: "faturas", redirectTo: "faturas/list"},
      {
        path: "faturas/list",
        component: FaturasListComponent,
        canActivate: [AccessControlGuard],
        data: getFaturasRoles(),
      },

      {path: "extrato", redirectTo: "extrato/list"},
      {
        path: "extrato/list",
        component: ExtratoPagamentoOnlineListComponent,
        canActivate: [AccessControlGuard],
        data: getExtratoOnlineRoles()
      },

      {path: "abatimentos", redirectTo: "abatimentos/list"},
      {
        path: "abatimentos/list",
        component: AbatimentosListComponent,
        canActivate: [AccessControlGuard],
        data: getAbatimentosRoles(),
      },
      {
        path: "abatimentos/form",
        component: AbatimentosFormComponent,
        canActivate: [AccessControlGuard],
        data: getAbatimentosRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },
      {
        path: "abatimentos/form/:id",
        component: AbatimentosFormComponent,
        canActivate: [AccessControlGuard],
        data: getAbatimentosRoles(),
        canDeactivate: [FormCanDeactivateGuard],
      },
      {
        path: "clientes",
        canActivate: [AccessControlGuard],
        data: getClientesRoles(),
        loadChildren: () => import("./modules/clientes/clientes.module").then(m => m.ClientesModule)
      },
      {
        path: "promocoes",
        canActivate: [AccessControlGuard],
        data: getPromocaoRoles(),
        loadChildren: () => import("./modules/promocoes/promocoes.module").then(m => m.PromocoesModule)
      },
    ],
  },
  {path: "login", loadChildren: () => import("./modules/login/login.module").then(m => m.LoginModule)},
  {path: "page-not-found", component: PageNotFoundComponent},
  {path: "**", redirectTo: "page-not-found"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
