import {Injectable} from "@angular/core";
import {AbstractControl} from "@angular/forms";
import {isNullOrUndefined} from "../utils/commons";

@Injectable({
  providedIn: "root"
})
export class CpfCnpjValidationService {

  validarCpf(control: AbstractControl): { [key: string]: boolean } | null {
    let cpf = control.value;
    if (isNullOrUndefined(cpf)) {
      return null;
    }

    // Remove a máscara
    cpf = cpf.toString().replace(/[^\d]+/g, "");

    if (cpf.length === 0) {
      return null;
    }

    let soma;
    let resto;
    soma = 0;
    if (cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999") {
      return {"cpf": true};
    }

    for (let i = 1; i <= 9; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }

    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.substring(9, 10), 10)) {
      return {"cpf": true};
    }

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }

    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.substring(10, 11), 10)) {
      return {"cpf": true};
    }

    return null;
  }

  validarCnpj(control: AbstractControl): { [key: string]: boolean } | null {
    let cnpj = control.value;
    if (isNullOrUndefined(cnpj)) {
      return null;
    }

    // Remove a máscara
    cnpj = cnpj.toString().replace(/[^\d]+/g, "");

    if (cnpj.length === 0) {
      return null;
    }

    if (cnpj.length >= 0 && cnpj.length < 14) {
      return {"cnpj": true};
    }

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999") {
      return {"cnpj": true};
    }

    // Valida DVs
    // Tamanho do cnpj sem os dígitos verificadores
    let tamanho = cnpj.length - 2;

    // Separa apenas os números do CPNJ sem os digitos verificadores
    let numeros = cnpj.substring(0, tamanho);

    // Separa apenas os dígitos verificadores
    const digitos = cnpj.substring(tamanho);

    let soma = 0;
    let pos = tamanho - 7;

    // Faz o cálculo do primeiro digito verificador baseado nos números do CNPJ
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    // Compara se o número calculado bate com o primeiro dígito verificador
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado.toString() !== digitos.charAt(0)) {
      return {"cnpj": true};
    }

    // Faz o cálculo do segundo digito baseado nos números do CNPJ
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    // Compara se o número calculado bate com o segundo dígito do verificador
    if (resultado.toString() !== digitos.charAt(1)) {
      return {"cnpj": true};
    }

    return null;
  }
}
