import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output} from "@angular/core";
import {AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";
import {MaskUtil} from "../../../utils/mask-util";
import {isNullOrUndefined} from "../../../utils/commons";
import {distinctUntilChanged, finalize, startWith} from "rxjs/operators";
import {LoadingService} from "../../../services/loading/loading.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import {Localizacao} from "../../../models/localizacao";
import {AngularFireFunctions} from "@angular/fire/functions";
import {EmpresaService} from "../empresa.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: "app-empresa-endereco-form",
  templateUrl: "./empresa-endereco-form.component.html",
  styleUrls: ["./empresa-endereco-form.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmpresaEnderecoFormComponent),
      multi: true
    }
  ]
})
export class EmpresaEnderecoFormComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input()
  localizacao: Localizacao;

  @Input()
  disableFields: boolean = false;

  @Input()
  hideCep: boolean = false;

  @Input()
  disableChooseLocation: boolean = false;

  @Input()
  enableScrollWheel: boolean = false;

  @Input()
  formControlName: string;

  @Output()
  formStatus = new EventEmitter();

  readonly maskCep = MaskUtil.maskCep;

  form: FormGroup;
  loadingName: string = "EmpresaEnderecoFormComponent";
  loadingCep: string = "loadingOverlayCep";

  enderecoFormatado;
  logradouroDisable: boolean = true;
  bairroDisable: boolean = true;

  public urlCorreios;

  // variável utilizada para retornar o valor cadastrado de latitude para o componente angular-google-maps.
  latitude;
  // variável utilizada para retornar o valor cadastrado de longitude para o componente angular-google-maps.
  longitude;

  onChange: any = () => {
  }
  onTouch: any = () => {
  }
  val: any;

  constructor(public formBuilder: FormBuilder,
              public loadingService: LoadingService,
              public dialogService: DialogService,
              public fns: AngularFireFunctions, public empresaService: EmpresaService,
              private sanitizer: DomSanitizer) {

    this.form = this.formBuilder.group({
      endereco: this.formBuilder.group({
        cep: ["", [Validators.required, this.validatorCep.bind(this)]],
        logradouro: ["", Validators.required],
        numero: ["", Validators.required],
        complemento: [""],
        bairro: ["", Validators.required],
        cidade: ["", Validators.required],
        estado: ["", Validators.required],
        codigoMunicipio: ["", Validators.required],
      }),
      latitude: ["", [Validators.required]],
      longitude: ["", [Validators.required]],
      fusoHorario: ["", [Validators.required]]
    });

    this.urlCorreios = this.sanitizer.bypassSecurityTrustUrl("http://www.buscacep.correios.com.br/sistemas/buscacep/");
  }

  ngOnInit() {
    this.form.statusChanges.pipe(
      startWith(this.form.status)
    ).subscribe(status => {
      this.formStatus.emit(status);
    });

    this.form.valueChanges
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe(value => {
        Object.keys(value).forEach(key => this.localizacao[key] = value[key]);
        this.setLocalizacao(this.localizacao);
      });

    this.form.markAllAsTouched();
  }

  ngOnChanges(): void {
    this.form.patchValue(this.localizacao);
    this.latitude = this.getLatitude();
    this.longitude = this.getLongitude();
  }

  validatorCep(control: AbstractControl): { [key: string]: boolean } | null {
    const cep = control.value;

    if (!cep) {
      return null;
    }

    if (cep.toString().replace(/[^\d]+/g, "").length < 8) {
      return {"incomplete": true};
    }

    return null;
  }

  buscarCep(keepNumero?: boolean) {
    if (this.cepControl.invalid)
      return;

    this.loadingService.register(this.loadingName);

    if (isNullOrUndefined(keepNumero) && !keepNumero) {
      // Limpar o endereço
      this.limparEndereco();
    }

    const handleError = (error?) => {
      if (isNullOrUndefined(keepNumero) && !keepNumero) {
        // Limpar o endereço
        this.limparEndereco();
      }

      this.dialogService.messageDialog()
        .message("Problemas ao carregar endereço! " + error)
        .show();
    };

    const cep: string = this.localizacao.endereco.cep.toString().replace(/[^\d]+/g, "");
    const functionGetEnderecoByCep = this.fns.httpsCallable("getEnderecoByCep");
    functionGetEnderecoByCep({cep: cep}).pipe(
      finalize(() => this.loadingService.timeOut(this.loadingName))
    ).subscribe(value => {
      this.enderecoControl.patchValue({
        cep: value.cep.replace(/\D/, ""),
        estado: value.uf,
        cidade: value.localidade,
        bairro: value.bairro,
        logradouro: value.logradouro,
        codigoMunicipio: value.ibge
      });
      this.enderecoFormatado = this.getEnderecoFormatado();

      // Quando o cep não tem campo o campo do objeto retorna com uma string vazia
      this.logradouroDisable = value.logradouro !== "";
      this.bairroDisable = value.bairro !== "";
    }, err => {
      handleError(err);
    });
  }

  get enderecoControl() {
    return this.form.get("endereco");
  }

  get cepControl() {
    return this.enderecoControl.get("cep");
  }

  private limparEndereco() {
    // Manter o cep
    const cep = this.cepControl.value;
    // Limpar o endereço
    this.enderecoControl.reset();
    // Setar o cep novamente
    this.cepControl.setValue(cep);
  }

  getEnderecoFormatado() {
    return this.form.get("endereco").get("logradouro").value
      + ", " +
      this.form.get("endereco").get("bairro").value
      + ", " +
      this.form.get("endereco").get("cidade").value
      + " - " +
      this.form.get("endereco").get("estado").value
      + ", " + " Brasil";
  }

  async receiveCoordinates(location) {
    if (location.latitude === undefined) {
      this.form.get("latitude").setValue(location.coords.lat);
      this.form.get("longitude").setValue(location.coords.lng);
    } else {
      this.form.get("latitude").setValue(location.latitude);
      this.form.get("longitude").setValue(location.longitude);
    }
    this.setTimezone();
  }

  // Obtém o valor de latitude cadastrado para a empresa da tela.
  getLatitude() {
    return this.form.get("latitude").value;
  }

  // Obtém o valor de longitude cadastrado para a empresa da tela.
  getLongitude() {
    return this.form.get("longitude").value;
  }

  writeValue(value: any): void {
    this.val = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setLocalizacao(localizacao: Localizacao) {
    this.val = localizacao;
    this.onChange(localizacao);
    this.onTouch();
  }

  setTimezone(): void {
    const latitude = this.form.get("latitude").value;
    const longitude = this.form.get("longitude").value;
    this.loadingService.showTopBar();
    this.empresaService.getTimezoneByCoords(latitude, longitude).subscribe(timezone => {
      this.form.get("fusoHorario").setValue(timezone);
    }, () => {
      this.loadingService.hideTopBar();
    }, () => {
      this.loadingService.hideTopBar();
    });
  }

  setCodigoIbge(): void {
    const cep: string = this.localizacao.endereco.cep.toString().replace(/[^\d]+/g, "");
    this.loadingService.showTopBar();
    this.fns.httpsCallable("getEnderecoByCep")({cep: cep}).pipe(
      finalize(() => this.loadingService.timeOut(this.loadingName))
    ).subscribe(value => {
      this.enderecoControl.patchValue({
        codigoMunicipio: value.ibge
      });
    }, ((_) => {
      this.loadingService.hideTopBar();
    }), () => {
      this.loadingService.hideTopBar();
    });
  }
}
