import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";
import {MaskUtil} from "../../../utils/mask-util";
import {Telefone} from "../../../models/telefone";
import {DialogService} from "../../../services/dialog/dialog.service";

@Component({
  selector: "app-empresa-telefone-dialog",
  templateUrl: "./empresa-telefone-dialog.component.html",
  styleUrls: ["./empresa-telefone-dialog.component.scss"]
})
export class EmpresaTelefoneDialogComponent implements OnInit {
  form: FormGroup;

  readonly maskCell = MaskUtil.maskCell;
  readonly maskFone = MaskUtil.maskFone;

  constructor(public formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<EmpresaTelefoneDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogService: DialogService) {
    this.form = this.formBuilder.group({
      tipo: ["", Validators.required],
      contato: [""],
      numero: ["", Validators.required],
      visivelNoSmartphone: [false, Validators.required],
      responsavel: [false, Validators.required],
      visivelNoTicket: [false, Validators.required]
    });
    this.form.patchValue(isNotNullOrUndefined(data.phoneForm) ? data.phoneForm : this.form.value);

  }

  // Máscara para o campo de telefone, muda de acordo com o tipo de telefone selecionado
  get maskForPhone() {
    return !isNullOrUndefined(this.form.get("tipo").value) &&
    this.form.get("tipo").value.toUpperCase() === "FIXO" ? this.maskFone : this.maskCell;
  }

  // Regex para o campo de telefone, muda de acordo com o tipo de telefone selecionado
  get regexForPhone() {
    if (isNullOrUndefined(this.form.get("tipo").value)) return null;

    return this.form.get("tipo").value.toUpperCase() === "FIXO" ? MaskUtil.regexFone : MaskUtil.regexCell;
  }

  addTelefone() {
    const telefone = new Telefone(this.form.getRawValue());

    if (telefone.responsavel && (isNullOrUndefined(telefone.contato.trim()) || telefone.contato.trim().length < 5)) {
      this.showMessagePhoneError("Nome de contato inválido");
      return;
    }

    // Verificar se o número é válido
    if (!(telefone.numero.match(this.regexForPhone))) {
      this.showMessagePhoneError("Número inválido");
      return;
    }

    // Verificar se o telefone já está na lista
    if (this.data.itemPhones.filter(t => t.numero === telefone.numero && t.tipo === telefone.tipo).length && !this.data.editPhone) {
      this.showMessagePhoneError("Este telefone já está cadastrado!");
      return;
    }

    return this.dialogRef.close(telefone);
  }

  showMessagePhoneError(message: string) {
    this.dialogService
      .messageDialog()
      .message(message)
      .show();
  }

  ngOnInit() {
    this.form.get("responsavel").valueChanges.subscribe(value => {
      if (value) {
        this.form.get("contato").setValidators([Validators.required, Validators.minLength(5)]);
        this.form.get("contato").updateValueAndValidity();
      } else {
        this.form.get("contato").clearValidators();
        this.form.get("contato").updateValueAndValidity();
      }
    });
  }

}
