import {Injectable, ViewContainerRef} from "@angular/core";
import {IAlertConfig, IConfirmConfig, IPromptConfig, TdDialogService} from "@covalent/core/dialogs";
import {Observable} from "rxjs";
import "rxjs/add/operator/map";
import {map} from "rxjs/operators";

@Injectable()
export class DialogService {

  constructor(private _dialogService: TdDialogService) {
  }

  public confirmDialog(): ConfirmDialog {
    return new ConfirmDialog(this._dialogService);
  }

  public confirmDeleteDialog(): ConfirmDialog {
    return this.confirmDialog()
      .message("Deseja realmente excluir este registro?")
      .cancelButton("Cancelar")
      .acceptButton("Excluir");
  }

  public messageDialog(): MessageDialog {
    return new MessageDialog(this._dialogService);
  }

  public promptDialog(): PromptDialog {
    return new PromptDialog(this._dialogService);
  }
}

export class ConfirmDialog {
  config: IConfirmConfig;

  constructor(private _dialogService: TdDialogService) {
    this.config = {
      message: "Informe uma mensagem",
      title: "Atenção",
      disableClose: false,
      viewContainerRef: null,
      acceptButton: "Sim",
      cancelButton: "Não"
    };
  }

  message(message: string): ConfirmDialog {
    this.config.message = message;
    return this;
  }

  title(title: string): ConfirmDialog {
    this.config.title = title;
    return this;
  }

  disableClose(disable: boolean): ConfirmDialog {
    this.config.disableClose = disable;
    return this;
  }

  viewContainerRef(view: ViewContainerRef): ConfirmDialog {
    this.config.viewContainerRef = view;
    return this;
  }

  acceptButton(acceptButton: string): ConfirmDialog {
    this.config.acceptButton = acceptButton;
    return this;
  }

  cancelButton(cancelButton: string): ConfirmDialog {
    this.config.cancelButton = cancelButton;
    return this;
  }

  show(): Observable<boolean> {
    return this._dialogService
      .openConfirm(this.config)
      .afterClosed()
      .pipe(
        map((resp: boolean) => resp)
      );
  }
}

export class MessageDialog {
  config: IAlertConfig;

  constructor(private _dialogService: TdDialogService) {
    this.config = {
      message: "Informe uma mensagem",
      title: "Atenção",
      disableClose: false,
      viewContainerRef: null,
      closeButton: "Fechar"
    };
  }

  message(message: string | Error): MessageDialog {
    if (typeof message === "string") {
      this.config.message = message;
    } else if (message instanceof Error) {
      this.config.message = message.message;
    }
    return this;
  }

  title(title: string): MessageDialog {
    this.config.title = title;
    return this;
  }

  disableClose(disable: boolean): MessageDialog {
    this.config.disableClose = disable;
    return this;
  }

  viewContainerRef(view: ViewContainerRef): MessageDialog {
    this.config.viewContainerRef = view;
    return this;
  }

  closeButton(closeButton: string): MessageDialog {
    this.config.closeButton = closeButton;
    return this;
  }

  show(): Observable<boolean> {
    return this._dialogService
      .openAlert(this.config)
      .afterClosed()
      .pipe(
        map((resp: boolean) => resp)
      );
  }
}

export class PromptDialog {
  config: IPromptConfig;

  constructor(private _dialogService: TdDialogService) {
    this.config = {
      message: "",
      disableClose: false, // defaults to false
      //viewContainerRef: this._viewContainerRef, //OPTIONAL
      title: "", //OPTIONAL, hides if not provided
      value: "", //OPTIONAL
      cancelButton: "Cancelar", //OPTIONAL, defaults to 'CANCEL'
      acceptButton: "Ok", //OPTIONAL, defaults to 'ACCEPT'
      width: "400px", //OPTIONAL, defaults to 400px
    };
  }

  message(message: string): PromptDialog {
    this.config.message = message;
    return this;
  }

  title(title: string): PromptDialog {
    this.config.title = title;
    return this;
  }

  disableClose(disable: boolean): PromptDialog {
    this.config.disableClose = disable;
    return this;
  }

  viewContainerRef(view: ViewContainerRef): PromptDialog {
    this.config.viewContainerRef = view;
    return this;
  }

  acceptButton(acceptButton: string): PromptDialog {
    this.config.acceptButton = acceptButton;
    return this;
  }

  show(): Observable<string> {
    return this._dialogService
      .openPrompt(this.config)
      .afterClosed()
      .pipe(
        map((value: string) => value)
      );
  }
}
