import {Injectable} from "@angular/core";
import {MatSnackBar, MatSnackBarRef, MatSnackBarVerticalPosition, SimpleSnackBar} from "@angular/material/snack-bar";
import {isNullOrUndefined} from "../../utils/commons";

@Injectable()
export class SnackService {

  constructor(private snack: MatSnackBar) {
  }

  public show(message: string, action?: string, durationMessage?: number, verticalPosition?: MatSnackBarVerticalPosition): MatSnackBarRef<SimpleSnackBar> {
    if (isNullOrUndefined(action)) {
      action = "Fechar";
    }
    if (isNullOrUndefined(durationMessage)) {
      durationMessage = 3000;
    }
    if (isNullOrUndefined(verticalPosition)) {
      verticalPosition = "bottom";
    }

    return this.snack.open(message, action, {
      duration: durationMessage,
      verticalPosition: verticalPosition
    });
  }
}
