import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output} from "@angular/core";
import {Complemento, ComplementoItem, Produto, RegraTotalizacao, regraTotalizacaoToString} from "../../../../models/produto.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import {ComplementoProdutoListDialogComponent} from "../../complemento-produto-list-dialog/complemento-produto-list-dialog.component";
import {ComplementoProdutoItemDatasource} from "../../../../models/datasource/complemento-produto-item.datasource";
import {ComplementoProduto} from "../../../../models/complemento-produto.model";
import {ComplementoProdutoItemDialogComponent} from "../complemento-produto-dialog/complemento-produto-dialog.component";
import {DialogService} from "../../../../services/dialog/dialog.service";
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../../../../utils/commons";
import {AngularFirestore} from "@angular/fire/firestore";
import {IFormCanDeactivate} from "../../../../guards/iform-canDeactivate";
import {SnackService} from "../../../../services/snack/snack.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
  selector: "adicional-produto-form",
  templateUrl: "./complemento-form.component.html",
  styleUrls: ["./complemento-form.component.scss"],
})
export class ComplementoFormComponent implements OnInit, IFormCanDeactivate, OnChanges {
  form: FormGroup;

  @Input()
  complemento: Complemento;

  @Input()
  produto?: Produto;

  @Output()
  editouComplemento = new EventEmitter();

  // Copia do complemento recebido no @Input
  item: Complemento;

  // Booleano que verifica o status da atualização de um formulário
  formMudou: boolean;

  readonly regrasTotalizacao: RegraTotalizacao[];
  readonly regraTotalizacaoToString = regraTotalizacaoToString;

  datasource: ComplementoProdutoItemDatasource;

  // Indica erro ao carregar os itens
  errorOnLoadItens: string;

  constructor(formBuilder: FormBuilder,
              private snack: SnackService,
              private db: AngularFirestore,
              private dialogService: DialogService,
              private dialog: MatDialog,
              private cdRef: ChangeDetectorRef
  ) {
    this.form = formBuilder.group({
      titulo: [
        "",
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
      quantidadeMinima: [1, [Validators.required, Validators.min(0)]],
      quantidadeMaxima: [1, [Validators.required, Validators.min(0)]],
      regraTotalizacao: ["", [Validators.required]],
      naoAplicaDesconto: [false],
      inactive: [false],
    });

    this.complemento = new Complemento();
    this.datasource = new ComplementoProdutoItemDatasource(
      db,
      this.complemento
    );
    this.regrasTotalizacao = Array.of(
      RegraTotalizacao.Soma,
      RegraTotalizacao.Media,
      RegraTotalizacao.Maior
    );

    /**
     * Inicializa método que verifica se ocorreram mudanças no conteúdo do formulário,
     * para decidir o comportamento do método podeMudarRota (canDeactivate)
     */
    this.onChanges();
  }

  ngOnInit() {
    // Ler as mudanças de valores no formulário
    this.form.valueChanges.subscribe((value) => {
      Object.keys(value).forEach((key) => (this.complemento[key] = value[key]));
    });
  }

  ngOnChanges() {
    // Verifica se o adicionalEditar existe, se ele for undefined significa que o complemento é novo
    if (this.complemento === undefined || null) {
      this.complemento = new Complemento();
    }

    // seta os itens do complemento recupearado para a variável de complemento do component
    this.datasource.data = this.complemento.itens;

    // seta os dados do complemento no formulário
    this.form.patchValue(this.complemento);

    // Marcar o formulário como ainda não clicado
    this.form.markAsUntouched();

    // Detectar as alterações no formulário
    this.cdRef.detectChanges();
  }

  addComplementos() {
    ComplementoProdutoListDialogComponent.showDialog(
      this.dialog,
      this.datasource
    ).subscribe((complementos: ComplementoProduto[]) => {
      if (complementos) {
        complementos.forEach((complemento) => {
          const item = new ComplementoItem();
          item.nome = complemento.nome;
          item.descricao = complemento.descricao;
          item.preco = complemento.preco;
          item.codigoPDV = complemento.codigoPDV;
          item.complementoProduto = complemento.ref;
          item.inactive = complemento.inactive;
          this.datasource.push(item);
        });
      }
    });
  }

  editItemAdicionalProduto(item: ComplementoItem) {
    this.dialog.open(ComplementoProdutoItemDialogComponent, {data: item});
  }

  novoItemAdicionalProduto() {
    this.dialog
      .open(ComplementoProdutoItemDialogComponent, {data: null})
      .beforeClose()
      .subscribe((item: ComplementoItem) => {
        if (isNotNullOrUndefined(item)) {
          this.datasource.push(item);
        }
      });
  }

  removeItem(item: ComplementoItem) {
    this.dialogService
      .confirmDeleteDialog()
      .show()
      .subscribe((accept) => {
        if (accept) {
          // Remove no datasource
          this.datasource.remove(item);
          // Atualiza o this.complemento com o datasource
          this.complemento.itens = this.datasource.data;
          this.complemento.itensRemoved = this.datasource.dataRemoved;
        }
      });
  }

  confirmar() {
    // Para não perguntar se o usuário deseja sair ao salvar o formulário eu forço o valor da variável para false.
    this.formMudou = true;

    // Verificar se a quantidade máxima é maior que zero e a
    // quantidade mínima informada é maior que a quantidade máxima
    if (
      this.complemento.quantidadeMaxima > 0 &&
      this.complemento.quantidadeMinima > this.complemento.quantidadeMaxima
    ) {
      this.showMessage(
        "A quantidade mínima não pode ser maior que a quantidade máxima!"
      );
      return;
    }

    // Validar a regra de totalização
    // A mesma é obrigatória quando se pode escolher mais de um item da lista
    if (
      this.complemento.quantidadeMaxima !== 1 &&
      isEmpty(this.complemento.regraTotalizacao)
    ) {
      this.showMessage(
        "Para essa configuração, você precisa informar uma Regra de totalização!"
      );
      return;
    }

    // Verificar se tem algum item que a quantidade máxima informada
    // é maior que a quantidade máxima informada no complemento
    const hasItemWithQtdeMaxMoreThanComplemento = this.complemento.itens.some(
      (item) =>
        this.complemento.quantidadeMaxima > 0 &&
        item.quantidadeMaxima > this.complemento.quantidadeMaxima
    );

    if (hasItemWithQtdeMaxMoreThanComplemento) {
      this.showMessage(
        "Existe um ou mais itens com a quantidade máxima maior que do complemento!"
      );
      return;
    }

    // Verificar se o complemento tem um id
    if (isNullOrUndefined(this.complemento.id)) {
      // Gera um id para o complemento
      this.complemento.id = this.db.createId();
    }

    const indexOf = (complementoItem: ComplementoItem): number =>
      this.complemento.itens.indexOf(complementoItem);
    this.complemento.itens.forEach((comp) => (comp.sequence = indexOf(comp)));

    this.produto.addComplemento(this.complemento);

    this.snack.show("Complemento incluído com sucesso!");
    this.editouComplemento.emit();
  }

  showMessage(message) {
    this.dialogService.messageDialog().message(message).show();
  }

  // Verifica se ocorreram mudanças no formulário,alterando o valor da variável formMudou para true, ou mantendo em null por padrão.
  onChanges() {
    let mudanca = 0;
    this.form.valueChanges.subscribe(() => {
      if (mudanca === 0) {
        this.formMudou = false;
        mudanca++;
      } else {
        this.formMudou = true;
      }
    });
  }

  // Caso o formulário foi modificado
  podeMudarRota(): boolean {
    if (this.formMudou) {
      return confirm(
        "Os dados ainda não foram salvos. Deseja sair mesmo assim?"
      );
    }
    return true;
  }

  cancel() {
    this.dialogService
      .confirmDialog()
      .message("Cancelar as alterações?")
      .show()
      .subscribe((cancel: boolean) => {
        if (cancel) {
          this.editouComplemento.emit();
        }
      });
  }

  drop(
    event: CdkDragDrop<ComplementoItem[]>,
    complementoItens: ComplementoItem[]
  ) {
    moveItemInArray(complementoItens, event.previousIndex, event.currentIndex);
    this.datasource.data = complementoItens.map((complemento, index) => {
      complemento.sequence = index;
      return complemento;
    });
    this.complemento.itens = this.datasource.data;
  }
}
