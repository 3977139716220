import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {isArray} from "../utils/commons";
import {catchError} from "rxjs/operators";

@Injectable()
export class OneSignalService {

  private host = "https://onesignal.com/api/v1/notifications";
  private headers = new HttpHeaders({
    "Content-Type": "application/json; charset=utf-8",
    "Authorization": "Basic " + environment.onesignal_rest_api_key
  });

  constructor(public http: HttpClient) {
  }

  deleteNotification(notification_id): Observable<any> {
    return this.http.delete(this.host + "/" + notification_id + "?app_id=" + environment.onesignal_app_id, {
        headers: this.headers
      }
    ).pipe(
      catchError(this.handleError)
    );
  }

  sendNotification(message): Observable<any> {
    return this.http.post(this.host, message, {
      headers: this.headers
    }).pipe(
      catchError(this.handleError)
    );
  }

  getById(id: string): Observable<any> {
    return this.http.get<any>(this.host + "/" + id + "?app_id=" + environment.onesignal_app_id, {
      headers: this.headers
    }).pipe(
      catchError(this.handleError)
    );
  }

  getNotifications(): Observable<any> {
    return this.http.get<any>(this.host + "?app_id=" + environment.onesignal_app_id, {
      headers: this.headers
    }).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error && error.error && error.error.errors) {
      if (isArray(error.error.errors)) {
        return throwError(error.error.errors[0]);
      }
    }
    return throwError(error);
  }
}
