import {Repository} from "../repository/Repository";
import {Extrato} from "../models/extrato.model";
import {AngularFirestore} from "@angular/fire/firestore";
import {AuthService} from "../modules/login/auth.service";
import {Injectable} from "@angular/core";
import {Criterion} from "./firebase/criteria/criterion";
import {$query, PoMQuery} from "./firebase/criteria/query";
import {Observable} from "rxjs/Rx";
import {isEmpty} from "../utils/commons";
import {PagamentoOnline} from "../models/pagamento-online.model";

@Injectable({
  providedIn: "root"
})
export class ExtratoService extends Repository<Extrato> {

  constructor(db: AngularFirestore,
              public auth: AuthService) {
    super(db, "extratos", true);
  }

  getExtratoByCnpj(): Observable<Extrato[]> {
    return super.col$($query(new Criterion("empresa.cnpj", "==", this.auth.currentEmpresa.cnpj)));
  }

  getExtratoBy(pagamentoOnline: PagamentoOnline, criterions?: Criterion[]): Observable<Extrato[]> {
    const filters = criterions ? criterions : [];
    if (!isEmpty(pagamentoOnline) && !isEmpty(pagamentoOnline.accountId)) {
      const accountIds: string[] = [pagamentoOnline.accountId];
      accountIds.push(...(pagamentoOnline.oldAccounts ? pagamentoOnline.oldAccounts.map(o => o.accountId) : []));
      console.log(accountIds);
      filters.push(new Criterion("accountId", "in", accountIds));
    }
    return super.col$(new PoMQuery(filters));
  }

  deserialize(value: any): Extrato {
    return new Extrato(value);
  }
}
