import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {Estado, estados} from "../../../../models/estado.model";
import {Observable, Subscription} from "rxjs";
import {Cidade} from "../../../../models/cidade.model";
import {Empresa, TipoPessoa} from "../../../../models/empresa";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {BancoMaskUtils} from "../../../../utils/banco-mask-utils";
import {MaskUtil} from "../../../../utils/mask-util";
import {isEmpty, isNotNullOrUndefined, isNullOrUndefined} from "../../../../utils/commons";
import {
  PagtOnlineVerificationType,
  PgtOnlineStatus,
  DiaSemana,
  SaqueAutomatico, PagamentoOnline
} from "../../../../models/pagamento-online.model";
import {MatExpansionPanel} from "@angular/material/expansion";
import {AuthService} from "../../../../modules/login/auth.service";
import {CpfCnpjValidationService} from "../../../../services/cpf-cnpj-validation.service";
import {CidadeService} from "../../../cidade/cidade.service";
import {LoadingService} from "../../../../services/loading/loading.service";
import {PerfilService} from "../../../perfil-acesso/perfil.service";
import {CustomersIuguService} from "../../../../services/iugu/customers.iugu.service";
import {DialogService} from "../../../../services/dialog/dialog.service";
import {EmpresaService} from "../../../empresa/empresa.service";
import {map, mergeMap} from "rxjs/operators";
import {Role} from "../../../../models/perfil-acesso.model";
import {of} from "rxjs/observable/of";
import {MatOptionSelectionChange} from "@angular/material/core";
import {StringUtils} from "../../../../utils/string-utils";

@Component({
  selector: "app-subconta-form",
  templateUrl: "./subconta-form.component.html",
  styleUrls: ["./subconta-form.component.scss"]
})
export class SubcontaFormComponent implements OnInit, OnDestroy {

  @Input()
  pagamentoOnline: PagamentoOnline;

  @Input()
  subcontaEspelho: boolean;

  @Output()
  formMudouEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  formValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  estados: Estado[] = estados;
  cidades: Observable<Cidade[]>;
  bancos = [
    "Itaú", "Bradesco", "Caixa Econômica", "Banco do Brasil",
    "Santander", "Banrisul", "Sicredi", "Sicoob", "Inter", "BRB",
    "Via Credi", "Neon", "Votorantim", "Nubank", "Pagseguro",
    "Banco Original", "Safra", "Modal", "Banestes", "Unicred",
    "Money Plus", "Mercantil do Brasil", "Banco C6", "BS2",
    "Banco Topazio", "Uniprime", "Stone", "Banco Daycoval",
    "Gerencianet Pagamentos do Brasil", "JP Morgan", "Rendimento",
    "Citibank", "Banco do Nordeste", "PJBank", "Agibank",
    "Banpará", "Cooperativa Central de Credito Noroeste Brasileiro",
    "Uniprime Norte do Paraná", "Global SCM", "Next"
  ];
  bankTypes = ["Conta Corrente", "Conta Poupança"];
  bancos$;
  empresa: Empresa;

  loadingName: string;

  form: FormGroup;
  formSaque: FormGroup;
  formAntecipacao: FormGroup;
  selectedBanco = new FormControl();

  formMudou: boolean = null;

  maskAgencia: any = BancoMaskUtils.maskAgencia4D;
  maskConta: any = [/\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, "-", /\w/];
  regexAgencia: any = BancoMaskUtils.regexAgencia4;
  regexConta: any = BancoMaskUtils.regexBanco8D;
  maskCpfCnpj: any = MaskUtil.maskCnpj;
  maskFone = MaskUtil.maskCell;
  readonly maskCep = MaskUtil.maskCep;
  readonly maskCpf = MaskUtil.maskCpf;

  isNotNullOrUndefined = isNotNullOrUndefined;

  pgtOnlineStatus = PgtOnlineStatus;
  pagtOnlineVerificationType = PagtOnlineVerificationType;
  tipoPessoa = TipoPessoa;

  saving: boolean = false;
  sendVerificationError: boolean = false;
  pagamentoIsEnabled: Observable<boolean>;
  hasPagamentoOnlinePermission: boolean = false;

  empresaSubscription: Subscription = new Subscription();
  hasPagamentoOnlineSubscription: Subscription = new Subscription();
  disableSubscription: Subscription = new Subscription();

  @ViewChild("expansionPanel", {static: false}) expansionPanel: MatExpansionPanel;
  @ViewChild("advanceExpansionPanel", {static: false}) advanceExpansionPanel: MatExpansionPanel;
  DiaSemana = DiaSemana;

  constructor(public formBuilder: FormBuilder,
              public auth: AuthService,
              public cpfCnpjValidator: CpfCnpjValidationService,
              public cidadeService: CidadeService,
              public loading: LoadingService,
              public perfil: PerfilService,
              public customersIuguService: CustomersIuguService,
              public dialog: DialogService,
              public empresaService: EmpresaService) {

    this.form = formBuilder.group({
      bank: ["", Validators.required],
      bank_ag: ["", [Validators.required, Validators.pattern(this.regexAgencia)]],
      account_type: ["", Validators.required],
      bank_cc: ["", [Validators.required, Validators.pattern(this.regexConta)]],
      person_type: ["", [Validators.required]],
      cnpj: ["", [Validators.required]],
      razaoSocial: ["", [Validators.required]],
      telephone: ["", [Validators.required]],
      business_type: ["", [Validators.required]],
      address: ["", [Validators.required]],
      cep: ["", [Validators.required, Validators.pattern(MaskUtil.regexCep)]],
      state: ["", [Validators.required]],
      city: ["", [Validators.required]],
      resp_name: [""],
      resp_cpf: [""],
    });

    // O formSaque será construido somente se não for cadastro de conta espelho
    if (!this.subcontaEspelho) {
      this.formSaque = formBuilder.group({
        active: [false],
        valorMinimo: [0, [Validators.required]],
        diaSemana: [null]
      });
    }

    this.formAntecipacao = formBuilder.group({
      active: [false],
      type: ["days_after_payment", [Validators.required]],
      option: [1, [Validators.required]]
    });

    this.bancos = this.bancos.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    this.bancos$ = this.bancos;
    this.empresa = this.auth.currentEmpresa;

    this.pagamentoIsEnabled = this.empresaService.getEmpresa(this.empresa.id).pipe(map(emp => {
      this.empresa = emp;
      if (isNotNullOrUndefined(emp.pagamentoOnline)) {
        return emp.pagamentoOnline.enabled;
      }
    }));

    this.hasPagamentoOnlineSubscription = this.perfil.getPerfilAcessoLoggedUser().subscribe(perf => {
      this.hasPagamentoOnlinePermission = perf.hasRole(Role.FormaPagamentoOnlineUpdate);
    });
  }

  // Atualizar a Mask de telefone para maskCell caso o usuário digite o 11° número do telefone
  applyMaskToPhone() {
    const size = (this.form.get("telephone").value.toString().replace(/[^\d]+/g, "").trim()).length + 1;
    if (size > 10) {
      this.maskFone = MaskUtil.maskCell;
    }
  }

  ngOnInit() {
    this.loadingName = this.subcontaEspelho ? "SubcontaEspelhoFormComponent" : "SubcontaFormComponent";
    this.loading.register(this.loadingName);

    // Atualizar lista de bancos com autocomplete
    this.selectedBanco.valueChanges.pipe(
      map(controlValue => {
        if (controlValue) {
          if (controlValue === "Caixa Econômica") {

            this.bankTypes = [
              "001 – Conta Corrente de Pessoa Física", "002 – Conta Simples de Pessoa Física", "003 – Conta Corrente de Pessoa Jurídica",
              "013 – Poupança de Pessoa Física", "1288 – Poupança de Pessoa Física (para contas de novo formato)", "022 – Poupança de Pessoa Jurídica"
            ];
          } else {
            this.bankTypes = ["Conta Corrente", "Conta Poupança"];
          }

          this.bancos$ = this.bancos.filter(banco => {
            return StringUtils.normalize(banco.toLowerCase()).includes(StringUtils.normalize(controlValue.toLowerCase()));
          });
        } else {
          this.form.get("bank").patchValue(null);
          this.bankTypes = ["Conta Corrente", "Conta Poupança"];
          this.bancos$ = this.bancos;
        }
      })
    ).subscribe();

    // Mudar comportamento dos forms de acordo com o tipo de pessoa
    this.form.get("person_type").valueChanges.subscribe(value => {
      this.form.get("cnpj").patchValue("");
      this.form.get("resp_name").patchValue(null);
      this.form.get("resp_cpf").patchValue(null);
      if (value === TipoPessoa[TipoPessoa.JURIDICA]) {

        // Adicionar Validators e Mask no campo de CPF/CNPJ para funcionar como CNPJ
        this.maskCpfCnpj = MaskUtil.maskCnpj;
        this.form.get("cnpj").setValidators([Validators.required, Validators.pattern(MaskUtil.regexCNPJ), this.cpfCnpjValidator.validarCnpj.bind(this)]);
        this.form.get("cnpj").updateValueAndValidity({emitEvent: false});

        // Adicionar Validators nos em resp_name e resp_cpf, caso o tipo de pessoa seja Jurídica
        this.form.get("resp_name").setValidators([Validators.required, Validators.minLength(10)]);
        this.form.get("resp_name").updateValueAndValidity({emitEvent: false});
        this.form.get("resp_cpf").setValidators([Validators.required, Validators.pattern(MaskUtil.regexCPF), this.cpfCnpjValidator.validarCpf.bind(this)]);
        this.form.get("resp_cpf").updateValueAndValidity({emitEvent: false});

      } else if (value === TipoPessoa[TipoPessoa.FISICA]) {

        // Adicionar Validators e Mask no campo de CPF/CNPJ para funcionar como CPF
        this.maskCpfCnpj = MaskUtil.maskCpf;
        this.form.get("cnpj").setValidators([Validators.required, Validators.pattern(MaskUtil.regexCPF), this.cpfCnpjValidator.validarCpf.bind(this)]);
        this.form.get("cnpj").updateValueAndValidity({emitEvent: false});

        // Remover Validators de resp_name e resp_cpf, caso o tipo de pessoa deja Física
        this.form.get("resp_name").clearValidators();
        this.form.get("resp_name").updateValueAndValidity({emitEvent: false});
        this.form.get("resp_cpf").clearValidators();
        this.form.get("resp_cpf").updateValueAndValidity({emitEvent: false});
      }
    });

    // Atualizar a Mask e o Regex do telefone dependendo da quantidade de digitos
    this.form.get("telephone").valueChanges.subscribe(value => {
      const _size = (value.toString().replace(/[^\d]+/g, "").trim()).length;
      if (_size < 11) {
        this.maskFone = MaskUtil.maskFone;
        this.form.get("telephone").setValidators([Validators.required, Validators.pattern(MaskUtil.regexFone)]);
        this.form.get("telephone").updateValueAndValidity({emitEvent: false});
      } else {
        this.form.get("telephone").setValidators([Validators.required, Validators.pattern(MaskUtil.regexCell)]);
        this.form.get("telephone").updateValueAndValidity({emitEvent: false});
      }
    });

    // Limpar cidade e atualizar lista de cidades
    this.form.get("state").valueChanges.subscribe(() => {
      this.form.get("city").patchValue("");
      this.setCidades();
    });

    // Adicionar ou remover validators de acordo com o valor de "type" da Antecipação, e limpa o campo de "option"
    this.formAntecipacao.get("type").valueChanges.subscribe(value => {
      this.formAntecipacao.get("option").patchValue(null);
      if (value === "daily") {
        this.formAntecipacao.get("option").clearValidators();
        this.formAntecipacao.get("option").updateValueAndValidity();
      } else {
        this.formAntecipacao.get("option").setValidators(Validators.required);
        this.formAntecipacao.get("option").updateValueAndValidity();
      }
    });
    // Adicionar ou remover Validators da Antecipação, ao ativar ou desativar a mesma
    this.formAntecipacao.get("active").valueChanges.subscribe(active => {
      if (active) {
        this.formAntecipacao.get("type").setValidators(Validators.required);
        if (this.formAntecipacao.get("type").value !== "daily") {
          this.formAntecipacao.get("option").setValidators(Validators.required);
        } else {
          this.formAntecipacao.get("option").clearValidators();
        }
        this.formAntecipacao.get("type").updateValueAndValidity({emitEvent: false});
        this.formAntecipacao.get("option").updateValueAndValidity({emitEvent: false});
      } else {
        this.formAntecipacao.get("active").clearValidators();
        this.formAntecipacao.get("option").clearValidators();
        this.formAntecipacao.get("type").updateValueAndValidity({emitEvent: false});
        this.formAntecipacao.get("option").updateValueAndValidity({emitEvent: false});
      }
    });

    this.setCidades();

    this.formatarAgencia();

    this.formatarConta();

    this.caixaAccountTypeChange();

    this.preencherCampos();
  }

  preencherCampos() {
    this.empresaSubscription = this.empresaService.getEmpresa(this.empresa.id).pipe(
      mergeMap(empresa => {
        this.empresa = empresa;
        if (isNotNullOrUndefined(this.pagamentoOnline) && isNotNullOrUndefined(this.pagamentoOnline.accountId)) {

          // Preencher os valores de Saque Automático
          if (!this.subcontaEspelho && isNotNullOrUndefined(this.pagamentoOnline.saqueAutomatico)) {
            const saqueAutmatico = new SaqueAutomatico(this.pagamentoOnline.saqueAutomatico);
            this.formSaque.patchValue({
              active: saqueAutmatico.active ? saqueAutmatico.active : false,
              valorMinimo: saqueAutmatico.valorMinimo ? saqueAutmatico.valorMinimo : 0,
              diaSemana: saqueAutmatico.diaSemana
            });
          }

          return this.customersIuguService.buscarSubcontaInfo(this.pagamentoOnline).pipe(map(subcontaInfo => {
            if (subcontaInfo) {

              // Preencher valors de Antecipação de Recebíveis
              if (isNotNullOrUndefined(subcontaInfo.auto_advance)) {
                this.formAntecipacao.get("active").patchValue(subcontaInfo.auto_advance);
                if (isNotNullOrUndefined(subcontaInfo.auto_advance_type)) this.formAntecipacao.get("type").patchValue(subcontaInfo.auto_advance_type);
                if (isNotNullOrUndefined(subcontaInfo.auto_advance_option)) this.formAntecipacao.get("option").patchValue(subcontaInfo.auto_advance_option);
              }

              if (isNotNullOrUndefined(subcontaInfo.last_verification_request_status)) {

                let subconta;
                if (subcontaInfo.last_verification_request_status === PgtOnlineStatus[PgtOnlineStatus.ACCEPTED].toLowerCase()) {
                  subconta = this.createInformatioObject(subcontaInfo.informations);
                } else {
                  subconta = subcontaInfo.last_verification_request_data;
                }

                if (isNotNullOrUndefined(subconta)) {
                  this.selectedBanco.patchValue(subconta.bank);

                  if (subconta.bank === "Caixa Econômica") {
                    // Tratar o bank_cc e o account_type para se adequar ao formato da Caixa Econômica
                    const caixa_cc = subconta.bank_cc.length === 15 ? subconta.bank_cc.slice(0, 4) : subconta.bank_cc.slice(0, 3);
                    if (caixa_cc === "001") subconta.account_type = "001 – Conta Corrente de Pessoa Física";
                    if (caixa_cc === "002") subconta.account_type = "002 – Conta Simples de Pessoa Física";
                    if (caixa_cc === "003") subconta.account_type = "003 – Conta Corrente de Pessoa Jurídica";
                    if (caixa_cc === "013") subconta.account_type = "013 – Poupança de Pessoa Física";
                    if (caixa_cc === "1288") subconta.account_type = "1288 – Poupança de Pessoa Física (para contas de novo formato)";
                    if (caixa_cc === "022") subconta.account_type = "022 – Poupança de Pessoa Jurídica";
                    subconta.bank_cc = subconta.bank_cc.length === 15 ? subconta.bank_cc.slice(4) : subconta.bank_cc.slice(3);
                  } else {
                    subconta.account_type = "Corrente" ? "Conta Corrente" : "Conta Poupança";
                  }

                  this.form.patchValue({
                    bank: subconta.bank,
                    bank_ag: subconta.bank_ag,
                    account_type: subconta.account_type,
                    bank_cc: subconta.bank_cc,
                    person_type: subconta.person_type === "Pessoa Física" ? TipoPessoa[TipoPessoa.FISICA] : TipoPessoa[TipoPessoa.JURIDICA],
                    cnpj: subconta.person_type === "Pessoa Física" ? subconta.cpf : subconta.cnpj,
                    razaoSocial: subconta.person_type === "Pessoa Física" ? subconta.name : subconta.company_name,
                    telephone: subconta.telephone,
                    business_type: subconta.business_type,
                    address: subconta.address,
                    cep: subconta.cep,
                    state: this.estados.find(estado => estado.nome === subconta.state || estado.uf === subconta.state).nome,
                    city: subconta.city,
                    resp_name: subconta.person_type === "Pessoa Jurídica" ? subconta.resp_name : "",
                    resp_cpf: subconta.person_type === "Pessoa Jurídica" ? subconta.resp_cpf : ""
                  });

                  if (this.pagamentoOnline.status === PgtOnlineStatus[PgtOnlineStatus.PENDING]) {
                    this.form.disable({emitEvent: false});
                    this.selectedBanco.disable();
                  } else if ((this.pagamentoOnline.status === PgtOnlineStatus[PgtOnlineStatus.ACCEPTED]) ||
                    (this.pagamentoOnline.status === PgtOnlineStatus[PgtOnlineStatus.REJECTED] &&
                      this.pagamentoOnline.verificationType === PagtOnlineVerificationType[PagtOnlineVerificationType.BANK_VERIFICATION])) {
                    this.form.disable({emitEvent: false});
                    this.form.get("bank").enable({emitEvent: false});
                    this.form.get("bank_ag").enable({emitEvent: false});
                    this.form.get("bank_cc").enable({emitEvent: false});
                    this.form.get("account_type").enable({emitEvent: false});
                  }
                  this.loading.timeOut(this.loadingName);
                } else {
                  this.sendVerificationError = true;
                  this.preencherDadosEmpresa(empresa);
                }
              } else {
                this.sendVerificationError = true;
                this.preencherDadosEmpresa(empresa);
              }
            }
            if (this.auth.user.isLojista() || !this.hasPagamentoOnlinePermission) {
              this.form.disable({emitEvent: false});
              this.selectedBanco.disable({emitEvent: false});
              if (this.formSaque) {
                this.formSaque.disable({emitEvent: false});
              }
              this.formAntecipacao.disable({emitEvent: false});
            }
            this.onChanges();
            this.formMudou = false;
            this.sendFormValidation();
          }, error => {
            this.dialog.messageDialog().message(error).show();
            this.loading.hideTopBar();
            if (this.auth.user.isLojista() || !this.hasPagamentoOnlinePermission) {
              this.form.disable({emitEvent: false});
              this.selectedBanco.disable({emitEvent: false});
              if (this.formSaque) {
                this.formSaque.disable({emitEvent: false});
              }
              this.formAntecipacao.disable({emitEvent: false});
            }
            this.sendFormValidation();
          }));
        } else {
          this.preencherDadosEmpresa(empresa);
          this.onChanges();
          this.formMudou = false;
          this.sendFormValidation();
          return of();
        }
      })
    ).subscribe();
  }

  // Transformar o valores da array "information", das informações da subconta, em um objeto único
  createInformatioObject(subconta: any[]) {
    return subconta.reduce(function (obj, item) {
      obj[item.key] = item.value;
      return obj;
    }, {});
  }

  // Atualizar lista de cidades
  setCidades() {
    this.cidades = this.cidadeService.col$().pipe(map((cidades) => {
      if (isNotNullOrUndefined(this.form.get("state").value) && !isEmpty(this.form.get("state").value)) {
        return cidades.filter(cidade => cidade.uf === this.estados.find(estado => estado.nome === this.form.get("state").value).uf);
      } else {
        return [];
      }
    }));
  }

  selectBanco(banco: string, event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.form.get("bank").patchValue(banco);
      this.form.get("bank_ag").patchValue("");
      this.form.get("bank_cc").patchValue("");
    }
  }

  preencherDadosEmpresa(empresa: Empresa) {
    // Verificar se tem empresa, ou se já foi adicionado valores da empresa nos formulários
    if (empresa && (isNullOrUndefined(this.form.get("person_type").value) || isEmpty(this.form.get("person_type").value))) {

      this.form.get("person_type").patchValue(empresa.pessoa);

      if (isNotNullOrUndefined(empresa.descricao)) {
        this.form.get("business_type").patchValue(empresa.descricao);
      }

      this.form.get("address").patchValue(empresa.localizacao.endereco.logradouro + ", " + empresa.localizacao.endereco.numero + ", " +
        (isNotNullOrUndefined(empresa.localizacao.endereco.complemento) ? empresa.localizacao.endereco.complemento : "") + ", " + empresa.localizacao.endereco.bairro
      );
      this.form.get("state").patchValue(this.estados.find((estado) => estado.uf === empresa.localizacao.endereco.estado).nome, {emitEvent: false});
      this.form.get("cnpj").patchValue(empresa.cnpj);
      this.form.get("razaoSocial").patchValue(empresa.razaoSocial);
      this.form.get("cep").patchValue(empresa.localizacao.endereco.cep);
      this.form.get("city").patchValue(empresa.localizacao.endereco.cidade);

      this.loading.timeOut(this.loadingName);

      this.disableSubscription = this.perfil.getPerfilAcessoLoggedUser().subscribe(perf => {
        if (!perf.hasRole(Role.FormaPagamentoOnlineUpdate) || this.auth.user.isLojista()) {
          this.form.disable({emitEvent: false});
          this.selectedBanco.disable({emitEvent: false});
        }
      });
    }
  }

  // Modificar a Mask e o Regex da agencia de acordo com o banco selecionado
  formatarAgencia() {
    this.form.get("bank").valueChanges.subscribe(value => {
      if (value === "Money Plus") {
        this.maskAgencia = BancoMaskUtils.maskAgencia1;
        this.regexAgencia = BancoMaskUtils.regexAgencia1;
      } else if (value === "Banco do Brasil" || value === "Bradesco" || value === "Rendimento" || value === "Next" || value === "Bradesco/Next") {
        this.maskAgencia = BancoMaskUtils.maskAgencia4D;
        this.regexAgencia = BancoMaskUtils.regexAgencia4D;
      } else if (value === "Banco do Nordeste") {
        this.maskAgencia = BancoMaskUtils.maskAgencia3;
        this.regexAgencia = BancoMaskUtils.regexAgencia3;
      } else if (value === "Santander" || value === "Caixa Econômica"
        || value === "Banrisul" || value === "Sicredi"
        || value === "Sicoob" || value === "Inter"
        || value === "BRB" || value === "Via Credi"
        || value === "Neos" || value === "Votorantim"
        || value === "Nubank" || value === "PagSeguro"
        || value === "Banco Original" || value === "Safra"
        || value === "Modal" || value === "Banestes"
        || value === "Unicred" || value === "Mercantil do Brasil"
        || value === "JP Morgan" || value === "Gerencianet Pagamentos do Brasil"
        || value === "Banco C6" || value === "BS2"
        || value === "Banco Topazio" || value === "Uniprime"
        || value === "Stone" || value === "Banco Daycoval"
        || value === "Citibank" || value === "Itaú"
        || value === "PJBank" || value === "Agibank"
        || value === "Banpará" || value === "Cooperativa Central de Credito Noroeste Brasileiro"
        || value === "Uniprime Norte do Paraná" || value === "Global SCM"
      ) {
        this.maskAgencia = BancoMaskUtils.maskAgencia4;
        this.regexAgencia = BancoMaskUtils.regexAgencia4;
      } else {
        this.maskAgencia = BancoMaskUtils.maskAgencia4;
        this.regexAgencia = BancoMaskUtils.regexAgencia4;
      }
      this.updateAgenciaVality();
    });
  }

  // Modificar a Mask e o Regex do número da conta de acordo com o banco selecionado
  formatarConta() {
    this.form.get("bank").valueChanges.subscribe(value => {
      if (value === "Via Credi" || value === "JP Morgan") {
        this.maskConta = BancoMaskUtils.maskBanco11D;
        this.regexConta = BancoMaskUtils.regexBanco11D;
      } else if (value === "Nubank" || value === "PJBank") {
        this.maskConta = BancoMaskUtils.maskBanco10D;
        this.regexConta = BancoMaskUtils.regexBanco10D;
      } else if (value === "Banrisul" || value === "Sicoob" || value === "Inter" ||
        value === "BRB" || value === "Modal" || value === "Banpará") {
        this.maskConta = BancoMaskUtils.maskBanco9D;
        this.regexConta = BancoMaskUtils.regexBanco9D;
      } else if (value === "Banco do Brasil" || value === "Santander" ||
        value === "PagSeguro" || value === "Gerencianet Pagamentos do Brasil" ||
        value === "Safra" || value === "Banestes" || value === "Unicred" ||
        value === "Money Plus" || value === "Mercantil do Brasil" || value === "Caixa Econômica"
      ) {
        this.maskConta = BancoMaskUtils.maskBanco8D;
        this.regexConta = BancoMaskUtils.regexBanco8D;
      } else if (value === "Banco C6" || value === "Cooperativa Central de Credito Noroeste Brasileiro" ||
        value === "Bradesco" || value === "Next" || value === "Bradesco/Next" || value === "Stone") {
        this.maskConta = BancoMaskUtils.maskBanco7D;
        this.regexConta = BancoMaskUtils.regexBanco7D;
      } else if (value === "BS2" || value === "Banco Daycoval" || value === "Banco do Nordeste" ||
        value === "Uniprime Norte do Paraná") {
        this.maskConta = BancoMaskUtils.maskBanco6D;
        this.regexConta = BancoMaskUtils.regexBanco6D;
      } else if (value === "Itaú" || value === "Banco Topazio" || value === "Uniprime") {
        this.maskConta = BancoMaskUtils.maskBanco5D;
        this.regexConta = BancoMaskUtils.regexBanco5D;
      } else if (value === "Rendimento") {
        this.maskConta = BancoMaskUtils.maskBanco10;
        this.regexConta = BancoMaskUtils.regexBanco10;
      } else if (value === "Agibank" || value === "Global SCM") {
        this.maskConta = BancoMaskUtils.maskBanco11;
        this.regexConta = BancoMaskUtils.regexBanco11;
      } else if (value === "Sicredi") {
        this.maskConta = BancoMaskUtils.maskBanco7;
        this.regexConta = BancoMaskUtils.regexBanco7;
      } else if (value === "Citibank") {
        this.maskConta = BancoMaskUtils.maskBanco8;
        this.regexConta = BancoMaskUtils.regexBanco8;
      } else {
        this.maskConta = BancoMaskUtils.maskBanco8D;
        this.regexConta = BancoMaskUtils.regexBanco8D;
      }
      this.updateNumeroContaVality();
    });
  }

  // Mudança no tipo de conta da Caixa Econômica
  caixaAccountTypeChange() {
    this.form.get("account_type").valueChanges.subscribe(value => {
      if (this.form.get("bank").value === "Caixa Econômica") {
        if (value === "1288 – Poupança de Pessoa Física (para contas de novo formato)") {
          this.maskConta = BancoMaskUtils.maskBanco9D;
          this.regexConta = BancoMaskUtils.regexBanco9D;
        } else {
          this.maskConta = BancoMaskUtils.maskBanco8D;
          this.regexConta = BancoMaskUtils.regexBanco8D;
        }
        this.updateNumeroContaVality();
      }
    });

  }

  // Atualizar os validators do número da conta
  updateNumeroContaVality() {
    this.form.get("bank_cc").setValidators([Validators.required, Validators.pattern(this.regexConta)]);
    this.form.get("bank_cc").updateValueAndValidity();
  }

  // Atualizar os Validators da agência
  updateAgenciaVality() {
    this.form.get("bank_ag").setValidators([Validators.required, Validators.pattern(this.regexAgencia)]);
    this.form.get("bank_ag").updateValueAndValidity();
  }

  counter(i: number) {
    return new Array(i);
  }

  // Método para emitir evento de validação do form
  sendFormValidation() {
    if (this.subcontaEspelho) {
      this.formValid.emit(this.form.valid && this.formAntecipacao.valid);
    } else {
      this.formValid.emit(this.form.valid && this.formSaque.valid && this.formAntecipacao.valid);
    }
  }

  // Se houver mudanças nos formulários, emitir evento de formMudou, e envia a validação do form
  onChanges() {
    this.form.valueChanges.subscribe(() => {
      this.formMudouEmitter.emit(true);
      this.sendFormValidation();
    });
    this.formAntecipacao.valueChanges.subscribe(() => {
      this.formMudouEmitter.emit(true);
      this.sendFormValidation();
    });
    if (!this.subcontaEspelho) {
      this.formSaque.valueChanges.subscribe(() => {
        this.formMudouEmitter.emit(true);
        this.sendFormValidation();
      });
    }
  }

  ngOnDestroy() {
    this.empresaSubscription.unsubscribe();
    this.hasPagamentoOnlineSubscription.unsubscribe();
    this.disableSubscription.unsubscribe();
  }

}
