import * as firebase from "firebase";
import {Nfse} from "./nfse.model";
import Timestamp = firebase.firestore.Timestamp;


export enum StatusComissao {
  // Ainda não fechou o mês ou porque a empresa não pagou o boleto.
  ABERTO = "ABERTO",
  // Boleto Pago ou, mês que não deu valor mínimo para gerar boleto,
  // ou ainda mês em que os abatimentos foram maiores que a comissão a ser paga.
  FECHADO = "FECHADO"
}

export enum AcoesComissao {
  GERAR_SEGUNDA_VIA_EXPIRADA = "GERAR_SEGUNDA_VIA_EXPIRADA",
  DAR_BAIXA = "DAR_BAIXA",
  GERAR_FATURA_PARCIAL = "GERAR_FATUAR_PARCIAL"
}

export class LogActivity {
  public data: Timestamp;
  public userId: string;
  public userName: string;
  public acao: string;
  public motivo: string;

  constructor(log?) {
    if (log) {
      this.data = log.data;
      this.userId = log.userId;
      this.userName = log.userName;
      this.acao = log.acao;
      this.motivo = log.motivo;
    }
  }
}

export class EmpresaComissao {
  public id: string;
  public empresaId: string;
  public dataInicial: Timestamp;
  public dataFinal: Timestamp;
  public delivery: number; // Valor das entregas
  public deliveryPagtoOnline: number; // Valor das entregas Pede Que Chega nas vendas online
  public comissao: number; // Valor da comissão
  public comissaoPagtoOnline: number; // Valor da comissão de vendas online
  public pontos: number; // Valor em PoMs usados na empresa
  public abatimentos: number; // Totalização de abatimentos relacionados a comissão
  public valor: number;
  public iuguInvoiceId: string;
  public status: string; // StatusComissao
  public iuguInvoiceUrl: string;
  public logActivity: LogActivity[];
  public emiteNF: boolean;
  public nfse: Nfse;

  constructor(comissao: any = {}) {
    this.logActivity = [];
    this.id = comissao.id;
    this.empresaId = comissao.empresaId;
    this.iuguInvoiceId = comissao.iuguInvoiceId;
    this.dataInicial = comissao.dataInicial;
    this.dataFinal = comissao.dataFinal;
    this.deliveryPagtoOnline = comissao.deliveryPagtoOnline ? Number((comissao.deliveryPagtoOnline).toFixed(2)) : 0;
    this.delivery = comissao.delivery ? Number((comissao.delivery - this.deliveryPagtoOnline).toFixed(2)) : 0;
    this.comissaoPagtoOnline = comissao.comissaoPagtoOnline ? Number(comissao.comissaoPagtoOnline.toFixed(2)) : 0;
    this.comissao = comissao.comissao ? Number((comissao.comissao - this.comissaoPagtoOnline).toFixed(2)) : 0;
    this.pontos = comissao.pontos ? Number(comissao.pontos.toFixed(2)) : 0;
    this.abatimentos = comissao.abatimentos ? Number(comissao.abatimentos.toFixed(2)) : 0;
    this.valor = comissao.valor;
    this.status = comissao.status ? comissao.status : StatusComissao[StatusComissao.ABERTO];
    this.iuguInvoiceUrl = comissao.iuguInvoiceUrl;
    if (comissao.logActivity) {
      comissao.logActivity.forEach((log) => this.logActivity.push(new LogActivity(log)));
    }
    this.nfse = comissao.nfse;
    this.emiteNF = comissao.emiteNF ? comissao.emiteNF : false;
  }

  isAberto(): boolean {
    return this.status === StatusComissao[StatusComissao.ABERTO];
  }

  isFechado(): boolean {
    return this.status === StatusComissao[StatusComissao.FECHADO];
  }
}
