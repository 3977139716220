import {Criterion} from "./criterion";
import * as firebase from "firebase";
import Query = firebase.firestore.Query;
import OrderByDirection = firebase.firestore.OrderByDirection;
import {isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/commons";

export class OrderBy extends Criterion {
  direction: OrderByDirection;
  private _gte: any = null;
  private _lte: any = null;

  constructor(direction: OrderByDirection, value: string | string[]);
  constructor(direction: OrderByDirection, value?: any) {
    super(null, null, null);
    if (!(value instanceof Array)) {
      const values = new Array();
      values.push(value);
      value = values;
    }
    this.value = value;
    this.direction = direction;
  }

  gte(value: any): OrderBy {
    if (value instanceof Date) {
      this._gte = value;
    } else {
      this._gte = value;
    }
    return this;
  }

  lte(value: any): OrderBy {
    if (value instanceof Date) {
      this._lte = value;
    } else {
      this._lte = value;
    }
    return this;
  }

  build(ref: firebase.firestore.CollectionReference | Query): Query {
    if (isNullOrUndefined(this.value)) return null;
    let query: Query = null;
    for (let i = 0; i < this.value.length; i++) {
      if (query === null) {
        query = (ref as Query).orderBy(this.value[i], this.direction);
      } else {
        query = query.orderBy(this.value[i], this.direction);
      }
    }
    if (isNotNullOrUndefined(this._gte)) {
      query = query.where(this.value[0], ">=", this._gte);
    }
    if (isNotNullOrUndefined(this._lte)) {
      query = query.where(this.value[0], "<=", this._lte);
    }
    return query;
  }
}
