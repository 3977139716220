import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {PerfilService} from "../../../../../components/perfil-acesso/perfil.service";

@Component({
  selector: "app-list-more-options",
  templateUrl: "./list-more-options.component.html",
  styleUrls: ["./list-more-options.component.scss"]
})
export class ListMoreOptionsComponent implements OnInit {

  @Output()
  menuTriggerEvent = new EventEmitter<boolean>();

  @Output()
  editEvent = new EventEmitter<void>();

  @Output()
  removeEvent = new EventEmitter<void>();

  @Input()
  disableWhen: boolean;

  @Input()
  hideDelete: boolean = false;

  @Input()
  hideEdit: boolean = false;

  showEdit: boolean = false;
  showDelete: boolean = false;

  constructor(private perfil: PerfilService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    // Recuperar a regra da rota atual
    const updateRole = this.route.snapshot.data.role.update;
    const deleteRole = this.route.snapshot.data.role.delete;

    this.perfil.getPerfilAcessoLoggedUser()
      .subscribe(perfil => {
        this.showEdit = perfil.hasRole(updateRole) && !this.hideEdit;
        this.showDelete = perfil.hasRole(deleteRole) && !this.hideDelete;
      });
  }

  menuTrigger() {
    this.menuTriggerEvent.emit(true);
  }

  edit() {
    this.editEvent.emit();
  }

  remove() {
    this.removeEvent.emit();
  }

}
