import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CovalentCommonModule} from "@covalent/core/common";
import {CovalentDataTableModule} from "@covalent/core/data-table";
import {
  CovalentDialogsModule,
  TdAlertDialogComponent,
  TdConfirmDialogComponent,
  TdPromptDialogComponent
} from "@covalent/core/dialogs";
import {CovalentLayoutModule} from "@covalent/core/layout";
import {CovalentLoadingModule, TdLoadingComponent} from "@covalent/core/loading";
import {CovalentVirtualScrollModule} from "@covalent/core/virtual-scroll";
import {CovalentMediaModule} from "@covalent/core/media";

@NgModule({
  entryComponents: [
    TdLoadingComponent,
    TdAlertDialogComponent,
    TdConfirmDialogComponent,
    TdPromptDialogComponent
  ],
  imports: [
    CommonModule,
    CovalentCommonModule,
    CovalentLayoutModule,
    CovalentMediaModule,
    CovalentDialogsModule,
    CovalentLoadingModule,
    CovalentVirtualScrollModule,
    CovalentDataTableModule
  ],
  exports: [
    CovalentCommonModule,
    CovalentLayoutModule,
    CovalentMediaModule,
    CovalentDialogsModule,
    CovalentLoadingModule,
    CovalentVirtualScrollModule,
    CovalentDataTableModule
  ]
})
export class CovalentModule {
}
