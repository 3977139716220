import {FireModel} from "./fire.model";

export class Segmento extends FireModel {

  public nome: string;

  constructor(segmento?: any) {
    super();
    if (segmento) {
      this.id = segmento.id;
      this.nome = segmento.nome;

    }
  }

  toAny(): any {
    return Object.assign({}, this);
  }

}
