import {Directive, ElementRef, Input, OnDestroy, OnInit} from "@angular/core";
import {fromEvent, Subject} from "rxjs";
import {startWith, takeUntil} from "rxjs/operators";

@Directive({
  selector: "[appResponsiveSpan]"
})
export class ResponsiveSpanDirective implements OnInit, OnDestroy {

  @Input()
  bottom: string;

  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (this.bottom) {
      fromEvent(window, "resize").pipe(
        startWith(window.innerWidth),
        takeUntil(this.unsubscribe$)
      ).subscribe(() => {
        if (window.innerWidth <= 600) {
          this.el.nativeElement.style.bottom = this.bottom;
        } else {
          this.el.nativeElement.style.bottom = 20;
        }
      });

    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
