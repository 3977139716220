import {Injectable} from "@angular/core";
import {Repository} from "../../../repository/Repository";
import {Empresa} from "../../../models/empresa";
import {AppUser} from "../../../models/appUser";
import {combineLatest, Observable, of} from "rxjs";
import {AngularFirestoreSecondary} from "../../../services/firebase/angular-firestore-secondary";
import {map, mergeMap} from "rxjs/operators";
import {AuthService} from "../../../modules/login/auth.service";

/**
 * Service criado para dar suporte ao GerenciadorPedidosService.
 * Ambos usam a instancia secundária do Firestore para não dar conflito de streams abertos na mesma collection.
 */
@Injectable({
  providedIn: "root"
})
export class EmpresaGerenciadorService extends Repository<Empresa> {

  constructor(db: AngularFirestoreSecondary, public auth: AuthService) {
    super(db, "companies", true);
  }

  getEmpresas(): Observable<Empresa[]> {
    return this.auth.currentUser.pipe(mergeMap((currentUser: AppUser) => {
      if (currentUser) {
        if (currentUser.isLojista()) {
          return this.getEmpresasLojista(currentUser);
        } else {
          return this.auth.currentEmpresaObservable().pipe(
            mergeMap(empresaSelected => {
              if (empresaSelected) {
                return this.getEmpresa(empresaSelected.id).pipe(
                  map(empresa => [empresa])
                );
              } else {
                return of([]);
              }
            })
          );
        }
      }
      return of([]);
    }));
  }

  getEmpresasLojista(user: AppUser): Observable<Empresa[]> {
    return combineLatest(user.empresas.filter((v, i) => user.empresas.indexOf(v) === i).map(empresaId => this.getEmpresa(empresaId)));
  }

  getEmpresa(empresaId: string): Observable<Empresa> {
    return this.getDoc(empresaId).valueChanges();
  }

}
