import {RecordByEmpresa} from "./record-by-empresa";
import * as firebase from "firebase";
import {CreditCard} from "./credit-card.model";
import {isNullOrUndefined} from "../utils/commons";
import DocumentReference = firebase.firestore.DocumentReference;

export class FormaPagamento extends RecordByEmpresa {
  public descricao: string;
  public tipoPagamento: string;
  public formaPagamentoGeralId: string;
  public inactive: boolean = false;
  public codigoPDV: string;
  public iconURL: string;
  public taxaAdicional: TaxaAdicional;
  public empresa: DocumentReference;

  // Usados apenas dentro do pedido
  public creditCard: CreditCard;
  public invoiceId: string;

  public invoice;

  constructor(forma: any = {}) {
    super();
    if (forma) {
      this.id = forma.id;
      this.ref = forma.ref;
      this.empresa = forma.empresa;
      this.descricao = forma.descricao;
      this.codigoPDV = forma.codigoPDV;
      this.tipoPagamento = forma.tipoPagamento;
      this.formaPagamentoGeralId = forma.formaPagamentoGeralId;
      this.inactive = forma.inactive;
      this.iconURL = forma.iconURL;
      this.taxaAdicional = forma.taxaAdicional;

      this.creditCard = !isNullOrUndefined(forma.creditCard) ? new CreditCard(forma.creditCard) : null;
      this.invoiceId = !isNullOrUndefined(forma.invoiceId) ? forma.invoiceId : null;

      if (!isNullOrUndefined(forma.invoice)) this.invoice = forma.invoice;
    }
  }
}

export class TaxaAdicional {
  public cobrar: boolean;
  public tipo: TipoTaxaAdicional;
  public valor: number;

  constructor(data: any) {
    this.cobrar = data.cobrar;
    this.tipo = data.tipo;
    this.valor = data.valor;
  }
}

export enum TipoTaxaAdicional {
  percentual = "percentual",
  valor_fixo = "valor_fixo"
}

export enum TipoPagamento {
  dinheiro = "dinheiro",
  debito = "debito",
  credito = "credito",
  pontos = "pontos",
  online = "online"
}

export function tipoPagamentoToString(tipo: string): string {
  switch (tipo) {
    case "credito":
      return "Cartão de Crédito";
    case "debito":
      return "Cartão de Débito";
    case "dinheiro":
      return "Dinheiro";
    case "pontos":
      return "Programa de Pontos";
    case "online":
      return "Pagamento Online";
  }
}


