import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {PageService} from "../../../services/page.service";
import {FormaPagamento, TipoPagamento, tipoPagamentoToString, TipoTaxaAdicional,} from "../../../models/forma-pagamento.model";
import {FormBuilder, FormControl, FormGroup, Validators,} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {SnackService} from "../../../services/snack/snack.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {DialogService} from "../../../services/dialog/dialog.service";
import { MatDialog } from "@angular/material/dialog";
import {FormaPagamentoService} from "../forma-pagamento.service";
import {IFormCanDeactivate} from "../../../guards/iform-canDeactivate";
import {AuthService} from "../../../modules/login/auth.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {MatCheckboxChange} from "@angular/material/checkbox";

@Component({
  selector: "app-forma-pagamento-form",
  templateUrl: "./forma-pagamento-form.component.html",
  styleUrls: ["./forma-pagamento-form.component.scss"],
})
export class FormaPagamentoFormComponent extends PageService<FormaPagamento> implements OnInit, IFormCanDeactivate {
  public $formasGeraisPagamento: Observable<FormaPagamento[]>;

  public form: FormGroup;

  // Booleano que verifica o status da atualização de um formulário
  public formMudou: boolean = null;

  readonly TipoPagamentoToString = tipoPagamentoToString;
  readonly TipoTaxaAdicional = TipoTaxaAdicional;

  constructor(dialog: MatDialog,
              dialogService: DialogService,
              loadingService: LoadingService,
              snack: SnackService,
              cdRef: ChangeDetectorRef,
              route: ActivatedRoute,
              router: Router,
              public formBuilder: FormBuilder,
              public auth: AuthService,
              public service: FormaPagamentoService) {
    super(service, dialog, dialogService, loadingService, snack, cdRef, route, router, "/home/formas-pagamento/");

    const formaPagamentoGeralIdControl = new FormControl("", {
      validators: this.auth.currentEmpresa ? Validators.required : null,
    });

    this.$formasGeraisPagamento = service.getFormasGeraisPagamento();

    this.form = this.formBuilder.group({
      descricao: ["", Validators.compose([Validators.required, Validators.minLength(4)])],
      tipoPagamento: ["", [Validators.required]],
      formaPagamentoGeralId: formaPagamentoGeralIdControl,
      codigoPDV: [""],
      inactive: [true],
      iconURL: [""],
      taxaAdicional: this.formBuilder.group({
        cobrar: [false],
        tipo: [null],
        valor: [null]
      })
    });

    this.form.get("formaPagamentoGeralId").markAsTouched();

    this.form.valueChanges.subscribe((value) => {
      if (value.taxaAdicional.cobrar === false) {
        value.taxaAdicional.tipo = null;
        value.taxaAdicional.valor = null;
      }

      if (value.taxaAdicional.cobrar) {
        this.form.get("taxaAdicional.tipo").setValidators(Validators.required);
        this.form.get("taxaAdicional.valor").setValidators(Validators.required);
      } else {
        this.form.get("taxaAdicional.tipo").clearValidators();
        this.form.get("taxaAdicional.valor").clearValidators();
      }

      if (!this.auth.currentEmpresa) {
        Object.keys(value).forEach((key) => (this.item[key] = value[key]));
      } else {
        const formaGeral = value.formaPagamentoGeralId;
        if (typeof formaGeral !== "string") {
          value.formaPagamentoGeralId = formaGeral.id;
          value.tipoPagamento = formaGeral.tipoPagamento;
          value.iconURL = formaGeral.iconURL;
          value.descricao = formaGeral.descricao;
        } else {
          value.formaPagamentoGeralId = this.item.formaPagamentoGeralId;
          value.tipoPagamento = this.item.tipoPagamento;
          value.iconURL = this.item.iconURL;
          value.descricao = this.item.descricao;
        }
      }
      Object.keys(value).forEach((key) => (this.item[key] = value[key]));
    });

    /**
     * Inicializa método que verifica se ocorreram mudanças no conteúdo do formulário,
     * para decidir o comportamento do método podeMudarRota (canDeactivate)
     */
    this.onChanges();
  }

  tiposKeys(): Array<string> {
    return Object.keys(TipoPagamento).filter(tipo => tipo !== TipoPagamento[TipoPagamento.online] && tipo !== TipoPagamento[TipoPagamento.pontos]);
  }

  ngOnInit() {
    super.ngOnInit();
    this.itemSubject.asObservable().subscribe((item) => {
      this.form.patchValue(item);
    });
  }

  compareFn(c1: TipoPagamento, c2: TipoPagamento): boolean {
    return c1 && c2 && TipoPagamento[c1] === TipoPagamento[c2];
  }

  compareFormasPagamentos(
    formaPagamentoA: FormaPagamento,
    formaPagamentoB: string
  ): boolean {
    return formaPagamentoA.id === formaPagamentoB;
  }

  newItem(): FormaPagamento {
    return new FormaPagamento();
  }

  onChanges() {
    let mudanca = 0;
    this.form.valueChanges.subscribe(() => {
      if (mudanca === 0) {
        this.formMudou = false;
        mudanca++;
      } else {
        this.formMudou = true;
      }
    });
  }

  // Caso o formulário foi modificado
  podeMudarRota(): boolean {
    if (this.formMudou === true) {
      return confirm("Os dados ainda não foram salvos. Deseja sair mesmo assim?");
    }
    return true;
  }

  isLogista(): Observable<boolean> {
    return this.auth.currentUser.pipe(map((user) => user.isLojista()));
  }

  tipoTaxaAdicionalKeys(): Array<string> {
    return Object.keys(TipoTaxaAdicional);
  }

  getTipoTaxaAdicional(tipoTaxaAdicional: string): string {
    switch (tipoTaxaAdicional) {
      case TipoTaxaAdicional[TipoTaxaAdicional.percentual]:
        return "Percentual";
      case TipoTaxaAdicional[TipoTaxaAdicional.valor_fixo]:
        return "Valor Fixo";
      default:
        return null;
    }
  }

  onChangeCobrarTaxaAdicionalCheckbox(event: MatCheckboxChange) {
    if (event.checked === false) {
      this.form.get("taxaAdicional.tipo").setValue(null);
      this.form.get("taxaAdicional.valor").setValue(null);
    }
    if (event.checked === true) {
      this.form.get("taxaAdicional.tipo").setValidators(Validators.required);
      this.form.get("taxaAdicional.valor").setValidators(Validators.required);
    }
  }
}
