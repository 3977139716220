import {Telefone} from "./telefone";
import {Localizacao} from "./localizacao";
import {Horario} from "./horario";
import {FireModel} from "./fire.model";
import * as firebase from "firebase";
import {AppUser} from "./appUser";
import {isNullOrUndefined} from "util";
import {PagamentoOnline} from "./pagamento-online.model";
import DocumentReference = firebase.firestore.DocumentReference;
import Timestamp = firebase.firestore.Timestamp;
import {isEmpty} from "../utils/commons";
import {StringUtils} from "../utils/string-utils";

export class Empresa extends FireModel {

  public id: string;
  public ref: DocumentReference;
  public bannerUrl: string;
  public logoUrl: string;
  public descricao: string;
  public nomeFantasia: string;
  public nomeFantasiaNormalized: string;
  public razaoSocial: string;
  public pessoa: string;
  public cnpj: string;
  public dataNascimento: Timestamp;
  public email: string;
  public comissao: number;
  public consultor: AppUser;
  public dataFechamentoContrato: Timestamp;
  public observacao: string;
  public status: string;
  public abertura: Timestamp;
  public avgRating: number = 0;
  public numRatings: number = 0;
  public tempoEntrega: TempoEntrega;
  public telefones: Telefone[] = [];
  public localizacao: Localizacao = new Localizacao();
  public horario: Horario = new Horario();
  public aberto: boolean;
  public disponivel: boolean;
  public pedeQueChega: boolean;
  public diaVencimento: string;
  public iuguCustomerId: string;
  public setDisponivelTask: string;
  public encerrarGerenciadorTask: string;
  public bloqueio: Bloqueio;
  public lastUpdate: LastUpdate;
  public motivoStatusChange: string;
  public emiteNfseAutomatico: boolean;
  public entrega: Entrega;
  public appLink: string;
  public pagamentoOnline: PagamentoOnline;
  public tags: TagEmpresa[];


  constructor(empresa: any = {}) {
    super();
    if (!isNullOrUndefined(empresa)) {
      this.id = empresa.id;
      this.ref = empresa.ref;
      this.bannerUrl = empresa.bannerUrl;
      this.logoUrl = empresa.logoUrl;
      this.descricao = empresa.descricao;
      this.nomeFantasia = empresa.nomeFantasia;
      this.nomeFantasiaNormalized = empresa.nomeFantasiaNormalized;
      this.razaoSocial = empresa.razaoSocial;
      this.pessoa = !isNullOrUndefined(empresa.pessoa) ? empresa.pessoa : TipoPessoa.JURIDICA;
      this.cnpj = empresa.cnpj;
      this.dataNascimento = empresa.dataNascimento ? new Timestamp(empresa.dataNascimento.seconds, empresa.dataNascimento.nanoseconds) : undefined;
      this.email = empresa.email;
      this.comissao = !isNullOrUndefined(empresa.comissao) ? empresa.comissao : 0;
      this.consultor = new AppUser(empresa.consultor);
      this.dataFechamentoContrato = empresa.dataFechamentoContrato ? new Timestamp(empresa.dataFechamentoContrato.seconds, empresa.dataFechamentoContrato.nanoseconds) : undefined;
      this.observacao = empresa.observacao;
      this.aberto = empresa.aberto;
      this.disponivel = empresa.disponivel;
      this.status = empresa.status ? empresa.status : StatusEmpresa.PENDENTE;
      this.localizacao = new Localizacao(empresa.localizacao);
      this.telefones = !isNullOrUndefined(empresa.telefones) ? empresa.telefones.map(fone => new Telefone(fone)) : [];
      this.horario = new Horario(empresa.horario);
      this.abertura = empresa.abertura ? new Timestamp(empresa.abertura.seconds, empresa.abertura.nanoseconds) : undefined;
      this.avgRating = !isNullOrUndefined(empresa.avgRating) ? empresa.avgRating : 0;
      this.numRatings = !isNullOrUndefined(empresa.numRatings) ? empresa.numRatings : 0;
      this.tempoEntrega = new TempoEntrega(empresa.tempoEntrega);
      this.pedeQueChega = empresa.pedeQueChega;
      this.diaVencimento = !isNullOrUndefined(empresa.diaVencimento) ? empresa.diaVencimento : null;
      this.iuguCustomerId = empresa.iuguCustomerId;
      this.setDisponivelTask = empresa.setDisponivelTask;
      this.encerrarGerenciadorTask = empresa.encerrarGerenciadorTask;
      this.bloqueio = empresa.bloqueio;
      this.lastUpdate = empresa.lastUpdate;
      this.motivoStatusChange = empresa.motivoStatusChange;
      this.emiteNfseAutomatico = empresa.emiteNfseAutomatico;
      this.entrega = empresa.entrega;
      this.appLink = empresa.appLink;
      this.pagamentoOnline = empresa.pagamentoOnline;
      this.tags = empresa.tags;
    }
  }

  public toAny(): any {
    const a: any = {};
    if (this.id) a.id = this.id;
    if (this.ref) a.ref = this.ref;
    if (this.bannerUrl) a.bannerUrl = this.bannerUrl;
    if (this.logoUrl) a.logoUrl = this.logoUrl;
    if (this.descricao) a.descricao = this.descricao;
    if (this.nomeFantasia) a.nomeFantasia = this.nomeFantasia;
    if (this.nomeFantasiaNormalized) a.nomeFantasiaNormalized = this.nomeFantasiaNormalized;
    if (this.razaoSocial) a.razaoSocial = this.razaoSocial;
    if (this.cnpj) a.cnpj = this.cnpj;
    if (this.dataNascimento) a.dataNascimento = this.dataNascimento;
    if (this.email) a.email = this.email;
    if (this.observacao) a.observacao = this.observacao;
    if (this.aberto) a.aberto = this.aberto;
    if (this.disponivel) a.disponivel = this.disponivel;
    if (this.status) a.status = this.status;
    if (this.abertura) a.abertura = this.abertura;
    if (this.avgRating) a.avgRating = this.avgRating;
    if (this.numRatings) a.numRatings = this.numRatings;
    if (this.tempoEntrega)
      a.tempoEntrega = Object.assign({}, this.tempoEntrega);
    if (this.telefones)
      a.telefones = this.telefones.map(t => ({
        tipo: t.tipo,
        contato: t.contato,
        numero: t.numero,
        visivelNoSmartphone: t.visivelNoSmartphone,
        responsavel: t.responsavel,
        visivelNoTicket: t.visivelNoTicket
      }));
    if (this.localizacao) a.localizacao = this.localizacao.toAny();
    if (this.horario) a.horario = this.horario.toAny();
    if (this.pessoa) a.pessoa = this.pessoa;
    if (this.comissao) a.comissao = this.comissao;
    if (this.consultor instanceof AppUser) {
      a.consultor = this.consultor.toAny();
    } else {
      a.consultor = this.consultor;
    }
    if (this.dataFechamentoContrato) a.dataFechamentoContrato = this.dataFechamentoContrato;
    if (this.pedeQueChega) a.pedeQueChega = this.pedeQueChega;
    if (this.diaVencimento) a.diaVencimento = this.diaVencimento;
    if (this.iuguCustomerId) a.iuguCustomerId = this.iuguCustomerId;
    if (this.setDisponivelTask) a.setDisponivelTask = this.setDisponivelTask;
    if (this.encerrarGerenciadorTask) a.encerrarGerenciadorTask = this.encerrarGerenciadorTask;
    if (this.bloqueio) a.bloqueio = this.bloqueio;
    if (this.lastUpdate) a.lastUpdate = this.lastUpdate;
    if (this.motivoStatusChange) a.motivoStatusChange = this.motivoStatusChange;
    a.emiteNfseAutomatico = isEmpty(this.emiteNfseAutomatico) ? false : this.emiteNfseAutomatico;
    if (this.entrega) a.entrega = this.entrega;
    if (this.appLink) a.appLink = this.appLink;
    if (this.tags) a.tags = this.tags;
    if (this.pagamentoOnline) a.pagamentoOnline = this.pagamentoOnline;
    return a;
  }

  public isAberto(): boolean {
    return this.disponivel || this.aberto;
  }

  public makeNormalizedNomeFantasia(nomeFantasia: string): string {
    return StringUtils.normalize(nomeFantasia).toLowerCase();
  }
}

export interface Bloqueio {
  bloqueado: boolean;
  tipo: string;
  dataVerificacao: any;
  iuguinvoiceId: string;
  motivo: string;
}

export interface LastUpdate {
  data: Timestamp;
  userId: string;
  userName: string;
}

export class TempoEntrega {
  public tempoMinimo: number;
  public tempoMaximo: number;

  constructor(tempo?: any) {
    this.tempoMinimo = 0;
    this.tempoMaximo = 0;
    if (tempo) {
      this.tempoMinimo = tempo.tempoMinimo;
      this.tempoMaximo = tempo.tempoMaximo;
    }
  }
}

export enum DiaVencimento {
  _10 = "10",
  _15 = "15",
  _20 = "20",
  _25 = "25"
}

export enum StatusEmpresa {
  // Empresa criada, porém ainda faltam validações
  PENDENTE = "PENDENTE",
  // Empresa aparecerá como em breve no aplicativo
  PRONTO = "PRONTO",
  // Empresa liberada no menu de empresas
  LIBERADO = "LIBERADO",
  // Empresa suspensa
  SUSPENSO = "SUSPENSO"
}

export enum TipoPessoa {
  FISICA = "FISICA",
  JURIDICA = "JURIDICA"
}

export function TipoPessoaToString(tipo: string): string {
  switch (tipo) {
    case "FISICA":
      return "Pessoa física";
    case "JURIDICA":
      return "Pessoa jurídica";
    default:
      return null;
  }
}

export class StatusLogActivity {
  public data: Timestamp;
  public dataVerificacao: any;
  public userId: string;
  public userName: string;
  public motivo: string;
  public tipo: string; // Enum TipoStatusLogActivity

  constructor(statusLog: any = {}) {
    this.data = statusLog.data;
    this.dataVerificacao = statusLog.dataVerificacao;
    this.userId = statusLog.userId;
    this.userName = statusLog.userName;
    this.motivo = statusLog.motivo;
    this.tipo = statusLog.tipo;
  }
}

export enum TipoStatusLogActivity {
  BLOQUEIO = "BLOQUEIO",
  DESBLOQUEIO = "DESBLOQUEIO",
  PENDENTE = "PENDENTE",
  LIBERADO = "LIBERADO",
  SUSPENSO = "SUSPENSO",
  CADASTRO = "CADASTRO",
  SUBCONTA = "SUBCONTA",
  DADOS_BANCARIOS = "DADOS_BANCARIOS",
  PAGTO_ONLINE = "PAGTO_ONLINE"
}

export enum TipoBloqueio {
  PENDENCIA_FINANCEIRA = "PENDENCIA_FINANCEIRA",
  OUTRO = "OUTRO"
}

export function TipoBloqueioToString(tipo: string): string {
  switch (tipo) {
    case TipoBloqueio.PENDENCIA_FINANCEIRA: {
      return "Pendência Financeira";
    }
    case TipoBloqueio.OUTRO: {
      return "Outro";
    }
  }
}

export class Entrega {
  tipo: string; // Enum TipoEntrega
  valor: number;
  valorMinimoEntregaGratis: number;
  valorMinimoPedido: number;

  constructor(value: any = {}) {
    this.tipo = value.tipo;
    this.valor = value.valor;
    this.valorMinimoEntregaGratis = value.valorMinimoEntregaGratis;
    this.valorMinimoPedido = value.valorMinimoPedido;
  }
}

enum TipoEntrega {
  normal = "normal",
  gratis = "gratis"
}

export enum TagEmpresa {
  PROMOPOM = "PROMOPOM"
}
