import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: "app-image-bagde",
  templateUrl: "./image-bagde.component.html",
  styleUrls: ["./image-bagde.component.scss"]
})
export class ImageBagdeComponent implements OnInit {

  @Input()
  imageSrc: string;

  @Input()
  badgeColor: string;

  constructor() { }

  ngOnInit() {
  }

}
